import React from "react";
import {Alert, Button, Col, Form, FormGroup} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import {useHideModal} from "../../../redux/ui/hooks";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface AnnuleerOpvolgingItemFormValues {
    reden: string;
}

export interface AnnuleerOpvolgingItemModalProps {
    onBevestigHandler: (form: AnnuleerOpvolgingItemFormValues) => void
}

export const AnnuleerOpvolgingItemModal: React.FC<AnnuleerOpvolgingItemModalProps> = (props) => {
    const {onBevestigHandler} = props;

    const {t} = useTranslation("opvolging");

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const onBevestig = (values: AnnuleerOpvolgingItemFormValues) => {
        hideModal();
        onBevestigHandler(values);
    };

    const initialValues: AnnuleerOpvolgingItemFormValues = {
        reden: ""
    };

    return (
        <Modal show onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>Annuleren</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                validateOnMount
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    reden: Yup.string().required(t("Foutmeldingen.reden-is-verplicht", "Reden is verplicht") as string)
                })}
            >
                {({
                      handleSubmit,
                      isValid
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <Alert variant="warning">
                                {t("AnnuleerOpvolgingItemModal", "Bent u zeker dat u dit wenst te annuleren? Deze actie is niet omkeerbaar.")}
                            </Alert>

                            <h5>{t("Labels.reden", "Reden")}</h5>
                            <Form.Row>
                                <FormGroup id="reden" as={Col}>
                                    <TextAreaFormField name="reden"/>
                                </FormGroup>
                            </Form.Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="light" onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}
                            <Button variant="primary" disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};

