import React, {useEffect} from 'react';
import {PartnerBox} from '../../../components/aqualex/PartnerBox';
import {Button, Modal} from 'react-bootstrap';
import {RadioFormField} from '../../../components/aqualex/form/RadioFormField';
import {PartnerFormField} from '../../../components/aqualex/form/PartnerFormField';
import {Form, useFormikContext} from 'formik';
import {BestelBonModel} from '../../../redux/bestelbon/types';
import {KoppelPartnerAanBestelbonFormValues} from './KoppelPartnerAanBestelbonModal';
import {PartnerSelectie} from '../../../redux/partner/types';
import Config from "../../../helpers/Config";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface KoppelPartnerAanBestelbonModalFormProps {
    bestelBon: BestelBonModel;

    onAnnuleer(): void;
}

export const KoppelPartnerAanBestelbonModalForm: React.FC<KoppelPartnerAanBestelbonModalFormProps> = (props) => {
    const {onAnnuleer} = props;
    const {values, isValid, setFieldValue} = useFormikContext<KoppelPartnerAanBestelbonFormValues>();

    const {t} = useTranslation("bestelbon");

    useEffect(() => {
        if (values.partnerSelectie !== PartnerSelectie.VASTGELEGD) {
            setFieldValue("partner", undefined);
        }
    }, [values.partnerSelectie, setFieldValue]);

    return (
        <Form>
            <Modal.Body>
                <p>
                    {t("KoppelPartnerAanBestelbonModalForm.kwam-deze-bestelbon-tot-stand-in-samenwerking-met-een-partner", "Kwam deze bestelbon tot stand in samenwerking met een partner?")}
                </p>

                <RadioFormField
                    name="partnerSelectie"
                    options={
                        [
                            {label: Config.labels.partnerSelectie[PartnerSelectie.GEEN], value: PartnerSelectie.GEEN},
                            {
                                label: Config.labels.partnerSelectie[PartnerSelectie.NOG_NIET_BESCHIKBAAR_IN_LIJST],
                                value: PartnerSelectie.NOG_NIET_BESCHIKBAAR_IN_LIJST
                            },
                            {
                                label: Config.labels.partnerSelectie[PartnerSelectie.VASTGELEGD],
                                value: PartnerSelectie.VASTGELEGD
                            }
                        ]
                    }
                />

                {values.partnerSelectie === PartnerSelectie.VASTGELEGD && (
                    <div className="mt-3">
                        <PartnerFormField name="partner"/>
                    </div>
                )}

                {values.partner && (
                    <>
                        <hr/>
                        <div className="mt-3">
                            <h4>{t("KoppelPartnerAanBestelbonModalForm.gekozen-partner-om-te-koppelen", "Gekozen partner om te koppelen:")}</h4>
                            <div className="d-flex align-items-center">
                                <i className="mdi mdi-check text-success mdi-36px mr-2"/>
                                <PartnerBox partner={values.partner}/>
                            </div>
                        </div>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onAnnuleer}>
                    {t("algemeen:Knoppen.annuleer", "Annuleer")}
                </Button>{' '}
                <Button variant="primary" disabled={!isValid} type="submit">
                    {t("algemeen:Knoppen.bevestig", "Bevestig")}
                </Button>
            </Modal.Footer>
        </Form>
    );
};
