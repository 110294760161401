import React from "react";
import {BestelBonLijnModel, BesteldArtikelItemModel, BesteldProductItemModel} from "../../../redux/bestelbon/types";
import ArtikelNrOmschrijving from "../../../components/aqualex/connected/ArtikelNrOmschrijving";
import {Translation} from "react-i18next";

export const BestelBonLijnItemLabel: React.FC<{ lijn: BestelBonLijnModel }> = (props) => {
    const {lijn} = props;

    switch (lijn.item.type) {
        case "ARTIKEL":
            return (
                <div>
                    <div><ArtikelNrOmschrijving artikelId={(lijn.item as BesteldArtikelItemModel).artikelId}/></div>
                    <small
                        className="text-muted"><Translation>{t => t("Labels.accessoire", "Accessoire")}</Translation></small>
                </div>
            );
        case "PRODUCT":
            return (
                <div>
                    <div>{(lijn.item as BesteldProductItemModel).configuratieArtikel.omschrijving}</div>
                    <small
                        className="text-muted"><Translation>{t => t("Labels.product", "Product")}</Translation></small>
                </div>
            );
    }
};
