import {CreateKlantForm, KlantFilters, KlantModel, KlantSimilarSearchFilters, UpdateKlantForm} from "./types";
import {baseApi} from "../support/api";
import {
    buildFilteredAndPagedSearchQuery,
    buildGetByIdQuery,
    buildQuery,
    buildUpdateMutation
} from "../support/ApiBuilderUtils";
import {installTranslationKey} from "../../helpers/i18nUtils";

export const klantApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getKlantById: buildGetByIdQuery<KlantModel, string>(build,
            installTranslationKey("klant:APIFoutmeldingen.ophalen-van-klant","ophalen van klant"), "Klant", id => `/klant/${id}`),
        getKlantByNummer: buildQuery<KlantModel, { bedrijfId: string, klantNummer: string }>(build,
            installTranslationKey("klant:APIFoutmeldingen.ophalen-van-klant", "ophalen van klant"), "Klant", () => `/klant/byNo`),
        herlaadKlant: buildUpdateMutation<KlantModel, { id: string }>(build,
            installTranslationKey("klant:APIFoutmeldingen.herladen-van-klant", "herladen van klant"), "Klant", ({id}) => `/klant/${id}?refresh=true`, {}, {
            useParams: true,
            method: "GET"
        }),
        searchKlanten: buildFilteredAndPagedSearchQuery<KlantModel, KlantFilters>(build,
            installTranslationKey("klant:APIFOutmeldingen.zoeken-van-klant", "zoeken van klanten"), "Klant", () => `/klant`),
        searchKlantSimilar: buildFilteredAndPagedSearchQuery<KlantModel, KlantSimilarSearchFilters>(build,
            installTranslationKey("klant:APIFoutmeldingen.zoeken-van-gelijkaardige-klanten", "zoeken van gelijkaardige klanten"), "Klant", () => `/klant/similar`),
        nieuweKlant: build.mutation<KlantModel, CreateKlantForm>({
            query: (form) => {
                return {
                    url: `/klant`,
                    method: "POST",
                    body: form,
                };
            },
            extraOptions: {
                omschrijving: "toevoegen klant"
            },
            invalidatesTags: (result, error, arg) => [
                {type: "Klant", id: "PARTIAL-LIST"}
            ],
        }),
        updateKlant: buildUpdateMutation<KlantModel, UpdateKlantForm & Pick<KlantModel, "id">>(build,
            installTranslationKey("klant:APIFoutmeldingen.bijwerken-klant", "bijwerken klant"), "Klant", ({id}) => `/klant/${id}`, {}, {method: "PATCH"})
    })
});


export const useGetKlantById = klantApi.endpoints.getKlantById.useQuery;
export const useGetKlantByNummer = klantApi.endpoints.getKlantByNummer.useQuery;
export const useNieuweKlant = klantApi.endpoints.nieuweKlant.useMutation;
export const useUpdateKlant = klantApi.endpoints.updateKlant.useMutation;
export const useHerlaadKlant = klantApi.endpoints.herlaadKlant.useMutation;
export const useSearchKlanten = klantApi.endpoints.searchKlanten.useQuery;
export const useSearchKlantSimilar = klantApi.endpoints.searchKlantSimilar.useQuery;
