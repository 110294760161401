import {default as React} from "react";
import {Formik, FormikHelpers} from "formik";
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {KlantModel} from "../../redux/klant/types";
import * as Yup from 'yup';
import {KlantFormField} from "../../components/aqualex/form/KlantFormField";
import {SimpleFormField} from "../../components/aqualex/form/SimpleFormField";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../components/aqualex/Modal";
import {useHideModal} from "../../redux/ui/hooks";
import {LoadingButton} from "../../components/aqualex/LoadingButton";
import {useTranslation} from "react-i18next";

export interface NieuweKlantAccountModalProps {
    naam?: string,
    email?: string,
    klant?: KlantModel,
    onBevestigHandler: (klantId: string, naam: string, email: string) => Promise<void>;
}

interface FormValues {
    naam?: string;
    email?: string;
    klant?: KlantModel;
}

export const NieuweKlantAccountModal: React.FC<NieuweKlantAccountModalProps> = ({
                                                                                    naam, email, klant,
                                                                                    onBevestigHandler,
                                                                                }) => {
    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    }

    const onBevestig = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        try {
            await onBevestigHandler(values.klant!.id, values.naam!, values.email!);
            hideModal();
        } finally {
            helpers.setSubmitting(false);
        }
    }

    const initialValues: FormValues = {
        klant: klant,
        naam: naam,
        email: email,
    };

    const {t} = useTranslation("klantaccount");

    return (
        <Modal show={true} onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.nieuwe-myaqualex-account-maken", "Nieuwe MyAQUALEX account maken")}</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                validateOnMount // to make validateOnMount, initialValues must be created using useMemo
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    klant: Yup.object()
                        .nullable()
                        .required(t("Foutmeldingen.klant-is-verplicht", "Klant is verplicht") as string),
                    naam: Yup.string()
                        .nullable()
                        .required(t("Foutmeldingen.naam-is-verplicht", "Naam is verplicht") as string),
                    email: Yup.string()
                        .nullable()
                        .required(t("Foutmeldingen.email-is-verplicht", "Email is verplicht") as string),
                })}
            >
                {({
                      handleSubmit,
                      isSubmitting,
                      isValid
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <h5>{t("Labels.naam", "Naam")}</h5>
                            <Form.Row>
                                <FormGroup id='naam' as={Col}>
                                    <SimpleFormField name='naam'/>
                                </FormGroup>
                            </Form.Row>
                            <h5>{t("Labels.e-mail", "E-mail")}</h5>
                            <Form.Row>
                                <FormGroup id='email' as={Col}>
                                    <SimpleFormField name='email'/>
                                </FormGroup>
                            </Form.Row>
                            <h5>{t("Labels.klant", "Klant")}</h5>
                            <Form.Row>
                                <FormGroup id='klant' as={Col}>
                                    <KlantFormField name='klant' disabled={!!klant}/>
                                </FormGroup>
                            </Form.Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='light' onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                            <LoadingButton variant='primary' disabled={!isValid || isSubmitting} loading={isSubmitting}
                                           onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</LoadingButton>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};
