import Config from "./Config";
import {GET, POST} from './api';
import {formatDateIso} from "./DateUtils";
import {DagPlanningModel} from "../redux/planning/types";

const BACKOFFICE_DAG_PLANNING_API_BASE_URL = Config.BACKOFFICE_BASE_URL + '/api/dagplanning';

export default class DagPlanningApi {

    static fetchDagPlanningen(medewerkerId?: string, vanaf?: Date, tot?: Date): Promise<Array<DagPlanningModel>> {
        const uri = BACKOFFICE_DAG_PLANNING_API_BASE_URL;

        const params = new URLSearchParams();
        medewerkerId && params.append('medewerkerId', medewerkerId)
        vanaf && params.append('vanaf', formatDateIso(vanaf));
        tot && params.append('tot', formatDateIso(tot));

        return GET(uri + `?${params.toString()}`, 'ophalen van dagplanningen');
    }

    static beschikbareDagPlanningen(medewerkerId: string, datum: Date, aantal: number): Promise<Array<DagPlanningModel>> {
        const uri = BACKOFFICE_DAG_PLANNING_API_BASE_URL + '/beschikbaar';

        const params = new URLSearchParams();
        params.append('medewerkerId', medewerkerId)
        params.append('datum', formatDateIso(datum));
        params.append('aantal', aantal.toString());

        return GET(uri + `?${params.toString()}`, 'ophalen van dagplanningen');
    }

    static bulkUploadDagPlanningen(upload: string) {
        const uri = BACKOFFICE_DAG_PLANNING_API_BASE_URL + `/upload`;

        return POST(uri, 'bulk upload van dagplanningen', {
            headers: {
                'Content-Type': 'text/tab-separated-values',
            },
            body: upload
        });
    }

    static fetchDagPlanningBezoeken(dagPlanningId: string) {
        const uri = BACKOFFICE_DAG_PLANNING_API_BASE_URL + `/${dagPlanningId}/bezoeken`;

        return GET(uri, 'ophalen van dagplanning bezoeken');
    }

    static pasAutomatischAanvullenVoorstellenToe(dagPlanningId: string) {
        const uri = BACKOFFICE_DAG_PLANNING_API_BASE_URL + `/${dagPlanningId}/automatischAanvullen/toepassen`;

        return POST(uri, "toepassen van voorgestelde bezoeken", {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    static verwijderAutomatischAanvullenVoorstellen(dagPlanningId: string) {
        const uri = BACKOFFICE_DAG_PLANNING_API_BASE_URL + `/${dagPlanningId}/automatischAanvullen/verwijderen`;

        return POST(uri, "verwijderen van voorgestelde bezoeken", {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    static dagPlanningCapaciteitProjection(van: string, tot: string) {
        const uri = BACKOFFICE_DAG_PLANNING_API_BASE_URL + `/capaciteit`;

        const params = new URLSearchParams();
        params.append('van', van)
        params.append('tot', tot)

        return GET(uri + `?${params.toString()}`, 'ophalen van dagplanning capaciteit');
    }

}
