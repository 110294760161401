import React, {CSSProperties} from "react";
import {Spin} from "../Spin";
import {useFileDownload} from "../../helpers/useFileDownload";

export interface FileDownloadLinkProps {
    uri?: string;
    omschrijving?: string;

    isImage?: boolean;

    style?: CSSProperties;
    className?: string;

    as?: keyof JSX.IntrinsicElements | React.ReactFragment;
}

export const FileDownloadLink: React.FC<FileDownloadLinkProps> = (props) => {
    const {uri, omschrijving, isImage, style, className, as: As = "a"} = props;

    const {download, loading, LightboxComponent} = useFileDownload({
        isImage,
        omschrijving,
        uri: uri || "unknown"
    });

    const onClick = (e) => {
        e.preventDefault();

        download();
    };

    if (!uri) {
        return <>{props.children}</>;
    }

    return (
        <>
            <Spin spinning={loading}>
                {/* @ts-ignore */}
                <As href={uri} onClick={onClick} target="_blank" style={style} className={className}>
                    {props.children}
                {/* @ts-ignore */}
                </As>
            </Spin>

            <LightboxComponent/>
        </>
    );
};
