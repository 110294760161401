import {default as React, useState} from "react";
import {AvForm} from 'availity-reactstrap-validation';
import {RootState} from "../../../redux/reducers";
import {connect} from 'react-redux';
import {hideModal} from "../../../redux/ui/actions";
import moment from 'moment';
import DatumSelector from "../../../components/aqualex/DatumSelector";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "../../../components/aqualex/Modal";
import {Button} from "react-bootstrap";
import {useTranslation} from "../../../helpers/i18nUtils";

const InstallatieStelUitModal = ({
                                     onStelUitHandler,
                                     hideModal
                                 }) => {

    const standaardDatum = moment().add(7, "days").toDate();
    const [uitgesteldTotDatum, setUitgesteldTotDatum] = useState(standaardDatum);

    const onAnnuleer = () => {
        hideModal();
    }

    const onBevestig = (event, values) => {
        hideModal();
        onStelUitHandler(uitgesteldTotDatum);
    }

    const handleDatumChange = (date) => {
        setUitgesteldTotDatum(date);
    }

    const {t} = useTranslation("installatie");

    return (
        <Modal show={true} onHide={onAnnuleer}>
            <ModalHeader closeButton>{t("Titels.installatie-uitstellen", "Installatie uitstellen")}</ModalHeader>
            <AvForm onValidSubmit={onBevestig}>
                <ModalBody>

                    <p>{t("InstallatieStelUitModal.tot-wanneer-wil-je-uitstellen", "Tot wanneer wil je uitstellen?")}</p>

                    <DatumSelector value={uitgesteldTotDatum} onChange={handleDatumChange}/>

                </ModalBody>
                <ModalFooter>
                    <Button color='light' onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                    <Button color='primary' type="submit">{t("algemeen:Buttons.stel-uit", "Stel uit")}</Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    );
};

const mapStateToProps = (state: RootState, ownProps) => ({
    onStelUitHandler: ownProps.onStelUitHandler,
});

const mapDispatchToProps = {
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallatieStelUitModal);
