import * as React from "react";
import {Button, Col, ListGroup, Row} from "react-bootstrap";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";
import {FieldHelperProps, FieldInputProps} from "formik";
import {OperationeelToestelModel} from "../../../redux/toestel/types";
import ToestelSelector from "../toestel/ToestelSelector";
import ToestelBox from "../toestel/ToestelBox";
import {useSearchToestellen} from "../../../redux/toestel/api";
import {skipToken} from "@reduxjs/toolkit/query";
import {SortOrder} from "../../../redux/support/pagination";

interface ToestelLijstFormFieldProps {
    klantId: string;
    verzendAdresId?: string;
}

export const ToestelLijstFormField: React.FC<FormikFormFieldProps & ToestelLijstFormFieldProps> = ({
                                                                                                       disabled,
                                                                                                       isInvalid,
                                                                                                       filters = {},
                                                                                                       ...props
                                                                                                   }) => {

    const {klantId, verzendAdresId} = props;

    // TODO betere manier vinden om alle toestellen te selecteren voor een verzend adres
    const {data} = useSearchToestellen(
        verzendAdresId // enkel alle toestellen inladen als er een verzend adres is
            ? {
                filters: {
                    klantId,
                    verzendAdresId
                },
                pageable: {
                    pageNumber: 1,
                    pageSize: 10000,
                    sortField: "nr",
                    sortOrder: SortOrder.asc
                },
            }
            : skipToken);

    const alleToestellen = data?.content || [];

    const selecteerToestel = (field: FieldInputProps<OperationeelToestelModel[]>, helpers: FieldHelperProps<OperationeelToestelModel[]>, toestel: OperationeelToestelModel) => {
        if (!toestel) {
            return;
        }

        const nieuweToestellen = new Set(field.value);
        nieuweToestellen.add(toestel);
        helpers.setValue(Array.from(nieuweToestellen));
    };

    const selecteerAlleToestellen = (helpers: FieldHelperProps<OperationeelToestelModel[]>) => {
        helpers.setValue(alleToestellen);
    };

    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <div className="custom-control" style={{padding: "0", width: "100%"}}>
                    <Button size='sm'
                            disabled={disabled}
                            variant='link'
                            className='float-right mt-n4'
                            onClick={() => selecteerAlleToestellen(helpers)}>
                        <small>Alle toestellen ({alleToestellen.length})</small>
                    </Button>
                    <div className="d-flex" style={{width: "100%"}}>
                        <div className="flex-grow-1">
                            <ToestelSelector disabled={disabled}
                                             klantId={klantId}
                                             verzendAdresId={verzendAdresId}
                                             isInvalid={!!meta.error}
                                             isClearable={false}
                                             toestelChanged={toestel => selecteerToestel(field, helpers, toestel)}/>
                        </div>
                    </div>
                    <div className="mt-1">
                        <ListGroup className='clearfix'>
                            {field.value?.map(toestel => <ListGroup.Item style={{verticalAlign: 'middle'}}
                                                                         key={toestel.id}
                                                                         className="p-1">
                                <Row className="align-items-center">
                                    <Col className="mb-n1">
                                        <ToestelBox toestel={toestel} clickable={false}/>
                                    </Col>
                                    {!disabled && (
                                        <Col xs="auto">
                                            <Button size='lg'
                                                    variant='link'
                                                    className='float-right'
                                                    onClick={() => {
                                                        const nieuweToestellen = new Set(field.value);
                                                        nieuweToestellen.delete(toestel);
                                                        helpers.setValue(Array.from(nieuweToestellen));
                                                    }}>
                                                <i className={'mdi mdi-trash-can-outline'}/>
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </ListGroup.Item>)}
                        </ListGroup>
                    </div>
                </div>
            )}
        </FormikFormField>
    );
};
