import {default as React} from "react";
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {Form as FormikForm, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {SimpleFormField} from "../../../components/aqualex/form/SimpleFormField";
import {NieuweAccessoireBestellingForm} from "../../../redux/accessoirebestelling/types";
import {useHideModal} from "../../../redux/ui/hooks";
import {BedrijfFormField} from "../../../components/aqualex/form/BedrijfFormField";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";
import {TransportKostConfiguratieType} from "../../../redux/types";
import {
    TransportKostConfiguratieForm,
    transportKostValidatie
} from "../../../components/aqualex/form/TransportKostConfiguratieForm";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "react-i18next";

export interface MaakAccessoireBestellingModalProps {
    onBevestigHandler: (form: NieuweAccessoireBestellingForm) => Promise<void>;
}

export interface BestelAccessoireFormValues {
    bedrijfId: string | null;
    verkoopOrderNr: string | null;
    transportKostType: TransportKostConfiguratieType | null;
    transportKostValue: number | null;
    transportKostInherit?: boolean;
}

export const NieuweAccessoireBestellingModal: React.FC<MaakAccessoireBestellingModalProps> = (props) => {
    const {onBevestigHandler} = props;

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const {t} = useTranslation("accessoire-bestelling");

    const bedrijfIsVerplichtLabel = t("Foutmeldingen.bedrijf-is-verplicht", "Bedrijf is verplicht");
    const verkoopordernummerIsVerplichtLabel = t("Foutmeldingen.verkoopordernummer-is-verplicht", "Verkoopordernummer is verplicht");

    const onSubmit = async (values: BestelAccessoireFormValues, helpers: FormikHelpers<any>) => {
        try {
            await onBevestigHandler({
                bedrijfId: values.bedrijfId!,
                verkoopOrderNr: values.verkoopOrderNr!,
                transportKostType: values.transportKostInherit ? TransportKostConfiguratieType.INHERIT : values.transportKostType as TransportKostConfiguratieType,
                transportKostValue: values.transportKostValue || undefined
            });

            hideModal();
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const schema = Yup.object({
        bedrijfId: Yup.string().nullable().required(bedrijfIsVerplichtLabel),
        verkoopOrderNr: Yup.string().nullable().required(verkoopordernummerIsVerplichtLabel),
        ...transportKostValidatie(t)
    });

    return (
        <Modal show={true} onHide={onAnnuleer} autoFocus size="lg">
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.accessoirebestelling-toevoegen", "Accessoirebestelling toevoegen")}</ModalTitle>
            </ModalHeader>

            <Formik<BestelAccessoireFormValues> validationSchema={schema}
                                                onSubmit={onSubmit}
                                                validateOnBlur={false}
                                                validateOnChange={false}
                                                initialValues={{
                                                    bedrijfId: null,
                                                    verkoopOrderNr: null,
                                                    transportKostInherit: true,
                                                    transportKostType: null,
                                                    transportKostValue: null
                                                } as BestelAccessoireFormValues}
            >
                {({
                      isValid,
                      isSubmitting
                  }) => {
                    return (
                        <FormikForm noValidate>
                            <ModalBody>
                                <h5>{t("Titels.verkooporder", "Verkooporder")}</h5>
                                <Form.Row>
                                    <FormGroup id="klant" as={Col}>
                                        <BedrijfFormField name="bedrijfId"
                                                          label={t("Labels.bedrijf", "Bedrijf")}/>
                                    </FormGroup>
                                    <FormGroup id="klant" as={Col}>
                                        <SimpleFormField name="verkoopOrderNr"
                                                         label={t("Labels.VOR-nummer", "VOR-nummer")}/>
                                    </FormGroup>
                                </Form.Row>

                                <h5>{t("Titels.transportkost", "Transportkost")}</h5>
                                <Form.Row>
                                    <FormGroup id="transportkost" as={Col} md={6} xs={12}>
                                        <TransportKostConfiguratieForm
                                            inheritLabel={t("Labels.transportkost-overnemen-van-verzendadres-of-klant",
                                                "Overnemen van verzendadres of klant")}/>
                                    </FormGroup>
                                </Form.Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button variant="light" onClick={onAnnuleer}>Annuleer</Button>
                                <LoadingButton disabled={!isValid || isSubmitting} loading={isSubmitting}
                                               variant="primary"
                                               type="submit">{t("algemeen:Buttons.toevoegen", "Toevoegen")}</LoadingButton>
                            </ModalFooter>
                        </FormikForm>
                    );
                }}
            </Formik>
        </Modal>
    );
};
