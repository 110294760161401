import * as React from "react";
import InstallatieVereiste from "./InstallatieVereiste";
import {SelectFormField} from "../../../components/aqualex/form/SelectFormField";


interface ToestellenProps {
    guard: boolean;
    label: string;
    mogelijkeWaarden: Array<{ value: string, label: string }>;
    waardePropertyName: string;
    waarde?: any;
    waardeColSize?: number;
    opmerkingPropertyName: string;
    opmerking?: string;
}

const ChoiceInstallatieVereiste: React.FC<ToestellenProps> = ({
                                                                  guard,
                                                                  label,
                                                                  mogelijkeWaarden,
                                                                  waardePropertyName,
                                                                  waarde,
                                                                  waardeColSize,
                                                                  opmerkingPropertyName,
                                                                  opmerking
                                                              }) => {

    return (
        <InstallatieVereiste guard={guard}
                             label={label}
                             waardePropertyName={waardePropertyName}
                             waarde={waarde}
                             waardeColSize={waardeColSize}
                             opmerkingPropertyName={opmerkingPropertyName}
                             opmerking={opmerking}
                             waardeRenderer={(waarde) => {
                                 return <SelectFormField name={waardePropertyName}>
                                     <option/>
                                     {mogelijkeWaarden.map(({value, label}) => {
                                         return <option key={value} value={value}>{label}</option>
                                     })}
                                 </SelectFormField>;
                             }}
        />
    )
};

export default ChoiceInstallatieVereiste;
