// @flow
import "./apm";
import React, {useEffect} from 'react';
import "./i18n";
import {useDispatch, useSelector} from 'react-redux';
import {Routes} from './routes/Routes';
import {fetchAnnulatieRedenenRequest} from "./redux/refdata/actions";
import {selectUserIsLoggedIn} from "./redux/auth/reducers";

// Themes
import './assets/scss/Saas.scss';
import Config from "./helpers/Config";
import {AuthProvider} from "./auth/authProvider";
import {useJsApiLoader} from "@react-google-maps/api";

// For Dark import DarkTheme.scss
// import './assets/scss/DarkTheme.scss';

// For green - import GreenTheme.scss
// import './assets/scss/GreenTheme.scss';

interface AppProps {
}

/**
 * Main app component
 */
const App: React.FC<AppProps> = () => {
    const dispatch = useDispatch();

    const userIsLoggedIn: boolean = useSelector(selectUserIsLoggedIn);

    useJsApiLoader({
        googleMapsApiKey: Config.BACKOFFICE_GOOGLE_MAPS_KEY,
        libraries: Config.BACKOFFICE_GOOGLE_MAPS_LIBRARIES as any,
        language: "nl"
    });

    useEffect(() => {
        if (userIsLoggedIn) {
            dispatch(fetchAnnulatieRedenenRequest());
        }
    }, [userIsLoggedIn, dispatch]);

    return (
        <>
            <AuthProvider>
                <Routes/>
            </AuthProvider>
        </>
    );
}

export default App;
