import React from "react";
import {useTranslation} from "../../helpers/i18nUtils";

export interface AfstandProps {
    afstandInMeter: number;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
}

export const Afstand: React.FC<AfstandProps> = (props) => {
    const {afstandInMeter, minimumFractionDigits, maximumFractionDigits} = props;

    const {language} = useTranslation();

    return (
        <>
            {Intl.NumberFormat(language, {style: "unit", unit: "kilometer", minimumFractionDigits, maximumFractionDigits}).format(afstandInMeter / 1000)}
        </>
    );
};
