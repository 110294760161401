import {
    BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_FAILURE,
    BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_REQUEST,
    BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_SUCCESS,
    BULK_UPLOAD_DAG_PLANNINGEN_FAILURE,
    BULK_UPLOAD_DAG_PLANNINGEN_REQUEST,
    BULK_UPLOAD_DAG_PLANNINGEN_SUCCESS,
    DAG_PLANNING_CAPACITEIT_PROJECTION_FAILURE,
    DAG_PLANNING_CAPACITEIT_PROJECTION_REQUEST,
    DAG_PLANNING_CAPACITEIT_PROJECTION_SUCCESS,
    FETCH_DAG_PLANNING_BEZOEKEN_FAILURE,
    FETCH_DAG_PLANNING_BEZOEKEN_REQUEST,
    FETCH_DAG_PLANNING_BEZOEKEN_SUCCESS,
    FETCH_DAG_PLANNINGEN_FAILURE,
    FETCH_DAG_PLANNINGEN_REQUEST,
    FETCH_DAG_PLANNINGEN_SUCCESS,
    FETCH_GEPLAND_BEZOEK_BY_ID_FAILURE,
    FETCH_GEPLAND_BEZOEK_BY_ID_REQUEST,
    FETCH_GEPLAND_BEZOEK_BY_ID_SUCCESS,
    FETCH_OPDRACHT_BY_ID_FAILURE,
    FETCH_OPDRACHT_BY_ID_REQUEST,
    FETCH_OPDRACHT_BY_ID_SUCCESS,
    FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_FAILURE,
    FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_REQUEST,
    FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_SUCCESS,
    FETCH_PRESTATIES_BY_OPDRACHTID_FAILURE,
    FETCH_PRESTATIES_BY_OPDRACHTID_REQUEST,
    FETCH_PRESTATIES_BY_OPDRACHTID_SUCCESS,
} from "./constants";
import merge from 'lodash.merge';
import {
    DagPlanningCapaciteitProjectionModel,
    DagPlanningModel,
    GeplandBezoekModel,
    PlanningActionTypes,
    PlanningState,
    PrestatieModel,
    PrestatieVerwachteDuurtijdPerMaandDict,
    PrestatieVerwachteDuurtijdProjectionModel
} from "./types";
import {RootState} from "../reducers";
import {EntityDict, paginationKey, SortOrder} from "../support/pagination";
import {formatDateIso, formatMonth} from "../../helpers/DateUtils";
import {combineHandlerBasedReducers} from "../support/reducers";
import {BezoekAanvraagModel} from "../bezoekaanvraag/types";

const INITIAL_STATE: PlanningState = {
    prestatiesByOpdrachtId: {},
    dagPlanningenByMedewerkerIdAndDatum: {},
    geplandeBezoekenByDagPlanningId: {},
    entities: {
        opdracht: {},
        prestatie: {},
        geplandBezoek: {},
        dagPlanning: {},
    },
    projections: {
        bezoekenPerMedewerkerPerDag: {},
        prestatieVerwachteDuurtijdPerMaand: {},
        dagPlanningCapaciteit: [],
    },
    loading: false,
    error: undefined,
};


// indexers

function indexPrestatiesByOpdrachtId(prestaties: Array<PrestatieModel>) {
    return prestaties.reduce((acc, prestatie) => {
        if (acc[prestatie.opdracht.id]) {
            return {
                ...acc,
                [prestatie.opdracht.id]: [...acc[prestatie.opdracht.id], prestatie.id]
            }
        } else {
            return {
                ...acc,
                [prestatie.opdracht.id]: [prestatie.id]
            }
        }
    }, {});
}

function indexPrestatiesById(prestaties: Array<PrestatieModel>) {
    return prestaties.reduce((acc, prestatie) => ({
        ...acc,
        [prestatie.id]: prestatie
    }), {});
}

function indexPerMaandEnType(entries: Array<PrestatieVerwachteDuurtijdProjectionModel>) {
    return entries.reduce((acc, entry) => {
        const datumKey = formatMonth(entry.maand);
        if (acc[datumKey]) {
            return {
                ...acc,
                [datumKey]: {
                    ...acc[datumKey],
                    [entry.type]: [...(acc[datumKey][entry.type] ? acc[datumKey][entry.type] : []), entry]
                }
            }
        } else {
            return {
                ...acc,
                [datumKey]: {
                    [entry.type]: [entry]
                }
            }
        }
    }, {});
}

const rootPlanningReducer = (state: PlanningState = INITIAL_STATE, action: PlanningActionTypes): PlanningState => {

    switch (action.type) {

        case FETCH_OPDRACHT_BY_ID_REQUEST:
            return {...state, loading: true};
        case FETCH_OPDRACHT_BY_ID_SUCCESS:
            return merge({}, state,
                {
                    entities: {
                        opdracht: {
                            [action.payload.id]: action.payload
                        }
                    },
                    loading: false,
                    error: undefined,
                });
        case FETCH_OPDRACHT_BY_ID_FAILURE:
            return {...state, error: action.payload, loading: false};

        case BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_REQUEST:
            return {...state, loading: true};
        case BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_SUCCESS:
            return {...state, loading: false, error: undefined};
        case BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_FAILURE:
            return {...state, error: action.payload, loading: false};

        case FETCH_PRESTATIES_BY_OPDRACHTID_REQUEST:
            return {...state, loading: true};
        case FETCH_PRESTATIES_BY_OPDRACHTID_SUCCESS:
            return merge({}, state,
                {
                    prestatiesByOpdrachtId: indexPrestatiesByOpdrachtId(action.payload as Array<PrestatieModel>),
                    entities: {
                        prestatie: indexPrestatiesById(action.payload as Array<PrestatieModel>),
                    },
                    loading: false,
                    error: undefined,
                });
        case FETCH_PRESTATIES_BY_OPDRACHTID_FAILURE:
            return {...state, error: action.payload, loading: false};


        case FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_REQUEST:
            return {...state, loading: true};
        case FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_SUCCESS:
            return {
                ...state,
                projections: {
                    ...state.projections,
                    prestatieVerwachteDuurtijdPerMaand: indexPerMaandEnType(action.payload)
                },
                loading: false,
                error: undefined
            };
        case FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_FAILURE:
            return {...state, error: action.payload, loading: false};

        case FETCH_GEPLAND_BEZOEK_BY_ID_REQUEST:
            return {...state, loading: true};
        case FETCH_GEPLAND_BEZOEK_BY_ID_SUCCESS:
            return merge({}, state,
                {
                    entities: {
                        geplandBezoek: {
                            [action.payload.id]: {
                                ...action.payload,

                            },
                        },
                    },
                    loading: false,
                    error: undefined,
                });
        case FETCH_GEPLAND_BEZOEK_BY_ID_FAILURE:
            return {...state, error: action.payload, loading: false};

        case FETCH_DAG_PLANNINGEN_REQUEST:
            return {...state, loading: true};
        case FETCH_DAG_PLANNINGEN_SUCCESS:
            const dagPlanningen: Array<DagPlanningModel> = action.payload;

            const fetchedDagPlanningenDict = dagPlanningen.reduce((result, dagPlanning) => {
                result[dagPlanning.id] = dagPlanning;
                return result;
            }, {});

            const alleDagPlanningenDict = {
                ...state.entities.dagPlanning,
                ...fetchedDagPlanningenDict,
            }

            const indexDagPlanningenByMedewerkerIdAndDatum = (dagPlanningen: EntityDict<DagPlanningModel>) => {
                return Object.values(dagPlanningen).reduce((result, dagPlanning) => {
                        if (!result[dagPlanning.medewerker.id]) {
                            result[dagPlanning.medewerker.id] = {};
                        }
                        result[dagPlanning.medewerker.id][formatDateIso(dagPlanning.datum)] = dagPlanning.id;
                        return result;
                    },
                    {})
            };

            return {
                ...state,
                entities: {
                    ...state.entities,
                    dagPlanning: alleDagPlanningenDict
                },
                dagPlanningenByMedewerkerIdAndDatum: indexDagPlanningenByMedewerkerIdAndDatum(alleDagPlanningenDict),
                loading: false,
                error: undefined
            };
        case FETCH_DAG_PLANNINGEN_FAILURE:
            return {...state, error: action.payload, loading: false};
        case FETCH_DAG_PLANNING_BEZOEKEN_REQUEST:
            return {...state, loading: true};
        case FETCH_DAG_PLANNING_BEZOEKEN_SUCCESS:
            const bezoeken: Array<BezoekAanvraagModel> = action.payload;

            const fetchedBezoekenDict = bezoeken.reduce((result, bezoek) => {
                result[bezoek.id] = bezoek;
                return result;
            }, {});

            return {
                ...state,
                entities: {
                    ...state.entities,
                    geplandBezoek: {
                        ...state.entities.geplandBezoek,
                        ...fetchedBezoekenDict,
                    }
                },
                geplandeBezoekenByDagPlanningId: {
                    ...state.geplandeBezoekenByDagPlanningId,
                    [action.meta.dagPlanningId]: bezoeken.map(bezoek => bezoek.id),
                },
                loading: false,
                error: undefined
            };
        case FETCH_DAG_PLANNING_BEZOEKEN_FAILURE:
            return {...state, error: action.payload, loading: false};
        case BULK_UPLOAD_DAG_PLANNINGEN_REQUEST:
            return {...state, loading: true};
        case BULK_UPLOAD_DAG_PLANNINGEN_SUCCESS:
            return {...state, loading: false, error: undefined};
        case BULK_UPLOAD_DAG_PLANNINGEN_FAILURE:
            return {...state, error: action.payload, loading: false};

        case DAG_PLANNING_CAPACITEIT_PROJECTION_REQUEST:
            return {...state, loading: true};
        case DAG_PLANNING_CAPACITEIT_PROJECTION_SUCCESS:
            return {
                ...state,
                projections: {
                    ...state.projections,
                    dagPlanningCapaciteit: action.payload
                },
                loading: false,
                error: undefined
            };
        case DAG_PLANNING_CAPACITEIT_PROJECTION_FAILURE:
            return {...state, error: action.payload, loading: false};

        default:
            return {...state};
    }
};


export const Planning = combineHandlerBasedReducers(INITIAL_STATE, [
    rootPlanningReducer
]);

//Selectors

export const selectPrestatiesByOpdrachtId = (state: RootState, opdrachtId): PrestatieModel[] | undefined => {
    if (state.Planning.prestatiesByOpdrachtId[opdrachtId]) {
        return state.Planning.prestatiesByOpdrachtId[opdrachtId].map(prestatieId => state.Planning.entities.prestatie[prestatieId]);
    }
}

export const searchBezoekAanvraagByPrestatieIdPagination = {
    pageNumber: 1,
    pageSize: 100,
    sortField: 'id',
    sortOrder: SortOrder.asc
};

export const selectBezoekAanvraagIdByPrestatieId = (state: RootState, prestatieId: string): string[] | null => {
    const pagination = state.Planning.bezoekAanvraagPagination[paginationKey({prestatieId}, searchBezoekAanvraagByPrestatieIdPagination)];
    if (!pagination) {
        return null;
    }

    const rawPage = state.Planning.bezoekAanvraagPagination[paginationKey({prestatieId}, searchBezoekAanvraagByPrestatieIdPagination)].pages[searchBezoekAanvraagByPrestatieIdPagination.pageNumber];
    if (!rawPage) {
        return null;
    }

    if (rawPage.loading) {
        return null;
    }

    return rawPage.items;
}

export const selectPrestatieVerwachteDuurtijdPerMaand = (state: RootState): PrestatieVerwachteDuurtijdPerMaandDict => {
    return state.Planning.projections.prestatieVerwachteDuurtijdPerMaand;
};

export const selectGeplandBezoekById = (state: RootState, geplandBezoekId: string): GeplandBezoekModel | undefined => {
    return state.Planning.entities.geplandBezoek[geplandBezoekId];
}

export const selectDagPlanningCapaciteit = (state: RootState,): Array<DagPlanningCapaciteitProjectionModel> => {
    return state.Planning.projections.dagPlanningCapaciteit ? state.Planning.projections.dagPlanningCapaciteit : [];
};
