import {Pageable} from "../support/pagination";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../reducers";
import {useDeepMemo} from "./effect";
import {useSelector} from "react-redux";

interface FiltersAndPageableState {
    [Key: string]: FiltersAndPageable<any>;
}

const initialState = {} as FiltersAndPageableState;

export const filtersAndPageableSlice = createSlice({
    name: "filtersAndPageable",
    initialState,
    reducers: {
        updateFiltersAndPageable<FilterType>(state, action: PayloadAction<{ key: string, filtersAndPageable: FiltersAndPageable<FilterType> }>) {
            if (!state[action.payload.key]) {
                state[action.payload.key] = {};
            }

            const filterState = state[action.payload.key];

            filterState.filters = action.payload.filtersAndPageable.filters;
            filterState.pageable = action.payload.filtersAndPageable.pageable;

            if (action.payload.filtersAndPageable.activeQuickFilters) {
                filterState.activeQuickFilters = action.payload.filtersAndPageable.activeQuickFilters;
            }
        },
    },
});

export const {updateFiltersAndPageable} = filtersAndPageableSlice.actions;

export const selectFiltersAndPageable = <FilterType, >(state: RootState, key: String, defaultFiltersAndPageable: FiltersAndPageable<FilterType>): FiltersAndPageable<FilterType> => {
    return state.filtersAndPageable[key] || defaultFiltersAndPageable;
};

export const useSelectFiltersAndPageable = <FilterType, >(key: string, defaultFiltersAndPageable: FiltersAndPageable<FilterType>): FiltersAndPageable<FilterType> => {
    const state = useSelector((state: RootState) => state.filtersAndPageable[key] || defaultFiltersAndPageable);

    return useDeepMemo(() => state, [state]);
};

export interface FiltersAndPageable<FilterType> {
    filters: FilterType;
    pageable: Pageable;

    activeQuickFilters?: Record<string, string | undefined>;
}
