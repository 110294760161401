import * as React from "react";
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import {OpdrachtType, OpdrachtTypeCountModel, Skill} from "../../../redux/planning/types";
import {KlantModel} from "../../../redux/klant/types";
import {Pageable, SortOrder} from "../../../redux/support/pagination";
import TextFilter from "../overzicht/TextFilter";
import FilteredAndPagedTable, {FilterField, QuickFilterFieldCreator} from "../overzicht/FilteredAndPagedTable";
import {TableColumn} from "../overzicht/SimpleTable";
import BezoekAanvraagStatusBadge from "./BezoekAanvraagStatusBadge";
import Datum from "../Datum";
import VanTotTijdstip from "../VanTotTijdstip";
import CheckFilter from "../overzicht/CheckFilter";
import Config from "../../../helpers/Config";
import DateFilter from "../overzicht/DateFilter";
import SkillBadge from "../skill/SkillBadge";
import {QuickFilter} from "../QuickFilter";
import {MultipleChoiceFilter} from "../overzicht/MultipleChoiceFilter";
import moment from "moment";
import OpdrachtTypesCountPills from "../opdracht/OpdrachtTypesCountPills";
import {
    BezoekAanvraagFilters,
    BezoekAanvraagModel,
    BezoekAanvraagOverzichtModel,
    BezoekAanvraagPlanningModel,
    BezoekAanvraagPrestatieSamenvattingModel,
    BezoekAanvraagStatus
} from "../../../redux/bezoekaanvraag/types";
import {Page} from "../../../redux/support/pagination/types";
import {KlantVerzendAdresTableColumn} from "../KlantVerzendAdresTableColumn";
import {Translation} from "react-i18next";
import {useTranslation} from "../../../helpers/i18nUtils";


const BezoekAanvraagGroepIcon = (
    <OverlayTrigger placement="right"
                    delay={{show: 250, hide: 400}}
                    overlay={(
                        <Tooltip id="prestatie-group-icon-tooltip">
                            <Translation>{t => t("planning:BezoekAanvraagGroepIcon.deel-van-serie-opeenvolgende-bezoekaanvragen", "Deze bezoekaanvraag is deel van een serie van opeenvolgende bezoekaanvragen.")}</Translation>
                        </Tooltip>)
                    }>
        <i className="mdi mdi-car-multiple text-info"/>
    </OverlayTrigger>
);


export const BezoekAanvraagNrColumn: TableColumn<BezoekAanvraagOverzichtModel | BezoekAanvraagModel, BezoekAanvraagStatus> = {
    dataField: 'nummer',
    text: <Translation>{t => t("algemeen:Labels.nr", "Nr.")}</Translation>,
    sort: true,
    formatter: (cell, row, rowIndex, extraData) => <span><strong>{cell}</strong></span>,
};

export const BezoekAanvraagStatusColumn: TableColumn<BezoekAanvraagOverzichtModel | BezoekAanvraagModel, BezoekAanvraagStatus> = {
    dataField: 'status',
    text: <Translation>{t => t("algemeen:Labels.status", "Status")}</Translation>,
    formatter: (cell, row, rowIndex, extraData) => <BezoekAanvraagStatusBadge status={cell}/>,
    sort: true,
};

export const BezoekAanvraagPrestatieColumn: TableColumn<BezoekAanvraagOverzichtModel, number> = {
    dataField: 'prestatieCount',
    text: <Translation>{t => t("algemeen:Labels.prestatie", "Prestatie")}</Translation>,
    formatter: (prestatieCount, row, rowIndex, extraData) => {

        return <span><Translation>{t => t("planning:BezoekAanvraagPrestatieColumn.prestaties", {
            defaultValue_one: "{{count}} prestatie",
            defaultValue_other: "{{count}} prestaties",
            count: prestatieCount
        })}</Translation> {' '}
            {row.heeftGroep && BezoekAanvraagGroepIcon}</span>;
    },
    sort: false,
};

export const BezoekAanvraagSkillsColumn: TableColumn<BezoekAanvraagOverzichtModel, Array<Skill>> = {
    dataField: 'skills',
    text: <Translation>{t => t("algemeen:Labels.skills", "Skills")}</Translation>,
    formatter: skills => <span>{skills?.concat()?.sort()?.map((skill, idx) => <><SkillBadge key={idx}
                                                                                            skill={skill}/> </>)}</span>,
    sort: false,
};

export const BezoekAanvraagPrestatieSamenvattingColumn: TableColumn<BezoekAanvraagOverzichtModel, Array<BezoekAanvraagPrestatieSamenvattingModel>> = {
    dataField: 'prestatieSamenvatting',
    text: <Translation>{t => t("Labels.prestaties", "Prestaties")}</Translation>,
    formatter: (samenvatting, bezoekAanvraag) => {
        const opdrachtTypesCount = samenvatting.reduce((acc, item) => {
            acc[item.opdrachtType] = item.aantal;
            return acc;
        }, {}) as OpdrachtTypeCountModel;

        return (
            <>
                {bezoekAanvraag.heeftGroep && <>{BezoekAanvraagGroepIcon}&nbsp;</>}
                <OpdrachtTypesCountPills opdrachtTypesCount={opdrachtTypesCount}/>
            </>
        );
    },
    sort: false,
};


export const BezoekAanvraagGewensteDatumColumn: TableColumn<BezoekAanvraagOverzichtModel | BezoekAanvraagModel, Date> = {
    dataField: 'vanDatum',
    text: <Translation>{t => t("planning:Labels.gewenste-datum", "Gewenste datum")}</Translation>,
    formatter: (datum, bezoekAanvraag, rowIndex, extraData) =>
        <React.Fragment>
            {bezoekAanvraag.vanDatum &&
                <span>
                    <Datum datum={bezoekAanvraag.vanDatum} showTime={false}/>
                    {bezoekAanvraag.vanDatum !== bezoekAanvraag.totDatum && <React.Fragment>
                        {' '}- <Datum datum={bezoekAanvraag.totDatum} showTime={false}/> </React.Fragment>}
                    {' '}<Badge
                    variant={"success"}><Translation>{t => t("planning:BezoekAanvraagGewensteDatumColumn.aangepast", "Aangepast")}</Translation></Badge> <br/>
                    <span className="text-muted"><VanTotTijdstip van={bezoekAanvraag.vanTijdstip}
                                                                 tot={bezoekAanvraag.totTijdstip}/></span>
                </span>
            }
            {!bezoekAanvraag.vanDatum &&
                <span>
                        <Datum datum={bezoekAanvraag.prestatieVanDatum} showTime={false}/>
                    {bezoekAanvraag.prestatieVanDatum !== bezoekAanvraag.prestatieTotDatum &&
                        <React.Fragment> - <Datum datum={bezoekAanvraag.prestatieTotDatum} showTime={false}/>
                        </React.Fragment>}

                    </span>
            }
        </React.Fragment>,
    sort: true,
};

export const BezoekAanvraagGecommuniceerdeDatumColumn: TableColumn<BezoekAanvraagOverzichtModel | BezoekAanvraagModel, Date> = {
    dataField: "gecommuniceerdeDatums",
    text: <Translation>{t => t("planning:Labels.gecommuniceerde-datums", "Gecommuniceerde datums")}</Translation>,
    formatter: (datum, bezoekAanvraag, rowIndex, extraData) => {
        return (
            <>
                {bezoekAanvraag.gecommuniceerdeDatums.map(datum =>
                    <span>
                        <div><Datum datum={datum} showTime={false}/></div>
                    </span>
                )}

                {bezoekAanvraag.gecommuniceerdeDatums.length > 0 &&
                    <span className="text-muted"><VanTotTijdstip van={bezoekAanvraag.vanTijdstip}
                                                                 tot={bezoekAanvraag.totTijdstip}/></span>}
                {!bezoekAanvraag.gecommuniceerdeDatums &&
                    <i className="text-danger mdi mdi-close-circle"/>
                }
            </>
        );
    },
    sort: true
};

export const BezoekAanvraagPlanningColumn: TableColumn<BezoekAanvraagOverzichtModel | BezoekAanvraagModel, BezoekAanvraagPlanningModel[]> = {
    dataField: 'planning',
    text: <Translation>{t => t("algemeen:Labels.planning", "Planning")}</Translation>,
    formatter: (planning, bezoekAanvraag, rowIndex, extraData) =>
        <React.Fragment>
            {!!planning.length && (
                <div>
                    <div><Datum datum={planning[0]?.datum} showTime={false}/></div>
                    <span className="text-muted">
                        <VanTotTijdstip van={planning[0]?.van} tot={planning[0]?.tot}/>
                    </span>
                    {planning.length > 1 && <div><small><Translation>{t => t("planning:ander-moment", {
                        defaultValue_one: "+ {{count}} ander moment",
                        defaultValue_other: "+ {{count}} andere momenten",
                        count: planning.length - 1
                    })}</Translation></small></div>}
                </div>
            )}
        </React.Fragment>,
    sort: false,
};

const bezoekAanvraagOverzichtNaarKlantModel = (bezoekAanvraag: BezoekAanvraagOverzichtModel) => ({
    bedrijfId: bezoekAanvraag.klantBedrijfId,
    nr: bezoekAanvraag.klantNr,
    naam: bezoekAanvraag.klantNaam,
    verwijderd: bezoekAanvraag.klantVerwijderd
});

const bezoekAanvraagOverzichtNaarVerzendAdresModel = (bezoekAanvraag: BezoekAanvraagOverzichtModel) => ({
    code: bezoekAanvraag.verzendAdresCode,
    naam: bezoekAanvraag.verzendAdresNaam,
    verwijderd: false,
    adres: {
        adres: bezoekAanvraag.verzendAdresAdres,
        postcode: bezoekAanvraag.verzendAdresPostcode,
        plaats: bezoekAanvraag.verzendAdresPlaats,
        landCode: bezoekAanvraag.verzendAdresLand,
        latitude: 0,
        longitude: 0
    }
});

export const BezoekAanvraagKlantColumn: TableColumn<BezoekAanvraagOverzichtModel, KlantModel> =
    {
        dataField: '',
        text: <Translation>{t => t("algemeen:Labels.klant-slash-verzendadres", "Klant / Verzendadres")}</Translation>,
        formatter: (_, bezoekAanvraag: BezoekAanvraagOverzichtModel) => (
            <KlantVerzendAdresTableColumn
                klant={bezoekAanvraagOverzichtNaarKlantModel(bezoekAanvraag)}
                verzendAdres={bezoekAanvraagOverzichtNaarVerzendAdresModel(bezoekAanvraag)}
            />
        ),
        sort: false
    };

export const BezoekAanvraagGroepColumn: TableColumn<BezoekAanvraagOverzichtModel, boolean> = {
    dataField: 'heeftGroep',
    text: <Translation>{t => t("planning:Labels.in-serie", "In serie")}</Translation>,
    formatter: (cell, row, rowIndex, extraData) => <span>{cell ? 'ja' : 'nee'}</span>,
    sort: false,
};

const defaultColumns = [BezoekAanvraagStatusColumn, BezoekAanvraagPrestatieColumn, BezoekAanvraagGewensteDatumColumn];

export const BezoekAanvraagEnkelManueelPlannenFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: 'enkelManueelPlannen',
    // label: '',
    component: <CheckFilter value={filterValues.enkelManueelPlannen}
                            label={
                                <Translation>{t => t("planning:Labels.enkel-manueel-inplannen", "Enkel manueel inplannen")}</Translation>}
                            onChange={value => handleUpdateFilter('enkelManueelPlannen', value)}/>
});

export const BezoekAanvraagDeelVanGroepFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: 'deelVanGroep',
    // label: '',
    component: <CheckFilter value={filterValues.deelVanGroep}
                            label={
                                <Translation>{t => t("planning:Labels.deel-van-serie", "Deel van serie")}</Translation>}
                            onChange={value => handleUpdateFilter('deelVanGroep', value)}/>
});

export const BezoekAanvraagManueelInTePlannenFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: 'manueelInTePlannen',
    // label: '',
    component: <CheckFilter value={filterValues.manueelInTePlannen}
                            label={
                                <Translation>{t => t("planning:Labels.manueel-in-te-plannen", "Manueel in te plannen")}</Translation>}
                            onChange={value => handleUpdateFilter('manueelInTePlannen', value)}/>
});

export const BezoekAanvraagStatusFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: 'statusIn',
    label: <Translation>{t => t("planning:Labels.status", "Status")}</Translation>,
    component:
        <div style={{minWidth: 300}}>
            <MultipleChoiceFilter value={filterValues.statusIn}
                                  onChange={value => {
                                      handleUpdateFilter('statusIn', value.map((item) => item.value))
                                  }}
                                  options={Object.keys(BezoekAanvraagStatus).map(status => ({
                                      label: Config.labels[status],
                                      value: status
                                  }))}
            />
        </div>
});

export const BezoekAanvraagOpdrachtTypeFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: 'opdrachtTypeIn',
    label: <Translation>{t => t("algemeen:Labels.manueel-in-te-plannen", "Manueel in te plannen")}</Translation>,
    component:
        <div style={{minWidth: 300}}>
            <MultipleChoiceFilter value={filterValues.opdrachtTypeIn}
                                  onChange={value => {
                                      handleUpdateFilter('opdrachtTypeIn', value.map((item) => item.value))
                                  }}
                                  options={Object.keys(OpdrachtType).map(type => ({
                                      label: Config.labels[type],
                                      value: type
                                  }))}
            />
        </div>
});

export const BezoekAanvraagVanDatumFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: 'vanDatum',
    label: <Translation>{t => t("planning:BezoekAanvraagTotDatumFilter.gewenst-van", "Gewenst van")}</Translation>,
    component: <DateFilter value={filterValues.vanDatum}
                           onChange={value => handleUpdateFilter('vanDatum', value)}/>
});

export const BezoekAanvraagTotDatumFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: 'totDatum',
    label: <Translation>{t => t("planning:BezoekAanvraagTotDatumFilter.tot", "tot")}</Translation>,
    component: <DateFilter value={filterValues.totDatum}
                           onChange={value => handleUpdateFilter('totDatum', value)}/>
});

export const BezoekAanvraagGecommuniceerdeDatumVanFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: "gecommuniceerdeDatumVan",
    label:
        <Translation>{t => t("planning:BezoekAanvraagGecommuniceerdeDatumVanFilter.gecommuniceerde-datum-van", "Gecommuniceerde datum van")}</Translation>,
    component: <DateFilter value={filterValues.gecommuniceerdeDatumVan}
                           onChange={value => handleUpdateFilter("gecommuniceerdeDatumVan", value)}/>
});

export const BezoekAanvraagGecommuniceerdeDatumTotFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: "gecommuniceerdeDatumTot",
    label: <Translation>{t => t("planning:BezoekAanvraagGecommuniceerdeDatumTotFilter.tot", "tot")}</Translation>,
    component: <DateFilter value={filterValues.gecommuniceerdeDatumTot}
                           onChange={value => handleUpdateFilter("gecommuniceerdeDatumTot", value)}/>
});

export const BezoekAanvraagGecommuniceerdeDatumOpFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: "gecommuniceerdeDatumOp",
    label: <Translation>{t => t("planning:Labels.gecommuniceerd-op", "Gecommuniceerd op")}</Translation>,
    component: <DateFilter value={filterValues.gecommuniceerdeDatumVan}
                           onChange={value => {
                               handleUpdateFilter("gecommuniceerdeDatumVan", value)
                               handleUpdateFilter("gecommuniceerdeDatumTot", value)
                           }
                           }/>
});

export const BezoekAanvraagPostcodeFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: 'postcode',
    label: <Translation>{t => t("algemeen:Labels.postcode", "Postcode")}</Translation>,
    component: <TextFilter value={filterValues.postcode}
                           placeholderI18nKey="algemeen:Placeholders.postcode"
                           onChange={value => handleUpdateFilter('postcode', value)}/>
});

export const BezoekAanvraagMogelijkMetSkillsFilter: FilterField<BezoekAanvraagFilters> = (filterValues, handleUpdateFilter) => ({
    naam: "mogelijkMetSkills",
    label: <Translation>{t => t("planning:Labels.mogelijk-met-skills", "Mogelijk met skills")}</Translation>,
    component:
        <div style={{minWidth: 200, maxWidth: 400}}>
            <MultipleChoiceFilter value={filterValues.mogelijkMetSkills}
                                  onChange={value => {
                                      handleUpdateFilter("mogelijkMetSkills", value.map((item) => item.value))
                                  }}
                                  options={Object.keys(Skill).map(status => ({
                                      label: Config.labels[status],
                                      value: status
                                  }))}
            />
        </div>
});

const AchterstandQuickFilter: QuickFilterFieldCreator<BezoekAanvraagFilters> = (updateFilter) => ({
    name: "achterstand",
    group: "quick-datums",
    render: (filters, {active, onClick}) => <QuickFilter active={active}
                                                         onClick={onClick}><Translation>{t => t("planning:Labels.achterstand", "Achterstand")}</Translation></QuickFilter>,
    onActivated() {
        updateFilter("totDatum", moment().startOf("day").toDate());
        updateFilter("statusIn", [BezoekAanvraagStatus.NIEUW, BezoekAanvraagStatus.TE_PLANNEN]);
    },
    onDeactivated() {
        updateFilter("totDatum", undefined);
        updateFilter("statusIn", undefined);
    }
});

const DezeWeekPlannenQuickFilter: QuickFilterFieldCreator<BezoekAanvraagFilters> = (updateFilter) => ({
    name: "dezeweekplannen",
    group: "quick-datums",
    render: (filters, {active, onClick}) => <QuickFilter active={active}
                                                         onClick={onClick}><Translation>{t => t("planning:Labels.deze-week-plannen", "Deze week plannen")}</Translation></QuickFilter>,
    onActivated() {
        updateFilter("vanDatum", moment().startOf("day").toDate());
        updateFilter("totDatum", moment().add(1, "week").toDate());
        updateFilter("statusIn", [BezoekAanvraagStatus.NIEUW, BezoekAanvraagStatus.TE_PLANNEN]);
    },
    onDeactivated() {
        updateFilter("vanDatum", undefined);
        updateFilter("totDatum", undefined);
        updateFilter("statusIn", undefined);
    }
});

const VerkeerdGecomunniceerdQuickFilter: QuickFilterFieldCreator<BezoekAanvraagFilters> = (updateFilter) => ({
    name: "verkeerdgecommuniceerd",
    render: (filters, {active, onClick}) => <QuickFilter active={active}
                                                         onClick={onClick}><Translation>{t => t("planning:Labels.verkeerd-gecommuniceerd", "Verkeerd gecommuniceerd")}</Translation></QuickFilter>,
    onActivated() {
        updateFilter("nietGeplandOfBevrorenOpAndereDatum", true);
        updateFilter("gecommuniceerdeDatumVan", new Date());
    },
    onDeactivated() {
        updateFilter("nietGeplandOfBevrorenOpAndereDatum", undefined);
        updateFilter("gecommuniceerdeDatumVan", undefined);
    }
});

const ManueelPlannenQuickFilter: QuickFilterFieldCreator<BezoekAanvraagFilters> = (updateFilter) => ({
    name: "manueelplannen",
    render: (filters, {active, onClick}) => <QuickFilter active={active}
                                                         onClick={onClick}><Translation>{t => t("planning:Labels.manueel-plannen", "Manueel plannen")}</Translation></QuickFilter>,
    onActivated() {
        updateFilter("enkelManueelPlannen", true);
        updateFilter("statusIn", [BezoekAanvraagStatus.NIEUW, BezoekAanvraagStatus.TE_PLANNEN]);
    },
    onDeactivated() {
        updateFilter("enkelManueelPlannen", undefined);
        updateFilter("statusIn", undefined);
    }
});

const defaultQuickFilters = [AchterstandQuickFilter, DezeWeekPlannenQuickFilter, ManueelPlannenQuickFilter, VerkeerdGecomunniceerdQuickFilter];
const defaultPrimaryFilterFields = [BezoekAanvraagVanDatumFilter, BezoekAanvraagTotDatumFilter];

export interface BezoekAanvraagOverzichtTableProps {
    page: Page<BezoekAanvraagOverzichtModel>;
    filters: BezoekAanvraagFilters;
    activeQuickFilters?: Record<string, string | undefined>;
    onQuickFiltersChanged?: (quickFilters: Record<string, string | undefined>) => any;
    pageable: Pageable;
    loadData: (filters: BezoekAanvraagFilters, pageable: Pageable) => void;
    onRowClicked?: (bezoekAanvraag: BezoekAanvraagOverzichtModel) => void;

    selectionMode?: 'single' | 'multiple';
    selected?: Array<string>;
    onRowSelected?: (bezoekAanvraag: BezoekAanvraagOverzichtModel, selected: boolean) => void;

    quickFilters?: QuickFilterFieldCreator<BezoekAanvraagFilters>[];
    primaryFilterFields?: Array<FilterField<BezoekAanvraagFilters>>;
    secondaryFilterFields?: FilterField<BezoekAanvraagFilters>[];
    columns?: Array<TableColumn<BezoekAanvraagOverzichtModel, any>>;
}

const BezoekAanvraagOverzichtTable = ({
                                          page,
                                          loadData,
                                          filters,
                                          activeQuickFilters,
                                          onQuickFiltersChanged,
                                          pageable,
                                          onRowClicked,

                                          selectionMode,
                                          selected,
                                          onRowSelected,

                                          quickFilters = defaultQuickFilters,
                                          primaryFilterFields = defaultPrimaryFilterFields,
                                          secondaryFilterFields,
                                          columns = defaultColumns,
                                      }: BezoekAanvraagOverzichtTableProps) => {
    const {t} = useTranslation("planning");

    const defaultSorted = [
        {
            dataField: "nummer",
            order: SortOrder.desc
        },
    ];

    const finalColumns = [
        {
            dataField: 'id',
            isKey: true,
            text: 'id',
            hidden: true,
            sort: false,
        },
        ...(columns)
    ];

    return (
        <FilteredAndPagedTable itemsLabel={t("BezoekAanvraagOverzichtTabel.bezoekaanvragen", "bezoekaanvragen")}
                               columns={finalColumns}
                               quickFilters={quickFilters}
                               initialQuickFilters={activeQuickFilters}
                               onQuickFiltersChanged={onQuickFiltersChanged}
                               primaryFilterFields={primaryFilterFields}
                               secondaryFilterFields={secondaryFilterFields}
                               page={page}
                               loadData={loadData}
                               onRowClicked={onRowClicked}
                               filters={filters}
                               pageable={pageable}
                               defaultSorted={defaultSorted}
                               selectionMode={selectionMode}
                               selected={selected}
                               onRowSelected={onRowSelected}
        />
    )
};

export default BezoekAanvraagOverzichtTable;

