import {ReduxAction_legacy} from "./types";
import {ApiError, ErrorInfo} from "../ui/types";
import {action, errorAction} from "./action";
import {STORE_UI_STATE} from "../ui/constants";

export const createFetchByIdActions = <TEntity, >(actionKeys: { REQUEST: string, SUCCESS: string, FAILURE: string }) => ({
    request:
        (id: string) => action<typeof actionKeys.REQUEST, { id: string }, any>(actionKeys.REQUEST, {id}, {
            loading: {classifier: id},
        }),
    success:
        (result: TEntity, id: string) => action(actionKeys.SUCCESS, result, {
            id,
            loading: {classifier: id},
        }),
    failure:
        (error: ErrorInfo, id: string) => errorAction(actionKeys.FAILURE, error, {
            id,
            loading: {classifier: id},
        }),
});

export const withLoading = (meta: any, classifier?: string) => ({
    ...meta,
    loading: classifier ? {classifier} : {},
});

export const createStoreUiStateAction = (uiStateKey: string, state: any) => action(STORE_UI_STATE, {}, {
    storeUIState: {
        key: uiStateKey,
        state
    }
});

export const createFetchQueryActions = <TEntity, TPayload, >(actionKeys: { REQUEST: string, SUCCESS: string, FAILURE: string }, classifierExtractor?: (payload: TPayload) => string) => ({
    request:
        (payload: TPayload, onSuccessCallback?: (entity: TEntity) => void, onFailureCallback?: (error: Error) => void, filters?: any, uiStateKey?: string) => action(actionKeys.REQUEST, payload, withLoading({
            onSuccessCallback,
            onFailureCallback,

            ...(uiStateKey && {
                storeUIState: {
                    key: uiStateKey,
                    state: {
                        filters
                    }
                },
            })
        }, classifierExtractor?.(payload))),
    success:
        (entity: TEntity, payload: TPayload) => action(actionKeys.SUCCESS, entity, withLoading({
            payload
        }, classifierExtractor?.(payload))),
    failure:
        (error: ErrorInfo, payload: TPayload) => errorAction(actionKeys.FAILURE, error, withLoading({
            payload,
        }, classifierExtractor?.(payload)))
});

// deprecated stuff
export function createActionsWithResult<TPayload, TResult, TAction extends ReduxAction_legacy<TAction['type']>>(types: [TAction['type'], TAction['type'], TAction['type']]):
    [
        (payload: TPayload) => TAction,
        (result: TResult, meta: TPayload) => TAction,
        (error: ApiError, meta: TPayload) => TAction
    ] {
    // TODO validate types ending with _REQUEST, _SUCCESS, _FAILURE

    return [
        (payload): TAction => ({
            type: types[0],
            payload,
        } as TAction),
        (result, meta): TAction => ({
            type: types[1],
            payload: result,
            meta,
        } as TAction),
        (error: ApiError, meta): TAction => ({
            type: types[2],
            payload: error,
            error: true,
            meta,
        } as TAction),
    ];
}

export function createActionsWithoutResult<TPayload, TAction extends ReduxAction_legacy<TAction['type']>>(types: [TAction['type'], TAction['type'], TAction['type']]):
    [
        (payload: TPayload) => TAction,
        (meta: TPayload) => TAction,
        (error: ApiError, meta: TPayload) => TAction
    ] {
    // TODO validate types ending with _REQUEST, _SUCCESS, _FAILURE

    return [
        (payload): TAction => ({
            type: types[0],
            payload,
        } as TAction),
        (meta): TAction => ({
            type: types[1],
            meta,
        } as TAction),
        (error: ApiError, meta): TAction => ({
            type: types[2],
            payload: error,
            error: true,
            meta,
        } as TAction),
    ];
}
