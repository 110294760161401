import {default as React} from "react";
import {hideModal} from "../../../../redux/ui/actions";
import {Button, Col, Form, FormGroup, ModalBody, Tab, Tabs} from "react-bootstrap";
import * as Yup from 'yup';
import {Formik} from "formik";
import {useDispatch} from 'react-redux';
import {DatumFormField} from "../../../../components/aqualex/form/DatumFormField";
import {SelectFormField} from "../../../../components/aqualex/form/SelectFormField";
import {TextAreaFormField} from "../../../../components/aqualex/form/TextAreaFormField";
import {InfoAlert} from "../../../../components/aqualex/Alerts";
import {Modal, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/aqualex/Modal";
import {useTranslation} from "../../../../helpers/i18nUtils";
import {Trans} from "react-i18next";

type GenereerOnderhoudServiceOrderOpdrachtenModalProps = {
    onBevestigHandler: (serviceOrderDatumVan: Date, serviceOrderDatumTot: Date, postcode: string, serviceOrderNrs: string[]) => void;
};

const postcodes = [
    {postcode: "", label: "Alle postcodes"},
    {postcode: "1", label: "1xxx: Brussel, Halle-Vilvoorde en Waals-Brabant"},
    {postcode: "2", label: "2xxx: provincie Antwerpen"},
    {postcode: "3", label: "3xxx: arrondissement Leuven en provincie Limburg"},
    {postcode: "4", label: "4xxx: provincie Luik"},
    {postcode: "5", label: "5xxx: provincie Namen"},
    {postcode: "6", label: "6xxx: Henegouwen (Oost) en Luxemburg (provincie)"},
    {postcode: "7", label: "7xxx: Henegouwen (West)"},
    {postcode: "8", label: "8xxx: West-Vlaanderen"},
    {postcode: "9", label: "9xxx: Oost-Vlaanderen"},
    ...(Array.from(Array(90).keys())
        .map(idx => (idx + 10))
        .map(idx => ({postcode: "" + idx, label: idx + "xx"})))
]

const GenereerOnderhoudServiceOrderOpdrachtenModal: React.FC<GenereerOnderhoudServiceOrderOpdrachtenModalProps> = ({onBevestigHandler}) => {

    const today = new Date();

    const {t} = useTranslation("planning");

    const schema = Yup.object({
        serviceOrderDatumVan: Yup.date()
            .required(t("Foutmeldingen.van-datum-is-verplicht", "Van datum is verplicht") as string),
        serviceOrderDatumTot: Yup.date()
            .required(t("Foutmeldingen.tot-datum-is-verplicht", "Tot datum is verplicht") as string)
            .when('serviceOrderDatumVan', {
                is: (vanDatum) => !!vanDatum,
                then: Yup.date()
                    .required(t("Foutmeldingen.tot-datum-is-verplicht", "Tot datum is verplicht") as string)
                    .min(Yup.ref('serviceOrderDatumVan'), t("Foutmeldingen.tot-datum-moet-na-van-datum-liggen", "Tot moet na van datum zijn") as string),
            }),
        postcode: Yup.string(),
        serviceOrderNrs: Yup.string(),
    });

    const dispatch = useDispatch();

    const onAnnuleer = () => {
        dispatch(hideModal());
    }

    const parseServiceOrderNrs = (s:string)=>{
        if(s) {
            const ttt =  s.split(/\s+/);
            console.log(ttt);
            return ttt;
        }

        return [];
    }

    return (
        <Modal show={true} onHide={onAnnuleer} size='lg' autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("GenereerOnderhoudServiceOrderOpdrachtenModal.opdrachten-genereren-uit-serviceorders", "Opdrachten genereren uit serviceorders")}</ModalTitle>
            </ModalHeader>

            <Formik validationSchema={schema}
                    onSubmit={(values) => {
                        dispatch(hideModal());
                        onBevestigHandler(values.serviceOrderDatumVan, values.serviceOrderDatumTot, values.postcode, parseServiceOrderNrs(values.serviceOrderNrs));
                    }}
                    initialValues={{
                        serviceOrderDatumVan: today,
                        serviceOrderDatumTot: today,
                        postcode: "",
                        serviceOrderNrs: "",
                    }}>
                {({
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                      isValid,
                      values,
                      errors,
                  }) => (
                    <Form noValidate>
                        <ModalBody>
                            <Tabs variant="pills" defaultActiveKey="zoeken" id="switch-selectie-manier-tabs"
                                  className="nav-justified nav-bordered mb-3">
                                <Tab eventKey="zoeken"
                                     title={t("GenereerOnderhoudServiceOrderOpdrachtenModal.zoeken", "Zoeken")}
                                     tabClassName="rounded-0 pt-2 pb-2">
                                    <Trans t={t}
                                           i18nKey="GenereerOnderhoudServiceOrderOpdrachtenModal.zoeken-hulp">
                                        <p>
                                            Deze functie genereert opdrachten uit serviceorders met:
                                        </p>

                                        <ul>
                                            <li>Serviceordersoort is "<strong>OND*</strong>" ("onderhouden initieel")
                                            </li>
                                            <li>Enkel de regels met herstelstatuscode "<strong>INITIEEL</strong>" worden
                                                verwerkt
                                                ("onderhouden initieel")
                                            </li>
                                            <li>Het veld <strong>Toegewezen gebruikers-id</strong> is leeg</li>
                                            <li>Het serviceorder is <strong>niet geblokkeerd</strong></li>
                                            <li>Zowel de klant, vezendadres als het serviceartikel / toestel worden
                                                correct
                                                teruggevonden
                                            </li>
                                            <li>Onderstaande extra filters voldoen</li>
                                        </ul>
                                    </Trans>
                                    <Form.Row>
                                        <FormGroup id='serviceOrderDatumVan' as={Col}>
                                            <DatumFormField name='serviceOrderDatumVan'
                                                            label={t("GenereerOnderhoudServiceOrderOpdrachtenModal.servicerorder-datum-van", 'Serviceorder datum van')}/>
                                        </FormGroup>

                                        <FormGroup id='serviceOrderDatumTot' as={Col}>
                                            <DatumFormField name='serviceOrderDatumTot'
                                                            label={t("GenereerOnderhoudServiceOrderOpdrachtenModal.serviceorder-datum-tot", "Serviceorder datum tot")}/>
                                        </FormGroup>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} id="postcode">
                                            <SelectFormField name="postcode"
                                                             label={t("GenereerOnderhoudServiceOrderOpdrachtenModal.postcode", "Postcode")}>
                                                {postcodes.map(postcode => <option key={postcode.postcode}
                                                                                   value={postcode.postcode}>{postcode.label}</option>)}
                                            </SelectFormField>
                                        </Form.Group>
                                    </Form.Row>
                                </Tab>
                                <Tab eventKey="opNr"
                                     title={t("Titels.op-nr", "Op nr")}
                                     tabClassName="rounded-0 pt-2 pb-2">
                                    <Trans t={t}
                                           i18nKey="GenereerOnderhoudServiceOrderOpdrachtenModal.genereer-op-nr-hulp">
                                        <p>
                                            Deze functie genereert opdrachten uit serviceorders met:
                                        </p>

                                        <ul>
                                            <li>Serviceordersoort is "<strong>OND*</strong>" ("onderhouden initieel")
                                            </li>
                                            <li>Enkel de regels met herstelstatuscode "<strong>INITIEEL</strong>" worden
                                                verwerkt
                                                ("onderhouden initieel")
                                            </li>
                                            <li>Het veld <strong>Toegewezen gebruikers-id</strong> is leeg</li>
                                            <li>Het serviceorder is <strong>niet geblokkeerd</strong></li>
                                            <li>Zowel de klant, vezendadres als het serviceartikel / toestel worden
                                                correct
                                                teruggevonden
                                            </li>
                                            <li>Onderstaande extra filters voldoen</li>
                                        </ul>
                                    </Trans>

                                    <Form.Row>
                                        <FormGroup id='serviceOrderNrs' as={Col}>
                                            <TextAreaFormField name="serviceOrderNrs"
                                                               label={t("GenereerOnderhoudServiceOrderOpdrachtenModal.service-order-nummers", "Service order nrs")}
                                                               rows={15}
                                                               maxLength={1024}
                                                               placeholder={t("GenereerOnderhoudServiceOrderOpdrachtenModal.service-order-nummers", "Service order nrs")}/>
                                        </FormGroup>
                                    </Form.Row>
                                </Tab>
                            </Tabs>

                            <InfoAlert>
                                <Trans t={t}
                                       i18nKey="GenereerOnderhoudServiceOrderOpdrachtenModal.info">
                                    Alle serviceorderregels waarvoor een opdracht wordt aangemaakt krijgen de
                                    herstelstatuscode <strong>AUTO_PLAN</strong> in Dynamics.
                                    Opdrachten worden niet dubbel aangemaakt. Het duurt enkele minuten voor deze
                                    opdrachten
                                    verschijnen.
                                </Trans>
                            </InfoAlert>

                        </ModalBody>
                        <ModalFooter>
                            <Button variant='light' onClick={onAnnuleer}>
                                {t("Buttons.annuleer", "Annuleer")}
                            </Button>{' '}
                            <Button disabled={!isValid}
                                    variant='primary'
                                    onClick={() => handleSubmit()}>
                                {t("Buttons.genereren", "Genereren")}</Button>
                        </ModalFooter>
                    </Form>)}
            </Formik>

        </Modal>
    );
};

export default GenereerOnderhoudServiceOrderOpdrachtenModal;
