import * as React from 'react';
import VerwijderdIndicator from './VerwijderdIndicator';
import { PartnerModel } from '../../redux/partner/types';

interface PartnerLabelProps {
    partner: Pick<PartnerModel, 'bedrijfId' | 'nr' | 'naam' | 'adres' | 'verwijderd'>;

    toonVerwijderd?: boolean;
}

export const PartnerLabel: React.FC<PartnerLabelProps> = ({partner, toonVerwijderd= true}) => (
    <>
        <strong>{partner.bedrijfId} {partner.nr}</strong> {partner.naam} {partner.adres} {toonVerwijderd && <VerwijderdIndicator verwijderd={partner.verwijderd}/>}
    </>
);

