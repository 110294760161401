import React from "react";
import Config from "../../../helpers/Config";
import {StompSessionProvider} from "react-stomp-hooks";
import {Client} from "@stomp/stompjs";
import {authAcquireTokenOrRedirect} from "../../../auth/authProvider";

export const WsStompSessionProvider: React.FC = (props) => {
    const beforeConnect = async function (this: Client) { // Geen arrow-function gebruiken om toegang te hebben tot this-context
        const user = await authAcquireTokenOrRedirect();

        const connectHeaders: Record<string, string> = {
            "X-Authorization": `Bearer ${user?.accessToken}`
        };

        this.connectHeaders = connectHeaders;
    };

    return (
        <StompSessionProvider url={Config.BACKOFFICE_WEBSOCKET_URL || "websocket-url-not-set"} beforeConnect={beforeConnect}>
            {props.children}
        </StompSessionProvider>
    );
};
