import {FilterField} from "../overzicht/FilteredAndPagedTable";
import TextFilter from "../overzicht/TextFilter";
import React from "react";
import {Translation} from "react-i18next";

export const KlantNaamFilter: FilterField<{ klantNaam?: string; }> = (filterValues, handleUpdateFilter) => ({
    naam: 'klantNaam',
    label: <Translation>{t => t("algemeen:Labels.klantnaam", "Klantnaam")}</Translation>,
    component: <TextFilter value={filterValues.klantNaam}
                           placeholderI18nKey="algemeen:Labels.klantnaam"
                           onChange={value => handleUpdateFilter('klantNaam', value)}/>
});
