import {authenticatedFetch} from "./api";
import streamSaver from 'streamsaver';
import contentDisposition from 'content-disposition';
import prettyBytes from "pretty-bytes";

// mitm.html en sw.js moeten in /public/streamsaver folder staan
streamSaver.mitm = '/streamsaver/mitm.html';

export interface FileDownload {
    naam: string;
    mediaType: string;
    content: ReadableStream<Uint8Array>;
}

export const getFileNaam = (contentDispositionHeader: string | null) => {
    if (!contentDispositionHeader) {
        return 'download';
    }

    const parsed = contentDisposition.parse(contentDispositionHeader);
    return parsed.parameters.filename || 'download';
};

export const mapFileResponse = (response) => {
    return {
        naam: getFileNaam(response.headers.get('Content-Disposition')),
        mediaType: response.headers.get('Content-Type') || 'application/octet-stream', //fallback
        content: response.body!,
    };
};

/**
 * download a file using the authenticated fetch
 * @param url
 * @param omschrijving
 * @param options
 */
export const downloadFile = (url, omschrijving, options: any = {}): Promise<FileDownload> => authenticatedFetch(url, omschrijving, options, response => {
    return {
        naam: getFileNaam(response.headers.get('Content-Disposition')),
        mediaType: response.headers.get('Content-Type') || 'application/octet-stream', //fallback
        content: response.body!,
    };
});

export const saveFile = (file: FileDownload) => {
    const fileStream = streamSaver.createWriteStream(file.naam);

    file.content.pipeTo(fileStream);
};

export const getReadableBytes = (bytes: number) => {
    return prettyBytes(bytes, {locale: "nl"});
};

//Legacy startDownload. FIXME: vervangen door downloadFile() en saveFile()
export const legacyStartDownload = (downloadUrl: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
}

export const blobToDataUri = (blob: Blob) => {
    return new Promise<string>((resolve) => {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
            if (event.target) {
                resolve(event.target.result as string);
            }
        };

        fileReader.readAsDataURL(blob);
    });
};
