import Config from "./Config";
import {downloadFile, FileDownload} from "./download";
import {formatDateIso} from "./DateUtils";

const BACKOFFICE_MAGAZIJN_API_BASE_URL = Config.BACKOFFICE_BASE_URL + "/api/magazijn";

export default class MagazijnRapportApi {

    static downloadMagazijnRapport(van: string, tot: string, medewerkerId: string): Promise<FileDownload> {
        const params = new URLSearchParams();

        params.append("van", formatDateIso(van));
        params.append("tot", formatDateIso(tot));
        params.append("medewerkerId", medewerkerId);

        const uri = BACKOFFICE_MAGAZIJN_API_BASE_URL + `/pickinglijst.xlsx?${params.toString()}`;

        return downloadFile(uri, "downloaden picking lijst in Excel-formaat");
    }
}
