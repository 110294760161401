import * as React from "react";
import {CSSProperties, useState} from "react";
import {Dropdown, Spinner} from "react-bootstrap";
import {DagPlanningAlignerenForm, DagPlanningAlignerenFormValues} from "./DagPlanningAlignerenForm";
import {useTranslation} from "../../../../helpers/i18nUtils";

export interface AligneerDropDownProps {
    loading?: boolean;
    onBevestig?: (form: DagPlanningAlignerenFormValues) => any;
}

export const AligneerDropDown: React.FC<AligneerDropDownProps> = (props) => {
    const {loading, onBevestig} = props;

    const {t} = useTranslation("planning");

    const [show, setShow] = useState(false);

    const onBevestigHandler = (values) => {
        setShow(false);
        onBevestig?.(values);
    };

    return (<Dropdown onToggle={setShow} show={show} className="ml-1 mb-1">
        <Dropdown.Toggle id="aligneer-dropdown" disabled={loading}>
            {loading && <Spinner animation="border" size="sm" className="mr-2"/>}
            <i className="mdi mdi-format-vertical-align-bottom mr-1"/>{t("Buttons.aligneer", "Aligneer")}
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} onBevestig={onBevestigHandler}/>
    </Dropdown>);
};

interface CustomMenuProps {
    style?: CSSProperties;
    className?: string;
    'aria-labelledby'?: string;
    onBevestig: (form: DagPlanningAlignerenFormValues) => any;
}

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef<HTMLDivElement, CustomMenuProps>(
    (props, ref) => {
        const {style, className, 'aria-labelledby': labeledBy, onBevestig} = props;

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <div className="m-1">
                    <DagPlanningAlignerenForm onBevestig={onBevestig}/>
                </div>
            </div>
        );
    },
);

