import {DOWNLOAD_FILE_BY_URI} from "./constants";
import {action, errorAction} from "../support/action";
import {FileDownload} from "../../helpers/download";
import {ErrorInfo} from "../ui/types";

export const downloadFileByUri = ({
    request:
        (uri: string, omschrijving?: string, onSuccessCallback?: () => void) => action<typeof DOWNLOAD_FILE_BY_URI.REQUEST, { uri: string, omschrijving?: string }, any>(
            DOWNLOAD_FILE_BY_URI.REQUEST,
            { uri, omschrijving },
            {
                loading: {classifier: uri},
                onSuccessCallback
            }
        ),
    success:
        (result: FileDownload, uri: string) => action(DOWNLOAD_FILE_BY_URI.SUCCESS, result, {
            uri,
            loading: {classifier: uri},
        }),
    failure:
        (error: ErrorInfo, uri: string) => errorAction(DOWNLOAD_FILE_BY_URI.FAILURE, error, {
            uri,
            loading: {classifier: uri},
        }),
});
