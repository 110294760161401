import React, {ReactNode} from "react";
import Select, {components} from 'react-select';
import Icon from "@mdi/react";
import {mdiCheck} from "@mdi/js";

interface Option {
    label: ReactNode;
    value: string;
}

export interface MultipleChoiceFilterProps {
    selectedOption?: Option[];
    value?: string[];
    onChange: (value: Option[]) => void;

    options: Option[];
    
    placeholder?: ReactNode;
}

export const MultipleChoiceFilter: React.FC<MultipleChoiceFilterProps & {hideSelectedOptions?: boolean; closeMenuOnSelect?: boolean; styles?: any; components?: any;}> = (props) => {
    const {selectedOption, value, onChange, options, placeholder = "", ...rest} = props;

    return (
        <Select
            isMulti
            className="form-control-light multi-select-scrollable-values"
            classNamePrefix="react-select"

            value={selectedOption || (value && options?.filter((item) => value.includes(item.value)))}
            options={options}
            onChange={value => onChange(value || [])}
            
            placeholder={placeholder}

            hideSelectedOptions={false} closeMenuOnSelect={false}
            components={{
                Option: (props) => {
                    return (
                        <components.Option {...props} className={`${props.className || ""} position-relative cursor-pointer`}>
                            {props.isSelected && (
                                <div className="position-absolute" style={{left: 3}}>
                                    <Icon path={mdiCheck} size={0.8} color="var(--success)"/>
                                </div>
                            )}
                            {props.label}
                        </components.Option>
                    );
                }
            }}

            {...rest}
        />
    )
};
