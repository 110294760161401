import * as i18next from "react-i18next";
import {useCallback, useEffect} from "react";
import {TFunction} from "i18next";
import moment from "moment";

export interface UseTranslationOptions {
    keyPrefix?: string;
}

export const useTranslation = (ns: string | string[] = "algemeen", options?: UseTranslationOptions) => {
    const {t, i18n} = i18next.useTranslation(ns, {
        keyPrefix: options?.keyPrefix
    });

    const changeLanguage = useCallback((locale: string) => {
        i18n.changeLanguage(locale);
    }, [i18n]);

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);

    return {
        t: t as TFunction<string | string[], string>,
        language: i18n.language,
        changeLanguage
    };
};

export const installTranslationKey = (key: string, defaultMessage: string) => {
    return key;
};
