import React from "react";
import {Modal, ModalBody, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useHideModal} from "../../../redux/ui/hooks";
import {useTranslation} from "../../../helpers/i18nUtils";
import {Form as FormikForm, Formik, FormikHelpers} from "formik";
import {AfvoerMetSifonAanwezig} from "../../../redux/installatie/types";
import * as Yup from "yup";
import {array, mixed, number} from "yup";
import {Skill} from "../../../redux/planning/types";
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import BooleanInstallatieVereiste from "../../installatie/dossierdetail/BooleanInstallatieVereiste";
import ChoiceInstallatieVereiste from "../../installatie/dossierdetail/ChoiceInstallatieVereiste";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";
import {InstantFileUpload} from "../../../components/aqualex/InstantFileUpload";
import {SimpleFormField} from "../../../components/aqualex/form/SimpleFormField";
import IntegerInstallatieVereiste from "../../installatie/dossierdetail/IntegerInstallatieVereiste";
import {BesteldProductItemModel, UpdateBesteldProductInstallatieInfoForm} from "../../../redux/bestelbon/types";
import {
    useGetBestelBonById,
    useUpdateBesteldProductInstallatieInfo,
    useVerwijderBesteldProductSituatieFoto,
    useVoegBesteldProductSituatieFotoToe
} from "../../../redux/bestelbon/api";
import {LoadingSkeleton} from "../../../components/aqualex/LoadingSkeleton";
import {SwitchFormField} from "../../../components/aqualex/form/SwitchFormField";

export interface BesteldProductInstallatieInfoIngevenModalProps {
    bestelBonId: string;
    bestelBonLijnId: string;
}

export const BesteldProductInstallatieInfoIngevenModal: React.FC<BesteldProductInstallatieInfoIngevenModalProps> = (props) => {
    const {bestelBonId, bestelBonLijnId} = props;

    const {data: bestelBon, isLoading} = useGetBestelBonById(bestelBonId);
    const bestelBonLijn = bestelBon?.lijnen?.find(item => item.id === bestelBonLijnId);
    const besteldProduct = bestelBonLijn?.item as BesteldProductItemModel;
    const installatieInfo = besteldProduct?.installatieInfo;
    const installatieProfiel = installatieInfo?.installatieProfiel;

    const [updateBesteldProductInstallatieInfo] = useUpdateBesteldProductInstallatieInfo();
    const [voegBesteldProductSituatieFotoToe] = useVoegBesteldProductSituatieFotoToe();
    const [verwijderBesteldProductSituatieFoto] = useVerwijderBesteldProductSituatieFoto();

    const hideModal = useHideModal();

    const {t} = useTranslation("bestelbon");

    const schema = Yup.object({
        stopcontactenAanwezig: number().nullable().min(0,
            t("Foutmeldingen.aantal-stopcontacten-kan-niet-negatief-zijn",
                'Aantal stopcontacten kan niet negatief zijn') as string),
        stopcontactenBovenAanwezig: number().nullable().min(0,
            t("Foutmeldingen.aantal-stopcontacten-kan-niet-negatief-zijn",
                'Aantal stopcontacten kan niet negatief zijn') as string),
        skills: array().of(mixed<Skill>().oneOf(Object.values(Skill))).min(1,
            t("Foutmeldingen.er-moet-minstens-1-skill-worden-gekozen",
                "Er moet minstens 1 skill gekozen worden") as string),
    });

    const initialValues: UpdateBesteldProductInstallatieInfoForm = {
        locatieBeschrijving: installatieInfo?.locatieBeschrijving,
        gebouw: installatieInfo?.gebouw,
        verdieping: installatieInfo?.verdieping,
        lokaalNummer: installatieInfo?.lokaalNummer,

        elektriciteitAanwezig: installatieInfo?.elektriciteitAanwezig ?? true,
        stopcontactenAanwezig: installatieInfo?.stopcontactenAanwezig,
        opmerkingBijStopcontacten: installatieInfo?.opmerkingBijStopcontacten,
        stopcontactenBovenAanwezig: installatieInfo?.stopcontactenBovenAanwezig,
        opmerkingBijStopcontactenBoven: installatieInfo?.opmerkingBijStopcontactenBoven,
        ventilatieAanwezig: installatieInfo?.ventilatieAanwezig,
        opmerkingBijVentilatie: installatieInfo?.opmerkingBijVentilatie,

        waterOpDeLeidingAanwezig: installatieInfo?.waterOpDeLeidingAanwezig ?? true,
        dubbeleDienstkraanBijToestelAanwezig: installatieInfo?.dubbeleDienstkraanBijToestelAanwezig,
        opmerkingBijDubbeleDienstkraanBijToestel: installatieInfo?.opmerkingBijDubbeleDienstkraanBijToestel,
        schellKraanWarmEnKoudAanwezig: installatieInfo?.schellKraanWarmEnKoudAanwezig,
        opmerkingBijSchellKraanWarmEnKoud: installatieInfo?.opmerkingBijSchellKraanWarmEnKoud,
        schellKraanWarmEnKoudBovenAanwezig: installatieInfo?.schellKraanWarmEnKoudBovenAanwezig,
        opmerkingBijSchellKraanWarmEnKoudBoven: installatieInfo?.opmerkingBijSchellKraanWarmEnKoudBoven,
        afvoerMetSifonAanwezig: installatieInfo?.afvoerMetSifonAanwezig,
        opmerkingBijAfvoerMetSifon: installatieInfo?.opmerkingBijAfvoerMetSifon,
        afvoerMetSifonBovenAanwezig: installatieInfo?.afvoerMetSifonBovenAanwezig,
        opmerkingBijAfvoerMetSifonBoven: installatieInfo?.opmerkingBijAfvoerMetSifonBoven,
        boringInWerkbladDoorKlant: installatieInfo?.boringInWerkbladDoorKlant,
        opmerkingBijBoringInWerkbladDoorKlant: installatieInfo?.opmerkingBijBoringInWerkbladDoorKlant,
        wachtbuis40mmAanwezig: installatieInfo?.wachtbuis40mmAanwezig,
        opmerkingBijWachtbuis40mm: installatieInfo?.opmerkingBijWachtbuis40mm,
        installatieInReedsAanwezigeKeukenOfKast: installatieInfo?.installatieInReedsAanwezigeKeukenOfKast,
        opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast: installatieInfo?.opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast,

        algemeneOpmerking: installatieInfo?.algemeneOpmerking
    };

    const onSubmit = async (values: UpdateBesteldProductInstallatieInfoForm, helpers: FormikHelpers<any>) => {
        try {
            await updateBesteldProductInstallatieInfo({...values, bestelBonId, bestelBonLijnId}).unwrap();
            hideModal();
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const situatieFotoToevoegen = async (bestand: File) => {
        await voegBesteldProductSituatieFotoToe({bestelBonId, bestelBonLijnId, situatieFoto: bestand});
    };

    const situatieFotoVerwijderen = async (bestandId: string) => {
        await verwijderBesteldProductSituatieFoto({bestelBonId, bestelBonLijnId, bestandId});
    };

    return (
        <Modal show={true} onHide={hideModal} size="xl" autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.installatieinfo", "Installatieinfo")} {besteldProduct?.configuratieArtikel?.omschrijving}
                    <span className="text-muted"> {installatieProfiel?.code}</span></ModalTitle>
            </ModalHeader>

            <ModalBody>
                <LoadingSkeleton loading={isLoading}>
                    {() =>
                        <Formik<UpdateBesteldProductInstallatieInfoForm> validationSchema={schema}
                                                                         onSubmit={onSubmit}
                                                                         enableReinitialize
                                                                         validateOnBlur={false}
                                                                         validateOnChange={false}
                                                                         initialValues={initialValues}
                        >
                            {({
                                  isSubmitting
                              }) => {
                                return (
                                    <FormikForm>
                                        <Row>
                                            <Col sm={3}>
                                                <Row>
                                                    <Col xs={12}>
                                                        <InstantFileUpload initialValues={[]}
                                                                           onUpload={async (bestand) => {
                                                                               await situatieFotoToevoegen(bestand);
                                                                           }}
                                                                           renderFiles={false}
                                                                           dropzoneClassName="dropzone--small"/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            {installatieInfo?.situatieFotos?.map(foto => (
                                                                <Col xs={12} sm={6} className="text-right">
                                                                    <a href={foto.previewUri} target="_blank"
                                                                       rel="noopener noreferrer"
                                                                       style={{
                                                                           display: "block",
                                                                           position: "relative",
                                                                           width: "100%",
                                                                           height: 0,
                                                                           paddingBottom: "100%"
                                                                       }}>
                                                                        <img src={foto.downloadUri}
                                                                             alt={foto.naam} style={{
                                                                            position: "absolute",
                                                                            left: 0,
                                                                            right: 0,
                                                                            bottom: 0,
                                                                            top: 0,
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            objectFit: "cover"
                                                                        }}/>
                                                                    </a>
                                                                    <LoadingButton variant="link"
                                                                                   onClick={() => {
                                                                                       situatieFotoVerwijderen(foto.id);
                                                                                   }}
                                                                                   loading={false}>
                                                                        <i className="mdi mdi-trash-can"/>
                                                                    </LoadingButton>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Form.Group as={Form.Row}>
                                                    <Form.Label column
                                                                sm={2}>{t("Labels.locatie", "Locatie")}</Form.Label>
                                                    <Col sm={10}>
                                                        <InputGroup className="mb-2" hasValidation>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text><i
                                                                    className='mdi mdi-map-marker'/></InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <SimpleFormField name="locatieBeschrijving"
                                                                             placeholder={t("Placeholders.toestel-locatie", "Toestel Locatie")}
                                                                             showFeedback={false}/>
                                                            <SimpleFormField name="gebouw"
                                                                             placeholder={t("Placeholders.gebouw", "Gebouw")}
                                                                             showFeedback={false}/>
                                                            <SimpleFormField name="verdieping"
                                                                             placeholder={t("Placeholders.verdieping", "Verdieping")}
                                                                             showFeedback={false}/>
                                                            <SimpleFormField name="lokaalNummer"
                                                                             placeholder={t("Placeholders.lokaal", "Lokaal")}
                                                                             showFeedback={false}/>
                                                        </InputGroup>
                                                    </Col>
                                                </Form.Group>
                                                <BooleanInstallatieVereiste
                                                    guard={installatieProfiel.installatieInReedsAanwezigeKeukenOfKast}
                                                    label={t("Labels.wordt-geïnstalleerd-in-keuken-of-kast-die-reeds-aanwezig-is",
                                                        'Wordt geïnstalleerd in keuken of kast die reeds aanwezig is')}
                                                    waardePropertyName='installatieInReedsAanwezigeKeukenOfKast'
                                                    waarde={installatieInfo?.installatieInReedsAanwezigeKeukenOfKast}
                                                    opmerkingPropertyName='opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast'
                                                    opmerking={installatieInfo?.opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast}/>

                                                <BooleanInstallatieVereiste
                                                    guard={installatieProfiel.boringInWerkbladNodig}
                                                    label={t("Labels.boring-in-werkblad-door-klant", 'Boring in werkblad door klant')}
                                                    waardePropertyName='boringInWerkbladDoorKlant'
                                                    waarde={installatieInfo?.boringInWerkbladDoorKlant}
                                                    opmerkingPropertyName='opmerkingBijBoringInWerkbladDoorKlant'
                                                    opmerking={installatieInfo?.opmerkingBijBoringInWerkbladDoorKlant}/>

                                                <h5>{t("Labels.elektriciteit", "Elektriciteit")}</h5>
                                                <hr/>

                                                <Form.Group as={Form.Row} className="align-items-center">
                                                    <Col sm="auto">
                                                        <SwitchFormField name="elektriciteitAanwezig"/>
                                                    </Col>
                                                    <Form.Label column sm={4}>
                                                        {t("Labels.er-is-elektriciteit-aanwezig", "Er is elektriciteit aanwezig")}
                                                    </Form.Label>
                                                </Form.Group>

                                                <Form.Group as={Form.Row}>
                                                    <Form.Label column
                                                                sm={12}>{t("Labels.algemeneOpmerking", "Algemene opmerking")}</Form.Label>
                                                    <Col sm={12}>
                                                        <SimpleFormField name="algemeneOpmerking" showFeedback={false}/>
                                                    </Col>
                                                </Form.Group>

                                                <IntegerInstallatieVereiste
                                                    guard={installatieProfiel.stopcontacten > 0}
                                                    label={t("Labels.stopcontacten-x-nodig",
                                                        `Stopcontacten ({{aantal}} nodig)`,
                                                        {aantal: installatieProfiel.stopcontacten})}
                                                    waardePropertyName='stopcontactenAanwezig'
                                                    waarde={installatieInfo?.stopcontactenAanwezig}
                                                    opmerkingPropertyName='opmerkingBijStopcontacten'
                                                    opmerking={installatieInfo?.opmerkingBijStopcontacten}/>
                                                <IntegerInstallatieVereiste
                                                    guard={installatieProfiel.stopcontactenBoven > 0}
                                                    label={t("Labels.stopcontacten-boven-x-nodig",
                                                        `Stopcontacten boven ({{aantal}} nodig)`,
                                                        {aantal: installatieProfiel.stopcontactenBoven})}
                                                    waardePropertyName='stopcontactenBovenAanwezig'
                                                    waarde={installatieInfo?.stopcontactenBovenAanwezig}
                                                    opmerkingPropertyName='opmerkingBijStopcontactenBoven'
                                                    opmerking={installatieInfo?.opmerkingBijStopcontactenBoven}/>

                                                <BooleanInstallatieVereiste
                                                    guard={installatieProfiel.ventilatie}
                                                    label={t("Labels.ventilatie", 'Ventilatie')}
                                                    waardePropertyName='ventilatieAanwezig'
                                                    waarde={installatieInfo?.ventilatieAanwezig}
                                                    opmerkingPropertyName='opmerkingBijVentilatie'
                                                    opmerking={installatieInfo?.opmerkingBijVentilatie}/>

                                                <h5>{t("Labels.watertoevoer", "Watertoevoer")}</h5>
                                                <hr/>

                                                <Form.Group as={Form.Row} className="align-items-center">
                                                    <Col sm="auto">
                                                        <SwitchFormField name="waterOpDeLeidingAanwezig"/>
                                                    </Col>
                                                    <Form.Label column sm={4}>
                                                        {t("Labels.er-is-water-op-de-leiding-aanwezig", "Er is water op de leiding aanwezig")}
                                                    </Form.Label>
                                                </Form.Group>

                                                <BooleanInstallatieVereiste
                                                    guard={installatieProfiel.dubbeleDienstkraanBijToestel}
                                                    label={t("Labels.dubbele-dienstkraan-bij-toestel", 'Dubbele dienstkraan bij toestel')}
                                                    waardePropertyName='dubbeleDienstkraanBijToestelAanwezig'
                                                    waarde={installatieInfo?.dubbeleDienstkraanBijToestelAanwezig}
                                                    opmerkingPropertyName='opmerkingBijDubbeleDienstkraanBijToestel'
                                                    opmerking={installatieInfo?.opmerkingBijDubbeleDienstkraanBijToestel}/>
                                                <BooleanInstallatieVereiste
                                                    guard={installatieProfiel.schellKraanWarmEnKoud}
                                                    label={t("Labels.schell-kraan-warm-en-koud",
                                                        'Schell kraan warm & koud')}
                                                    waardePropertyName='schellKraanWarmEnKoudAanwezig'
                                                    waarde={installatieInfo?.schellKraanWarmEnKoudAanwezig}
                                                    opmerkingPropertyName='opmerkingBijSchellKraanWarmEnKoud'
                                                    opmerking={installatieInfo?.opmerkingBijSchellKraanWarmEnKoud}/>
                                                <BooleanInstallatieVereiste
                                                    guard={installatieProfiel.schellKraanWarmEnKoudBoven}
                                                    label={t("Labels.schell-kraan-warm-en-koud-boven",
                                                        'Schell kraan warm & koud boven')}
                                                    waardePropertyName='schellKraanWarmEnKoudBovenAanwezig'
                                                    waarde={installatieInfo?.schellKraanWarmEnKoudBovenAanwezig}
                                                    opmerkingPropertyName='opmerkingBijSchellKraanWarmEnKoudBoven'
                                                    opmerking={installatieInfo?.opmerkingBijSchellKraanWarmEnKoudBoven}/>

                                                <ChoiceInstallatieVereiste
                                                    guard={installatieProfiel.afvoerMetSifon}
                                                    label={t("Labels.afvoer", 'Afvoer')}
                                                    mogelijkeWaarden={[
                                                        {
                                                            value: AfvoerMetSifonAanwezig.GEEN_AFVOER_AANWEZIG,
                                                            label: t("Labels.geen-afvoer-aanwezig", 'Geen afvoer aanwezig')
                                                        },
                                                        {
                                                            value: AfvoerMetSifonAanwezig.AFVOER_AANWEZIG,
                                                            label: t("Labels.enkel-afvoer-aanwezig", 'Enkel afvoer aanwezig')
                                                        },
                                                        {
                                                            value: AfvoerMetSifonAanwezig.AFVOER_EN_SIFON_AANWEZIG,
                                                            label: t("Labels.afvoer-met-sifon-aanwezig", 'Afvoer met sifon aanwezig')
                                                        }]}
                                                    waardePropertyName='afvoerMetSifonAanwezig'
                                                    waarde={installatieInfo?.afvoerMetSifonAanwezig}
                                                    waardeColSize={4}
                                                    opmerkingPropertyName='opmerkingBijAfvoerMetSifon'
                                                    opmerking={installatieInfo?.opmerkingBijAfvoerMetSifon}/>
                                                <ChoiceInstallatieVereiste
                                                    guard={installatieProfiel.afvoerMetSifonBoven}
                                                    label={t("Labels.afvoer-boven", 'Afvoer boven')}
                                                    mogelijkeWaarden={[
                                                        {
                                                            value: AfvoerMetSifonAanwezig.GEEN_AFVOER_AANWEZIG,
                                                            label: t("Labels.geen-afvoer-aanwezig", 'Geen afvoer aanwezig')
                                                        },
                                                        {
                                                            value: AfvoerMetSifonAanwezig.AFVOER_AANWEZIG,
                                                            label: t("Labels.enkel-afvoer-aanwezig", 'Enkel afvoer aanwezig')
                                                        },
                                                        {
                                                            value: AfvoerMetSifonAanwezig.AFVOER_EN_SIFON_AANWEZIG,
                                                            label: t("Labels.afvoer-met-sifon-aanwezig", 'Afvoer met sifon aanwezig')
                                                        }]}
                                                    waardePropertyName='afvoerMetSifonBovenAanwezig'
                                                    waarde={installatieInfo?.afvoerMetSifonBovenAanwezig}
                                                    opmerkingPropertyName='opmerkingBijAfvoerMetSifonBoven'
                                                    opmerking={installatieInfo?.opmerkingBijAfvoerMetSifonBoven}/>

                                                <BooleanInstallatieVereiste
                                                    guard={installatieProfiel.wachtbuis40mm}
                                                    label={t("Labels.wachtbuis-40mm", 'Wachtbuis 40mm')}
                                                    waardePropertyName='wachtbuis40mmAanwezig'
                                                    waarde={installatieInfo?.wachtbuis40mmAanwezig}
                                                    opmerkingPropertyName='opmerkingBijWachtbuis40mm'
                                                    opmerking={installatieInfo?.opmerkingBijWachtbuis40mm}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className="d-flex justify-content-end">
                                                    <LoadingButton variant="primary" loading={isSubmitting}
                                                                   type="submit">
                                                        {t("algemeen:Buttons.bewaren", "Bewaren")}
                                                    </LoadingButton>
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormikForm>
                                );
                            }}
                        </Formik>}
                </LoadingSkeleton>
            </ModalBody>
        </Modal>
    )
};
