import React, {useMemo} from "react";
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {useHideModal} from "../../../../redux/ui/hooks";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/aqualex/Modal";
import {TextAreaFormField} from "../../../../components/aqualex/form/TextAreaFormField";
import {useTranslation} from "../../../../helpers/i18nUtils";

export interface PlanningInterneNotitiesFormValues {
    interneNotities: string;
}

export interface PlanningInterneNotitiesModalProps {
    defaultValues?: Partial<PlanningInterneNotitiesFormValues>;
    onBevestigHandler: (form: PlanningInterneNotitiesFormValues) => void
}

export const PlanningInterneNotitiesModal: React.FC<PlanningInterneNotitiesModalProps> = (props) => {
    const {defaultValues, onBevestigHandler} = props;

    const {t} = useTranslation("planning");

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const onBevestig = (values: PlanningInterneNotitiesFormValues) => {
        hideModal();
        onBevestigHandler(values);
    };

    const initialValues: PlanningInterneNotitiesFormValues = useMemo(() => ({
        interneNotities: "",
        ...defaultValues
    }), [defaultValues]);

    return (
        <Modal show onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Labels.interne-notities", "Interne notities")}</ModalTitle>
            </ModalHeader>

            <Formik
                initialValues={initialValues}
                validateOnMount
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    interneNotities: Yup.string().nullable()
                })}
            >
                {({
                      handleSubmit,
                      isValid
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <p>{t("PlanningInterneNotitiesModal.deze-notities-zijn-enkel-zichtbaar-voor-planners", "Deze notities zijn enkel zichtbaar voor de planners.")}</p>
                            <Form.Row>
                                <FormGroup id="interneNotities" as={Col}>
                                    <TextAreaFormField name="interneNotities" rows={4}/>
                                </FormGroup>
                            </Form.Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="light" onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}
                            <Button variant="primary" disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};

