import {Pageable} from "../support/pagination";
import {ApiError} from "../ui/types";
import {
    DOWNLOAD_SERVICE_RAPPORT_FAILURE,
    DOWNLOAD_SERVICE_RAPPORT_REQUEST,
    DOWNLOAD_SERVICE_RAPPORT_SUCCESS,
    SEARCH_SERVICE_RAPPORT_FAILURE,
    SEARCH_SERVICE_RAPPORT_REQUEST,
    SEARCH_SERVICE_RAPPORT_SUCCESS
} from "./constants";
import {DownloadServiceRapportAction, SearchServiceRapportAction, ServiceRapportFilters} from "./types";
import {FileDownload} from "../../helpers/download";


export const searchServiceRapportRequest = (filters: ServiceRapportFilters, pageable: Pageable): SearchServiceRapportAction => ({
    type: SEARCH_SERVICE_RAPPORT_REQUEST,
    payload: {
        filters,
        pageable,
    },
});

export const searchServiceRapportSuccess = (page, filters: ServiceRapportFilters, pageable: Pageable): SearchServiceRapportAction => ({
    type: SEARCH_SERVICE_RAPPORT_SUCCESS,
    payload: page,
    meta: {
        filters,
        pageable,
    },
});

export const searchServiceRapportFailure = (error: ApiError, filters: ServiceRapportFilters, pageable: Pageable): SearchServiceRapportAction => ({
    type: SEARCH_SERVICE_RAPPORT_FAILURE,
    payload: error,
    error: true,
    meta: {
        filters,
        pageable,
    },
});



export const downloadServiceRapportByIdRequest = (klantId: string, serviceRapportId: string): DownloadServiceRapportAction => ({
    type: DOWNLOAD_SERVICE_RAPPORT_REQUEST,
    payload: {
        klantId,
        serviceRapportId,
    },
});

export const downloadServiceRapportByIdSuccess = (result: FileDownload, klantId: string, serviceRapportId: string): DownloadServiceRapportAction => ({
    type: DOWNLOAD_SERVICE_RAPPORT_SUCCESS,
    payload: result,
    meta: {
        klantId,
        serviceRapportId,

    },
});

export const downloadServiceRapportByIdFailure = (error: ApiError, klantId: string, serviceRapportId: string): DownloadServiceRapportAction => ({
    type: DOWNLOAD_SERVICE_RAPPORT_FAILURE,
    payload: error,
    meta: {
        klantId,
        serviceRapportId,
    },
    error: true,
});
