import React from "react";
import {BezoekDuurtijdAanpassenDropDown, BezoekDuurtijdAanpassenDropDownProps} from "./BezoekDuurtijdAanpassenDropDown";
import {useGetDagPlanningByMedewerkerIdAndDatum, useWijzigDuurtijdBezoek} from "../../../redux/planning/api";
import {BezoekDuurtijdAanpassenFormValues} from "./BezoekDuurtijdAanpassenForm";
import {timeToDuration} from "../../../helpers/DateUtils";

export const BezoekDuurtijdAanpassenDropDownContainer: React.FC<BezoekDuurtijdAanpassenDropDownProps> = (props) => {
    const {medewerkerId, datum, geplandBezoekId} = props;

    const {data: dagPlanning} = useGetDagPlanningByMedewerkerIdAndDatum({medewerkerId, datum});
    const [wijzigDuurtijdBezoek, {isLoading}] = useWijzigDuurtijdBezoek();

    const onBevestig = (form: BezoekDuurtijdAanpassenFormValues) => {
        if (!dagPlanning) {
            return;
        }

        wijzigDuurtijdBezoek({
            id: dagPlanning.id,
            geplandBezoekId,
            duurtijd: timeToDuration(form.duurtijd!, "minutes")
        });
    };

    return (
       <BezoekDuurtijdAanpassenDropDown {...props} onBevestig={onBevestig} loading={isLoading}/>
    );
};
