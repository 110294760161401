import {createPagedSearchActions} from "./pagination/action";
import {actionTypes} from "./action";
import {reducerForPagedSearchActionType} from "./pagination/reducers";
import {denormalize, normalize, schema} from "normalizr";
import {overwriteArraysInsteadOfMerge, paginationKey} from "./pagination/pagination";
import mergeWith from "lodash.mergewith";
import {RootState} from "../reducers";
import {call, delay, put, takeEvery, takeLatest} from "redux-saga/effects";
import {ReduxAction} from "./types";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useState} from "react";
import {useDeepCompareEffect} from "use-deep-compare";
import {Pageable, Pagination, SortOrder} from "./pagination/types";
import {selectPage} from "./pagination/selectors";
import {reducerForActionType} from "./reducers";
import {selectLoading} from "../ui/reducers";
import {createFetchByIdActions, createFetchQueryActions} from "./actionCreators";
import debounce from "lodash.debounce";
import {useDeepEffect} from "./effect";

const classifierExtractor = (payload: any) => JSON.stringify(payload);


export interface CreateSearchActionOptions<TFilter, TModel> {
    entityName: string;
    reducerName: string;
    schema?: schema.Entity;

    sagaCall: (incomingAction: ReduxAction<string, { filters: TFilter, pageable: Pageable }, any>) => Generator<any, any, any>

    entityToId?(entity: TModel): string;

    storeState?(state: RootState, entities: Array<TModel>, paginationState: Record<string, Pagination>): void;

    debounce?: boolean;
}

interface CreateSearchActionCreateHookOptions<TFilter> {
    filters: TFilter,
    pageable: Pageable,
    uiStateKey?: string,
    skip?: boolean;
}

export const createSearchAction = <TFilter, TModel>(actionName: string, options: CreateSearchActionOptions<TFilter, TModel>) => {
    const types = actionTypes(actionName);
    const actions = createPagedSearchActions<TFilter, TModel>(types);

    const entitySchema = options.schema || new schema.Entity(options.entityName);

    const debounceSaga = !!options.debounce;

    const defaultEntityToId = <TModel extends { id: string }>(entity: TModel): string => entity.id;
    const defaultStoreState = (state: RootState, entities: Array<TModel>, paginationState: Record<string, Pagination>) => {
        const normalized = normalize(entities, [entitySchema]);

        return mergeWith({},
            state,
            {pagination: {[options.entityName]: {...paginationState}}},
            {entities: {...normalized.entities}},
            overwriteArraysInsteadOfMerge
        );
    };

    const reducer = reducerForPagedSearchActionType(
        types,
        options.entityToId || defaultEntityToId,
        options.storeState || defaultStoreState
    );

    const saga = function* (incomingAction: ReturnType<typeof actions.request>) {
        if (debounceSaga) {
            yield delay(500);
        }

        const {payload} = incomingAction;

        try {
            const result = yield options.sagaCall(incomingAction);

            yield put(actions.success(result, payload.filters, payload.pageable));
        } catch (err: any) {
            yield put(actions.failure(err, payload.filters, payload.pageable));
        }
    };

    const createFetchHook = (defaultHookOptions?: Partial<CreateSearchActionCreateHookOptions<TFilter>>) => (hookOptions: CreateSearchActionCreateHookOptions<TFilter>) => {
        const dispatch = useDispatch();

        const {filters, pageable, uiStateKey = options.entityName, skip} = {...defaultHookOptions, ...hookOptions};

        const state = useSelector((state: RootState) => ({
            page: selectPage(
                state[options.reducerName].pagination[options.entityName],
                filters,
                pageable,
                id => denormalize(id, entitySchema, state[options.reducerName].entities),
                !skip
            )
        }));

        let requestDispatcher = (newFilters: TFilter, newPageable: Pageable) => {
            if (!skip) {
                dispatch(actions.request(newFilters, newPageable, uiStateKey));
            }
        };
        const loadData = debounceSaga
            // eslint-disable-next-line
            ? useCallback(debounce(requestDispatcher, 300), [skip, dispatch, uiStateKey])
            : useCallback(requestDispatcher, [skip, dispatch, uiStateKey]);

        useDeepCompareEffect(() => {
            if (!skip) {
                loadData(filters, pageable);
            }
        }, [skip, filters, pageable]);

        return {
            page: state.page,
            loadData,
            skip
        };
    };

    const sagaEffect = debounceSaga ? takeLatest(types.REQUEST, saga) : takeEvery(types.REQUEST, saga);

    return {
        types,
        actions,
        entitySchema,
        reducer,
        saga,
        sagaEffect,
        createFetchHook
    };
};

export interface CreateQueryActionOptions<TFilter, TModel> {
    entityName: string;
    reducerName: string;
    schema?: schema.Entity;

    sagaCall: (incomingAction: ReduxAction<string, { filters: TFilter }, any>) => Generator<any, any, any>

    entityToId?(entity: TModel): string;

    storeState?: any;
}

export interface QueryActionPayload<TFilter> {
    filters: TFilter;
}

export const createQueryAction = <TFilter, TModel>(actionName: string, options: CreateQueryActionOptions<TFilter, TModel>) => {
    const types = actionTypes(actionName);
    const actions = createFetchQueryActions<TModel, QueryActionPayload<TFilter>>(types, classifierExtractor);

    const entitySchema = options.schema || new schema.Entity(options.entityName);

    const defaultStoreState = (state: RootState, action: ReduxAction<string, { filters: TFilter }, any>) => {
        const {payload} = action;
        const normalized = normalize(payload, [entitySchema]);

        return mergeWith({},
            state,
            {entities: {...normalized.entities}},
            {[`${actionName}Map`]: normalized.result},
            overwriteArraysInsteadOfMerge
        );
    };

    const reducer = reducerForActionType(
        types,
        options.storeState || defaultStoreState
    );

    const saga = function* (incomingAction: ReturnType<typeof actions.request>) {
        yield delay(500);

        const {payload} = incomingAction;

        try {
            const result = yield options.sagaCall(incomingAction);

            yield put(actions.success(result, payload));
        } catch (err: any) {
            yield put(actions.failure(err, payload));
        }
    };

    const createFetchHook = () => (hookOptions: { filters: TFilter; skip?: boolean; }) => {
        const dispatch = useDispatch();

        const {filters, skip} = hookOptions;

        const state = useSelector((state: RootState) => ({
            items: denormalize(state[options.reducerName][`${actionName}Map`], [entitySchema], state[options.reducerName].entities) || [],
            loading: selectLoading(state, types, classifierExtractor({filters}), true)
        }));

        const loadData = useCallback((newFilters: TFilter) => {
            if (!skip) {
                dispatch(actions.request({filters: newFilters}));
            }
        }, [skip, dispatch]);

        useDeepEffect(() => {
            if (!skip) {
                loadData(filters);
            }
        }, [skip, filters]);

        return {...state, skip};
    };

    const sagaEffect = takeLatest(types.REQUEST, saga);

    return {
        types,
        actions,
        entitySchema,
        reducer,
        saga,
        sagaEffect,
        createFetchHook
    };
};


export interface CreateMutationActionOptions<TPayload, TModel> {
    entityName: string;
    reducerName: string;
    schema?: schema.Entity;

    sagaCall: (incomingAction: ReduxAction<string, TPayload, any>) => Generator<any, any, any>

    entityToId?(entity: TModel): string;

    storeState?(state: any, action: ReduxAction<string, TModel, any>): void;
}

export const createMutationAction = <TPayload, TModel>(actionName: string, options: CreateMutationActionOptions<TPayload, TModel>) => {
    const types = actionTypes(actionName);
    const actions = createFetchQueryActions<TModel, TPayload>(types, classifierExtractor);

    const entitySchema = options.schema || new schema.Entity(options.entityName);

    const defaultStoreState = (state: RootState, action: ReduxAction<string, TModel, any>) => {
        const {payload} = action;

        if (!payload) {
            return;
        }

        const normalized = normalize([payload], [entitySchema]);

        return mergeWith({},
            state,
            {entities: {...normalized.entities}},
            {[`${actionName}Map`]: normalized.result},
            overwriteArraysInsteadOfMerge
        );
    };

    const reducer = reducerForActionType(
        types,
        options.storeState || defaultStoreState
    );

    const saga = function* (incomingAction: ReturnType<typeof actions.request>) {
        const {payload, meta} = incomingAction;
        const {onSuccessCallback, onFailureCallback} = meta;

        try {
            const result = yield options.sagaCall(incomingAction);

            yield put(actions.success(result, payload));

            if (onSuccessCallback) {
                yield call(onSuccessCallback, result);
            }
        } catch (err: any) {
            yield put(actions.failure(err, payload));

            if (onFailureCallback) {
                yield call(onFailureCallback, err);
            }
        }
    };

    const createCallbackHook = () => () => {
        const dispatch = useDispatch();

        const [classifier, setClassifier] = useState<string>();
        const state = useSelector((state: RootState) => ({
            loading: selectLoading(state, actionName, classifier)
        }));

        const callback = React.useCallback((payload: TPayload, options?: {
            onSuccessCallback?(entity: TModel): void;
            onFailureCallback?(error: Error): void;
        }) => {
            setClassifier(classifierExtractor(payload));

            return new Promise<TModel | undefined>((resolve, reject) => {
                const onSuccess = (entity: TModel) => {
                    setClassifier(undefined);

                    options?.onSuccessCallback?.(entity);
                    resolve(entity);
                };

                const onFailure = (error: Error) => {
                    setClassifier(undefined);

                    options?.onFailureCallback?.(error);
                    resolve(undefined);
                };

                dispatch(actions.request(payload, onSuccess, onFailure));
            });
        }, [dispatch]);

        return [callback, state] as [typeof callback, typeof state];
    };

    const sagaEffect = takeEvery(types.REQUEST, saga);

    return {
        types,
        actions,
        entitySchema,
        reducer,
        saga,
        sagaEffect,
        createCallbackHook
    };
};


export interface CreateFetchByIdActionOptions<TModel> {
    entityName: string;
    reducerName: string;
    schema?: schema.Entity;

    sagaCall: (incomingAction: ReduxAction<string, FetchByIdActionPayload, any>) => Generator<any, any, any>

    entityToId?(entity: TModel): string;

    storeState?: any;
}

export interface FetchByIdActionPayload {
    id: string;
}

export const createFetchByIdAction = <TModel>(actionName: string, options: CreateFetchByIdActionOptions<TModel>) => {
    const types = actionTypes(actionName);
    const actions = createFetchByIdActions<TModel>(types);

    const entitySchema = options.schema || new schema.Entity(options.entityName);

    const defaultStoreState = (state: RootState, action: ReduxAction<string, FetchByIdActionPayload, any>) => {
        const {payload} = action;
        const normalized = normalize(payload, entitySchema);

        return mergeWith({},
            state,
            {entities: {...normalized.entities}},
            overwriteArraysInsteadOfMerge
        );
    };

    const reducer = reducerForActionType(
        types,
        options.storeState || defaultStoreState
    );

    const saga = function* (incomingAction: ReturnType<typeof actions.request>) {
        const {payload} = incomingAction;

        try {
            const result = yield options.sagaCall(incomingAction);

            yield put(actions.success(result, payload.id));
        } catch (err: any) {
            yield put(actions.failure(err, payload.id));
        }
    };

    const createFetchHook = () => (hookOptions: FetchByIdActionPayload & { skip?: boolean; }): { item?: TModel, loading: boolean, skip?: boolean, loadData: (id: string) => void } => {
        const dispatch = useDispatch();

        const {id, skip} = hookOptions;

        const state = useSelector((state: RootState) => ({
            item: denormalize(id, entitySchema, state[options.reducerName].entities),
            loading: selectLoading(state, types, id, false)
        }));

        const loadData = useCallback((newId: string) => {
            if (!skip) {
                dispatch(actions.request(newId));
            }
        }, [skip, dispatch]);

        useDeepCompareEffect(() => {
            if (!skip) {
                loadData(id);
            }
        }, [loadData, skip, id]);

        return {...state, skip, loadData};
    };

    const sagaEffect = takeLatest(types.REQUEST, saga);

    return {
        types,
        actions,
        entitySchema,
        reducer,
        saga,
        sagaEffect,
        createFetchHook
    };
};


export interface CreateFetchActionOptions<TModel, TFilter> {
    entityName: string;
    reducerName: string;
    schema?: schema.Entity;

    sagaCall: (incomingAction: ReduxAction<string, TFilter, any>) => Generator<any, any, any>

    entityToId?(entity: TModel): string;

    storeState?: any;
}

export const createFetchAction = <TModel, TFilter>(actionName: string, options: CreateFetchActionOptions<TModel, TFilter>) => {
    const types = actionTypes(actionName);
    const actions = createFetchQueryActions<TModel, TFilter>(types);

    const dummyPageable = {pageNumber: 0, pageSize: 0, sortField: "", sortOrder: SortOrder.asc};

    const defaultStoreState = (state: RootState, action: ReduxAction<string, TModel, any>) => {
        const key = paginationKey(action.meta.payload, dummyPageable)

        return mergeWith({},
            state,
            {
                entities: {
                    [options.entityName]: {
                        [key]: action.payload
                    }
                }
            },
            overwriteArraysInsteadOfMerge
        );
    };

    const reducer = reducerForActionType(
        types,
        options.storeState || defaultStoreState
    );

    const saga = function* (incomingAction: ReturnType<typeof actions.request>) {
        const {payload} = incomingAction;

        try {
            const result = yield options.sagaCall(incomingAction);

            yield put(actions.success(result, payload));
        } catch (err: any) {
            yield put(actions.failure(err, payload));
        }
    };

    const createFetchHook = () => (hookOptions: { filters: TFilter } & { skip?: boolean; }): { item?: TModel, loading: boolean, skip?: boolean, loadData: (filters: TFilter) => void } => {
        const dispatch = useDispatch();

        const {filters, skip} = hookOptions;

        const key = paginationKey(filters, dummyPageable);
        const state = useSelector((state: RootState) => {
            return {
                item: state[options.reducerName].entities[options.entityName][key],
                loading: selectLoading(state, types, undefined, false)
            };
        });

        const loadData = useCallback((filters: TFilter) => {
            if (!skip) {
                dispatch(actions.request(filters));
            }
        }, [skip, dispatch]);

        useDeepCompareEffect(() => {
            if (!skip) {
                loadData(filters);
            }
        }, [loadData, skip, filters]);

        return {...state, skip, loadData};
    };

    const sagaEffect = takeLatest(types.REQUEST, saga);

    return {
        types,
        actions,
        reducer,
        saga,
        sagaEffect,
        createFetchHook
    };
};
