import { default as React } from 'react';
import { PartnerSelector } from '../partner/PartnerSelector';
import { FormikFormField, FormikFormFieldProps } from './FormikFormField';
import { PartnerFilters } from '../../../redux/partner/types';

export interface PartnerFormFieldProps {
    searchFilters?: PartnerFilters;
}

export const PartnerFormField: React.FC<FormikFormFieldProps & PartnerFormFieldProps> = ({
                                                                                         searchFilters,
                                                                                         ...props
                                                                                     }) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <PartnerSelector {...field}
                               partnerChanged={async partner => {
                                   await helpers.setValue(partner);

                                   props.onChange && props.onChange(partner);
                               }}
                               geselecteerdePartner={field.value}
                               isInvalid={!!meta.error}
                               disabled={props.disabled}
                />
            )}
        </FormikFormField>
    );
};
