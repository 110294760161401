import React, {useCallback, useMemo} from "react";
import {Col, Row} from "react-bootstrap";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DropResult,
    ResponderProvided
} from "react-beautiful-dnd";
import {
    DagPlanningAutomatischAanvullenModel,
    DagPlanningEntryModel,
    DagPlanningEntryType,
    DagPlanningModel,
    DagPlanningOmwegBerekeningModel,
    DagPlanningStatus,
    DagPlanningStopType
} from "../../../../redux/planning/types";
import {PlanningStop} from "./cards/PlanningStop";
import {PlanningRit} from "./cards/PlanningRit";
import {PlanningPauze} from "./cards/PlanningPauze";
import {PlanningBezoekCard} from "./cards/PlanningBezoekCard";
import ActionBarButton from "../../../../components/aqualex/ActionBarButton";
import {PlanningInstructieCard} from "./cards/PlanningInstructieCard";
import {PlanningAutomatischAanvullenMarker} from "./cards/PlanningAutomatischAanvullenMarker";
import {PlanningOmweg} from "./cards/PlanningOmweg";
import {DagPlanningTimelineUtils} from "./DagPlanningTimelineUtils";
import {AligneerDropDown} from "./AligneerDropDown";
import {DagPlanningAlignerenFormValues} from "./DagPlanningAlignerenForm";
import Datum from "../../../../components/aqualex/Datum";
import {AdresModel} from "../../../../redux/verzendadres/types";

export enum DagPlanningTimelineEntryType {
    DAG_PLANNING_ENTRY = "DAG_PLANNING_ENTRY",
    AUTOMATISCH_AANVULLEN_MARKER = "AUTOMATISCH_AANVULLEN_MARKER",
    BEZOEK_AANVRAAG_OMWEG = "BEZOEK_AANVRAAG_OMWEG"
}

export interface DagPlanningTimelineEntry {
    id: string;
    type: DagPlanningTimelineEntryType;

    dagPlanningEntry?: DagPlanningEntryModel;
    automatischAanvullen?: DagPlanningAutomatischAanvullenModel;
    omwegBerekening?: {
        berekening: DagPlanningOmwegBerekeningModel;
        aangeraden: boolean;
    };
}

export interface DagPlanningTimelineProps {
    dagPlanning: DagPlanningModel;
    entries: DagPlanningTimelineEntry[];

    voegBezoekToe?: () => void;
    verplaatsBezoek?: (geplandBezoekId: string, nieuweIndex: number, onderMarker?: boolean) => Promise<any>;
    verwijderBezoek?: (geplandBezoekId: string) => void;
    verplaatsAutomatischAanvullenMarker?: (naBezoekIndex: number | undefined, isBevestigingNodig?: boolean) => any;
    schakelAutomatischAanvullenMarker?: (automatischAanvullen: boolean) => Promise<any>;
    startStopAdresWijzigen?: (startAdres?: AdresModel, startDepot?: string, standaardStartAdres?: boolean, eindAdres?: AdresModel, eindDepot?: string, standaardEindAdres?: boolean) => Promise<any>;

    onOmwegClick?: (omwegBerekening: DagPlanningOmwegBerekeningModel | undefined, item: DagPlanningTimelineEntry, index: number) => any;

    showBezoekLink?: boolean;

    dragDropEnabled?: boolean;
    showInstructieEntry?: boolean;

    loading?: boolean;
    loadingEntryId?: string;
    onSelectEntry?: (string?) => void;
    selectedEntry?: string;
    communiceer?: Function;
    aligneer?: (form: DagPlanningAlignerenFormValues) => any;
}

export const DagPlanningTimeline: React.FC<DagPlanningTimelineProps> = (props) => {
    const {
        dagPlanning,
        entries,

        voegBezoekToe,
        verplaatsBezoek,
        verwijderBezoek,
        verplaatsAutomatischAanvullenMarker,
        schakelAutomatischAanvullenMarker,
        startStopAdresWijzigen,

        onOmwegClick,
        showBezoekLink,

        dragDropEnabled = true,
        showInstructieEntry = true,

        loading,
        loadingEntryId,
        onSelectEntry,
        selectedEntry,
        communiceer,
        aligneer,
    } = props;

    const calculateFixedStyle = (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        return {
            ...provided.draggableProps.style,
            // Dirty fix om ervoor te zorgen dat bij drop het item correct terechtkomt,
            // anders "vliegt" het item naar zijn plaats + 50px naar rechts (door de padding),
            // om dan uiteindelijk te "springen" naar de correcte plaats.
            // Door bij de dropanimatie die 50px translate weg te halen "vliegt" het item meteen
            // naar de juiste plaats.
            // Komt doordat het bezoekindex-icoontje mee in de draggable zit,
            // waar normaal de inner ref op de Card zit en je het probleem niet hebt.
            transform: !snapshot.isDropAnimating ?
                provided.draggableProps.style?.transform :
                provided.draggableProps.style?.transform?.replace("translate(50px", "translate(0px")
        };
    };

    const draggableItems = useMemo(() => {
        const entriesWithoutFirstAndLast = entries.slice(1, entries.length - 1);

        return entriesWithoutFirstAndLast.map((item, index) => {
            if (item.type === DagPlanningTimelineEntryType.DAG_PLANNING_ENTRY) {
                const dagPlanningEntry = item.dagPlanningEntry!;

                return (
                    <Draggable draggableId={dagPlanningEntry.id} index={index}
                               key={dagPlanningEntry.id}
                               isDragDisabled={loading || !!loadingEntryId || dagPlanningEntry.type !== DagPlanningEntryType.BEZOEK}>
                        {(provided, snapshot) => {
                            if (dagPlanningEntry.type === DagPlanningEntryType.RIT) {
                                return <PlanningRit {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        opacity: loadingEntryId ? 0.5 : undefined
                                                    }}
                                                    placeholder={!!loadingEntryId || loading}
                                                    entry={dagPlanningEntry}
                                                    innerRef={provided.innerRef}/>;
                            } else if (dagPlanningEntry.type === DagPlanningEntryType.PAUZE) {
                                return <PlanningPauze {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      innerRef={provided.innerRef}/>;
                            } else if (dagPlanningEntry.type === DagPlanningEntryType.STOP) {
                                return <PlanningStop {...provided.draggableProps}
                                                     {...provided.dragHandleProps}
                                                     dagPlanning={dagPlanning}
                                                     entry={dagPlanningEntry}
                                                     stopType={index === 0 ? DagPlanningStopType.START : DagPlanningStopType.EINDE}
                                                     innerRef={provided.innerRef}/>;
                            } else if (dagPlanningEntry.type === DagPlanningEntryType.BEZOEK) {
                                return (
                                    <PlanningBezoekCard {...provided.draggableProps}
                                                        style={calculateFixedStyle(provided, snapshot)}
                                                        dagPlanning={dagPlanning}
                                                        selected={selectedEntry === dagPlanningEntry.id}
                                                        onSelect={() => onSelectEntry?.(dagPlanningEntry.id)}
                                                        entry={dagPlanningEntry}
                                                        loading={dagPlanningEntry.id === loadingEntryId}
                                                        actionsDisabled={loading}
                                                        showLink={showBezoekLink}
                                                        verwijderBezoek={verwijderBezoek}
                                                        innerRef={provided.innerRef}
                                                        handleProps={provided.dragHandleProps}
                                                        draggable={dragDropEnabled}/>
                                );
                            }

                            return <div ref={provided.innerRef}/>;
                        }}
                    </Draggable>
                );
            } else if (item.type === DagPlanningTimelineEntryType.AUTOMATISCH_AANVULLEN_MARKER) {
                return (
                    <Draggable draggableId="automatisch-aanvullen" index={index}
                               key="automatisch-aanvullen"
                               isDragDisabled={loading || !!loadingEntryId}>
                        {(provided, snapshot) => {
                            return (
                                <PlanningAutomatischAanvullenMarker innerRef={provided.innerRef}
                                                                    draggableProps={provided.draggableProps}
                                                                    dragHandleProps={provided.dragHandleProps}

                                                                    style={calculateFixedStyle(provided, snapshot)}

                                                                    automatischAanvullen={dagPlanning?.automatischAanvullen}
                                                                    schakelAutomatischAanvullenMarker={schakelAutomatischAanvullenMarker}
                                />
                            );
                        }}
                    </Draggable>
                );
            } else if (item.type === DagPlanningTimelineEntryType.BEZOEK_AANVRAAG_OMWEG) {
                const placeholder = !!loadingEntryId || loading;
                return <PlanningOmweg placeholder={placeholder}
                                      afstand={item.omwegBerekening?.berekening?.afstand}
                                      aangeraden={item.omwegBerekening?.aangeraden}
                                      enabled={placeholder || !!item.omwegBerekening}
                                      onClick={() => onOmwegClick?.(item.omwegBerekening?.berekening, item, index)}/>;
            }

            return null;
        });
    }, [entries, dagPlanning, loading, loadingEntryId, onSelectEntry, selectedEntry, verwijderBezoek, schakelAutomatischAanvullenMarker, onOmwegClick, showBezoekLink, dragDropEnabled]);

    const onDragBezoek = useCallback(async (tmpEntries: DagPlanningTimelineEntry[], rawSourceIndex: number, rawTargetIndex: number) => {
        if (rawSourceIndex === rawTargetIndex) {
            return;
        }

        const bezoekEntry = tmpEntries[rawSourceIndex];
        if (!bezoekEntry.dagPlanningEntry?.geplandBezoekId) {
            return;
        }

        const bezoekWordtNaarOnderGesleept = rawSourceIndex < rawTargetIndex;

        const targetEntry = tmpEntries[rawTargetIndex];
        const entryVoorTarget = tmpEntries[rawTargetIndex - 1];
        const tweedeEntryVoorTarget = tmpEntries[rawTargetIndex - 2];

        const entryVoorTargetIsMarker = entryVoorTarget?.type === DagPlanningTimelineEntryType.AUTOMATISCH_AANVULLEN_MARKER;
        const tweedeEntryVoorTargetIsMarker = tweedeEntryVoorTarget?.type === DagPlanningTimelineEntryType.AUTOMATISCH_AANVULLEN_MARKER;
        const targetIsMarker = targetEntry.type === DagPlanningTimelineEntryType.AUTOMATISCH_AANVULLEN_MARKER;

        // Indien bezoek naar beneden gesleept wordt net onder de marker, dan is de target de marker
        // Indien bezoek naar boven gesleept wordt net onder de marker, dan is de entry vóór de target de marker (of de tweede ervoor als er een rit tussen zit)
        const isNetOnderMarker = (targetIsMarker && bezoekWordtNaarOnderGesleept) || (entryVoorTargetIsMarker && bezoekWordtNaarOnderGesleept) || (!bezoekWordtNaarOnderGesleept && entryVoorTargetIsMarker) || (!bezoekWordtNaarOnderGesleept && tweedeEntryVoorTargetIsMarker);

        const newEntries = [...tmpEntries];
        newEntries.splice(rawSourceIndex, 1);
        newEntries.splice(rawTargetIndex, 0, bezoekEntry);

        const newBezoekIndex = DagPlanningTimelineUtils.getBezoekEntries(newEntries).indexOf(bezoekEntry);

        const newMarkerIndex = DagPlanningTimelineUtils.getMarkerIndex(newEntries);
        const nieuweBezoekIndexBovenMarker = DagPlanningTimelineUtils.getEersteBezoekIndexBovenArrayIndex(newEntries, newMarkerIndex);

        if (newBezoekIndex === bezoekEntry.dagPlanningEntry?.bezoek?.bezoekIndex) {
            // Enkel de marker is verplaatst
            await verplaatsAutomatischAanvullenMarker?.(nieuweBezoekIndexBovenMarker ?? -1, false);
        } else {
            await verplaatsBezoek?.(bezoekEntry.dagPlanningEntry.geplandBezoekId, newBezoekIndex, isNetOnderMarker);
        }
    }, [verplaatsBezoek, verplaatsAutomatischAanvullenMarker]);

    const onDragAutomatischAanvullenMarker = useCallback((tmpEntries: DagPlanningTimelineEntry[], rawSourceIndex: number, rawTargetIndex: number) => {
        const vorigeDagPlanningEntry = tmpEntries
            .slice(0, rawTargetIndex + (rawTargetIndex < rawSourceIndex ? 0 : 1))
            .reverse()
            .find(item => item.type === DagPlanningTimelineEntryType.DAG_PLANNING_ENTRY &&
                item.dagPlanningEntry!.type === DagPlanningEntryType.BEZOEK
            );

        const bevestigingNodig = DagPlanningTimelineUtils.isBevestigingNodigVoorVerplaatsing(tmpEntries, dagPlanning.automatischAanvullen?.automatischAanvullenNaBezoekIndex, rawTargetIndex);

        if (!vorigeDagPlanningEntry) {
            verplaatsAutomatischAanvullenMarker?.(-1, bevestigingNodig);

            return;
        }

        const nieuweBezoekIndex = vorigeDagPlanningEntry.dagPlanningEntry!.bezoek!.bezoekIndex;
        if (dagPlanning.automatischAanvullen?.automatischAanvullenNaBezoekIndex !== nieuweBezoekIndex) {
            verplaatsAutomatischAanvullenMarker?.(nieuweBezoekIndex, bevestigingNodig);
        }
    }, [verplaatsAutomatischAanvullenMarker, dagPlanning.automatischAanvullen]);

    const onDragEnd = useCallback((result: DropResult, provided: ResponderProvided) => {
        if (result.reason === "DROP") {
            if (!result.destination) {
                return;
            }

            const rawSourceIndex = result.source.index;
            const rawTargetIndex = result.destination.index;

            // slice omdat we de start en eind "STOP" entries uit de lijst van draggables houden
            const tmpEntries = entries.slice(1, entries.length - 1);

            const draggableType = tmpEntries[rawSourceIndex].type;

            if (draggableType === DagPlanningTimelineEntryType.DAG_PLANNING_ENTRY) {
                onDragBezoek(tmpEntries, rawSourceIndex, rawTargetIndex);
            } else if (draggableType === DagPlanningTimelineEntryType.AUTOMATISCH_AANVULLEN_MARKER) {
                onDragAutomatischAanvullenMarker(tmpEntries, rawSourceIndex, rawTargetIndex);
            }

        }
    }, [entries, onDragBezoek, onDragAutomatischAanvullenMarker]);

    return (
        <>
            <Row className="mb-2">
                <Col className="d-flex justify-content-end">
                    <ActionBarButton label="Voeg bezoek toe"
                                     visible={!!voegBezoekToe && DagPlanningStatus.NIETS_PLANNEN !== dagPlanning.status}
                                     handler={voegBezoekToe}
                                     icon={<i className="mdi mdi-map-marker-plus mr-1"/>}
                                     loading={loading || false}
                                     color="primary"/>

                    {!!voegBezoekToe && DagPlanningStatus.NIETS_PLANNEN !== dagPlanning.status && (
                        <AligneerDropDown loading={loading || false} onBevestig={aligneer}/>
                    )}

                    <ActionBarButton label='Communiceer'
                                     visible={!!communiceer && (DagPlanningStatus.ONTWERP === dagPlanning.status || DagPlanningStatus.VASTGELEGD === dagPlanning.status) &&
                                         (dagPlanning.gecommuniceerdOpAndereDatumBezoekenCount > 0 || dagPlanning.nietGecommuniceerdeBezoekenCount > 0)}
                                     handler={communiceer}
                                     icon={<i className="mdi mdi-email-check-outline mr-1"></i>}
                                     loading={loading || false}
                                     color='primary'/>
                </Col>
            </Row>

            {dagPlanning && (
                <div className="mb-1 mr-2 w-100 text-right">
                    <small className="text-muted">
                        #{dagPlanning.versie} - <Datum datum={dagPlanning.versieTijdstip}/>
                    </small>
                </div>
            )}

            <div className="dagplanning-timeline">
                {entries.length > 0 && (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <>
                            {showInstructieEntry && <PlanningInstructieCard dagPlanning={dagPlanning}/>}

                            <PlanningStop entry={entries[0].dagPlanningEntry!} stopType={DagPlanningStopType.START} dagPlanning={dagPlanning} startStopAdresWijzigen={startStopAdresWijzigen}/>
                            <Droppable droppableId="planning-entry">
                                {(provided, droppableSnapshot) => (
                                    <>
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {draggableItems}
                                        </div>

                                        {provided.placeholder}
                                    </>
                                )}
                            </Droppable>
                            <PlanningStop entry={entries[entries.length - 1].dagPlanningEntry!} stopType={DagPlanningStopType.EINDE} dagPlanning={dagPlanning} startStopAdresWijzigen={startStopAdresWijzigen}/>
                            <div className="end-dot"/>
                        </>
                    </DragDropContext>
                )}
            </div>
        </>
    );
};
