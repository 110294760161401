import React, {CSSProperties, LegacyRef, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import Switch from "react-bootstrap/Switch";
import {DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps} from "react-beautiful-dnd";
import {DagPlanningAutomatischAanvullenModel} from "../../../../../redux/planning/types";
import {HelpTooltip} from "../../../../../components/aqualex/HelpTooltip";
import {useTranslation} from "../../../../../helpers/i18nUtils";

export interface PlanningAutomatischAanvullenMarkerProps {
    automatischAanvullen: DagPlanningAutomatischAanvullenModel | undefined;
    schakelAutomatischAanvullenMarker?: (automatischAanvullen: boolean) => Promise<any>;

    innerRef: LegacyRef<any>;

    draggableProps: DraggableProvidedDraggableProps;
    dragHandleProps?: DraggableProvidedDragHandleProps;

    style?: CSSProperties;
}

export const PlanningAutomatischAanvullenMarker: React.FC<PlanningAutomatischAanvullenMarkerProps> = (props) => {
    const {
        automatischAanvullen,
        schakelAutomatischAanvullenMarker,
        innerRef,
        draggableProps,
        dragHandleProps,
        style
    } = props;

    const {t} = useTranslation("planning");

    const [switchValue, setSwitchValue] = useState(automatischAanvullen?.automatischAanvullen || false);
    const switchRef = useRef<HTMLInputElement>();

    const onSwitchContainerClick = () => {
        switchRef.current?.click?.();
    };

    const onSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        try {
            await schakelAutomatischAanvullenMarker?.(checked);

            setSwitchValue(checked);
        } catch (error: any) {

        }
    };

    return (
        <div className="timeline-item pt-2" {...draggableProps} ref={innerRef} style={style}>
            <div className="dagplanning-automatisch-aanvullen">
                <Row className="">
                    <Col xs="auto" className="d-flex align-items-center">
                        <div className="pl-3"
                             onClick={onSwitchContainerClick}> {/* Staat op de div want de Switch heeft een bug waardoor je maar op een enkele pixel of zo kan klikken */}
                            <Switch checked={switchValue} onChange={onSwitchChange} ref={switchRef}/>
                        </div>
                    </Col>
                    <Col className="p-2">
                        <strong>
                            {t("Labels.automatisch-aanvullen", "Automatisch aanvullen")}{" "}
                            <HelpTooltip id="automatisch-aanvullen-help">
                                {t("Tooltip.bezoeken-met-gestippelde-lijn", "Bezoeken met een gestippelde lijn zijn tijdelijk en nog niet definitief ingepland. Leg de planning vast, of schakel 'Automatisch aanvullen' uit om te bevestigen.")}
                            </HelpTooltip>
                        </strong><br/>
                        <span className="text-muted">
                            {t("PlanningAutomatischAanvullenMarker.automatisch-aanvullen-indien-ingeschakeld", "Indien ingeschakeld, zal de automatische planning hiertussen aanvullen.")}
                        </span>
                    </Col>
                    <Col xs="auto">
                        <div className="h-100 px-2 planning-entry-drag-handle d-flex align-items-center justify-content-center" {...dragHandleProps}>
                            <i className="mdi mdi-drag-vertical font-24"/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
