import * as React from "react";
import VerwijderdIndicator from "./VerwijderdIndicator";
import {VerzendAdresModel} from "../../redux/verzendadres/types";

interface VerzendAdresLabelProps {
    verzendAdres: Pick<VerzendAdresModel, "code" | "naam" | "verwijderd">;
    toonVerwijderd?: boolean;
}

const VerzendAdresLabel: React.FC<VerzendAdresLabelProps> = ({verzendAdres, toonVerwijderd = true}) => (
    <>
        <strong>{verzendAdres.code}</strong> {verzendAdres.naam} {toonVerwijderd &&
        <VerwijderdIndicator verwijderd={verzendAdres.verwijderd}/>}
    </>
);

export default VerzendAdresLabel;
