import {useCallback} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {selectDownloadFileByUriState} from "./selectors";
import {downloadFileByUri} from "./actions";
import {RootState} from "../reducers";

export interface UseDownloadFileByUriOptions {
    uri: string;
    omschrijving?: string;

    onSuccessCallback?: () => void;
}

export const useDownloadFileCallback = () => {
    const dispatch = useDispatch();

    return useCallback((options: UseDownloadFileByUriOptions) => {
        const {uri, omschrijving, onSuccessCallback} = options;

        dispatch(downloadFileByUri.request(uri, omschrijving, onSuccessCallback));
    }, [dispatch]);
}

export const useDownloadFileByUri = (options: UseDownloadFileByUriOptions) => {
    const {uri} = options;

    const downloadFile = useDownloadFileCallback();
    const state = useSelector((state: RootState) => selectDownloadFileByUriState(state, uri));

    const callback = useCallback(() => {
        downloadFile(options);
    }, [downloadFile, options]);

    return [callback, state] as [typeof callback, typeof state];
};
