import * as React from "react";
import ConnectedComponentSpinner from "./ConnectedComponentSpinner";
import {useGetArtikelById} from "../../../redux/artikel/api";
import {ArtikelModel} from "../../../redux/artikel/types";
import {useTranslation} from "../../../helpers/i18nUtils";


interface ArtikelNrOmschrijvingProps {
    artikelId?: string;
    artikel?: Pick<ArtikelModel, "nr" | "omschrijving">;
}

const ArtikelNrOmschrijving: React.FC<ArtikelNrOmschrijvingProps> = ({artikelId, artikel}) => {
    const {data: fetchedArtikel} = useGetArtikelById(artikelId!, {skip: !artikelId || !!artikel});

    const {t} = useTranslation("algemeen");

    const item = artikel || fetchedArtikel;

    if (item) {
        return <><strong>{item.nr}</strong> {item.omschrijving}</>
    } else if (artikelId) {
        return <ConnectedComponentSpinner entity={t("Artikel.artikel", "Artikel")} id={artikelId}/>
    } else {
        return <span className="text-muted">{t("Artikel.geen-artikel", "Geen artikel")}</span>
    }

};

export default ArtikelNrOmschrijving;
