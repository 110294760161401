import {KlantModel} from "../klant/types";
import {VerzendAdresModel} from "../verzendadres/types";
import {MedewerkerModel} from "../medewerker/types";
import {OpdrachtType, PrestatieModel, PrioriteitHint, Skill} from "../planning/types";
import {BezoekAanvraagGroepModel} from "../bezoekaanvraaggroep/types";

export enum BezoekAanvraagStatus {
    NIEUW = 'NIEUW',
    TE_PLANNEN = 'TE_PLANNEN',
    GEPLAND = 'GEPLAND',
    GEANNULEERD = 'GEANNULEERD',
    BEZOCHT = 'BEZOCHT'
}

export interface BezoekAanvraagOverzichtModel {
    id: string;
    nummer: string;

    status: string;

    prestatieVanDatum: string;
    prestatieTotDatum: string;

    vanDatum: string;
    totDatum: string;

    gecommuniceerdeDatums: string[];
    gecommuniceerdOpTijdstip?: string;

    vanTijdstip: string;
    totTijdstip: string;

    klantNr: string;
    klantNaam: string;
    klantBedrijfId: string;
    klantAdres: string;
    klantAdres2: string;
    klantPlaats: string;
    klantLand: string;
    klantPostcode: string;
    klantVerwijderd: boolean;

    verzendAdresCode: string;
    verzendAdresNaam: string;
    verzendAdresAdres: string;
    verzendAdresAdres2: string;
    verzendAdresPlaats: string;
    verzendAdresLand: string;
    verzendAdresPostcode: string;

    skills: string[];

    prestatieCount: number;

    prestatieSamenvatting: BezoekAanvraagPrestatieSamenvattingModel[];

    heeftGroep: boolean;

    planning: BezoekAanvraagPlanningModel;

    dagPlanningInternePlannersNotities?: string;
    techniekerInstructie?: string;
    voorbereidendeStappen: BezoekAanvraagVoorbereidendeStappenModel;
}

export interface BezoekAanvraagPrestatieSamenvattingModel {
    opdrachtType: OpdrachtType;
    aantal: number;
}

export interface BezoekAanvraagVoorbereidendeStappenModel {
    aanvraagVergunningAutovrijeZoneNodig: boolean;
    aanvraagVergunningParkeerzoneNodig: boolean;
    techniekerOpVoorhandAanmeldenNodig: boolean;
}

export interface BezoekAanvraagModel {
    id: string;
    nummer: string;
    status: BezoekAanvraagStatus;
    klant: KlantModel;
    verzendAdres: VerzendAdresModel;
    prestaties: Array<PrestatieModel>;

    prestatieVanDatum: Date;
    prestatieTotDatum: Date;

    vanDatum?: Date;
    totDatum?: Date;

    gecommuniceerdeDatums: string[];
    gecommuniceerdOpTijdstip?: string;

    vanTijdstip?: string;
    totTijdstip?: string;

    toegelatenTechniekers: Array<MedewerkerModel>;
    uitgeslotenTechniekers: Array<MedewerkerModel>;

    techniekerInstructie?: string;

    planning: BezoekAanvraagPlanningModel[];
    groep?: BezoekAanvraagGroepModel;

    prioriteitHint: PrioriteitHint;

    verwachteDuur: number;
    geplandeDuur: number;

    skills: Array<Skill>;

    serviceBezoekOpvolgingId?: string;
    automatischPlannen: boolean;

    voorbereidendeStappen: BezoekAanvraagVoorbereidendeStappenModel;
}

export interface UpdateBezoekAanvraagForm {
    techniekerInstructie?: string;
}

export interface BezoekAanvraagPlanningModel {
    geplandBezoekId: string;
    datum: string;
    van: string;
    tot: string;
    techniekerId: string;
    primair: boolean;
}

export interface BezoekAanvraagFilters {
    status?: BezoekAanvraagStatus;
    statusIn?: Array<BezoekAanvraagStatus>;
    klantNr?: string;
    klantNaam?: string;
    verzendAdresId?: string;
    exclude?: Array<string>;
    vanDatum?: Date;
    totDatum?: Date;
    enkelManueelPlannen?: boolean; // vlag op verzendadres
    deelVanGroep?: boolean;
    prestatieId?: string;
    postcode?: string;

    gecommuniceerdeDatumVan?: Date;
    gecommuniceerdeDatumTot?: Date;
    nietGeplandOfBevrorenOpAndereDatum?: boolean;

    opdrachtTypeIn?: Array<OpdrachtType>;

    // compound filters, deze triggeren in de api meerdere zoekfilters
    manueelInTePlannen?: boolean; // dit is combinatie van enkelManueelPlannen + deelVanGroep
    mogelijkMetSkills?: string[];

    heeftVoorbereidendeStappen?: boolean;
    dagPlanningStatusIn?: string[];
}

export interface UpdateBezoekAanvraagPlanningParametersForm {
    vanDatum?: Date;
    totDatum?: Date;
    vanTijdstip: string;
    totTijdstip: string;
    toegelatenTechniekers: Array<string>;
    prioriteitHint?: PrioriteitHint;
    automatischPlannen?: boolean;

    aanvraagVergunningAutovrijeZoneNodig: boolean;
    aanvraagVergunningParkeerzoneNodig: boolean;
    techniekerOpVoorhandAanmeldenNodig: boolean;
}
