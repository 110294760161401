import {default as React} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {useDispatch} from 'react-redux';

import {Formik,} from 'formik';
import * as Yup from 'yup';
import {hideModal} from "../../../redux/ui/actions";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "react-i18next";


export const BevestigBestelbonModal = ({
                                           bestelbon,
                                           onBevestigHandler
                                       }) => {

    const dispatch = useDispatch();

    const onBevestig = (values) => {
        dispatch(hideModal());
        onBevestigHandler({
            installatieInfoTeMelden: !values.geenInstallatieInfoTeMelden,
            installatieInfo: values.installatieInfo
        });
    }

    const {t} = useTranslation("bestelbon");

    const verdereUitlegIsVerplichtLabel = t("BevestigBestelBonModal.verdere-uitleg-is-verplicht",
        "Verdere uitleg is verplicht");


    const verdereUitlegMinimaleLengte = 10;

    const verdereUitlegMinimaleLengteLabel = t("BevestigBestelBonModal.verdere-uitleg-is-verplicht-en-minstens-x-karakters",
        'Verdere uitleg is verplicht, minstens {{lengte}} karakters',
        {lengte: verdereUitlegMinimaleLengte});

    const verdereUitlegMaximaleLengte = 1000;

    const verdereUitlegMaximaleLengteLabel = t("BevestigBestelBonModal.verdere-uitleg-is-verplicht-en-maximum-x-karakters",
        'Verdere uitleg is verplicht, maar mag maximaal {{lengte}} karakters bevatten',
        {lengte: verdereUitlegMinimaleLengte});

    const schema = Yup.object({
        geenInstallatieInfoTeMelden: Yup.boolean(),
        installatieInfo: Yup.string()
            .when('geenInstallatieInfoTeMelden', {
                is: (geenInstallatieInfoTeMelden) => (geenInstallatieInfoTeMelden),
                then: Yup.string().max(1000),
                otherwise: Yup.string()
                    .required(verdereUitlegIsVerplichtLabel)
                    .min(verdereUitlegMinimaleLengte,
                        verdereUitlegMinimaleLengteLabel)
                    .max(verdereUitlegMaximaleLengte,
                        verdereUitlegMaximaleLengteLabel),
            }),
    });

    const installatieInfoLabel = t("Labels.installatie-info", "Installatie info");
    const installatieInfoPlaceholderLabel = t("Labels.geen-informatie", "Geen informatie");
    const geenInstallatieInfoTeMeldenLabel = t("Labels.ik-bevestig-dat-er-geen-informatie-te-melden-is",
        "Ik bevestig dat er geen informatie te melden is");

    return (
        <Modal show={true} onHide={hideModal} size='lg' autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.bestelbon-bevestigen",
                    "Bestelbon bevestigen")}</ModalTitle>
            </ModalHeader>

            <Formik validationSchema={schema}
                    onSubmit={onBevestig}
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={{
                        geenInstallatieInfoTeMelden: false,
                        installatieInfo: bestelbon.installatieInfo?.installatieInfo || "",
                    }}>
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting
                  }) => (
                    <Form noValidate>
                        <ModalBody>
                            <Alert variant='warning'>{t("BevestigBestelBonModal.deze-actie-is-definitief",
                                "Deze actie is definitief.")}</Alert>

                            <p>{t("BevestigBestelBonModal.bij-bevestigen-van-de-bestelbom-kan-je-nog-extra-informatie-doorgeven",
                                "Bij bevestigen van de bestelbon kan je nog extra informatie i.v.m. de installatie doorgeven.")}</p>

                            <TextAreaFormField name="installatieInfo"
                                               label={installatieInfoLabel}
                                               rows={4}
                                               maxLength={1000}
                                               placeholder={installatieInfoPlaceholderLabel}/>

                            <p>{t("BevestigBestelbonModal.indien-er-geen-informatie-door-te-geven-is-kan-je-dit-hier-melden",
                                "Indien er geen informatie door te geven is, kan je dit hieronder expliciet melden.")}</p>

                            <Form.Group controlId="geenInstallatieInfoTeMelden">
                                <Form.Check type="checkbox"
                                            label={geenInstallatieInfoTeMeldenLabel}
                                            checked={values.geenInstallatieInfoTeMelden}
                                            onChange={handleChange}
                                            isValid={isSubmitting && !errors.geenInstallatieInfoTeMelden}
                                            isInvalid={!!errors.geenInstallatieInfoTeMelden}/>
                            </Form.Group>

                        </ModalBody>

                        <ModalFooter>
                            <Button variant='light' onClick={() => dispatch(hideModal())}>{t("algemeen:Buttons.annuleer",
                                "Annuleer")}</Button>{' '}
                            <Button variant='primary' disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestigen", "Bevestigen")}</Button>
                        </ModalFooter>
                    </Form>)}
            </Formik>

        </Modal>
    );
};

