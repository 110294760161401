import {
    BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_FAILURE,
    BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_REQUEST,
    BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_SUCCESS,
    BULK_UPLOAD_DAG_PLANNINGEN_FAILURE,
    BULK_UPLOAD_DAG_PLANNINGEN_REQUEST,
    BULK_UPLOAD_DAG_PLANNINGEN_SUCCESS,
    DAG_PLANNING_CAPACITEIT_PROJECTION_FAILURE,
    DAG_PLANNING_CAPACITEIT_PROJECTION_REQUEST,
    DAG_PLANNING_CAPACITEIT_PROJECTION_SUCCESS,
    FETCH_DAG_PLANNING_BEZOEKEN_FAILURE,
    FETCH_DAG_PLANNING_BEZOEKEN_REQUEST,
    FETCH_DAG_PLANNING_BEZOEKEN_SUCCESS,
    FETCH_DAG_PLANNINGEN_FAILURE,
    FETCH_DAG_PLANNINGEN_REQUEST,
    FETCH_DAG_PLANNINGEN_SUCCESS,
    FETCH_GEPLAND_BEZOEK_BY_ID_FAILURE,
    FETCH_GEPLAND_BEZOEK_BY_ID_REQUEST,
    FETCH_GEPLAND_BEZOEK_BY_ID_SUCCESS,
    FETCH_OPDRACHT_BY_ID_FAILURE,
    FETCH_OPDRACHT_BY_ID_REQUEST,
    FETCH_OPDRACHT_BY_ID_SUCCESS,
    FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_FAILURE,
    FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_REQUEST,
    FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_SUCCESS,
    FETCH_PRESTATIES_BY_OPDRACHTID_FAILURE,
    FETCH_PRESTATIES_BY_OPDRACHTID_REQUEST,
    FETCH_PRESTATIES_BY_OPDRACHTID_SUCCESS,
    GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_FAILURE,
    GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_REQUEST,
    GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_SUCCESS,
    GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_FAILURE,
    GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_REQUEST,
    GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_SUCCESS,
    HERBEZOEK_ONDERHOUD_SERVICE_ORDER_FAILURE,
    HERBEZOEK_ONDERHOUD_SERVICE_ORDER_REQUEST,
    HERBEZOEK_ONDERHOUD_SERVICE_ORDER_SUCCESS,
} from "./constants";
import {ApiError} from "../ui/types";
import {EntityDict} from "../support/pagination";
import {KlantModel, MinimalKlantModel} from "../klant/types";
import {AdresModel, MinimalVerzendAdresModel, VerzendAdresModel} from "../verzendadres/types";
import {MedewerkerModel} from "../medewerker/types";
import {AfstandModel, ReduxAction_legacy} from "../support/types";
import {Co2BestellingModel} from "../co2bestelling/types";
import {MinimalInstallatieModel, TeInstallerenToestelModel} from "../installatie/types";
import {AccessoireBestellingModel} from "../accessoirebestelling/types";
import {_Links} from "../types";
import {InterventieModel} from "../interventie/types";
import {OperationeelToestelModel} from "../toestel/types";

export type FetchOpdrachtByIdAction = ReduxAction_legacy<typeof FETCH_OPDRACHT_BY_ID_REQUEST | typeof FETCH_OPDRACHT_BY_ID_SUCCESS | typeof FETCH_OPDRACHT_BY_ID_FAILURE>;
export type BehandelServiceOrderMetDynamicsAction = ReduxAction_legacy<typeof BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_REQUEST | typeof BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_SUCCESS | typeof BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_FAILURE>;
export type HerbezoekOnderhoudServiceOrderAction = ReduxAction_legacy<typeof HERBEZOEK_ONDERHOUD_SERVICE_ORDER_REQUEST | typeof HERBEZOEK_ONDERHOUD_SERVICE_ORDER_SUCCESS | typeof HERBEZOEK_ONDERHOUD_SERVICE_ORDER_FAILURE>;
export type GenerateOpdrachtenForOnderhoudenInitieelServiceOrdersAction = ReduxAction_legacy<typeof GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_REQUEST | typeof GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_SUCCESS | typeof GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_FAILURE>;
export type GenerateServiceOrderOpdrachtenFromServiceOrderNrsAction = ReduxAction_legacy<typeof GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_REQUEST | typeof GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_SUCCESS | typeof GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_FAILURE>;

export type FetchPrestatiesByOpdrachtIdAction = ReduxAction_legacy<typeof FETCH_PRESTATIES_BY_OPDRACHTID_REQUEST | typeof FETCH_PRESTATIES_BY_OPDRACHTID_SUCCESS | typeof FETCH_PRESTATIES_BY_OPDRACHTID_FAILURE>;
export type FetchPrestatieVerwachteDuurtijdProjectionAtion = ReduxAction_legacy<typeof FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_REQUEST | typeof FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_SUCCESS | typeof FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_FAILURE>;

export type FetchDagPlanningenAction = ReduxAction_legacy<typeof FETCH_DAG_PLANNINGEN_REQUEST | typeof FETCH_DAG_PLANNINGEN_SUCCESS | typeof FETCH_DAG_PLANNINGEN_FAILURE>;
export type FetchDagPlanningBezoekenAction = ReduxAction_legacy<typeof FETCH_DAG_PLANNING_BEZOEKEN_REQUEST | typeof FETCH_DAG_PLANNING_BEZOEKEN_SUCCESS | typeof FETCH_DAG_PLANNING_BEZOEKEN_FAILURE>;
export type BulkUploadDagPlanningenAction = ReduxAction_legacy<typeof BULK_UPLOAD_DAG_PLANNINGEN_REQUEST | typeof BULK_UPLOAD_DAG_PLANNINGEN_SUCCESS | typeof BULK_UPLOAD_DAG_PLANNINGEN_FAILURE>;
export type DagPlanningCapaciteitAction = ReduxAction_legacy<typeof DAG_PLANNING_CAPACITEIT_PROJECTION_REQUEST | typeof DAG_PLANNING_CAPACITEIT_PROJECTION_SUCCESS | typeof DAG_PLANNING_CAPACITEIT_PROJECTION_FAILURE>;
export type FetchGeplandBezoekByIdAction = ReduxAction_legacy<typeof FETCH_GEPLAND_BEZOEK_BY_ID_REQUEST | typeof FETCH_GEPLAND_BEZOEK_BY_ID_SUCCESS | typeof FETCH_GEPLAND_BEZOEK_BY_ID_FAILURE>;

export type PlanningActionTypes =
    FetchOpdrachtByIdAction |
    BehandelServiceOrderMetDynamicsAction |
    HerbezoekOnderhoudServiceOrderAction |
    FetchPrestatiesByOpdrachtIdAction |
    FetchPrestatieVerwachteDuurtijdProjectionAtion |
    FetchDagPlanningenAction |
    FetchDagPlanningBezoekenAction |
    BulkUploadDagPlanningenAction |
    DagPlanningCapaciteitAction |
    FetchGeplandBezoekByIdAction
    ;

export type PrestatieByOpdrachtIdDict = {
    [serviceId: string]: Array<string>
}

export type PrestatieVerwachteDuurtijdPerMaandDict = {
    [maand: string]: {
        [type: string]: Array<PrestatieVerwachteDuurtijdProjectionModel>
    }
}

export enum DagPlanningStatus {
    ONTWERP = 'ONTWERP',
    VASTGELEGD = 'VASTGELEGD',
    GEARCHIVEERD = 'GEARCHIVEERD',
    NIETS_PLANNEN = 'NIETS_PLANNEN'
}

export enum DagPlanningFase {
    KEY = "KEY",
    INSTALLATIE = "INSTALLATIE",
    INTERVENTIE = "INTERVENTIE",
    ONDERHOUD = "ONDERHOUD",
    AUTOMATISCH_PLANNEN = "AUTOMATISCH_PLANNEN"
}

export interface DagPlanningOverzichtModel {
    id: string;
    medewerker: MedewerkerModel;
    datum: string;
    status: DagPlanningStatus;
    planningsFase: DagPlanningFase;
    start: string;
    eind: string;
    startAdres?: AdresModel;
    eindAdres?: AdresModel;
    opmerking?: string;
    internePlannersNotities?: string;
    afstandInMeter: number;
    opdrachtTypesCount: OpdrachtTypeCountModel;
    geplandeBezoekenCount: number;
    nietGecommuniceerdeBezoekenCount: number;
    gecommuniceerdOpJuisteDatumBezoekenCount: number;
    gecommuniceerdOpAndereDatumBezoekenCount: number;
    geplandBezoekenPlaatsen: string[];
    minutenBeschikbaar: number;
    minutenBeschikbaarExclusiefVoorstellen: number;
    ladingGewicht: number;
    versie: number;
    versieTijdstip: string;
}

export interface DagPlanningModel {
    id: string;
    medewerker: MedewerkerModel;
    datum: string;
    status: DagPlanningStatus;
    planningsFase: DagPlanningFase;
    start: string;
    eind: string;
    startAdres?: AdresModel;
    eindAdres?: AdresModel;
    opmerking?: string;
    internePlannersNotities?: string;
    entries: Array<DagPlanningEntryModel>;
    afstandInMeter: number;
    opdrachtTypesCount: OpdrachtTypeCountModel;
    geplandeBezoekenCount: number;
    nietGecommuniceerdeBezoekenCount: number;
    gecommuniceerdOpJuisteDatumBezoekenCount: number;
    gecommuniceerdOpAndereDatumBezoekenCount: number;
    geplandBezoekenPlaatsen: string[];
    minutenBeschikbaar: number;
    minutenBeschikbaarExclusiefVoorstellen: number;
    ladingGewicht: number;
    automatischAanvullen?: DagPlanningAutomatischAanvullenModel;
    versie: number;
    versieTijdstip: string;
}

export interface DagPlanningOmwegBerekeningModel {
    vanBezoekId?: string;
    naarBezoekId?: string;
    afstand: AfstandModel;
}

export interface DagPlanningAutomatischAanvullenModel {
    automatischAanvullen: boolean;
    automatischAanvullenNaBezoekIndex?: number;
}

export type OpdrachtTypeCountModel = {
    [type in OpdrachtType]: number
};

export enum DagPlanningEntryType {
    STOP = 'STOP',
    RIT = 'RIT',
    BEZOEK = 'BEZOEK',
    PAUZE = 'PAUZE',
}

export interface DagPlanningEntryModel {
    id: string;
    type: DagPlanningEntryType;
    van: string;
    tot: string;
    geplandBezoekId?: string;
    bezoekAanvraagId?: string;
    adres?: AdresModel;
    afstand?: number;
    bezoek?: DagPlanningBezoekModel;
}

export interface DagPlanningBezoekModel {
    bezoekAanvraagId: string;

    bezoekAanvraagInstructie?: string;
    verzendAdresInstuctie?: string;

    bezoekIndex: number;
    bezoekNummer: number;

    isVoorstel: boolean;

    gecommuniceerdeDatums: string[];
    gecommuniceerdOpTijdstip?: string;

    opdrachtTypes: OpdrachtType[];
    prestatieCount: number;

    klant: MinimalKlantModel;
    verzendAdres: MinimalVerzendAdresModel;
}

export interface DagPlanningWrapper {
    dagPlanning: DagPlanningModel;
    entries: Array<DagPlanningEntryWrapper>;
}

export enum DagPlanningStopType {
    START = "START",
    STOP = "STOP",
    EINDE = "EINDE",
}

export interface DagPlanningEntryWrapper {
    entry: DagPlanningEntryModel;
    placeholder?: boolean;
    start?: boolean;
    bezoekIndex?: number;
    omschrijving: any;
    adres?: AdresModel;
    geplandBezoekId?: string;
    stopType?: DagPlanningStopType;
}

export interface DagPlanningCapaciteitProjectionModel {
    maand: string;
    geplandeTijd: number;
    geplandeBezoekTijd: number;
    geplandeRitTijd: number;
    geplandePauzeTijd: number;
    geplandeStopTijd: number;
}

export type PlanningState = {
    prestatiesByOpdrachtId: PrestatieByOpdrachtIdDict,
    dagPlanningenByMedewerkerIdAndDatum: EntityDict<EntityDict<string>>,
    geplandeBezoekenByDagPlanningId: EntityDict<Array<string>>,
    entities: {
        opdracht: EntityDict<OpdrachtModel>,
        prestatie: EntityDict<ExtendedPrestatieModel>,
        geplandBezoek: EntityDict<GeplandBezoekModel>,
        dagPlanning: EntityDict<DagPlanningModel>,
    },
    projections: {
        bezoekenPerMedewerkerPerDag: EntityDict<Array<BezoekenPerMedewerkerPerDagProjectionModel>>
        prestatieVerwachteDuurtijdPerMaand: PrestatieVerwachteDuurtijdPerMaandDict,
        dagPlanningCapaciteit: Array<DagPlanningCapaciteitProjectionModel>
    }
    loading: boolean,
    error?: ApiError
};

export enum TijdsvensterType {
    DAG = "DAG",
    MAAND = "MAAND",
    PERIODE = "PERIODE",
    VAN_TOT = "VAN_TOT"
}

type BaseTijdsvensterModel<T extends TijdsvensterType> = {
    type: T;
    vroegsteDatum: string;
};

export type DagTijdsvensterModel = BaseTijdsvensterModel<TijdsvensterType.DAG>;

export type MaandTijdsvensterModel = BaseTijdsvensterModel<TijdsvensterType.MAAND> & {
    maand: string;
};

export type VanTotTijdsvensterModel = BaseTijdsvensterModel<TijdsvensterType.VAN_TOT> & {
    tot: string;
};

export type PeriodeTijdsvensterModel = BaseTijdsvensterModel<TijdsvensterType.PERIODE> & {
    periode: string;
};

export type TijdsvensterModel =
    DagTijdsvensterModel
    | MaandTijdsvensterModel
    | PeriodeTijdsvensterModel
    | VanTotTijdsvensterModel;

export enum OpdrachtType {
    TOESTEL_INSTALLATIE = "TOESTEL_INSTALLATIE",
    TOESTEL_ONDERHOUD = "TOESTEL_ONDERHOUD",
    ONDERHOUD_SERVICE_ORDER = "ONDERHOUD_SERVICE_ORDER",
    CO2_LEVERING = "CO2_LEVERING",
    ACCESSOIRE_LEVERING = "ACCESSOIRE_LEVERING",
    TOESTEL_INTERVENTIE = "TOESTEL_INTERVENTIE",
}

export enum ToestelOnderhoudType {
    REINIGEN = "REINIGEN",
    REINIGEN_MET_UV = "REINIGEN_MET_UV",
    REINIGEN_FILTER_VERVANGEN = "REINIGEN_FILTER_VERVANGEN",
    REINIGEN_MET_UV_FILTER_VERVANGEN = "REINIGEN_MET_UV_FILTER_VERVANGEN",
}

export interface SkillSetModel {
    onderhoud: boolean;
    installatie: boolean;
    levering: boolean;
}

interface BaseOpdrachtModel<T extends OpdrachtType> {
    id: string;
    type: T;
    klant: KlantModel;
    verzendAdres: VerzendAdresModel;
    verwachteDuurtijd: number;

    tijdsvenster: TijdsvensterModel;
    vanDatum: string;
    totDatum: string;

    skillSet: SkillSetModel;
    taal: string;
    laadVolume: string;
}

export type OnderhoudServiceOrderOpdrachtModel = BaseOpdrachtModel<OpdrachtType.ONDERHOUD_SERVICE_ORDER> & {
    toestelId: string;
    toestel: OperationeelToestelModel;
    serviceOrderBedrijfId: string;
    serviceOrderNr: string;
    serviceOrderPlatformId: string;
    serviceOrderSoort: string;
    serviceOrderOmschrijving: string;
    _links: _Links;
};

export type ToestelOnderhoudOpdrachtModel = BaseOpdrachtModel<OpdrachtType.TOESTEL_ONDERHOUD> & {
    serviceId: string;
    onderhoudType: ToestelOnderhoudType;
    toestelId: string;
};

export type ToestelInstallatieOpdrachtModel = BaseOpdrachtModel<OpdrachtType.TOESTEL_INSTALLATIE> & {
    installatie: MinimalInstallatieModel;
    teInstallerenToestel: TeInstallerenToestelModel;
};

export type Co2LeveringOpdrachtModel = BaseOpdrachtModel<OpdrachtType.CO2_LEVERING> & {
    co2Bestelling: Co2BestellingModel;
};

export type AccessoireLeveringOpdrachtModel = BaseOpdrachtModel<OpdrachtType.ACCESSOIRE_LEVERING> & {
    accessoireBestelling: AccessoireBestellingModel;
};

export type InterventieOpdrachtModel = BaseOpdrachtModel<OpdrachtType.TOESTEL_INTERVENTIE> & {
    interventie: InterventieModel;
};

export type OpdrachtModel =
    OnderhoudServiceOrderOpdrachtModel
    | ToestelOnderhoudOpdrachtModel
    | ToestelInstallatieOpdrachtModel
    | Co2LeveringOpdrachtModel
    | AccessoireLeveringOpdrachtModel
    | InterventieOpdrachtModel;

export interface OpdrachtEnPrestatieFilters {
    klantNr?: string;
    klantNaam?: string;
    klantId?: string;
    verzendAdresId?: string;
    opdrachtType?: OpdrachtType;
    serviceOrderNr?: string;
    serviceId?: string;
    toestelNr?: string;
    tijdsvensterVan?: Date;
    tijdsvensterTot?: Date;
    zonderBezoekAanvraag?: boolean;
    status?: PrestatieStatus[],
}

export type PrestatiePage = {
    content: Array<ExtendedPrestatieModel>,
    number: number,
    size: number,
    totalElements: number,
    loading: boolean,
};

export enum Skill {
    INSTALLATIE_KRAAN = "INSTALLATIE_KRAAN",
    INSTALLATIE_KRAAN_MET_BOILER = "INSTALLATIE_KRAAN_MET_BOILER",
    INSTALLATIE_KRAAN_MET_KELDERUNIT = "INSTALLATIE_KRAAN_MET_KELDERUNIT",
    INSTALLATIE_KRAAN_MET_PYTHON = "INSTALLATIE_KRAAN_MET_PYTHON",
    INSTALLATIE_TOESTEL = "INSTALLATIE_TOESTEL",
    INSTALLATIE_CASA = "INSTALLATIE_CASA",
    INSTALLATIE_BOREN_DOOR_STEEN = "INSTALLATIE_BOREN_DOOR_STEEN",
    INTERVENTIE_COMPLEX = "INTERVENTIE_COMPLEX",
    INTERVENTIE_STANDAARD = "INTERVENTIE_STANDAARD",
    INTERVENTIE_EENVOUDIG = "INTERVENTIE_EENVOUDIG",
    ONDERHOUD = "ONDERHOUD",
    LEVERING_CO2_APART = "LEVERING_CO2_APART",
    LEVERING_CO2_GECOMBINEERD = "LEVERING_CO2_GECOMBINEERD",
    LEVERING_ACCESSOIRES = "LEVERING_ACCESSOIRES",
    LEVERING_TOESTEL = "LEVERING_TOESTEL"
}

export const InterventieSkill = {
    [Skill.INTERVENTIE_COMPLEX]: Skill.INTERVENTIE_COMPLEX,
    [Skill.INTERVENTIE_STANDAARD]: Skill.INTERVENTIE_STANDAARD,
    [Skill.INTERVENTIE_EENVOUDIG]: Skill.INTERVENTIE_EENVOUDIG
}

export interface PrestatieModel {
    id: string;
    opdracht: OpdrachtModel;
    status: PrestatieStatus;
    opmerking: string;
    vanDatum: Date;
    totDatum: Date;
}

export interface ExtendedPrestatieModel extends PrestatieModel {
    zonderBezoekAanvraag?: boolean;
}

export enum PrestatieStatus {
    UIT_TE_VOEREN = 'UIT_TE_VOEREN',
    VOLTOOID = 'VOLTOOID',
    ONVOLTOOID = 'ONVOLTOOID',
    GEANNULEERD = 'GEANNULEERD',
}

export enum PrioriteitHint {
    ZEER_DRINGEND = 'ZEER_DRINGEND',
    INSTALLATIE = 'INSTALLATIE',
    INTERVENTIE_MET_SLA = 'INTERVENTIE_MET_SLA',
    INTERVENTIE_MET_LEK = 'INTERVENTIE_MET_LEK',
    INTERVENTIE = 'INTERVENTIE',
    LEVERING = 'LEVERING',
    ONDERHOUD_DRINGEND = 'ONDERHOUD_DRINGEND',
    ONDERHOUD_MET_LEVERING = 'ONDERHOUD_MET_LEVERING',
    ONDERHOUD_ZONDER_LEVERING = 'ONDERHOUD_ZONDER_LEVERING',
    MINST_DRINGEND = 'MINST_DRINGEND',
    AUTOMATISCH = 'AUTOMATISCH',
}

export interface GeplandBezoekModel {
    id: string;
    gecommuniceerd: boolean;
    medewerkerId: string;
    datum: string;
    start: string;
    einde: string;
}

export interface BezoekenPerMedewerkerPerDagProjectionModel {
    dagPlanningId: string;
    medewerker: MedewerkerModel;
    datum: Date;
    status: DagPlanningStatus;
    bezoekenCount: number
    afstand: number
}

export interface PrestatieVerwachteDuurtijdProjectionModel {
    maand: string;
    type: OpdrachtType;
    inBezoek: boolean;
    verwachteDuurtijdPerMaand: number;
}


export interface VoegDagPlanningenToeRequest {
    datum: string;
    medewerkerIds: string[];

    individueleDag?: {
        startUur: string;
        eindUur: string;
        depotCode: string;
    };
    periode?: {
        totDatum: string;
    };
}

export interface UpdateDagPlanningForm {
    start: string;
    eind: string;
    startAdres: AdresModel;
    standaardStartAdres?: boolean;
    eindAdres: AdresModel;
    standaardEindAdres?: boolean;
    startDepot: string;
    eindDepot: string;
    opmerking: string;
    internePlannersNotities?: string;
    planningsFase: DagPlanningFase;
}

export interface UpdateDagPlanningAutomatischAanvullenForm {
    automatischAanvullen: boolean;
    automatischAanvullenNaBezoekIndex?: number;
}

export interface DagPlanningVoegBezoekToeForm {
    bezoekAanvraagId: string;
    index: number;
    tePlannenDuurtijd: number;
}

export interface OpdrachtPlanningModel {
    opdrachtId: string;
    entries: OpdrachtPlanningEntryModel[];
}

export interface WijzigDuurtijdBezoekForm {
    duurtijd: number;
}

export interface OpdrachtPlanningEntryModel {
    geplandeDatum: string;
    geplandVanTijdstip: string;
    geplandTotTijdstip: string;
    medewerkerId: string;
    dagPlanningStatus?: DagPlanningStatus;
}
