import {default as React} from "react";
import {Form} from "react-bootstrap";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

export interface BetterSelectFormFieldProps<TItem> {
    showEmpty?: boolean;
    emptyLabel?: string;
    items: TItem[];
    keyGetter: (item: TItem) => string;
    formatter: (item: TItem) => string;
}

export const BetterSelectFormField = <TItem, >({
                                                   disabled,
                                                   showEmpty = true,
                                                   emptyLabel,
                                                   items,
                                                   keyGetter,
                                                   formatter,
                                                   ...props
                                               }: FormikFormFieldProps & BetterSelectFormFieldProps<TItem>) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => {

                const selectedKey = field.value ? keyGetter(field.value) : undefined;

                return (
                    <Form.Control {...field}
                                  value={selectedKey}
                                  onChange={async event => {
                                      const key = event.target.value;

                                      const selectedItem = key === undefined
                                          ? undefined
                                          : items.find(item => key === keyGetter(item));

                                      await helpers.setValue(selectedItem);
                                      props.onChange && props.onChange(selectedItem);
                                  }}
                                  as="select"
                                  disabled={disabled}
                                  isInvalid={!!meta.error}>
                        {showEmpty && (
                            <option value={undefined}>{emptyLabel}</option>
                        )}
                        {items?.map(item => {
                            const itemKey = keyGetter(item);
                            const selected = selectedKey === itemKey;

                            return (
                                <option key={itemKey}
                                        value={itemKey}
                                        selected={selected}> {/* selected is nodig omdat indien de value undefined wordt het veld niet leeg wordt */}
                                    {formatter(item)}
                                </option>
                            );
                        })}
                    </Form.Control>
                )
            }}
        </FormikFormField>
    );
};
