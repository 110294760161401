import {RootState} from "../reducers";
import {PrincipalModel} from "./types";

export const selectLoggedInUser = (state: RootState): PrincipalModel | undefined => {
    return state.Auth.loggedInUser;
};

export const selectLoggedInUserNaam = (state: RootState): string | undefined => {
    return state.Auth.loggedInUser?.naam;
};


export const selectUserIsLoggedIn = (state: RootState): boolean => {
    return state.Auth.loggedInUser !== undefined;
};