import {default as React} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {Form as FormikForm, Formik} from "formik";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";
import * as Yup from "yup";
import {FormFileUploadField} from "../../../components/aqualex/form/FormFileUploadField";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../Modal";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface CreateBijlageFormValues {
    bestand: File[];
    omschrijving?: string;
}

export interface CreateBijlageModalProps {
    initialValues?: CreateBijlageFormValues;
    loading?: boolean;

    onSubmit(form: CreateBijlageFormValues): void | Promise<void>;

    onCancel(): void;
}

export const CreateBijlageModal: React.FC<CreateBijlageModalProps> = (props) => {
    const {onSubmit, onCancel, initialValues, loading} = props;

    const {t} = useTranslation("algemeen");

    const formSchema = Yup.object<CreateBijlageFormValues>({
        omschrijving: Yup.string().max(500, t("Bijlage.Foutmeldingen.omschrijving-max-500-karakters", "Omschrijving kan maximum 500 karakters bevatten") as string),
        bestand: Yup.array<File>().max(1).required()
    });

    return (
        <Modal show={true} onHide={props.onCancel} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>Bijlage toevoegen</ModalTitle>
            </ModalHeader>

            <Formik<CreateBijlageFormValues> validationSchema={formSchema}
                                             onSubmit={onSubmit}
                                             validateOnBlur={false}
                                             validateOnChange={false}
                                             initialValues={initialValues || {
                                                 bestand: []
                                             }}
            >
                {(isValid) => (

                    <FormikForm noValidate>
                        <ModalBody>
                            <Row>
                                <Col xs={12} className="mb-2">
                                    <FormFileUploadField name="bestand" label="Bestand" max={1} hideOnMax/>
                                </Col>

                                <Col xs={12}>
                                    <TextAreaFormField name="omschrijving" label={t("Bijlage.Labels.optionele-omschrijving", "Optionele omschrijving")}
                                                       placeholder={t("Bijlage.Placeholders.omschrijving", "Omschrijving")}/>
                                </Col>
                            </Row>
                        </ModalBody>

                        <ModalFooter>
                            <Button variant="light" onClick={onCancel}>{t("Knoppen.annuleer", "Annuleer")}</Button>{""}
                            <LoadingButton disabled={!isValid || loading} type="submit"
                                           loading={loading} variant="primary">{t("Knoppen.uploaden", "Uploaden")}</LoadingButton>
                        </ModalFooter>
                    </FormikForm>
                )}
            </Formik>
        </Modal>
    );
};
