import React from 'react';
import {AccessoireBestellingModel} from "../../redux/accessoirebestelling/types";
import ArtikelNrOmschrijving from "../../components/aqualex/connected/ArtikelNrOmschrijving";

export interface AccessoireBestellingSummaryProps {
    accessoireBestelling?: AccessoireBestellingModel;

    includeArtikelen?: boolean;
}

export const AccessoireBestellingSummary: React.FC<AccessoireBestellingSummaryProps> = (props) => {
    const {
        accessoireBestelling,
        includeArtikelen
    } = props;

    if (accessoireBestelling) {
        return (
            <>
                <span className="text-muted">
                    {accessoireBestelling.verkoopOrder.nr}
                </span>

                {includeArtikelen && (
                    <ul>
                        {accessoireBestelling?.verkoopOrder?.lijnen?.map(lijn => (
                            <li key={lijn.lijnNr}>
                                {lijn.aantal} x <ArtikelNrOmschrijving artikel={lijn.artikel}/>
                            </li>
                        ))}
                    </ul>
                )}
            </>
        );
    } else {
        return null;
    }
};
