import {Pageable} from "./types";

export const overwriteArraysInsteadOfMerge = (objValue, srcValue) => {
    if (Array.isArray(srcValue)) {
        return srcValue
    }
};

export function paginationKey(filters, pageable: Pageable) {
    let filtersKey = '';

    for (const [fieldName, fieldValue] of Object.entries(filters)) {
        filtersKey += (fieldValue !== undefined) ? `${fieldName}=${JSON.stringify(fieldValue)};` : "";
    }

    return `${filtersKey}___${pageable.pageSize}_${pageable.sortField}_${pageable.sortOrder}`;
}
