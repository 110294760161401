import {default as React, useState} from "react";
import {useDispatch} from 'react-redux';
import {Alert, Button, Col, Form, ListGroup, Row} from "react-bootstrap";
import {hideModal} from "../../../../redux/ui/actions";
import {DagPlanningModel} from "../../../../redux/planning/types";
import {Field, FieldArray, Formik} from "formik";
import * as Yup from 'yup';
import {MedewerkerFormField} from "../../../../components/aqualex/form/MedewerkerFormField";
import {MedewerkerModel} from "../../../../redux/medewerker/types";
import DatumSelector from "../../../../components/aqualex/DatumSelector";
import DagPlanningApi from "../../../../helpers/DagPlanningApi";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/aqualex/Modal";
import {BezoekAanvraagGroepModel} from "../../../../redux/bezoekaanvraaggroep/types";
import {useTranslation} from "../../../../helpers/i18nUtils";

export interface PlanBezoekAanvraagGroepModalProps {
    bezoekAanvraagGroep: BezoekAanvraagGroepModel;
    onBevestigHandler: (mapping) => void;
}

interface FormValues {
    technieker?: MedewerkerModel;
    datums?: Array<{
        bezoekAanvraagId: string;
        dagPlanning?: DagPlanningModel;
    }>;
}

export const PlanBezoekAanvraagGroepModal: React.FC<PlanBezoekAanvraagGroepModalProps> = ({
                                                                                              bezoekAanvraagGroep,
                                                                                              onBevestigHandler,
                                                                                          }) => {
    const {t} = useTranslation("planning");

    const dispatch = useDispatch();

    const [beginDatum, setBeginDatum] = useState<Date>();
    const [geenDagplanningenBeschikbaar, setGeenDagPlanningenBeschikbaar] = useState(false);

    const onAnnuleer = () => {
        dispatch(hideModal());
    }

    const onBevestig = (datums) => {
        dispatch(hideModal());

        const mapping = datums.reduce((mapping, entry) => {
            mapping[entry.bezoekAanvraagId] = entry.dagPlanning.id;
            return mapping;
        }, {});

        onBevestigHandler(mapping);
    }



    const initialValues: FormValues = {
        technieker: undefined,
        datums: bezoekAanvraagGroep.bezoekAanvraagIds.map(bezoekAanvraagId => ({
            bezoekAanvraagId,
            dagPlanning: undefined
        })),
    };

    return (
        <Modal show={true} onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Labels.bezoekaanvraagserie-plannen", "Bezoekaanvraagserie plannen")}</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                validateOnMount // to make validateOnMount, initialValues must be created using useMemo
                onSubmit={(values: FormValues) => onBevestig(values.datums!)}
                validationSchema={Yup.object({
                    technieker: Yup.object()
                        .nullable()
                        .required(t("Foutmeldingen.technieker-is-verplicht", "Technieker is verplicht") as string),
                    datums: Yup.array().of(Yup.object({
                        bezoekAanvraagId: Yup.string().required('should not be nullable'),
                        dagPlanning: Yup.object()
                            .nullable()
                            .required(t("Foutmeldingen.dagplanning-is-verplicht", "Dagplanning is verplicht") as string),
                    })),
                })}
            >
                {({
                      handleSubmit,
                      values,
                      isValid,
                      setFieldValue,
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <h5>{t("Labels.technieker", "Technieker")}</h5>
                            <MedewerkerFormField name='technieker' searchFilters={{technieker: true}}/>
                            <h5>{t("algemeen:Labels.datum", "Datum")}</h5>
                            <Row>
                                <Col>
                                    <DatumSelector value={beginDatum} onChange={setBeginDatum}/>
                                </Col>
                                <Col>
                                    <Button disabled={!beginDatum || !values.technieker} onClick={() => {
                                        if (values.technieker && beginDatum) {
                                            // datums leegmaken
                                            bezoekAanvraagGroep.bezoekAanvraagIds.forEach((bezoekAanvraagId, index) => {
                                                setFieldValue(`datums.${index}.dagPlanning`, undefined);
                                            });
                                            setGeenDagPlanningenBeschikbaar(false);

                                            // beschikbare dagplanningen ophalen
                                            DagPlanningApi.beschikbareDagPlanningen(values.technieker.id, beginDatum, bezoekAanvraagGroep.bezoekAanvraagIds.length).then(dagPlanningen => {
                                                if (dagPlanningen.length === 0) {
                                                    setGeenDagPlanningenBeschikbaar(true);
                                                } else {
                                                    setGeenDagPlanningenBeschikbaar(false);
                                                    dagPlanningen.forEach((dagPlanning, index) => {
                                                        setFieldValue(`datums.${index}.dagPlanning`, dagPlanning);
                                                    });
                                                }
                                            });
                                        }
                                    }}><i className='mdi mdi-update'/></Button>
                                </Col>
                            </Row>
                            <h5>{t("Labels.bezoekaanvragen", "Bezoekaanvragen")}</h5>
                            {geenDagplanningenBeschikbaar && (
                                <Alert variant='danger'>
                                    <Row>
                                        <Col xl='auto'><h3 className='mdi mdi-alert-circle'> </h3></Col>
                                        <Col>{t("PlanBezoekAanvraagGroepModal.geen-dagplanningen-beschikbaar", "Er zijn geen dagplanningen beschikbaar voor de gekozen medewerker en begin datum.")}</Col>
                                    </Row>
                                </Alert>
                            )}
                            <FieldArray name='datums'>
                                {() => (
                                    <ListGroup>
                                        {values.datums?.map((entry, index) => (
                                            <ListGroup.Item key={entry.bezoekAanvraagId}>
                                                <Row>
                                                    <Col xl={1}>{index + 1}</Col>
                                                    <Col>
                                                        <Field name={`datums.${index}.dagPlanning`}>{({
                                                                                                          field
                                                                                                      }) => (
                                                            <span>{field.value ? field.value.datum : ''}</span>)}</Field>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )}
                            </FieldArray>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='light' onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                            <Button variant='primary' disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};
