import {default as React} from "react";
import CreateVerzendAdresFormContainer, {CreateVerzendAdresFormValues} from "./CreateVerzendAdresFormContainer";
import {CreateVerzendAdresForm} from "../../../redux/verzendadres/types";
import {Modal, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface CreateVerzendAdresModalProps {
    initialValues?: CreateVerzendAdresFormValues;
    klantId?: string;
    loading?: boolean;

    onSubmit(form: CreateVerzendAdresForm): void | Promise<void>;

    onCancel(): void;
}

const CreateVerzendAdresModal: React.FC<CreateVerzendAdresModalProps> = (props) => {

    const {t} = useTranslation("klant");

    const {onSubmit, klantId, onCancel, initialValues} = props;

    return (
        <Modal show={true} onHide={props.onCancel} autoFocus size="xl">
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.verzendadres-toevoegen", "Verzendadres toevoegen")}</ModalTitle>
            </ModalHeader>

            <CreateVerzendAdresFormContainer klantId={klantId} onSubmit={onSubmit} onCancel={onCancel} initialValues={initialValues} />
        </Modal>
    );
};

export default CreateVerzendAdresModal;
