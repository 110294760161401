import {DagPlanningTimelineEntry, DagPlanningTimelineEntryType} from "./DagPlanningTimeline";
import {DagPlanningEntryType} from "../../../../redux/planning/types";

export class DagPlanningTimelineUtils {

    public static getBezoekEntries(entries: DagPlanningTimelineEntry[]) {
        return entries.filter(entry => entry.dagPlanningEntry?.type === DagPlanningEntryType.BEZOEK);
    }

    public static getVoorstelBezoekEntries(entries: DagPlanningTimelineEntry[]) {
        return this.getBezoekEntries(entries).filter(entry => entry.dagPlanningEntry?.bezoek?.isVoorstel);
    }

    public static getHeeftVoorstellen(entries: DagPlanningTimelineEntry[]) {
        return this.getVoorstelBezoekEntries(entries).length > 0;
    }

    public static getEersteBezoekIndexBovenArrayIndex(entries: DagPlanningTimelineEntry[], arrayIndex: number) {
        const bezoekEntries = this.getBezoekEntries(entries);

        for (let i = arrayIndex; i >= 0; i--) {
            const entry = entries[i];

            if (entry.dagPlanningEntry?.type === DagPlanningEntryType.BEZOEK) {
                return bezoekEntries.indexOf(entry);
            }
        }

        return -1;
    }

    public static getEersteVoorstelBezoekIndex(entries: DagPlanningTimelineEntry[]) {
        const bezoekEntries = this.getBezoekEntries(entries);

        for (let i = 0; i < bezoekEntries.length; i++) {
            const entry = bezoekEntries[i];

            if (entry.dagPlanningEntry?.type === DagPlanningEntryType.BEZOEK && entry.dagPlanningEntry.bezoek?.isVoorstel) {
                return i;
            }
        }

        return undefined;
    }

    public static getLaatsteVoorstelBezoekIndex(entries: DagPlanningTimelineEntry[]) {
        const bezoekEntries = this.getBezoekEntries(entries);

        for (let i = bezoekEntries.length - 1; i >= 0; i--) {
            const entry = bezoekEntries[i];

            if (entry.dagPlanningEntry?.type === DagPlanningEntryType.BEZOEK && entry.dagPlanningEntry.bezoek?.isVoorstel) {
                return i;
            }
        }

        return undefined;
    }

    public static getBezoekIndexFromArrayIndex(entries: DagPlanningTimelineEntry[], arrayIndex: number) {
        if (arrayIndex >= entries.length) {
            return undefined;
        }

        const bezoekEntries = this.getBezoekEntries(entries);
        const eersteBezoek = bezoekEntries[0];
        console.log({arrayIndex, eersteBezoekIndex: entries.indexOf(eersteBezoek), entries});
        if (eersteBezoek && entries.indexOf(eersteBezoek) > arrayIndex) {
            return 0;
        }

        for (let i = arrayIndex; i >= 0; i--) {
            // Eerste bezoek dat we tegenkomen als we terugkeren van de target index
            const entry = entries[i];

            if (entry.dagPlanningEntry?.type === DagPlanningEntryType.BEZOEK) {
                console.log("bezoek index wordt", bezoekEntries.indexOf(entry));
                return bezoekEntries.indexOf(entry);
            }
        }
    }

    public static isBezoekIndexTusseninVoorstellen(entries: DagPlanningTimelineEntry[], rawTargetIndex: number, automatischAanvullenNaBezoekIndex?: number) {
        const eersteVoorstelBezoekIndex = DagPlanningTimelineUtils.getEersteVoorstelBezoekIndex(entries);
        const laatsteVoorstelBezoekIndex = DagPlanningTimelineUtils.getLaatsteVoorstelBezoekIndex(entries);

        const targetBezoekIndex = this.getBezoekIndexFromArrayIndex(entries, rawTargetIndex);
        if (targetBezoekIndex === undefined || eersteVoorstelBezoekIndex === undefined || laatsteVoorstelBezoekIndex === undefined) {
            return false;
        }

        return targetBezoekIndex >= (automatischAanvullenNaBezoekIndex || eersteVoorstelBezoekIndex) && targetBezoekIndex < laatsteVoorstelBezoekIndex;
    }

    public static isNetOnderLaatsteVoorstel(entries: DagPlanningTimelineEntry[], rawTargetIndex: number) {
        const laatsteVoorstel = entries.concat().reverse().find(entry => entry.dagPlanningEntry?.bezoek?.isVoorstel);
        if (!laatsteVoorstel) {
            // Er zijn geen voorstellen, dus altijd goed
            return true;
        }

        const laatsteVoorstelArrayIndex = entries.indexOf(laatsteVoorstel);

        return rawTargetIndex === laatsteVoorstelArrayIndex;
    }

    public static isOnderVoorstellen(entries: DagPlanningTimelineEntry[], rawIndex: number) {
        const laatsteVoorstel = entries.concat().reverse().find(entry => entry.dagPlanningEntry?.bezoek?.isVoorstel);
        if (!laatsteVoorstel) {
            return true;
        }

        const laatsteVoorstelArrayIndex = entries.indexOf(laatsteVoorstel);

        return rawIndex >= laatsteVoorstelArrayIndex;
    }

    public static isMarker(entry: DagPlanningTimelineEntry) {
        return entry.type === DagPlanningTimelineEntryType.AUTOMATISCH_AANVULLEN_MARKER;
    }

    public static getMarkerIndex(entries: DagPlanningTimelineEntry[]) {
        return entries.findIndex(this.isMarker);
    }

    public static isNetOnderMarker(entries: DagPlanningTimelineEntry[], rawSourceIndex: number, rawTargetIndex: number) {
        const markerIndex = this.getMarkerIndex(entries);

        return rawTargetIndex > markerIndex;
    }

    public static isVerplaatsenToegestaan(entries: DagPlanningTimelineEntry[], automatischAanvullenNaBezoekIndex: number | undefined, rawSourceIndex: number, rawTargetIndex: number, nieuweBezoekIndex: number): boolean {
        const teVerplaatsenBezoekEntry = entries[rawSourceIndex];

        const markerIndex = this.getMarkerIndex(entries);

        if (teVerplaatsenBezoekEntry.dagPlanningEntry?.bezoek?.isVoorstel) {
            // Voorstel mag onder laatste voorstel of boven marker verplaatst worden
            const isTargetBovenMarker = rawTargetIndex <= markerIndex;
            const isTargetOnderVoorstellen = this.isOnderVoorstellen(entries, rawTargetIndex);
console.log({isTargetBovenMarker, isTargetOnderVoorstellen})
            return isTargetBovenMarker || isTargetOnderVoorstellen;
        } else {
            // Een niet-voorstel mag van boven de marker opnieuw boven de marker verplaatst worden
            // of van onder de voorstellen opnieuw onder de voorstellen
            // of van onder de voorstellen naar boven de marker
            // of van boven de marker naar onder de voorstellen
            const isSourceBovenMarker = rawSourceIndex < markerIndex;
            const isSourceOnderVoorstellen = this.isOnderVoorstellen(entries, rawSourceIndex);
            const isTargetBovenMarker = rawTargetIndex <= markerIndex;
            const isTargetOnderVoorstellen = this.isOnderVoorstellen(entries, rawTargetIndex);

            return (isSourceBovenMarker && isTargetBovenMarker) ||
                (isSourceOnderVoorstellen && isTargetOnderVoorstellen) ||
                (isSourceOnderVoorstellen && isTargetBovenMarker) ||
                (isSourceBovenMarker && isTargetOnderVoorstellen);
        }
    }

    public static isBevestigingNodigVoorVerplaatsing(entries: DagPlanningTimelineEntry[], automatischAanvullenNaBezoekIndex: number | undefined, rawTargetIndex: number) {
        if (automatischAanvullenNaBezoekIndex !== undefined) {
            const isNetOnderLaatsteVoorstel = DagPlanningTimelineUtils.isNetOnderLaatsteVoorstel(entries, rawTargetIndex);
            const isTussenin = DagPlanningTimelineUtils.isBezoekIndexTusseninVoorstellen(entries, rawTargetIndex, automatischAanvullenNaBezoekIndex);

            if (!isNetOnderLaatsteVoorstel && !isTussenin) {
                return true;
            }
        }

        return false;
    }

}
