import React from "react";
import DatumSelector from "../DatumSelector";


interface DateFilterProps {
    value?: Date;
    onChange: (value: Date) => void;

    minDate?: Date;
    maxDate?: Date;
}

const DateFilter: React.FC<DateFilterProps> = ({value, onChange, minDate, maxDate}) => {
    return (
        <DatumSelector value={value} minDate={minDate} maxDate={maxDate}
                       onChange={value => onChange(value)}/>
    );
};

export default DateFilter;
