import {actionTypes} from "../support/action";

export const FETCH_DAG_PLANNINGEN_REQUEST = 'FETCH_DAG_PLANNINGEN_REQUEST';
export const FETCH_DAG_PLANNINGEN_SUCCESS = 'FETCH_DAG_PLANNINGEN_SUCCESS';
export const FETCH_DAG_PLANNINGEN_FAILURE = 'FETCH_DAG_PLANNINGEN_FAILURE';

export const FETCH_DAG_PLANNING_BEZOEKEN_REQUEST = 'FETCH_DAG_PLANNING_BEZOEKEN_REQUEST';
export const FETCH_DAG_PLANNING_BEZOEKEN_SUCCESS = 'FETCH_DAG_PLANNING_BEZOEKEN_SUCCESS';
export const FETCH_DAG_PLANNING_BEZOEKEN_FAILURE = 'FETCH_DAG_PLANNING_BEZOEKEN_FAILURE';

export const BULK_UPLOAD_DAG_PLANNINGEN_REQUEST = 'BULK_UPLOAD_DAG_PLANNINGEN_REQUEST';
export const BULK_UPLOAD_DAG_PLANNINGEN_SUCCESS = 'BULK_UPLOAD_DAG_PLANNINGEN_SUCCESS';
export const BULK_UPLOAD_DAG_PLANNINGEN_FAILURE = 'BULK_UPLOAD_DAG_PLANNINGEN_FAILURE';

export const FETCH_OPDRACHT_BY_ID_REQUEST = 'FETCH_OPDRACHT_BY_ID_REQUEST';
export const FETCH_OPDRACHT_BY_ID_SUCCESS = 'FETCH_OPDRACHT_BY_ID_SUCCESS';
export const FETCH_OPDRACHT_BY_ID_FAILURE = 'FETCH_OPDRACHT_BY_ID_FAILURE';

export const GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_REQUEST = 'GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_REQUEST';
export const GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_SUCCESS = 'GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_SUCCESS';
export const GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_FAILURE = 'GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_FAILURE';

export const GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_REQUEST = 'GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_REQUEST';
export const GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_SUCCESS = 'GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_SUCCESS';
export const GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_FAILURE = 'GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_FAILURE';

export const BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_REQUEST = 'BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_REQUEST';
export const BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_SUCCESS = 'BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_SUCCESS';
export const BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_FAILURE = 'BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_FAILURE';

export const HERBEZOEK_ONDERHOUD_SERVICE_ORDER_REQUEST = 'HERBEZOEK_ONDERHOUD_SERVICE_ORDER_REQUEST';
export const HERBEZOEK_ONDERHOUD_SERVICE_ORDER_SUCCESS = 'HERBEZOEK_ONDERHOUD_SERVICE_ORDER_SUCCESS';
export const HERBEZOEK_ONDERHOUD_SERVICE_ORDER_FAILURE = 'HERBEZOEK_ONDERHOUD_SERVICE_ORDER_FAILURE';

export const FETCH_PRESTATIES_BY_OPDRACHTID_REQUEST = 'FETCH_PRESTATIES_BY_OPDRACHTID_REQUEST';
export const FETCH_PRESTATIES_BY_OPDRACHTID_SUCCESS = 'FETCH_PRESTATIES_BY_OPDRACHTID_SUCCESS';
export const FETCH_PRESTATIES_BY_OPDRACHTID_FAILURE = 'FETCH_PRESTATIES_BY_OPDRACHTID_FAILURE';

export const FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_REQUEST = 'FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_REQUEST';
export const FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_SUCCESS = 'FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_SUCCESS';
export const FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_FAILURE = 'FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_FAILURE';

export const FETCH_GEPLAND_BEZOEK_BY_ID_REQUEST = 'FETCH_GEPLAND_BEZOEK_BY_ID_REQUEST';
export const FETCH_GEPLAND_BEZOEK_BY_ID_SUCCESS = 'FETCH_GEPLAND_BEZOEK_BY_ID_SUCCESS';
export const FETCH_GEPLAND_BEZOEK_BY_ID_FAILURE = 'FETCH_GEPLAND_BEZOEK_BY_ID_FAILURE';

export const DAG_PLANNING_CAPACITEIT_PROJECTION_REQUEST = 'DAG_PLANNING_CAPACITEIT_PROJECTION_REQUEST';
export const DAG_PLANNING_CAPACITEIT_PROJECTION_SUCCESS = 'DAG_PLANNING_CAPACITEIT_PROJECTION_SUCCESS';
export const DAG_PLANNING_CAPACITEIT_PROJECTION_FAILURE = 'DAG_PLANNING_CAPACITEIT_PROJECTION_FAILURE';

export const PLANNING_WEEK_OVERZICHT_UI_STATE_KEY = 'planning.weekoverzicht';
export const PLANNING_DAG_OVERZICHT_UI_STATE_KEY = 'planning.dagoverzicht';

export const DOWNLOAD_MAGAZIJN_RAPPORT = actionTypes("DOWNLOAD_MAGAZIJN_RAPPORT");
