import {fetchAnnulatieRedenenFailure, fetchAnnulatieRedenenRequest, fetchAnnulatieRedenenSuccess} from './actions';
import {FETCH_ANNULATIE_REDENEN_REQUEST,} from "./constants";
import {all, call, put, takeLatest} from 'redux-saga/effects';
import RefDataApi from '../../helpers/RefDataApi'
import {fetchDepotsToolkit} from "./index";
import {ApiError} from "../ui/types";

export function* fetchAnnulatieRedenen({payload}: ReturnType<typeof fetchAnnulatieRedenenRequest>) {
    try {
        const result = yield call(RefDataApi.fetchAnnulatieRedenen);
        yield put(fetchAnnulatieRedenenSuccess(result));
    } catch (err: any) {
        yield put(fetchAnnulatieRedenenFailure(err as ApiError));
    }
}

export default function* refDataSaga() {
    yield all([
        takeLatest(FETCH_ANNULATIE_REDENEN_REQUEST, fetchAnnulatieRedenen),
        fetchDepotsToolkit.sagaEffect,
    ]);
}
