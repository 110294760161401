import {useDispatch, useSelector} from "react-redux";
import {selectLoggedInUser} from "./selectors";
import {useCallback} from "react";
import {AccountInfo, AuthenticationResult} from "@azure/msal-browser";
import {loginUserSuccess} from './actions';
import {PrincipalModel} from "./types";
import {userHasRole} from "../../helpers/authUtils";
import {MedewerkerRol} from "../medewerker/types";

export const useLoggedInUser = () => useSelector(selectLoggedInUser);

export const useUserHasRole = (role: MedewerkerRol) => {
    const user = useLoggedInUser();
    if (!user) {
        return false;
    }

    return userHasRole(user, role);
}

export const useLoginUser = () => {
    const dispatch = useDispatch();

    return useCallback((params: {info: AccountInfo, me: PrincipalModel, token: AuthenticationResult}) => {
        const {info, me, token} = params;

        const user: PrincipalModel = {
            gebruikerNaam: info.username,
            medewerkerId: me.medewerkerId,
            naam: info.name!,
            authorities: me.authorities,
            taal: me.taal,
            token
        };

        dispatch(loginUserSuccess(user));
    }, [dispatch]);
};
