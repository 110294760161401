import React from "react";
import {useFetchDepots} from "../../../redux/refdata";
import {BetterSelectFormField, BetterSelectFormFieldProps} from "./BetterSelectFormField";
import {DepotModel} from "../../../redux/refdata/types";
import {FormikFormFieldProps} from "./FormikFormField";

export type DepotFormFieldProps = FormikFormFieldProps & BetterSelectFormFieldProps<DepotModel>;

export const DepotFormField: React.FC<DepotFormFieldProps> = (props) => {
    const {items, keyGetter, formatter, ...rest} = props;

    const {items: depots} = useFetchDepots({filters: {ignoreMe: "ignore me"}});

    return (
        <BetterSelectFormField items={depots}
                               keyGetter={depot => depot.code}
                               formatter={depot => depot.code}
                               {...rest}/>
    );
};
