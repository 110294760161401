import * as React from "react";
import Select from "react-select";
import {BedrijfModel} from "../../redux/bedrijf/types";
import {useGetBedrijven} from "../../redux/bedrijf/api";
import {useTranslation} from "../../helpers/i18nUtils";

interface BedrijfSelectorProps {
    value?: string;
    defaultValue?: BedrijfModel;
    defaultValueId?: string;

    isInvalid?: boolean;
    name?: string;

    disabled?: boolean;
    placeholder?: string;

    isClearable?: boolean;

    onChange(bedrijf?: BedrijfModel): void;
}

const BedrijfSelector: React.FC<BedrijfSelectorProps> = (props) => {
    const {
        value,
        defaultValue,
        defaultValueId,
        disabled,
        placeholder,
        isInvalid,
        name,
        isClearable,
    } = props;

    const {t} = useTranslation("algemeen");

    const {data, isLoading: loading} = useGetBedrijven({});

    const options = data?.map((item) => ({
        label: item.label,
        value: item.id
    })) || [];

    const onChange = (option) => {
        if (option) {
            const bedrijf = data?.find((item) => item.id === option.value);

            props.onChange(bedrijf);
        } else {
            props.onChange(undefined);
        }
    };

    const defaultOption = options?.find((item) => item.value === defaultValue?.id || item.value === defaultValueId);

    return (
        <Select
            className={"react-select-container" + (isInvalid ? " is-invalid" : "")}
            classNamePrefix="react-select"
            name={name}
            value={value && options.find((item) => item.value === value)}
            defaultValue={defaultOption}
            isLoading={loading}
            isDisabled={disabled}
            options={options}
            onChange={onChange}
            placeholder={placeholder || t("Placeholders.selecteer-een-bedrijf", "Selecteer een bedrijf...")}

            isClearable={isClearable !== undefined ? isClearable : true}
            noOptionsMessage={() => t("Labels.geen-bedrijven-gevonden", "Geen bedrijven gevonden")}
        />
    )
};


export default BedrijfSelector;
