import * as React from "react";
import {Alert, Col, Row} from "react-bootstrap";
import {useTranslation} from "../../helpers/i18nUtils";

interface AlertWithIconProps {
}

export const WarningAlert: React.FC<AlertWithIconProps> = ({children}) => {
    const {t} = useTranslation("algemeen");

    return (
        <Alert variant='warning' role='alert'>
            <Row>
                <Col xl='auto'>
                    <h3 className='mdi mdi-alert' aria-hidden={true}/>
                    <span className='sr-only'>{t("algemeen:Labels.opgelet-colon", "Opgelet:")}</span>
                </Col>
                <Col>{children}</Col>
            </Row>
        </Alert>
    );
};

export const InfoAlert: React.FC<AlertWithIconProps> = ({children}) => {
    const {t} = useTranslation("algemeen");

    return (
        <Alert variant='info' role='alert'>
            <Row>
                <Col xl='auto'>
                    <h3 className='mdi mdi-information' aria-hidden={true}/>
                    <span className='sr-only'>{t("algemeen:Labels.info-colon", "Info:")}</span>
                </Col>
                <Col>{children}</Col>
            </Row>
        </Alert>
    );
};
