import {default as React, useMemo} from "react";
import {useDispatch} from 'react-redux';
import {hideModal} from "../../../../../redux/ui/actions";
import {Formik,} from 'formik';
import * as Yup from 'yup';
import {Alert, Button, Col, Form, FormGroup} from "react-bootstrap";
import {PrioriteitHint} from "../../../../../redux/planning/types";
import {DatumFormField} from "../../../../../components/aqualex/form/DatumFormField";
import {TijdstipFormField} from "../../../../../components/aqualex/form/TijdstipFormField";
import {parseDate, parseTime} from "../../../../../helpers/DateUtils";
import {SwitchFormField} from "../../../../../components/aqualex/form/SwitchFormField";
import {MedewerkerModel} from "../../../../../redux/medewerker/types";
import {MedewerkerLijstFormField} from "../../../../../components/aqualex/form/MedewerkerLijstFormField";
import {SelectFormField} from "../../../../../components/aqualex/form/SelectFormField";
import Config from "../../../../../helpers/Config";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../../components/aqualex/Modal";
import {
    BezoekAanvraagModel,
    UpdateBezoekAanvraagPlanningParametersForm
} from "../../../../../redux/bezoekaanvraag/types";
import {SimpleCheckField} from "../../../../../components/aqualex/form/SimpleCheckField";
import {useTranslation} from "../../../../../helpers/i18nUtils";

export interface UpdateBezoekAanvraagVanTotModalProps {
    bezoekAanvraag: BezoekAanvraagModel;
    onBevestigHandler: (form: UpdateBezoekAanvraagPlanningParametersForm) => void;
}

interface FormValues {
    periode: boolean;
    vanDatum?: Date;
    totDatum?: Date;
    vanTijdstip?: string;
    totTijdstip?: string;
    toegelatenTechniekers: Array<MedewerkerModel>;
    prioriteitHint?: PrioriteitHint;
    automatischPlannen?: boolean;

    aanvraagVergunningAutovrijeZoneNodig: boolean;
    aanvraagVergunningParkeerzoneNodig: boolean;
    techniekerOpVoorhandAanmeldenNodig: boolean;
}

export const UpdateBezoekAanvraagVanTotModal: React.FC<UpdateBezoekAanvraagVanTotModalProps> = ({
                                                                                                    bezoekAanvraag,
                                                                                                    onBevestigHandler,
                                                                                                }) => {
    const {t} = useTranslation("planning");

    const dispatch = useDispatch();

    const onAnnuleer = () => {
        dispatch(hideModal());
    }

    const onBevestig = (values: FormValues) => {
        dispatch(hideModal());

        onBevestigHandler({
            toegelatenTechniekers: values.toegelatenTechniekers.map(medewerker => medewerker.id),
            vanDatum: values.vanDatum,
            totDatum: values.vanDatum ? values.totDatum : undefined,
            vanTijdstip: values.vanTijdstip!,
            totTijdstip: values.totTijdstip!,
            prioriteitHint: values.prioriteitHint,
            automatischPlannen: values.automatischPlannen,

            aanvraagVergunningAutovrijeZoneNodig: values.aanvraagVergunningAutovrijeZoneNodig,
            aanvraagVergunningParkeerzoneNodig: values.aanvraagVergunningParkeerzoneNodig,
            techniekerOpVoorhandAanmeldenNodig: values.techniekerOpVoorhandAanmeldenNodig
        });
    }

    return (
        <Modal show={true} onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("UpdateBezoekAanvraagVanTotModal.planningsparameters-aanpassen", "Planningsparameters aanpassen")}</ModalTitle>
            </ModalHeader>

            <Formik
                initialValues={useMemo(() => {
                    return {
                        periode: bezoekAanvraag.vanDatum !== bezoekAanvraag.totDatum && !bezoekAanvraag.vanTijdstip && !bezoekAanvraag.totTijdstip,
                        vanDatum: parseDate(bezoekAanvraag.vanDatum),
                        totDatum: parseDate(bezoekAanvraag.totDatum),
                        vanTijdstip: bezoekAanvraag.vanTijdstip,
                        totTijdstip: bezoekAanvraag.totTijdstip,
                        toegelatenTechniekers: bezoekAanvraag.toegelatenTechniekers,
                        prioriteitHint: bezoekAanvraag.prioriteitHint,
                        automatischPlannen: bezoekAanvraag.automatischPlannen,

                        aanvraagVergunningAutovrijeZoneNodig: bezoekAanvraag.voorbereidendeStappen.aanvraagVergunningAutovrijeZoneNodig,
                        aanvraagVergunningParkeerzoneNodig: bezoekAanvraag.voorbereidendeStappen.aanvraagVergunningParkeerzoneNodig,
                        techniekerOpVoorhandAanmeldenNodig: bezoekAanvraag.voorbereidendeStappen.techniekerOpVoorhandAanmeldenNodig
                    } as FormValues
                }, [bezoekAanvraag])}
                validateOnMount // to make validateOnMount, initialValues must be created using useMemo
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    periode: Yup.boolean(),
                    prioriteitHint: Yup.string()
                        .required(t("Foutmeldingen.prioriteit-is-verplicht", "Prioriteit is verplicht") as string)
                        .nullable(),
                    vanDatum: Yup.date().nullable()
                        .when('periode', {
                            is: (periode) => !!periode,
                            then: Yup.date()
                                .required(t("Foutmeldingen.van-datum-is-verplicht", "Van datum is verplicht") as string)
                        }),
                    totDatum: Yup.date()
                        .when('periode', {
                            is: (periode) => !!periode,
                            then: Yup.date()
                                .required(t("Foutmeldingen.tot-datum-is-verplicht", "Tot datum is verplicht") as string)
                                .min(Yup.ref('vanDatum'), t("Foutmeldingen", "Tot datum moet <= van datum zijn") as string),
                            else: Yup.date()
                                .nullable()
                        }),
                    vanTijdstip: Yup.string().nullable()
                        .when('vanDatum', {
                            is: (vanDatum) => !vanDatum,
                            then: Yup.string()
                                .length(0, t("Foutmeldingen.op-is-verplicht", "'Op' verplicht") as string)
                        }),
                    totTijdstip: Yup.string()
                        .when(['periode', 'vanTijdstip'], {
                            is: (periode, vanTijdstip) => !periode && !!vanTijdstip,
                            then: Yup.string()
                                .required('Tot tijdstip is verplicht')
                                .test(
                                    'van_voor_tot',
                                    t("Foutmeldingen.tot-tijdstip-moet-groter-dan-of-gelijk-aan-van-tijdstip-zijn", "Tot tijdstip moet <= van tijdstip zijn") as string,
                                    function (totTijdstip) {
                                        // @ts-ignore
                                        const {parent} = this;
                                        const van = parseTime(parent.vanTijdstip);
                                        const tot = parseTime(totTijdstip!);
                                        return van! <= tot!;
                                    }
                                ),
                        })
                        .nullable(),
                    automatischPlannen: Yup.boolean(),
                    aanvraagVergunningAutovrijeZoneNodig: Yup.boolean(),
                    aanvraagVergunningParkeerzoneNodig: Yup.boolean(),
                    techniekerOpVoorhandAanmeldenNodig: Yup.boolean()
                })}
            >
                {({
                      handleSubmit,
                      values,
                      isValid,
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <h5>{t("Labels.automatisch-plannen", "Automatisch plannen")}</h5>
                            <Form.Row>
                                <Col>
                                    <SimpleCheckField name="automatischPlannen" id="automatischPlannen"
                                                      label={t("Labels.bezoekaanvraag-automatisch-inplannen", "De bezoekaanvraag automatisch plannen")}/>
                                </Col>
                            </Form.Row>

                            <h5>{t("Labels.datum", "Datum")}</h5>
                            <Form.Row>
                                <FormGroup id='vanDatum' as={Col}>
                                    <DatumFormField name='vanDatum'
                                                    label={values.periode ? t("algemeen:Labels.van", "Van") : t("algemeen:Labels.op", "Op")}/>
                                </FormGroup>
                                {values.periode &&
                                    <FormGroup id='totDatum' as={Col}>
                                        <DatumFormField name='totDatum' label={t("algemeen:Labels.tot", "Tot")}/>
                                    </FormGroup>
                                }
                            </Form.Row>
                            <Form.Row>
                                <FormGroup id='periode' as={Col}>
                                    <SwitchFormField name='periode' label={t("Labels.periode", "Periode")}/>
                                </FormGroup>
                            </Form.Row>
                            <h5>{t("Labels.tijdstip-aankomst", "Tijdstip aankomst")}</h5>
                            {values.periode &&
                                <Alert
                                    variant="info">{t("UpdateBezoekAanvraagVanTotModal.periode-niet-mogelijk-om-tijdstip-in-te-stellen", "Bij een periode is het niet mogelijk om een tijdstip in te stellen")}</Alert>
                            }
                            {!values.periode &&
                                <React.Fragment>
                                    <p>{t("UpdateBezoekAanvraagVanTotModal.optionele-periode-instellen", "Optioneel. Kies bij voorkeur een zo breed mogelijk aankomstmoment.")}</p>
                                    <Form.Row>
                                        <FormGroup id='vanTijdstip' as={Col}>
                                            <TijdstipFormField name='vanTijdstip'
                                                               label={t("algemeen:Labels.van", "Van")}/>
                                        </FormGroup>
                                        <FormGroup id='totDatum' as={Col}>
                                            <TijdstipFormField name='totTijdstip'
                                                               label={t("algemeen:Labels.tot", "Tot")}/>
                                        </FormGroup>
                                    </Form.Row>
                                </React.Fragment>
                            }
                            <h5>Prioriteit</h5>
                            <SelectFormField name="prioriteitHint">
                                {Object.keys(PrioriteitHint).map(hint => (
                                    <option key={hint} value={hint}>{Config.labels[hint]}</option>
                                ))}
                            </SelectFormField>
                            <h5>{t("Labels.toegelaten-techniekers", "Toegelaten techniekers")}</h5>
                            <MedewerkerLijstFormField name='toegelatenTechniekers'
                                                      filters={{technieker: true}}/>

                            <h5>{t("Labels.voorbereidende-stappen", "Voorbereidende stappen")}</h5>
                            <SimpleCheckField name="aanvraagVergunningAutovrijeZoneNodig" id="aanvraagVergunningAutovrijeZoneNodig"
                                              label={t("Labels.aanvraag-vergunning-autovrije-zone-nodig", "Aanvraag vergunning autovrije zone nodig")}/>

                            <SimpleCheckField name="aanvraagVergunningParkeerzoneNodig" id="aanvraagVergunningParkeerzoneNodig"
                                              label={t("Labels.aanvraag-vergunning-parkeerzone-nodig", "Aanvraag vergunning parkeerzone nodig")}/>

                            <SimpleCheckField name="techniekerOpVoorhandAanmeldenNodig" id="techniekerOpVoorhandAanmeldenNodig"
                                              label={t("Labels.technieker-op-voorhand-aanmelden-nodig", "Technieker op voorhand aanmelden nodig")}/>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='light'
                                    onClick={onAnnuleer}>{t("Labels.annuleer", "Annuleer")}</Button>{' '}
                            <Button variant='primary' disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("Labels.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};
