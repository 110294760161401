import Config from "./Config";
import {GET} from './api';

import {EntityPage, Pageable} from "../redux/support/pagination";
import {ServiceRapportFilters, ServiceRapportModel} from "../redux/servicerapport/types";
import {downloadFile, FileDownload} from "./download";

export default class ServiceRapportApi {

    static search(filters: ServiceRapportFilters, pageable: Pageable): Promise<EntityPage<ServiceRapportModel>> {

        const params = new URLSearchParams();

        filters.adresId && params.append('adresId', filters.adresId);

        // paging and sorting
        params.append('page', `${pageable.pageNumber - 1}`);
        params.append('size', `${pageable.pageSize}`);
        params.append('sort', `${pageable.sortField},${pageable.sortOrder}`);

        const uri = Config.BACKOFFICE_BASE_URL + `/api/klant/${filters.klantId}/servicerapport?${params.toString()}`;

        return GET(uri, 'zoeken van servicerapporten');
    }

    static downloadServiceRapport(klantId: string, id: string): Promise<FileDownload> {

        const uri = Config.BACKOFFICE_BASE_URL + `/api/klant/${klantId}/servicerapport/${id}/pdf`;

        return downloadFile(uri, 'downloaden van service rapport');
    }

};



