export enum TransportKostConfiguratieType {
    INHERIT = "INHERIT",
    STANDAARD = "STANDAARD",
    VAST = "VAST",
    NOOIT = "NOOIT"
}

export enum TransportKostOnderhevig {
    ONDERHEVIG = "ONDERHEVIG",
    REEDS_INBEGREPEN = "REEDS_INBEGREPEN"
}

export interface TransportKostConfiguratieModel {
    type: TransportKostConfiguratieType;
    value?: number;
}

export interface TransportKostModel {
    regeling: TransportKostOnderhevig;
    bedrag?: number;
}

export interface _Links {
    view?: string;
}

export enum ErpStatus {
    VEROUDERD = "VEROUDERD",
    ACTUEEL = "ACTUEEL"
}
