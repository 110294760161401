import {Button, Form, InputGroup} from "react-bootstrap";
import React, {forwardRef} from "react";
import DatePicker from "react-datepicker";
import {useTranslation} from "../../helpers/i18nUtils";

const InputWithButton = (props, _ref) => (
    <InputGroup>
        <Form.Control name={props.name}
                      onClick={props.onClick}
                      value={props.value}
                      onBlur={props.onBlur}
                      onChange={props.onChange}
                      disabled={props.disabled}
                      isValid={props.isValid}
                      isInvalid={props.isInvalid}
                      placeholder={props.placeholder}
                      autoComplete="off"
        />
        <InputGroup.Append>
            <Button disabled={props.disabled} onClick={props.onClick}>
                <i className="mdi mdi-calendar-range font-13"/>
            </Button>
        </InputGroup.Append>
    </InputGroup>
);

const InputWithButtonRefForward = forwardRef(InputWithButton);


export interface DatumSelectorProps {
    name?: string;
    value?: Date | [Date, Date];
    onChange: (value: Date) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    isInvalid?: boolean;
    disabled?: boolean;
    placeholder?: string;
    wrapperClassName?: string;

    minDate?: Date;
    maxDate?: Date;

    selectsRange?: boolean;
    monthsShown?: number;
    showMonthYearPicker?: boolean;
    dateFormat?: string;
}

const DatumSelector: React.FC<DatumSelectorProps> = (props) => {
    const {
        name,
        value,
        onChange,
        onBlur,
        isInvalid,
        disabled,
        placeholder,
        wrapperClassName,
        minDate,
        maxDate,
        selectsRange,
        monthsShown,
        showMonthYearPicker,
        dateFormat
    } = props;

    const {language} = useTranslation();

    return (
        <DatePicker customInput={<InputWithButtonRefForward isInvalid={isInvalid}/>}
                    name={name}
                    selected={selectsRange ? value?.[0] : value as Date}
                    startDate={selectsRange ? value?.[0] : undefined}
                    endDate={selectsRange ? value?.[1] : undefined}
                    onChange={onChange}
                    onBlur={onBlur}
                    dateFormat={dateFormat || 'dd/MM/yyyy'} // TODO move this to Config
                    locale={language}
                    placeholderText={placeholder}
                    disabled={disabled}
                    wrapperClassName={wrapperClassName}
                    minDate={minDate}
                    maxDate={maxDate}
                    selectsRange={selectsRange}
                    monthsShown={monthsShown}
                    showMonthYearPicker={showMonthYearPicker}
        />
    );
};

export default DatumSelector;
