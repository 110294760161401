import React from "react";

export interface ViewportBorderProps {
    color: string;
}

export const ViewportBorder: React.FC<ViewportBorderProps> = ({ color }) => {
    return (
        <div style={{
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,

            zIndex: 5000,
            overflow: "auto",

            border: "2px solid",
            borderColor: color,

            pointerEvents: "none"
        }}/>
    )
};
