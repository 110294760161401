import {createQueryAction} from "../support/toolkit";
import {DepotModel} from "./types";
import {call} from "redux-saga/effects";
import RefDataApi from "../../helpers/RefDataApi";
import {depotSchema} from "./schema";

export const fetchDepotsToolkit = createQueryAction<{}, DepotModel>("FETCH_DEPOTS", {
    entityName: "depot",
    reducerName: "RefData",
    schema: depotSchema,
    sagaCall: function* (incomingAction) {
        return yield call(RefDataApi.fetchDepots);
    }
});

export const useFetchDepots = fetchDepotsToolkit.createFetchHook();
