import * as React from "react";
import {formatTime} from "../../helpers/DateUtils";

interface TijdstipProps {
    tijd?: string;
}

const Tijdstip: React.FC<TijdstipProps> = ({tijd}) => {
    if(!tijd) {
        return null;
    }

    return (
        <span>{formatTime(tijd)}</span>
    );
};

export default Tijdstip;
