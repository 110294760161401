import React, {useMemo} from "react";
import * as Yup from "yup";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import {TijdsduurFormField} from "../form/TijdsduurFormField";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface BezoekDuurtijdAanpassenFormProps {
    onBevestig: (form: BezoekDuurtijdAanpassenFormValues) => void;
    defaultValue?: string;
}

export interface BezoekDuurtijdAanpassenFormValues {
    duurtijd?: string | null;
}

export const BezoekDuurtijdAanpassenForm: React.FC<BezoekDuurtijdAanpassenFormProps> = (props) => {
    const {onBevestig, defaultValue} = props;

    const {t} = useTranslation("planning");

    const onSubmitHandler = (values: BezoekDuurtijdAanpassenFormValues) => {
        onBevestig({
            duurtijd: values.duurtijd
        });
    }

    const schema = Yup.object<BezoekDuurtijdAanpassenFormValues>({
        duurtijd: Yup.string().nullable().required(t("Foutmeldingen.duur-is-verplicht", "Duurtijd is verplicht") as string),
    });

    const initialValues: BezoekDuurtijdAanpassenFormValues = useMemo(() => ({
        duurtijd: defaultValue
    }), [defaultValue]);

    return (
        <Formik validationSchema={schema}
                onSubmit={onSubmitHandler}
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialValues}>
            {({
                  handleSubmit,
                  isValid
              }) => (
                <Form noValidate>
                    <Row>
                        <Col>
                            <TijdsduurFormField name="duurtijd"/>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Button variant="primary" disabled={!isValid} className="form-control"
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestigen", "Bevestigen")}</Button>
                        </Col>
                    </Row>
                </Form>)}
        </Formik>
    );
};
