import * as React from "react";
import {OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import {useTranslation} from "../../../helpers/i18nUtils";

interface ConnectedComponentSpinnerProps {
    entity: string;
    id: string;
}

const ConnectedComponentSpinner: React.FC<ConnectedComponentSpinnerProps> = ({entity, id}) => {
    const {t} = useTranslation("algemeen");

    const tooltip = <Tooltip id="login-help-tooltip">
        {t("ConnectedComponentSpinner.wordt-opgehaald", "{{entity}} wordt opgehaald.", {entity})}<br/>
        ID: {id}
    </Tooltip>;

    return <span>
                  <OverlayTrigger placement="right"
                                  delay={{show: 250, hide: 400}}
                                  overlay={tooltip}>
                            <Spinner as={"span"} animation="border" size="sm" variant='primary'/>
                  </OverlayTrigger>
        </span>

};


export default (ConnectedComponentSpinner);
