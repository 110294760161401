import {
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta
} from "@reduxjs/toolkit/query/react";
import {authAcquireTokenSilent} from "../../auth/authProvider";
import Config from "../../helpers/Config";
import {BaseQueryFn} from "@reduxjs/toolkit/dist/query/baseQueryTypes";


type Meta = {
    omschrijving: string
};

type ExtraOptions = {
    omschrijving?: string
};

const authenticatedApiQuery = fetchBaseQuery({
    baseUrl: Config.BACKOFFICE_BASE_URL + "/api",
    prepareHeaders: (headers, api) => {
        return authAcquireTokenSilent()
            .then(result => {
                headers.append("Authorization", "Bearer " + result!.accessToken);
                return headers;
            })
    },
});

const metaBaseQuery: BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    ExtraOptions,
    Meta & FetchBaseQueryMeta> = async (args, api, extraOptions) => {

    const baseResult = await authenticatedApiQuery(
        args,
        api,
        {}
    );

    return {
        ...baseResult,
        meta: baseResult.meta && {
            ...baseResult.meta,
            omschrijving: extraOptions?.omschrijving || 'todo - omschrijving'
        },
    }
};


export const baseApi = createApi({
    reducerPath: "baseApi",
    tagTypes: [
        "Medewerker",
        "Co2Bestelling",
        "BestelBon",
        "AccessoireBestelling",
        "Interventie",
        "Installatie",
        "Opvolging",
        "Klant",
        "VerzendAdres",
        "KlantAccount",
        "Opdracht",
        "Prestatie",
        "BezoekAanvraag",
        "Artikel",
        "Toestel",
        "KeuringVerslag",
        "Service",
        "Prestatie",
        "Bedrijf",
        "Opmerking",
        "AutomatischePlanning",
        "BezoekAanvraagGroep",
        "OpvolgingItemGeschiedenis",
        "DagPlanning",
        "DagPlanningOverzicht"
    ],
    baseQuery: metaBaseQuery,
    endpoints: () => ({}),
});
