import moment, {Moment} from 'moment';
import Config from "./Config";
import {unitOfTime} from "moment/moment";

export const dateWeekdays = () => {
    return moment.weekdays();
};

export const monthRange = (datum: Date): [Date, Date] => {
    return [
        moment(datum).startOf('month').toDate(),
        moment(datum).endOf('month').toDate()
    ];
};

export const endOfMonth = () => {
    return moment().endOf('month').toDate();
}

export const nextDay = (datum: Date | string) => {
    return moment(datum).add(1, "day").toDate();
}

export const previousDay = (datum: Date | string) => {
    return moment(datum).subtract(1, "day").toDate();
}

export const weekRange = (datum: Date): [Date, Date] => {
    return [
        moment(datum).startOf('week').toDate(),
        moment(datum).endOf('week').toDate()
    ];
};

export const forEachDay = (van: Date, tot: Date, callback: (datum: Date) => any) => {
    // TODO validatie: van <= tot

    for (let d = new Date(van); d <= tot; d.setDate(d.getDate() + 1)) {
        callback(d);
    }
};

export const formatDateIso = (date: Date | string, format?: string): string => {
    return moment(date).format(format || Config.DATE_ISO_FORMAT);
};

export const formatDateHuman = (date: Date | string, format?: string): string => {
    return moment(date).format(format || Config.DATE_HUMAN_FORMAT);
};

export const formatMonth = (date: Date | string): string => {
    return moment(date).format(Config.MONTH_FORMAT);
}

export const monthWithOffset = (offsetFromCurrentMonth: number): string => {
    return moment().add(offsetFromCurrentMonth, "months").format(Config.MONTH_FORMAT);
}

export const formatWeek = (date: Date | string): string => {
    return moment(date).format(Config.WEEK_FORMAT);
}

export const formatTime = (time: Date | string): string => {
    return moment(parseTime(time)).format(Config.TIME_FORMAT);
};

export const parseDate = (date?: Date | string): Date | undefined => {
    if (!date) {
        return undefined;
    }

    return moment(date).toDate();
}

export const parseTime = (time?: Date | string): Date | undefined => {
    if (!time) {
        return undefined;
    }

    if (isTimeString(time)) {
        const timeString = time.toString()
        if (timeString.length === moment.HTML5_FMT.TIME_SECONDS.length) {
            return moment(time, moment.HTML5_FMT.TIME_SECONDS).toDate();
        } else {
            return moment(time).toDate();
        }
    }

    return time as unknown as Date;
}

export const asMoment = (time: string): Moment => {
    return moment(time, "HH:mm");
}

function isTimeString(time: Date | string): time is Date {
    return (typeof time === typeof 'definitely a string');
}

export const timeToDuration = (time: string, as: unitOfTime.Base) => {
    return moment.duration(time).as(as);
};

export const durationToTime = (value: number, unit: unitOfTime.Base) => {
    const duration = moment.duration(value, unit);

    return momentDurationToTime(duration);
};

export const momentDurationToTime = (duration: moment.Duration) => {
    return moment.utc(duration.asMilliseconds()).format(moment.HTML5_FMT.TIME);
};
