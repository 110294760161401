import {default as React, ReactNode} from "react";
import {Form} from "react-bootstrap";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

type RadioFormFieldProps = {
    options: Array<{
        label: ReactNode;
        value: string;
    }>;
};

export const RadioFormField: React.FC<FormikFormFieldProps & RadioFormFieldProps> = ({
                                                                                         options,
                                                                                         disabled,
                                                                                         ...props
                                                                                     }) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <>
                    {options?.map((option, index) => (
                        <Form.Check type="radio"
                                    name={props.name}
                                    key={index}
                                    checked={option.value === field.value}
                                    value={option.value}
                                    label={option.label}
                                    id={`${props.name}_${index}`}
                                    onChange={field.onChange}
                                    onBlur={field.onBlur}
                                    disabled={disabled}
                                    isInvalid={!!meta.error}
                                    inline={props.inline}
                        />
                    ))}
                </>
            )}
        </FormikFormField>
    );
};
