// @flow
import {
    LOGIN_USER_SUCCESS,
    LOGOUT_USER
} from './constants';

type AuthAction = { type: string, payload: {} | string };

export const loginUserSuccess = (user: string): AuthAction => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});

export const logoutUser = (history: any): AuthAction => ({
    type: LOGOUT_USER,
    payload: { history },
});

