import * as React from "react";
import GenericBadge from "../GenericBadge";
import {OpdrachtType} from "../../../redux/planning/types";
import Config from "../../../helpers/Config";


interface OpdrachtTypeBadgeProps {
    opdrachtType: OpdrachtType;
}

const OpdrachtTypeBadge: React.FC<OpdrachtTypeBadgeProps> = ({opdrachtType}) => {
    const config = {
        [OpdrachtType.CO2_LEVERING]: {
            label: Config.labels[OpdrachtType.CO2_LEVERING],
            color: 'info',
        },
        [OpdrachtType.TOESTEL_INSTALLATIE]: {
            label: Config.labels[OpdrachtType.TOESTEL_INSTALLATIE],
            color: 'warning',
        },
        [OpdrachtType.TOESTEL_ONDERHOUD]: {
            label: Config.labels[OpdrachtType.TOESTEL_ONDERHOUD],
            color: 'primary',
        },
        [OpdrachtType.ONDERHOUD_SERVICE_ORDER]: {
            label: Config.labels[OpdrachtType.ONDERHOUD_SERVICE_ORDER],
            color: 'primary',
        },
        [OpdrachtType.ACCESSOIRE_LEVERING]: {
            label: Config.labels[OpdrachtType.ACCESSOIRE_LEVERING],
            color: 'info',
        },
        [OpdrachtType.TOESTEL_INTERVENTIE]: {
            label: Config.labels[OpdrachtType.TOESTEL_INTERVENTIE],
            color: 'danger',
        }
    };

    return <GenericBadge value={opdrachtType}
                         config={config}/>;
};

export default OpdrachtTypeBadge;
