import React from 'react';
import {Co2BestellingModel} from "../../redux/co2bestelling/types";
import ArtikelLabel from "../../components/aqualex/ArtikelLabel";
import {useTranslation} from "../../helpers/i18nUtils";
import {Trans} from "react-i18next";


interface Co2BestellingSummaryProps {
    co2Bestelling?: Co2BestellingModel;
}

const Co2BestellingSummary: React.FC<Co2BestellingSummaryProps> = ({
                                                                       co2Bestelling
                                                                   }) => {

    const {t} = useTranslation("co2-bestelling");

    if (co2Bestelling) {
        const artikel = co2Bestelling.besteldArtikel;
        const aantal = co2Bestelling.aantal;

        return (
            <>
                <ArtikelLabel artikel={artikel}/>
                <br/>
                <span className="text-muted">
                    {co2Bestelling.besteldArtikel.co2Kenmerken.hervulbaar
                        ? <span>Aantal omruil: {co2Bestelling.aantal || "0"}{co2Bestelling.extraAantal ? <>, extra: {co2Bestelling.extraAantal}</> : null}</span>
                        : <span><Trans t={t}
                            i18nKey="Labels.co2-bestellingen-aantal">
                            Aantal: {{aantal}}</Trans></span>
                    }
                </span>
            </>
        );
    } else {
        return null;
    }
};

export default Co2BestellingSummary;
