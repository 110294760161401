import * as React from "react";
import * as PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";
import {Link} from 'react-router-dom';
import KlantLabel from "./KlantLabel";
import {KlantModel} from "../../redux/klant/types";

const UnclickableKlantBox = ({klant}: { klant: KlantModel }) => {
    return (
        <Row className="mb-1 align-items-center">
            <Col className="col-auto">
                <div className="avatar-sm ">
                    <span className="avatar-title bg-primary-lighten text-primary rounded">
                        <i className="dripicons-user"/>
                    </span>
                </div>
            </Col>
            <Col className="pl-0">
                <KlantLabel klant={klant}/>
                <p className="mb-0">{klant.adres}, {klant.postcode} {klant.plaats}</p>
            </Col>
        </Row>
    );
};

const KlantBox = ({klant, clickable}: { klant: KlantModel, clickable?: boolean }) => {

    if (clickable) {
        return <Link to={`/klant/${klant.id}`}>
            <UnclickableKlantBox klant={klant}/>
        </Link>;
    } else {
        return <UnclickableKlantBox klant={klant}/>
    }
};

KlantBox.propTypes = {
    klant: PropTypes.object.isRequired,
    clickable: PropTypes.bool,
};

export default KlantBox;
