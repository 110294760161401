import React, {CSSProperties, ReactNode} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Spinner from '../../Spinner';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from "react-bootstrap-table2-overlay";

// fix voor onnodige warning: https://github.com/derrickpelletier/react-loading-overlay/pull/57#issuecomment-1054194254
import LoadingOverlay from "react-loading-overlay"
import {useTranslation} from "../../../helpers/i18nUtils";

LoadingOverlay.propTypes = undefined

export interface SimpleTableProps<T> {
    keyField?: string;
    itemsLabel: string;
    loading?: boolean;
    loadingOverlay?: boolean;
    items: Array<T>;
    rowClicked?: (T) => void;
    expandRow?: (T) => JSX.Element;
    columns: Array<TableColumn<T, any>>;
    defaultSorted?;
    selectRow?: any;
    selected?;
    pass?: any;
    paged?: boolean;
    compact?: boolean;
    wrapperClass?: string;
}

export interface TableColumn<TRow, TCell> {
    dataField: string; /*keyof TRow | 'action'*/
    text?: ReactNode;
    isKey?: boolean;
    hidden?: boolean;
    sort?: boolean;
    isDummyField?: boolean;
    classes?: string | ((cell: TCell, row: TRow, rowIndex: number, colIndex: number) => string | undefined);
    formatter?: (cell: TCell, row: TRow, rowIndex: number, extraData: any) => ReactNode | undefined;
    sortValue?: (cell: TCell, row: TRow) => any;
    style?: CSSProperties;

    headerClasses?: string | ((cell: TCell, row: TRow, rowIndex: number, colIndex: number) => string | undefined);
    headerStyle?: CSSProperties;
}

/**
 * SimpleTable: data is een array van items, optioneel paged
 */
const SimpleTable = <T, >({
                              keyField = 'id',
                              itemsLabel,
                              loading = false,
                              loadingOverlay = true,
                              items,
                              rowClicked,
                              columns,
                              defaultSorted,
                              selectRow,
                              expandRow,
                              selected,
                              paged = false,
                              pass = {},
                              compact = false,
                              wrapperClass
                          }: SimpleTableProps<T>) => {
    const {t} = useTranslation("algemeen");

    const paginationOptions = {
        pageStartIndex: 1,
        withFirstAndLast: true,
        firstPageText: t("Tabel.eerste", "Eerste"),
        prePageText: t("Tabel.vorige", "Vorige"),
        nextPageText: t("Tabel.volgende", "Volgende"),
        lastPageText: t("Tabel.laatste", "Laatste"),
        firstPageTitle: t("Tabel.ga-naar-de-eerste-pagina", "Ga naar de eerste pagina"),
        prePageTitle: t("Tabel.ga-naar-de-vorige-pagina", "Ga naar de vorige pagina"),
        nextPageTitle: t("Tabel.ga-naar-de-volgende-pagina", "Ga naar de volgende pagina"),
        lastPageTitle: t("Tabel.ga-naar-de-laatste-pagina", "Ga naar de laatste pagina"),
        paginationTotalRenderer: (from, to, size) => {
            if (size > 0) {
                return <span
                    className="react-bootstrap-table-pagination-total ml-2">{t("Tabel.pagina-resultaten", "{{van}} tot {{tot}} van {{totaal}} resultaten", {van: from, tot: to, totaal: size})}</span>;
            } else {
                return null;
            }
        },
        showTotal: true,
        sizePerPageList: [
            {
                text: '10',
                value: 10,
            },
            {
                text: '50',
                value: 50,
            },
        ],
    };

    return (
        <BootstrapTable
            bootstrap4
            keyField={keyField}
            noDataIndication={() => (loading ?
                <Spinner className="m-2" color='primary'/> : t("Tabel.geen-gevonden", "Geen {{item}} gevonden", {item: itemsLabel}))}
            loading={items && items.length > 0 && loading}
            overlay={loadingOverlay ? overlayFactory({
                spinner: <Spinner/>,
                styles: {
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(192, 192, 192, 0.15)"
                    }),
                    content: (base) => ({
                        ...base,
                        // margin: "1em auto"
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    })
                }
            }) : undefined}
            data={items}
            columns={columns}
            defaultSorted={defaultSorted}
            bordered={false}
            table
            classes={compact ? "table-sm" : ""}
            headerClasses={compact ? "" : "thead-light"}
            wrapperClasses={`table-responsive ${wrapperClass || ""}`}
            rowClasses={rowClicked ? "cursor-pointer" : undefined}
            hover
            selectRow={selectRow}
            selected={selected}
            rowEvents={{
                onClick: (e, row, rowIndex) => {
                    if (rowClicked) {
                        rowClicked(row);
                    }
                },
            }}
            expandRow={expandRow
                ? {
                    renderer: (row, rowIndex) => {
                        return expandRow(row)
                    },
                    expandColumnRenderer: ({expanded, rowKey, expandable}) => (expanded ?
                        <i className="mdi mdi-16px mdi-chevron-down"/> :
                        <i className="mdi mdi-16px mdi-chevron-right"/>),
                    expandHeaderColumnRenderer: ({isAnyExpands}) => (<span/>),
                    showExpandColumn: true,
                    expandByColumnOnly: false,
                }
                : undefined
            }
            pagination={paged ? paginationFactory(paginationOptions) : undefined}
            {...pass}
        />
    );
};

export default SimpleTable;
