import React from "react";
import {VerzendAdresModel} from "../verzendadres/types";
import {_Links, TransportKostConfiguratieModel, TransportKostConfiguratieType} from "../types";
import {ExtraCo2FlesBeleid} from "../co2bestelling/types";
import {Trans, Translation} from "react-i18next";

export type KlantFilters = {
    btwNummer?: string;
    ondernemingsnummer?: string;
    naam?: string,
    nummer?: string,
    naamOfNummer?: string,
    toonVerwijderd?: boolean,
};

export interface KlantSimilarSearchFilters {
    bedrijfId: string;
    naam?: string | null;
    emailadres?: string | null;

    btwNummer?: string | null;
    ondernemingsnummer?: string | null;
}

export type KlantModel = {
    id: string;
    bedrijfId: string;
    nr: string;
    naam: string;
    adres: string;
    adres2?: string;
    postcode: string;
    plaats: string;
    landRegioCode: string;
    contact?: string;
    telefoon?: string;
    mobieleTelefoon?: string;
    email?: string;
    emailFacturen?: string;
    emailAanmaningen?: string;
    contactEmailAdressen: string[];
    verzendAdressen: Array<VerzendAdresModel>;
    locale: string;
    externDocumentNummerVerplicht: boolean;
    sector: string;
    btwNummer?: string;
    ondernemingsNummer?: string;
    transportKostConfiguratie: TransportKostConfiguratieModel;
    extraCo2FlesBeleid: ExtraCo2FlesBeleid;
    watermeterStandOpnemenBijBezoek: boolean;
    maximaleInterventieResponsTijd: number;
    geblokkeerd: KlantGeblokkeerd;
    verwijderd: boolean;
    _links: _Links;
};

export enum KlantGeblokkeerd {
    NEE = "NEE",
    VERZENDEN = "VERZENDEN",
    FACTUUR = "FACTUUR",
    ALLE = "ALLE"
}

export interface MinimalKlantModel {
    id: string;
    bedrijfId: string;
    naam: string;
    nr: string;
    adres: string;
    adres2: string;
    plaats: string;
    postcode: string;
    landRegioCode: string;
}

export interface AdresForm {
    adres: string;
    adres2?: string;
    postcode: string;
    plaats: string;
    landCode: string;
}

export interface GeolocationModel {
    latitude: number;
    longitude: number;
}

export enum KlantSectoren {
    BEDRIJF = "BEDRIJF"
}

export const KlantSector = [
    {
        label: <Translation>{t => t("klant:Klantsector.algemeen", "Algemeen")}</Translation>,
        options: [
            {
                label: <Translation>{t => t("klant:Klantsector.bedrijf", "Bedrijf")}</Translation>,
                value: KlantSectoren.BEDRIJF
            },
            {
                label: <Translation>{t => t("klant:Klantsector.particulier", "Particulier")}</Translation>,
                value: "PARTICULIER"
            },
            {
                label: <Translation>{t => t("klant:Klantsector.architect", "Architect")}</Translation>,
                value: "ARCHITECT"
            },
            {
                label: <Translation>{t => t("klant:Klantsector.overheid", "Overheid")}</Translation>,
                value: "OVERHEID"
            },
            {
                label:
                    <Translation>{t => t("klant:Klantsector.sport-cultuur-vrije-tijd", "Sport, cultuur, vrije tijd")}</Translation>,
                value: "SPORT, CULTUUR"
            }
        ]
    },
    {
        label: <Translation>{t => t("klant:Klantsector.horeca", "Horeca")}</Translation>,
        options: [
            {
                label: <Translation>{t => t("klant:Klantsector.hotel", "Hotel")}</Translation>,
                value: "HOTEL"
            },
            {
                label: <Translation>{t => t("klant:Klantsector.restaurant", "Restaurant")}</Translation>,
                value: "RESTAURANT"
            },
            {
                label: <Translation>{t => t("klant:Klantsector.café", "Café")}</Translation>,
                value: "CAFÉ"
            },
            {
                label: <Translation>{t => t("klant:Klantsector.catering", "Catering")}</Translation>,
                value: "CATERING"
            },
            {
                label: <Trans i18nKey={"klant:Klantsector.overige-horeca"}>
                    <span>Overige horeca <small className='text-muted'>(campings, B&B, vakantieverblijven, kortverblijf, kampeerterrein, ...)</small></span>
                </Trans>,
                value: "OVERIGE HORECA"
            }
        ]
    },
    {
        label: <Translation>{t => t("klant:Klantsector.onderwijs", "Onderwijs")}</Translation>,
        options: [
            {
                label:
                    <Translation>{t => t("klant:Klantsector.kleuter-basisschool", "Kleuter-Basisschool")}</Translation>,
                value: "KLEUTER-BASISSCHOOL"
            },
            {
                label:
                    <Translation>{t => t("klant:Klantsector.hoge-scholen-universiteiten", "Hoge scholen & Universiteiten")}</Translation>,
                value: "HOGE SCHOLEN & UNIF"
            },
            {
                label:
                    <Translation>{t => t("klant:Klantsector.middelbare-scholen", "Middelbare scholen")}</Translation>,
                value: "MIDDELBARE SCHOLEN"
            },
            {
                label: <Trans i18nKey={"klant:Klantsector.overig-onderwijs"}>
                    <span>Overig onderwijs <small
                        className="text-muted">(+&nbsp;onderwijsondersteunende activiteiten)</small></span>
                </Trans>,
                value: "OVERIGE ONDERWIJS"
            }
        ]
    },
    {
        label: <Translation>{t => t("klant:Klantsector.zorg", "Zorg")}</Translation>,
        options: [
            {
                label: <Translation>{t => t("klant:Klantsector.ziekenhuis", "Ziekenhuis")}</Translation>,
                value: "ZIEKENHUIS"
            },
            {
                label:
                    <Translation>{t => t("klant:Klantsector.voorzieningen-voor-ouderenzorg", "Voorzieningen voor Ouderenzorg")}</Translation>,
                value: "WOONZORG/OUDERENZORG"
            },
            {
                label:
                    <Trans i18nKey={"klant:Klantsector.overige-zorg"}>
                        <span>Overige zorg <small className="text-muted">(praktijken van artsen en tandartsen, overige menselijke gezondheidszorg, maatschappelijke dienstverlening)</small></span>
                    </Trans>,
                value: "OVERIGE ZORG"
            }
        ]
    }
];

export const KlantBedrijfsgrootte = {
    "EENMANSZAAK": <Translation>{t => t("klant:KlantBedrijfsgrootte.eenmanszaak", "Eenmanszaak")}</Translation>,
    "GROTE ONDERNEMINGEN":
        <Translation>{t => t("klant:KlantBedrijfsgrootte.grote-ondernemingen", "Grote ondernemingen")}</Translation>,
    "KMO":
        <Translation>{t => t("klant:KlantBedrijfsgrootte.kleine-middelgrote-onderneming", "Kleine Middelgrote Ondernemingen")}</Translation>,
    "PARTICULIER": <Translation>{t => t("klant:KlantBedrijfsgrootte.particulier", "Particulier")}</Translation>
};

export const KlantBedrijfsgrootteEntries = Object.entries(KlantBedrijfsgrootte);

export const KlantTalen = {
    "nl-BE": <Translation>{t => t("klant:KlantTalen.nederlands-belgië", "Nederlands (België)")}</Translation>,
    "de-BE": <Translation>{t => t("klant:KlantTalen.duits-belgië", "Duits (België)")}</Translation>,
    "fr-BE": <Translation>{t => t("klant:KlantTalen.frans-belgië", "Frans (België)")}</Translation>,
    "nl-NL": <Translation>{t => t("klant:KlantTalen.nederlands-nederland", "Nederlands (Nederland)")}</Translation>,
    "de-DE": <Translation>{t => t("klant:KlantTalen.duits-duitsland", "Duits (Duitsland)")}</Translation>,
    "fr-FR": <Translation>{t => t("klant:KlantTalen.frans-frankrijk", "Frans (Frankrijk)")}</Translation>,
    "en": <Translation>{t => t("klant:KlantTalen.engels", "Engels")}</Translation>
};

export const KlantLanden = {
    BE: <Translation>{t => t("klant:KlantLanden.belgië", "België")}</Translation>,
    NL: <Translation>{t => t("klant:KlantLanden.nederland", "Nederland")}</Translation>,
    DE: <Translation>{t => t("klant:KlantLanden.duitsland", "Duitsland")}</Translation>,
    FR: <Translation>{t => t("klant:KlantLanden.frankrijk", "Frankrijk")}</Translation>,
    LU: <Translation>{t => t("klant:KlantLanden.luxemburg", "Luxemburg")}</Translation>
};

export const KlantAanmaningscondities = {
    AANMNL:
        <Translation>{t => t("klant:KlantAanmaningscondities.nl-algemeen-4niveaus", "NL - algemeen 4 niveaus")}</Translation>,
    DOM:
        <Translation>{t => t("klant:KlantAanmaningscondities.betaling-via-domicilëring", "Betaling via domiciliëring")}</Translation>,
    ENU: <Translation>{t => t("klant:KlantAanmaningscondities.engels", "Engels")}</Translation>,
    FRB: <Translation>{t => t("klant:KlantAanmaningscondities.frans", "Frans")}</Translation>,
    INCASSO:
        <Translation>{t => t("klant:KlantAanmaningscondities.ingediend-ter-incasso", "Ingediend ter incasso")}</Translation>,
    NLB: <Translation>{t => t("klant:KlantAanmaningscondities.nederlands", "Nederlands")}</Translation>,
    UITSTEL:
        <Translation>{t => t("klant:KlantAanmaningscondities.uitstel-wegens-corona", "UITSTEL WEGENS CORONA")}</Translation>,
    WACHTEND:
        <Translation>{t => t("klant:KlantAanmaningscondities.info-klantverantwoordelijke", "info klantverantwoordelijke")}</Translation>
};
export const KlantAanmaningsconditiesEntries = Object.entries(KlantAanmaningscondities);

export const KlantBetalingscondities = {
    "0D": <Translation>{t => t("klant:KlantBetalingscondities.contant", "Contant")}</Translation>,
    "10D": <Translation>{t => t("klant:KlantBetalingscondities.netto-10-dagen", "Netto 10 dagen")}</Translation>,
    "10DK2":
        <Translation>{t => t("klant:KlantBetalingscondities.netto-10-dagen-2%", "Netto 10 dagen 2%")}</Translation>,
    "120DEM":
        <Translation>{t => t("klant:KlantBetalingscondities.netto-120-dagen-einde-maand", "Netto 120 dagen einde maand")}</Translation>,
    "12M": <Translation>{t => t("klant:KlantBetalingscondities.netto-12-maand", "Netto 12 maand")}</Translation>,
    "14 DAGEN": <Translation>{t => t("klant:KlantBetalingscondities.netto-14-dagen", "Netto 14 dagen")}</Translation>,
    "14D": <Translation>{t => t("klant:KlantBetalingscondities.netto-14-dagen", "Netto 14 dagen")}</Translation>,
    "14DDOM2":
        <Translation>{t => t("klant:KlantBetalingscondities.domiciliëring-14-dagen-2%", "Domiciliëring 14 dagen 2%")}</Translation>,
    "15D": <Translation>{t => t("klant:KlantBetalingscondities.netto-15-dagen", "Netto 15 dagen")}</Translation>,
    "1M(8D)":
        <Translation>{t => t("klant:KlantBetalingscondities.een-maand-2%-8-dagen", "1 maand/2% 8 dagen")}</Translation>,
    "21 DAGEN": <Translation>{t => t("klant:KlantBetalingscondities.netto-21-dagen", "Netto 21 dagen")}</Translation>,
    "21D": <Translation>{t => t("klant:KlantBetalingscondities.netto-21-dagen", "Netto 21 dagen")}</Translation>,
    "30 DAGEN": <Translation>{t => t("klant:KlantBetalingscondities.netto-30-dagen", "Netto 30 dagen")}</Translation>,
    "30D": <Translation>{t => t("klant:KlantBetalingscondities.netto-30-dagen", "Netto 30 dagen")}</Translation>,
    "30D8K2":
        <Translation>{t => t("klant:KlantBetalingscondities.netto-30-dagen-kk-8-dagen-2%", "Netto 30 dagen KK 8 dagen 2%")}</Translation>,
    "30DEM":
        <Translation>{t => t("klant:KlantBetalingscondities.netto-30-dagen-einde-maand", "Netto 30 dagen einde maand")}</Translation>,
    "30DEM8K2":
        <Translation>{t => t("klant:KlantBetalingscondities.netto-3-dagen-einde-maand-kk-8-dagen-2%-excl-btw", "Netto 30 dagen einde maand KK 8 dagen 2% excl BTW")}</Translation>,
    "30DEMDOM2":
        <Translation>{t => t("klant:KlantBetalingscondities.domiciëli", "Domiciliëring 30 dagen einde maand 2%")}</Translation>,
    "30DFK":
        <Translation>{t => t("klant:KlantBetalingscondities.netto-30-dagen-2%", "Netto 30 dagen 2%")}</Translation>,
    "45D": <Translation>{t => t("klant:KlantBetalingscondities.netto-45-dagen", "Netto 45 dagen")}</Translation>,
    "50D": <Translation>{t => t("klant:KlantBetalingscondities.netto-50-dagen", "Netto 50 dagen")}</Translation>,
    "60D": <Translation>{t => t("klant:KlantBetalingscondities.netto-60-dagen", "Netto 60 dagen")}</Translation>,
    "60DDOM2":
        <Translation>{t => t("klant:KlantBetalingscondities.domiciliëring-60-dagen-2%", "Domiciliëring 60 dagen 2%")}</Translation>,
    "60DEM":
        <Translation>{t => t("klant:KlantBetalingscondities.netto-60-dagen-einde-maand", "Netto 60 dagen einde maand")}</Translation>,
    "60DEM2":
        <Translation>{t => t("klant:KlantBetalingscondities.netto-60-dagen-einde-maand-2%", "Netto 60 dagen einde maand 2%")}</Translation>,
    "7 DAGEN": <Translation>{t => t("klant:KlantBetalingscondities.netto-7-dagen", "Netto 7 dagen")}</Translation>,
    "8D": <Translation>{t => t("klant:KlantBetalingscondities.netto-8-dagen", "Netto 8 dagen")}</Translation>,
    "8DK1": <Translation>{t => t("klant:KlantBetalingscondities.netto-8-dagen-1%", "Netto 8 dagen 1%")}</Translation>,
    "8DK2": <Translation>{t => t("klant:KlantBetalingscondities.kk-8-dagen-2%", "KK 8 dagen 2%")}</Translation>,
    "8DK3": <Translation>{t => t("klant:KlantBetalingscondities.netto-8-dagen-3%", "Netto 8 dagen 3%")}</Translation>,
    "90D":
        <Translation>{t => t("klant:KlantBetalingscondities.netto-90-dagen-einde-maand", "Netto 90 dagen einde maand")}</Translation>,
    "CONTANT": <Translation>{t => t("klant:KlantBetalingscondities.contant", "Contant")}</Translation>,
    "DOM": <Translation>{t => t("klant:KlantBetalingscondities.domiciliëring", "Domiciliëring")}</Translation>,
    "LM": <Translation>{t => t("klant:KlantBetalingscondities.lopende-maand", "Lopende maand")}</Translation>,
    "REMBOURS": <Translation>{t => t("klant:KlantBetalingscondities.rembours", "Rembours")}</Translation>,
    "VOORAF": <Translation>{t => t("klant:KlantBetalingscondities.voorafbetaling", "Voorafbetaling")}</Translation>,
};
export const KlantBetalingsconditiesEntries = Object.entries(KlantBetalingscondities);

export const KlantBtwBedrijfsboekingsgroepen = {
    "BINNENLAND": <Translation>{t => t("klant:KlantBtwBedrijfsboekingsgroepen.binnenland", "Binnenland")}</Translation>,
    "EU": <Translation>{t => t("klant:KlantBtwBedrijfsboekingsgroepen.eu", "EU")}</Translation>,
};
export const KlantBtwBedrijfsboekingsgroepenEntries = Object.entries(KlantBtwBedrijfsboekingsgroepen);

export const Klantenprijsgroepen = {
    "0": <Translation>{t => t("klant:KlantPrijsgroepen.geen-info", "geen info")}</Translation>,
    "ARCHITECT": <Translation>{t => t("klant:KlantPrijsgroepen.architect", "Architect")}</Translation>,
    "BEDRIJF":
        <Translation>{t => t("klant:KlantPrijsgroepen.bedrijf-met-minder-dan-100-personen", "Bedrijf < 100 personen")}</Translation>,
    "BEDRIJFGRO":
        <Translation>{t => t("klant:KlantPrijsgroepen.groot-bedrijf-met-meer-dan-100-personen", "Groot bedrijf > 100 personen")}</Translation>,
    "DEALER ACT": <Translation>{t => t("klant:KlantPrijsgroepen.dealer-actief", "Dealer actief")}</Translation>,
    "DEALER PAS": <Translation>{t => t("klant:KlantPrijsgroepen.dealer-passief", "Dealer passief")}</Translation>,
    "DENEMARKEN": <Translation>{t => t("klant:KlantPrijsgroepen.denemarken", "Denemarken")}</Translation>,
    "FAILLIET": <Translation>{t => t("klant:KlantPrijsgroepen.failliet", "Failliet")}</Translation>,
    "FRANKRIJK": <Translation>{t => t("klant:KlantPrijsgroepen.frankrijk", "Frankrijk")}</Translation>,
    "HORECA": <Translation>{t => t("klant:KlantPrijsgroepen.horeca", "Horeca")}</Translation>,
    "KEUKENBOUW": <Translation>{t => t("klant:KlantPrijsgroepen.keukenbouwer", "Keukenbouwer")}</Translation>,
    "LUXEMBURG": <Translation>{t => t("klant:KlantPrijsgroepen.luxemburg", "Luxemburg")}</Translation>,
    "NA": <Translation>{t => t("klant:KlantPrijsgroepen.niet-actief", "Niet actief")}</Translation>,
    "NEDERLAND": <Translation>{t => t("klant:KlantPrijsgroepen.nederland", "Nederland")}</Translation>,
    "OOSTENRIJK": <Translation>{t => t("klant:KlantPrijsgroepen.oostenrijk", "Oostenrijk")}</Translation>,
    "OVERHEID":
        <Translation>{t => t("klant:KlantPrijsgroepen.overheid-ocmw-stad", "Overheid, OCMW, stad")}</Translation>,
    "PARTICULIE": <Translation>{t => t("klant:KlantPrijsgroepen.particulier", "Particulier")}</Translation>,
    "RUSTH OVER": <Translation>{t => t("klant:KlantPrijsgroepen.rusthuis-overheid", "Rusthuis overheid")}</Translation>,
    "RUSTH PRIV": <Translation>{t => t("klant:KlantPrijsgroepen.rusthuis-privé", "Rusthuis prive")}</Translation>,
    "SCHOOL":
        <Translation>{t => t("klant:KlantPrijsgroepen.school-kinderdagverblijf-creche", "School, kinderdagverblijf, creche")}</Translation>,
    "SPANJE": <Translation>{t => t("klant:KlantPrijsgroepen.spanje", "Spanje")}</Translation>,
    "TESTTOESTE": <Translation>{t => t("klant:KlantPrijsgroepen.testtoestel", "Testtoestel")}</Translation>,
    "TRIN": <Translation>{t => t("klant:KlantPrijsgroepen.trin", "TRIN")}</Translation>,
    "TRINITY": <Translation>{t => t("klant:KlantPrijsgroepen.trinity", "Trinity")}</Translation>,
    "ZIEKENHUIS": <Translation>{t => t("klant:KlantPrijsgroepen.ziekenhuis", "Ziekenhuis")}</Translation>,
    "ZORG":
        <Translation>{t => t("klant:KlantPrijsgroepen.zorg-psychiatrisch-of-invaliden", "Psychiatrisch, invaliden")}</Translation>
};
export const KlantenprijsgroepenEntries = Object.entries(Klantenprijsgroepen);

export interface CreateKlantForm {
    bedrijfId: string;
    btwNummer: string;

    naam: string;
    contactNaam: string;
    verkoperId?: string;
    externDocumentNrVerplicht: boolean;

    btwBedrijfsboekingsgroep: string;
    kredietlimiet: number;
    klantenprijsgroep: string;
    betalingscondities: string;
    aanmaningsconditie: string;
    sector: string;
    bedrijfsgrootte: string;
    keyAccount: boolean;

    adres: AdresForm;

    emailadres: string;
    emailadresFacturen?: string;
    emailadresAanmaningen?: string;
    mobielTelefoonnummer?: string;
    telefoonnummer: string;
    locale: string;

    transportKostType: TransportKostConfiguratieType;
    transportKostValue?: number;

    extraCo2FlesBeleid: string;

    uitnodigingType?: CreateKlantUitnodigingType;
    klantAccounts?: {naam: string; emailAdres: string;}[];
    geenKlantAccountMakenReden?: GeenKlantAccountMakenReden;
}

export enum CreateKlantUitnodigingType {
    UITGESTELD = "UITGESTELD",
    DIRECT = "DIRECT"
}

export interface UpdateKlantForm {
    transportKostConfiguratieType: TransportKostConfiguratieType;
    transportKostValue: number;
    extraCo2FlesBeleid: ExtraCo2FlesBeleid;
    watermeterStandOpnemenBijBezoek: boolean;
    maximaleInterventieResponsTijd: number;
}

export enum GeenKlantAccountMakenReden {
    KLANT_HEEFT_GEEN_EMAILADRES = "KLANT_HEEFT_GEEN_EMAILADRES",
    EMAILADRES_VOOR_KLANTACCOUNT_NOG_NIET_BEKEND = "EMAILADRES_VOOR_KLANTACCOUNT_NOG_NIET_BEKEND",
    KLANT_WIL_GEEN_MYAQUALEX_ACCOUNT = "KLANT_WIL_GEEN_MYAQUALEX_ACCOUNT",
    NIET_BESPROKEN_MET_KLANT = "NIET_BESPROKEN_MET_KLANT",
    ANDERE = "ANDERE"
}
