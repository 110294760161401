import {
    EndpointBuilder,
    EndpointDefinitions,
    MutationDefinition,
    QueryDefinition,
    ResultDescription,
    TagDescription
} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {baseApi} from "./api";
import {Page} from "./pagination/types";
import {FiltersAndPageable} from "../support/uiSlice";
import {BaseQueryFn} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {FileDownload, mapFileResponse, saveFile} from "../../helpers/download";
import {BaseQueryArg} from "@reduxjs/toolkit/src/query/baseQueryTypes";

export function buildApi<NewDefinitions extends EndpointDefinitions>(fn: (build: EndpointBuilder<BaseQueryFn, string, string>) => NewDefinitions) {
    return baseApi.injectEndpoints({
        endpoints: build => fn(build)
    });
}

export function buildGetByIdQuery<TModel, TParam extends string>(build: EndpointBuilder<BaseQueryFn, string, string>, omschrijving: string, tag: string, pathFn: (params: TParam) => string) {
    return build.query<TModel, TParam>({
        query: pathFn,
        providesTags: (result, error, id) => [{type: tag, id}],
        extraOptions: {
            omschrijving,
        }
    });
}

export function buildQuery<TResult, TParam>(build: EndpointBuilder<BaseQueryFn, string, string>, omschrijving: string, tag: string, pathFn: (params: TParam) => string, options?: Partial<QueryDefinition<TParam, BaseQueryFn, string, TResult, string>>, queryOptions?: BaseQueryArg<any>, paramFn?: (params: TParam) => any) {
    return build.query<TResult, TParam>({
        query: (params) => {
            const mappedParams = paramFn ? paramFn(params) : params;

            return {
                url: pathFn(params),
                params: mappedParams,
                ...queryOptions
            };
        },
        providesTags: options?.providesTags,
        transformResponse: options?.transformResponse,
        extraOptions: {
            omschrijving,
        }
    });
}

export function buildFilteredAndPagedSearchQuery<TModel extends { id: string }, TFilters>(build: EndpointBuilder<BaseQueryFn, string, string>, omschrijving: string, tag: string, pathFn: (params: TFilters) => string, paramFn?: (params: TFilters) => any) {
    return build.query<Page<TModel>, FiltersAndPageable<TFilters>>({
        query: (params) => {
            const {filters, pageable} = params;

            const mappedFilters = paramFn ? paramFn(filters) : filters;

            return {
                url: pathFn(filters),
                params: {
                    ...mappedFilters,
                    page: pageable.pageNumber - 1,
                    size: pageable.pageSize,
                    sort: `${pageable.sortField},${pageable.sortOrder},ignoreCase`,
                }
            };
        },
        transformResponse: (response: Page<TModel>) => {
            return {
                content: response.content,
                number: response.number + 1,
                size: response.size,
                totalElements: response.totalElements,
                loading: false,
            };
        },
        providesTags: (result) =>
            result
                ? [
                    // Provides a tag for each post in the current page,
                    // as well as the "PARTIAL-LIST" tag.
                    ...result.content.map(({id}) => ({type: tag, id})),
                    {type: tag, id: "PARTIAL-LIST"},
                ]
                : [{type: tag, id: "PARTIAL-LIST"}],
        extraOptions: {
            omschrijving,
        }
    });
}

export function buildUpdateMutation<TModel, TForm extends { id: string }>(build: EndpointBuilder<BaseQueryFn, string, string>, omschrijving: string, tag: string, pathFn: (form: TForm) => string, options?: Partial<MutationDefinition<TForm, BaseQueryFn, string, TModel, string>>, queryOptions?: BaseQueryArg<any>) {
    return build.mutation<TModel, TForm>({
        query: (form) => {
            const {id, ...patch} = form;

            return {
                url: pathFn(form),
                body: !queryOptions?.useParams ? patch : undefined,
                params: queryOptions?.useParams ? patch : undefined,
                method: "POST",
                ...queryOptions
            };
        },
        invalidatesTags: (result, error, arg, meta) => {
            return [tag, {
                type: tag,
                id: arg.id
            }, ...invalidatesTagsOptionToArray(options?.invalidatesTags || [], result, error, arg, meta)];
        },
        extraOptions: {
            omschrijving,
        },
        onQueryStarted: options?.onQueryStarted
    });
}

export function buildMutation<TModel, TForm>(build: EndpointBuilder<BaseQueryFn, string, string>, omschrijving: string, tag: string, pathFn: (form: TForm) => string, options?: Partial<MutationDefinition<TForm, BaseQueryFn, string, TModel, string>>, queryOptions?: BaseQueryArg<any>, formFn?: (params: TForm) => any) {
    return build.mutation<TModel, TForm>({
        query: (form) => {
            const mappedForm = formFn ? formFn(form) : form;

            return {
                url: pathFn(form),
                method: "POST",
                body: !queryOptions?.useParams ? mappedForm : undefined,
                params: queryOptions?.useParams ? mappedForm : undefined,
                ...queryOptions
            };
        },
        invalidatesTags: (result, error, arg, meta) => {
            return [tag, ...invalidatesTagsOptionToArray(options?.invalidatesTags || [], result, error, arg, meta)];
        },
        extraOptions: {
            omschrijving,
        }
    });
}

const invalidatesTagsOptionToArray = <ResultType, ErrorType, QueryArg>(options: ResultDescription<any, any, any, any, any>, result: ResultType | undefined, error: ErrorType | undefined, arg: QueryArg, meta) => {
    let invalidatesTags: TagDescription<string>[] = [];
    if (options) {
        if (Array.isArray(options)) {
            invalidatesTags.push(...options);
        } else if (typeof options === "function") {
            invalidatesTags.push(...options(result, error, arg, meta));
        }
    }

    return invalidatesTags;
};

export function buildDownload<TModel extends FileDownload, TParams>(build: EndpointBuilder<BaseQueryFn, string, string>, omschrijving: string, tag: string, pathFn: (form: TParams) => string, options?: { method?: string; invalidatesTags?: ResultDescription<any, any, any, any, any> }) {
    const {
        method = "GET"
    } = options || {};

    return build.mutation<TModel, TParams>({
        query: (params) => {
            return {
                url: pathFn(params),
                method,
                params,
                responseHandler: (response) => {
                    const fileDownload = mapFileResponse(response);
                    saveFile(fileDownload);

                    return fileDownload as TModel;
                }
            };
        },
        extraOptions: {omschrijving}
    });
}
