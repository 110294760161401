import {default as React, ReactNode, useEffect} from "react";
import {FieldAttributes, FieldHelperProps, FieldInputProps, FieldMetaProps, useField} from "formik";
import {Form} from "react-bootstrap";
import {LabelWithHelp} from "./LabelWithHelp";

export type FormikFormFieldProps = FieldAttributes<any> & {
    label?: string;
    helpText?: string;
    showFeedback?: boolean;
    children: (field: FieldInputProps<any>, meta: FieldMetaProps<any>, helpers: FieldHelperProps<any>) => ReactNode;
    inline?: boolean;
}

export const FormikFormField: React.FC<FormikFormFieldProps> = ({
                                                                    label,
                                                                    helpText,
                                                                    showFeedback = true,
                                                                    children,
                                                                    inline,
                                                                    ...props
                                                                }) => {
    const [field, meta, helpers] = useField(props);

    useEffect(() => {
        helpers.setError(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value]);

    return (
        <>
            {label && (
                <Form.Label htmlFor={field.name} className={inline ? "mr-2" : ""}>
                    <LabelWithHelp fieldName={field.name} label={label} helpText={helpText}/>
                </Form.Label>
            )}
            {children(field, meta, helpers)}
            {showFeedback && (
                <Form.Control.Feedback type='invalid'
                                       style={{display: 'block'}}>{Array.isArray(meta.error) ? null : meta.error}</Form.Control.Feedback>
            )}
        </>
    );
};
