import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {MedewerkerModel} from "../../../redux/medewerker/types";
import {ProfielfotoAvatar} from "../ProfielfotoAvatar";

interface UnclickableMedewerkerBoxProps {
    medewerker: MedewerkerModel;
}

const UnclickableMedewerkerBox: React.FC<UnclickableMedewerkerBoxProps> = ({medewerker}) => {
    return (
        <Row className="mb-1 align-items-center flex-nowrap">
            <Col className="col-auto">
                <ProfielfotoAvatar size="sm" src={medewerker.profielfotoUrl} rounded/>
            </Col>
            <Col className="pl-0 col-auto">
                <strong>{medewerker.naam}</strong>
                <p className="mb-0 text-muted" style={{width:"available", whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"}}>{medewerker.gebruikerNaam}</p>
            </Col>
        </Row>
    );
};

export interface MedewerkerBoxProps {
    medewerker: MedewerkerModel;
    clickable: boolean;
}

export const MedewerkerBox: React.FC<MedewerkerBoxProps> = ({medewerker, clickable}) => {
    if (clickable) {
        return (
            <Link to={`/medewerker/${medewerker.id}`}>
                <UnclickableMedewerkerBox medewerker={medewerker}/>
            </Link>
        );
    } else {
        return <UnclickableMedewerkerBox medewerker={medewerker}/>
    }
};

