import React, {useCallback, useState} from "react";
import {useDownloadFileByUri} from "../redux/download/hooks";
import {Lightbox} from "../components/aqualex/Lightbox";

export interface UseFileDownloadOptions {
    uri: string;
    omschrijving?: string;

    isImage?: boolean;
}

export const useFileDownload = (options: UseFileDownloadOptions) => {
    const {uri, omschrijving, isImage} = options;

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [downloadFileByUri, {loading}] = useDownloadFileByUri({
        uri,
        omschrijving
    });

    const download = useCallback(() => {
        if (isImage) {
            setLightboxOpen(true);
        } else {
            downloadFileByUri();
        }
    }, [isImage, downloadFileByUri]);

    const LightboxComponent: React.FC = () => {
        return (
            <Lightbox images={[{url: uri, caption: omschrijving}]} downloadButton open={lightboxOpen}
                      onClose={() => setLightboxOpen(false)}/>
        );
    };

    return {
        download,
        loading,
        LightboxComponent
    };
};
