import {default as React} from "react";
import {FormikFormFieldProps} from "./FormikFormField";
import {ArtikelFormField} from "./ArtikelFormField";
import {Col, Row} from "react-bootstrap";
import ArtikelBox from "../artikel/ArtikelBox";
import {SimpleFormField} from "./SimpleFormField";
import {useFormikContext} from "formik";
import {ArtikelModel} from "../../../redux/artikel/types";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface ArtikelMetAantalFormFieldProps {
    bedrijfId?: string;
    isMulti?: boolean;
}

export type ArtikelMetAantalFormValue = ArtikelModel & {aantal: number};

export const ArtikelMetAantalFormField: React.FC<FormikFormFieldProps & ArtikelMetAantalFormFieldProps> = (props) => {
    const {values} = useFormikContext<any>();

    const {t} = useTranslation("algemeen");

    const value = values?.[props.name];

    return (
        <>
            <ArtikelFormField {...props}/>

            {value?.map((meeTeGevenArtikel, i) => (
                <Row className="align-items-center m-2">
                    <Col>
                        <ArtikelBox clickable={false} artikel={meeTeGevenArtikel}/>
                    </Col>

                    <Col xs="auto">
                        <SimpleFormField placeholder={t("Placeholder.aantal", "Aantal")} name={`${props.name}[${i}].aantal`} type="number" formControlProps={{min: 1, step: 1}}/>
                    </Col>
                </Row>
            ))}
        </>
    );
};
