import {FilterField} from "../overzicht/FilteredAndPagedTable";
import TextFilter from "../overzicht/TextFilter";
import React from "react";
import {Translation} from "react-i18next";

export const KlantNrFilter: FilterField<{ klantNr?: string; }> = (filterValues, handleUpdateFilter) => ({
    naam: 'klantNr',
    label: <Translation>{t => t("algemeen:Labels.klantnr", "Klantnr.")}</Translation>,
    component: <TextFilter style={{width: "5rem"}} value={filterValues.klantNr}
                           onChange={value => handleUpdateFilter('klantNr', value)}/>
});
