import {CLEAR_ERROR, HANDLE_ERROR, HIDE_MODAL, RESET_UI_STATE, SHOW_MODAL,} from "./constants";
import {ApiError, UIActionTypes, UIState} from "./types";
import {RootState} from "../reducers";
import {AnyAction} from "redux";

const INITIAL_STATE: UIState = {
    errors: [],
    unauthorizedError: false,
    modalStack: [],
    pageState: {},
    loading: {},
};

export const UI = (state: UIState = INITIAL_STATE, action: UIActionTypes | AnyAction): UIState => {
    const {type} = action;
    const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

    if (action.meta?.loading && matches) {
        const [, requestName, requestState] = matches;

        const loadingKey = action.meta.loading.classifier ?
            `${requestName}::${action.meta.loading.classifier}`
            : requestName;

        return {
            ...state,
            loading: {
                ...state.loading,
                [loadingKey]: requestState === 'REQUEST',
            },
            ...(action.meta?.storeUIState ? {
                pageState: {
                    ...state.pageState,
                    [action.meta.storeUIState.key]: action.meta.storeUIState.state,
                }
            } : {})
        };
    } else {
        switch (action.type) {
            case HANDLE_ERROR:
                console.log(action.payload)
                const error: ApiError = action.payload;
                if (error?.status === 401) {
                    return {
                        ...state,
                        unauthorizedError: true,
                    }
                }

                return {
                    ...state,
                    errors: [...state.errors, action.payload],
                };
            case CLEAR_ERROR:
                return {
                    ...state,
                    errors: state.errors.filter(error => error !== action.payload),
                };
            case SHOW_MODAL:
                return {
                    ...state,
                    modalStack: [
                        ...state.modalStack,
                        {
                            type: action.payload.modalType,
                            props: action.payload.modalProps
                        }
                    ],
                };
            case HIDE_MODAL:
                const modalStack = state.modalStack.concat();
                modalStack.pop();

                return {
                    ...state,
                    modalStack
                };
            case RESET_UI_STATE:
                return {...INITIAL_STATE};
            default:
                if (action.meta?.storeUIState) {
                    return {
                        ...state,
                        pageState: {
                            ...state.pageState,
                            [action.meta.storeUIState.key]: action.meta.storeUIState.state,
                        },
                    };
                }
                return {...state};
        }
    }
};

export const selectUIState = (state: RootState, key): any | undefined => {
    return state.UI.pageState[key];
};

export const selectLoading = (state: RootState, action: { toString: () => string }, classifier?: string, defaultValue = false): boolean => {
    const loadingKey = classifier
        ? `${action}::${classifier}`
        : `${action}`;

    return state.UI.loading[loadingKey] !== undefined ? !!state.UI.loading[loadingKey] : defaultValue;
};
