import * as React from "react";
import GenericBadge from "../GenericBadge";
import {Skill} from "../../../redux/planning/types";
import Config from "../../../helpers/Config";

interface SkillBadgeProps {
    skill: Skill;
}

const SkillBadge: React.FC<SkillBadgeProps> = ({skill}) => {
    const config = {
        [Skill.INSTALLATIE_KRAAN]: {
            label: Config.labels[Skill.INSTALLATIE_KRAAN],
            color: 'primary',
        },
        [Skill.INSTALLATIE_KRAAN_MET_BOILER]: {
            label: Config.labels[Skill.INSTALLATIE_KRAAN_MET_BOILER],
            color: 'primary',
        },
        [Skill.INSTALLATIE_KRAAN_MET_KELDERUNIT]: {
            label: Config.labels[Skill.INSTALLATIE_KRAAN_MET_KELDERUNIT],
            color: 'primary',
        },
        [Skill.INSTALLATIE_KRAAN_MET_PYTHON]: {
            label: Config.labels[Skill.INSTALLATIE_KRAAN_MET_PYTHON],
            color: 'primary',
        },
        [Skill.INSTALLATIE_TOESTEL]: {
            label: Config.labels[Skill.INSTALLATIE_TOESTEL],
            color: 'primary',
        },
        [Skill.INSTALLATIE_CASA]: {
            label: Config.labels[Skill.INSTALLATIE_CASA],
            color: 'primary',
        },
        [Skill.INSTALLATIE_BOREN_DOOR_STEEN]: {
            label: Config.labels[Skill.INSTALLATIE_BOREN_DOOR_STEEN],
            color: 'primary',
        },
        [Skill.INTERVENTIE_COMPLEX]: {
            label: Config.labels[Skill.INTERVENTIE_COMPLEX],
            color: 'primary',
        },
        [Skill.INTERVENTIE_STANDAARD]: {
            label: Config.labels[Skill.INTERVENTIE_STANDAARD],
            color: 'primary',
        },
        [Skill.INTERVENTIE_EENVOUDIG]: {
            label: Config.labels[Skill.INTERVENTIE_EENVOUDIG],
            color: 'primary',
        },
        [Skill.ONDERHOUD]: {
            label: Config.labels[Skill.ONDERHOUD],
            color: 'primary',
        },
        [Skill.LEVERING_CO2_APART]: {
            label: Config.labels[Skill.LEVERING_CO2_APART],
            color: 'primary',
        },
        [Skill.LEVERING_CO2_GECOMBINEERD]: {
            label: Config.labels[Skill.LEVERING_CO2_GECOMBINEERD],
            color: 'primary',
        },
        [Skill.LEVERING_ACCESSOIRES]: {
            label: Config.labels[Skill.LEVERING_ACCESSOIRES],
            color: 'primary',
        },
        [Skill.LEVERING_TOESTEL]: {
            label: Config.labels[Skill.LEVERING_TOESTEL],
            color: 'primary',
        },
    };

    return <GenericBadge value={skill}
                         config={config}/>;
};

export default SkillBadge;
