import React, {CSSProperties, ReactNode} from "react";
import {FieldAttributes, FieldHelperProps, FieldInputProps} from "formik";
import Dropzone, {DropzoneOptions} from "react-dropzone";
import {Row} from "react-bootstrap";
import {FileCard} from "../FileCard";
import {FormikFormField} from "./FormikFormField";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface FormFileUploadFieldProps {
    name: string;
    disabled?: boolean;
    as?: keyof JSX.IntrinsicElements | React.ReactFragment;
    containerClassName?: string;
    containerStyle?: CSSProperties;

    max?: number;
    hideOnMax?: boolean;
    dropzoneOptions?: DropzoneOptions;
    dropzoneContainerStyle?: CSSProperties;
    dropzoneContainerClassName?: string;
    dropzoneMessageContent?: ReactNode;
}

export const FormFileUploadField: React.FC<FormFileUploadFieldProps & FieldAttributes<any>> = (props) => {
    const {
        hideOnMax,
        dropzoneOptions,
        max,
        as: As = "div",
        containerStyle,
        containerClassName,
        dropzoneContainerStyle,
        dropzoneContainerClassName,
        dropzoneMessageContent
    } = props;

    const {t} = useTranslation("algemeen");

    const onDrop = (field: FieldInputProps<any>, helpers: FieldHelperProps<any>, bestanden: File[]) => {
        if (max === undefined || field.value === undefined || field.value?.length < max) {
            helpers.setValue([...(field.value || []), ...bestanden]);
        }
    };

    const onFileRemove = (field: FieldInputProps<any>, helpers: FieldHelperProps<any>, index: number) => {
        // Gebruik index want bestandsnaam is niet bepaald uniek
        helpers.setValue((field.value || []).filter((item, i) => i !== index));
    };

    return (
        <FormikFormField {...props}>
            {(field, meta, helper) => {
                const disabled = props.disabled || (max !== undefined && field.value && field.value?.length >= max);

                return (
                    // @ts-ignore
                    <As style={containerStyle} className={containerClassName}>
                        {field.value && Array.isArray(field.value) && (
                            <Row>
                                {field.value.map((bestand: any, i) => (
                                    // <FileIcon filename={bestand.name} key={i} style={{ width: "8em", padding: "1em" }} />

                                    <FileCard className="m-1" filename={bestand.name} fileSize={bestand.size} key={i} downloadUri={bestand.downloadUri}
                                              onRemove={() => onFileRemove(field, helper, i)}/>
                                ))}
                            </Row>
                        )}

                        {(disabled && hideOnMax) ? null : (
                            <Dropzone {...dropzoneOptions} onDrop={(bestanden) => onDrop(field, helper, bestanden)}
                                      disabled={disabled}>
                                {({getRootProps, getInputProps}) => (
                                    <div
                                        className={`dropzone ${disabled ? "dropzone--disabled" : ""} ${dropzoneContainerClassName || ""}`}
                                        style={dropzoneContainerStyle}>
                                        <div
                                            className="dz-message needsclick" {...getRootProps()}>
                                            <input {...getInputProps()} disabled={disabled}/>
                                            {!dropzoneMessageContent ? (
                                                <>
                                                    <i className="h1 text-muted dripicons-cloud-upload"></i>
                                                    <h4>{t("FileUpload.sleep-hier-het-bestand-of-klik-om-te-uploaden", "Sleep hier het bestand of klik om te uploaden")}</h4>
                                                </>
                                            ) : dropzoneMessageContent}
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        )}
                    </As>
                );
            }}
        </FormikFormField>
    );
};
