import {default as React} from "react";
import CreateKlantModal, {NieuweKlantModalFormValues} from "./CreateKlantModal";
import {useHideModal} from "../../../redux/ui/hooks";
import {CreateKlantForm, KlantModel} from "../../../redux/klant/types";
import {useNieuweKlant} from "../../../redux/klant/api";

export interface NieuweKlantModalContainerProps {
    initialValues?: NieuweKlantModalFormValues;

    onSuccessCallback?(klant: KlantModel): void;
}

export const CreateKlantModalContainer: React.FC<NieuweKlantModalContainerProps> = (props) => {
    const {initialValues, onSuccessCallback} = props;

    const hideModal = useHideModal();
    const [nieuweKlant, {isLoading: loading}] = useNieuweKlant();

    const onSubmit = (form: CreateKlantForm) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const klant = await nieuweKlant(form).unwrap();

                resolve();

                hideModal();
                if (onSuccessCallback) {
                    onSuccessCallback(klant);
                }
            } catch (e) {
                reject(e);
            }
        });
    };

    const onCancel = () => {
        hideModal();
    };

    return (
        <CreateKlantModal {...props} initialValues={initialValues} loading={loading} onSubmit={onSubmit} onCancel={onCancel}/>
    );
};

