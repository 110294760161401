import * as React from "react";
import {useTranslation} from "../../helpers/i18nUtils";

interface VerwijderdIndicatorProps {
    verwijderd: boolean;
}

const VerwijderdIndicator: React.FC<VerwijderdIndicatorProps> = ({verwijderd,}) => {
    const {t} = useTranslation("algemeen");

    if (!verwijderd) {
        return null;
    }

    return (
        <span className="text-danger"><i className="mdi mdi-cancel"/>&nbsp;{t("Labels.verwijderd", "Verwijderd")}</span>
    );
};

export default VerwijderdIndicator;
