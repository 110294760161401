import * as React from "react";
import {Card, Col, Row} from "react-bootstrap";
import ArtikelNr from "../connected/ArtikelNr";
import {ArtikelModel} from "../../../redux/artikel/types";
import {useModal} from "../../../redux/ui/hooks";
import {BackOfficeModals} from "../ModalRoot";
import {NieuweCo2BestellingForm} from "../../../redux/co2bestelling/types";
import {NieuweCo2BestellingModalProps} from "../../../pages/co2bestelling/overzicht/NieuweCo2BestellingModal";
import {KlantModel} from "../../../redux/klant/types";
import {VerzendAdresModel} from "../../../redux/verzendadres/types";
import {useNieuweCo2Bestelling} from "../../../redux/co2bestelling/api";

interface UnclickableCo2ArtikelBoxProps {
    artikel?: ArtikelModel;

    size?: "sm" | "md";
}

const UnclickableCo2ArtikelBox: React.FC<UnclickableCo2ArtikelBoxProps> = (props) => {
    const {artikel, size = "md"} = props;

    let sizeCss = "3rem";
    let descriptionFontSizeClass = "";

    if (size === "sm") {
        sizeCss = "1.5rem";
        descriptionFontSizeClass = "small";
    }

    return (
        <Card className="mb-0 shadow-none">
            <div>
                <Row className="align-items-center">
                    <Col className="col-auto">
                        <div style={{width: sizeCss, height: sizeCss}}>
                             <span className="avatar-title bg-primary-lighten text-primary rounded p-1">
                                {/*<i className={`mdi mdi-molecule-co2 ${iconFontSizeClass}`}/>*/}
                                 <img src={artikel?.imageUri} alt={artikel?.nr} style={{
                                     height: "100%"
                                 }}/>
                            </span>
                        </div>
                    </Col>
                    <Col className={`pl-0 ${descriptionFontSizeClass}`}>
                        <strong>
                            <ArtikelNr artikelId={artikel?.id} artikel={artikel}/>
                        </strong> {artikel?.omschrijving}
                    </Col>
                </Row>
            </div>
        </Card>
    );
};


export interface Co2ArtikelBoxProps {
    artikel?: ArtikelModel;
    clickable: boolean;

    size?: "sm" | "md";

    klantVoorBestelling?: KlantModel;
    verzendAdresVoorBestelling?: VerzendAdresModel;
}

export const Co2ArtikelBox: React.FC<Co2ArtikelBoxProps> = (props) => {
    const {artikel, clickable, size, klantVoorBestelling, verzendAdresVoorBestelling} = props;

    const {showModal} = useModal();
    const [nieuweCo2Bestelling] = useNieuweCo2Bestelling();

    const toonMaakCo2BestellingModal = () => {
        showModal(BackOfficeModals.NIEUWE_CO_BESTELLING_MODAL, {
            defaultValues: {
                besteldArtikel: artikel,
                klant: klantVoorBestelling,
                verzendAdres: verzendAdresVoorBestelling
            },
            onBevestigHandler: (form: NieuweCo2BestellingForm) => {
                nieuweCo2Bestelling(form);
            }
        } as NieuweCo2BestellingModalProps);
    };

    if (clickable) {
        // eslint-disable-next-line
        return <a href="#" onClick={(e) => {
            e.preventDefault();
            toonMaakCo2BestellingModal();
        }}>
            <UnclickableCo2ArtikelBox artikel={artikel} size={size}/>
        </a>;
    } else {
        return <UnclickableCo2ArtikelBox artikel={artikel} size={size}/>
    }
};
