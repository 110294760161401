import React, {useMemo} from "react";
import {Button} from "react-bootstrap";
import {useHideModal} from "../../../redux/ui/hooks";
import {InterventieModel, InterventiePrioriteit} from "../../../redux/interventie/types";
import {Form, Formik} from "formik";
import {DatumFormField} from "../../../components/aqualex/form/DatumFormField";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import moment from "moment";
import Config from "../../../helpers/Config";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface PlanInterventieModalProps {
    interventie: InterventieModel;
    onBevestigHandler: (bezoekAanvraagId?: string) => void;
}

export interface PlanInterventieFormValues {
    deadline: Date;
}

export const PlanInterventieModal: React.FC<PlanInterventieModalProps> = (props) => {
    const {onBevestigHandler} = props;

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const handleBevestig = (values) => {
        hideModal();
        onBevestigHandler(values.deadline.toISOString());
    };

    const initialValues = useMemo(() => {
        const aantalDagen = Config.INTERVENTIE_DEADLINE_AANTAL_DAGEN_PER_PRIORITEIT[props.interventie.prioriteit];
        let deadlineMoment = moment().add(aantalDagen, "days");

        if (props.interventie.prioriteit === InterventiePrioriteit.SLA && props.interventie.klant.maximaleInterventieResponsTijd > 0) {
            deadlineMoment = moment().add(props.interventie.klant.maximaleInterventieResponsTijd, "hours");
        }

        return {
            deadline: deadlineMoment.toDate()
        } as PlanInterventieFormValues;
    }, [props.interventie]);

    const {t} = useTranslation("interventie");

    return (
        <Modal show onHide={onAnnuleer} autoFocus size="lg">
            <Formik<PlanInterventieFormValues> initialValues={initialValues} onSubmit={handleBevestig}>
                {
                    (formikProps) => {
                        return (
                            <Form>
                                <ModalHeader closeButton={true}>
                                    <ModalTitle>{t("Titels.plan-interventie", "Plan interventie")}</ModalTitle>
                                </ModalHeader>
                                <ModalBody>
                                    <p>{t("PlanInterventieModal.geef-de-deadline-in",
                                        "Geef de deadline voor deze interventie in.")}</p>

                                    <DatumFormField name="deadline"/>
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="light" onClick={onAnnuleer}>{
                                        t("algemeen:Buttons.annuleer", "Annuleer")}
                                    </Button>
                                    <LoadingButton variant="primary" type="submit" disabled={formikProps.isSubmitting}
                                                   loading={formikProps.isSubmitting}>
                                        {t("algemeen:Buttons.bevestig", "Bevestig")}
                                    </LoadingButton>
                                </ModalFooter>
                            </Form>
                        );
                    }
                }
            </Formik>
        </Modal>
    );
};
