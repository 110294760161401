import {default as React, useMemo} from "react";
import {Modal, ModalHeader} from "../../../components/aqualex/Modal";
import {Formik} from "formik";
import * as Yup from "yup";
import {NieuwContractModalForm} from "./NieuwContractModalForm";
import {useTranslation} from "react-i18next";

export interface NieuwContractFormValues {
    omschrijving?: string;
    contractVoorstel?: File[];
    getekend?: boolean;
}

const NieuwContractModal = ({
                                onBevestigHandler,
                                onAnnuleerHandler,
                            }) => {

    const {t} = useTranslation("bestelbon");

    const contractIsVerplichtLabel = t("", "Contract is verplicht")

    const validationSchema = useMemo(() => Yup.object({
        contractVoorstel: Yup.array(Yup.object().nullable()).required(contractIsVerplichtLabel),
        getekend: Yup.boolean().nullable()
    }), [contractIsVerplichtLabel]);

    const onSubmit = (values: NieuwContractFormValues) => {
        const file = values.contractVoorstel?.[0];

        onBevestigHandler({
            contractVoorstel: file,
            omschrijving: file?.name,
            getekend: values.getekend || false
        });
    }

    return (
        <Modal show onHide={onAnnuleerHandler}>
            <ModalHeader>{t("Titels.contract-toevoegen", "Contract Toevoegen")}</ModalHeader>
            <Formik initialValues={{}} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize
                    validateOnChange={false} validateOnMount={false} validateOnBlur={false}>
                {() => {
                    return <NieuwContractModalForm onAnnuleerHandler={onAnnuleerHandler}/>;
                }}
            </Formik>
        </Modal>
    );
};

export default NieuwContractModal;
