import React, {useMemo} from "react";
import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Row, Tab, Tabs} from "react-bootstrap";
import {Formik} from "formik";
import {MedewerkerModel} from "../../../../redux/medewerker/types";
import {parseTime} from "../../../../helpers/DateUtils";
import {MedewerkerLijstFormField} from "../../../../components/aqualex/form/MedewerkerLijstFormField";
import {TijdstipFormField} from "../../../../components/aqualex/form/TijdstipFormField";
import {useFetchDepots} from "../../../../redux/refdata";
import {DepotModel} from "../../../../redux/refdata/types";
import {BetterSelectFormField} from "../../../../components/aqualex/form/BetterSelectFormField";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/aqualex/Modal";
import {DatumFormField} from "../../../../components/aqualex/form/DatumFormField";
import {useTranslation} from "../../../../helpers/i18nUtils";

export interface NieuweDagPlanningenModalProps {
    onBevestigHandler: (form: NieuweDagPlanningenFormValues) => void;
    onAnnuleerHandler: () => void;
    defaultValues?: NieuweDagPlanningenFormValues;
}

export interface NieuweDagPlanningenFormValues {
    medewerkers?: MedewerkerModel[] | null;
    startUur?: string | null;
    eindUur?: string | null;
    datum?: Date | null;
    periode?: Date[];
    depot?: DepotModel | null;

    type?: "individuele-dag" | "periode";
}

export const NieuweDagPlanningenModal: React.FC<NieuweDagPlanningenModalProps> = (props) => {
    const {onBevestigHandler, onAnnuleerHandler, defaultValues} = props;

    const {t} = useTranslation("planning");

    const {items: depots} = useFetchDepots({filters: {ignoreMe: "ignore me"}});

    const onBevestig = async (values: NieuweDagPlanningenFormValues) => {
        onBevestigHandler(values);
    }

    const schema = Yup.object<NieuweDagPlanningenFormValues>({
        type: Yup.string(),
        medewerkers: Yup.array<MedewerkerModel>().min(1, t("Foutmeldingen.minstens-1-medewerker-nodig", "Minstens 1 medewerker nodig") as string).required(t("Foutmeldingen.minstens-1-medewerker-nodig", "Minstens 1 medewerker nodig") as string),
        startUur: Yup.string().nullable(),
        eindUur: Yup.string().nullable()
            .test(
                "van_voor_tot",
                t("van-tijdstip-moet-kleiner-dan-of-gelijk-aan-tot-tijdstip-zijn", "Van tijdstip moet <= tot tijdstip zijn") as string,
                function (einduur) {
                    // @ts-ignore
                    const {parent} = this;
                    const van = parseTime(parent.startUur);
                    const tot = parseTime(einduur!);
                    return van! <= tot!;
                }),
        depot: Yup.object<DepotModel>().nullable()
            .when("type", {
                is: "periode",
                then: Yup.object<DepotModel>(),
                otherwise: Yup.object<DepotModel>().required(t("Foutmeldingen.depot-is-verplicht", "Depot is verplicht") as string)
            })
    });

    const initialValues: NieuweDagPlanningenFormValues = useMemo(() => {
        return {
            ...defaultValues,
            startUur: "07:30:00",
            eindUur: "15:30:00"
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues]);

    return (
        <Modal show={true} onHide={onAnnuleerHandler} size="lg" autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Labels.dagplanningen-toevoegen", "Dagplanning(en) toevoegen")}</ModalTitle>
            </ModalHeader>

            <Formik validationSchema={schema}
                    onSubmit={onBevestig}
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={initialValues}>
                {({
                      handleSubmit,
                      isValid,
                      setFieldValue,
                      values
                  }) => (
                    <Form noValidate>
                        <ModalBody>
                            <Tabs variant="pills" id="dagplanning-tabs" className="nav-justified nav-bordered mb-3"
                                  onSelect={(key) => setFieldValue("type", key)}
                                  activeKey={values.type}>
                                <Tab eventKey="individuele-dag"
                                     title={t("Labels.individuele-dag", "Individuele dag")}
                                     tabClassName="rounded-0 pt-2 pb-2">
                                    <p>
                                        {t("NieuweDagPlanningenModal.deze-functie-maakt-dagplanningen-aan", "Deze functie maakt dagplanningen aan voor de geselecteerde techniekers en met een specifieke start- en eindtijd en depot.")}
                                    </p>

                                    <Row>
                                        <Col xs="12">
                                            <h5>{t("Labels.technici", "Technici")}</h5>
                                            <MedewerkerLijstFormField name="medewerkers" filters={{technieker: true}}/>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12} md={3}>
                                            <h5>{t("algemeen:Labels.datum", "Datum")}</h5>
                                            <FormGroup id="datum">
                                                <DatumFormField name="datum"/>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6} md={3}>
                                            <h5>{t("Labels.startuur", "Startuur")}</h5>
                                            <FormGroup id="startUur">
                                                <TijdstipFormField name="startUur"/>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6} md={3}>
                                            <h5>{t("Labels.einduur", "Einduur")}</h5>
                                            <FormGroup id="eindUur">
                                                <TijdstipFormField name="eindUur"/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <h5>{t("algemeen:Labels.depot", "Depot")}</h5>
                                            <BetterSelectFormField name="depot"
                                                                   items={depots}
                                                                   keyGetter={depot => depot.code}
                                                                   formatter={depot => depot.code}/>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="periode"
                                     title={t("Labels.periode", "Periode")} tabClassName="rounded-0 pt-2 pb-2">
                                    <p>
                                        {t("NieuweDagPlanningenModal.deze-functie-maakt-dagplanningen-aan-volgens-weeksjabloon", "Deze functie maakt dagplanningen aan volgens de technieker zijn/haar weeksjabloon en dit voor een bepaalde periode.")}
                                    </p>

                                    <Row>
                                        <Col xs="12">
                                            <h5>{t("Labels.technici", "Technici")}</h5>
                                            <MedewerkerLijstFormField name="medewerkers" filters={{technieker: true}}/>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs="6">
                                            <h5>{t("Labels.periode", "Periode")}</h5>
                                            <DatumFormField name="periode" datumSelectorProps={{selectsRange: true}}/>
                                        </Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </ModalBody>

                        <ModalFooter>
                            <Button variant="light" onClick={() => onAnnuleerHandler()}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}
                            <Button variant="primary" disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>)}
            </Formik>

        </Modal>
    );
};
