import {default as React} from "react";
import {FormikFormFieldProps} from "./FormikFormField";
import {useFormikContext} from "formik";
import {ArtikelModel} from "../../../redux/artikel/types";
import {MedewerkerFormField} from "./MedewerkerFormField";
import {MedewerkerModel} from "../../../redux/medewerker/types";

export interface MedewerkerMetInputFormFieldProps {
    renderItem?: (medewerker: ArtikelModel, i: number) => React.ReactNode;
    useId?: boolean;
    filter?: (medewerker: MedewerkerModel) => boolean;
}

export const MedewerkerMetInputFormField: React.FC<FormikFormFieldProps & MedewerkerMetInputFormFieldProps> = (props) => {
    const {renderItem, useId, filter} = props;

    const {values} = useFormikContext<any>();

    const value = values?.[props.name];

    return (
        <>
            <MedewerkerFormField isMulti isClearable={false} clearAfterSelect useId={useId} filter={filter} {...props}/>

            {value?.map((medewerker, i) => renderItem(medewerker, i))}
        </>
    );
};
