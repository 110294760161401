import {default as React, useMemo} from "react";
import {Button, Form} from "react-bootstrap";
import {DagPlanningEntryType, DagPlanningModel} from "../../../../redux/planning/types";
import {FieldAttributes, Formik, useField} from "formik";
import * as Yup from 'yup';
import BezoekAanvraagOverzichtTable, {
    BezoekAanvraagGecommuniceerdeDatumColumn,
    BezoekAanvraagGecommuniceerdeDatumOpFilter,
    BezoekAanvraagGewensteDatumColumn,
    BezoekAanvraagKlantColumn,
    BezoekAanvraagNrColumn,
    BezoekAanvraagPostcodeFilter,
    BezoekAanvraagPrestatieSamenvattingColumn,
    BezoekAanvraagTotDatumFilter,
    BezoekAanvraagVanDatumFilter
} from "../../../../components/aqualex/bezoekaanvraag/BezoekAanvraagOverzichtTable";
import {SortOrder} from "../../../../redux/support/pagination";
import {BackOfficeModals} from "../../../../components/aqualex/ModalRoot";
import {useModal} from "../../../../redux/ui/hooks";
import {MedewerkerModel} from "../../../../redux/medewerker/types";
import {PlanBezoekAanvraagModalProps} from "../../bezoekaanvraag/detail/plan/PlanBezoekAanvraagModal";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/aqualex/Modal";
import {
    BezoekAanvraagFilters,
    BezoekAanvraagOverzichtModel,
    BezoekAanvraagStatus
} from "../../../../redux/bezoekaanvraag/types";
import {useFilteredAndPagedSearch} from "../../../../redux/support/hooks";
import {useSearchBezoekAanvragen} from "../../../../redux/bezoekaanvraag/api";
import {KlantNrFilter} from "../../../../components/aqualex/filter/KlantNrFilter";
import {KlantNaamFilter} from "../../../../components/aqualex/filter/KlantNaamFilter";
import {useTranslation} from "../../../../helpers/i18nUtils";
import {FiltersAndPageable} from "../../../../redux/support/uiSlice";

export interface DagPlanningVoegBezoekToeModalProps {
    dagPlanning: DagPlanningModel;
    medewerker: MedewerkerModel;
    onBevestigHandler: (bezoekAanvraagId: string, index: number, tePlannenDuurtijd) => void;
}

interface FormValues {
    bezoekAanvraagId?: string;
    bezoekNummer?: number;
}

export const DagPlanningVoegBezoekToeModal: React.FC<DagPlanningVoegBezoekToeModalProps> = ({
                                                                                                dagPlanning,
                                                                                                medewerker,
                                                                                                onBevestigHandler,
                                                                                            }) => {
    const {t} = useTranslation("planning");

    const {showModal, hideModal} = useModal();

    const onAnnuleer = () => {
        hideModal();
    }

    const onBevestig = (bezoekAanvraagId: string) => {
        hideModal();

        showModal(BackOfficeModals.PLAN_BEZOEK_AANVRAAG_MODAL, {
            bezoekAanvraagId,
            technieker: medewerker,
            datum: new Date(dagPlanning.datum),
            onBevestigHandler: (dagPlanningId, bezoekNummer, tePlannenDuurtijd) => {
                onBevestigHandler(bezoekAanvraagId, bezoekNummer, tePlannenDuurtijd);
            },
        } as PlanBezoekAanvraagModalProps);
    }

    const maxBezoekNummer = dagPlanning.entries.filter(entry => entry.type === DagPlanningEntryType.BEZOEK).length + 1;

    return (
        <Modal show={true} onHide={onAnnuleer} size='xl' autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Labels.bezoekaanvraag-toevoegen", "Bezoekaanvraag toevoegen")}</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={useMemo(() => {
                    return {
                        bezoekAanvraagId: undefined,
                        bezoekNummer: maxBezoekNummer
                    } as FormValues
                }, [maxBezoekNummer])}
                validateOnMount // to make validateOnMount, initialValues must be created using useMemo
                onSubmit={(values: FormValues) => onBevestig(values.bezoekAanvraagId!)}
                validationSchema={Yup.object({
                    bezoekAanvraagId: Yup.string()
                        .nullable()
                        .required(t("Foutmeldingen.bezoekaanvraag-is-verplicht", "Bezoekaanvraag is verplicht") as string),
                    bezoekNummer: Yup.number()
                        .required(t("Foutmeldingen.bezoeknummer-is-verplicht", "Bezoeknummer is verplicht") as string)
                        .min(1, t("Foutmeldingen.bezoek-moet-groter-dan-of-gelijk-aan-1-zijn", "Bezoeknummer moet >= 1 zijn") as string)
                        .max(maxBezoekNummer, t("Foutmeldingen.bezoek-moet-groter-dan-of-gelijk-aan-nummer-zijn", "Bezoeknummer moet <= {{nummer}} zijn", {nummer: maxBezoekNummer}) as string),
                })}
            >
                {({
                      handleSubmit,
                      isValid,
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <h5>{t("Labels.bezoekaanvraag", "Bezoekaanvraag")}</h5>
                            <BezoekAanvraagFormField name='bezoekAanvraagId'/>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='light' onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                            <Button variant='primary' disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};


const BezoekAanvraagFormField: React.FC<FieldAttributes<any>> = (props) => {
    const [, meta, helpers] = useField(props);

    const initialFiltersAndPageable: FiltersAndPageable<BezoekAanvraagFilters> = {
        filters: {
            statusIn: [BezoekAanvraagStatus.NIEUW, BezoekAanvraagStatus.TE_PLANNEN]
        },
        pageable: {
            pageNumber: 1,
            pageSize: 10,
            sortField: 'klantNr',
            sortOrder: SortOrder.asc,
        },
        activeQuickFilters: {}
    };

    const {
        page,
        filtersAndPageable,
        loadData
    } = useFilteredAndPagedSearch<BezoekAanvraagOverzichtModel, BezoekAanvraagFilters>("bezoekaanvraag.select", useSearchBezoekAanvragen, initialFiltersAndPageable);

    return (
        <React.Fragment>
            <BezoekAanvraagOverzichtTable
                page={page}
                pageable={filtersAndPageable.pageable}
                filters={filtersAndPageable.filters}
                loadData={loadData}

                quickFilters={[]}
                primaryFilterFields={[
                    KlantNrFilter,
                    KlantNaamFilter,
                    BezoekAanvraagPostcodeFilter,
                    BezoekAanvraagVanDatumFilter,
                    BezoekAanvraagTotDatumFilter,
                    BezoekAanvraagGecommuniceerdeDatumOpFilter,
                ]}
                columns={[
                    BezoekAanvraagNrColumn,
                    BezoekAanvraagKlantColumn,
                    BezoekAanvraagPrestatieSamenvattingColumn,
                    BezoekAanvraagGewensteDatumColumn,
                    BezoekAanvraagGecommuniceerdeDatumColumn,
                ]}

                selectionMode='single'
                onRowSelected={bezoekAanvraag => {
                    helpers.setTouched(true, true);
                    helpers.setValue(bezoekAanvraag?.id);
                }}
            />

            <Form.Control.Feedback type='invalid'
                                   style={{display: 'block'}}>{meta.error}</Form.Control.Feedback>
        </React.Fragment>
    );
};

