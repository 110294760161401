import React from "react";
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import {useHideModal} from "../../../redux/ui/hooks";
import {FormikHelpers} from "formik/dist/types";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface AnnuleerInterventieFormValues {
    reden: string;
}

export interface AnnuleerInterventieModalProps {
    onBevestigHandler: (form: AnnuleerInterventieFormValues) => Promise<void>
}

export const AnnuleerInterventieModal: React.FC<AnnuleerInterventieModalProps> = (props) => {
    const {onBevestigHandler} = props;

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const onBevestig = async (values: AnnuleerInterventieFormValues, helpers: FormikHelpers<AnnuleerInterventieFormValues>) => {
        try {
            helpers.setSubmitting(true);

            await onBevestigHandler(values);
            hideModal();
        } catch (error: any) {

        } finally {
            helpers.setSubmitting(false);
        }
    };

    const initialValues: AnnuleerInterventieFormValues = {
        reden: ""
    };

    const {t} = useTranslation("interventie");

    return (
        <Modal show onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.interventie-annuleren", "Interventie annuleren")}</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                validateOnMount // to make validateOnMount, initialValues must be created using useMemo
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    reden: Yup.string().required(
                        t("Foutmeldingen.reden-is-verplicht", "Reden is verplicht") as string)
                })}
            >
                {({
                      handleSubmit,
                      isValid,
                      values
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <h5>{t("Labels.reden", "Reden")}</h5>
                            <Form.Row>
                                <FormGroup id="reden" as={Col}>
                                    <TextAreaFormField name="reden"/>
                                </FormGroup>
                            </Form.Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="light" onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}
                            <Button variant="primary" disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};

