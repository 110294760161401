import React, {CSSProperties} from "react";
import {Spinner} from "react-bootstrap";

export interface SpinProps {
    spinning?: boolean;
    className?: string;
    style?: CSSProperties;
}

export const Spin: React.FC<SpinProps> = (props) => {
    return (
        <div className={props.className} style={{...props.style, position: "relative"}}>
            <div className="h-100" style={{filter: props.spinning ? "blur(1px)" : undefined}}>
                {props.children}
            </div>

            {props.spinning && (
                <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}>
                    <Spinner animation="border" className="text-secondary"/>
                </div>
            )}
        </div>
    );
};
