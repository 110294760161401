import React, {CSSProperties, LegacyRef} from "react";
import {Button, Card, Collapse} from "react-bootstrap";
import KlantLabel from "../../../../../components/aqualex/KlantLabel";
import GeplandBezoekOpdrachtTypeBadges
    from "../../../../../components/aqualex/bezoekaanvraag/GeplandBezoekOpdrachtTypeBadges";
import {DagPlanningEntryModel, DagPlanningModel} from "../../../../../redux/planning/types";
import {AdresModel} from "../../../../../redux/verzendadres/types";
import {DraggableProvidedDragHandleProps} from "react-beautiful-dnd";
import Tijdstip from "../../../../../components/aqualex/Tijdstip";
import {Spin} from "../../../../../components/Spin";
import moment from "moment";
import {Link} from "react-router-dom";
import GecommuniceerdIcon from "../../../../../components/aqualex/bezoekaanvraag/GecommuniceerdIcon";
import {TruncateText} from "../../../../../components/aqualex/TruncateText";
import {PlanningBezoekInfoPanelCard} from "./PlanningBezoekInfoPanelCard";
import {
    BezoekDuurtijdAanpassenDropDownContainer
} from "../../../../../components/aqualex/planning/BezoekDuurtijdAanpassenDropDownContainer";
import {durationToTime} from "../../../../../helpers/DateUtils";
import {useTranslation} from "../../../../../helpers/i18nUtils";

export interface PlanningEntryCardProps {
    dagPlanning: DagPlanningModel;
    entry: DagPlanningEntryModel;

    onSelect?: () => void;
    selected?: boolean;
    loading?: boolean;

    actionsDisabled?: boolean;

    showLink?: boolean;
    verwijderBezoek?: (geplandBezoekId: string) => void;

    draggable?: boolean;
    handleProps?: DraggableProvidedDragHandleProps;

    style?: CSSProperties;
    innerRef: LegacyRef<any>;
}

const LocatieFormatter: React.FC<{ adres: AdresModel }> = ({adres}) => {
    if (!adres) {
        return null;
    }

    return <React.Fragment>
        {adres.adres}, {adres.adres2 && <span>{adres.adres2}, </span>}{adres.postcode} {adres.plaats}
    </React.Fragment>
};

export const PlanningBezoekCard: React.FC<PlanningEntryCardProps> = (props) => {
    const {
        dagPlanning,
        entry,
        onSelect,
        selected,
        loading,
        actionsDisabled,
        showLink = true,
        verwijderBezoek,
        innerRef,
        draggable = true,
        handleProps,
        style,
        ...rest
    } = props;

    const {language} = useTranslation("planning");

    const onVerwijderBezoek = (geplandBezoekId: string) => {
        // TODO: AD-1200 - loading state instellen op enkel deze card (lokale state + success callback)
        verwijderBezoek?.(geplandBezoekId);
    };

    const minuten = moment(entry.tot, "HH:mm").diff(moment(entry.van, "HH:mm"), "minutes")

    const bezoekAanvraagInstructie = entry.bezoek?.bezoekAanvraagInstructie;
    const verzendAdresInstuctie = entry.bezoek?.verzendAdresInstuctie;

    return (
        <div className="timeline-item" {...rest} style={style} ref={innerRef}>
            <span className="icon d-flex align-items-center justify-content-center">
                <strong>{entry.bezoek?.bezoekNummer}</strong>
            </span>

            <Spin spinning={loading}>
                <Card
                    className={`dagplanning-bezoek-card m-0 shadow-sm ${selected ? "bg-primary-lighten" : ""} ${entry.bezoek?.isVoorstel ? "dagplanning-bezoek-card--voorstel" : ""}`}
                    onClick={() => onSelect?.()}>
                    <div className="d-flex">
                        <div className="d-flex flex-column align-items-center justify-content-between px-2">
                            <div className="font-weight-semibold mt-1">
                                <Tijdstip tijd={entry?.van}/>
                            </div>

                            <div className="mb-1">
                                <Tijdstip tijd={entry?.tot}/>
                            </div>
                        </div>

                        <div className="flex-fill text-nowrap text-truncate">
                            <Card.Body className="p-1">
                                <>
                                    {entry.bezoek && (
                                        <span>
                                            <KlantLabel klant={{...entry.bezoek?.klant, verwijderd: false}}/>{" "}
                                            <span className="text-muted">{entry.bezoek.verzendAdres.code}</span><br/>
                                            <LocatieFormatter adres={entry.bezoek.verzendAdres.adres}/><br/>
                                        </span>
                                    )}
                                    {entry.bezoek &&
                                        <>
                                            {!entry.bezoek.isVoorstel && (
                                                <span className={"mr-1"}><GecommuniceerdIcon
                                                    geplandeDatum={dagPlanning.datum}
                                                    gecommuniceerdeDatums={entry.bezoek.gecommuniceerdeDatums}
                                                    gecommuniceerdOpTijdstip={entry.bezoek.gecommuniceerdOpTijdstip}/></span>
                                            )}

                                            <GeplandBezoekOpdrachtTypeBadges
                                                opdrachtTypes={entry.bezoek.opdrachtTypes}/>
                                            <small className="text-muted ">{Intl.NumberFormat(language, {style: "unit", unit: "minute"}).format(minuten)}</small>
                                        </>
                                    }

                                    {!selected && (
                                        <div>
                                            {bezoekAanvraagInstructie && (
                                                <small className="ms-2 text-muted">
                                                    <TruncateText text={bezoekAanvraagInstructie} max={40}/>
                                                    {" "}
                                                </small>
                                            )}

                                            {verzendAdresInstuctie && (
                                                <small className="text-muted">
                                                    <TruncateText text={verzendAdresInstuctie} max={40}/>
                                                </small>
                                            )}
                                        </div>
                                    )}
                                </>
                            </Card.Body>
                        </div>

                        <div className="planning-bezoek-actions">
                            {!actionsDisabled && (
                                <div className="d-flex align-items-center justify-content-center mr-3">
                                    <div onClick={e => e.stopPropagation()} className="btn pr-0 pb-2">
                                        <BezoekDuurtijdAanpassenDropDownContainer geplandBezoekId={entry.geplandBezoekId!}
                                                                                  datum={dagPlanning.datum}
                                                                                  medewerkerId={dagPlanning.medewerker.id}
                                                                                  defaultValue={durationToTime(minuten, "minutes")}/>
                                    </div>

                                    {showLink && (
                                        <div>
                                            <Link to={`/planning/bezoek/aanvraag/${entry.bezoek?.bezoekAanvraagId}`}>
                                                <Button variant="link" className="text-secondary"
                                                        size="sm">
                                                    <i className="font-20 mdi mdi-open-in-new"/>
                                                </Button>
                                            </Link>
                                        </div>
                                    )}

                                    {verwijderBezoek && (
                                        <div>
                                            <Button variant="link" className="text-danger"
                                                    size="sm"
                                                    onClick={(evt) => {
                                                        evt.stopPropagation();
                                                        onVerwijderBezoek(entry.geplandBezoekId!);
                                                    }}>
                                                <i className="font-20 mdi mdi-trash-can-outline"/>
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}

                            {draggable && (
                                <div
                                    className="d-flex justify-content-center align-items-center px-2 planning-entry-drag-handle" {...handleProps}>
                                    <i className="mdi mdi-drag-vertical font-24"/>
                                </div>
                            )}
                        </div>
                    </div>
                </Card>

                <Collapse in={selected} mountOnEnter>
                    <div>
                        <PlanningBezoekInfoPanelCard dagPlanning={dagPlanning} entry={entry}/>
                    </div>
                </Collapse>
            </Spin>
        </div>
    );
};
