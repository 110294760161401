import * as React from "react";
import {ArtikelModel} from "../../redux/toestel/types";

interface ArtikelLabelProps {
    artikel: ArtikelModel;
}

const ArtikelLabel: React.FC<ArtikelLabelProps> = ({artikel}) => (
    <><strong>{artikel.nr}</strong> {artikel.omschrijving}</>
);

export default ArtikelLabel;
