import Config from "./Config";
import {GET} from './api';
import {BezoekenPerMedewerkerPerDagProjectionModel, GeplandBezoekModel} from "../redux/planning/types";
import {formatDateIso} from "./DateUtils";

const BACKOFFICE_GEPLAND_BEZOEK_API_BASE_URL = Config.BACKOFFICE_BASE_URL + '/api/bezoek/gepland';

export default class GeplandBezoekApi {

    static fetchBezoekenPerMedewerkerIdPerDatumProjection(van: Date, tot: Date): Promise<Array<BezoekenPerMedewerkerPerDagProjectionModel>> {

        const params = new URLSearchParams();

        params.append('van', formatDateIso(van));
        params.append('tot', formatDateIso(tot));

        const uri = BACKOFFICE_GEPLAND_BEZOEK_API_BASE_URL + `/perMedewerkerPerDag?${params.toString()}`;

        return GET(uri, 'zoeken van geplande bezoeken per medewerker per dag');
    }

    static fetchGeplandBezoekById(geplandBezoekId: string): Promise<GeplandBezoekModel> {
        const uri = BACKOFFICE_GEPLAND_BEZOEK_API_BASE_URL + `/${geplandBezoekId}`;

        return GET(uri, 'ophalen van gepland bezoek');
    }

}
