import {default as React} from "react";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";
import ArtikelSelector from "../ArtikelSelector";

export interface ArtikelFormFieldProps {
    bedrijfId?: string;
    isMulti?: boolean;
}

export const ArtikelFormField: React.FC<FormikFormFieldProps & ArtikelFormFieldProps> = (props) => {
    const {bedrijfId, isMulti} = props;

    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <ArtikelSelector {...field}
                                 bedrijfId={bedrijfId}
                                 artikelChanged={async artikel => {
                                     await helpers.setValue(artikel);

                                     props.onChange && props.onChange(artikel);
                                 }}
                                 geselecteerdArtikel={field.value}
                                 isInvalid={!!meta.error}
                                 disabled={props.disabled || !bedrijfId}
                                 isMulti={isMulti}
                />
            )}
        </FormikFormField>
    );
};
