export enum ServiceStatus {
    INPLANNEN = "INPLANNEN",
    OPERATIONEEL = "OPERATIONEEL",
    VERLOPEN = "VERLOPEN",
    GEANNULEERD = "GEANNULEERD",
}

export enum ServiceType {
    ONDERHOUD = "ONDERHOUD",
}

export enum ServiceTechnischeOorsprong {
    DYNAMICS = "DYNAMICS",
    BACKOFFICE_MANUEEL = "BACKOFFICE_MANUEEL",
}

export enum ServicePlanningType {
    AUTOMATISCH = "AUTOMATISCH",
    MANUEEL_PERIODIEK = "MANUEEL_PERIODIEK",
    MANUEEL_OP_AANVRAAG = "MANUEEL_OP_AANVRAAG",
}

export enum Month {
    JANUARY = "JANUARY",
    FEBRUARY = "FEBRUARY",
    MARCH = "MARCH",
    APRIL = "APRIL",
    MAY = "MAY",
    JUNE = "JUNE",
    JULY = "JULY",
    AUGUST = "AUGUST",
    SEPTEMBER = "SEPTEMBER",
    OCTOBER = "OCTOBER",
    NOVEMBER = "NOVEMBER",
    DECEMBER = "DECEMBER",
}

export interface ServiceContractRefModel {
    serviceContractId: string;
    bedrijfId: string;
    nr: string;
}

export interface ServiceModel {
    id: string;
    bedrijfId: string;
    serviceContracten: ServiceContractRefModel[];
    inSyncWithNavision: boolean;
    status: ServiceStatus;
    serviceType: ServiceType;
    technischeOorsprong: ServiceTechnischeOorsprong;
    klantId: string;
    verzendAdresId: string;
    startDatum: string;
    eindDatum: string;
    automatischVerlengen: boolean;
    servicePeriode: string;
    servicePlanningType: ServiceType;
    oproepMaand: Month;
    toestellen: string[];
}
