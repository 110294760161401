import * as React from "react";
import ConnectedComponentSpinner from "./ConnectedComponentSpinner";
import {ArtikelModel} from "../../../redux/toestel/types";
import {useGetArtikelById} from "../../../redux/artikel/api";
import {useTranslation} from "../../../helpers/i18nUtils";


interface ArtikelNrProps {
    artikelId?: string;
    artikel?: ArtikelModel;
}

const ArtikelNr: React.FC<ArtikelNrProps> = ({artikelId, artikel}) => {
    const {data: fetchedArtikel} = useGetArtikelById(artikelId!, {skip: !artikelId || !!artikel});

    const {t} = useTranslation("algemeen");

    const item = artikel || fetchedArtikel;

    if (item) {
        return <span className='font-weight-bold'>{item.nr}</span>;
    } else if (artikelId) {
        return <ConnectedComponentSpinner entity="Artikel" id={artikelId} />;
    } else {
        return <span className="text-muted">{t("Artikel.geen-artikel", "Geen artikel")}</span>;
    }

};

export default ArtikelNr;
