import {default as React} from "react";
import Config from "../../../helpers/Config";
import {Skill} from "../../../redux/planning/types";
import Select from "react-select";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

export const SkillformField: React.FC<FormikFormFieldProps> = ({skillEnum = Skill, className, disabled, ...props}) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <Select
                    name={field.name}
                    value={field.value}
                    isDisabled={disabled}
                    onBlur={field.onBlur}
                    onChange={value => {
                        helpers.setValue(value == null ? [] : value)
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isMulti
                    defaultValue={field.value}
                    options={Object.values(skillEnum)}
                    getOptionValue={skill => String(skill)}
                    getOptionLabel={skill => Config.labels[skill]}
                    isClearable={false}
                    styles={{
                        control: provided => (!!meta.error ? {
                            ...provided,
                            borderColor: "#fa5c7c",
                            boxShadow: "none",
                            '&:hover': {
                                borderColor: "#fa5c7c",
                            }
                        } : provided),
                    }}
                />
            )}
        </FormikFormField>
    );
};
