import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {VerzendAdresModel} from "../../../redux/verzendadres/types";
import {GoogleMap, InfoWindow, Marker} from "@react-google-maps/api";
import {Card} from "react-bootstrap";

declare var google: any

interface VerzendAdressenMapProps {
    verzendAdressen: Array<VerzendAdresModel>;
}

const getLatLon = (verzendAdres: VerzendAdresModel) => {
    return {lat: verzendAdres.adres.latitude, lng: verzendAdres.adres.longitude};
}

const VerzendAdresCard: React.FC<{ verzendAdres: VerzendAdresModel }> = ({verzendAdres}) => {
    return <Card>
        <Card.Title>{verzendAdres.code} - {verzendAdres.naam}</Card.Title>
        <Card.Text>
            {verzendAdres.adres.adres}<br/>
            {verzendAdres.adres.adres2 && <span>{verzendAdres.adres.adres2}<br/></span>}
            {verzendAdres.adres.postcode} {verzendAdres.adres.plaats}<br/>
        </Card.Text>
    </Card>
};

const VerzendAdressenMap: React.FC<VerzendAdressenMapProps> = ({verzendAdressen}) => {
    const [infoOpen, setInfoOpen] = useState('');
    const [map, setMap] = useState<google.maps.Map>();

    const openInfo = (verzendAdresId: string) => {
        setInfoOpen(verzendAdresId);
    }
    const closeInfo = () => {
        setInfoOpen('');
    }

    const bounds = useMemo(() => {
        const bounds = new google.maps.LatLngBounds();
        for (const verzendAdres of verzendAdressen.filter(verzendAdres => verzendAdres.adres.latitude && verzendAdres.adres.longitude)) {
            bounds.extend(getLatLon(verzendAdres));
        }

        return bounds;
    }, [verzendAdressen]);

    useEffect(() => {
        if (bounds) {
            map?.fitBounds(bounds);
        }
    }, [bounds, map]);

    const onMapLoad = useCallback((map) => {
        setMap(map);

        if (bounds) {
            map.fitBounds(bounds);
        }
    }, [setMap, bounds]);

    return (
        <GoogleMap
            mapContainerStyle={{height: "600px", width: "100%"}}
            center={{
                lat: 50.855182,
                lng: 3.3901914
            }}
            zoom={16}
            options={{disableDefaultUI: false}}
            onLoad={onMapLoad}>
            {verzendAdressen.filter(verzendAdres => verzendAdres.adres.latitude && verzendAdres.adres.longitude).map(verzendAdres =>
                <Marker
                    key={verzendAdres.id}
                    position={getLatLon(verzendAdres)}
                    onClick={() => openInfo(verzendAdres.id)}
                >
                    {infoOpen === verzendAdres.id &&
                        <InfoWindow onCloseClick={closeInfo}>
                            <VerzendAdresCard verzendAdres={verzendAdres}/>
                        </InfoWindow>}
                </Marker>
            )}
        </GoogleMap>
    );
};

export default VerzendAdressenMap;
