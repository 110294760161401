import {Skill} from "../planning/types";

export type MedewerkerFilters = {
    naam?: string;
    technieker?: boolean;

    rol?: MedewerkerRol;
    skills?: Skill[];
    technicusProfiel?: TechnicusProfiel;
};

export enum MedewerkerRol {
    PLATFORM_TECHNIEKER = 'PLATFORM_TECHNIEKER',
    PLATFORM_ADMINISTRATOR = 'PLATFORM_ADMINISTRATOR',
    PLATFORM_ADMINISTRATIEF_MEDEWERKER = 'PLATFORM_ADMINISTRATIEF_MEDEWERKER',
    PLATFORM_PLANNER = 'PLATFORM_PLANNER',
}

export enum TechnicusProfiel {
    ALL_ROUND = "ALL_ROUND",
    INSTALLATEUR = "INSTALLATEUR",
    ONDERHOUDER = "ONDERHOUDER",
    UIT_DIENST = 'UIT_DIENST',
}

export interface MedewerkerPlanningDagTemplateModel {
    start: string;
    eind: string;
    depotCode: string;
}

export type MedewerkerModel = {
    id: string;
    naam: string;
    gebruikerNaam: string;
    email: string;
    disabled: boolean;
    rollen: Array<MedewerkerRol>;
    adres: MedewerkerAdres;
    color: string;
    dynamicsVestiging: string;
    skills: Array<Skill>;
    technicusProfiel: TechnicusProfiel;
    profielfotoUrl?: string;
    planningWeekTemplate: Record<DayOfWeek, MedewerkerPlanningDagTemplateModel>;
    groepen: MedewerkerGroepModel[];
};

export interface MedewerkerGroepModel {
    naam: string;
}

export type MedewerkerAdres = {
    straatAdres?: string;
    postBus?: string;
    plaats?: string;
    provincie?: string;
    postcode?: boolean;
    land?: string;
    landRegioCode?: string;
    latitude?: number;
    longitude?: number;
};

export interface UpdateMedewerkerForm {
    medewerkerId: string;
    skills: Skill[];
    technicusProfiel?: TechnicusProfiel;
}

export enum DayOfWeek {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY"
}

export interface UpdateMedewerkerPlanningDayTemplateForm {
    start: string;
    eind: string;
    depotCode: string;
}

export interface UpdateMedewerkerPlanningWeekTemplateForm {
    id: string;
    planningWeekTemplate: Record<DayOfWeek, UpdateMedewerkerPlanningDayTemplateForm>;
}
