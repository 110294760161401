import {default as React, useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch} from 'react-redux';
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {hideModal} from "../../../../../redux/ui/actions";
import {
    DagPlanningEntryModel,
    DagPlanningEntryType,
    DagPlanningOmwegBerekeningModel,
    DagPlanningStatus
} from "../../../../../redux/planning/types";
import {Formik, FormikContextType} from "formik";
import * as Yup from 'yup';
import {MedewerkerFormField} from "../../../../../components/aqualex/form/MedewerkerFormField";
import {MedewerkerModel} from "../../../../../redux/medewerker/types";
import {FormikEffect} from "../../../../../components/aqualex/form/FormikEffect";
import {DatumFormField} from "../../../../../components/aqualex/form/DatumFormField";
import {WarningAlert} from "../../../../../components/aqualex/Alerts";
import {
    DagPlanningTimeline,
    DagPlanningTimelineEntry,
    DagPlanningTimelineEntryType
} from "../../../dagplanning/detail/DagPlanningTimeline";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../../components/aqualex/Modal";
import {useGetBezoekAanvraagById} from "../../../../../redux/bezoekaanvraag/api";
import {
    useGetDagPlanningByMedewerkerIdAndDatum,
    useGetDagPlanningOmwegBerekening
} from "../../../../../redux/planning/api";
import {skipToken} from "@reduxjs/toolkit/query";
import {TijdsduurFormField} from "../../../../../components/aqualex/form/TijdsduurFormField";
import {durationToTime, timeToDuration} from "../../../../../helpers/DateUtils";
import {useTranslation} from "../../../../../helpers/i18nUtils";

export interface PlanBezoekAanvraagModalProps {
    bezoekAanvraagId: string;
    onBevestigHandler: (dagPlanningId: string, bezoekNummer: number, tePlannenDuurtijd: number) => void;

    datum?: Date;
    technieker?: MedewerkerModel;
}

interface FormValues {
    technieker?: MedewerkerModel;
    datum?: Date;
    tePlannenDuurtijd?: string;
}

export const PlanBezoekAanvraagModal: React.FC<PlanBezoekAanvraagModalProps> = (props) => {
    const {
        bezoekAanvraagId,
        onBevestigHandler
    } = props;

    const {t} = useTranslation("planning");

    const dispatch = useDispatch();

    const [techniekerId, setTechniekerId] = useState<string | undefined>(props.technieker?.id);
    const [datum, setDatum] = useState<Date | undefined>(props.datum);

    const {
        data: dagPlanning,
        isFetching: dagPlanningLoading,
    } = useGetDagPlanningByMedewerkerIdAndDatum((techniekerId && datum) ? {
        medewerkerId: techniekerId,
        datum
    } : skipToken);
    const dagPlanningNotFound = techniekerId && datum && !dagPlanningLoading && !dagPlanning;

    const {data: omwegBerekening, isFetching: omwegBerekeningLoading} = useGetDagPlanningOmwegBerekening({
        dagPlanningId: dagPlanning?.id!,
        bezoekAanvraagId: bezoekAanvraagId
    }, {skip: !dagPlanning});

    const {data: bezoekAanvraag} = useGetBezoekAanvraagById(bezoekAanvraagId);


    const nogInTePlannenDuurtijd = durationToTime(bezoekAanvraag ? Math.max(bezoekAanvraag.verwachteDuur - bezoekAanvraag.geplandeDuur, 0) : 0, "minutes");

    const [tePlannenDuurtijd, setTePlannenDuurtijd] = useState<string>(nogInTePlannenDuurtijd);

    useEffect(() => {
        const nogInTePlannenDuur = bezoekAanvraag ? Math.max(bezoekAanvraag.verwachteDuur - bezoekAanvraag.geplandeDuur, 0) : 0;

        setTePlannenDuurtijd(durationToTime(nogInTePlannenDuur, "minutes"));
    }, [bezoekAanvraag]);

    const gestorteerdeOmwegen = useMemo(() => (omwegBerekening || []).concat().sort((a, b) => a.afstand.inMinuten - b.afstand.inMinuten), [omwegBerekening]);

    const besteOmwegen = useMemo(() => {
        if (!gestorteerdeOmwegen?.length) {
            return [];
        }

        const besteOmweg = gestorteerdeOmwegen[0];
        const besteOmwegen: DagPlanningOmwegBerekeningModel[] = [besteOmweg];

        if (gestorteerdeOmwegen.length > 1) {
            for (const omweg of gestorteerdeOmwegen) {
                if (omweg.afstand.inMinuten - besteOmweg.afstand.inMinuten <= 5) {
                    besteOmwegen.push(omweg);
                } else {
                    break; // Het is al gestorteerd dus van zodra er geen betere meer zijn stoppen we compleet
                }
            }
        }

        return besteOmwegen;
    }, [gestorteerdeOmwegen]);

    const createOmwegTimelineEntry = useCallback((entry: DagPlanningEntryModel, omweg?: DagPlanningOmwegBerekeningModel) => ({
        id: entry.id + "-omweg",
        type: DagPlanningTimelineEntryType.BEZOEK_AANVRAAG_OMWEG,
        omwegBerekening: omweg ? {
            berekening: omweg,
            aangeraden: besteOmwegen.includes(omweg)
        } : undefined
    }), [besteOmwegen]);

    const timelineEntries = useMemo(() => {
        if (!dagPlanning?.entries) {
            return undefined;
        }

        const timelineEntries: DagPlanningTimelineEntry[] = [];

        for (let i = 0; i < dagPlanning.entries.length; i++) {
            const entry = dagPlanning.entries[i];

            if (entry.type === DagPlanningEntryType.RIT) {
                continue;
            }

            timelineEntries.push({
                id: entry.id,
                type: DagPlanningTimelineEntryType.DAG_PLANNING_ENTRY,
                dagPlanningEntry: entry
            });

            if (i === 0) {
                const omweg = omwegBerekening?.find(item => item.vanBezoekId == null);
                timelineEntries.push(createOmwegTimelineEntry(entry, omweg));
            } else if (i === dagPlanning.entries.length - 1) {
                const omweg = omwegBerekening?.find(item => item.naarBezoekId == null);
                timelineEntries.push(createOmwegTimelineEntry(entry, omweg));
            } else if (entry.type === DagPlanningEntryType.BEZOEK && entry.geplandBezoekId) {
                const omweg = omwegBerekening?.find(item => item.vanBezoekId === entry.geplandBezoekId);
                timelineEntries.push(createOmwegTimelineEntry(entry, omweg));
            }
        }

        return timelineEntries;
    }, [dagPlanning, omwegBerekening, createOmwegTimelineEntry]);

    const formValuesChanged = useCallback(
        ({values, touched}: FormikContextType<FormValues>) => {
            if (touched.technieker) {
                setTechniekerId(values.technieker?.id);
            }

            if (touched.datum) {
                setDatum(values.datum);
            }

            if (touched.tePlannenDuurtijd && values.tePlannenDuurtijd) {
                setTePlannenDuurtijd(values.tePlannenDuurtijd);
            }
        },
        []
    );

    const maxBezoekNummer = dagPlanning
        ? dagPlanning.entries.filter(entry => entry.type === DagPlanningEntryType.BEZOEK).length
        : 1;

    const onAnnuleer = () => {
        dispatch(hideModal());
    };

    const onBevestig = (bezoekNummer: number) => {
        if (!dagPlanning) {
            return;
        }

        dispatch(hideModal());

        onBevestigHandler(dagPlanning.id, bezoekNummer, timeToDuration(tePlannenDuurtijd, "minutes"));
    };

    const onOmwegClick = (omwegBerekening: DagPlanningOmwegBerekeningModel | undefined, item: DagPlanningTimelineEntry, index: number) => {
        if (!omwegBerekening) {
            // Lege planning
            if (index === 0) {
                onBevestig(0);
            }

            return;
        }

        if (omwegBerekening.vanBezoekId == null) {
            onBevestig(0);
        } else if (omwegBerekening.naarBezoekId == null) {
            onBevestig(maxBezoekNummer);
        } else {
            const bezoek = dagPlanning?.entries?.find(item => item.geplandBezoekId === omwegBerekening.vanBezoekId);

            if (bezoek && bezoek.bezoek?.bezoekIndex !== undefined) {
                onBevestig(bezoek.bezoek?.bezoekNummer);
            }
        }
    };

    const initialValues: FormValues = useMemo(() => ({
        technieker: props.technieker,
        datum: props.datum,
        tePlannenDuurtijd: nogInTePlannenDuurtijd
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [props.technieker, props.datum, bezoekAanvraag]);

    return (
        <Modal show={true} onHide={onAnnuleer} autoFocus size="lg">
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("PlanBezoekAanvraagModal.bezoekaanvraag-plannen", "Bezoekaanvraag plannen")}</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validateOnMount={false}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={() => {
                }}
                validationSchema={Yup.object({
                    technieker: Yup.object()
                        .nullable()
                        .required(t("Foutmeldingen.technieker-is-verplicht", "Technieker is verplicht") as string),
                    datum: Yup.date()
                        .nullable()
                        .required(t("Foutmeldingen.datum-is-verplicht", "Datum is verplicht") as string),
                    tePlannenDuurtijd: Yup.string()
                        .required(t("Foutmeldingen.te-plannen-duur-is-verplicht", "Te plannen duur is verplicht") as string)
                })}
            >
                {() => {
                    return <Form noValidate>
                        <FormikEffect onChange={formValuesChanged}/>
                        <ModalBody>
                            <Form.Row>
                                {!props.datum && (
                                    <FormGroup id='datum' as={Col}>
                                        <DatumFormField name='datum' label={t("Labels.datum", "Datum")}/>
                                    </FormGroup>
                                )}
                                {!props.technieker && (
                                    <FormGroup id='medewerker' as={Col}>
                                        <MedewerkerFormField name='technieker' label={t("Labels.technicus", "Technicus")}
                                                             searchFilters={{technieker: true}}/>
                                    </FormGroup>
                                )}

                                <FormGroup id='tePlannenDuurtijd' as={Col}>
                                    <TijdsduurFormField name="tePlannenDuurtijd" label={t("Labels.duur", "Duur")}
                                                        presets={[
                                                       {
                                                           label: t("Labels.nog-in-te-plannen", "Nog in te plannen"),
                                                           value: nogInTePlannenDuurtijd
                                                       },
                                                       ...(bezoekAanvraag?.verwachteDuur ? [{
                                                           label: t("Labels.volledige-verwachte-duur", "Volledige verwachte duur"),
                                                           value: durationToTime(bezoekAanvraag.verwachteDuur, "minutes")
                                                       }] : [])
                                                   ]}/>
                                </FormGroup>

                                <hr/>
                            </Form.Row>

                            {dagPlanningNotFound
                                ?
                                <WarningAlert>
                                    {t("PlanBezoekAanvraagModal.geen-dagplanning-voor-deze-medewerker", "Geen dagplanning voor deze medewerker op de gekozen datum, kies een andere medewerker en/of datum.")}
                                </WarningAlert>
                                : (techniekerId && datum && dagPlanningLoading) && (
                                <div role="status" className="spinner-border text-primary mt-1"></div>)
                            }

                            {dagPlanning?.status === DagPlanningStatus.NIETS_PLANNEN && (
                                <WarningAlert>
                                    {t("PlanBezoekAanvraagModal.dagplanning-is-geannuleerd", "De dagplanning is geannuleerd voor deze medewerker op de gekozen datum, kies een andere medewerker en/of datum.")}
                                </WarningAlert>
                            )}

                            {dagPlanning?.entries && (
                                <DagPlanningTimeline dagPlanning={dagPlanning}
                                                     showBezoekLink={false}
                                                     entries={timelineEntries || []}
                                                     onOmwegClick={onOmwegClick}
                                                     loading={omwegBerekeningLoading}
                                                     dragDropEnabled={false}
                                                     showInstructieEntry={false}
                                />
                            )}
                        </ModalBody>
                    </Form>
                }}
            </Formik>
            <ModalFooter>
                <Button variant="light" onClick={() => onAnnuleer()}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>
            </ModalFooter>
        </Modal>
    );
};
