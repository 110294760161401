import React from "react";
import {Col, Form, FormGroup, InputGroup, Modal} from "react-bootstrap";
import {SimpleFormField} from "../../../../components/aqualex/form/SimpleFormField";
import {
    KlantAanmaningsconditiesEntries,
    KlantBedrijfsgrootteEntries,
    KlantBetalingsconditiesEntries,
    KlantBtwBedrijfsboekingsgroepenEntries,
    KlantModel,
    KlantSector
} from "../../../../redux/klant/types";
import {CreateKlantModalSimilarKlanten} from "./CreateKlantModalSimilarKlanten";
import {Page} from "../../../../redux/support/pagination/types";
import {ReactSelectFormField} from "../../../../components/aqualex/form/ReactSelectFormField";
import {SimpleCheckField} from "../../../../components/aqualex/form/SimpleCheckField";
import {SelectFormField} from "../../../../components/aqualex/form/SelectFormField";
import {TransportKostConfiguratieForm} from "../../../../components/aqualex/form/TransportKostConfiguratieForm";
import {ExtraCo2FlesBeleidFormSelectField} from "../../../../components/aqualex/form/ExtraCo2FlesBeleidFormSelectField";
import {useTranslation} from "../../../../helpers/i18nUtils";

export interface CreateKlantModalKlantparametersProps {
    similarKlantNaam: Page<KlantModel>;
    similarKlantEmailadres: Page<KlantModel>;
    similarKlantBtwNummer: Page<KlantModel>;
}

export const CreateKlantModalKlantparameters: React.FC<CreateKlantModalKlantparametersProps> = (props) => {
    const {similarKlantNaam, similarKlantEmailadres, similarKlantBtwNummer} = props;

    const {t} = useTranslation("klant");

    return (
        <Modal.Body>
            <CreateKlantModalSimilarKlanten similarKlantNaam={similarKlantNaam}
                                            similarKlantBtwNummer={similarKlantBtwNummer}
                                            similarKlantEmailadres={similarKlantEmailadres}/>

            <Form.Row>
                <FormGroup id="sector" as={Col} sm={4} xs={12}>
                    <ReactSelectFormField name="klantparameters:sector"
                                          label={t("Labels.sector", "Sector")}
                                          selectProps={{
                                              placeholder: t("Placeholders.sector", "Sector"),
                                              options: KlantSector
                                          }}
                    />
                </FormGroup>

                <FormGroup id="bedrijfsgrootte" as={Col} sm={4} xs={12}>
                    <SelectFormField name="klantparameters:bedrijfsgrootte"
                                     label={t("Labels.bedrijfsgrootte", "Bedrijfsgrootte")}
                                     placeholder={t("Placeholders.bedrijfsgrootte", "Bedrijfsgrootte")}>
                        <option value={undefined}/>
                        {KlantBedrijfsgrootteEntries.map(([value, label]) => <option key={value}
                            value={value}>{label}</option>)}
                    </SelectFormField>
                </FormGroup>

                <FormGroup id="btwBedrijfsboekingsgroep" as={Col} sm={4} xs={12}>
                    <SelectFormField name="klantparameters:btwBedrijfsboekingsgroep"
                                     label={t("Labels.btw-bedrijfsboekingsgroep", "BTW Bedrijfsboekingsgroep")}
                                     placeholder={t("Placeholders.btw-bedrijfsboekingsgroep", "BTW Bedrijfsboekingsgroep")}>
                        {KlantBtwBedrijfsboekingsgroepenEntries.map(([value, label]) => <option key={value}
                                                                                                value={value}>{label}</option>)}
                    </SelectFormField>
                </FormGroup>

                <FormGroup id="kredietlimiet" as={Col} sm={4} xs={12}>
                    <Form.Label htmlFor="kredietlimiet">
                        {t("Labels.kredietlimiet", "Kredietlimiet")}
                    </Form.Label>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>€</InputGroup.Text>
                        </InputGroup.Prepend>
                        <SimpleFormField type="number" step="0.01" pattern="^€ \d+(?:\.\d{1,2})?"
                                         name="klantparameters:kredietlimiet"
                                         placeholder={t("Placeholders.kredietlimiet", "Kredietlimiet")}/>
                    </InputGroup>
                </FormGroup>

                <FormGroup id="betalingscondities" as={Col} sm={4} xs={12}>
                    <SelectFormField name="klantparameters:betalingscondities"
                                     label={t("Labels.betalingscondities", "Betalingscondities")}
                                     placeholder={t("Labels.betalingscondities", "Betalingscondities")}>
                        <option value={undefined}/>
                        {KlantBetalingsconditiesEntries.map(([value, label]) => <option key={value}
                            value={value}>{label}</option>)}
                    </SelectFormField>
                </FormGroup>

                <FormGroup id="aanmaningsconditie" as={Col} sm={4} xs={12}>
                    <SelectFormField name="klantparameters:aanmaningsconditie"
                                     label={t("Labels.aanmaningsconditie", "Aanmaningsconditie")}
                                     placeholder={t("Placeholders.aanmaingsconditie", "Aanmaningsconditie")}>
                        {KlantAanmaningsconditiesEntries.map(([value, label]) => <option key={value}
                            value={value}>{label}</option>)}
                    </SelectFormField>
                </FormGroup>

                <FormGroup id="transportkost" as={Col} sm={4} xs={6}>
                    <TransportKostConfiguratieForm namePrefix="klantparameters:" allowInherit={false}
                                                   typeLabel={t("Labels.transportkost", "Transportkost")}
                                                   bedragLabel={t("Labels.bedrag", "Bedrag")}
                    />
                </FormGroup>

                <FormGroup id="co2FlesBeleid" as={Col} sm={4} xs={6}>
                    <ExtraCo2FlesBeleidFormSelectField allowInherit={false}
                                                       typeLabel={t("Labels.extra-co2-beleid", "Extra CO₂-fles beleid")}
                                                       fieldName="klantparameters:extraCo2FlesBeleid"/>
                </FormGroup>

                <FormGroup id="externDocumentNrVerplichtAndKeyAccount" as={Col} xs={12}>
                    <SimpleCheckField id="klantparameters:keyAccount"
                                      name="klantparameters:keyAccount"
                                      label={t("Labels.key-account", "Key account")}/>
                    <SimpleCheckField id="klantparameters:externDocumentNrVerplicht"
                                      name="klantparameters:externDocumentNrVerplicht"
                                      label={t("Labels.extern-documentnr-verplicht", "Extern documentnr. verplicht")}/>
                </FormGroup>

            </Form.Row>
        </Modal.Body>
    );
};
