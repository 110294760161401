import React, {ReactNode, useMemo} from "react";
import dayjs from "dayjs";
import {OpeningsurenModel} from "../../../redux/verzendadres/types";
import Tijdstip from "../Tijdstip";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface OpeningsurenProps {
    openingsuren?: OpeningsurenModel;
    datum?: Date;

    geslotenLabel?: ReactNode;
    onbekendLabel?: ReactNode;
}

export const Openingsuren: React.FC<OpeningsurenProps> = (props) => {
    const {t} = useTranslation("algemeen");

    const {openingsuren, datum, geslotenLabel = t("Labels.gesloten", "Gesloten"), onbekendLabel = t("Labels.onbekend", "Onbekend")} = props;

    const dagen: (keyof OpeningsurenModel)[] = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];

    const huidigeDag = useMemo(() => {
        const date = dayjs(datum);

        return dagen[date.day()];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datum]);

    const hasSlots = dagen.some(dag => openingsuren?.[dag]?.slot1 || openingsuren?.[dag]?.slot2);
    if (!hasSlots) {
        return <>{onbekendLabel}</>;
    }

    const huidigeOpeningsuren = openingsuren?.[huidigeDag];

    if (!huidigeOpeningsuren || (!huidigeOpeningsuren?.slot1 && !huidigeOpeningsuren?.slot2)) {
        return <>{geslotenLabel}</>;
    }

    return (
        <>
            {huidigeOpeningsuren.slot1 && (
                <div className="pe-2">
                    <Tijdstip tijd={huidigeOpeningsuren.slot1.van}/> - <Tijdstip
                    tijd={huidigeOpeningsuren.slot1.tot}/>
                </div>
            )}

            {huidigeOpeningsuren.slot2 && (
                <div>
                    <Tijdstip tijd={huidigeOpeningsuren.slot2.van}/> - <Tijdstip
                    tijd={huidigeOpeningsuren.slot2.tot}/>
                </div>
            )}
        </>
    );
};
