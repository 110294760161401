import {
    CreateVerzendAdresBijlageForm,
    CreateVerzendAdresForm,
    UpdatePlanningsparametersModel,
    UpdateVerzendAdresForm,
    VerzendAdresModel,
    VerzendAdresOverzichtModel,
    VerzendAdresSearchFilters,
    VerzendAdresTransportKostSimulatieModel
} from "./types";
import {Page} from "../support/pagination/types";
import {baseApi} from "../support/api";
import {FiltersAndPageable} from "../support/uiSlice";
import {buildMutation, buildQuery, buildUpdateMutation} from "../support/ApiBuilderUtils";
import {TransportKostConfiguratieType} from "../types";
import {installTranslationKey} from "../../helpers/i18nUtils";

export const verzendAdresApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getVerzendAdresById: build.query<VerzendAdresModel, string>({
            query: id => `/verzendadres/${id}`,
            providesTags: (result, error, id) => [{type: "VerzendAdres", id}],
        }),
        getVerzendAdressenByKlantId: buildQuery<VerzendAdresModel[], { klantId: string }>(build,
            installTranslationKey("klant:APIFoutmeldingen.ophalen-verzendadressen", "ophalen verzendadressen"), "VerzendAdres", ({klantId}) => `/klant/${klantId}/verzendadressen`, {
            providesTags: (result, error, {klantId}) => result
                ? [
                    ...result.map(({id}) => ({type: "VerzendAdres" as const, id})),
                    {type: "VerzendAdres" as const, id: `KLANT-${klantId}`},
                ]
                : [{type: "VerzendAdres" as const, id: `KLANT-${klantId}`}],
        }),
        searchVerzendAdresen: build.query<Page<VerzendAdresOverzichtModel>, FiltersAndPageable<VerzendAdresSearchFilters>>({
            query: ({filters, pageable}) => ({
                url: "/verzendadres",
                params: {
                    ...filters,
                    page: pageable.pageNumber - 1,
                    size: pageable.pageSize,
                    sort: `${pageable.sortField},${pageable.sortOrder},ignoreCase`,
                },
            }),
            transformResponse: (response: Page<VerzendAdresOverzichtModel>) => {
                return {
                    content: response.content,
                    number: response.number + 1,
                    size: response.size,
                    totalElements: response.totalElements,
                    loading: false,
                };
            },
            providesTags: (result, error, arg) =>
                result
                    ? [
                        ...result.content.map(({id}) => ({type: "VerzendAdres" as const, id})),
                        {type: "VerzendAdres", id: "PARTIAL-LIST"},
                    ]
                    : [{type: "VerzendAdres", id: "PARTIAL-LIST"}],
        }),
        nieuwVerzendAdres: buildMutation<VerzendAdresModel, CreateVerzendAdresForm>(build,
            installTranslationKey("klant:APIFoutmeldingen.nieuw-verzendadres-maken", "nieuw verzendadres maken"), "VerzendAdres", ({klantId}) => `/klant/${klantId}/verzendadres`),
        updateVerzendAdres: buildUpdateMutation<VerzendAdresModel, UpdateVerzendAdresForm & Pick<VerzendAdresModel, "id">>(build,
            installTranslationKey("klant:APIFoutmeldingen.bijwerken-verzendadres", "bijwerken verzendadres"), "VerzendAdres", ({id}) => `/verzendadres/${id}`, {}, {method: "PATCH"}),
        createVerzendAdresBijlage: build.mutation<VerzendAdresModel, CreateVerzendAdresBijlageForm>({
            query: ({klantId, verzendAdresId, ...form}) => {
                const formData = new FormData();

                formData.append("bestand", form.bestand, form.bestand.name);
                form.omschrijving && formData.append("omschrijving", form.omschrijving);

                return {
                    url: `/klant/${klantId}/verzendadres/${verzendAdresId}/bijlage`,
                    method: 'POST',
                    body: formData,
                }
            },
            extraOptions: {
                omschrijving: "toevoegen van verzendadres bijlage",
            },
            invalidatesTags: (result, error, arg) => [
                {type: "VerzendAdres", id: arg.verzendAdresId},
                {type: "VerzendAdres", id: "PARTIAL-LIST"},
            ],
        }),
        deleteVerzendAdresBijlage: build.mutation<VerzendAdresModel, { klantId: string, verzendAdresId: string, verzendAdresBijlageId: string }>({
            query: ({klantId, verzendAdresId, verzendAdresBijlageId}) => ({
                url: `/klant/${klantId}/verzendadres/${verzendAdresId}/bijlage/${verzendAdresBijlageId}`,
                method: 'DELETE',
            }),
            extraOptions: {
                omschrijving: installTranslationKey("klant:APIFoutmeldingen.verwijderen-van-verzendadres-bijlage", "verwijderen van verzendAdres bijlage"),
            },
            invalidatesTags: (result, error, arg) => [
                {type: "VerzendAdres", id: arg.verzendAdresId},
                {type: "VerzendAdres", id: "PARTIAL-LIST"},
            ],
        }),
        updatePlanningsparameters: build.mutation<VerzendAdresModel, UpdatePlanningsparametersModel & { klantId: string, verzendAdresId: string }>({
            query: ({klantId, verzendAdresId, ...form}) => {
                return {
                    url: `/klant/${klantId}/verzendadres/${verzendAdresId}`,
                    method: 'POST',
                    body: form,
                }
            },
            extraOptions: {
                omschrijving: installTranslationKey("klant:APIFoutmeldingen.bijwerken-van-verzendadres", "bijwerken van verzendadres"),
            },
            invalidatesTags: (result, error, arg) => [
                {type: "VerzendAdres", id: arg.verzendAdresId},
                {type: "VerzendAdres", id: "PARTIAL-LIST"},
            ],
        }),

        getVerzendAdresTransportKostSimulatie: build.query<VerzendAdresTransportKostSimulatieModel, {id: string; type: TransportKostConfiguratieType; value?: number;}>({
            query: ({id, type, value}) => ({
                url: `/verzendadres/${id}/transportkost/simulatie`,
                method: "GET",
                params: {type, value}
            }),
            extraOptions: {
                omschrijving: installTranslationKey("klant:APIFoutmeldingen.simuleren-vam-transportkost", "simuleren van transportkost")
            }
        })
    })
});

export const useGetVerzendAdresById = verzendAdresApi.endpoints.getVerzendAdresById.useQuery;
export const useSearchVerzendAdressen = verzendAdresApi.endpoints.searchVerzendAdresen.useQuery;
export const useGetVerzendAdressenByKlantId = verzendAdresApi.endpoints.getVerzendAdressenByKlantId.useQuery;
export const useNieuwVerzendAdres = verzendAdresApi.endpoints.nieuwVerzendAdres.useMutation;
export const useUpdateVerzendAdres = verzendAdresApi.endpoints.updateVerzendAdres.useMutation;
export const useCreateVerzendAdresBijlage = verzendAdresApi.endpoints.createVerzendAdresBijlage.useMutation;
export const useDeleteVerzendAdresBijlage = verzendAdresApi.endpoints.deleteVerzendAdresBijlage.useMutation;
export const useUpdatePlanningsparameters = verzendAdresApi.endpoints.updatePlanningsparameters.useMutation;
export const useLazyGetVerzendAdresTransportKostSimulatie = verzendAdresApi.endpoints.getVerzendAdresTransportKostSimulatie.useLazyQuery;

