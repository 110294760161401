import {Co2BestellingModel} from "../redux/co2bestelling/types";

export interface Co2LeveringGeleverdArtikel {
    geleverdAantal: number;
    geleverdOmruilAantal: number;
}

export interface Co2LeveringGecombineerd {
    id: string;
    aantal: number;
    aantalExtra: number;
    aantalTotaal: number;
}

export class Co2LeveringUtils {

    public static combineer(bestellingen: Co2BestellingModel[]): Record<string, Co2LeveringGecombineerd> {
        const gecombineerd: Record<string, Co2LeveringGecombineerd> = {};

        for (const bestelling of bestellingen) {
            const artikelId = bestelling.besteldArtikel.id;

            if (!gecombineerd[artikelId]) {
                gecombineerd[artikelId] = {
                    id: artikelId,
                    aantal: 0,
                    aantalExtra: 0,
                    aantalTotaal: 0
                };
            }

            gecombineerd[artikelId].aantal += bestelling.aantal;
            gecombineerd[artikelId].aantalExtra += bestelling.extraAantal;

            gecombineerd[artikelId].aantalTotaal = gecombineerd[artikelId].aantal + gecombineerd[artikelId].aantalExtra;
        }

        return gecombineerd;
    }

}
