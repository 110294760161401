import {Co2BestellingFilters, Co2BestellingModel, NieuweCo2BestellingForm} from "./types";
import {
    buildDownload,
    buildFilteredAndPagedSearchQuery,
    buildGetByIdQuery,
    buildMutation,
    buildUpdateMutation
} from "../support/ApiBuilderUtils";
import {FileDownload} from "../../helpers/download";
import {Pageable} from "../support/pagination";
import {baseApi} from "../support/api";
import {formatDateIso} from "../../helpers/DateUtils";
import {installTranslationKey} from "../../helpers/i18nUtils";


export const co2BestellingApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCo2BestellingById: buildGetByIdQuery<Co2BestellingModel, string>(build,
            installTranslationKey("co2-bestelling:APIFoutmeldingen.ophalen-van-co2-bestelling", "ophalen van CO₂ bestelling"), "Co2Bestelling", id => `/co2bestelling/${id}`),
        searchCo2Bestellingen: buildFilteredAndPagedSearchQuery<Co2BestellingModel, Co2BestellingFilters>(build,
            installTranslationKey("co2-bestelling:APIFoutmeldingen.zoeken-van-co2-bestellingen", "zoeken van CO₂ bestellingen"), "Co2Bestelling", () => "/co2bestelling", filters => ({
            ...filters,
            ...(filters.geplandeDatum && {geplandeDatum: formatDateIso(filters.geplandeDatum)})
        })),
        nieuweCo2Bestelling: build.mutation<Co2BestellingModel, NieuweCo2BestellingForm>({
            query: ({klantReferentieBijlage, ...rest}) => {
                const formData = new FormData();

                for (const key of Object.keys(rest)) {
                    if (rest[key] !== undefined) {
                        formData.append(key, rest[key]);
                    }
                }

                if (klantReferentieBijlage) {
                    formData.append("klantReferentieBijlage", klantReferentieBijlage, klantReferentieBijlage.name);
                }

                return {
                    url: `/co2bestelling`,
                    method: 'POST',
                    body: formData,
                };
            },
            extraOptions: {
                omschrijving: "bestellen CO₂"
            },
            invalidatesTags: (result, error, arg) => [
                {type: "Co2Bestelling", id: "PARTIAL-LIST"}
            ],
        }),
        annuleerCo2Bestelling: build.mutation<Co2BestellingModel, { reden: string } & Pick<Co2BestellingModel, "id">>({
            query: ({id, ...patch}) => ({
                url: `/co2bestelling/${id}/annuleer`,
                method: 'POST',
                body: patch
            }),
            extraOptions: {
                omschrijving: installTranslationKey("co2-bestelling.APIFoutmeldingen.annuleren-van-co2-bestelling", "annuleren van CO₂ bestelling"),
            },
            invalidatesTags: (result, error, arg) => [
                {type: "Co2Bestelling", id: arg.id},
                {type: "Co2Bestelling", id: "PARTIAL-LIST"}
            ],
        }),
        downloadCo2Bestelling: buildDownload<FileDownload, Co2BestellingFilters & Pageable>(build,
            installTranslationKey("co2-bestelling:APIFoutmeldingen.downloaden-van-co2-bestellingen-in-Excel-formaat", "downloaden van CO₂-bestellingen in Excel-formaat"), "Co2Bestelling", () => `/co2bestelling.xlsx`),
        markeerCo2BestellingAlsVerzondenMetKoerier: buildUpdateMutation<void, { id: string }>(build,
            installTranslationKey("co2-bestelling:APIFoutmeldingen.verzonden-markeren", "verzonden markeren"), "Co2Bestelling", ({id}) => `/co2bestelling/${id}/verzondenMetKoerier`),
        verzendCo2BestellingMetKoerier: buildUpdateMutation<void, { id: string }>(build,
            installTranslationKey("co2-bestelling:APIFoutmeldingen.verzenden-met-koerier", "verzenden met koerier"), "Co2Bestelling", ({id}) => `/co2bestelling/${id}/verzendMetKoerier`),
        verzendCo2BestellingMetTechnieker: buildUpdateMutation<void, { id: string }>(build,
            installTranslationKey("co2-bestelling:APIFoutmeldingen.verzenden-met-technieker", "verzenden met technieker"), "Co2Bestelling", ({id}) => `/co2bestelling/${id}/planningVoorbereiden`),
        updateCo2Bestelling: buildMutation<void, FormData>(build,
            installTranslationKey("co2-bestelling:APIFoutmeldingen.bijwerken-van-co2-bestelling", "bijwerken van CO2-bestelling"), "Co2Bestelling", (formData) => `/co2bestelling/${formData.get("id")}`, {
            invalidatesTags: (result, error, arg) => [{
                type: "Co2Bestelling",
                id: arg.get("id") as string
            }]
        }, {method: "PATCH"})
    })
});

export const useGetCo2BestellingById = co2BestellingApi.endpoints.getCo2BestellingById.useQuery;
export const useSearchCo2Bestellingen = co2BestellingApi.endpoints.searchCo2Bestellingen.useQuery;
export const useNieuweCo2Bestelling = co2BestellingApi.endpoints.nieuweCo2Bestelling.useMutation;
export const useAnnuleerCo2Bestelling = co2BestellingApi.endpoints.annuleerCo2Bestelling.useMutation;
export const useDownloadCo2Bestelling = co2BestellingApi.endpoints.downloadCo2Bestelling.useMutation;
export const useMarkeerCo2BestellingAlsVerzondenMetKoerier = co2BestellingApi.endpoints.markeerCo2BestellingAlsVerzondenMetKoerier.useMutation;
export const useVerzendCo2BestellingMetKoerier = co2BestellingApi.endpoints.verzendCo2BestellingMetKoerier.useMutation;
export const useVerzendCo2BestellingMetTechnieker = co2BestellingApi.endpoints.verzendCo2BestellingMetTechnieker.useMutation;
export const useUpdateCo2Bestelling = co2BestellingApi.endpoints.updateCo2Bestelling.useMutation;
