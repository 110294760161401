import {RootState} from "../reducers";
import {AnnulatieReden} from "./types";

// selectors
export const selectAnnulatieRedenen = (state: RootState): Array<AnnulatieReden> => {
    return state.RefData.annulatieRedenen?.entries || [];
}

export const selectAnnulatieRedenByCode = (state: RootState, code: string): AnnulatieReden | null => {
    if (state.RefData.annulatieRedenen?.byCodes) {
        return state.RefData.annulatieRedenen.byCodes[code];
    }
    return null;
}
