import React, {useEffect, useState} from "react";
import {Badge} from "react-bootstrap";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";
import {FieldHelperProps, useFormikContext} from "formik";
import * as moment from "moment";
import {momentDurationToTime} from "../../../helpers/DateUtils";

export interface TijdsduurFormFieldProps extends FormikFormFieldProps {
    presets?: { label: string; value: string }[];
}

export const TijdsduurFormField: React.FC<TijdsduurFormFieldProps> = (props) => {
    const {presets, ...rest} = props;

    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);

    const form = useFormikContext<any>();

    useEffect(() => {
        if (form.values?.[rest.name]) {
            const timeString = form.values[rest.name];

            const duration = moment.duration(timeString);
            setHours(duration.get("hours"));
            setMinutes(duration.get("minutes"));
        }
    }, [form.values, rest.name]);

    const getAsString = (hours: number, minutes: number) => {
        const duration = moment.duration(hours, "hours").add(minutes, "minutes");

        return momentDurationToTime(duration);
    };

    const onHourChange = (hours: number, helpers: FieldHelperProps<any>) => {
        if (hours < 0 || hours > 12) {
            return;
        }

        setHours(hours);
        helpers.setValue(getAsString(hours, minutes), true);
        helpers.setTouched(true, true);
    };

    const onMinuteChange = (minutes: number, helpers: FieldHelperProps<any>) => {
        if (minutes < 0 || minutes > 59) {
            return;
        }

        setMinutes(minutes);
        helpers.setValue(getAsString(hours, minutes), true);
        helpers.setTouched(true, true);
    };

    const onPresetClick = async (value: string) => {
        await form.setFieldValue(props.name, value);
        await form.setFieldTouched(props.name, true, true);
    };

    return (
        <FormikFormField {...rest}>
            {(field, meta, helpers) => {
                return (
                    <div className="tijdsduur-form-field">
                        <div className={`form-control d-flex mb-1 ${meta.error ? "is-invalid" : ""}`}>
                            <div className="mr-2">
                                <input style={{width: "3em"}}
                                       className="border-0 mr-1" type="number"
                                       step={1} min={0} max={12} value={hours} onFocus={e => field.value === 0 && e.target.select()}
                                       onChange={event => onHourChange(Number.parseInt(event.target.value), helpers)}/>

                                uur
                            </div>
                            <div>
                                <input style={{width: "3em"}}
                                       className="border-0 mr-1" type="number"
                                       step={1} min={0} max={59} value={minutes} onFocus={e => field.value === 0 && e.target.select()}
                                       onChange={event => onMinuteChange(Number.parseInt(event.target.value), helpers)}/>

                                min.
                            </div>
                        </div>

                        {presets?.map(preset => (
                            <div key={preset.label} className="d-flex align-items-center cursor-pointer"
                                 onClick={() => onPresetClick(preset.value)}>
                                <Badge variant="primary" className="mr-1">{preset.value}</Badge> <small
                                className="text-muted">{preset.label}</small>
                            </div>
                        ))}
                    </div>
                );
            }}
        </FormikFormField>
    );
};
