import {default as React, useState} from "react";
import {CreateBijlageModal} from "./CreateBijlageModal";
import {useHideModal} from "../../../redux/ui/hooks";
import {CreateBijlageFormValues} from "./CreateBijlageModal";

export interface CreateBijlageModalContainerProps {
    initialValues?: CreateBijlageFormValues;
    createBijlageHandler: (bestand: File, omschrijving?: string) => void;
}

export const CreateBijlageModalContainer: React.FC<CreateBijlageModalContainerProps> = ({
                                                                                            initialValues,
                                                                                            createBijlageHandler,
                                                                                        }) => {
    const hideModal = useHideModal();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (values: CreateBijlageFormValues) => {
        setLoading(true);

        await createBijlageHandler(values.bestand[0], values.omschrijving);

        setLoading(false);

        hideModal();
    };

    return (
        <CreateBijlageModal initialValues={initialValues} onCancel={hideModal} onSubmit={onSubmit} loading={loading}/>
    );
};
