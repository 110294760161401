import {default as React} from "react";
import BedrijfSelector from "../BedrijfSelector";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

export interface BedrijfFormFieldProps {
    label?: string;
    isClearable?: boolean;
}

export const BedrijfFormField: React.FC<FormikFormFieldProps & BedrijfFormFieldProps> = ({
                                                                                             searchFilters,
                                                                                             isClearable,
                                                                                             ...props
                                                                                         }) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <BedrijfSelector {...field}
                                 isClearable={isClearable}
                                 defaultValue={meta.initialValue}
                                 onChange={bedrijf => {
                                     helpers.setValue(bedrijf?.id);
                                 }}
                                 isInvalid={!!meta.error}
                                 disabled={props.disabled}/>
            )}
        </FormikFormField>
    );
};
