import merge from 'lodash.merge';
import mergeWith from 'lodash.mergewith';

import {
    EntityDict as EntityDict_new,
    EntityPage as EntityPage_new,
    Pageable as Pageable_new,
    PageDict as PageDict_new,
    PageState as PageState_new,
    Pagination as Pagination_new,
    PaginationEntry as PaginationEntry_new,
    SortOrder as SortOrder_new
} from "./pagination/types";
import {overwriteArraysInsteadOfMerge, paginationKey as paginationKey_new} from "./pagination/pagination";

export type Pageable = Pageable_new;
export type EntityPage<T> = EntityPage_new<T>;
export type Pagination = Pagination_new;
export type PaginationEntry = PaginationEntry_new;
export type PageDict = PageDict_new;
export type PageState = PageState_new;
export type EntityDict<T> = EntityDict_new<T>;
export const SortOrder = SortOrder_new;

export const paginationKey = paginationKey_new;

export function createPaginationReducer(
    types: Array<string>,
    paginationStateKey: string,
    byIdsStateKey: string,
    normalizePayload: Function,
    pageableProvider: Function,
    filtersProvider: Function,
): (state, action) => any {

    const [requestType, successType, failureType] = types;

    function updatePaginationState(state, action) {
        const pageable = pageableProvider(action);
        const filters = filtersProvider(action);

        switch (action.type) {
            case requestType:
                return merge({}, state, {
                    [paginationStateKey]: {
                        [paginationKey(filters, pageable)]: {
                            pages: {
                                [pageable.pageNumber]: {
                                    items: [],
                                    loading: true,
                                },
                            }
                        }
                    }
                });
            case successType:
                const normalizedPayload = normalizePayload(action.payload);

                const newState = {};
                var tmp = newState;
                const pathParts = byIdsStateKey.split('.');
                const pathToLastPart = pathParts.slice(0, pathParts.length - 1);
                const lastPart = pathParts[pathParts.length - 1];
                pathToLastPart.forEach(pathPart => {
                    tmp[pathPart] = {};
                    tmp = tmp[pathPart];
                });
                tmp[lastPart] = normalizedPayload.entities;
                newState[paginationStateKey] = {
                    [paginationKey(filters, pageable)]: {
                        pages: {
                            [pageable.pageNumber]: {
                                items: normalizedPayload.items,
                                loading: false,
                            },
                        },
                        totalItemCount: normalizedPayload.totalItemCount,
                    }
                };

                return mergeWith({}, state, newState, overwriteArraysInsteadOfMerge);
            case failureType:
                return merge({}, state, {
                    [paginationStateKey]: {
                        [paginationKey(filters, pageable)]: {
                            pages: {
                                [pageable.pageNumber]: {
                                    loading: false,
                                },
                            },
                        }
                    },
                });
            default:
                return state;
        }
    }

    return (state, action) => {
        switch (action.type) {
            case requestType:
            case successType:
            case failureType:
                return mergeWith({},
                    state,
                    updatePaginationState(state, action),
                    overwriteArraysInsteadOfMerge
                )
            default:
                return state;
        }
    };
}

