import * as React from "react";
import {OpdrachtModel, PrestatieModel} from "../../../../redux/planning/types";
import PrestatieStatusBadge from "../../../../components/aqualex/opdracht/PrestatieStatusBadge";
import OpdrachtSummary from "../../../../components/aqualex/connected/OpdrachtSummary";
import SimpleTable from "../../../../components/aqualex/overzicht/SimpleTable";
import ToestelLocatie from "../../../../components/aqualex/toestel/ToestelLocatie";
import OpdrachtTypeBadge from "../../../../components/aqualex/opdracht/OpdrachtTypeBadge";
import {Button} from "react-bootstrap";
import {BezoekAanvraagModel} from "../../../../redux/bezoekaanvraag/types";
import ToestelLabel from "../../../../components/aqualex/toestel/ToestelLabel";
import {OperationeelToestelModel} from "../../../../redux/toestel/types";
import {useTranslation} from "../../../../helpers/i18nUtils";


interface BezoekPrestatiesTableProps {
    bezoekAanvraag: BezoekAanvraagModel;
    loading?: boolean;
    onVerwijderPrestatie?: (prestatieId: string) => void;
    onClickedOpdrachtIdHandler?: (opdrachtId: string) => void;
}

export const BezoekAanvraagPrestatiesTable: React.FC<BezoekPrestatiesTableProps> = ({
                                                                                        bezoekAanvraag,
                                                                                        loading,
                                                                                        onVerwijderPrestatie,
                                                                                        onClickedOpdrachtIdHandler
                                                                                    }) => {
    const {t} = useTranslation("planning");

    const columns = [
        {
            dataField: 'id',
            isKey: true,
            text: 'id',
            hidden: true,
        },
        {
            dataField: 'status',
            text: t("Kolomtitels.status", "Status"),
            formatter: (cell) => <PrestatieStatusBadge status={cell}/>

        },
        {
            dataField: 'opdracht',
            text: t("Kolomtitels.opdracht", "Opdracht"),
            formatter: (opdracht: OpdrachtModel) => <OpdrachtTypeBadge opdrachtType={opdracht.type}/>

        },
        {
            dataField: 'opdracht#2',
            text: t("Kolomtitels.extra-informatie", "Extra informatie"),
            formatter: (dummy, prestatie: PrestatieModel) => <OpdrachtSummary opdracht={prestatie.opdracht}/>

        },
        {
            dataField: 'toestel',
            text: t("Kolomtitels.toestel", "Toestel"),
            formatter: (cell, row, rowIndex, extraData) => {

                const toestel = (row.opdracht.toestel || row.opdracht.interventie?.operationeelToestel) as OperationeelToestelModel;

                if (toestel) {
                    return (
                        <span>
                            <ToestelLabel toestel={toestel}/>
                            <br/>
                            <span className="text-muted"><ToestelLocatie locatie={toestel.locatie}/></span>
                        </span>
                    );
                }

                return <span className="text-muted">{t("algemeen:Labels.nvt", "nvt")}</span>;
            }
        },
        ...onVerwijderPrestatie ? [{
            dataField: 'entry_verwijder',
            isDummyField: true,
            text: "",
            classes: 'table-action text-right',
            formatter: (cell, prestatie: PrestatieModel) => {
                return <Button variant='link'
                               className="text-danger"
                               size='lg'
                               onClick={(evt) => {
                                   evt.stopPropagation();
                                   onVerwijderPrestatie(prestatie.id);
                               }}>
                    <i className={'mdi mdi-trash-can-outline'}/>
                </Button>
            }
        }] : []
    ];

    return (
        <SimpleTable
            compact={true}
            itemsLabel={t("BezoekAanvraagPrestatiesTable.prestaties", "prestaties")}
            items={bezoekAanvraag.prestaties}
            columns={columns}
            loading={loading}
            rowClicked={prestatie => onClickedOpdrachtIdHandler?.(prestatie.opdracht.id)}
        />
    )
}
