import {default as React, useCallback, useEffect, useState} from "react";
import {Button, Col, Form, FormGroup, Modal, Row} from "react-bootstrap";
import {Form as FormikForm, useFormikContext} from "formik";
import {AdresForm, KlantLanden, KlantModel, KlantTalen} from "../../../redux/klant/types";
import {SimpleFormField} from "../../../components/aqualex/form/SimpleFormField";
import {SelectFormField} from "../../../components/aqualex/form/SelectFormField";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";
import {PhoneFormField} from "../../../components/aqualex/form/PhoneFormField";
import {AdresAutocompleteInput} from "../../../components/aqualex/form/AdresAutocompleteInput";
import {AdresModel} from "../../../redux/verzendadres/types";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import {AdresUtils} from "../../../helpers/AdresUtils";
import {KlantFormField} from "../../../components/aqualex/form/KlantFormField";
import {googleMapsUrl} from "../../../helpers/links";
import {CreateVerzendAdresFormValues} from "./CreateVerzendAdresFormContainer";
import {RadioFormField} from "../../../components/aqualex/form/RadioFormField";
import {TransportKostConfiguratieForm} from "../../../components/aqualex/form/TransportKostConfiguratieForm";
import {useTranslation} from "../../../helpers/i18nUtils";
import {Trans} from "react-i18next";

export interface CreateVerzendAdresModalFormComponentProps {
    editKlant?: boolean;
    klant?: KlantModel;
    loading?: boolean;

    onCancel(): void;
}

const verzendCodeIgnoreRegex = /((?![A-Z0-9]).)*/gi;
const coordinateIgnoreRegex = /((?!([0-9]|\.)).)*/gi;

export const CreateVerzendAdresFormComponent: React.FC<CreateVerzendAdresModalFormComponentProps> = (props) => {
    const {editKlant, klant, loading} = props;

    const [, setGeocodeLoading] = useState(false);

    const formProps = useFormikContext<CreateVerzendAdresFormValues>();
    const {values, isValid, touched, initialValues, setFieldValue} = formProps;

    const updatePriveField = useCallback((klant?: KlantModel) => {
        if (klant && !loading) {
            if (klant.sector === "PARTICULIER" && values.prive !== "true") {
                setFieldValue("prive", "true");
            } else {
                if (values.prive !== undefined) {
                    setFieldValue("prive", initialValues.prive);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFieldValue, klant]);

    useEffect(() => {
        updatePriveField(klant);
    }, [klant, updatePriveField]);

    useEffect(() => {
        updatePriveField(values.klant);
    }, [values, updatePriveField]);

    useEffect(() => {
        if (values.klant && !values.naam) {
            setFieldValue("naam", values.klant.naam);
        }
    }, [values, setFieldValue]);

    const geocodeAdres = async (adres: Partial<AdresForm>) => {
        setGeocodeLoading(true);

        try {
            const result = await AdresUtils.geocodeByAdres(adres as AdresForm);

            if (result) {
                const {latitude, longitude} = result;

                setFieldValue("latitude", latitude, false);
                setFieldValue("longitude", longitude, false);
            }
        } finally {
            setGeocodeLoading(false);
        }
    };

    const onGeocodeClick = () => {
        geocodeAdres({
            adres: values.adres,
            landCode: values.landCode,
            plaats: values.plaats,
            postcode: values.postcode
        });
    };

    const onAdresAutocomplete = (adres: Partial<AdresModel>) => {
        setFieldValue("adres", adres.adres);
        setFieldValue("postcode", adres.postcode);
        setFieldValue("plaats", adres.plaats);
        setFieldValue("landCode", adres.landCode);

        geocodeAdres(adres);
    };

    const priveCheckDisabled = klant?.sector === "PARTICULIER" || values.klant?.sector === "PARTICULIER";

    const {t} = useTranslation("klant");

    return (
        <FormikForm noValidate>
            <Modal.Body>
                <Row>
                    <Col lg={6} xs={12} className="pr-3">
                        <Row>
                            {editKlant && (
                                <FormGroup id="naam" as={Col} xs={12}>
                                    <KlantFormField capitalize name="klant"
                                                    label={t("Labels.klant", "Klant")}/>
                                </FormGroup>
                            )}

                            <FormGroup id="naam" as={Col} sm={6} xs={12}>
                                <SimpleFormField capitalize name="naam"
                                                 label={t("Labels.naam", "Naam")}
                                                 placeholder={t("Placeholders.naam", "Naam")}
                                                 onChange={(e) => {
                                                     const text = e.target.value;

                                                     setFieldValue("naam", text);

                                                     if (text !== initialValues.naam && text !== values.klant?.naam && !touched.code) {
                                                         setFieldValue("code", text?.replace(verzendCodeIgnoreRegex, "")
                                                             .toUpperCase()
                                                             .substring(0, 10)
                                                         );
                                                     }
                                                 }}
                                />
                            </FormGroup>
                            <FormGroup id="code" as={Col} sm={6} xs={12}>
                                <SimpleFormField uppercased name="code"
                                                 label={t("Labels.verzendcode", "Verzendcode")}
                                                 placeholder={t("Placeholders.verzendcode", "Verzendcode")}
                                                 ignoreRegex={verzendCodeIgnoreRegex}/>
                            </FormGroup>

                            <FormGroup id="contact" as={Col} sm={6} xs={12}>
                                <SimpleFormField capitalize name="contact"
                                                 label={t("Labels.naam-contactpersson","Naam contactpersoon")}
                                                 placeholder={t("Placeholders.naam-contactpersoon", "Naam contactpersoon")}/>
                            </FormGroup>

                            <FormGroup id="taal" as={Col} sm={6} xs={12}>
                                <SelectFormField name="taalCode"
                                                 label={t("Labels.taal", "Taal")}>
                                    {Object.entries(KlantTalen).map(([locale, label]) => (
                                        <option key={locale} value={locale}>{label}</option>
                                    ))}
                                </SelectFormField>
                            </FormGroup>

                            <FormGroup id="emailadres" as={Col} xs={12}>
                                <SimpleFormField name="email"
                                                 label={t("Labels.e-mailadres", "E-mailadres")}
                                                 placeholder={t("Placeholders.e-mailadres", "E-mailadres")}/>
                            </FormGroup>

                            <FormGroup id="telefoon" as={Col} sm={6} xs={12}>
                                <PhoneFormField name="telefoon"
                                                placeholder="01 111 22 33"
                                                label={t("Labels.telefoonnummer", "Telefoonnummer")}/>
                            </FormGroup>

                            <FormGroup id="fax" as={Col} sm={6} xs={12}>
                                <PhoneFormField name="fax"
                                                placeholder="01 111 22 33"
                                                label={t("Labels.faxnummer", "Faxnummer")}/>
                            </FormGroup>

                            <FormGroup id="opmerking" as={Col} xs={12}>
                                <TextAreaFormField name="opmerking"
                                                   label={t("Labels.opmerking", "Opmerking")}
                                                   placeholder={t("Placeholders.opmerking", "Opmerking")}/>
                            </FormGroup>
                        </Row>
                    </Col>

                    <Col lg={6} xs={12}>
                        <Form.Label>Adres</Form.Label>
                        <div className="mb-2 d-flex align-items-center justify-content-start">
                            <i className="mdi mdi-map-marker-outline font-24 mr-2"/>
                            <div className="flex-grow-1">
                                <AdresAutocompleteInput name="adres-autocomplete"
                                                        onChange={onAdresAutocomplete}/>
                                <div className="text-muted p-1">
                                    <Trans t={t}
                                        i18nKey="CreateVerzendAdresForm.zoek-hier-een-adres">
                                        Zoek hier een adres op om de onderstaande velden automatisch in te vullen.
                                    </Trans>
                                </div>
                            </div>
                        </div>

                        <Form.Row>
                            <Col as={Col} md={6} sm={12}>
                                <Row>
                                    <FormGroup as={Col} xs={12}>
                                        <SimpleFormField name="adres"
                                                         label={t("Labels.adresregel", "Adresregel")}
                                                         placeholder={t("Placeholders.adresregel", "Adresregel")}/>
                                    </FormGroup>
                                </Row>
                            </Col>

                            <FormGroup as={Col} md={6} sm={12}>
                                <Row>
                                    <Col sm={4}>
                                        <SimpleFormField name="postcode"
                                                         label={t("Labels.postcode", "Postcode")}
                                                         placeholder="1000"/>
                                    </Col>
                                    <Col sm={8}>
                                        <SimpleFormField name="plaats"
                                                         label={t("Labels.plaats", "Plaats")}
                                                         placeholder={t("Placeholders.plaats", "Plaats")}
                                                         onChange={e => setFieldValue("plaats", e.target?.value?.toUpperCase())}
                                        />
                                    </Col>

                                    <Col>
                                        <SelectFormField name="landCode" placeholder="Land" required>
                                            <option value="" disabled>Selecteer een land...</option>
                                            {Object.entries(KlantLanden).map(([landCode, label]) => (
                                                <option key={landCode} value={landCode}>{label}</option>
                                            ))}
                                        </SelectFormField>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup as={Col} sm={12}>
                                <Form.Label>{t("Titels.coordinaten", "Coördinaten")}</Form.Label>

                                <Form.Row className="flex-nowrap">
                                    <Col>
                                        <SimpleFormField name="latitude"
                                                         placeholder={t("Labels.latitude","Latitude")}
                                                         ignoreRegex={coordinateIgnoreRegex}
                                                         showFeedback={false}/>
                                    </Col>
                                    <Col>
                                        <SimpleFormField name="longitude"
                                                         placeholder={t("Labels.longitude", "Longitude")}
                                                         ignoreRegex={coordinateIgnoreRegex}
                                                         showFeedback={false}/>
                                    </Col>

                                    <Col xs="auto">
                                        <Button onClick={onGeocodeClick}
                                                disabled={!values.adres || !values.plaats || !values.postcode || !values.landCode}
                                                title={t("Titels.automatisch-bepalen-met-google-maps",
                                                    "Automatisch bepalen d.m.v. Google Maps") as string}>
                                            <i className="mdi mdi-cogs"/>
                                        </Button>
                                    </Col>

                                    <Col xs="auto">
                                        <a target="_blank" rel="noopener noreferrer"
                                           href={values.latitude && values.longitude ? googleMapsUrl(values.latitude, values.longitude) : "#"}>
                                            <Button variant="light"
                                                    title={t("Titels.locatie-openen-in-google-maps",
                                                        "Locatie openen in Google Maps") as string}>
                                                <i className="mdi mdi-google-maps"/>
                                            </Button>
                                        </a>
                                    </Col>
                                </Form.Row>
                            </FormGroup>

                            <FormGroup id="prive" as={Col} xs={12}>
                                <RadioFormField name="prive"
                                                label={t("Labels.privé", "Privé")}
                                                disabled={priveCheckDisabled}
                                                options={[
                                                    {label: t("algemeen:ja", "Ja"), value: "true"},
                                                    {label: t("algemeen:nee", "Nee"), value: "false"},
                                                ]}/>
                            </FormGroup>

                            <FormGroup id="transportkost" as={Col} xs={12}>
                                <Form.Label>{t("Labels.transportkost", "Transportkost")}</Form.Label>
                                <TransportKostConfiguratieForm
                                    inheritLabel={t("Labels.transportkost-overnemen-van-klant", "Overnemen van klant")}/>
                            </FormGroup>
                        </Form.Row>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="light" onClick={props.onCancel}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{""}
                <LoadingButton disabled={!isValid || loading} type="submit"
                               loading={loading} variant="primary">
                    {t("algemeen:Buttons.aanmaken", "Aanmaken")}
                </LoadingButton>
            </Modal.Footer>
        </FormikForm>
    );
};
