import * as React from "react";
import {CSSProperties, useState} from "react";
import {Dropdown, Spinner} from "react-bootstrap";
import {BezoekDuurtijdAanpassenForm, BezoekDuurtijdAanpassenFormValues} from "./BezoekDuurtijdAanpassenForm";
import {mdiTimerEditOutline} from "@mdi/js";
import Icon from "@mdi/react";

export interface BezoekDuurtijdAanpassenDropDownProps {
    datum: string;
    medewerkerId: string;
    geplandBezoekId: string;
    loading?: boolean;
    onBevestig?: (form: BezoekDuurtijdAanpassenFormValues) => any;
    defaultValue?: string;
    className?: string;
}

export const BezoekDuurtijdAanpassenDropDown: React.FC<BezoekDuurtijdAanpassenDropDownProps> = (props) => {
    const {loading, onBevestig, defaultValue, className} = props;

    const [show, setShow] = useState(false);

    const onBevestigHandler = (values) => {
        setShow(false);
        onBevestig?.(values);
    };

    return (<Dropdown onToggle={setShow} show={show}>
        <Dropdown.Toggle id="bezoek-duurtijd-aanpassen-dropdown" disabled={loading} size="sm" variant="link" className={`py-0 ${className || ""}`}>
            {loading && <Spinner animation="border" size="sm" className="mr-2"/>}
            <Icon path={mdiTimerEditOutline} size={0.8}/>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu} onBevestig={onBevestigHandler} defaultValue={defaultValue}/>
    </Dropdown>);
};

interface CustomMenuProps {
    style?: CSSProperties;
    className?: string;
    'aria-labelledby'?: string;
    onBevestig: (form: BezoekDuurtijdAanpassenFormValues) => any;
    defaultValue?: string;
}

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef<HTMLDivElement, CustomMenuProps>(
    (props, ref) => {
        const {style, className, 'aria-labelledby': labeledBy, onBevestig, defaultValue} = props;

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <div className="m-1">
                    <BezoekDuurtijdAanpassenForm onBevestig={onBevestig} defaultValue={defaultValue}/>
                </div>
            </div>
        );
    },
);

