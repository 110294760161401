import React, {useEffect} from "react";
import {Button, Modal, Tab, Tabs} from "react-bootstrap";
import {Form as FormikForm, useFormikContext} from "formik";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";
import {FormUtils} from "../../../helpers/FormUtils";
import {Page, SortOrder} from "../../../redux/support/pagination/types";
import {NieuweKlantModalFormValues} from "./CreateKlantModal";
import {Pageable} from "../../../redux/support/pagination";
import {CreateKlantModalAlgemeneInformatie} from "./create/CreateKlantModalAlgemeneInformatie";
import {CreateKlantModalContactgegevens} from "./create/CreateKlantModalContactgegevens";
import {CreateKlantModalKlantparameters} from "./create/CreateKlantModalKlantparameters";
import {useSearchKlantSimilar} from "../../../redux/klant/api";
import {KlantModel} from "../../../redux/klant/types";
import {CreateKlantModalMyAqualex} from "./create/CreateKlantModalMyAqualex";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface NieuweKlantModalFormProps {
    loading?: boolean;
    disabledFieldNames?: string[];

    onCancel(): void;
}

export const CreateKlantModalForm: React.FC<NieuweKlantModalFormProps> = (props) => {
    const {loading, disabledFieldNames} = props;

    const formProps = useFormikContext<NieuweKlantModalFormValues>();
    const {values, isValid, validateField} = formProps;

    useEffect(() => {
        if (values["klantparameters:sector"]) {
            validateField("algemeneInformatie:btwNummer");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values["klantparameters:sector"]]);

    const pageable: Pageable = {
        pageSize: 5,
        pageNumber: 1,
        sortOrder: SortOrder.desc,
        sortField: ""
    };
    const similarKlantNaam = useSearchKlantSimilar({
            pageable,
            filters: {
                bedrijfId: values?.["algemeneInformatie:bedrijfId"],
                naam: values?.["algemeneInformatie:naam"]
            }
        },
        {
            skip: !(values?.["algemeneInformatie:bedrijfId"] && values?.["algemeneInformatie:naam"] && values?.["algemeneInformatie:naam"]?.length > 3)
        });
    const similarKlantEmailadres = useSearchKlantSimilar({
            pageable,
            filters: {
                bedrijfId: values?.["algemeneInformatie:bedrijfId"],
                emailadres: values?.["contactgegevens:emailadres"]
            }
        },
        {
            skip: !(values?.["algemeneInformatie:bedrijfId"] && values?.["contactgegevens:emailadres"] && values?.["contactgegevens:emailadres"]?.length > 3)
        });
    const similarKlantBtwNummer = useSearchKlantSimilar({
            pageable,
            filters: {
                bedrijfId: values?.["algemeneInformatie:bedrijfId"],
                btwNummer: values?.["algemeneInformatie:btwNummer"]
            }
        },
        {
            skip: !(values?.["algemeneInformatie:bedrijfId"] && values?.["algemeneInformatie:btwNummer"] && values?.["algemeneInformatie:btwNummer"]?.length > 3)
        });
    const emptyPage: Page<KlantModel> = {
        content: [],
        number: 1,
        size: 0,
        totalElements: 0,
        loading: true
    };

    const algemeneInformatieTabClass = FormUtils.getValidationClassTab(formProps, "algemeneInformatie");
    const contactgegevensTabClass = FormUtils.getValidationClassTab(formProps, "contactgegevens");
    const klantparametersTabClass = FormUtils.getValidationClassTab(formProps, "klantparameters");
    const myaqualexTabClass = FormUtils.getValidationClassTab(formProps, "myaqualex");

    const {t} = useTranslation("klant");

    return (
        <FormikForm noValidate>
            <Tabs variant="pills" className="nav-justified form-wizard-header">
                <Tab eventKey="algemeneInformatie"
                     tabClassName={`rounded-0 pt-2 pb-2 ${algemeneInformatieTabClass}`}
                     title={<><i className="mdi mdi-information-outline"/>
                         1. {t("Titels.algemene-informatie", "Algemene informatie")}
                     </>}>
                    <CreateKlantModalAlgemeneInformatie similarKlantNaam={similarKlantNaam.data || emptyPage}
                                                        similarKlantBtwNummer={similarKlantBtwNummer.data || emptyPage}
                                                        similarKlantEmailadres={similarKlantEmailadres.data || emptyPage}/>
                </Tab>

                <Tab eventKey="contactgegevens"
                     tabClassName={`rounded-0 pt-2 pb-2 ${contactgegevensTabClass}`}
                     title={<><i className="mdi mdi-phone-outline"/>
                         2. {t("Titels.contactgegevens", "Contactgegevens")}
                     </>}>
                    <CreateKlantModalContactgegevens similarKlantNaam={similarKlantNaam.data || emptyPage}
                                                     similarKlantBtwNummer={similarKlantBtwNummer.data || emptyPage}
                                                     similarKlantEmailadres={similarKlantEmailadres.data || emptyPage}/>
                </Tab>
                <Tab eventKey="klantparameters"
                     tabClassName={`rounded-0 pt-2 pb-2 ${klantparametersTabClass}`}
                     title={<><i className="mdi mdi-tune"/>
                         3. {t("Titels.klantparameters", "Klantparameters")}
                     </>}>
                    <CreateKlantModalKlantparameters similarKlantNaam={similarKlantNaam.data || emptyPage}
                                                     similarKlantBtwNummer={similarKlantBtwNummer.data || emptyPage}
                                                     similarKlantEmailadres={similarKlantEmailadres.data || emptyPage}/>
                </Tab>
                <Tab eventKey="myaqualex"
                     tabClassName={`rounded-0 pt-2 pb-2 ${myaqualexTabClass}`}
                     title={<><i className="mdi mdi-account-multiple-outline"/>
                         4. {t("Titels.MyAQUALEX", "MyAQUALEX")}
                     </>}>
                    <CreateKlantModalMyAqualex disabledFieldNames={disabledFieldNames}/>
                </Tab>
            </Tabs>

            <Modal.Footer>
                <Button variant="light" onClick={props.onCancel}>
                    {t("algemeen:Buttons.annuleer", "Annuleer")}
                </Button>{""}
                <LoadingButton disabled={!isValid || loading} type="submit"
                               loading={loading} variant="primary">
                    {t("algemeen:Buttons.aanmaken", "Aanmaken")}
                </LoadingButton>
            </Modal.Footer>
        </FormikForm>
    );
};
