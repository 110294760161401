import React, {useEffect} from "react";
import {Badge, Button, Col, Form, FormGroup, Modal, Row} from "react-bootstrap";
import {CreateKlantUitnodigingType, GeenKlantAccountMakenReden} from "../../../../redux/klant/types";
import {RadioFormField} from "../../../../components/aqualex/form/RadioFormField";
import {useFormikContext} from "formik";
import {NieuweKlantModalFormValues} from "../CreateKlantModal";
import * as Yup from "yup";
import {SimpleFormField} from "../../../../components/aqualex/form/SimpleFormField";
import CustomerEmailPill from "../../../../components/aqualex/CustomerEmailPill";
import {MultipleFormFieldArray} from "../../../../components/aqualex/form/MultipleFormFieldArray";
import {SelectFormField} from "../../../../components/aqualex/form/SelectFormField";
import Config from "../../../../helpers/Config";
import {useTranslation} from "../../../../helpers/i18nUtils";

export interface CreateKlantModalMyAqualexProps {
    disabledFieldNames?: string[];
}

export const CreateKlantModalMyAqualex: React.FC<CreateKlantModalMyAqualexProps> = (props) => {
    const {disabledFieldNames} = props;

    const {values, validateField, setFieldValue} = useFormikContext<NieuweKlantModalFormValues>();

    const klantAccountEmails = values["myaqualex:klantAccounts"]?.map(item => item.emailAdres) || [];

    const emailAdressen = Array.from(new Set([
        values["contactgegevens:emailadres"],
        values["contactgegevens:emailadresAanmaningen"],
        values["contactgegevens:emailadresFacturen"]
    ].filter(item => item))) as string[];

    const {t} = useTranslation("klant");

    const validationSchema = Yup.object({
        naam: Yup.string().required(t("Foutmeldingen.naam-is-verplicht",
            "Naam is verplicht") as string)
            .nullable(),
        emailAdres: Yup.string().email(t("Foutmeldingen.geldig-e-mail-adres-is-verplicht",
            "Gelieve een geldig e-mailadres in te vullen") as string).nullable()
            .test("no-duplicates",
                t("Foutmeldingen.bestaand-e-mail-adres",
                   "Dit e-mailadres is reeds toegevoegd") as string, function (value) {
                if (!value) {
                    return true;
                }

                return !klantAccountEmails.includes(value);
            })
    });

    useEffect(() => {
        if (values["myaqualex:klantAccounts"]?.length) {
            validateField("myaqualex:geenKlantAccountMakenReden");

            if (values["myaqualex:geenKlantAccountMakenReden"]) {
                setFieldValue("myaqualex:geenKlantAccountMakenReden", undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values["myaqualex:klantAccounts"]]);

    return (
        <Modal.Body>
            <Form.Row>
                <FormGroup id="myaqualex:klantAccountWelkomMailAction" as={Col} xs={12}>
                    <label>{t("Labels.uitnodigingsmail-versturen", "Uitnodigingsmail versturen")}</label>
                    <RadioFormField name="myaqualex:klantAccountWelkomMailAction" className="font-weight-normal"
                                    disabled={disabledFieldNames?.includes("myaqualex:klantAccountWelkomMailAction")}
                                    options={[
                                        {
                                            label: <span
                                                className="font-weight-normal">
                                                {t("Labels.onmiddellijk-e-mail-versturen", "Onmiddellijk") as string}
                                            </span>,
                                            value: CreateKlantUitnodigingType.DIRECT
                                        },
                                        {
                                            label: <span className="font-weight-normal">
                                                {t("Labels.uitgesteld-e-mail-versturen", "Uitgesteld: bij bevestigen van een bestelbon")}
                                            </span>,
                                            value: CreateKlantUitnodigingType.UITGESTELD
                                        },
                                    ]}/>
                </FormGroup>
                <FormGroup id="myaqualex:klantAccounts" as={Col} xs={12}>
                    <label>{t("Labels.klantaccounts", "Klantaccounts")}</label>
                    <p>{t("CreateKlantModalMyAqualex.voeg-hier-e-mail-adressen-toe",
                        "Voeg hieronder e-mailadressen toe waarvoor klantaccounts moeten voorzien worden.")}</p>

                    <MultipleFormFieldArray name="myaqualex:klantAccounts"
                                            validationSchema={validationSchema}
                                            initialValues={{naam: "", emailAdres: ""}}

                                            itemRenderer={(item) => (
                                                <div>
                                                    <CustomerEmailPill
                                                        emailAdres={item.emailAdres}/> {item.naam}
                                                </div>
                                            )}

                                            beforeFormRenderer={({helpers, setVisible}) => {
                                                if (emailAdressen?.length) {
                                                    return (
                                                        <>
                                                            <Row>
                                                                {emailAdressen.map(item => (
                                                                    <Col key={item} xs="auto">
                                                                        <Badge as={Button} variant="light"
                                                                               className="font-14 btn-light"
                                                                               disabled={klantAccountEmails.includes(item)}
                                                                               onClick={() => {
                                                                                   helpers.setValues({
                                                                                       naam: values["contactgegevens:contactNaam"],
                                                                                       emailAdres: item
                                                                                   });
                                                                                   // helpers.setValues()
                                                                                   setVisible(true);
                                                                               }}>
                                                                            {item}
                                                                        </Badge>
                                                                    </Col>
                                                                ))}
                                                            </Row>

                                                            <small className="text-muted">{t("Labels.snel-toevoegen", "Snel toevoegen")}</small>
                                                        </>
                                                    );
                                                }
                                            }}
                    >
                        <Row>
                            <Col>
                                <SimpleFormField name="naam"
                                                 placeholder={t("Placeholders.naam", "Naam")}/>
                            </Col>
                            <Col>
                                <SimpleFormField name="emailAdres"
                                                 placeholder={t("Placeholders.e-mailadres", "E-mailadres")}/>
                            </Col>
                        </Row>
                    </MultipleFormFieldArray>

                    {!values["myaqualex:klantAccounts"]?.length && (
                        <SelectFormField name="myaqualex:geenKlantAccountMakenReden">
                            <option value="">{t("CreateKlantModalMyAqualex.selecteer-een-reden",
                                "Selecteer een reden indien er geen klantaccount gemaakt wordt")}</option>
                            {Object.keys(GeenKlantAccountMakenReden).map((reden) => (
                                <option key={reden}
                                        value={reden}>{Config.labels.geenKlantAccountMakenReden[reden]}</option>
                            ))}
                        </SelectFormField>
                    )}
                </FormGroup>
            </Form.Row>
        </Modal.Body>
    );
};
