import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from 'react-router-dom';
import {VerzendAdresModel} from "../../redux/verzendadres/types";
import VerzendAdresLabel from "./VerzendAdresLabel";


interface VerzendAdresBoxProps {
    verzendAdres: VerzendAdresModel;
    clickable?: boolean;
}

const UnclickableVerzendAdresBox = ({verzendAdres}: { verzendAdres: VerzendAdresModel }) => {
    return (
        <Row className="mb-1 align-items-center">
            <Col className="col-auto">
                <div className="avatar-sm">
                             <span className="avatar-title bg-primary-lighten text-primary rounded">
                                <i className="dripicons-location"></i>
                            </span>
                </div>
            </Col>
            <Col className="pl-0">
                <VerzendAdresLabel verzendAdres={verzendAdres}/>
                <p className="mb-0">{verzendAdres.adres.adres}, {verzendAdres.adres.postcode} {verzendAdres.adres.plaats}</p>
            </Col>
        </Row>
    );
};

const VerzendAdresBox: React.FC<VerzendAdresBoxProps> = ({verzendAdres, clickable = false}) => {
    if (clickable) {
        return <Link to={`/klant/${verzendAdres.klantId}/verzendadres/${verzendAdres.id}`}>
            <UnclickableVerzendAdresBox verzendAdres={verzendAdres}/>
        </Link>;
    } else {
        return <UnclickableVerzendAdresBox verzendAdres={verzendAdres}/>
    }
};

export default VerzendAdresBox;
