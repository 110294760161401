import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';

// layout HOC
import withLayout from '../components/Layout';
import {allFlattenRoutes as routes} from './index';
import ErrorHandler from "../components/aqualex/ErrorHandler";
import ModalRoot from "../components/aqualex/ModalRoot";
import Config from "../helpers/Config";
import {ViewportBorder} from "../components/aqualex/ViewportBorder";
import {WsStompSessionProvider} from "../components/aqualex/websocket/WsStompSessionProvider";

export const Routes = () => (
    // rendering the router with layout
    <WsStompSessionProvider>
        <BrowserRouter>
            {Config.VIEWPORT_BORDER_COLOR && <ViewportBorder color={Config.VIEWPORT_BORDER_COLOR} />}
            <ErrorHandler/>

            <ModalRoot/>

            <Switch>
                {routes.map((route, index) => {
                    return (
                        !route.children && (
                            <route.route
                                key={index}
                                path={route.path}
                                roles={route.roles}
                                exact={route.exact}
                                component={withLayout(props => {
                                    return <route.component {...props} />;
                                })}></route.route>
                        )
                    );
                })}
            </Switch>
        </BrowserRouter>
    </WsStompSessionProvider>
);
