import React, {ChangeEvent, CSSProperties, ReactNode, useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {LoadingButton} from "./LoadingButton";
import {EditableControlStyle} from "./EditableControlStyle";
import {useTranslation} from "../../helpers/i18nUtils";
import {Spin} from "../Spin";

export interface EditableControlProps {
    onSubmit: (value?: any) => any;
    loading?: boolean;

    initialValue?: any;
    emptyLabel?: ReactNode;

    style?: CSSProperties;
    previewStyle?: CSSProperties;
    className?: string;

    controlStyle?: CSSProperties;
    controlClassName?: string;

    inline?: boolean;

    infoText?: ReactNode;

    valueRenderer?: (value?: any) => ReactNode;
}

export const EditableControl: React.FC<EditableControlProps> = (props) => {
    const {
        onSubmit,
        loading,
        initialValue,
        emptyLabel,
        style,
        className,
        controlStyle,
        controlClassName,
        inline,
        infoText,
        valueRenderer = (value) => value
    } = props;

    const {t} = useTranslation("algemeen");

    const [value, setValue] = useState(initialValue);
    const [editing, setEditing] = useState(false);

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;

        setValue(value);
    };

    const onEditClick = () => {
        setEditing(true);
    };

    const onCancelClick = () => {
        setEditing(false);

        setValue(initialValue);
    };

    const onSaveClick = () => {
        const result = onSubmit(value);

        if (result?.then) {
            result
                .catch(() => {
                    setValue(initialValue);
                })
                .finally(() => {
                    setEditing(false);
                });
        } else {
            setEditing(false);
        }
    };

    useEffect(() => {
        // When initial value changes and we're not editing, change value.
        if (!editing) {
            setValue(initialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    const childrenAsFunction = props.children as (options: { value: any, onChange: Function, setValue: Function, className?: string, style?: CSSProperties; onSaveClick: () => any; }) => ReactNode;

    if (editing) {
        return (
            <Row style={style} className={className}>
                <Col xs={inline ? undefined : 12}>
                    {childrenAsFunction({
                        value,
                        onChange,
                        setValue,
                        className: controlClassName,
                        style: controlStyle,
                        onSaveClick
                    })}
                </Col>

                {infoText && (
                    <Col xs="auto">
                        <Form.Text className="text-muted">
                            {infoText}
                        </Form.Text>
                    </Col>
                )}

                <Col xs={inline ? "auto" : undefined} className={`d-flex justify-content-end ${inline ? "" : "mt-2"}`}>
                    <Button variant="link" onClick={onCancelClick} className="mr-1">
                        {t("Buttons.annuleer", "Annuleer")}
                    </Button>

                    <LoadingButton variant="primary" onClick={onSaveClick} loading={loading}>
                        {t("Buttons.bewaar", "Bewaar")}
                    </LoadingButton>
                </Col>
            </Row>
        );
    }

    return (
        <Spin spinning={loading} className={className}>
            <div style={{...style, ...props.previewStyle}} className="d-flex cursor-pointer" onClick={onEditClick}>
                <EditableControlStyle>
                    {valueRenderer(value)}

                    {!value && <span className="text-muted">{emptyLabel || t("Labels.leeg", "Leeg")}</span>}
                </EditableControlStyle>
            </div>
        </Spin>
    );
};
