import React, {ReactNode} from "react";
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import {useHideModal} from "../../../redux/ui/hooks";
import {FormikHelpers} from "formik/dist/types";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface KlantAkkoordInterventieFormValues {
    verklaring: string;
}

export interface KlantAkkoordInterventieModalProps {
    title: ReactNode;
    description: ReactNode;
    onBevestigHandler: (form: KlantAkkoordInterventieFormValues) => Promise<void>;
}

export const KlantAkkoordInterventieModal: React.FC<KlantAkkoordInterventieModalProps> = (props) => {
    const {title, description, onBevestigHandler} = props;

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const onBevestig = async (values: KlantAkkoordInterventieFormValues, helpers: FormikHelpers<KlantAkkoordInterventieFormValues>) => {
        try {
            helpers.setSubmitting(true);

            await onBevestigHandler(values);
            hideModal();
        } catch (error: any) {

        } finally {
            helpers.setSubmitting(false);
        }
    };

    const initialValues: KlantAkkoordInterventieFormValues = {
        verklaring: ""
    };

    const {t} = useTranslation("interventie");

    return (
        <Modal show onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                validateOnMount // to make validateOnMount, initialValues must be created using useMemo
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    verklaring: Yup.string().required(
                        t("Foutmeldingen.verklaring-is-verplicht", "Verklaring is verplicht") as string)
                })}
            >
                {({
                      handleSubmit,
                      isValid,
                      values
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            {description}
                            <h5>{t("Labels.verklaring", "Verklaring")}</h5>
                            <Form.Row>
                                <FormGroup id="verklaring" as={Col}>
                                    <TextAreaFormField name="verklaring"/>
                                </FormGroup>
                            </Form.Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="light" onClick={onAnnuleer}>
                                {t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}
                            <Button variant="primary" disabled={!isValid}
                                    onClick={() => handleSubmit()}>
                                {t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};

