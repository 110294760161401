import {default as React} from "react";
import TijdSelector from "../TijdSelector";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

type TijdstipFormFieldProps = {
    showButton?: boolean;
    timeIntervals?: number;
}

export const TijdstipFormField: React.FC<FormikFormFieldProps & TijdstipFormFieldProps> = ({
                                                                                               placeholder,
                                                                                               disabled,
                                                                                               showButton,
                                                                                               timeIntervals,
                                                                                               ...props
                                                                                           }) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <TijdSelector {...field}
                              onChange={datum => {
                                  helpers.setValue(datum);
                                  helpers.setTouched(true);
                              }}
                              timeIntervals={timeIntervals}
                              disabled={disabled}
                              placeholder={placeholder}
                              showButton={showButton}
                              isInvalid={!!meta.error}/>
            )}
        </FormikFormField>
    );
};
