import { useEffect } from 'react'
import { useFormikContext, FormikContextType } from 'formik'

export interface FormikEffectProps<T> {
    onChange: (context: FormikContextType<T>) => void
}

export const FormikEffect = <T extends unknown>({ onChange }: FormikEffectProps<T>) => {
    const context = useFormikContext<T>()

    useEffect(() => {
        onChange(context)
    }, [context, onChange])

    return null
}