import {
    BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_FAILURE,
    BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_REQUEST,
    BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_SUCCESS,
    BULK_UPLOAD_DAG_PLANNINGEN_FAILURE,
    BULK_UPLOAD_DAG_PLANNINGEN_REQUEST,
    BULK_UPLOAD_DAG_PLANNINGEN_SUCCESS,
    DAG_PLANNING_CAPACITEIT_PROJECTION_FAILURE,
    DAG_PLANNING_CAPACITEIT_PROJECTION_REQUEST,
    DAG_PLANNING_CAPACITEIT_PROJECTION_SUCCESS,
    DOWNLOAD_MAGAZIJN_RAPPORT,
    FETCH_DAG_PLANNING_BEZOEKEN_FAILURE,
    FETCH_DAG_PLANNING_BEZOEKEN_REQUEST,
    FETCH_DAG_PLANNING_BEZOEKEN_SUCCESS,
    FETCH_DAG_PLANNINGEN_FAILURE,
    FETCH_DAG_PLANNINGEN_REQUEST,
    FETCH_DAG_PLANNINGEN_SUCCESS,
    FETCH_GEPLAND_BEZOEK_BY_ID_FAILURE,
    FETCH_GEPLAND_BEZOEK_BY_ID_REQUEST,
    FETCH_GEPLAND_BEZOEK_BY_ID_SUCCESS,
    FETCH_OPDRACHT_BY_ID_FAILURE,
    FETCH_OPDRACHT_BY_ID_REQUEST,
    FETCH_OPDRACHT_BY_ID_SUCCESS,
    FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_FAILURE,
    FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_REQUEST,
    FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_SUCCESS,
    FETCH_PRESTATIES_BY_OPDRACHTID_FAILURE,
    FETCH_PRESTATIES_BY_OPDRACHTID_REQUEST,
    FETCH_PRESTATIES_BY_OPDRACHTID_SUCCESS,
    GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_FAILURE,
    GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_REQUEST,
    GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_SUCCESS,
    GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_FAILURE,
    GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_REQUEST,
    GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_SUCCESS,
    HERBEZOEK_ONDERHOUD_SERVICE_ORDER_FAILURE,
    HERBEZOEK_ONDERHOUD_SERVICE_ORDER_REQUEST,
    HERBEZOEK_ONDERHOUD_SERVICE_ORDER_SUCCESS,
} from "./constants";
import {ApiError, ErrorInfo} from "../ui/types";
import {
    BehandelServiceOrderMetDynamicsAction,
    BulkUploadDagPlanningenAction,
    DagPlanningCapaciteitAction,
    DagPlanningCapaciteitProjectionModel,
    DagPlanningModel,
    FetchDagPlanningBezoekenAction,
    FetchDagPlanningenAction,
    FetchGeplandBezoekByIdAction,
    FetchOpdrachtByIdAction,
    FetchPrestatiesByOpdrachtIdAction,
    FetchPrestatieVerwachteDuurtijdProjectionAtion,
    GenerateOpdrachtenForOnderhoudenInitieelServiceOrdersAction,
    GenerateServiceOrderOpdrachtenFromServiceOrderNrsAction,
    GeplandBezoekModel,
    HerbezoekOnderhoudServiceOrderAction,
    OpdrachtModel,
    PrestatieModel,
    PrestatieVerwachteDuurtijdProjectionModel,
} from "./types";
import {withLoading} from "../support/actionCreators";
import {action, errorAction} from "../support/action";
import {BezoekAanvraagModel} from "../bezoekaanvraag/types";

export const fetchOpdrachtByIdRequest = (opdrachtId: string, cascade?: boolean): FetchOpdrachtByIdAction => ({
    type: FETCH_OPDRACHT_BY_ID_REQUEST,
    payload: {
        opdrachtId,
    },
    meta: {
        cascade,
    },
});

export const fetchOpdrachtByIdSuccess = (opdracht: OpdrachtModel, opdrachtId: string): FetchOpdrachtByIdAction => ({
    type: FETCH_OPDRACHT_BY_ID_SUCCESS,
    payload: opdracht,
    meta: {opdrachtId},
});

export const fetchOpdrachtByIdFailure = (error: ApiError, opdrachtId: string): FetchOpdrachtByIdAction => ({
    type: FETCH_OPDRACHT_BY_ID_FAILURE,
    payload: error,
    meta: {opdrachtId},
    error: true,
});


export const generateOpdrachtenForOnderhoudInitieelServiceOrdersRequest = (serviceOrderDatumVan: Date, serviceOrderDatumTot: Date, postcode: string): GenerateOpdrachtenForOnderhoudenInitieelServiceOrdersAction => ({
    type: GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_REQUEST,
    payload: {
        serviceOrderDatumVan,
        serviceOrderDatumTot,
        postcode
    },
});

export const generateOpdrachtenForOnderhoudInitieelServiceOrdersSuccess = (serviceOrderDatumVan: Date, serviceOrderDatumTot: Date, postcode: string): GenerateOpdrachtenForOnderhoudenInitieelServiceOrdersAction => ({
    type: GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_SUCCESS,
    payload: {},
    meta: {serviceOrderDatumVan, serviceOrderDatumTot, postcode},
});

export const generateOpdrachtenForOnderhoudInitieelServiceOrdersFailure = (error: ApiError, serviceOrderDatumVan: Date, serviceOrderDatumTot: Date, postcode: string): GenerateOpdrachtenForOnderhoudenInitieelServiceOrdersAction => ({
    type: GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_FAILURE,
    payload: error,
    meta: {serviceOrderDatumVan, serviceOrderDatumTot, postcode},
    error: true,
});


export const generateServiceOrderOpdrachtenFromServiceOrderNrsRequest = (serviceOrderNrs: string[]): GenerateServiceOrderOpdrachtenFromServiceOrderNrsAction => ({
    type: GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_REQUEST,
    payload: {
        serviceOrderNrs
    },
});

export const generateServiceOrderOpdrachtenFromServiceOrderNrsSuccess = (serviceOrderNrs: string[]): GenerateServiceOrderOpdrachtenFromServiceOrderNrsAction => ({
    type: GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_SUCCESS,
    payload: {},
    meta: {serviceOrderNrs},
});

export const generateServiceOrderOpdrachtenFromServiceOrderNrsFailure = (error: ApiError, serviceOrderNrs: string[]): GenerateServiceOrderOpdrachtenFromServiceOrderNrsAction => ({
    type: GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_FAILURE,
    payload: error,
    meta: {serviceOrderNrs},
    error: true,
});


export const behandelServiceOrderMetDynamicsRequest = (serviceOrderBedrijfId: string, serviceOrderPlatformId: string, deleteRegels: boolean, calledFromOpdrachtId?: string): BehandelServiceOrderMetDynamicsAction => ({
    type: BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_REQUEST,
    payload: {
        serviceOrderBedrijfId,
        serviceOrderPlatformId,
        deleteRegels,
    },
    meta: {
        calledFromOpdrachtId
    },
});

export const behandelServiceOrderMetDynamicsSuccess = (serviceOrderBedrijfId: string, serviceOrderPlatformId: string, deleteRegels: boolean): BehandelServiceOrderMetDynamicsAction => ({
    type: BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_SUCCESS,
    payload: {},
    meta: {serviceOrderBedrijfId, serviceOrderPlatformId, deleteRegels},
});

export const behandelServiceOrderMetDynamicsFailure = (error: ApiError, serviceOrderBedrijfId: string, serviceOrderPlatformId: string, deleteRegels: boolean): BehandelServiceOrderMetDynamicsAction => ({
    type: BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_FAILURE,
    payload: error,
    meta: {serviceOrderBedrijfId, serviceOrderPlatformId, deleteRegels},
    error: true,
});

export const herbezoekOnderhoudServiceOrderRequest = (serviceOrderBedrijfId: string, serviceOrderPlatformId: string, opmerking: string, calledFromOpdrachtId?: string): HerbezoekOnderhoudServiceOrderAction => ({
    type: HERBEZOEK_ONDERHOUD_SERVICE_ORDER_REQUEST,
    payload: {
        serviceOrderBedrijfId,
        serviceOrderPlatformId,
        opmerking,
    },
    meta: {
        calledFromOpdrachtId
    },
});

export const herbezoekOnderhoudServiceOrderSuccess = (serviceOrderBedrijfId: string, serviceOrderPlatformId: string, opmerking: string): HerbezoekOnderhoudServiceOrderAction => ({
    type: HERBEZOEK_ONDERHOUD_SERVICE_ORDER_SUCCESS,
    payload: {},
    meta: {serviceOrderBedrijfId, serviceOrderPlatformId, opmerking},
});

export const herbezoekOnderhoudServiceOrderFailure = (error: ApiError, serviceOrderBedrijfId: string, serviceOrderPlatformId: string, opmerking: string): HerbezoekOnderhoudServiceOrderAction => ({
    type: HERBEZOEK_ONDERHOUD_SERVICE_ORDER_FAILURE,
    payload: error,
    meta: {serviceOrderBedrijfId, serviceOrderPlatformId, opmerking},
    error: true,
});


export const fetchPrestatiesByOpdrachtIdRequest = (opdrachtId: string, cascade: boolean): FetchPrestatiesByOpdrachtIdAction => ({
    type: FETCH_PRESTATIES_BY_OPDRACHTID_REQUEST,
    payload: {
        opdrachtId,
    },
    meta: {
        cascade
    }
});

export const fetchPrestatiesByOpdrachtIdSuccess = (prestaties: PrestatieModel[], opdrachtId: string): FetchPrestatiesByOpdrachtIdAction => ({
    type: FETCH_PRESTATIES_BY_OPDRACHTID_SUCCESS,
    payload: prestaties,
    meta: {opdrachtId},
});

export const fetchPrestatiesByOpdrachtIdFailure = (error: ApiError, opdrachtId: string): FetchPrestatiesByOpdrachtIdAction => ({
    type: FETCH_PRESTATIES_BY_OPDRACHTID_FAILURE,
    payload: error,
    meta: {opdrachtId},
    error: true,
});


export const fetchPrestatieVerwachteDuurtijdProjectionRequest = (van: string, tot: string): FetchPrestatieVerwachteDuurtijdProjectionAtion => ({
    type: FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_REQUEST,
    payload: {
        van,
        tot
    },
});

export const fetchPrestatieVerwachteDuurtijdProjectionSuccess = (prestatieVerwachteDuurtijdProjection: PrestatieVerwachteDuurtijdProjectionModel[], van: string, tot: string): FetchPrestatieVerwachteDuurtijdProjectionAtion => ({
    type: FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_SUCCESS,
    payload: prestatieVerwachteDuurtijdProjection,
    meta: {
        van,
        tot,
    },
});

export const fetchPrestatieVerwachteDuurtijdProjectionFailure = (error: ApiError, van: string, tot: string): FetchPrestatieVerwachteDuurtijdProjectionAtion => ({
    type: FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_FAILURE,
    payload: error,
    error: true,
    meta: {
        van,
        tot,
    },
});


export const fetchGeplandBezoekByIdRequest = (geplandBezoekId: string): FetchGeplandBezoekByIdAction => ({
    type: FETCH_GEPLAND_BEZOEK_BY_ID_REQUEST,
    payload: {
        geplandBezoekId,
    },
});

export const fetchGeplandBezoekByIdSuccess = (geplandBezoek: GeplandBezoekModel, geplandBezoekId: string): FetchGeplandBezoekByIdAction => ({
    type: FETCH_GEPLAND_BEZOEK_BY_ID_SUCCESS,
    payload: geplandBezoek,
    meta: {
        geplandBezoekId
    },
});

export const fetchGeplandBezoekByIdFailure = (error: ApiError, geplandBezoekId: string): FetchGeplandBezoekByIdAction => ({
    type: FETCH_GEPLAND_BEZOEK_BY_ID_FAILURE,
    payload: error,
    meta: {
        geplandBezoekId
    },
    error: true,
});


export const fetchDagPlanningenRequest = (vanaf: Date, tot: Date, medewerkerId?: string): FetchDagPlanningenAction => ({
    type: FETCH_DAG_PLANNINGEN_REQUEST,
    payload: {
        medewerkerId,
        vanaf,
        tot,
    },
    meta: {
        cascade: true
    },
});

export const fetchDagPlanningenSuccess = (dagPlanningen: Array<DagPlanningModel>, vanaf: Date, tot: Date, medewerkerId: string): FetchDagPlanningenAction => ({
    type: FETCH_DAG_PLANNINGEN_SUCCESS,
    payload: dagPlanningen,
    meta: {
        medewerkerId,
        vanaf,
        tot,
    },
});

export const fetchDagPlanningenFailure = (error: ApiError, vanaf: Date, tot: Date, medewerkerId: string): FetchDagPlanningenAction => ({
    type: FETCH_DAG_PLANNINGEN_FAILURE,
    payload: error,
    meta: {
        medewerkerId,
        vanaf,
        tot,
    },
    error: true,
});

export const fetchDagPlanningBezoekenRequest = (dagPlanningId: string, onSuccess?: () => void): FetchDagPlanningBezoekenAction => ({
    type: FETCH_DAG_PLANNING_BEZOEKEN_REQUEST,
    payload: {
        dagPlanningId,
        onSuccess
    },
});

export const fetchDagPlanningBezoekenSuccess = (bezoeken: Array<BezoekAanvraagModel>, dagPlanningId: string): FetchDagPlanningBezoekenAction => ({
    type: FETCH_DAG_PLANNING_BEZOEKEN_SUCCESS,
    payload: bezoeken,
    meta: {dagPlanningId},
});

export const fetchDagPlanningBezoekenFailure = (error: ApiError, dagPlanningId: string): FetchDagPlanningBezoekenAction => ({
    type: FETCH_DAG_PLANNING_BEZOEKEN_FAILURE,
    payload: error,
    meta: {dagPlanningId},
    error: true,
});

export const bulkUploadDagPlanningenRequest = (upload: string): BulkUploadDagPlanningenAction => ({
    type: BULK_UPLOAD_DAG_PLANNINGEN_REQUEST,
    payload: {
        upload,
    },
});

export const bulkUploadDagPlanningenSuccess = (): BulkUploadDagPlanningenAction => ({
    type: BULK_UPLOAD_DAG_PLANNINGEN_SUCCESS,
    payload: {},
    meta: {},
});

export const bulkUploadDagPlanningenFailure = (error: ApiError): BulkUploadDagPlanningenAction => ({
    type: BULK_UPLOAD_DAG_PLANNINGEN_FAILURE,
    payload: error,
    meta: {},
    error: true,
});

export const fetchDagPlanningCapaciteitProjectionRequest = (van: string, tot: string): DagPlanningCapaciteitAction => ({
    type: DAG_PLANNING_CAPACITEIT_PROJECTION_REQUEST,
    payload: {
        van,
        tot
    },
});
export const fetchDagPlanningCapaciteitProjectionSuccess = (dagPlanningCapaciteitProjection: DagPlanningCapaciteitProjectionModel[], van: string, tot: string): DagPlanningCapaciteitAction => ({
    type: DAG_PLANNING_CAPACITEIT_PROJECTION_SUCCESS,
    payload: dagPlanningCapaciteitProjection,
    meta: {
        van,
        tot,
    },
});
export const fetchDagPlanningCapaciteitProjectionFailure = (error: ApiError, van: string, tot: string): DagPlanningCapaciteitAction => ({
    type: DAG_PLANNING_CAPACITEIT_PROJECTION_FAILURE,
    payload: error,
    error: true,
    meta: {
        van,
        tot,
    },
});

export const downloadMagazijnRapport = {
    request:
        (van: string, tot: string, medewerkerIds: string[]) => action(DOWNLOAD_MAGAZIJN_RAPPORT.REQUEST, {
            van, tot, medewerkerIds,
        }, withLoading({
            van, tot, medewerkerIds,
        })),
    success:
        (van: string, tot: string, medewerkerIds: string[]) => action(DOWNLOAD_MAGAZIJN_RAPPORT.SUCCESS, {}, withLoading({
            van, tot, medewerkerIds,
        })),
    failure:
        (error: ErrorInfo, van: string, tot: string, medewerkerIds: string[]) => errorAction(DOWNLOAD_MAGAZIJN_RAPPORT.FAILURE, error, withLoading({
            van, tot, medewerkerIds,
        })),
};
