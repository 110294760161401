import * as React from "react";
import GenericBadge from "../GenericBadge";
import Config from "../../../helpers/Config";
import {BezoekAanvraagStatus} from "../../../redux/bezoekaanvraag/types";

interface BezoekStatusBadgeProps {
    status: BezoekAanvraagStatus;
}

const BezoekAanvraagStatusBadge: React.FC<BezoekStatusBadgeProps> = ({status}) => {
    const config = {
        [BezoekAanvraagStatus.NIEUW]: {
            label: Config.labels[BezoekAanvraagStatus.NIEUW],
            color: 'warning-lighten',
        },
        [BezoekAanvraagStatus.TE_PLANNEN]: {
            label: Config.labels[BezoekAanvraagStatus.TE_PLANNEN],
            color: 'info',
        },
        [BezoekAanvraagStatus.GEPLAND]: {
            label: Config.labels[BezoekAanvraagStatus.GEPLAND],
            color: 'success-lighten',
        },
        [BezoekAanvraagStatus.GEANNULEERD]: {
            label: Config.labels[BezoekAanvraagStatus.GEANNULEERD],
            color: 'light',
        },
        [BezoekAanvraagStatus.BEZOCHT]: {
            label: Config.labels[BezoekAanvraagStatus.BEZOCHT],
            color: 'success'
        }
    };

    return <GenericBadge value={status}
                         config={config}/>;
};

export default BezoekAanvraagStatusBadge;
