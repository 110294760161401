import React from "react";
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import {useHideModal} from "../../../redux/ui/hooks";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface HerprobeerOpvolgingItemFormValues {
    toelichting: string;
}

export interface HerprobeerOpvolgingItemModalProps {
    onBevestigHandler: (form: HerprobeerOpvolgingItemFormValues) => void
}

export const HerprobeerOpvolgingItemModal: React.FC<HerprobeerOpvolgingItemModalProps> = (props) => {
    const {onBevestigHandler} = props;

    const {t} = useTranslation("opvolging");

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const onBevestig = (values: HerprobeerOpvolgingItemFormValues) => {
        hideModal();
        onBevestigHandler(values);
    };

    const initialValues: HerprobeerOpvolgingItemFormValues = {
        toelichting: "",
    };

    return (
        <Modal show onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>Verwerk opnieuw</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                validateOnMount
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    toelichting: Yup.string().required(t("Foutmeldingen.toelichting-is-verplicht", "Toelichting is verplicht") as string)
                })}
            >
                {({
                      handleSubmit,
                      isValid
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <h5>{t("Labels.toelichting", "Toelichting")}</h5>
                            <Form.Row>
                                <FormGroup id="toelichting" as={Col}>
                                    <TextAreaFormField name="toelichting"/>
                                </FormGroup>
                            </Form.Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="light" onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}
                            <Button variant="primary" disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};

