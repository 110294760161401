import Config from "./Config";
import {GET} from './api';

const BACKOFFICE_REFDATA_API_BASE_URL = Config.BACKOFFICE_BASE_URL + '/api/refdata';


export default class RefDataApi {
    static fetchAnnulatieRedenen() {
        const uri = BACKOFFICE_REFDATA_API_BASE_URL + '/annulatiereden';

        return GET(uri, 'ophalen van annulatie redenen');
    }

    static fetchDepots() {
        const uri = BACKOFFICE_REFDATA_API_BASE_URL + '/depot';

        return GET(uri, 'ophalen van depots');
    }
}
