import {KlantModel} from '../klant/types';
import {VerzendAdresModel} from '../verzendadres/types';
import {MedewerkerModel} from '../medewerker/types';
import {OpdrachtPlanningModel, Skill} from '../planning/types';
import {ArtikelModel} from '../toestel/types';
import {_Links, ErpStatus} from '../types';
import {ArtikelMetAantalModel} from "../artikel/types";

export type InstallatieFilters = {
    klantNaam?: string;
    klantNr?: string;
    no?: string;
    status?: InstallatieStatusStatus;
    statusNotIn?: InstallatieStatusStatus[];
    verkoper?: MedewerkerModel;
    dynamicsStatus?: VerkoopOrderStatus;
    bevestigd?: boolean;
    verzendAdresPostcode?: string;
    geplandeDatum?: Date;

    productType?: ProductType;

    verzochteLeveringsDatumVan?: Date;
    verzochteLeveringsDatumTot?: Date;
};

export interface InstallatieDossierUpdate {
    algemeneOpmerking?: string;
    interneAlgemeneOpmerking?: string;
    techniekerInstructies?: string;
    waterOpDeLeiding: boolean;
    elektriciteitAanwezig: boolean;
}

export interface TeInstallerenToestelDossierUpdate {
    status: ToestelInstallatieStatus;
    installatieLocatieBeschrijving?: string;
    installatieGebouw?: string;
    installatieVerdieping?: string;
    installatieLokaalNummer?: string;
    stopContactenAanwezig?: number;
    opmerkingBijStopContacten?: string;
    stopContactenBovenAanwezig?: number;
    opmerkingBijStopContactenBoven?: string;
    dubbeleDienstkraanBijToestelAanwezig?: boolean;
    opmerkingBijDubbeleDienstkraanBijToestel?: string;
    schellKraanWarmEnKoudAanwezig?: boolean;
    opmerkingBijSchellKraanWarmEnKoud?: string;
    schellKraanWarmEnKoudBovenAanwezig?: boolean;
    opmerkingBijSchellKraanWarmEnKoudBoven?: string;
    ventilatieAanwezig?: boolean;
    opmerkingBijVentilatie?: string;
    afvoerMetSifonAanwezig?: AfvoerMetSifonAanwezig;
    opmerkingBijAfvoerMetSifon?: string;
    afvoerMetSifonBovenAanwezig?: AfvoerMetSifonAanwezig;
    opmerkingBijAfvoerMetSifonBoven?: string;
    boringInWerkbladDoorKlant?: boolean;
    opmerkingBijBoringInWerkbladDoorKlant?: string;
    wachtbuis40mmAanwezig?: boolean;
    opmerkingBijWachtbuis40mm?: string;
    installatieInReedsAanwezigeKeukenOfKast?: boolean;
    opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast?: string;
    skills?: Array<Skill>;
    duurtijd?: number;
    klantOpmerking?: string;
}

export type InstallatieStatus = {
    status: InstallatieStatusStatus;
    datum: Date;
};

export enum InstallatieStatusStatus {
    IN_VOORBEREIDING = 'IN_VOORBEREIDING',
    UITGESTELD = 'UITGESTELD',
    GEANNULEERD = 'GEANNULEERD',
    PLANNING = 'PLANNING',
    WACHT_OP_UITVOERING = 'WACHT_OP_UITVOERING',
    GEINSTALLEERD = 'GEINSTALLEERD',
    NIET_UITGEVOERD = 'NIET_UITGEVOERD'
}

export enum InstallatieDossierStatus {
    OPGESTART = 'OPGESTART',
    BIJ_KLANT = 'BIJ_KLANT',
    EVALUEREN = 'EVALUEREN',
    KLAAR = 'KLAAR',
}

export interface InstallatieDossierModel {
    id: string;
    installatieId: string;
    verkoopOrder: VerkoopOrderRefModel;
    creatieDatum: Date;
    klant: KlantModel;
    verzendAdres: VerzendAdresModel;
    status: InstallatieDossierStatus;
    algemeneOpmerking: string;
    interneAlgemeneOpmerking: string;
    techniekerInstructies: string;
    waterOpDeLeiding: boolean;
    elektriciteitAanwezig: boolean;
    algemeneBestanden: Array<BestandModel>;
    toestellen: Array<InstallatieDossierToestelModel>;
}

export type BestandModel = {
    id: string;
    type: string;
    naam: string;
    grootte: number;
    mediaType: string;
    downloadUri: string;
    previewUri: string;
};

export enum AfvoerMetSifonAanwezig {
    GEEN_AFVOER_AANWEZIG = 'GEEN_AFVOER_AANWEZIG',
    AFVOER_AANWEZIG = 'AFVOER_AANWEZIG',
    AFVOER_EN_SIFON_AANWEZIG = 'AFVOER_EN_SIFON_AANWEZIG',
}

export enum ToestelInstallatieStatus {
    ONVOLLEDIG = "ONVOLLEDIG",
    VOLLEDIG = "VOLLEDIG",
}


export type InstallatieDossierToestelModel = {
    id: string;
    status: ToestelInstallatieStatus;

    installatieLocatieBeschrijving: string;
    installatieGebouw: string;
    installatieVerdieping: string;
    installatieLokaalNummer: string;

    situatieFotos: BestandModel[];

    stopContactenAanwezig: number;
    opmerkingBijStopContacten?: string;

    stopContactenBovenAanwezig: number;
    opmerkingBijStopContactenBoven?: string;

    dubbeleDienstkraanBijToestelAanwezig: boolean;
    opmerkingBijDubbeleDienstkraanBijToestel?: string;

    schellKraanWarmEnKoudAanwezig: boolean;
    opmerkingBijSchellKraanWarmEnKoud?: string;

    schellKraanWarmEnKoudBovenAanwezig: boolean;
    opmerkingBijSchellKraanWarmEnKoudBoven?: string;

    ventilatieAanwezig: boolean;
    opmerkingBijVentilatie?: string;

    afvoerMetSifonAanwezig?: AfvoerMetSifonAanwezig;
    opmerkingBijAfvoerMetSifon?: string;

    afvoerMetSifonBovenAanwezig?: AfvoerMetSifonAanwezig;
    opmerkingBijAfvoerMetSifonBoven?: string;

    boringInWerkbladDoorKlant: boolean;
    opmerkingBijBoringInWerkbladDoorKlant?: string;

    wachtbuis40mmAanwezig: boolean;
    opmerkingBijWachtbuis40mm?: string;

    klantOpmerking?: string;
    installatieInReedsAanwezigeKeukenOfKast?: boolean;
    opmerkingBijInstallatieInReedsAanwezigeKeukenOfKast?: string;
    teInstallerenToestel: TeInstallerenToestelModel;

    skills: Array<Skill>;
    duurtijd: number;
};

export enum ProductType {
    KRAAN = "KRAAN",
    TOESTEL = "TOESTEL",
    CASA = "CASA",
    ANDERE = "ANDERE",
}

export type IntallatieProfielModel = {
    id: string;
    code: string;
    productType: ProductType;
    stopcontacten: number;
    stopcontactenBoven: number;
    dubbeleDienstkraanBijToestel: boolean;
    schellKraanWarmEnKoud: boolean;
    schellKraanWarmEnKoudBoven: boolean;

    ventilatie: boolean;
    afvoerMetSifon: boolean;
    afvoerMetSifonBoven: boolean;
    boringInWerkbladNodig: boolean;
    wachtbuis40mm: boolean;
    duurtijd: string;
    installatieInReedsAanwezigeKeukenOfKast: boolean;
};

export type VerkoopOrderRefModel = {
    id: string;
    bedrijfId: string;
    nr: string;
    status: string;
    lijnen: VerkoopOrderLijnModel[];
    verkoper?: MedewerkerModel;
    _links: _Links;
}

export interface VerkoopOrderLijnModel {
    lijnNr: number;
    artikel: ArtikelModel;
    aantal: number;
    teVerzendenAantal: number;
    percentageKorting: number;
    vasteKorting: number;
    prijs: number;
    eenheidsCode: string;
    locatie: string;
}

export enum VerkoopOrderStatus {
    OPEN = "OPEN",
    VRIJGEGEVEN = "VRIJGEGEVEN",
    WACHT_OP_GOEDKEURING = "WACHT_OP_GOEDKEURING",
    WACHT_OP_VOORUITBETALING = "WACHT_OP_VOORUITBETALING"
}

export type VerkoopOrderModel = {
    id: string;
    bedrijfId: string;
    nr: string;
    klantId: string;
    referentie: string;
    orderDatum: string;
    verzendDatum: string;
    verzochteLeveringsDatum: string;
    verkoper?: MedewerkerModel;
    lijnen: VerkoopOrderLijnModel[];
    //private Adres facturatieAdres; TODO
    //private Adres verzendAdres; TODO
    status: VerkoopOrderStatus;
    volledigVerzonden: boolean;
    gefactureerd: boolean;

    telefoon?: string;
    email?: string;
    _links: _Links;
}

export interface MinimalInstallatieModel {
    id: string;
    status: InstallatieStatus;
    verkoopOrder: VerkoopOrderModel;
    beschrijving: string;
}

export interface InstallatieModel {
    id: string;
    no: string;
    verkoopOrder: VerkoopOrderModel;
    status: InstallatieStatus;
    creatieDatum: Date;
    klant: KlantModel;
    verzendAdres: VerzendAdresModel;
    beschrijving: string;
    teInstallerenToestellen: Array<TeInstallerenToestelModel>;
    installatieDossier: InstallatieDossierModel; // todo verwijder dit als het niet meer nodig is
    uitgesteldTotDatum: Date;
    statusGeschiedenis: Array<InstallatieStatus>;

    bevestigingsDatum?: string;
    bevestigdDoor?: MedewerkerModel;
    bevestigdTijdstip?: string;

    annulatieReden?: string;
    geannuleerdDoor?: MedewerkerModel;
    geannuleerdTijdstip?: string;

    erpStatus: ErpStatus;
}

export interface TeInstallerenToestelModel {
    id: string;
    lijnNr: number;

    verkoopOrderLijnIndex: number;
    installatieProfiel: IntallatieProfielModel;
    artikelId: string;
    artikelNo: string;
    omschrijving: string;
    teInstallerenToestelDossierId: string;
    opdrachtId?: string;
    opdrachtPlanning?: OpdrachtPlanningModel;
    onderdelen: ArtikelMetAantalModel[];

    verwijderd: boolean;
}
