import React, {ReactNode} from "react";
import {useFormikContext} from "formik";
import {Col, Row} from "react-bootstrap";
import {SimpleFormField} from "./SimpleFormField";
import {ReactSelectFormField} from "./ReactSelectFormField";
import {TransportKostConfiguratieType} from "../../../redux/types";
import {SwitchFormField} from "./SwitchFormField";
import * as Yup from "yup";
import {TFunction} from "i18next";
import {useTranslation} from "../../../helpers/i18nUtils";
import {Translation} from "react-i18next";

const typeOptions = [
    {
        label:
            <Translation>{t => t("klant:TransportkostConfiguratieType.standaardformule", "Standaardformule")}</Translation>,
        value: TransportKostConfiguratieType.STANDAARD
    },
    {
        label: <Translation>{t => t("klant:TransportkostConfiguratieType.vast", "Vast")}</Translation>,
        value: TransportKostConfiguratieType.VAST
    },
    {
        label: <Translation>{t => t("klant:TransportkostConfiguratieType.nooit", "Nooit")}</Translation>,
        value: TransportKostConfiguratieType.NOOIT
    }
];

export interface TransportKostConfiguratieFormProps {
    namePrefix?: string;

    typeLabel?: ReactNode;
    bedragLabel?: ReactNode;

    allowInherit?: boolean;
    inheritLabel?: ReactNode;

    renderAfter?: (values: any) => ReactNode;
}

export const transportKostValidatie = (t: TFunction, namePrefix = "") => ({
    [namePrefix + "transportKostValue"]: Yup.number()
        .nullable()
        .when(namePrefix + "transportKostType", {
            is: transportKostType => transportKostType === TransportKostConfiguratieType.VAST,
            then: Yup.number()
                .nullable()
                .min(0, t("algemeen:TransportKost.Foutmeldingen.bedrag-moet-minstens-0-zijn", "Bedrag moet minstens 0 zijn.") as string)
                .required(t("algemeen:TransportKost.Foutmeldingen.bedrag-is-verplicht", "Bedrag is verplicht.") as string)
        })
});

export const TransportKostConfiguratieForm: React.FC<TransportKostConfiguratieFormProps> = (props) => {
    const {namePrefix = "", allowInherit = true, inheritLabel, typeLabel, bedragLabel, renderAfter} = props;

    const {t} = useTranslation("algemeen");

    const {values} = useFormikContext<any>();
    const inheritEnabled = values.transportKostInherit;

    const renderValue = () => {
        if (values[namePrefix + "transportKostType"] === TransportKostConfiguratieType.VAST) {
            return (
                <Col sm={4}>
                    <SimpleFormField name={`${namePrefix}transportKostValue`} label={bedragLabel}
                                     placeholder={t("Placeholders.bedrag", "Bedrag")} type="number"
                    />
                </Col>
            );
        }
    };

    return (
        <>
            {allowInherit && (
                <Row>
                    <Col>
                        {typeLabel && <label>{typeLabel}</label>}
                        <SwitchFormField id="transportKostInherit"
                                         name="transportKostInherit"
                                         label={inheritLabel ? inheritLabel : t("Labels.overnemen", "Overnemen")}/>
                    </Col>
                </Row>
            )}

            {(!allowInherit || !inheritEnabled) && (
                <Row>
                    <Col>
                        {typeLabel && <label>{typeLabel}</label>}
                        <ReactSelectFormField name={`${namePrefix || ""}transportKostType`}
                                              selectProps={{
                                                  placeholder: t("Placeholders.transportkost", "Transportkost"),
                                                  options: typeOptions
                                              }}
                        />
                    </Col>

                    {renderValue()}
                </Row>
            )}

            {renderAfter?.(values)}
        </>
    );
};
