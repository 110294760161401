import * as React from "react";
import {Badge} from 'react-bootstrap';
import {ReactNode} from "react";


export interface GenericBadgeProps<T extends string | symbol | number> {
    value: T;
    config: Record<T, ConfigEntry>
}

export interface ConfigEntry {
    label: ReactNode;
    color: string;
    icon?: string;
    className?: string;
}

function GenericBadge<T extends string | symbol | number>({value, config}: GenericBadgeProps<T>) {
    const entry = config[value];

    if (!entry) {
        console.warn("GenericBadge: no config for value ", value);
    }


    const color = entry?.color || "light";
    const icon = entry?.icon;
    const label = entry?.label || value;

    return (
        <React.Fragment>
            <Badge variant={color} className={entry?.className}>
                {icon &&
                    <i className={`mdi ${icon} mr-1`}/>
                }
                {label}
            </Badge>
        </React.Fragment>
    );
}

export default GenericBadge;
