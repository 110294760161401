import {default as React} from "react";
import {RootState} from "../../../redux/reducers";
import {connect} from 'react-redux';
import {hideModal} from "../../../redux/ui/actions";
import {Button} from "react-bootstrap";
import VerzendAdressenMap from "../../../components/aqualex/verzendadres/VerzendAdressenMap";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useGetVerzendAdressenByKlantId} from "../../../redux/verzendadres/api";
import {useTranslation} from "../../../helpers/i18nUtils";

type VerzendAdressenMapProps = {
    klantId: string;
    hideModal: () => void;
};

const VerzendAdressenMapModal: React.FC<VerzendAdressenMapProps> = ({
                                                                        klantId,
                                                                        hideModal
                                                                    }) => {

    const {data: verzendAdressen} = useGetVerzendAdressenByKlantId({klantId});

    const onAnnuleer = () => {
        hideModal();
    }

    const {t} = useTranslation("klant");

    return (
        <Modal show={true} onHide={onAnnuleer} size='xl' autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.verzendadressen", "Verzendadressen")}</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <VerzendAdressenMap verzendAdressen={verzendAdressen || []}/>
            </ModalBody>

            <ModalFooter>
                <Button color='light' onClick={onAnnuleer}>{t("algemeen:Buttons.sluiten", "Sluiten")}</Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = (state: RootState, ownProps) => ({
    onBevestigHandler: ownProps.onBevestigHandler,
});

const mapDispatchToProps = {
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerzendAdressenMapModal);
