import {formatDateIso, formatMonth} from "./DateUtils";
import Config from "./Config";

export const maandOverzichtUrl = (datum: Date) => {
    return `/planning/maandoverzicht/${formatMonth(datum)}`
}

export const dagPlanningDetailUrl = (medewerkerId: string, datum: Date | string) => {
    return `/planning/dagplanning/${formatDateIso(datum)}/${medewerkerId}`
}

export const dagPlanningDetailUrl2 = (medewerkerId: string, datum: Date | string) => {
    return `/planning/dagplanning/${datum}/${medewerkerId}`
}

export const dagPlanningDagOverzichtUrl = (datum?: string) => {
    return `/planning/dagplanning/${datum ? formatDateIso(datum) : ""}`;
}

export enum DagPlanningDagOverzichtPageView {
    GRID = "GRID",
    CALENDAR = "CALENDAR",
    MAP = "MAP",
    VOORBEREIDENDE_STAPPEN = "VOORBEREIDENDE_STAPPEN"
}

export const dagPlanningDagOverzichtUrlWithView = (datum: Date, view: DagPlanningDagOverzichtPageView) => {
    return `/planning/dagplanning/${formatDateIso(datum)}?view=${view}`;
}

export const bezoekAanvraagDetailUrl = (bezoekAanvraagId: string) => {
    return `/planning/bezoek/aanvraag/${bezoekAanvraagId}`;
}

export const googleMapsUrl =(lat: number | string, lon: number | string) => {
    return `http://www.google.com/maps/place/${lat},${lon}`;
}

export const downloadKeuringsVerslagenKlantPdfUrl = (klantId:string) => {
    return Config.BACKOFFICE_BASE_URL + `/api/klant/${klantId}/alle-toestel-keuringen.zip`;
}
