import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PartnerLabel } from './PartnerLabel';
import { PartnerModel } from '../../redux/partner/types';

export interface PartnerBoxProps {
    partner: PartnerModel;
}

export const PartnerBox: React.FC<PartnerBoxProps> = (props) => {
    const { partner } = props;

    return (
        <Row className="mb-1 align-items-center">
            <Col className="col-auto">
                <div className="avatar-sm ">
                    <span className="avatar-title bg-primary-lighten text-primary rounded">
                        <i className="dripicons-user" />
                    </span>
                </div>
            </Col>
            <Col className="pl-0">
                <PartnerLabel partner={partner} />
            </Col>
        </Row>
    );
};
