import React, {LegacyRef, useCallback} from "react";
import {
    DagPlanningEntryModel,
    DagPlanningModel,
    DagPlanningStatus,
    DagPlanningStopType
} from "../../../../../redux/planning/types";
import {Button, Card} from "react-bootstrap";
import Tijdstip from "../../../../../components/aqualex/Tijdstip";
import Adres from "../../../../../components/aqualex/Adres";
import {mdiPencil} from "@mdi/js";
import Icon from "@mdi/react";
import {useModal} from "../../../../../redux/ui/hooks";
import {BackOfficeModals} from "../../../../../components/aqualex/ModalRoot";
import {
    DagPlanningStartEindStopAanpassenFormValues,
    DagPlanningStartEindStopAanpassenModalProps
} from "../modal/DagPlanningStartEindStopAanpassenModal";
import {AdresModel} from "../../../../../redux/verzendadres/types";

export interface PlanningStopProps {
    dagPlanning: DagPlanningModel;
    entry: DagPlanningEntryModel;
    stopType: DagPlanningStopType;

    startStopAdresWijzigen?: (startAdres?: AdresModel, startDepot?: string, standaardStartAdres?: boolean, eindAdres?: AdresModel, eindDepot?: string, standaardEindAdres?: boolean) => Promise<any>;

    innerRef?: LegacyRef<any>;
}

export const PlanningStop: React.FC<PlanningStopProps> = (props) => {
    const {dagPlanning, entry, stopType, innerRef, startStopAdresWijzigen, ...rest} = props;

    const {showModal, hideModal} = useModal();

    const adres = entry?.adres;

    const icon = () => {
        switch (stopType) {
            case DagPlanningStopType.START:
                return "mdi-home-map-marker";
            case DagPlanningStopType.EINDE:
                return "mdi-flag-checkered";
            default:
                return "mdi-stop";
        }
    };

    const onBevestigAdresWijziging = useCallback(async (values: DagPlanningStartEindStopAanpassenFormValues) => {
        await startStopAdresWijzigen?.(values.startAdres, values.startDepot?.code, values.startAdresOptie === "STANDAARD", values.eindAdres, values.eindDepot?.code, values.eindAdresOptie === "STANDAARD");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAnnuleerAdresWijziging = useCallback(() => {
        hideModal();
    }, [hideModal]);

    return (
        <div className="timeline-item">

            <i className={`icon mdi ${icon()}`}/>

            <div {...rest} ref={innerRef}>
                <Card className="dagplanning-bezoek-card m-0 shadow-sm">
                    <div className="d-flex align-items-center mt-2 mb-2">
                        <div className="flex-column px-2">
                            <div className="font-weight-semibold">
                                <Tijdstip tijd={entry?.van}/>
                            </div>
                        </div>

                        <div className="flex-fill">
                            <Card.Body className="p-1">
                                <span><Adres adres={adres} toonDepotCode plaatsnaamAparteLijn={false}/></span>
                            </Card.Body>
                        </div>

                        <div className="planning-bezoek-actions">
                            <div className="d-flex align-items-center justify-content-center mr-3">
                                {dagPlanning.status === DagPlanningStatus.ONTWERP && (
                                    <div>
                                        <Button variant="link" className="text-secondary"
                                                size="sm"
                                                onClick={(evt) => {
                                                    evt.stopPropagation();

                                                    showModal(BackOfficeModals.DAG_PLANNING_START_EIND_STOP_AANPASSEN, {dagPlanning, onBevestig: onBevestigAdresWijziging, onAnnuleer: onAnnuleerAdresWijziging} as DagPlanningStartEindStopAanpassenModalProps);
                                                }}>
                                            <Icon path={mdiPencil} size="1.4em"/>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    );
};
