import React, {useCallback, useEffect, useState} from "react";
import {Alert, Button, Col, Form, FormGroup} from "react-bootstrap";
import {ModalBody, ModalFooter} from "../../../components/aqualex/Modal";
import {KlantFormField} from "../../../components/aqualex/form/KlantFormField";
import {VerzendAdresFormField} from "../../../components/aqualex/form/VerzendAdresFormField";
import {BetterSelectFormField} from "../../../components/aqualex/form/BetterSelectFormField";
import {SimpleFormField} from "../../../components/aqualex/form/SimpleFormField";
import {RadioFormField} from "../../../components/aqualex/form/RadioFormField";
import {TransportKostConfiguratieForm} from "../../../components/aqualex/form/TransportKostConfiguratieForm";
import {ExtraCo2FlesBeleidFormSelectField} from "../../../components/aqualex/form/ExtraCo2FlesBeleidFormSelectField";
import {HelpTooltip} from "../../../components/aqualex/HelpTooltip";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import {useFormikContext} from "formik";
import {BestelCo2FormValues} from "./NieuweCo2BestellingModal";
import {KlantModel} from "../../../redux/klant/types";
import {VerzendAdresModel} from "../../../redux/verzendadres/types";
import {Co2ArtikelModel} from "../../../redux/co2bestelling/types";
import {useGetCo2Artikelen} from "../../../redux/artikel/api";
import {MultipleEmailAdresFormFieldArray} from "../../../components/aqualex/form/MultipleEmailAdresFormFieldArray";
import {PlannersWaarschuwing} from "../../../components/aqualex/PlannersWaarschuwing";
import {useTranslation} from "react-i18next";

export interface NieuweCo2BestellingFormikFormProps {
    defaultValues?: Partial<BestelCo2FormValues>;
    onAnnuleer: () => any;
}

export const NieuweCo2BestellingFormikForm: React.FC<NieuweCo2BestellingFormikFormProps> = (props) => {
    const {onAnnuleer, defaultValues} = props;

    const formik = useFormikContext<BestelCo2FormValues>();

    const {
        handleSubmit,
        isValid,
        values,
        setFieldValue,
        setFieldError
    } = formik;

    const [verzendAdresId, setVerzendAdresId] = useState("");
    const [klantId, setKlantId] = useState("");

    const {data: co2Artikelen, isLoading: co2ArtikelenLoading} = useGetCo2Artikelen({
        klantId,
        verzendAdresId
    }, {skip: !klantId || !verzendAdresId});

    const isHervulbaar = values.besteldArtikel?.co2Kenmerken?.hervulbaar;

    const handleKlantGeselecteerd = useCallback((klant: KlantModel) => {
        setKlantId(klant?.id);
        if (klant && klant.verzendAdressen.length === 1) {
            let adres = klant.verzendAdressen[0];
            formik.setFieldValue("verzendAdres", adres);

            setVerzendAdresId(adres.id);

            const emailAdressen = adres.contactEmailAdressen.length ? adres.contactEmailAdressen : klant.contactEmailAdressen;

            formik.setFieldValue("emailAdressen", emailAdressen?.map(item => ({emailAdres: item})));
        } else {
            formik.setFieldValue("verzendAdres", undefined);
            setVerzendAdresId("");
            formik.setFieldValue("emailAdressen", []);
        }
        formik.setFieldValue("besteldArtikel", undefined);
        formik.setFieldValue("aantal", "");
        formik.setFieldValue("extraAantal", "");
    }, [formik]);

    const handleVerzendAdresGeselecteerd = useCallback((adres: VerzendAdresModel) => {
        setVerzendAdresId(adres?.id);

        formik.setFieldValue("besteldArtikel", undefined);
        formik.setFieldValue("aantal", "");
        formik.setFieldValue("extraAantal", "");

        if (adres) {
            const emailAdressen = adres?.contactEmailAdressen?.length ? adres.contactEmailAdressen : formik.values.klant!.contactEmailAdressen;

            formik.setFieldValue("emailAdressen", emailAdressen?.map(item => ({emailAdres: item})));
        } else {
            formik.setFieldValue("emailAdressen", []);
        }
    }, [formik]);

    const handleArtikelGeselecteerd = useCallback((artikel: Co2ArtikelModel) => {
        formik.setFieldValue("aantal", artikel?.co2Kenmerken?.standaardAantal || 0);

        const extraAantal = artikel?.co2Kenmerken?.hervulbaar ? 0 : undefined;
        formik.setFieldValue("extraAantal", extraAantal);
    }, [formik]);

    useEffect(() => {
        if (defaultValues?.klant) {
            handleKlantGeselecteerd(defaultValues.klant);
        }

        if (defaultValues?.verzendAdres) {
            setFieldValue("verzendAdres", defaultValues.verzendAdres);
            handleVerzendAdresGeselecteerd(defaultValues.verzendAdres);
        }

        if (defaultValues?.besteldArtikel && !co2ArtikelenLoading) {
            setFieldValue("besteldArtikel", defaultValues.besteldArtikel);
            handleArtikelGeselecteerd(defaultValues.besteldArtikel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues, co2ArtikelenLoading]);

    const {t} = useTranslation("co2-bestelling");

    return (
        <Form noValidate>
            <ModalBody>
                <h5>{t("Labels.klant-en-verzendadres", "Klant en verzendadres")}</h5>
                <Form.Row>
                    <FormGroup id='klant' as={Col}>
                        <KlantFormField name='klant'
                                        onChange={klant => handleKlantGeselecteerd(klant)}/>
                    </FormGroup>
                </Form.Row>
                <Form.Row>
                    <FormGroup id='verzendAdres' as={Col}>
                        {values.klant &&
                            <VerzendAdresFormField name='verzendAdres' disabled={!values.klant}
                                                   verzendAdressen={values.klant?.verzendAdressen || []}
                                                   onChange={adres => handleVerzendAdresGeselecteerd(adres)}/>
                        }
                    </FormGroup>
                </Form.Row>
                <PlannersWaarschuwing waarschuwing={values.verzendAdres?.plannersWaarschuwing}/>
                <h5>{t("Labels.artikel", "Artikel")}</h5>
                <Form.Row>
                    <FormGroup id='artikel' as={Col}>
                        {values.besteldArtikel && !values.besteldArtikel.inGebruik && (
                            <Alert variant="warning">
                                {t("Foutmeldingen.er-is-een-artikel-geselecteerd-dat-de-klant-nog-niet-in-gebruik-heeft",
                                    "Er is een artikel geselecteerd dat de klant nog niet in gebruik heeft.")}
                            </Alert>
                        )}
                        <BetterSelectFormField name="besteldArtikel"
                                               disabled={!(values.klant && values.verzendAdres)}
                                               items={co2Artikelen?.concat()?.sort((a, b) => (a.co2Kenmerken?.inhoud ?? 0) - (b.co2Kenmerken?.inhoud ?? 0))}
                                               keyGetter={artikel => artikel.id}
                                               formatter={artikel => artikel.nr + " - " + artikel.omschrijving + " " + (artikel.inGebruik ? "(bij klant in gebruik)" : "")}
                                               onChange={artikel => handleArtikelGeselecteerd(artikel)}/>
                    </FormGroup>
                </Form.Row>
                <Form.Row>
                    <FormGroup id='aantal' as={Col}>
                        <h5>{isHervulbaar ?
                            t("Labels.aantal-omruil", "Aantal omruil")
                            : t("Labels.aantal", "Aantal")}</h5>
                        <SimpleFormField type="number" name="aantal" onChange={(e) => {
                            setFieldValue("aantal", e?.target?.value);
                            setFieldError("extraAantal", undefined);
                        }}/>
                    </FormGroup>

                    {isHervulbaar &&
                        <FormGroup id='extraAantal' as={Col}>
                            <h5>{t("Labels.aantal-extra", "Aantal extra")}</h5>
                            <SimpleFormField type="number" name="extraAantal" onChange={(e) => {
                                setFieldValue("extraAantal", e?.target?.value);
                                setFieldError("aantal", undefined);
                            }}/>
                        </FormGroup>
                    }
                </Form.Row>
                <Form.Row>
                    <FormGroup id='aangevraagdeLevertermijn' as={Col} md={6} xs={12}>
                        <h5>{t("Labels.gevraagde-levertermijn", "Gevraagde levertermijn")}</h5>
                        <RadioFormField name="aangevraagdeLevertermijn" options={[
                            {label: t("Labels.levering-bij-onderhoud", "Levering bij onderhoud"), value: "ONDERHOUD"},
                            {label: t("Labels.levering-apart",
                                    "Levering apart"), value: "APART"},
                        ]}/>
                    </FormGroup>
                    <FormGroup id="speciale-afspraken" as={Col} md={6} xs={12}>
                        <h5>{t("Labels.speciale-afspraken", "Speciale afspraken")}</h5>

                        <h6>{t("Labels.transportkost", "Transportkost")}</h6>
                        <TransportKostConfiguratieForm
                            inheritLabel={t("Labels.overnemen-van-verzendadres-of-klant", "Overnemen van verzendadres of klant")}/>

                        <h6>{t("Labels.extra-co2-fles-beleid", "Extra CO₂-fles beleid")}</h6>
                        <ExtraCo2FlesBeleidFormSelectField allowInherit inheritLabel={t("Labels.overnemen-van-klant", "Overnemen van klant")}/>
                    </FormGroup>
                </Form.Row>
                <Form.Row>
                    <FormGroup id='extraAanvraagInfo' as={Col}>
                        <h5>{t("Labels.extra-informatie-ivm-aanvraag", "Extra info i.v.m. aanvraag")}
                            {' '}
                            <HelpTooltip id='tooltip-klantReferentieNr'>
                                {t("NieuweCo2BestellingFormikForm.klantreferentie-tooltip",
                                    "Referentie naar het telefoongesprek of e-mail waarin de klant de bestelling heeft geplaatst.")}
                            </HelpTooltip>
                        </h5>
                        <TextAreaFormField name="extraAanvraagInfo" rows={2}
                                           maxLength={255}
                                           placeholder={""}/>
                    </FormGroup>
                </Form.Row>
                <Form.Row>
                    <FormGroup id='klantReferentieNummer' as={Col}>
                        <h5>{t("Labels.referentie-klant", "Referentie klant")}{' '}
                            <HelpTooltip id='tooltip-klantReferentieNr'>
                                {t("NieuweCo2BestellingFormikForm.klantreferentienummer-tooltip",
                                    "Referentie klant komt overeen met het Dynamics veld 'Extern documentnr'.")}
                            </HelpTooltip>
                        </h5>
                        <SimpleFormField name="klantReferentieNummer"/>
                    </FormGroup>
                </Form.Row>
                <Form.Row>
                    <FormGroup id="emailAdressen" as={Col}>
                        <h5>{t("Labels.e=mail-adressen", "E-mailadressen")}{' '}
                            <HelpTooltip id='tooltip-emailAdressen'>
                                {t("NieuweCo2BestellingFormikForm.e-mailadressen-tooltip",
                                    "Email-adressen die notificaties moeten ontvangen.")}
                            </HelpTooltip>
                        </h5>
                        <MultipleEmailAdresFormFieldArray/>
                    </FormGroup>
                </Form.Row>
            </ModalBody>
            <ModalFooter>
                <Button variant='light' onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                <Button disabled={!isValid}
                        variant='primary'
                        onClick={() => {
                            handleSubmit();
                        }}>{t("algemeen:Buttons.toevoegen", "Toevoegen")}</Button>
            </ModalFooter>
        </Form>
    );
};
