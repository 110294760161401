import {Form} from "react-bootstrap";
import React, {ReactNode} from "react";


interface CheckFilterProps {
    value?: boolean;
    label: ReactNode;
    onChange: (value?: boolean) => void;
}

const CheckFilter: React.FC<CheckFilterProps> = ({value, label, onChange}) => {
    return (
        <Form.Check type="checkbox"
                    id={`checkfilter_${label}`}
                    label={label}
                    checked={!!value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked ? true : undefined)}/>
    );
};

export default CheckFilter;
