import React, {useEffect, useState} from "react";
import {LoadingSkeleton} from "../../../components/aqualex/LoadingSkeleton";
import KlantBox from "../../../components/aqualex/KlantBox";
import {Button, Col, Form as BootstrapForm, FormGroup, InputGroup, Modal, Row} from "react-bootstrap";
import {RadioFormField} from "../../../components/aqualex/form/RadioFormField";
import {KlantFormField} from "../../../components/aqualex/form/KlantFormField";
import {SimpleFormField} from "../../../components/aqualex/form/SimpleFormField";
import {Form, useFormikContext} from "formik";
import {BestelBonModel} from "../../../redux/bestelbon/types";
import {SortOrder} from "../../../redux/support/pagination/types";
import {KoppelKlantAanBestelbonFormValues, KoppelKlantAanBestelbonKlantType} from "./KoppelKlantAanBestelbonModal";
import {useGetKlantByNummer, useSearchKlantSimilar} from "../../../redux/klant/api";
import {Trans, useTranslation} from "react-i18next";

export interface KoppelKlantAanBestelbonModalFormProps {
    bestelBon: BestelBonModel;

    onAnnuleer(): void;
}

export const KoppelKlantAanBestelbonModalForm: React.FC<KoppelKlantAanBestelbonModalFormProps> = (props) => {
    const {bestelBon, onAnnuleer} = props;
    const {values, isValid, setFieldValue} = useFormikContext<KoppelKlantAanBestelbonFormValues>();

    const [zoekNummer, setZoekNummer] = useState<string>();

    const {data: similarKlanten, isLoading: loading} = useSearchKlantSimilar({
        pageable: {
            pageSize: 10,
            pageNumber: 1,
            sortOrder: SortOrder.desc,
            sortField: ""
        },
        filters: {
            bedrijfId: bestelBon.bedrijfId,
            naam: bestelBon.prospect?.naam,
            emailadres: bestelBon.contactEmail,
            btwNummer: bestelBon.btwNummer
        }
    });

    const {
        data: klantByKlantNummer
    } = useGetKlantByNummer({bedrijfId: bestelBon.bedrijfId, klantNummer: zoekNummer!}, {skip: !zoekNummer});

    useEffect(() => {
        setFieldValue("klant", klantByKlantNummer);
    }, [klantByKlantNummer, setFieldValue]);

    const {t} = useTranslation("bestelbon");

    const renderPotentieleMatches = () => {
        if (!bestelBon.prospect) {
            return null;
        }

        return (
            <>
                <h5>{t("Titels.mogelijk-overeenkomstige-klanten", "Mogelijk overeenkomstige klanten")}</h5>
                <p className="text-muted">
                    <Trans t={t}
                           i18nKey="KoppelKlantAanBestelBonModalForm.deze-overeenkomst-wordt-bepaald-op-basis-van-naam-en-e--mailadres">
                        Deze overeenkomstige klanten werden bepaald op basis van de naam en het
                        e-mailadres van het prospect en het ingegeven btw-nummer bij bestelling.
                    </Trans>
                </p>
                <div>
                    <LoadingSkeleton loading={loading} height={70}/>

                    <div style={{maxHeight: 200, overflow: "auto"}}>
                        {similarKlanten?.content.map((klant) => (
                            <div key={klant.id} onClick={() => setFieldValue("klant", klant)}
                                 className="cursor-pointer">
                                <KlantBox klant={klant}/>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    };

    return (
        <Form>
            <Modal.Body>
                <p>
                    <Trans t={t}
                           i18nKey="KoppelKlantAanBestelBonModalForm.de-begunstigde-is-een-prospect">
                        De begunstige is een prospect. U dient het prospect te koppelen aan een bestaande klant uit
                        Dynamics
                        (<strong>{{bedrijfId: bestelBon.bedrijfId}}</strong>) of een nieuwe aan te maken.
                    </Trans>
                </p>

                <RadioFormField
                    name="klantType"
                    label={t("KoppelKlantAanBestelBonModalForm.wenst-u-een-bestaande-klant-te-koppelen-of-een-nieuwe-klant-aan-te-maken",
                        "Wenst u een bestaande klant te koppelen of een nieuwe klant aan te maken?")}
                    options={[
                        {
                            label: t("KoppelKlantAanBestelBonModalForm.nieuwe-klant",
                                "Nieuwe klant"), value: KoppelKlantAanBestelbonKlantType.NEW
                        },
                        {
                            label: t("KoppelKlantAanBestelBonModalForm.bestaande-klant", "Bestaande klant"),
                            value: KoppelKlantAanBestelbonKlantType.EXISTING
                        },
                    ]}
                />

                {values.klantType === KoppelKlantAanBestelbonKlantType.EXISTING && (
                    <>
                        <hr/>

                        <h4>{t('Titels.kies-een-bestaande-klant', 'Kies een bestaande klant')}</h4>
                        <Row className="pl-2 pr-2">
                            <Col className="mr-2">
                                {renderPotentieleMatches()}
                            </Col>

                            <Col>
                                <h5>{t("Titels.klant-opzoeken-in-dynamics", "Opzoeken in Dynamics")}</h5>
                                <Row>
                                    <FormGroup as={Col} sm={12}>
                                        <KlantFormField name="klant" label={t("Labels.klant", "Klant")}/>
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup as={Col} sm={12}>
                                        <BootstrapForm.Label htmlFor="klantNummer">
                                            {t("Labels.klantnummer", "Klantnummer")}
                                        </BootstrapForm.Label>

                                        <InputGroup>
                                            <SimpleFormField name="klantNummer"
                                                             placeholder={t("Placeholders.klantnt",
                                                                 "Klantnr.")}
                                                             showFeedback={false}/>

                                            <InputGroup.Append>
                                                <Button
                                                    onClick={() => setZoekNummer(values.klantNummer)}>
                                                    {t("Buttons.opzoeken", "Opzoeken")}
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </FormGroup>
                                </Row>
                            </Col>
                        </Row>

                        <hr/>

                        {values.klant && (
                            <div className="mt-3">
                                <h4>
                                    {t("Titels.gekozen-klant-om-te-koppelen",
                                        "Gekozen klant om te koppelen:")}
                                </h4>
                                <div className="d-flex align-items-center">
                                    <i className="mdi mdi-check text-success mdi-36px mr-2"/>
                                    <KlantBox klant={values.klant}/>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onAnnuleer}>
                    {t("algemeen:Buttons.annuleer", "Annuleer")}
                </Button>{" "}

                <Button variant="primary" disabled={!isValid} type="submit">
                    {t("algemeen:Buttons.bevestig",
                        "Bevestig")}
                </Button>
            </Modal.Footer>
        </Form>
    );
};
