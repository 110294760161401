import {Form} from "react-bootstrap";
import React, {CSSProperties} from "react";
import {useTranslation} from "../../../helpers/i18nUtils";


interface TextFilterProps {
    value?: string;
    placeholder?: string;
    placeholderI18nKey?: string;
    onChange: (value: string) => void;
    className?: string;
    style?: CSSProperties;
}

const TextFilter: React.FC<TextFilterProps> = ({value, placeholder,placeholderI18nKey, onChange, className, style}) => {

    const {t} = useTranslation();

    return (
        <Form.Control type="text"
                      className={className}
                      style={style}
                      placeholder={placeholder || (placeholderI18nKey && t(placeholderI18nKey) as string)}
                      value={value || ''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                      onKeyUp={e => {
                          if (e.key === 'Enter') {
                              e.preventDefault();
                          }
                      }}/>
    );
};

export default TextFilter;
