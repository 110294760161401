import {CLEAR_ERROR, HANDLE_ERROR, HIDE_MODAL, RESET_UI_STATE, SHOW_MODAL,} from "./constants";
import {ApiError, ErrorAction, ModalAction, OtherAction} from "./types";

export const handleError = (error: ApiError): ErrorAction => ({
    type: HANDLE_ERROR,
    payload: error,
    error: false,
});

export const clearError = (error: ApiError): ErrorAction => ({
    type: CLEAR_ERROR,
    payload: error,
    error: false,
});

export const showModal = (modalType: string, modalProps: {}): ModalAction => ({
    type: SHOW_MODAL,
    payload: {
        modalType: modalType,
        modalProps: modalProps,
    },
});

export const hideModal = () => ({
    type: HIDE_MODAL,
});

export const resetUIState = (): OtherAction => ({
    type: RESET_UI_STATE,
});

