import * as React from "react";
import {OpdrachtModel, OpdrachtType} from "../../../redux/planning/types";
import ToestelOnderhoudTypeBadge from "../opdracht/OnderhoudTypeBadge";
import {Badge} from "react-bootstrap";
import Co2BestellingSummary from "../../../pages/co2bestelling/Co2BestellingSummary";
import {AccessoireBestellingSummary} from "../../../pages/accesoirebestelling/AccessoireBestellingSummary";
import {InterventieTaakTypeBadge} from "../../../pages/interventie/InterventieTaakTypeBadge";
import {TruncateText} from "../TruncateText";
import ToestelLabel from "../toestel/ToestelLabel";
import {ToestelLabelContainer} from "../toestel/ToestelLabelContainer";


interface OpdrachtSummaryProps {
    opdracht: OpdrachtModel
}

const OpdrachtSummary: React.FC<OpdrachtSummaryProps> = ({opdracht}) => {
    switch (opdracht.type) {
        case OpdrachtType.CO2_LEVERING:
            return <Co2BestellingSummary co2Bestelling={opdracht.co2Bestelling}/>;
        case OpdrachtType.ONDERHOUD_SERVICE_ORDER:
            return <>
                <div><ToestelLabel toestel={opdracht.toestel}/></div>
                <Badge variant='primary-lighten'>{opdracht.serviceOrderSoort}</Badge>
                <br/>
                <span className='text-muted'>{opdracht.serviceOrderNr}</span>
            </>;
        case OpdrachtType.TOESTEL_ONDERHOUD:
            return (
                <div>
                    <div><ToestelLabelContainer toestelId={opdracht.toestelId}/></div>
                    <ToestelOnderhoudTypeBadge toestelOnderhoudType={opdracht.onderhoudType}/>
                </div>
            );
        case OpdrachtType.TOESTEL_INSTALLATIE:
            return (
                <>
                    <span><strong>{opdracht.teInstallerenToestel?.artikelNo}</strong> {opdracht.teInstallerenToestel?.omschrijving}</span>
                    <br/>
                    <span className="text-muted">{opdracht.installatie?.verkoopOrder?.nr}</span>
                </>
            );
        case OpdrachtType.ACCESSOIRE_LEVERING:
            return <AccessoireBestellingSummary accessoireBestelling={opdracht.accessoireBestelling}/>;
        case OpdrachtType.TOESTEL_INTERVENTIE:
            return (
                <div>
                    {opdracht.interventie && <div><ToestelLabel toestel={opdracht.interventie.operationeelToestel}/></div>}
                    <div>{opdracht.interventie?.nummer} <InterventieTaakTypeBadge type={opdracht.interventie?.taakType}/></div>
                    <div>
                        <TruncateText text={opdracht.interventie?.taakOmschrijving} max={75}/>
                    </div>
                </div>
            );
    }
};

export default OpdrachtSummary;
