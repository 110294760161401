import React, {useMemo} from "react";
import * as Yup from "yup";
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {Formik, FormikHelpers} from "formik";
import {DagPlanningModel} from "../../../../../redux/planning/types";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../../components/aqualex/Modal";
import {useTranslation} from "../../../../../helpers/i18nUtils";
import {DepotFormField} from "../../../../../components/aqualex/form/DepotFormField";
import {RadioFormField} from "../../../../../components/aqualex/form/RadioFormField";
import {AdresModel} from "../../../../../redux/verzendadres/types";
import {AdresAutocompleteFormField} from "../../../../../components/aqualex/form/AdresAutocompleteFormField";
import {DepotModel} from "../../../../../redux/refdata/types";
import {LoadingButton} from "../../../../../components/aqualex/LoadingButton";
import {SimpleCheckField} from "../../../../../components/aqualex/form/SimpleCheckField";
import Adres from "../../../../../components/aqualex/Adres";

export interface DagPlanningStartEindStopAanpassenModalProps {
    dagPlanning: DagPlanningModel;

    onBevestig: (values: DagPlanningStartEindStopAanpassenFormValues) => Promise<void>;
    onAnnuleer: () => void;
}

export interface DagPlanningStartEindStopAanpassenFormValues {
    startAdresWijzigen?: boolean;
    startAdres?: AdresModel;
    startDepot?: DepotModel;
    startAdresOptie?: string // "DEPOT" | "VRIJ_ADRES";

    eindAdresWijzigen?: boolean;
    eindAdres?: AdresModel;
    eindDepot?: DepotModel;
    eindAdresOptie?: string // "DEPOT" | "VRIJ_ADRES";
}

export const DagPlanningStartEindStopAanpassenModal: React.FC<DagPlanningStartEindStopAanpassenModalProps> = (props) => {
    const {dagPlanning, onBevestig, onAnnuleer} = props;

    const {t} = useTranslation("planning");

    const onSubmit = async (values: DagPlanningStartEindStopAanpassenFormValues, helpers: FormikHelpers<any>) => {
        try {
            await onBevestig({
                ...values,
                startAdres: values.startAdresWijzigen && !values.startDepot ? values.startAdres : undefined,
                startDepot: values.startAdresWijzigen ? values.startDepot : undefined,

                eindAdres: values.eindAdresWijzigen && !values.eindDepot ? values.eindAdres : undefined,
                eindDepot: values.eindAdresWijzigen ? values.eindDepot : undefined
            });

            props.onAnnuleer();
        } finally {
            helpers.setSubmitting(false);
        }
    };

    const validationSchema = useMemo(() => Yup.object<DagPlanningStartEindStopAanpassenFormValues>({
        startAdresOptie: Yup.string().required(),
        eindAdresOptie: Yup.string().required()
    }), []);

    const initialValues = useMemo(() => {
        return {
            startAdresWijzigen: false,
            startAdres: dagPlanning.startAdres,
            startAdresOptie: "STANDAARD",
            eindAdresWijzigen: false,
            eindAdres: dagPlanning.eindAdres,
            eindAdresOptie: "STANDAARD"
        } as DagPlanningStartEindStopAanpassenFormValues;
    }, [dagPlanning]);

    const options = [
        {
            label: t("Labels.standaardwaarde", "Standaardwaarde"),
            value: "STANDAARD"
        },
        {
            label: t("Labels.depot-uit-de-lijst", "Depot uit de lijst"),
            value: "DEPOT"
        },
        {
            label: t("Labels.vrij-adres", "Vrij adres"),
            value: "VRIJ_ADRES"
        }
    ];

    return (
        <Modal show={true} onHide={onAnnuleer} size="lg" autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Labels.dagplanning-start-en-eindadres-aanpassen", "Dagplanning start- en eindadres aanpassen")}</ModalTitle>
            </ModalHeader>

            <Formik validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={initialValues}>
                {({
                      handleSubmit,
                      isSubmitting,
                      values
                  }) => (
                    <Form noValidate>
                        <ModalBody>
                            <Row>
                                <Col xs={12}>
                                    <h5>{t("Labels.startadres", "Startadres")}</h5>

                                    <Adres adres={dagPlanning.startAdres}/>
                                    <div className="mt-1 mb-2">
                                        <SimpleCheckField name="startAdresWijzigen" label={t("Labels.startadres-wijzigen", "Startadres wijzigen")}/>
                                    </div>

                                    {values.startAdresWijzigen && (
                                        <FormGroup id="startAdres" className="pl-2">
                                            <div className="mb-3">
                                                <RadioFormField name="startAdresOptie"
                                                                className="font-weight-normal" options={options}/>
                                            </div>

                                            {values.startAdresOptie === "DEPOT" && <DepotFormField name="startDepot"/>}
                                            {values.startAdresOptie === "VRIJ_ADRES" &&
                                                <AdresAutocompleteFormField name="startAdres"/>}
                                        </FormGroup>
                                    )}

                                    <hr/>
                                </Col>

                                <Col xs={12}>
                                    <h5>{t("Labels.eindadres", "Eindadres")}</h5>

                                    <Adres adres={dagPlanning.eindAdres}/>
                                    <div className="mt-1 mb-2">
                                        <SimpleCheckField name="eindAdresWijzigen" label={t("Labels.eindadres-wijzigen", "Eindadres wijzigen")}/>
                                    </div>

                                    {values.eindAdresWijzigen && (
                                        <FormGroup id="eindUur" className="pl-2">
                                            <div className="mb-3">
                                                <RadioFormField name="eindAdresOptie"
                                                                className="font-weight-normal" options={options}/>
                                            </div>

                                            {values.eindAdresOptie === "DEPOT" &&
                                                <DepotFormField name="eindDepot"/>}
                                            {values.eindAdresOptie === "VRIJ_ADRES" &&
                                                <AdresAutocompleteFormField name="eindAdres"/>}
                                        </FormGroup>
                                    )}
                                </Col>
                            </Row>
                        </ModalBody>

                        <ModalFooter>
                            <Button variant="light"
                                    onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}
                            <LoadingButton variant="primary" loading={isSubmitting}
                                           onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</LoadingButton>
                        </ModalFooter>
                    </Form>)}
            </Formik>

        </Modal>
    );
};
