import React from "react";
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {FormikHelpers} from "formik/dist/types";
import {useTranslation} from "react-i18next";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import {useHideModal} from "../../../redux/ui/hooks";

export interface AnnuleerInstallatieFormValues {
    reden: string;
}

export interface AnnuleerInstallatieModalProps {
    onBevestigHandler: (form: AnnuleerInstallatieFormValues) => Promise<void> | void
}

export const AnnuleerInstallatieModal: React.FC<AnnuleerInstallatieModalProps> = (props) => {
    const {onBevestigHandler} = props;

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const {t} = useTranslation("installatie");

    const onBevestig = async (values: AnnuleerInstallatieFormValues, helpers: FormikHelpers<AnnuleerInstallatieFormValues>) => {
        try {
            helpers.setSubmitting(true);

            await onBevestigHandler(values);
            hideModal();
        } catch (error: any) {

        } finally {
            helpers.setSubmitting(false);
        }
    };

    const initialValues: AnnuleerInstallatieFormValues = {
        reden: ""
    };

    const redenIsVerplichtLabel = t("AnnuleerInstallatieModal.reden-is-verplicht", "Reden is verplicht");

    return (
        <Modal show onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("AnnuleerInstallatieModal.installatie-annuleren",
                    "Installatie annuleren")}</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                validateOnMount // to make validateOnMount, initialValues must be created using useMemo
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    reden: Yup.string().required(redenIsVerplichtLabel)
                })}
            >
                {({
                      handleSubmit,
                      isValid,
                      values
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <p>{t("AnnuleerInstallatieModal.ben-je-zeker", "Ben je zeker? Dit annuleert deze installatie. De bijhorende VOR wordt niet aangepast.")}</p>

                            <h5>{t("AnnuleerInstallatieModal.Headers.reden", "Reden")}</h5>

                            <Form.Row>
                                <FormGroup id="reden" as={Col}>
                                    <TextAreaFormField name="reden"/>
                                </FormGroup>
                            </Form.Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="light" onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}
                            <Button variant="primary" disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};

