import React, {ReactNode} from "react";
import {ReactSelectFormField} from "./ReactSelectFormField";
import Config from "../../../helpers/Config";
import {ExtraCo2FlesBeleid} from "../../../redux/co2bestelling/types";
import {SwitchFormField} from "./SwitchFormField";
import {useFormikContext} from "formik";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface ExtraCo2FlesBeleidFormSelectFieldProps {
    allowInherit?: boolean;
    inheritLabel?: ReactNode;
    typeLabel?: ReactNode;

    fieldName?: string;
}

export const ExtraCo2FlesBeleidFormSelectField: React.FC<ExtraCo2FlesBeleidFormSelectFieldProps> = (props) => {
    const {
        allowInherit,
        inheritLabel,
        typeLabel,
        fieldName = "extraCo2FlesBeleid"
    } = props;

    const {t} = useTranslation("co2-bestelling");

    const {values} = useFormikContext<{ extraCo2FlesBeleidInherit: boolean }>();

    return (
        <>
            {allowInherit && (
                <SwitchFormField id="extraCo2FlesBeleidInherit"
                                 name="extraCo2FlesBeleidInherit"
                                 label={inheritLabel ? inheritLabel : "Overnemen"}/>
            )}
            {!values.extraCo2FlesBeleidInherit && (
                <ReactSelectFormField name={fieldName} label={typeLabel}
                                      selectProps={{
                                          placeholder: t("Placeholder.extraCo2FlesBeleid", "Extra CO₂-fles beleid"),
                                          options: Object.keys(Config.labels.extraCo2FlesBeleid).filter(key => key !== ExtraCo2FlesBeleid.INHERIT).map(key => ({
                                              label: Config.labels.extraCo2FlesBeleid[ExtraCo2FlesBeleid[key]],
                                              value: ExtraCo2FlesBeleid[key]
                                          }))
                                      }}
                />
            )}
        </>
    );
};
