import {Pageable, Pagination, paginationKey} from "./pagination";
import {Page} from "./pagination/types";

export function selectPage<TEntity>(pagination: Pagination, filters: any, pageable: Pageable, mapper: (string) => TEntity, loading = true): Page<TEntity> {
    const pageNumber = pageable.pageNumber;

    const paginationForFiltersAndKey = pagination[paginationKey(filters, pageable)];

    if (!paginationForFiltersAndKey || !paginationForFiltersAndKey.pages[pageNumber]) {
        return {
            content: [],
            number: pageNumber,
            size: 0,
            totalElements: 0,
            loading
        };
    }
    const rawPage = paginationForFiltersAndKey.pages[pageNumber];

    return {
        content: rawPage.items?.map(mapper) || [],
        number: pageNumber,
        size: rawPage.items?.length || 0,
        totalElements: paginationForFiltersAndKey.totalItemCount,
        loading: rawPage.loading,
    };
}
