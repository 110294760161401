import * as React from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from 'react-router-dom';
import ToestelLocatie from "./ToestelLocatie";
import {OperationeelToestelModel} from "../../../redux/toestel/types";
import ToestelLabel from "./ToestelLabel";


interface UnclickableToestelBoxProps {
    toestel: OperationeelToestelModel;
}

const UnclickableToestelBox: React.FC<UnclickableToestelBoxProps> = ({toestel}) => {
    return (
        <Row className="mb-1 align-items-center">
            <Col className="col-auto">
                <div className="avatar-sm">
                    <span className="avatar-title bg-primary-lighten text-primary rounded">
                        <i className="dripicons-flag"></i>
                    </span>
                </div>
            </Col>
            <Col className="pl-0">
                <ToestelLabel toestel={toestel}/>
                <p className="mb-0"><ToestelLocatie locatie={toestel.locatie}/></p>
            </Col>
        </Row>
    );
};


interface ToestelBoxProps {
    toestel: OperationeelToestelModel;
    clickable: boolean;
}

const ToestelBox: React.FC<ToestelBoxProps> = ({toestel, clickable}) => {
    if (clickable) {
        return <Link to={`/toestel/${toestel.id}`}>
            <UnclickableToestelBox toestel={toestel}/>
        </Link>;
    } else {
        return <UnclickableToestelBox toestel={toestel}/>
    }
};

export default ToestelBox;
