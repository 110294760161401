import * as React from "react";
import {OperationeelToestelModel} from "../../../redux/toestel/types";
import {useTranslation} from "../../../helpers/i18nUtils";

interface ToestelOmschrijvingProps {
    toestel: OperationeelToestelModel;
}

const ToestelOmschrijving: React.FC<ToestelOmschrijvingProps> = ({toestel}) => {
    const {t} = useTranslation("toestel");

    return (
        <>
            {toestel.omschrijving || toestel.artikel?.omschrijving ||
                <span className="text-muted">{t("algemeen:Labels.geen", "Geen")}</span>}
        </>
    );
};

export default ToestelOmschrijving;
