import React, {useCallback, useEffect} from "react";
import {Button, Col, Form, ListGroup, Row} from "react-bootstrap";
import Icon from "@mdi/react";
import {mdiClose, mdiPlus, mdiTrashCan} from "@mdi/js";
import {FormikContextType, FormikProps, useFormikContext} from "formik";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface MultipleFormFieldArrayInnerFormProps {
    name: string;
    parentForm: FormikContextType<any>;

    visible: boolean;
    itemRenderer: (item: any) => React.ReactNode;
    beforeFormRenderer?: (props: { helpers: FormikProps<any>; setVisible: (visible: boolean) => void }) => React.ReactNode;

    setVisible(visible: boolean);

    remove(index: number): void;
}

export const MultipleFormFieldArrayInnerForm: React.FC<MultipleFormFieldArrayInnerFormProps> = (props) => {
    const {name, parentForm, visible, setVisible, remove, itemRenderer, beforeFormRenderer} = props;

    const {t} = useTranslation("algemeen");

    const form = useFormikContext<any>();

    const items = parentForm.values[name] || [];

    const onValidate = useCallback(async () => {
        const values = form.values;

        if (Object.values(values).some((item: unknown) => !!item)) {
            return {
                [name]: t("Foutmeldingen.onopgeslagen-wijzigingen", "Je hebt nog onopgeslagen wijzigingen. Klik op 'Toevoegen'.")
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values]);

    useEffect(() => {
        parentForm.setFieldValue(`${name}:validate`, onValidate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values]);

    return (
        <>
            {beforeFormRenderer?.({helpers: form, setVisible})}

            <ListGroup className="mb-2">
                {items.map((item, index) => (
                    <ListGroup.Item key={index}
                                    className="d-flex align-items-center justify-content-between px-2 py-0">
                        <div>
                            {itemRenderer(item)}
                        </div>

                        <Button variant="link"
                                onClick={() => remove(index)}>
                            <Icon path={mdiTrashCan} size={0.9}/>
                        </Button>
                    </ListGroup.Item>
                ))}

                <ListGroup.Item>
                    {!visible && (
                        <Button variant="link" onClick={() => setVisible(true)}>
                            <Icon path={mdiPlus} size={"1em"}/> {t("Knoppen.toevoegen", "Toevoegen")}
                        </Button>
                    )}

                    {visible && (
                        <div onKeyUp={e => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                form.submitForm();
                            }
                        }}>
                            <Row>
                                <Col>
                                    {props.children}
                                </Col>
                                <Col xs="auto">
                                    <Button onClick={() => form.submitForm()} className="mr-1">
                                        {t("Knoppen.toevoegen", "Toevoegen")}
                                    </Button>

                                    <Button type="button" variant="light"
                                            onClick={() => {
                                                form.resetForm();
                                                parentForm.setFieldError(name, undefined);
                                                setVisible(false);
                                            }}>
                                        <Icon path={mdiClose} size="1em"/>
                                    </Button>
                                </Col>
                            </Row>

                            {parentForm.errors[name] && <Form.Control.Feedback type="invalid"
                                                                               style={{display: "block"}}>{parentForm.errors[name]}</Form.Control.Feedback>}
                        </div>
                    )}
                </ListGroup.Item>
            </ListGroup>
        </>
    );
};
