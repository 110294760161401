import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import Select, {FocusEventHandler} from 'react-select';
import {MedewerkerFilters, MedewerkerModel} from "../../redux/medewerker/types";
import {useSearchMedewerkers} from "../../redux/medewerker/api";
import {SortOrder} from "../../redux/support/pagination";
import {MedewerkerBox} from "./medewerker/MedewerkerBox";
import Medewerker from "./connected/Medewerker";
import {useTranslation} from "../../helpers/i18nUtils";

interface MedewerkerSelectorProps {
    geselecteerdeMedewerker?: MedewerkerModel | MedewerkerModel[];
    geselecteerdeMedewerkerId?: string | string[];
    medewerkerChanged: (medewerker) => any;
    searchFilters?: MedewerkerFilters;
    isInvalid?: boolean;
    disabled?: boolean;
    onBlur?: FocusEventHandler;
    name?: string;
    isClearable?: boolean;
    placeholder?: string;
    clearAfterSelect?: boolean;
    isMulti?: boolean;

    filter?: (medewerker: MedewerkerModel) => boolean;
}

const searchMedewerkerForSelectorPagination = {
    pageNumber: 1,
    pageSize: 1000,
    sortField: 'naam',
    sortOrder: SortOrder.asc
};

const MedewerkerSelector: React.FC<MedewerkerSelectorProps> = ({
                                                                   geselecteerdeMedewerker,
                                                                   geselecteerdeMedewerkerId,
                                                                   medewerkerChanged,
                                                                   searchFilters = {},
                                                                   isInvalid,
                                                                   onBlur,
                                                                   name,
                                                                   isClearable,
                                                                   disabled,
                                                                   placeholder,
                                                                   clearAfterSelect = false,
                                                                   isMulti,
                                                                   filter
                                                               }) => {
    const {t} = useTranslation("klant");

    const {data, isFetching: loading} = useSearchMedewerkers({
        filters: {
            ...searchFilters,
        },
        pageable: searchMedewerkerForSelectorPagination,
    });

    const medewerkers = useMemo(() => {
        let content = data?.content || [];

        if (filter) {
            content = content.filter(filter);
        }

        return content;
    }, [data, filter]);

    const [selectie, setSelectie] = useState(geselecteerdeMedewerker);

    useEffect(() => {
        if (geselecteerdeMedewerker) {
            setSelectie(geselecteerdeMedewerker);
        } else if (geselecteerdeMedewerkerId) {
            if (Array.isArray(geselecteerdeMedewerkerId)) {
                setSelectie(medewerkers.filter((item) => geselecteerdeMedewerkerId.includes(item.id)));
            } else {
                setSelectie(medewerkers.find((item) => item.id === geselecteerdeMedewerkerId));
            }
        } else {
            setSelectie(undefined);
        }

    }, [geselecteerdeMedewerker, geselecteerdeMedewerkerId, medewerkers]);

    return (
        <Select
            inputId={name}
            name={name}
            onBlur={onBlur}
            isMulti={isMulti}

            // hack om z-index correct te krijgen
            menuPortalTarget={document.body}
            styles={{menuPortal: provided => ({...provided, zIndex: 9999})}}

            className={"react-select-container" + (isInvalid ? " is-invalid" : "")}
            classNamePrefix='react-select'
            value={selectie || null}
            isLoading={loading}
            options={medewerkers || []}
            onChange={(medewerker => {
                if (clearAfterSelect) {
                    setSelectie(undefined);
                } else {
                    setSelectie(medewerker);
                }
                medewerkerChanged(medewerker);
                // setNaamFilter("");
            })}
            placeholder={placeholder || t("Placeholders.typ-om-medewerkers-te-zoeken", "Typ om medewerkers te zoeken...")}

            getOptionValue={medewerker => medewerker.naam}
            formatOptionLabel={(medewerker, meta) => {
                if (meta.context === "value") {
                    return <Medewerker medewerker={medewerker} clickable={false}/>;
                }

                return <MedewerkerBox medewerker={medewerker} clickable={false}/>;
            }}

            isDisabled={disabled}

            isClearable={isClearable !== undefined ? isClearable : true}
            onInputChange={(inputValue, reason) => {
                if (reason.action === 'set-value' || reason.action === 'menu-close' || reason.action === 'input-blur') {
                    // set-value, menu-close en input-blur triggeren reset van input value, en dus ook een 'reset' van de dropdown list
                    return;
                }
                // debouncedSetNaamFilter(inputValue);
            }}
            noOptionsMessage={() => t("Labels.geen-medewerkers-gevonden", "Geen medewerkers gevonden")}
        />
    )
};


export default MedewerkerSelector;
