import {Pageable} from "../../../redux/support/pagination";
import React, {ReactNode, useCallback, useEffect, useState} from "react";
import FilteredOverviewTable, {Filter} from "./FilteredOverviewTable";
import {TableColumn} from "./SimpleTable";
import {useDeepCompareEffect} from "use-deep-compare";
import debounce from "lodash.debounce";
import {Page} from "../../../redux/support/pagination/types";
import {dequal} from "dequal";
import {usePrevious} from "../../../helpers/hooks/usePrevious";

export type HandleUpdateFilter = (name: string, value: any) => void;
export type FilterField<TFilters> = (filterValues: TFilters, handleUpdateFilter: HandleUpdateFilter) => Filter;

export interface QuickFilterFieldOptions {
    active: boolean;

    onClick(): void;
}

export interface QuickFilterField<TFilters> {
    name: string;
    group?: string;
    defaultActivated?: boolean;

    onActivated?(): void;

    onDeactivated?(): void;

    render(filterValues: TFilters, options: QuickFilterFieldOptions);
}

export type QuickFilterFieldCreator<TFilters> = (updateFilter: HandleUpdateFilter) => QuickFilterField<TFilters>;

export interface FilteredAndPagedTableProps<TEntity, TFilters> {
    itemsLabel: string,
    columns: Array<TableColumn<TEntity, any>>,
    defaultSorted?: any,
    page: Page<TEntity>;
    loadData: (filters: TFilters, pageable: Pageable) => void;
    onRowClicked?: (item: TEntity) => void;
    defaultFilters?: TFilters;
    filters: TFilters;

    initialQuickFilters?: Record<string, string | undefined>;
    onQuickFiltersChanged?: (quickFilters: Record<string, string | undefined>) => any;

    pageable: Pageable;
    selectionMode?: 'single' | 'multiple';
    selected?: Array<any>;
    onRowSelected?: (item: TEntity, selected: boolean) => void;
    expandRow?: (item: TEntity) => ReactNode;
    isExpandable?: (item: TEntity) => boolean;

    quickFilters?: QuickFilterFieldCreator<TFilters>[];
    primaryFilterFields?: FilterField<TFilters>[];
    secondaryFilterFields?: FilterField<TFilters>[];
}

const buildSelectRow = <TEntity, >(selectionMode: "single" | "multiple" | undefined, selected?: Array<string>, onRowSelected?: (item: TEntity, isSelect: boolean) => void) => {
    if (!selectionMode || !onRowSelected) {
        return;
    }

    return {
        mode: selectionMode === 'single' ? 'radio' : 'checkbox',
        selected,
        clickToSelect: true,
        hideSelectAll: false,
        onSelect: (item: TEntity, isSelect: boolean, rowIndex: number, e: any) => {
            onRowSelected?.(item, isSelect);
        },
        onSelectAll: (isSelect: boolean, rows: TEntity[], e: any) => {
            if (onRowSelected) {
                for (const row of rows) {
                    onRowSelected(row, isSelect);
                }
            }
        },
        style: {backgroundColor: '#727cf540'},// TODO should be config
    };
}

const defaultGroupName = "default";

const FilteredAndPagedTable = <TEntity, TFilters, >(props: FilteredAndPagedTableProps<TEntity, TFilters>) => {
    const {
        itemsLabel,
        columns,
        quickFilters = [],
        primaryFilterFields = [],
        secondaryFilterFields = [],
        defaultSorted,
        page,
        loadData,
        defaultFilters = {} as TFilters,
        filters: initialFilters,
        initialQuickFilters,
        onQuickFiltersChanged,
        pageable,
        onRowClicked,
        selectionMode,
        selected,
        onRowSelected,
        expandRow,
        isExpandable
    } = props;

    const [filters, setFilters] = useState<TFilters>(initialFilters || defaultFilters);
    const [activeQuickFilters, setActiveQuickFilters] = useState<Record<string, string | undefined>>(initialQuickFilters || {});

    // eslint-disable-next-line
    const debouncedLoadData = useCallback(debounce(loadData, 300), [loadData]);

    const previousFilters = usePrevious(filters);
    useEffect(() => {
        if (!previousFilters) {
            debouncedLoadData(filters, pageable);
        } else if (previousFilters && !dequal(previousFilters, filters)) {
            debouncedLoadData(filters, {...pageable!, pageNumber: 1});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const updateFilter = (name: string, value: any) => {
        setFilters((prev) => {
            const {[name]: prevValue, ...rest}: any = prev;

            if (value != null) {
                return {
                    [name]: value,
                    ...rest,
                };
            } else {
                return rest;
            }
        });

        setActiveQuickFilters({});
    };

    const updateFilterQuick = useCallback((name: string, value: any) => {
        setFilters((prev) => ({
            ...prev,
            [name]: value
        } as TFilters));
    }, [setFilters]);

    const handleTableChange = (type, pagination) => {
        if (pageable.pageSize !== pagination.sizePerPage ||
            pageable.pageNumber !== pagination.page ||
            pageable.sortOrder !== pagination.sortOrder ||
            pageable.sortField !== pagination.sortField) {
            debouncedLoadData(filters, {
                pageNumber: pagination.page,
                pageSize: pagination.sizePerPage,
                sortField: pagination.sortField,
                sortOrder: pagination.sortOrder
            });
        }
    };

    const resetFilters = () => {
        setActiveQuickFilters({});
        setFilters(defaultFilters);
    };

    const removeFilter = (filter: QuickFilterField<any>) => {
        setActiveQuickFilters((prev) => ({...prev, [filter.group || defaultGroupName]: undefined}));

        filter.onDeactivated?.();
    };

    const addQuickFilter = (filter: QuickFilterField<any>) => {
        setActiveQuickFilters((prev) => ({...prev, [filter.group || defaultGroupName]: filter.name}));

        filter.onActivated?.();
    };

    useEffect(() => {
        onQuickFiltersChanged?.(activeQuickFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeQuickFilters]);

    const quickFiltersFields = quickFilters
        .map((filter) => filter(updateFilterQuick));

    useDeepCompareEffect(() => {
        if (Object.keys(activeQuickFilters).length === 0) {
            const quickFiltersDefaultActivated = quickFiltersFields.filter(item => item.defaultActivated);

            quickFiltersDefaultActivated.forEach(addQuickFilter);
        }
    }, [quickFiltersFields]);

    const quickFilterElements = quickFiltersFields
        .map((filter) => ({
            group: filter.group,
            element: <React.Fragment key={filter.name}>
                {filter.render(filters, {
                    active: activeQuickFilters[filter.group || defaultGroupName] === filter.name,
                    onClick: () => {
                        const group = filter.group || defaultGroupName;

                        if (activeQuickFilters[group]) {
                            if (activeQuickFilters[group] === filter.name) {
                                removeFilter(filter);
                            } else {
                                const activeGroupFilter = quickFiltersFields.find((item) => item.name === activeQuickFilters[group]);
                                activeGroupFilter && removeFilter(activeGroupFilter);

                                addQuickFilter(filter);
                            }
                        } else {
                            addQuickFilter(filter);
                        }
                    }
                })
                }
            </React.Fragment>
        }));

    return (
        <FilteredOverviewTable itemsLabel={itemsLabel}
                               page={page.number}
                               loading={page.loading}

                               items={page.content}

                               pageSize={page.size}
                               totalSize={page.totalElements}

                               onTableChange={handleTableChange}
                               rowClicked={onRowClicked}

                               resetFilters={resetFilters}
                               quickFiltersWithGroup={quickFilterElements}
                               primaryFilters={primaryFilterFields.map(filterField => filterField(filters, updateFilter))}
                               secondaryFilters={secondaryFilterFields.map(filterField => filterField(filters, updateFilter))}

                               columns={columns}
                               defaultSorted={defaultSorted}
                               selectRow={buildSelectRow(selectionMode, selected, onRowSelected)}
                               expandRow={expandRow}
                               isExpandable={isExpandable}
        />
    );
};

export default FilteredAndPagedTable;
