import {ReduxAction, ReduxAction_legacy} from "../support/types";

export type ErrorAction = {
    readonly type: string,
    readonly payload?: any,
    readonly meta?: never,
    readonly error?: boolean
};

export type ModalAction = {
    readonly type: string,
    readonly payload?: any,
    readonly meta?: never,
};

export type OtherAction = {
    readonly type: string,
    readonly payload?: never,
    readonly meta?: never,
};

export type UIActionTypes =
    ErrorAction |
    ModalAction |
    OtherAction |
    ReduxAction<any, any>|
    ReduxAction_legacy<any>;

export type ApiError = {
    readonly status: number;
    readonly message: string;
    readonly cause?: ErrorInfo;
}

export enum ErrorType {
    DOMAIN = "DOMAIN",
    ERP = "ERP",
    GENERIC = "GENERIC",
    ENTITY_NOT_FOUND = "ENTITY_NOT_FOUND",
    ACCESS_DENIED = "ACCESS_DENIED"
}

export type ErrorInfo = {
    readonly identifier: string;
    readonly type: ErrorType;
    readonly url: string;
    readonly message: string;
    readonly timestamp: string;
    readonly technicalMessage: string;
    readonly code?: string;
    readonly context?: Record<string, string>;
}

export interface ModalItem<P> {
    type: string;
    props: P;
}

export type UIState = {
    errors: Array<ApiError>,
    unauthorizedError: boolean,
    modalStack: ModalItem<any>[];
    pageState: {
        [Key: string]: any,
    },
    loading: {
        [Key: string]: boolean,
    },
};

