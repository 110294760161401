import * as React from 'react';
import { useCallback, useState } from 'react';
import Select, { components } from 'react-select';
import { PartnerBox } from '../PartnerBox';
import { SortOrder } from '../../../redux/support/pagination';
import debounce from 'lodash.debounce';
import { PartnerLabel } from '../PartnerLabel';
import { useSearchPartners } from '../../../redux/partner/api';
import { PartnerModel } from '../../../redux/partner/types';

interface PartnerSelectorProps {
    geselecteerdePartner?: PartnerModel;
    partnerChanged: (partner) => any;
    isInvalid?: boolean;
    disabled?: boolean;
    placeholder?: string;
}

const SingleValue = props => (
    <components.SingleValue {...props}>
        <PartnerLabel partner={props.data}/>
    </components.SingleValue>
);

const pageable = {
    pageNumber: 1,
    pageSize: 20,
    sortField: "naam",
    sortOrder: SortOrder.asc
};

export const PartnerSelector = ({geselecteerdePartner, partnerChanged, isInvalid, placeholder, disabled}: PartnerSelectorProps) => {
    const [filter, setFilter] = useState("");

    const {data, isLoading: loading} = useSearchPartners({
        filters: {
            naamOfNummer: filter
        },
        pageable: pageable,
    });

    const partners = data?.content || [];

    // eslint-disable-next-line
    const handleInputChange = useCallback(
        debounce(inputValue => setFilter(inputValue), 300),
        []
    );

    return (
        <Select
            className={"react-select-container" + (isInvalid ? " is-invalid" : "")}
            classNamePrefix="react-select"
            value={geselecteerdePartner || null}
            isLoading={loading}
            loadingMessage={() => "Laden..."}
            filterOption={() => true} // completely bypass filtering
            options={partners}
            onChange={partner => partnerChanged(partner)}
            isDisabled={disabled}
            placeholder={placeholder || "Typ om partners te zoeken op naam/nummer..."}
            getOptionValue={partner => partner.id}
            formatOptionLabel={partner => <PartnerBox partner={partner}/>}
            components={{SingleValue}}
            isClearable
            onInputChange={(inputValue, reason) => {
                if (reason.action === "set-value" || reason.action === "menu-close" || reason.action === "input-blur") {
                    // set-value, menu-close en input-blur triggeren reset van input value, en dus ook een "reset" van de dropdown list
                    return;
                }

                // if (reason.action === "input-change") {
                handleInputChange(inputValue);
                // }
            }}
            noOptionsMessage={() => "Geen partners gevonden"}
        />
    )
};
