import React, {CSSProperties, LegacyRef} from "react";
import {DagPlanningEntryModel} from "../../../../../redux/planning/types";
import moment from "moment";
import {LoadingSkeleton} from "../../../../../components/aqualex/LoadingSkeleton";
import {useTranslation} from "../../../../../helpers/i18nUtils";
import {Afstand} from "../../../../../components/aqualex/Afstand";

export interface PlanningRitProps {
    entry: DagPlanningEntryModel;
    innerRef: LegacyRef<any>;

    placeholder?: boolean;
    style?: CSSProperties;
}

export const PlanningRit: React.FC<PlanningRitProps> = (props) => {
    const {entry, innerRef, placeholder, style, ...rest} = props;

    const {language} = useTranslation("planning");

    const minuten = moment(entry.tot, "HH:mm").diff(moment(entry.van, "HH:mm"), "minutes");
    const omschrijving = entry.afstand !== undefined ? <Afstand afstandInMeter={entry.afstand}/> : "";

    return (
        <div className="timeline-item">
            <div className="d-flex align-items-center justify-content-between px-3" {...rest} style={style}
                 ref={innerRef}>
                <div className="d-flex align-items-center">
                    <i className="mdi mdi-car font-24 mr-2"/> {!placeholder ? omschrijving :
                    <LoadingSkeleton width={50}/>}
                </div>

                <div>
                    {!placeholder && minuten ? <small className="text-muted">{Intl.NumberFormat(language, {style: "unit", unit: "minute"}).format(minuten)}</small> :
                        <LoadingSkeleton width={75}/>}
                </div>
            </div>
        </div>
    );
};
