import React, {useState} from "react";
import {FieldArray, Formik, FormikHelpers, FormikProps, useFormikContext} from "formik";
import {FormikErrors} from "formik/dist/types";
import {MultipleFormFieldArrayInnerForm} from "./MultipleFormFieldArrayInnerForm";

export interface MultipleFormFieldArrayProps {
    name: string;
    validationSchema?: any;
    validate?: (values: any) => void | object | Promise<FormikErrors<any>>;
    initialValues?: any;

    itemRenderer: (item: any) => React.ReactNode;
    beforeFormRenderer?: (props: { helpers: FormikProps<any>; setVisible: (visible: boolean) => void }) => React.ReactNode;
}

export const MultipleFormFieldArray: React.FC<MultipleFormFieldArrayProps> = (props) => {
    const {name, validationSchema, validate, initialValues, itemRenderer, beforeFormRenderer} = props;

    const parentForm = useFormikContext<{ [k: string]: any[] }>();

    const [visible, setVisible] = useState(false);

    return (
        <>
            <FieldArray name={name}>
                {({push, remove}) => {
                    const onSubmit = (values: any, helpers: FormikHelpers<any>) => {
                        push(values);
                        parentForm.setFieldError(name, undefined);
                        helpers.resetForm();
                        setVisible(false);
                    };

                    return (
                        <>
                            <Formik
                                initialValues={initialValues} onSubmit={onSubmit}
                                validationSchema={validationSchema} validate={validate}>
                                {() => {
                                    return (
                                        <MultipleFormFieldArrayInnerForm name={name} parentForm={parentForm}
                                                                         remove={remove} itemRenderer={itemRenderer}
                                                                         beforeFormRenderer={beforeFormRenderer}
                                                                         visible={visible}
                                                                         setVisible={setVisible}>{props.children}</MultipleFormFieldArrayInnerForm>
                                    );
                                }}
                            </Formik>
                        </>
                    );
                }}
            </FieldArray>
        </>
    );
};
