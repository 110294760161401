import {default as React} from "react";
import MedewerkerSelector from "../MedewerkerSelector";
import {MedewerkerFilters, MedewerkerModel} from "../../../redux/medewerker/types";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

export interface MedewerkerFormFieldProps {
    searchFilters?: MedewerkerFilters;
    isClearable?: boolean;
    useId?: string;
    clearAfterSelect?: boolean;
    isMulti?: boolean;
    filter?: (medewerker: MedewerkerModel) => boolean;
}

export const MedewerkerFormField: React.FC<FormikFormFieldProps & MedewerkerFormFieldProps> = ({
                                                                                                   searchFilters,
                                                                                                   isClearable,
                                                                                                   useId,
                                                                                                   disabled,
                                                                                                   clearAfterSelect,
                                                                                                   isMulti, filter,
                                                                                                   ...props
                                                                                               }) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <MedewerkerSelector {...field}
                                    useId={useId}
                                    filter={filter}
                                    clearAfterSelect={clearAfterSelect}
                                    isMulti={isMulti}
                                    disabled={disabled}
                                    isClearable={isClearable}
                                    searchFilters={searchFilters}
                                    geselecteerdeMedewerker={!useId && field.value}
                                    geselecteerdeMedewerkerId={useId && field.value}
                                    medewerkerChanged={medewerker => {
                                        if (isMulti && medewerker) {
                                            helpers.setValue(useId ? medewerker.map(item => item.id) : medewerker);
                                        } else {
                                            helpers.setValue(useId ? medewerker?.id : medewerker);
                                        }

                                        helpers.setTouched(true);
                                    }}
                                    isInvalid={!!meta.error}/>
            )}
        </FormikFormField>
    );
};
