import {handleError} from "./actions";
import {isRejectedWithValue} from "@reduxjs/toolkit";
import i18next from "i18next";

export const errorHandlingMiddleware = store => next => action => {


    if (isRejectedWithValue(action)) {

        let message = action.meta.baseQueryMeta.omschrijving ?
            i18next.t("algemeen:Foutmeldingen.fout-opgetreden-bij",
                "Fout opgetreden bij {{oorzaak}}",
                {oorzaak: i18next.t(action.meta.baseQueryMeta.omschrijving)})
            : i18next.t("algemeen:Foutmeldingen.fout-opgetreden", "Fout opgetreden")


        store.dispatch(handleError({
            cause: action.payload.data,
            status: action.payload.status,
            message: message
        }));

    } else if (action.error && action.error?.name !== "ConditionError") { // nodig voor rtk-query
        store.dispatch(handleError(action.payload));
    }

    next(action);
};
