import * as React from "react";
import {OperationeelToestelModel} from "../../../redux/toestel/types";
import UitDienstIndicator from "./UitDienstIndicator";
import ToestelOmschrijving from "./ToestelOmschrijving";

interface ToestelLabelProps {
    toestel: OperationeelToestelModel;

    toonUitDienst?: boolean;
}

const ToestelLabel: React.FC<ToestelLabelProps> = ({toestel, toonUitDienst = true}) => (
    <>
        <strong>{toestel.bedrijfId} {toestel.nr}</strong> <ToestelOmschrijving toestel={toestel}/> {toonUitDienst &&
        <UitDienstIndicator toestel={toestel}/>}</>
);

export default ToestelLabel;
