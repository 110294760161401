// @flow
import {LOGIN_USER_SUCCESS, LOGOUT_USER} from './constants';

import {AuthState, PrincipalModel} from "./types";
import {RootState} from "../reducers";
import {AuthAction} from "../actions";

const INIT_STATE: AuthState = {
    loggedInUser: undefined,
    loading: false,
};

export const Auth = (state: AuthState = INIT_STATE, action: AuthAction): AuthState => {
    switch (action.type) {
        case LOGIN_USER_SUCCESS:
            return { ...state, loggedInUser: action.payload, loading: false, error: null };
        case LOGOUT_USER:
            return { ...state, loggedInUser: undefined, username: null};
        default:
            return { ...state };
    }
};

export const selectLoggedInUser = (state: RootState): PrincipalModel | undefined => {
    return state.Auth.loggedInUser;
};

export const selectUserIsLoggedIn = (state: RootState): boolean => {
    return state.Auth.loggedInUser !== undefined;
};
