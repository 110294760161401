import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import Select, {components} from "react-select";

import ArtikelLabel from "./ArtikelLabel";
import debounce from "lodash.debounce";
import {ArtikelModel} from "../../redux/toestel/types";
import {useSearchArtikelen} from "../../redux/artikel/api";
import {SortOrder} from "../../redux/support/pagination";
import {ArtikelFilters} from "../../redux/artikel/types";
import ArtikelBox from "./artikel/ArtikelBox";
import ArtikelNrOmschrijving from "./connected/ArtikelNrOmschrijving";
import {useDeepCompareEffect} from "use-deep-compare";
import {useFilteredAndPagedSearch} from "../../redux/support/hooks";
import {useTranslation} from "../../helpers/i18nUtils";
import {FiltersAndPageable} from "../../redux/support/uiSlice";


interface ArtikelSelectorProps {
    bedrijfId: string;

    geselecteerdArtikel?: ArtikelModel;
    artikelChanged: (artikel) => any;
    isInvalid?: boolean;
    disabled?: boolean;
    placeholder?: string;

    isMulti?: boolean;
    toonGeblokkeerdeArtikelen?: boolean;
}

const SingleValue = props => (
    <components.SingleValue {...props}>
        <ArtikelLabel artikel={props.data}/>
    </components.SingleValue>
);

const ArtikelSelector: React.FC<ArtikelSelectorProps> = (props) => {
    const {bedrijfId, geselecteerdArtikel, artikelChanged, isInvalid, placeholder, disabled, isMulti, toonGeblokkeerdeArtikelen} = props;

    const {t} = useTranslation("artikel");

    const [filter, setFilter] = useState("");

    const filters: ArtikelFilters = useMemo(() => ({
        bedrijfId,
        nrOfOmschrijving: filter,
        inclusiefGeblokkeerd: toonGeblokkeerdeArtikelen || false
    }), [bedrijfId, filter, toonGeblokkeerdeArtikelen]);
    const pageable = {pageNumber: 1, pageSize: 20, sortField: "nr", sortOrder: SortOrder.asc};

    const initialFiltersAndPageable: FiltersAndPageable<ArtikelFilters> = {
        filters: {
            bedrijfId,
            nrOfOmschrijving: filter,
            inclusiefGeblokkeerd: toonGeblokkeerdeArtikelen || false
        },
        pageable: {
            pageNumber: 1,
            pageSize: 20,
            sortField: "nr",
            sortOrder: SortOrder.desc,
        },
        activeQuickFilters: {}
    };

    const {
        page,
        updateFiltersAndPageable
    } = useFilteredAndPagedSearch<ArtikelModel, ArtikelFilters>("artikelSelector.search", useSearchArtikelen, initialFiltersAndPageable);

    // eslint-disable-next-line
    const debouncedLoadData = useCallback(debounce(updateFiltersAndPageable, 300), [updateFiltersAndPageable]);

    useDeepCompareEffect(() => {
        debouncedLoadData({filters, pageable});
    }, [filters, pageable]);

    const handleInputChange = (inputValue) => setFilter(inputValue);

    return (
        <Select
            className={"react-select-container" + (isInvalid ? " is-invalid" : "")}
            classNamePrefix="react-select"
            value={geselecteerdArtikel || null}
            isLoading={page?.loading}
            loadingMessage={() => "Laden..."}
            filterOption={() => true} // completely bypass filtering
            options={page?.content || []}
            onChange={artikel => artikelChanged(artikel)}
            isDisabled={disabled}
            placeholder={placeholder || t("Placeholders.typ-om-artikelen-te-zoeken", "Typ om artikelen te zoeken...")}
            getOptionValue={artikel => artikel.id}
            formatOptionLabel={(artikel, meta) => {
                if (meta.context === "menu") {
                    // Item in dropdown
                    return <ArtikelBox artikel={artikel} clickable={false}/>;
                }

                // Item geselecteerd (bij multiple selection)
                return <ArtikelNrOmschrijving artikel={artikel}/>;
            }}
            components={{SingleValue}}
            isClearable
            onInputChange={(inputValue, reason) => {
                if (reason.action === "input-change") {
                    handleInputChange(inputValue);
                }
            }}
            noOptionsMessage={() => t("Labels.geen-artikelen-gevonden", "Geen artikelen gevonden")}
            isMulti={isMulti}
        />
    )
};

export default ArtikelSelector;
