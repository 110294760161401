import {DependencyList, EffectCallback} from "react";
import {useDeepCompareEffect, useDeepCompareMemo} from "use-deep-compare";

export function useDeepEffect(effect: EffectCallback, deps: DependencyList = []) {
    return useDeepCompareEffect(effect, deps);
}

export function useDeepMemo<TValue>(memoFn: () => TValue, deps: DependencyList = []): TValue {
    return useDeepCompareMemo(memoFn, deps);
}
