import {
    behandelServiceOrderMetDynamicsFailure,
    behandelServiceOrderMetDynamicsRequest,
    behandelServiceOrderMetDynamicsSuccess,
    bulkUploadDagPlanningenFailure,
    bulkUploadDagPlanningenRequest,
    bulkUploadDagPlanningenSuccess,
    downloadMagazijnRapport,
    fetchDagPlanningBezoekenFailure,
    fetchDagPlanningBezoekenRequest,
    fetchDagPlanningBezoekenSuccess,
    fetchDagPlanningCapaciteitProjectionFailure,
    fetchDagPlanningCapaciteitProjectionRequest,
    fetchDagPlanningCapaciteitProjectionSuccess,
    fetchDagPlanningenFailure,
    fetchDagPlanningenRequest,
    fetchDagPlanningenSuccess,
    fetchGeplandBezoekByIdFailure,
    fetchGeplandBezoekByIdRequest,
    fetchGeplandBezoekByIdSuccess,
    fetchOpdrachtByIdFailure,
    fetchOpdrachtByIdRequest,
    fetchOpdrachtByIdSuccess,
    fetchPrestatiesByOpdrachtIdFailure,
    fetchPrestatiesByOpdrachtIdRequest,
    fetchPrestatiesByOpdrachtIdSuccess,
    fetchPrestatieVerwachteDuurtijdProjectionFailure,
    fetchPrestatieVerwachteDuurtijdProjectionRequest,
    fetchPrestatieVerwachteDuurtijdProjectionSuccess,
    generateOpdrachtenForOnderhoudInitieelServiceOrdersFailure,
    generateOpdrachtenForOnderhoudInitieelServiceOrdersRequest,
    generateOpdrachtenForOnderhoudInitieelServiceOrdersSuccess,
    generateServiceOrderOpdrachtenFromServiceOrderNrsFailure,
    generateServiceOrderOpdrachtenFromServiceOrderNrsRequest,
    generateServiceOrderOpdrachtenFromServiceOrderNrsSuccess,
    herbezoekOnderhoudServiceOrderFailure,
    herbezoekOnderhoudServiceOrderRequest,
    herbezoekOnderhoudServiceOrderSuccess,
} from './actions';
import {
    BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_REQUEST,
    BULK_UPLOAD_DAG_PLANNINGEN_REQUEST,
    DAG_PLANNING_CAPACITEIT_PROJECTION_REQUEST,
    DOWNLOAD_MAGAZIJN_RAPPORT,
    FETCH_DAG_PLANNING_BEZOEKEN_REQUEST,
    FETCH_DAG_PLANNINGEN_REQUEST,
    FETCH_GEPLAND_BEZOEK_BY_ID_REQUEST,
    FETCH_OPDRACHT_BY_ID_REQUEST,
    FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_REQUEST,
    FETCH_PRESTATIES_BY_OPDRACHTID_REQUEST,
    GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_REQUEST,
    GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_REQUEST,
    HERBEZOEK_ONDERHOUD_SERVICE_ORDER_REQUEST,
} from "./constants";
import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects';
import DagPlanningApi from "../../helpers/DagPlanningApi";
import {DagPlanningModel, GeplandBezoekModel} from "./types";
import GeplandBezoekApi from "../../helpers/GeplandBezoekApi";
import PrestatieApi from "../../helpers/PrestatieApi";
import OpdrachtApi from "../../helpers/OpdrachtApi";
import {saveFile} from "../../helpers/download";
import MagazijnRapportApi from "../../helpers/MagazijnRapportApi";

export function* fetchOpdrachtByIdSaga({
                                           payload: {opdrachtId},
                                           meta: {cascade}
                                       }: ReturnType<typeof fetchOpdrachtByIdRequest>) {
    try {
        const result = yield call(OpdrachtApi.fetchOpdrachtById, opdrachtId);
        yield put(fetchOpdrachtByIdSuccess(result, opdrachtId));

        if (cascade) {
            //bijhorende entities fetchen
            //yield put(fetchPrestatiesByOpdrachtIdRequest(opdrachtId, true));
            // yield put(fetchKlantByIdRequest(result.klantId, false));
            // yield put(fetchVerzendAdressenByKlantIdRequest(result.klant.id));
            // yield put(fetchServicesByKlantIdRequest(result.klant.id));
            // yield put(fetchToestellenByKlantIdRequest(result.klantId));
        }

    } catch (err: any) {
        yield put(fetchOpdrachtByIdFailure(err, opdrachtId));
    }
}


export function* generateOpdrachtenForOnderhoudInitieelServiceOrdersSaga({
                                                                             payload: {
                                                                                 serviceOrderDatumVan,
                                                                                 serviceOrderDatumTot,
                                                                                 postcode
                                                                             }
                                                                         }: ReturnType<typeof generateOpdrachtenForOnderhoudInitieelServiceOrdersRequest>) {
    try {
        yield call(OpdrachtApi.generateOpdrachtenForOnderhoudInitieelServiceOrdersRequest, serviceOrderDatumVan, serviceOrderDatumTot, postcode);
        yield put(generateOpdrachtenForOnderhoudInitieelServiceOrdersSuccess(serviceOrderDatumVan, serviceOrderDatumTot, postcode));
    } catch (err: any) {
        yield put(generateOpdrachtenForOnderhoudInitieelServiceOrdersFailure(err, serviceOrderDatumVan, serviceOrderDatumTot, postcode));
    }
}

export function* generateServiceOrderOpdrachtenFromServiceOrderNrsSaga({
                                                                           payload: {
                                                                               serviceOrderNrs
                                                                           }
                                                                       }: ReturnType<typeof generateServiceOrderOpdrachtenFromServiceOrderNrsRequest>) {
    try {
        yield call(OpdrachtApi.generateServiceOrderOpdrachtenFromServiceOrderNrsRequest, serviceOrderNrs);
        yield put(generateServiceOrderOpdrachtenFromServiceOrderNrsSuccess(serviceOrderNrs));
    } catch (err: any) {
        yield put(generateServiceOrderOpdrachtenFromServiceOrderNrsFailure(err, serviceOrderNrs));
    }
}

export function* behandelServiceOrderMetDynamicsSaga({
                                                         payload: {
                                                             serviceOrderBedrijfId,
                                                             serviceOrderPlatformId,
                                                             deleteRegels
                                                         },
                                                         meta: {calledFromOpdrachtId}
                                                     }: ReturnType<typeof behandelServiceOrderMetDynamicsRequest>) {
    try {
        yield call(OpdrachtApi.behandelServiceOrderMetDynamicsRequest, serviceOrderBedrijfId, serviceOrderPlatformId, deleteRegels);
        yield put(behandelServiceOrderMetDynamicsSuccess(serviceOrderBedrijfId, serviceOrderPlatformId, deleteRegels));

        if (calledFromOpdrachtId) {
            yield put(fetchOpdrachtByIdRequest(calledFromOpdrachtId, true));
        }

    } catch (err: any) {
        yield put(behandelServiceOrderMetDynamicsFailure(err, serviceOrderBedrijfId, serviceOrderPlatformId, deleteRegels));
    }
}

export function* herbezoekOnderhoudServiceOrderSaga({
                                                        payload: {
                                                            serviceOrderBedrijfId,
                                                            serviceOrderPlatformId,
                                                            opmerking
                                                        },
                                                        meta: {calledFromOpdrachtId}
                                                    }: ReturnType<typeof herbezoekOnderhoudServiceOrderRequest>) {
    try {
        yield call(OpdrachtApi.herbezoekOnderhoudServiceOrderRequest, serviceOrderBedrijfId, serviceOrderPlatformId, opmerking);
        yield put(herbezoekOnderhoudServiceOrderSuccess(serviceOrderBedrijfId, serviceOrderPlatformId, opmerking));

        if (calledFromOpdrachtId) {
            yield put(fetchOpdrachtByIdRequest(calledFromOpdrachtId, true));
        }

    } catch (err: any) {
        yield put(herbezoekOnderhoudServiceOrderFailure(err, serviceOrderBedrijfId, serviceOrderPlatformId, opmerking));
    }
}

export function* fetchPrestatiesByOpdrachtIdSaga({
                                                     payload: {opdrachtId},
                                                     meta: {cascade}
                                                 }: ReturnType<typeof fetchPrestatiesByOpdrachtIdRequest>) {
    try {
        const result = yield call(PrestatieApi.fetchPrestatiesByOpdrachtId, opdrachtId);
        yield put(fetchPrestatiesByOpdrachtIdSuccess(result, opdrachtId));
    } catch (err: any) {
        yield put(fetchPrestatiesByOpdrachtIdFailure(err, opdrachtId));
    }
}

export function* fetchPrestatieVerwachteDuurtijdProjectionSaga({
                                                                   payload: {
                                                                       van,
                                                                       tot
                                                                   }
                                                               }: ReturnType<typeof fetchPrestatieVerwachteDuurtijdProjectionRequest>) {
    try {
        const result = yield call(PrestatieApi.fetchPrestatieVerwachteDuurtijdProjection, van, tot);
        yield put(fetchPrestatieVerwachteDuurtijdProjectionSuccess(result, van, tot));
    } catch (err: any) {
        yield put(fetchPrestatieVerwachteDuurtijdProjectionFailure(err, van, tot));
    }
}

export function* fetchGeplandBezoekByIdSaga({payload: {geplandBezoekId}}: ReturnType<typeof fetchGeplandBezoekByIdRequest>) {
    try {
        const result: GeplandBezoekModel = yield call(GeplandBezoekApi.fetchGeplandBezoekById, geplandBezoekId);
        yield put(fetchGeplandBezoekByIdSuccess(result, geplandBezoekId));
    } catch (err: any) {
        yield put(fetchGeplandBezoekByIdFailure(err, geplandBezoekId));
    }
}


export function* fetchDagPlanningBezoekenSaga({
                                                  payload: {
                                                      dagPlanningId,
                                                      onSuccess
                                                  }
                                              }: ReturnType<typeof fetchDagPlanningBezoekenRequest>) {
    try {
        const result = yield call(DagPlanningApi.fetchDagPlanningBezoeken, dagPlanningId);
        yield put(fetchDagPlanningBezoekenSuccess(result, dagPlanningId));

        onSuccess?.();
    } catch (err: any) {
        yield put(fetchDagPlanningBezoekenFailure(err, dagPlanningId));
    }
}

export function* fetchDagPlanningen({
                                        payload: {vanaf, tot, medewerkerId},
                                        meta: {cascade}
                                    }: ReturnType<typeof fetchDagPlanningenRequest>) {
    try {
        const result: Array<DagPlanningModel> = yield call(DagPlanningApi.fetchDagPlanningen, medewerkerId, vanaf, tot);
        yield put(fetchDagPlanningenSuccess(result, vanaf, tot, medewerkerId));

        if (cascade) {
            for (let dagPlanning of result) {
                yield put(fetchDagPlanningBezoekenRequest(dagPlanning.id));
            }
        }
    } catch (err: any) {
        yield put(fetchDagPlanningenFailure(err, vanaf, tot, medewerkerId));
    }
}

export function* bulkUploadDagPlanningen({payload: {upload}}: ReturnType<typeof bulkUploadDagPlanningenRequest>) {
    try {
        yield call(DagPlanningApi.bulkUploadDagPlanningen, upload);
        yield put(bulkUploadDagPlanningenSuccess());
    } catch (err: any) {
        yield put(bulkUploadDagPlanningenFailure(err));
    }
}

export function* dagPlanningCapaciteitProjectionSaga({
                                                         payload: {
                                                             van,
                                                             tot
                                                         }
                                                     }: ReturnType<typeof fetchDagPlanningCapaciteitProjectionRequest>) {
    try {
        const result = yield call(DagPlanningApi.dagPlanningCapaciteitProjection, van, tot);
        yield put(fetchDagPlanningCapaciteitProjectionSuccess(result, van, tot));
    } catch (err: any) {
        yield put(fetchDagPlanningCapaciteitProjectionFailure(err, van, tot));
    }
}


export function* downloadMagazijnRapportSaga({
                                                 payload: {
                                                     van,
                                                     tot,
                                                     medewerkerIds
                                                 }
                                             }: ReturnType<typeof downloadMagazijnRapport.request>) {
    try {
        const files = yield all(medewerkerIds.map(medewerkerId => call(MagazijnRapportApi.downloadMagazijnRapport, van, tot, medewerkerId)));
        yield all(files.map(file => saveFile(file)));

        yield put(downloadMagazijnRapport.success(van, tot, medewerkerIds));
    } catch (err: any) {
        yield put(downloadMagazijnRapport.failure(err, van, tot, medewerkerIds));
    }
}


export default function* planningSaga() {
    yield all([
        takeEvery(FETCH_OPDRACHT_BY_ID_REQUEST, fetchOpdrachtByIdSaga),
        takeLatest(GENERATE_OPDRACHTEN_FOR_ONDERHOUD_INITIEEL_SERVICE_ORDERS_REQUEST, generateOpdrachtenForOnderhoudInitieelServiceOrdersSaga),
        takeLatest(GENERATE_SERVICE_ORDER_OPDRACHTEN_FROM_SERVICE_ORDER_NRS_REQUEST, generateServiceOrderOpdrachtenFromServiceOrderNrsSaga),
        takeEvery(BEHANDEL_SERVICE_ORDER_MET_DYNAMICS_REQUEST, behandelServiceOrderMetDynamicsSaga),
        takeEvery(HERBEZOEK_ONDERHOUD_SERVICE_ORDER_REQUEST, herbezoekOnderhoudServiceOrderSaga),
        takeLatest(FETCH_PRESTATIES_BY_OPDRACHTID_REQUEST, fetchPrestatiesByOpdrachtIdSaga),
        takeLatest(FETCH_PRESTATIE_VERWACHTE_DUURTIJD_PROJECTION_REQUEST, fetchPrestatieVerwachteDuurtijdProjectionSaga),
        takeEvery(FETCH_GEPLAND_BEZOEK_BY_ID_REQUEST, fetchGeplandBezoekByIdSaga),
        takeLatest(FETCH_DAG_PLANNINGEN_REQUEST, fetchDagPlanningen),
        takeEvery(FETCH_DAG_PLANNING_BEZOEKEN_REQUEST, fetchDagPlanningBezoekenSaga),
        takeLatest(BULK_UPLOAD_DAG_PLANNINGEN_REQUEST, bulkUploadDagPlanningen),
        takeEvery(DOWNLOAD_MAGAZIJN_RAPPORT.REQUEST, downloadMagazijnRapportSaga),
        takeLatest(DAG_PLANNING_CAPACITEIT_PROJECTION_REQUEST, dagPlanningCapaciteitProjectionSaga)
    ]);
}
