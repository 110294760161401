import * as React from "react";
import ToestelLabel from "./ToestelLabel";
import {useGetToestelById} from "../../../redux/toestel/api";
import {LoadingSkeleton} from "../LoadingSkeleton";
import {skipToken} from "@reduxjs/toolkit/query";

export interface ToestelLabelContainerProps {
    toestelId: string;
    toonUitDienst?: boolean;
}

export const ToestelLabelContainer: React.FC<ToestelLabelContainerProps> = (props) => {
    const {toestelId, toonUitDienst = true} = props;

    const {data: toestel} = useGetToestelById(toestelId ? toestelId : skipToken);

    return (
        <LoadingSkeleton item={toestel}>
            {(toestel) => <ToestelLabel toestel={toestel} toonUitDienst={toonUitDienst}/>}
        </LoadingSkeleton>
    );
};
