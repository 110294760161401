import {default as React} from "react";
import {VerzendAdresModel} from "../../../redux/verzendadres/types";
import VerzendAdresSelector from "../klant/VerzendAdresSelector";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

export interface VerzendAdresFormFieldProps {
    verzendAdressen: Array<VerzendAdresModel>;
}

export const VerzendAdresFormField: React.FC<FormikFormFieldProps & VerzendAdresFormFieldProps> = ({
                                                                                                       verzendAdressen,
                                                                                                       ...props
                                                                                                   }) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <VerzendAdresSelector {...field}
                                      verzendAdressen={verzendAdressen}
                                      verzendAdresChanged={async adres => {
                                          await helpers.setValue(adres);

                                          props.onChange && props.onChange(adres);
                                      }}
                                      geselecteerdVerzendAdres={field.value}
                                      isInvalid={!!meta.error}
                                      disabled={props.disabled}
                />
            )}
        </FormikFormField>
    );
};
