import React from "react";
import KlantLabel from "./KlantLabel";
import {KlantModel} from "../../redux/klant/types";
import {VerzendAdresModel} from "../../redux/verzendadres/types";
import VerzendAdresLabel from "./VerzendAdresLabel";

export const KlantVerzendAdresTableColumn = ({
                                                 klant,
                                                 verzendAdres
                                             }: { klant: Pick<KlantModel, "bedrijfId" | "nr" | "naam" | "verwijderd">; verzendAdres: Pick<VerzendAdresModel, "code" | "naam" | "verwijderd" | "adres"> }) => {
    return (
        <span>
            <KlantLabel klant={klant}/><br/>

            <VerzendAdresLabel verzendAdres={verzendAdres}/><br/>
            <span
                className='text-muted'>{verzendAdres.adres.adres}, {verzendAdres.adres.postcode} {verzendAdres.adres.plaats}</span>
        </span>
    );
};
