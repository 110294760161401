import {
    FETCH_ANNULATIE_REDENEN_FAILURE,
    FETCH_ANNULATIE_REDENEN_REQUEST,
    FETCH_ANNULATIE_REDENEN_SUCCESS,
} from "./constants";
import {ApiError} from "../ui/types";
import {FetchAnnulatieRedenenAction} from "./types";

export const fetchAnnulatieRedenenRequest = (): FetchAnnulatieRedenenAction => ({
    type: FETCH_ANNULATIE_REDENEN_REQUEST,
    payload: {},
});

export const fetchAnnulatieRedenenSuccess = (annulatieRedenen: []): FetchAnnulatieRedenenAction => ({
    type: FETCH_ANNULATIE_REDENEN_SUCCESS,
    payload: annulatieRedenen,
});

export const fetchAnnulatieRedenenFailure = (error: ApiError): FetchAnnulatieRedenenAction => ({
    type: FETCH_ANNULATIE_REDENEN_FAILURE,
    payload: error,
    error: true,
});
