// @flow

import {combineReducers} from 'redux';
import Layout from './layout/reducers';
import {Auth} from './auth/reducers';
import AppMenu from './appMenu/reducers';
import {UI} from './ui/reducers';
import {RefData} from "./refdata/reducers";
import {Planning} from "./planning/reducers";
import {GoogleMaps} from "./googlemaps/reducers";
import {ServiceRapport} from "./servicerapport/reducers";
import {medewerkerApi} from "./medewerker/api";
import {filtersAndPageableSlice} from "./support/uiSlice";

let rootReducer = combineReducers({
    Auth,
    AppMenu,
    Layout,
    Planning,
    UI,
    RefData,
    GoogleMaps,
    ServiceRapport,
    [medewerkerApi.reducerPath]: medewerkerApi.reducer,
    [filtersAndPageableSlice.name]: filtersAndPageableSlice.reducer,
});
export default rootReducer;

// @ts-ignore
export type RootState = ReturnType<typeof rootReducer>;
