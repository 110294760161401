import React from "react";
import {Col, Form, FormGroup, Modal} from "react-bootstrap";
import {SimpleFormField} from "../../../../components/aqualex/form/SimpleFormField";
import {KlantModel} from "../../../../redux/klant/types";
import {CreateKlantModalSimilarKlanten} from "./CreateKlantModalSimilarKlanten";
import {Page} from "../../../../redux/support/pagination/types";
import {PhoneFormField} from "../../../../components/aqualex/form/PhoneFormField";
import {useTranslation} from "../../../../helpers/i18nUtils";

export interface CreateKlantModalContactgegevensProps {
    similarKlantNaam: Page<KlantModel>;
    similarKlantEmailadres: Page<KlantModel>;
    similarKlantBtwNummer: Page<KlantModel>;
}

export const CreateKlantModalContactgegevens: React.FC<CreateKlantModalContactgegevensProps> = (props) => {
    const {similarKlantNaam, similarKlantEmailadres, similarKlantBtwNummer} = props;

    const {t} = useTranslation("klant");

    return (
        <Modal.Body>
            <CreateKlantModalSimilarKlanten similarKlantNaam={similarKlantNaam}
                                            similarKlantBtwNummer={similarKlantBtwNummer}
                                            similarKlantEmailadres={similarKlantEmailadres}/>

            <Form.Row>
                <FormGroup id="contactNaam" as={Col}>
                    <SimpleFormField capitalize name="contactgegevens:contactNaam"
                                     label={t("Labels.naam-contactpersoon", "Naam contactpersoon")}
                                     placeholder={t("Placeholders.naam-contactpersoon", "Naam contactpersoon")}/>
                </FormGroup>
            </Form.Row>

            <Form.Row>
                <FormGroup id="emailadres" as={Col} sm={4} xs={6}>
                    <SimpleFormField name="contactgegevens:emailadres"
                                     label={t("Labels.e-mailadres", "E-mailadres")}
                                     placeholder={t("Placeholders.e-mailadres", "E-mailadres")}/>
                </FormGroup>

                <FormGroup id="emailadresFacturen" as={Col} sm={4} xs={6}>
                    <SimpleFormField name="contactgegevens:emailadresFacturen"
                                     label={t("Labels.e-mail-adres-voor-facturen", "E-mailadres voor facturen")}
                                     placeholder={t("Placeholders.e-mail-adres-voor-facturen", "E-mailadres voor facturen")}/>
                </FormGroup>

                <FormGroup id="emailadresAanmaningen" as={Col} sm={4} xs={6}>
                    <SimpleFormField name="contactgegevens:emailadresAanmaningen"
                                     label={t("Labels.e-mail-adres-voor-aanmaningen", "E-mailadres voor aanmaningen")}
                                     placeholder={t("Placeholders.e-mail-adres-voor-aanmaningen", "E-mailadres voor aanmaningen")}/>
                </FormGroup>

                <FormGroup id="telefoonnummer" as={Col} sm={4} xs={6}>
                    <PhoneFormField name="contactgegevens:telefoonnummer"
                                    placeholder="01 111 22 33"
                                    label={t("Labels.telefoonnummer", "Telefoonnummer")}/>
                </FormGroup>

                <FormGroup id="mobielTelefoonnummer" as={Col} sm={4} xs={6}>
                    <PhoneFormField name="contactgegevens:mobielTelefoonnummer"
                                    placeholder="0477 11 22 33"
                                    label={t("Labels.mobiel-telefoonnummer", "Mobiel telefoonnummer")}/>
                </FormGroup>
            </Form.Row>
        </Modal.Body>
    );
};
