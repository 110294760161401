import {buildApi, buildFilteredAndPagedSearchQuery, buildGetByIdQuery, buildMutation} from "../support/ApiBuilderUtils";
import {
    BezoekAanvraagFilters,
    BezoekAanvraagModel,
    BezoekAanvraagOverzichtModel,
    UpdateBezoekAanvraagForm,
    UpdateBezoekAanvraagPlanningParametersForm
} from "./types";
import {formatDateIso} from "../../helpers/DateUtils";
import {installTranslationKey} from "../../helpers/i18nUtils";

export const bezoekAanvraagApi = buildApi(build => ({
    verwijderPrestatieVanBezoekAanvraag: buildMutation<void, { bezoekAanvraagId: string; prestatieId: string }>(build,
        installTranslationKey("planning.APIFoutmeldingen.prestatie-van-bezoekaanvraag-verwijderen", "prestatie van bezoekaanvraag verwijderen"), "BezoekAanvraag", ({
                                                                                                                                                                                          bezoekAanvraagId,
                                                                                                                                                                                          prestatieId
                                                                                                                                                                                      }) => `/bezoek/aanvraag/${bezoekAanvraagId}/prestatie/${prestatieId}`, {invalidatesTags: ["BezoekAanvraag"]}, {method: "DELETE"}),
    mergeBezoekAanvragen: buildMutation<void, { bezoekAanvraagId: string; bezoekAanvraagIds: string[] }>(build,
        installTranslationKey("planning:APIFoutmeldingen.bezoekaanvragen-verwijderen", "bezoekaanvragen samenvoegen"), "BezoekAanvraag", ({bezoekAanvraagId}) => `/bezoek/aanvraag/${bezoekAanvraagId}/merge`, {invalidatesTags: ["BezoekAanvraag"]}),

    searchBezoekAanvragenVoorPlanning: buildFilteredAndPagedSearchQuery<BezoekAanvraagOverzichtModel, BezoekAanvraagFilters>(build,
        installTranslationKey("planning:APIFoutmeldingen.bezoekaanvragen-opvragen-voor-planning", "bezoekaanvragen opvragen voor planning"),
        "BezoekAanvraag",
        () => `/bezoek/aanvraag/voorPlanning`
    ),
    searchBezoekAanvragen: buildFilteredAndPagedSearchQuery<BezoekAanvraagOverzichtModel, BezoekAanvraagFilters>(build,
        installTranslationKey("planning:APIFoutmeldingen.zoeken-van-bezoekaanvragen", "zoeken van bezoekaanvragen"),
        "BezoekAanvraag",
        (filters) => `/bezoek/aanvraag`,
        filters => ({
            ...filters,
            ...(filters.vanDatum && {vanDatum: formatDateIso(filters.vanDatum)}),
            ...(filters.totDatum && {totDatum: formatDateIso(filters.totDatum)}),
            ...(filters.gecommuniceerdeDatumVan && {gecommuniceerdeDatumVan: formatDateIso(filters.gecommuniceerdeDatumVan)}),
            ...(filters.gecommuniceerdeDatumTot && {gecommuniceerdeDatumTot: formatDateIso(filters.gecommuniceerdeDatumTot)}),
        })
    ),
    bulkBezoekAanvraagKlaarVoorPlanning: build.mutation<void, Array<string>>({
        query: (bezoekAanvraagIds) => {
            return {
                url: `/bezoek/aanvraag/bulkKlaarVoorPlanning`,
                method: "POST",
                body: bezoekAanvraagIds,
            };
        },
        extraOptions: {
            omschrijving: installTranslationKey("planning:APIFoutmeldingen.bezoekaanvragen-klaar-voor-planning", "bezoekaanvragen klaar voor planning")
        },
        invalidatesTags: (result, error, bezoekAanvraagIds) => [
            {type: "BezoekAanvraag", id: "PARTIAL-LIST"},
            ...bezoekAanvraagIds.map(bezoekAanvraagId => ({type: "BezoekAanvraag", id: bezoekAanvraagId}))
        ],
    }),
    annuleerBezoekAanvraag: build.mutation<void, Pick<BezoekAanvraagModel, "id">>({
        query: ({id}) => ({
            url: `/bezoek/aanvraag/${id}`,
            method: "DELETE"
        }),
        extraOptions: {
            omschrijving: installTranslationKey("planning:APIFoutmeldingen.annuleren-van-bezoekaanvraag", "annuleren van bezoekaanvraag")
        },
        invalidatesTags: (result, error, {id}) => [
            {type: "BezoekAanvraag", id},
            {type: "BezoekAanvraag", id: "PARTIAL-LIST"}
        ],
    }),
    bezoekAanvraagKlaarVoorPlanning: build.mutation<void, Pick<BezoekAanvraagModel, "id">>({
        query: ({id}) => ({
            url: `/bezoek/aanvraag/${id}/klaarVoorPlanning`,
            method: "POST"
        }),
        extraOptions: {
            omschrijving: "bezoekaanvraag klaar voor planning"
        },
        invalidatesTags: (result, error, {id}) => [
            {type: "BezoekAanvraag", id},
            {type: "BezoekAanvraag", id: "PARTIAL-LIST"}
        ],
    }),
    getBezoekAanvraagById: buildGetByIdQuery<BezoekAanvraagModel, string>(build, "ophalen van bezoekaanvraag", "BezoekAanvraag", id => `/bezoek/aanvraag/${id}`),
    updateBezoekAanvraagPlanningParameters: build.mutation<void, Pick<BezoekAanvraagModel, "id"> & UpdateBezoekAanvraagPlanningParametersForm>({
        query: ({id, ...form}) => ({
            url: `/bezoek/aanvraag/${id}/planning`,
            method: "POST",
            body: {
                ...form,
                vanDatum: form.vanDatum ? formatDateIso(form.vanDatum) : undefined,
                totDatum: form.totDatum ? formatDateIso(form.totDatum) : undefined
            }
        }),
        extraOptions: {
            omschrijving: "update planning parameters van bezoekaanvraag"
        },
        invalidatesTags: (result, error, {id}) => [
            {type: "BezoekAanvraag", id},
            {type: "BezoekAanvraag", id: "PARTIAL-LIST"}
        ],
    }),
    updateBezoekAanvraag: build.mutation<void, Pick<BezoekAanvraagModel, "id"> & UpdateBezoekAanvraagForm>({
        query: ({id, ...form}) => ({
            url: `/bezoek/aanvraag/${id}`,
            method: "PATCH",
            body: form,
        }),
        extraOptions: {
            omschrijving: "update van bezoekaanvraag"
        },
        invalidatesTags: (result, error, {id}) => [
            {type: "BezoekAanvraag", id},
            {type: "BezoekAanvraag", id: "PARTIAL-LIST"}
        ],
    }),
    geneerBezoeken: build.mutation<void, { van: Date, tot: Date }>(
        {
            query: ({van, tot}) => ({
                url: `/bezoek/aanvraag/genereer`,
                method: "POST",
                params: {
                    van: formatDateIso(van),
                    tot: formatDateIso(tot),
                }
            }),
            extraOptions: {
                omschrijving: "genereren van bezoeken"
            },
            invalidatesTags: (result, error, args) => [
                {type: "BezoekAanvraag", id: "PARTIAL-LIST"}
            ],
        }
    ),
    annuleerCommunicatieBezoekAanvraag: build.mutation<void, Pick<BezoekAanvraagModel, "id">>({
        query: ({id}) => ({
            url: `/bezoek/aanvraag/${id}/annuleerCommunicatie`,
            method: "POST"
        }),
        extraOptions: {
            omschrijving: "annuleren van communicatie van bezoekaanvraag"
        },
        invalidatesTags: (result, error, {id}) => [
            {type: "BezoekAanvraag", id},
            {type: "BezoekAanvraag", id: "PARTIAL-LIST"}
        ],
    }),
}));

export const useGetBezoekAanvraagById = bezoekAanvraagApi.endpoints.getBezoekAanvraagById.useQuery;
export const useSearchBezoekAanvragen = bezoekAanvraagApi.endpoints.searchBezoekAanvragen.useQuery;
export const useVerwijderPrestatieVanBezoekAanvraag = bezoekAanvraagApi.endpoints.verwijderPrestatieVanBezoekAanvraag.useMutation;
export const useMergeBezoekAanvragen = bezoekAanvraagApi.endpoints.mergeBezoekAanvragen.useMutation;
export const useSearchBezoekAanvragenVoorPlanning = bezoekAanvraagApi.endpoints.searchBezoekAanvragenVoorPlanning.useQuery;
export const useBulkBezoekAanvraagKlaarVoorPlanning = bezoekAanvraagApi.endpoints.bulkBezoekAanvraagKlaarVoorPlanning.useMutation;
export const useAnnuleerBezoekAanvraag = bezoekAanvraagApi.endpoints.annuleerBezoekAanvraag.useMutation;
export const useBezoekAanvraagKlaarVoorPlanning = bezoekAanvraagApi.endpoints.bezoekAanvraagKlaarVoorPlanning.useMutation;
export const useUpdateBezoekAanvraagPlanningParameters = bezoekAanvraagApi.endpoints.updateBezoekAanvraagPlanningParameters.useMutation;
export const useUpdateBezoekAanvraag = bezoekAanvraagApi.endpoints.updateBezoekAanvraag.useMutation;
export const useGenereerBezoeken = bezoekAanvraagApi.endpoints.geneerBezoeken.useMutation;
export const useAnnuleerCommunicatieBezoekAanvraag = bezoekAanvraagApi.endpoints.annuleerCommunicatieBezoekAanvraag.useMutation;
