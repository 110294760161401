import {default as React} from "react";
import Select from "react-select";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";
import {findOption} from "./ReactSelectFormField";

export const SelectFormField: React.FC<FormikFormFieldProps> = ({disabled, ...props}) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => {
                const options = props.options || React.Children.map(props.children, (child: any) => ({
                    value: child.props.value,
                    label: child.props.children
                }));

                const activeValue = findOption(options as any[], field.value);

                return (
                    <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        defaultValue={findOption(options as any[], "")} value={activeValue} options={options} placeholder=""
                        onChange={async event => {
                            const value = event?.value;

                            await helpers.setValue(value);
                            props.onChange && props.onChange(value);
                        }}>
                        {props.children}
                    </Select>
                );
            }}
        </FormikFormField>
    );
};
