import {default as React} from "react";
import {HelpTooltip} from "../HelpTooltip";

export type LabelWithHelpProps =
    {
        fieldName: string;
        label?: string;
        helpText?: string;
    }

export const LabelWithHelp: React.FC<LabelWithHelpProps> = ({
                                                                fieldName,
                                                                label,
                                                                helpText,
                                                            }) => {
    return (
        <>
            {label && (
                <>
                    {label}
                    {helpText && <>&nbsp;<HelpTooltip id={`tooltip_help_${fieldName}`}>{helpText}</HelpTooltip></>}
                </>
            )}
        </>
    );
};
