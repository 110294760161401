import * as React from "react";
import {useState} from "react";
import {Alert, Collapse} from "react-bootstrap";
import {clearError} from '../../redux/ui/actions';
import {useDispatch, useSelector} from 'react-redux';
import {ApiError, ErrorType} from "../../redux/ui/types";
import {Redirect} from 'react-router-dom';
import {RootState} from "../../redux/reducers";
import {useTranslation} from "../../helpers/i18nUtils";

const ErrorHandler = () => {

    const dispatch = useDispatch();

    const errors = useSelector((state: RootState) => state.UI.errors);
    const unauthorizedError = useSelector((state: RootState) => state.UI.unauthorizedError);

    if (unauthorizedError) {
        return <Redirect to='/account/logout'/>;
    }

    if (errors.length > 0) {
        return (
            <div aria-live='polite'
                 aria-atomic={true}
                 role='alert'
                 style={{
                     position: 'fixed',
                     top: 20,
                     right: 20,
                     zIndex: 2000,
                     minHeight: 200
                 }}>
                <div style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    minWidth: 400,
                }}>
                    {errors.map((error, i) => (
                        <ErrorAlert key={i} error={error} errorIndex={i} dismissError={() => dispatch(clearError(error))}/>
                    ))}
                </div>
            </div>
        )
    } else {
        return null;
    }
};


const errorType2Variant: Record<ErrorType,  string> = {
    ACCESS_DENIED: "danger",
    DOMAIN: "info",
    ENTITY_NOT_FOUND: "danger",
    ERP: "warning",
    GENERIC: "danger"
};
const defaultVariant = "danger";

const ErrorAlert: React.FC<{ error: ApiError, errorIndex: number; dismissError: () => void; }> = ({
                                                                                                      error,
                                                                                                      errorIndex,
                                                                                                      dismissError
                                                                                                  }) => {
    const [showDetails, setShowDetails] = useState(false);
    const {t} = useTranslation("algemeen");

    return <Alert
        key={errorIndex}
        variant={error.cause ? errorType2Variant[error.cause.type] || defaultVariant : defaultVariant}
        show={true}
        dismissible={true}
        onClose={dismissError}
    >

        <h4>{error.message}</h4>

        {error.cause && (
            <React.Fragment>
                <p>
                    {error.cause.code ? t(error.cause.code, error.cause.message || error.message, error.cause.context) : error.cause.message || error.message}
                </p>

                {error.cause?.type === ErrorType.ERP && <p>{error.cause.message}</p>}

                <p>
                    <small>
                        <span className='cursor-pointer' onClick={() => setShowDetails(!showDetails)}>
                            {showDetails
                                ? <i className="mdi mdi-16px mdi-chevron-down"/>
                                : <i className="mdi mdi-16px mdi-chevron-right"/>}
                            {t("Foutmeldingen.details-van-foutmelding", "Details")}
                        </span>
                    </small>
                </p>
                <Collapse in={showDetails}>
                    <div id={`details-${error.cause.identifier}`}>
                        <h5>{t("ErrorHandler.correlatie-identifier", "Correlatie identifier")}</h5>
                        <p>{error.cause.identifier}</p>

                        <h5>{t("Labels.tijdstip", "Tijdstip")}</h5>
                        <p>{error.cause.timestamp}</p>

                        <h5>{t("Label.url", "Url")}</h5>
                        <p>{error.cause.url}</p>

                        <h5>{t("Label.technische-stacktrace", "Technische stacktrace")}</h5>
                        <pre style={{
                            overflow: 'auto',
                            maxWidth: '600px',
                            maxHeight: '200px',
                            background: 'white',
                            borderStyle: 'solid',
                            borderWidth: '1px'
                        }}>{error.cause.technicalMessage}</pre>
                    </div>
                </Collapse>
            </React.Fragment>
        )}
    </Alert>;
};

export default ErrorHandler;
