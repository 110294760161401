import React, {CSSProperties} from "react";
import {LoadingSkeleton} from "../../../../../components/aqualex/LoadingSkeleton";
import {AfstandModel} from "../../../../../redux/support/types";
import {Button} from "react-bootstrap";
import {Afstand} from "../../../../../components/aqualex/Afstand";
import {useTranslation} from "../../../../../helpers/i18nUtils";

export interface PlanningOmwegProps {
    afstand?: AfstandModel;
    onClick?: () => any;

    aangeraden?: boolean;
    enabled?: boolean;
    placeholder?: boolean;
    style?: CSSProperties;
}

export const PlanningOmweg: React.FC<PlanningOmwegProps> = (props) => {
    const {afstand, onClick, aangeraden, enabled = true, placeholder, style, ...rest} = props;

    const {t} = useTranslation("planning");

    if (!enabled) {
        return null;
    }

    const renderAfstand = () => {
        if (placeholder) {
            return <LoadingSkeleton width={75}/>;
        }

        if (!afstand) {
            return null;
        }

        return <span className={aangeraden ? "text-success font-weight-bold" : ""}>+ {afstand?.inMinuten} min (<Afstand afstandInMeter={afstand!.inMeter}/>)</span>;
    };

    return (
        <div className="timeline-item">
            <div className="d-flex align-items-center border-1 justify-content-between px-3" {...rest} style={style}>
                <div className="d-flex align-items-center">
                    <i className="mdi mdi-car font-24 mr-2"/> {renderAfstand()}
                </div>

                <div>
                    {placeholder && <LoadingSkeleton width={60}/>}
                    {!placeholder && <Button variant="link" onClick={onClick}>{t("Button.voeg-bezoek-hier-in", "+ Voeg bezoek hier in")}</Button>}
                </div>
            </div>
        </div>
    );
};
