import {
    downloadServiceRapportByIdFailure,
    downloadServiceRapportByIdRequest,
    downloadServiceRapportByIdSuccess,
    searchServiceRapportFailure,
    searchServiceRapportRequest,
    searchServiceRapportSuccess,
} from './actions';
import {DOWNLOAD_SERVICE_RAPPORT_REQUEST, SEARCH_SERVICE_RAPPORT_REQUEST,} from "./constants";
import {all, call, put, takeEvery, throttle} from 'redux-saga/effects';

import ServiceRapportApi from "../../helpers/ServiceRapportApi";
import {FileDownload, saveFile} from "../../helpers/download";


export function* searchServiceRapportSaga({
                                              payload: {
                                                  filters,
                                                  pageable
                                              }
                                          }: ReturnType<typeof searchServiceRapportRequest>) {
    try {
        const result = yield call(ServiceRapportApi.search, filters, pageable);
        yield put(searchServiceRapportSuccess(result, filters, pageable));
    } catch (err: any) {
        yield put(searchServiceRapportFailure(err, filters, pageable));
    }
}

export function* downloadServiceRapportSaga({payload: {klantId, serviceRapportId}}: ReturnType<typeof downloadServiceRapportByIdRequest>) {
    try {
        const result: FileDownload = yield call(ServiceRapportApi.downloadServiceRapport, klantId, serviceRapportId);
        yield saveFile(result);
        yield put(downloadServiceRapportByIdSuccess(result, klantId, serviceRapportId));
    } catch (err: any) {
        yield put(downloadServiceRapportByIdFailure(err, klantId, serviceRapportId));
    }
}

export default function* serviceRapportSaga() {
    yield all([
        throttle(300, SEARCH_SERVICE_RAPPORT_REQUEST, searchServiceRapportSaga),
        takeEvery(DOWNLOAD_SERVICE_RAPPORT_REQUEST, downloadServiceRapportSaga),
    ]);
}
