import * as React from "react";
import Select, {components} from 'react-select';
import {VerzendAdresModel} from "../../../redux/verzendadres/types";
import VerzendAdresBox from "../VerzendAdresBox";
import {useTranslation} from "../../../helpers/i18nUtils";

interface VerzendAdresSelectorProps {
    verzendAdressen: Array<VerzendAdresModel>;
    geselecteerdVerzendAdres: VerzendAdresModel;
    verzendAdresChanged: (adres) => any;
    placeholder?: string;
    isInvalid?: boolean;
    disabled?: boolean;
}

const SingleValue = props => {
    const adres: VerzendAdresModel = props.data;

    return <components.SingleValue {...props}>
        <span>
            <strong>{adres.code}</strong> {adres.naam}, {adres.adres.adres}, {adres.adres.plaats}
        </span>
    </components.SingleValue>
};


const VerzendAdresSelector = ({
                                  verzendAdressen,
                                  geselecteerdVerzendAdres,
                                  verzendAdresChanged,
                                  isInvalid,
                                  disabled,
                                  placeholder
                              }: VerzendAdresSelectorProps) => {
    const {t} = useTranslation("klant");

    const filter = ({data: adres}, filter) => adres.code.toUpperCase().includes(filter.toUpperCase()) || adres.naam.toUpperCase().includes(filter.toUpperCase());

    return (
        <Select
            className={"react-select-container" + (isInvalid ? " is-invalid" : "")}
            classNamePrefix="react-select"
            value={geselecteerdVerzendAdres || null} //value needs to be explicitly null to clear
            defaultValue={null}
            options={verzendAdressen.concat().sort((a, b) => a.code.localeCompare(b.code))}
            filterOption={filter}
            onChange={verzendAdresChanged}
            isDisabled={disabled}
            placeholder={placeholder || t("Placeholders.type-om-adressen-te-zoeken", "Typ om adressen te zoeken op naam/code...")}
            getOptionValue={adres => adres.id}
            getOptionLabel={adres => adres.naam} // getOptionLabel is nodig, hoewel de rendering gebeurd via formatOptionLabel en SingleValue component. Er is een bug in react-select die ervoor zorgt dat de options niet getoond worden als deze optie niet gezet is
            formatOptionLabel={adres => <VerzendAdresBox verzendAdres={adres} clickable={false}/>}
            components={{SingleValue}}
            isClearable
            noOptionsMessage={() => t("Labels.geen-adressen-gevonden", "Geen adressen gevonden")}
        />
    )
};

export default VerzendAdresSelector;
