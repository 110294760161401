import {GoogleMapsActionType, GoogleMapsDirections, GoogleMapsState} from "./types";
import {
    FETCH_GOOGLE_MAPS_ROUTE_FAILURE,
    FETCH_GOOGLE_MAPS_ROUTE_REQUEST,
    FETCH_GOOGLE_MAPS_ROUTE_SUCCESS,
    INVALIDATE_GOOGLE_MAPS_DIRECTIONS
} from "./constants";
import {RootState} from "../reducers";

const INITIAL_STATE: GoogleMapsState = {
    directions: {},
};

export const GoogleMaps = (state = INITIAL_STATE, action: GoogleMapsActionType): GoogleMapsState => {
    switch (action.type) {
        case FETCH_GOOGLE_MAPS_ROUTE_REQUEST:
            return {
                ...state,
                directions: {
                    ...state.directions,
                    [action.payload.id]: {
                        id: action.payload.id,
                        directions: undefined,
                        loading: true,
                        error: undefined,
                    },
                },
            };
        case FETCH_GOOGLE_MAPS_ROUTE_SUCCESS:
            const result: GoogleMapsDirections = action.payload;
            const key = action.meta.id;

            return {
                ...state,
                directions: {
                    ...state.directions,
                    [key]: {
                        id: key,
                        directions: {
                            ...result,
                            directions: result?.directions ? {
                                ...result.directions,
                                geocoded_waypoints: [...result.directions.geocoded_waypoints],
                                routes: [...result.directions.routes],
                                directions: [...result.directions.directions]
                            } : undefined,
                            // routes: [...(result.directions)]
                        },
                        loading: false,
                        error: undefined,
                    },
                },
            }
        case FETCH_GOOGLE_MAPS_ROUTE_FAILURE:
            return {
                ...state,
                directions: {
                    ...state.directions,
                    [action.meta.id]: {
                        id: action.meta.id,
                        directions: undefined,
                        loading: false,
                        error: action.payload,
                    },
                },
            };
        case INVALIDATE_GOOGLE_MAPS_DIRECTIONS:
            return {
                ...state,
                directions: {
                    ...state.directions,
                    [action.payload.id]: undefined
                }
            };

        default:
            return {...state};
    }
};

export const selectDirections = (state: RootState, id: string): GoogleMapsDirections | undefined => {
    return state.GoogleMaps.directions[id];
};
