export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
}

export interface Page<ItemT> {
    content: Array<ItemT>,
    number: number,
    size: number,
    totalElements: number,
    loading: boolean,
}

export interface Pageable {
    pageNumber: number;
    pageSize: number;
    sortField: string;
    sortOrder: SortOrder;
}

export interface EntityPage<T> {
    content: Array<T>;
    totalElements: number;
}

export interface Pagination {
    [Key: string]: PaginationEntry;
}

export interface PaginationEntry {
    pages: PageDict;
    totalItemCount: number;
}

export interface PageDict {
    [Key: string]: PageState;
}

export interface PageState {
    items: Array<string>;
    loading: boolean;
}

export interface EntityDict<T> {
    [Key: string]: T
}