import React, {ChangeEvent} from "react";
import {Form} from "react-bootstrap";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";
import {FormCheckType} from "react-bootstrap/FormCheck";
import {HelpTooltip} from "../HelpTooltip";

type SimpleCheckFieldProps = {
    type?: FormCheckType,
};

export const SimpleCheckField: React.FC<FormikFormFieldProps & SimpleCheckFieldProps> = ({
                                                                                             label,
                                                                                             type,
                                                                                             disabled,
                                                                                             helpText,
                                                                                             id,
                                                                                             ...props
                                                                                         }) => {
        return (
            <FormikFormField {...props}>
                {(field, meta, helpers) => {
                    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                        if (type === "radio") {
                            helpers.setValue(event.target.id);
                        } else {
                            field.onChange(event);
                        }
                    };

                    return <>
                        <Form.Check {...field}
                                    checked={field.value}
                                    id={id ?? field.name}
                                    type={type}
                                    isInvalid={!!meta.error}
                                    label={label}
                                    disabled={disabled}
                                    onChange={onChange}/>
                        {helpText && <>&nbsp;<HelpTooltip id={`tooltip_help_${field.name}`}>{helpText}</HelpTooltip></>}
                    </>
                }}
            </FormikFormField>
        );
    }
;
