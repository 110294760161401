import {default as React, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {FormikFormFieldProps} from "./FormikFormField";

export const CapitalizeTextFormControl: React.FC<FormikFormFieldProps & { type?: string }> = (props) => {
    const {onChange, name, value} = props;

    const [initial, setInitial] = useState(true);

    useEffect(() => {
        if (initial && value) {
            setInitial(false);

            onChange({
                target: {
                    name: name,
                    value: value?.[0]?.toUpperCase() + value?.substring(1)
                }
            });
        }
    }, [initial, onChange, name, value]);

    return (
        <Form.Control {...props} />
    );
};
