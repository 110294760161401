import {default as React} from "react";
import {Button, Table} from 'react-bootstrap';
import {VerzendAdresModel} from "../../../redux/verzendadres/types";
import LoaderWidget from '../../../components/Loader';
import {Form, Formik} from "formik";
import {SelectFormField} from "../../../components/aqualex/form/SelectFormField";
import {useModal} from "../../../redux/ui/hooks";
import {BackOfficeModals} from "../../../components/aqualex/ModalRoot";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useGetVerzendAdressenByKlantId} from "../../../redux/verzendadres/api";
import {CreateVerzendAdresFormValues} from "../../klant/verzendadresdetail/CreateVerzendAdresFormContainer";
import {useGetBestelBonById} from "../../../redux/bestelbon/api";
import {Translation, useTranslation} from "react-i18next";
import Adres from "../../../components/aqualex/Adres";
import {BestelBonLijnModel} from "../../../redux/bestelbon/types";
import TransactieTypeBadge from "../../../components/aqualex/bestelbon/TransactieTypeBadge";
import {BestelBonLijnItemLabel} from "./BestelBonLijnItemLabel";

export interface KoppelVerzendAdressenModalProps {
    klantId: string;
    bestelBonId: string;
    onBevestigHandler: Function;
    loading: boolean;
}

export interface KoppelVerzendAdressenModalFormValues {
    verzendAdresCode: Record<string, string>;
}

const KoppelVerzendAdressenModal: React.FC<KoppelVerzendAdressenModalProps> = (props) => {
    const {
        klantId,
        bestelBonId,
        onBevestigHandler,
        loading,
    } = props;

    const {showModal, hideModal} = useModal();

    const {data: bestelBon} = useGetBestelBonById(bestelBonId);
    const {data: verzendAdressen} = useGetVerzendAdressenByKlantId({klantId})

    const onAnnuleer = () => {
        hideModal();
    };

    const onBevestig = (values: KoppelVerzendAdressenModalFormValues) => {
        hideModal();
        onBevestigHandler(values.verzendAdresCode);
    };

    const initialValues: KoppelVerzendAdressenModalFormValues = {
        verzendAdresCode: {}
    };

    if (bestelBon?.lijnen) {
        for (const toestel of bestelBon.lijnen) {
            if (toestel.verzendAdresId) {
                initialValues.verzendAdresCode[toestel.id] = toestel.verzendAdresId;
            }
        }
    }

    const {t} = useTranslation("bestelbon");

    return (
        <Modal show={true} onHide={onAnnuleer} size="xl">
            <ModalHeader closeButton>
                <ModalTitle>
                    {t("Titels.verzendadressen-toevoegen-voor-BB",
                        "Verzendadressen toewijzen voor BB-{{nummer}}",
                        {nummer: bestelBon?.nummer})}
                </ModalTitle>
            </ModalHeader>
            {loading && <LoaderWidget/>}
            {bestelBon && (
                <Formik<KoppelVerzendAdressenModalFormValues>
                    initialValues={initialValues as KoppelVerzendAdressenModalFormValues} onSubmit={onBevestig}>
                    {
                        ({isValid, setFieldValue}) => {
                            const onCreateVerzendAdresSuccess = (lijn: BestelBonLijnModel, verzendAdres: VerzendAdresModel) => {
                                setFieldValue(`verzendAdresCode.${lijn.id}`, verzendAdres.id);
                            };

                            const onCreateVerzendAdresSelected = (lijn: BestelBonLijnModel) => {
                                const {besteldOpVerzendAdres} = lijn;

                                const initialValues = {
                                    code: besteldOpVerzendAdres.plaats?.toUpperCase()?.substring(0, 10),
                                    contact: bestelBon?.klant?.contact,
                                    email: bestelBon?.klant?.email,
                                    telefoon: bestelBon?.klant?.telefoon,

                                    adres: besteldOpVerzendAdres.adres,
                                    postcode: besteldOpVerzendAdres.postcode,
                                    plaats: besteldOpVerzendAdres.plaats,
                                    landCode: besteldOpVerzendAdres.landCode,
                                    latitude: besteldOpVerzendAdres.latitude as any,
                                    longitude: besteldOpVerzendAdres.longitude as any
                                } as Partial<CreateVerzendAdresFormValues>;

                                showModal(BackOfficeModals.CREATE_VERZEND_ADRES_MODAL, {
                                    klantId: klantId,
                                    onSuccessCallback: (verzendAdres) => onCreateVerzendAdresSuccess(lijn, verzendAdres),
                                    initialValues
                                });
                            };

                            return (
                                <Form>
                                    <ModalBody>
                                        <Table className="mb-3" size="sm">
                                            <thead>
                                            <tr className="d-flex">
                                                <th className="col-3">{t("Kolomtitels.artikel", "Artikel")}</th>
                                                <th className="col-1">{t("Kolomtitels.transactie", 'Transactie')}</th>
                                                <th className="text-right, col-1">{t("Kolomtitels.aantal", 'Aantal')}</th>
                                                <th className="col-2">{t("Kolomtitels.besteld-op", "Besteld op")}</th>
                                                <th className="col-2">{t("Kolomtitels.locatie", 'Locatie')}</th>
                                                <th className="col-3">{t("Kolomtitels.verzendcode", "Verzendcode")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {bestelBon?.lijnen?.map((bestelBonLijn) => (
                                                <tr key={bestelBonLijn.id} id={bestelBonLijn.id} className="d-flex">
                                                    <td className="col-3">
                                                        <BestelBonLijnItemLabel lijn={bestelBonLijn}/>
                                                    </td>
                                                    <td className="col-1">
                                                        <TransactieTypeBadge
                                                            transactieType={bestelBonLijn.transactieType}/>
                                                    </td>
                                                    <td className="text-right, col-1">
                                                        {bestelBonLijn.besteldAantal}
                                                    </td>
                                                    <td className="col-2">
                                                        <Adres adres={bestelBonLijn.besteldOpVerzendAdres}/>
                                                    </td>
                                                    <td className="col-2">
                                                        {bestelBonLijn.locatie
                                                            ? <span>{bestelBonLijn.locatie}</span>
                                                            : <span
                                                                className="text-muted"><Translation>{t => t("Labels.leeg", "Leeg")}</Translation></span>
                                                        }
                                                    </td>
                                                    <td className="col-3">
                                                        <SelectFormField name={`verzendAdresCode.${bestelBonLijn.id}`}
                                                                         type='select'
                                                                         onChange={(value) => {
                                                                             if (value === "nieuw") {
                                                                                 setFieldValue(`verzendAdresCode.${bestelBonLijn.id}`, "");
                                                                                 onCreateVerzendAdresSelected(bestelBonLijn);
                                                                             } else {
                                                                                 setFieldValue(`verzendAdresCode.${bestelBonLijn.id}`, value);
                                                                             }
                                                                         }}
                                                        >
                                                            <option/>
                                                            {(verzendAdressen || []).map(adres => (
                                                                <option key={adres.id}
                                                                        value={adres.id}>{adres.code} ({adres.adres.adres}, {adres.adres.plaats})</option>
                                                            ))}
                                                            <option key="nieuw" value="nieuw">
                                                                {t("Labels.nieuw-verzendadres-maken", "Nieuw verzendadres maken...")}
                                                            </option>
                                                        </SelectFormField>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button variant="light"
                                                onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                                        <Button type="submit"
                                                disabled={!isValid}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                                    </ModalFooter>
                                </Form>
                            );
                        }
                    }
                </Formik>
            )}
        </Modal>
    );
};

export default KoppelVerzendAdressenModal;
