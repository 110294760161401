import React from "react";
import {Card} from "react-bootstrap";
import {EditableTextArea} from "../../../../../components/aqualex/EditableTextArea";
import {useTranslation} from "../../../../../helpers/i18nUtils";

export interface PlanningOpmerkingCardProps {
    opmerking?: string;
    onChangeSubmit: (opmerking?: string) => any;
    emptyLabel?: string;
}

export const PlanningOpmerkingCard: React.FC<PlanningOpmerkingCardProps> = (props) => {
    const {opmerking, onChangeSubmit, emptyLabel} = props;

    const {t} = useTranslation("planning");

    const onSubmit = (opmerking?: string) => {
        onChangeSubmit(opmerking);
    };

    return (
        <div className="timeline-item">
            <i className="icon mdi mdi-information-variant"/>

            <Card className="m-0 shadow-sm">
                <Card.Body className="p-1">
                    <EditableTextArea rows={2} initialValue={opmerking} onSubmit={onSubmit}
                                      emptyLabel={emptyLabel || t("Labels.voeg-opmerking-toe", "Voeg een opmerking toe...")}/>
                </Card.Body>
            </Card>
        </div>
    );
};
