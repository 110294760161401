import * as React from "react";
import {useCallback, useState} from "react";
import Select, {components} from 'react-select';
import {KlantModel} from "../../../redux/klant/types";
import KlantBox from "../KlantBox";
import KlantLabel from "../KlantLabel";
import {SortOrder} from "../../../redux/support/pagination";
import debounce from "lodash.debounce";
import {useSearchKlanten} from "../../../redux/klant/api";
import {useTranslation} from "../../../helpers/i18nUtils";

interface KlantSelectorProps {
    geselecteerdeKlant?: KlantModel;
    klantChanged: (klant) => any;
    isInvalid?: boolean;
    disabled?: boolean;
    placeholder?: string;
}

const SingleValue = props => (
    <components.SingleValue {...props}>
        <KlantLabel klant={props.data}/>
    </components.SingleValue>
);

const pageable = {
    pageNumber: 1,
    pageSize: 20,
    sortField: "naam",
    sortOrder: SortOrder.asc
};

const KlantSelector = ({geselecteerdeKlant, klantChanged, isInvalid, placeholder, disabled}: KlantSelectorProps) => {
    const [filter, setFilter] = useState('');

    const {t} = useTranslation("klant");

    const {data, isLoading: loading} = useSearchKlanten({
        filters: {
            naamOfNummer: filter
        },
        pageable: pageable,
    });

    const klanten = data?.content || [];

    // eslint-disable-next-line
    const handleInputChange = useCallback(
        debounce(inputValue => setFilter(inputValue), 300),
        []
    );

    return (
        <Select
            className={"react-select-container" + (isInvalid ? " is-invalid" : "")}
            classNamePrefix="react-select"
            value={geselecteerdeKlant || null}
            isLoading={loading}
            loadingMessage={() => "Laden..."}
            filterOption={() => true} // completely bypass filtering
            options={klanten}
            onChange={klant => klantChanged(klant)}
            isDisabled={disabled}
            placeholder={placeholder || t("Placeholders.type-om-klanten-te-zoeken", "Typ om klanten te zoeken op naam/nummer...")}
            getOptionValue={klant => klant.id}
            formatOptionLabel={klant => <KlantBox klant={klant} clickable={false}/>}
            components={{SingleValue}}
            isClearable
            onInputChange={(inputValue, reason) => {
                if (reason.action === 'set-value' || reason.action === 'menu-close' || reason.action === 'input-blur') {
                    // set-value, menu-close en input-blur triggeren reset van input value, en dus ook een 'reset' van de dropdown list
                    return;
                }

                // if (reason.action === "input-change") {
                handleInputChange(inputValue);
                // }
            }}
            noOptionsMessage={() => t("Labels.geen-klanten-gevonden", "Geen klanten gevonden")}
        />
    )
};

export default KlantSelector;
