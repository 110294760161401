import {UseQuery} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {useCallback, useMemo} from "react";
import {useDispatch} from "react-redux";
import {FiltersAndPageable, updateFiltersAndPageable, useSelectFiltersAndPageable} from "./uiSlice";
import {Page} from "../support/pagination/types";
import {QueryDefinition} from "@reduxjs/toolkit/query";
import {Pageable} from "../support/pagination";

interface FilteredAndPagedSearchResultType<EntityType, FiltersType> {
    page: Page<EntityType>;
    loading: boolean;
    filtersAndPageable: FiltersAndPageable<FiltersType>;
    updateFiltersAndPageable: (filtersAndPageable: FiltersAndPageable<FiltersType>) => void;
    updateQuickFilters: (quickFilters: Record<string, string | undefined>) => void;
    loadData: (filters: FiltersType, pageable: Pageable) => void;
}

type PagedQueryType<EntityType, FiltersType> = UseQuery<QueryDefinition<FiltersAndPageable<FiltersType>, any, any, Page<EntityType>>>;

export const useFilteredAndPagedSearch = <EntityType, FiltersType>(key: string, query: PagedQueryType<EntityType, FiltersType>, initialFiltersAndPageable: FiltersAndPageable<FiltersType>, options: any = {}): FilteredAndPagedSearchResultType<EntityType, FiltersType> => {
    const dispatch = useDispatch();

    const filtersAndPageable = useSelectFiltersAndPageable(key, initialFiltersAndPageable);

    const updateFiltersAndPageableCallback = useCallback((filtersAndPageable: FiltersAndPageable<FiltersType>) => {
        dispatch(updateFiltersAndPageable({key, filtersAndPageable}));
    }, [key, dispatch]);

    const {data, isFetching} = query(filtersAndPageable, options);

    const page = useMemo(() => {
        if (data) {
            return {
                ...data,
                loading: isFetching
            };
        }

        return {
            content: [],
            number: filtersAndPageable.pageable.pageNumber,
            size: filtersAndPageable.pageable.pageSize,
            totalElements: 0,
            loading: isFetching
        };
    }, [data, isFetching, filtersAndPageable]);

    const loadData = useCallback((newFilters: FiltersType, newPageable: Pageable) => {
        updateFiltersAndPageableCallback({
            filters: newFilters,
            pageable: newPageable
        });
    }, [updateFiltersAndPageableCallback]);

    const updateQuickFilters = useCallback((quickFilters: Record<string, string | undefined>) => {
        updateFiltersAndPageableCallback({...filtersAndPageable, activeQuickFilters: quickFilters});
    }, [updateFiltersAndPageableCallback, filtersAndPageable]);

    return {
        page,
        loading: isFetching,
        filtersAndPageable,
        updateFiltersAndPageable: updateFiltersAndPageableCallback,
        updateQuickFilters,
        loadData
    };

};
