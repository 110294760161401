import React from "react";
import {Alert} from "react-bootstrap";
import {useTranslation} from "../../helpers/i18nUtils";

export interface PlannersWaarschuwingProps {
    waarschuwing?: string;
}

export const PlannersWaarschuwing: React.FC<PlannersWaarschuwingProps> = (props) => {
    const {waarschuwing} = props;

    const {t} = useTranslation("algemeen");

    if (!waarschuwing) {
        return null;
    }

    return (
        <Alert variant="warning">
            <strong>{t("Labels.waarschuwing-colon", "Waarschuwing:")}</strong> {waarschuwing}
        </Alert>
    );
};
