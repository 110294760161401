import React, {ReactNode} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import Spinner from "../../Spinner";
import {LoadingSkeleton} from "../LoadingSkeleton";
import {mdiChevronDown, mdiChevronRight} from "@mdi/js";
import Icon from "@mdi/react";

// fix voor onnodige warning: https://github.com/derrickpelletier/react-loading-overlay/pull/57#issuecomment-1054194254
import LoadingOverlay from "react-loading-overlay"
import {useTranslation} from "../../../helpers/i18nUtils";
LoadingOverlay.propTypes = undefined


export interface OverviewTableProps<T> {
    itemsLabel: string;
    loading: boolean;
    loadingOverlay?: boolean;
    items: Array<T>;
    page?: number;
    pageSize?: number;
    totalSize?: number;
    onTableChange?: (type, params) => void;
    rowClicked?: (T) => void;
    columns;
    defaultSorted?;
    selectRow?: any;
    expandRow?: (item: T) => ReactNode;
    isExpandable?: (item: T) => boolean;

    headerClasses?: string,
    wrapperClasses?: string,
    rowClasses?: string,
    hover?: boolean;
}


const OverviewTable = <T, >({
                                itemsLabel,
                                loading,
                                loadingOverlay,
                                items,
                                page,
                                pageSize,
                                totalSize,
                                onTableChange,
                                rowClicked,
                                columns,
                                defaultSorted,
                                selectRow,
                                expandRow,
                                isExpandable = () => true,
                                headerClasses,
                                wrapperClasses,
                                rowClasses,
                                hover = true
                            }: OverviewTableProps<T>) => {
    const {t} = useTranslation("algemeen");

    let paginationOptions: any = undefined;

    if (page !== undefined) {
        paginationOptions = {
            page: page,
            paginationSize: 10,
            pageStartIndex: 1,
            withFirstAndLast: true,
            firstPageText: t("Tabel.eerste", "Eerste"),
            prePageText: t("Tabel.vorige", "Vorige"),
            nextPageText: t("Tabel.volgende", "Volgende"),
            lastPageText: t("Tabel.laatste", "Laatste"),
            firstPageTitle: t("Tabel.ga-naar-de-eerste-pagina", "Ga naar de eerste pagina"),
            prePageTitle: t("Tabel.ga-naar-de-vorige-pagina", "Ga naar de vorige pagina"),
            nextPageTitle: t("Tabel.ga-naar-de-volgende-pagina", "Ga naar de volgende pagina"),
            lastPageTitle: t("Tabel.ga-naar-de-laatste-pagina", "Ga naar de laatste pagina"),
            paginationTotalRenderer: (from, to, size) => {
                if (size > 0) {
                    return <span
                        className="react-bootstrap-table-pagination-total ml-2">{t("Tabel.pagina-resultaten", "{{van}} tot {{tot}} van {{totaal}} resultaten", {van: from, tot: to, totaal: size})}</span>;
                } else {
                    return null;
                }
            },
            showTotal: true,
            totalSize: totalSize,
            sizePerPageList: [
                {
                    text: "10",
                    value: 10,
                },
                {
                    text: "50",
                    value: 50,
                },
            ],
        };
    }

    const noDataAndLoading = (
        <div className="bg-white" style={{margin: "-0.95rem", padding: "0.95rem"}}>
            {Array.from(Array(paginationOptions?.paginationSize || 10)).map((_, i) => (
                <div style={{marginBottom: "1em", textAlign: "left"}} key={i}>
                    <LoadingSkeleton width="45%"/>
                    <LoadingSkeleton height={16}/>
                </div>
            ))}
        </div>
    );

    const keyField = "id";
    const nonExpandableKeys = (items || []).filter(item => !isExpandable(item)).map(item => item[keyField]);

    return (
        <BootstrapTable
            bootstrap4
            keyField={keyField}
            noDataIndication={() => (loading ? noDataAndLoading : <span>{t("Tabel.geen-gevonden", "Geen {{item}} gevonden", {item: itemsLabel})}</span>)}
            loading={items && items.length > 0 && loading}
            overlay={loadingOverlay ? overlayFactory({
                spinner: <Spinner/>,
                styles: {
                    overlay: (base) => ({
                        ...base,
                        background: "rgba(192, 192, 192, 0.15)"
                    }),
                    content: (base) => ({
                        ...base,
                        // margin: "1em auto"
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    })
                }
            }) : undefined}
            onTableChange={onTableChange}
            fetchInfo={{dataTotalSize: totalSize}}
            data={items || []}
            remote
            columns={columns}
            defaultSorted={defaultSorted}
            pagination={paginationOptions ? paginationFactory(paginationOptions) : undefined}
            bordered={false}
            headerClasses={headerClasses || "thead-light"}
            wrapperClasses={wrapperClasses || "table-responsive"}
            rowClasses={rowClasses || "cursor-pointer"}
            hover={hover}
            selectRow={selectRow}
            rowEvents={{
                onClick: (e, row, rowIndex) => {
                    if (rowClicked) {
                        rowClicked(row);
                    }
                },
            }}
            expandRow={expandRow
                ? {
                    nonExpandable: nonExpandableKeys,
                    renderer: (row) => {
                        return (
                            <div className="expanded-row">
                                {expandRow(row)}
                            </div>
                        );
                    },
                    expandColumnRenderer: ({expanded, expandable}) => {
                        if (!expandable) {
                            return null;
                        }

                        const icon = expanded ? mdiChevronDown : mdiChevronRight;

                        return <Icon path={icon} size="16px"/>
                    },
                    expandHeaderColumnRenderer: ({isAnyExpands}) => (<span/>),
                    showExpandColumn: true,
                    expandByColumnOnly: false,
                    parentClassName: "expanded-row-parent"
                }
                : undefined
            }
        />
    );
};

export default OverviewTable;
