import React, {useMemo} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {
    BezoekAanvraagEditableInstructie
} from "../../../../../components/aqualex/bezoekaanvraag/BezoekAanvraagEditableInstructie";
import {BezoekAanvraagPrestatiesTable} from "../../../bezoekaanvraag/detail/BezoekAanvraagPrestatiesTable";
import {
    Co2LeveringOpdrachtModel,
    DagPlanningEntryModel,
    DagPlanningModel,
    OpdrachtType
} from "../../../../../redux/planning/types";
import Datum from "../../../../../components/aqualex/Datum";
import BezoekAanvraagStatusBadge from "../../../../../components/aqualex/bezoekaanvraag/BezoekAanvraagStatusBadge";
import GecommuniceerdIcon from "../../../../../components/aqualex/bezoekaanvraag/GecommuniceerdIcon";
import {Co2Artikels} from "../../../../../components/aqualex/artikel/Co2Artikels";
import {Openingsuren} from "../../../../../components/aqualex/klant/Openingsuren";
import {parseDate} from "../../../../../helpers/DateUtils";
import {Co2LeveringUtils} from "../../../../../helpers/co2LeveringUtils";
import {Co2ArtikelModel} from "../../../../../redux/co2bestelling/types";
import {useGetCo2Artikelen} from "../../../../../redux/artikel/api";
import {useGetDagPlanningById} from "../../../../../redux/planning/api";
import {useGetKlantById} from "../../../../../redux/klant/api";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetVerzendAdresById} from "../../../../../redux/verzendadres/api";
import {useGetBezoekAanvraagById} from "../../../../../redux/bezoekaanvraag/api";
import {Opsomming} from "../../../../../components/aqualex/Opsomming";
import {useTranslation} from "../../../../../helpers/i18nUtils";

export interface PlanningBezoekInfoPanelCardProps {
    dagPlanning: DagPlanningModel;
    entry: DagPlanningEntryModel;
}

export const PlanningBezoekInfoPanelCard: React.FC<PlanningBezoekInfoPanelCardProps> = (props) => {
    const {dagPlanning, entry} = props;

    const {t} = useTranslation("planning");

    const bezoekAanvraagInstructie = entry.bezoek?.bezoekAanvraagInstructie;
    const verzendAdresInstuctie = entry.bezoek?.verzendAdresInstuctie;

    const {data: klant} = useGetKlantById(entry.bezoek?.klant?.id || skipToken);
    const {data: verzendAdres} = useGetVerzendAdresById(entry.bezoek?.verzendAdres?.id || skipToken);
    const {data: bezoekAanvraag} = useGetBezoekAanvraagById(entry.bezoek?.bezoekAanvraagId || skipToken);

    const co2BestellingPrestaties = bezoekAanvraag?.prestaties?.filter(item => item.opdracht.type === OpdrachtType.CO2_LEVERING) || [];
    const co2Bestellingen = co2BestellingPrestaties.map(item => item.opdracht as Co2LeveringOpdrachtModel).map(item => item.co2Bestelling);
    const co2BestellingenGecombineerd = useMemo(() => Co2LeveringUtils.combineer(co2Bestellingen), [co2Bestellingen]);

    const {data: co2Artikelen} = useGetCo2Artikelen({klantId: klant?.id}, {skip: !klant});

    const {refetch: refreshDagPlanning} = useGetDagPlanningById(dagPlanning.id);

    const co2ArtikelenOmTeTonen = useMemo(() => {
        const co2ArtikelenMetAantal: (Co2ArtikelModel & { aantal: number })[] = [];

        for (const co2Artikel of co2Artikelen || []) {
            const aantal = co2BestellingenGecombineerd[co2Artikel.id]?.aantalTotaal || 0;

            if (aantal > 0 || co2Artikel.inGebruik) {
                co2ArtikelenMetAantal.push({...co2Artikel, aantal});
            }
        }

        return co2ArtikelenMetAantal;
    }, [co2Artikelen, co2BestellingenGecombineerd]);

    const onSuccessCallback = () => {
        refreshDagPlanning()
    };

    return (
        <Card>
            <Card.Body>
                <div className="info-panel" onClick={(event) => event.stopPropagation()}>
                    {bezoekAanvraag && (
                        <>
                            <h4 className="mt-0">{bezoekAanvraag.nummer} <BezoekAanvraagStatusBadge
                                status={bezoekAanvraag.status}/></h4>

                            {!!bezoekAanvraag?.gecommuniceerdeDatums?.length && (
                                <p>
                                    <GecommuniceerdIcon geplandeDatum={dagPlanning.datum}
                                                        gecommuniceerdeDatums={entry.bezoek?.gecommuniceerdeDatums || []}
                                                        gecommuniceerdOpTijdstip={entry.bezoek?.gecommuniceerdOpTijdstip}/>{" "}
                                    <Opsomming
                                        items={bezoekAanvraag.gecommuniceerdeDatums.map(datum => <Datum datum={datum}
                                                                                                        showTime={false}/>)}/>
                                </p>
                            )}

                            <div className="mb-2">
                                <BezoekAanvraagEditableInstructie
                                    bezoekAanvraagId={bezoekAanvraag.id}
                                    rows={2}
                                    initialValue={bezoekAanvraagInstructie}
                                    onSuccessCallback={onSuccessCallback}
                                />
                            </div>
                        </>
                    )}

                    <Row>
                        <Col>
                            <label>{t("Labels.vaste-adresinstructie", "Vaste adresinstructie")}</label>
                            <p>
                                {verzendAdresInstuctie}
                                {!verzendAdresInstuctie && <span className="text-muted">{t("algemeen:Labels.geen", "Geen")}</span>}
                            </p>
                        </Col>
                        <Col>
                            <label>{t("Labels.openingsuren", "Openingsuren")}</label>
                            <p>
                                <Openingsuren openingsuren={verzendAdres?.openingsuren}
                                              datum={parseDate(dagPlanning.datum)}/>
                            </p>
                        </Col>
                    </Row>

                    <label>{t("Labels.co2", "CO₂")}</label>
                    <Co2Artikels co2Artikelen={co2ArtikelenOmTeTonen}
                                 klantVoorBestelling={klant}
                                 verzendAdresVoorBestelling={verzendAdres}
                                 clickable={false}
                                 boxProps={{size: "md"}}
                                 colProps={{md: 6}}

                                 renderItem={(children, {aantal, inGebruik}) => (
                                     <div className="d-flex align-items-center">
                                         <small className="mr-1">{aantal} x</small>
                                         <div>{children}</div>
                                         {inGebruik && <small className="text-muted ml-1">{t("Labels.(in-gebruik)", "(in gebruik)")}</small>}
                                     </div>
                                 )}
                    />

                    <label>{t("Labels.prestaties", "Prestaties")}</label>
                    {bezoekAanvraag &&
                        <BezoekAanvraagPrestatiesTable bezoekAanvraag={bezoekAanvraag}/>}
                </div>
            </Card.Body>
        </Card>
    );
};
