import React from "react";

export interface EditableControlStyleProps {}

export const EditableControlStyle: React.FC = (props) => {
    return (
        <div className="editable-text">
            {props.children}
        </div>
    );
};