// @flow
import {all, fork, takeEvery} from 'redux-saga/effects';


import {LOGOUT_USER} from './constants';

import {authClient} from "../../auth/client";

/**
 * Logout the user
 * @param {*} param0
 */
function logout({payload: {history}}) {
    try {
        authClient.logoutRedirect({
            postLogoutRedirectUri: window.location.origin,
            extraQueryParameters: {id_token_hint: authClient.getActiveAccount().idToken || ""}
        });
    } catch (error) {
    }
}

export function* watchLogoutUser(): any {
    yield takeEvery(LOGOUT_USER, logout);
}


function* authSaga(): any {
    yield all([fork(watchLogoutUser)]);
}

export default authSaga;
