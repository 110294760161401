import React from "react";
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {TextAreaFormField} from "../../../components/aqualex/form/TextAreaFormField";
import {useHideModal} from "../../../redux/ui/hooks";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "react-i18next";

export interface AnnuleerCo2BestellingFormValues {
    reden: string;
}

export interface AnnuleerCo2BestellingModalProps {
    onBevestigHandler: (form: AnnuleerCo2BestellingFormValues) => void
}

export const AnnuleerCo2BestellingModal: React.FC<AnnuleerCo2BestellingModalProps> = (props) => {
    const {onBevestigHandler} = props;

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const onBevestig = (values: AnnuleerCo2BestellingFormValues) => {
        hideModal();
        onBevestigHandler(values);
    };

    const initialValues: AnnuleerCo2BestellingFormValues = {
        reden: ""
    };

    const {t} = useTranslation("co2-bestelling");

    const redenIsVerplichtLabel = t("Foutmeldingen.reden-is-verplicht", "Reden is verplicht");

    return (
        <Modal show onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.co2-bestelling-annuleren", "CO₂-bestelling annuleren")}</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                validateOnMount // to make validateOnMount, initialValues must be created using useMemo
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    reden: Yup.string()
                        .required(redenIsVerplichtLabel)})}>
                {({
                      handleSubmit,
                      isValid,
                    values
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <h5>{t("Titels.reden", "Reden")}</h5>
                            <Form.Row>
                                <FormGroup id="reden" as={Col}>
                                    <TextAreaFormField name="reden"/>
                                </FormGroup>
                            </Form.Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="light" onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}
                            <Button variant="primary" disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};

