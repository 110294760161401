import {default as React, useState} from "react";
import {SortOrder} from "../../../../../redux/support/pagination";
import {
    MultiSelectBezoekAanvraagTable
} from "../../../../../components/aqualex/bezoekaanvraag/MultiSelectBezoekAanvraagTable";
import {useHideModal} from "../../../../../redux/ui/hooks";
import {
    BezoekAanvraagGewensteDatumColumn,
    BezoekAanvraagNrColumn,
    BezoekAanvraagPrestatieSamenvattingColumn,
    BezoekAanvraagStatusColumn
} from "../../../../../components/aqualex/bezoekaanvraag/BezoekAanvraagOverzichtTable";
import {Button} from "react-bootstrap";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../../components/aqualex/Modal";
import {
    BezoekAanvraagFilters,
    BezoekAanvraagModel,
    BezoekAanvraagOverzichtModel,
    BezoekAanvraagStatus
} from "../../../../../redux/bezoekaanvraag/types";
import {useFilteredAndPagedSearch} from "../../../../../redux/support/hooks";
import {useSearchBezoekAanvragen} from "../../../../../redux/bezoekaanvraag/api";
import {useTranslation} from "../../../../../helpers/i18nUtils";
import {FiltersAndPageable} from "../../../../../redux/support/uiSlice";

export interface MergeBezoekAanvraagModalProps {
    bezoekAanvraag: BezoekAanvraagModel;
    onBevestigHandler: (bezoekIds: string[]) => void;
}

export const MergeBezoekAanvraagModal: React.FC<MergeBezoekAanvraagModalProps> = ({
                                                                                      bezoekAanvraag,
                                                                                      onBevestigHandler,
                                                                                  }) => {
    const {t} = useTranslation("planning");

    const hideModal = useHideModal();
    const [geselecteerdeBezoekAanvragen, setGeselecteerdeBezoekAanvragen] = useState<string[]>([])

    const initialFiltersAndPageable: FiltersAndPageable<BezoekAanvraagFilters> = {
        filters: {
            statusIn: [BezoekAanvraagStatus.NIEUW, BezoekAanvraagStatus.TE_PLANNEN],
            verzendAdresId: bezoekAanvraag.verzendAdres.id,
            exclude: [bezoekAanvraag.id]
        },
        pageable: {
            pageNumber: 1,
            pageSize: 10,
            sortField: "vanDatum",
            sortOrder: SortOrder.asc,
        },
        activeQuickFilters: {}
    };

    const {
        page,
        filtersAndPageable,
        loadData
    } = useFilteredAndPagedSearch<BezoekAanvraagOverzichtModel, BezoekAanvraagFilters>("bezoekaanvraag.plan.accessoirelevering", useSearchBezoekAanvragen, initialFiltersAndPageable);


    const onBezoekIdsSelectedUpdate = (geselecteerdeBezoekAanvragen: string[]) => {
        setGeselecteerdeBezoekAanvragen(geselecteerdeBezoekAanvragen);
    }

    const onAnnuleer = () => {
        hideModal();
    }

    const onBevestig = () => {
        hideModal();

        onBevestigHandler(geselecteerdeBezoekAanvragen);
    }

    return (
        <Modal show={true} onHide={onAnnuleer} autoFocus size="lg">
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("MergeBezoekAanvraagModal.bezoekaanvragen-samenvoegen", "Bezoekaanvragen samenvoegen")}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <p>{t("MergeBezoekAanvraagModel.selecteer-bezoekaanvragen-om-samen-te-voegen", "Selecteer bezoekaanvragen om samen te voegen met deze bezoekaanvraag. De geselecteerde bezoekaanvragen worden geannuleerd.")}</p>

                <MultiSelectBezoekAanvraagTable page={page}
                                                pageable={filtersAndPageable.pageable}
                                                filters={filtersAndPageable.filters}
                                                loadData={loadData}
                                                columns={[
                                                    BezoekAanvraagNrColumn,
                                                    BezoekAanvraagStatusColumn,
                                                    BezoekAanvraagPrestatieSamenvattingColumn,
                                                    BezoekAanvraagGewensteDatumColumn,
                                                ]}
                                                primaryFilterFields={[]}

                                                geselecteerdeBezoeken={geselecteerdeBezoekAanvragen}
                                                onBezoekIdsSelectedUpdate={onBezoekIdsSelectedUpdate}
                />
            </ModalBody>
            <ModalFooter>
                <Button color='light' onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                <Button color='primary' disabled={geselecteerdeBezoekAanvragen.length === 0} onClick={onBevestig}>
                    {t("Buttons.voeg-samen", "Voeg samen")}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
