import React, {CSSProperties} from "react";
import {EditableTextArea} from "../EditableTextArea";
import {useUpdateBezoekAanvraag} from "../../../redux/bezoekaanvraag/api";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface BezoekAanvraagEditableInstructieProps {
    bezoekAanvraagId: string;
    onSuccessCallback?: () => void;

    rows: number;

    initialValue?: string;

    style?: CSSProperties;
    className?: string;

    textAreaStyle?: CSSProperties;
    textAreaClassName?: string;
}

export const BezoekAanvraagEditableInstructie: React.FC<BezoekAanvraagEditableInstructieProps> = (props) => {
    const {
        bezoekAanvraagId,
        rows,
        initialValue,
        style,
        className,
        textAreaStyle,
        textAreaClassName,
        onSuccessCallback
    } = props;

    const {t} = useTranslation("planning");

    const [updateBezoekAanvraag, {isLoading: loading}] = useUpdateBezoekAanvraag();

    const onSaveClick = (value?: string) => {
        updateBezoekAanvraag({
            id: bezoekAanvraagId,
            techniekerInstructie: value
        }).then(onSuccessCallback);
    };

    return (
        <EditableTextArea rows={rows} style={style} className={className} controlStyle={textAreaStyle}
                          controlClassName={textAreaClassName}
                          initialValue={initialValue} onSubmit={onSaveClick} loading={loading}
                          emptyLabel={t("BezoekAanvraagEditableInstructie.voeg-techniekerinstructie-toe", "Voeg techniekerinstructie toe...")}/>
    );
};
