import {useCallback} from "react";
import {useDispatch} from 'react-redux';
import {hideModal, showModal} from "./actions";
import {BackOfficeModals} from "../../components/aqualex/ModalRoot";

export const useShowModal = () => {
   const dispatch = useDispatch();

   return useCallback((modalType: BackOfficeModals, modalProps: {}) => {
      dispatch(showModal(modalType, modalProps));
   }, [dispatch]);
};

export const useHideModal = () => {
   const dispatch = useDispatch();

   return useCallback(() => {
      dispatch(hideModal());
   }, [dispatch]);
};

export const useModal = () => {
   const dispatch = useDispatch();

   const showModalCallback = useCallback((modalType: BackOfficeModals, modalProps: {}) => {
      dispatch(showModal(modalType, modalProps));
   }, [dispatch]);

   const hideModalCallback = useCallback(() => {
      dispatch(hideModal());
   }, [dispatch]);

   return {
      showModal: showModalCallback,
      hideModal: hideModalCallback
   };
};
