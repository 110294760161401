import * as React from "react";
import {useCallback, useState} from "react";
import Select, {components} from "react-select";

import {OperationeelToestelModel} from "../../../redux/toestel/types";
import ToestelBox from "./ToestelBox";
import ToestelLabel from "./ToestelLabel";
import {useSearchToestellen} from "../../../redux/toestel/api";
import {skipToken} from "@reduxjs/toolkit/query";
import {SortOrder} from "../../../redux/support/pagination";
import debounce from "lodash.debounce";
import {useTranslation} from "../../../helpers/i18nUtils";

interface ToestelSelectorProps {
    klantId: string;
    verzendAdresId?: string;
    geselecteerdeToestel?: OperationeelToestelModel;
    toestelChanged: (toestel) => any;
    isInvalid?: boolean;
    disabled?: boolean;
    isClearable?: boolean;
    placeholder?: string;
}

const SingleValue = props => (
    <components.SingleValue {...props}>
        <ToestelLabel toestel={props.data}/>
    </components.SingleValue>
);


const pageable = {
    pageNumber: 1,
    pageSize: 20,
    sortField: "nr",
    sortOrder: SortOrder.asc
};

const ToestelSelector: React.FC<ToestelSelectorProps> = (props) => {
    const {
        klantId,
        verzendAdresId,
        geselecteerdeToestel,
        toestelChanged,
        isInvalid,
        isClearable,
        placeholder,
        disabled,
    } = props;

    const {t} = useTranslation("toestel");

    const [filter, setFilter] = useState("");

    // eslint-disable-next-line
    const handleInputChange = useCallback(
        debounce(inputValue => setFilter(inputValue), 300),
        []
    );

    const {data, isLoading: loading} = useSearchToestellen(
        (klantId || verzendAdresId) // 1 van klantId of verzendAdresId moet gegeven zijn, anders skip
            ? {
                filters: {
                    ...(filter && {filter}),
                    ...(klantId && {klantId}),
                    ...(verzendAdresId && {verzendAdresId}),
                },
                pageable: pageable,
            }
            : skipToken);

    const toestellen = data?.content || [];

    return (
        <Select
            className={"react-select-container" + (isInvalid ? " is-invalid" : "")}
            classNamePrefix="react-select"
            value={geselecteerdeToestel || null}
            isLoading={loading}
            loadingMessage={() => t("algemeen:Labels.laden-dot-dot-dot", "Laden...")}
            filterOption={() => true} // completely bypass filtering
            options={toestellen}
            onChange={toestel => {
                toestelChanged(toestel)
            }}
            isDisabled={disabled}
            placeholder={placeholder || t("Placeholders.type-om-toestellen-te-zoeken", "Typ om toestellen te zoeken...")}

            getOptionValue={toestel => toestel.id}

            formatOptionLabel={toestel => <ToestelBox toestel={toestel} clickable={false}/>}
            components={{SingleValue}}
            isClearable={isClearable !== undefined ? isClearable : true}

            noOptionsMessage={() => t("Labels.geen-toestellen-gevonden", "Geen toestellen gevonden")}

            onInputChange={(inputValue, reason) => {
                if (reason.action === 'set-value' || reason.action === 'menu-close' || reason.action === 'input-blur') {
                    // set-value, menu-close en input-blur triggeren reset van input value, en dus ook een 'reset' van de dropdown list
                    return;
                }

                // if (reason.action === "input-change") {
                handleInputChange(inputValue);
                // }
            }}
        />
    )
};

export default ToestelSelector;
