import {ReduxAction} from "./types";
import {ErrorInfo} from "../ui/types";

export const actionTypes = (type: string) => ({
    REQUEST: `${type}_REQUEST`,
    SUCCESS: `${type}_SUCCESS`,
    FAILURE: `${type}_FAILURE`,
    toString: () => type,
});

export const action = <TType, TPayload, TMeta>(type: TType, payload: TPayload, meta?: TMeta): ReduxAction<TType, TPayload, TMeta> => ({
    type,
    payload,
    meta
});

export const errorAction = <TType, TPayload = ErrorInfo, TMeta = any>(type: TType, error: TPayload, meta?: TMeta): ReduxAction<TType, TPayload, TMeta> => ({
    type,
    payload: error,
    error: true,
    meta
});


// voorbeeld van createActions
// export const [searchMedewerkerRequest_b, searchMedewerkerSuccess_b, searchMedewerkerFailure_b] = createActions<
//     { filters: MedewerkerFilters, pageable: Pageable },
//     EntityPage<MedewerkerModel>,
//     SearchMedewerkerAction>([SEARCH_MEDEWERKER_REQUEST, SEARCH_MEDEWERKER_SUCCESS, SEARCH_MEDEWERKER_FAILURE]);
