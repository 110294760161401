import {default as React} from "react";
import Select from "react-select";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

export const findOption = (options: any[], value?: string) => {
    const flatOptions: any[] = [];

    for (const option of options || []) {
        if (option.options) {
            flatOptions.push(...option.options);
        } else {
            flatOptions.push(option);
        }
    }

    return flatOptions.find(item => item.value === value);
};

export const ReactSelectFormField: React.FC<FormikFormFieldProps> = ({className, disabled, selectProps, ...props}) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <Select
                    name={field.name}
                    value={findOption(selectProps?.options, field.value) || null}
                    isDisabled={disabled}
                    onBlur={field.onBlur}
                    onChange={value => {
                        helpers.setValue(value == null ? null : value.value);
                    }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    defaultValue={field.value}
                    isClearable={false}
                    styles={{
                        control: provided => (!!meta.error ? {
                            ...provided,
                            borderColor: "#fa5c7c",
                            boxShadow: "none",
                            '&:hover': {
                                borderColor: "#fa5c7c",
                            }
                        } : provided),
                    }}
                    {...selectProps}
                />
            )}
        </FormikFormField>
    );
};
