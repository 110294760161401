import React, {useMemo, useState} from "react";
import ImageLightbox from "react-image-lightbox";
import {FileDownloadLink} from "./FileDownloadLink";

export interface LightboxImageItem {
    url: string;
    title?: string;
    caption?: string;
}

export interface LightboxProps {
    open?: boolean;
    images: LightboxImageItem[];

    downloadButton?: boolean;

    onClose?(): void;
}

export const Lightbox: React.FC<LightboxProps> = (props) => {
    const {images, open, downloadButton, onClose} = props;

    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const currentPhoto = images[currentPhotoIndex];

    const toolbarButtons = useMemo(() => {
        if (downloadButton) {
            return [
                <FileDownloadLink uri={currentPhoto.url} omschrijving={currentPhoto.caption} className="ril__toolbarItemChild ril__builtinButton">
                    <i className="mdi mdi-download text-white d-block mr-2" style={{fontSize: 26, marginTop: 2, opacity: 0.7}} />
                </FileDownloadLink>
            ];
        }

        return [];
    }, [downloadButton, currentPhoto?.caption, currentPhoto?.url]);

    if (!open) {
        return null;
    }

    return (
        <ImageLightbox mainSrc={currentPhoto.url}
                       nextSrc={images[currentPhotoIndex + 1]?.url}
                       prevSrc={images[currentPhotoIndex - 1]?.url}
                       imageTitle={currentPhoto.title}
                       imageCaption={currentPhoto.caption}

                       onCloseRequest={() => onClose?.()}

                       toolbarButtons={toolbarButtons}

                       onMovePrevRequest={() => setCurrentPhotoIndex((currentPhotoIndex + images.length - 1) % images.length)}
                       onMoveNextRequest={() => setCurrentPhotoIndex((currentPhotoIndex + 1) % images.length)}
        />
    );
};
