import * as React from "react";
import {Card, Col, Row} from "react-bootstrap";
import {Link} from 'react-router-dom';
import ArtikelNr from "../connected/ArtikelNr";
import {ArtikelModel} from "../../../redux/artikel/types";

interface UnclickableArtikelBoxProps {
    artikelId?: string;
    artikel?: ArtikelModel;
}

const UnclickableArtikelBox: React.FC<UnclickableArtikelBoxProps> = ({artikelId, artikel}) => {
    return (
        <Card className="mb-1 shadow-none">
            <div>
                <Row className="align-items-center">
                    <Col className="col-auto">
                        <div className="avatar-sm">
                             <span className="avatar-title bg-primary-lighten text-primary rounded">
                                <i className="dripicons-flag"></i>
                            </span>
                        </div>
                    </Col>
                    <Col className="pl-0">
                        <strong><ArtikelNr artikelId={artikelId} artikel={artikel}/></strong> {artikel?.omschrijving}
                    </Col>
                </Row>
            </div>
        </Card>
    );
};


interface ArtikelBoxProps {
    artikelId?: string;
    artikel?: ArtikelModel;
    clickable: boolean;
}
const ArtikelBox: React.FC<ArtikelBoxProps> = ({artikelId, artikel, clickable}) => {
    if (clickable) {
        return <Link to={`/artikel/${artikelId || artikel?.id}`}>
            <UnclickableArtikelBox artikelId={artikelId} artikel={artikel}/>
        </Link>;
    } else {
        return <UnclickableArtikelBox artikelId={artikelId} artikel={artikel}/>
    }
};

export default ArtikelBox;
