declare var google: any

export default class GoogleMapsApi {

    static fetchDirections(places: Array<{ lat: number, lng: number }>): Promise<any> {
        const waypoints = places.map(p => ({
            location: p,
            stopover: true,
        }))

        const origin = waypoints.shift()?.location;
        const destination = waypoints.pop()?.location;

        return new Promise((resolve, reject) => {
            const directionsService = new google.maps.DirectionsService();

            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    travelMode: google.maps.TravelMode.DRIVING,
                    waypoints: waypoints
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        resolve(result);
                    } else {
                        reject(result);
                    }
                }
            );
        });
    }

}