import {default as React} from "react";
import CreateVerzendAdresModal from "./CreateVerzendAdresModal";
import {useHideModal} from "../../../redux/ui/hooks";
import {CreateVerzendAdresForm, VerzendAdresModel} from "../../../redux/verzendadres/types";
import {CreateVerzendAdresFormValues} from "./CreateVerzendAdresFormContainer";
import {useNieuwVerzendAdres} from "../../../redux/verzendadres/api";

export interface CreateVerzendAdresModalContainerProps {
    initialValues?: CreateVerzendAdresFormValues;
    klantId?: string;

    meta?: any;

    onCancel?(): void;

    onSuccessCallback?(verzendAdresModel: VerzendAdresModel, meta?: any): void;
}

export const CreateVerzendAdresModalContainer: React.FC<CreateVerzendAdresModalContainerProps> = (props) => {
    const {initialValues, klantId, onSuccessCallback, onCancel, meta} = props;

    const hideModal = useHideModal();
    const [nieuwVerzendAdres, {isLoading: loading}] = useNieuwVerzendAdres();

    const onSubmit = async (form: CreateVerzendAdresForm) => {
        const result = await nieuwVerzendAdres(form) as { data: VerzendAdresModel };

        hideModal();

        if (result?.data && onSuccessCallback) {
            onSuccessCallback(result.data, meta);
        }
    };

    const defaultOnCancel = () => {
        hideModal();
    };

    return (
        <CreateVerzendAdresModal klantId={klantId} initialValues={initialValues} loading={loading} onSubmit={onSubmit}
                                 onCancel={onCancel || defaultOnCancel}/>
    );
};

