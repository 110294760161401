import {default as React} from "react";
import {KlantFilters} from "../../../redux/klant/types";
import KlantSelector from "../klant/KlantSelector";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

export interface KlantFormFieldProps {
    searchFilters?: KlantFilters;
}

export const KlantFormField: React.FC<FormikFormFieldProps & KlantFormFieldProps> = ({
                                                                                         searchFilters,
                                                                                         ...props
                                                                                     }) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <KlantSelector {...field}
                               klantChanged={async klant => {
                                   await helpers.setValue(klant);

                                   props.onChange && props.onChange(klant);
                               }}
                               geselecteerdeKlant={field.value}
                               isInvalid={!!meta.error}
                               disabled={props.disabled}
                />
            )}
        </FormikFormField>
    );
};
