import {default as React, useCallback, useMemo} from "react";
import {hideModal} from "../../../redux/ui/actions";
import {useDispatch} from 'react-redux';
import {Formik, FormikHelpers} from "formik";
import * as Yup from 'yup';
import {KlantModel} from "../../../redux/klant/types";
import {VerzendAdresModel} from "../../../redux/verzendadres/types";
import {Co2ArtikelModel, ExtraCo2FlesBeleid, NieuweCo2BestellingForm} from "../../../redux/co2bestelling/types";
import {TransportKostConfiguratieType} from "../../../redux/types";
import {transportKostValidatie} from "../../../components/aqualex/form/TransportKostConfiguratieForm";
import {Modal, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import Config from "../../../helpers/Config";
import {NieuweCo2BestellingFormikForm} from "./NieuweCo2BestellingFormikForm";
import {useTranslation} from "../../../helpers/i18nUtils";

export type NieuweCo2BestellingModalProps = {
    defaultValues?: Partial<BestelCo2FormValues>;

    onBevestigHandler: (form: NieuweCo2BestellingForm) => PromiseLike<any>;
};

export interface BestelCo2FormValues {
    klant?: KlantModel;
    verzendAdres?: VerzendAdresModel;
    besteldArtikel?: Co2ArtikelModel;
    aantal?: number;
    extraAantal?: number;
    klantReferentieNummer?: string;
    emailAdressen?: {emailAdres: string}[];
    "emailAdressen:validate"?();
    aangevraagdeLevertermijn?: string;
    extraAanvraagInfo?: string;
    transportKostType?: string;
    transportKostValue?: number;
    transportKostInherit: boolean;
    extraCo2FlesBeleidInherit: boolean;
    extraCo2FlesBeleid?: ExtraCo2FlesBeleid;
}

const NieuweCo2BestellingModal: React.FC<NieuweCo2BestellingModalProps> = (props) => {
    const {onBevestigHandler, defaultValues} = props;

    const {t} = useTranslation("co2-bestelling");

    const dispatch = useDispatch();

    const onAnnuleer = () => {
        dispatch(hideModal());
    }

    const bevestigHandler = useCallback(async (values: BestelCo2FormValues, helpers: FormikHelpers<any>) => {
        try {
            await onBevestigHandler({
                klantId: values.klant!.id,
                verzendAdresId: values.verzendAdres!.id,
                besteldArtikelId: values.besteldArtikel!.id,
                aantal: values.aantal!,
                extraAantal: values.extraAantal,
                klantReferentieNummer: values.klantReferentieNummer,
                emailAdressen: values.emailAdressen?.map(item => item.emailAdres) || [],
                aangevraagdeLevertermijn: values.aangevraagdeLevertermijn!,
                extraAanvraagInfo: values.extraAanvraagInfo,
                transportKostType: values.transportKostInherit ? TransportKostConfiguratieType.INHERIT : values.transportKostType as TransportKostConfiguratieType,
                transportKostValue: values.transportKostValue || undefined,
                extraCo2FlesBeleid: values.extraCo2FlesBeleidInherit ? ExtraCo2FlesBeleid.INHERIT : values.extraCo2FlesBeleid as ExtraCo2FlesBeleid
            });

            dispatch(hideModal());
        } finally {
            helpers.setSubmitting(false);
        }
    }, [dispatch, onBevestigHandler]);

    const maximumBestelbaar = artikel => Config.CO2_BESTELLING_MAXIMUM_BESTELBAAR_AANTAL_FACTOR * (artikel?.co2Kenmerken?.maximumBestelbaar || Config.CO2_BESTELLING_MAXIMUM_BESTELBAAR_AANTAL_FALLBACK);

    const schema = useMemo(() => Yup.object({
        klant: Yup.object().nullable()
            .required(t("Foutmeldingen.klant-is-verplicht", "Klant is verplicht") as string),
        verzendAdres: Yup.object().nullable()
            .required(t("Foutmeldingen.verzendadres-is-verplicht", "Verzendadres is verplicht") as string),
        besteldArtikel: Yup.object().nullable()
            .required(t("Foutmeldingen.artikels-is-verplicht", "Artikel is verplicht") as string),
        aangevraagdeLevertermijn: Yup.string()
            .required(t("Foutmeldingen.moment-van-levering-is-verplicht", "Moment van levering is verplicht") as string),
        aantal: Yup.number().nullable()
            .min(1, t("Foutmeldingen.aantal-moet-groter-dan-of-gelijk-aan-1-zijn", "Aantal moet groter dan of gelijk aan 1 zijn") as string)
            .test("maximum-aantal", "maximum", function (value) {
                const {besteldArtikel} = this.parent;

                const maxBestelbaar = maximumBestelbaar(besteldArtikel);
                if (!!value && value > maxBestelbaar) {
                    return this.createError({message:
                            t("Foutmeldingen.maximum-is-x", "Maximum is {{maximum}}", {maximum: maxBestelbaar}) as string});
                }

                return true;
            })
            .required(t("Foutmeldingen.aantal-is-verplicht", "Aantal is verplicht") as string)
            .when(["extraAantal", "besteldArtikel"], {
                is: (extraAantal, besteldArtikel) => besteldArtikel?.co2Kenmerken?.hervulbaar && extraAantal !== undefined && extraAantal !== null,
                then: Yup.number().nullable()
                    .min(0, t("Foutmeldingen.aantal-moet-groter-dan-of-gelijk-aan-0-zijn",
                        "Aantal moet groter dan of gelijk aan 0 zijn") as string)
                    .required("Totaal aantal moet groter dan of gelijk aan 1 zijn")
                    .test("som-positief-aantal",
                        t("Foutmeldingen.totaal-aantal-moet-groter-dan-of-gelijk-aan-1-zijn",
                            "Totaal aantal moet groter of gelijk aan 1 zijn") as string,
                        function (value) {
                        const {extraAantal} = this.parent;

                        return value + extraAantal > 0;
                    })
                    .test("som-maximum-aantal", "maximum", function (value) {
                        const {extraAantal, besteldArtikel} = this.parent;

                        const maxBestelbaar = maximumBestelbaar(besteldArtikel);
                        if ((value + extraAantal) > maxBestelbaar) {
                            return this.createError({
                                message: t("Foutmeldingen.maximum-is-x",
                                    "Maximum is {{maximum}}",
                                    {maximum: maxBestelbaar}) as string
                            })
                        }

                        return true;
                    })
            }),
        extraAantal: Yup.number().nullable()
            .min(0, "Aantal moet groter dan of gelijk aan 0 zijn")
            .test("som-positief-extraAantal", " ", function (value) {
                const {aantal} = this.parent;

                return value ? value + aantal > 0 : true;
            })
            .test("som-maximum-extraAantal", " ", function (value) {
                const {aantal, besteldArtikel} = this.parent;

                return value ? value + aantal <= maximumBestelbaar(besteldArtikel) : true;
            }),
        klantReferentieNummer: Yup.string().nullable()
            .max(35,
                t("Foutmeldingen.referentie-klant-mag-maximaal-35-karakters-lang-zijn",
                    "Referentie klant mag maximaal 35 karakters lang zijn") as string)
            .when("klant",
                {
                    is: klant => klant?.externDocumentNummerVerplicht,
                    then: Yup.string().required(
                        t("Foutmeldingen.extern-documentnr-is-verplicht-voor-de-gekozen-klant",
                            "Extern documentnr is verplicht voor de gekozen klant") as string)
                        .nullable()
                }),
        extraAanvraagInfo: Yup.string().nullable(),
        ...transportKostValidatie(t),
        extraCo2FlesBeleidInherit: Yup.boolean(),
        extraCo2FlesBeleid: Yup.string().nullable().required(
            t("Foutmeldingen.extra-co2-fles-beleid-is-verplicht",
                "Extra CO₂-fles beleid is verplicht") as string)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [])

    const initialValues: BestelCo2FormValues = useMemo(() => ({
        klant: undefined,
        verzendAdres: undefined,
        besteldArtikel: undefined,
        aantal: 0,
        extraAantal: 0,
        klantReferentieNummer: undefined,
        emailAdressen: [],
        aangevraagdeLevertermijn: undefined,
        extraAanvraagInfo: undefined,
        transportKostType: undefined,
        transportKostInherit: true,
        extraCo2FlesBeleidInherit: true,
        extraCo2FlesBeleid: ExtraCo2FlesBeleid.INHERIT,
        ...defaultValues
    }), [defaultValues]);

    return (
        <Modal show={true} onHide={onAnnuleer} autoFocus size="lg">
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.co2-bestelling-toevoegen", "CO₂ bestelling toevoegen")}</ModalTitle>
            </ModalHeader>

            <Formik<BestelCo2FormValues> validationSchema={schema} validate={(values) => values["emailAdressen:validate"]?.()}
                                         onSubmit={bevestigHandler}
                                         validateOnBlur={false}
                                         validateOnChange={false}
                                         enableReinitialize

                                         initialValues={initialValues}
            >
                {() => {
                    return <NieuweCo2BestellingFormikForm onAnnuleer={onAnnuleer} defaultValues={defaultValues}/>;
                }}
            </Formik>
        </Modal>
    );
};

export default NieuweCo2BestellingModal;
