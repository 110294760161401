import {default as React, useEffect, useState} from "react";
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {RootState} from "../../../redux/reducers";
import {selectAnnulatieRedenen} from "../../../redux/refdata/selectors";
import {connect} from 'react-redux';
import {hideModal} from "../../../redux/ui/actions";
import LoaderWidget from '../../../components/Loader';
import {fetchAnnulatieRedenenRequest as fetchAnnulatieRedenen} from "../../../redux/refdata/actions";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "../../../components/aqualex/Modal";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const AnnuleerBestelBonModal = ({
                                    annulatieRedenen,
                                    onBevestigHandler,
                                    loading,
                                    hideModal,
                                    fetchAnnulatieRedenen
                                }) => {
    const [selectedReden, setSelectedReden] = useState('');

    const toelichtingVerplicht = annulatieRedenen.reduce((map, reden) => {
        map[reden.code] = reden.toelichtingVerplicht;
        return map
    }, {});

    useEffect(() => {
        if (!loading && annulatieRedenen.length === 0) {
            fetchAnnulatieRedenen();
        }
    });

    const onAnnuleer = () => {
        hideModal();
    }

    const onBevestig = (event, values) => {
        hideModal();
        onBevestigHandler(values.reden, values.toelichting);
    }

    const {t} = useTranslation("bestelbon");

    return (
        <Modal show={true} onHide={onAnnuleer}>
            <ModalHeader closeButton>{t("Titels.bestelbon-annuleren", "Bestelbon Annuleren")}</ModalHeader>
            <AvForm onValidSubmit={onBevestig}>

                {loading && <LoaderWidget/>}

                <ModalBody>
                    <AvField type='select' name="reden" label={t("Labels.reden", "Reden")} required onChange={(event, value) => {
                        setSelectedReden(value)
                    }}>
                        <option value=''>{' '}</option>
                        {annulatieRedenen.map(reden => {
                            return <option key={reden.code} value={reden.code}>{reden.label}</option>
                        })}
                    </AvField>
                    <AvField type='text' name="toelichting" label={t("Labels.toelichting", "Toelichting")} validate={{
                        required: {
                            value: toelichtingVerplicht[selectedReden],
                            errorMessage: t("Foutmeldingen.toelichting-is-verplicht-voor-de-geselecteerde-reden",
                                "Toelichting is verplicht voor de geselecteerde reden."),
                        }
                    }}/>
                </ModalBody>
                <ModalFooter>
                    <Button color='light' onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                    <Button color='primary' type="submit">{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    );
};

const mapStateToProps = (state: RootState, ownProps) => ({
    annulatieRedenen: selectAnnulatieRedenen(state),
    onBevestigHandler: ownProps.onBevestigHandler,
    loading: state.RefData.annulatieRedenen.loading,
});

const mapDispatchToProps = {
    hideModal,
    fetchAnnulatieRedenen,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnuleerBestelBonModal);
