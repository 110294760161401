// @flow
import {all} from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import refDataSaga from "./refdata/saga";
import planningSaga from "./planning/saga";
import googleMapsSaga from "./googlemaps/saga";
import serviceRapportSaga from "./servicerapport/saga";
import downloadSaga from "./download/saga";

export default function* rootSaga(getState: any): any {
    yield all([
        authSaga(),
        layoutSaga(),
        appMenuSaga(),
        refDataSaga(),
        planningSaga(),
        googleMapsSaga(),
        serviceRapportSaga(),
        downloadSaga(),
    ]);
}
