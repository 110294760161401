import {Placement} from "react-bootstrap/Overlay";
import * as React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export interface HelpTooltipProps {
    id: string;
    enabled?: boolean;
    content?: React.ReactNode;
    placement?: Placement;
}

/**
 * TODO: content needs to be refactored to be the children and vice versa (like my-aqualex-ui HelpTooltip)
 */
export const HelpTooltip: React.FC<HelpTooltipProps> = (props) => {
    const defaultIcon = <i className="text-info mdi mdi-help-circle" aria-hidden={true}/>;

    const {id, placement = "auto-start", children, content = defaultIcon, enabled} = props;

    if (enabled === false) {
        return <>{content}</>;
    }

    return (
        <OverlayTrigger placement={placement}
                        delay={{show: 250, hide: 400}}
                        overlay={(props) => <Tooltip id={id} {...props}><span>{children}</span></Tooltip>}>
            <span>{content}</span>
        </OverlayTrigger>
    );
};
