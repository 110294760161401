import {default as React} from "react";
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {RootState} from "../../../redux/reducers";
import {connect} from 'react-redux';
import {hideModal} from "../../../redux/ui/actions";
import {Alert, Button} from "react-bootstrap";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "../../../components/aqualex/Modal";
import {useTranslation} from "../../../helpers/i18nUtils";
import {Trans} from "react-i18next";

const BulkUploadDagPlanningenModal = ({
                                    onBevestigHandler,
                                    hideModal,
                                }) => {

    const onAnnuleer = () => {
        hideModal();
    }

    const onBevestig = (event, values) => {
        hideModal();
        onBevestigHandler(values.upload);
    }

    const {t} = useTranslation("medewerker");

    return (
        <Modal show={true} onHide={onAnnuleer} size='lg' autoFocus>
            <ModalHeader closeButton>{t("Titels.dagplanningen-toevoegen", "Dagplanningen toevoegen")}</ModalHeader>
            <AvForm onValidSubmit={onBevestig}>
                <ModalBody>
                    <Alert variant="info">
                        <Trans t={t}
                            i18nKey="BulkUploadDagPlanningenModal.helptekst">
                        Plak hieronder de inhoud van het excel bestand.<br/>
                        De kolomstructuur is: gebruikersnaam | datum (yyyy-mm-dd) | begin (uu:mm) | einde (uu:mm) | depot (optioneel)
                        </Trans>
                    </Alert>
                    <AvField type='textarea' rows={10} name="upload" required/>
                </ModalBody>
                <ModalFooter>
                    <Button color='light' onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                    <Button color='primary' type="submit">{t("algemeen:Buttons.toevoegen", "Toevoegen")}</Button>
                </ModalFooter>
            </AvForm>
        </Modal>
    );
};

const mapStateToProps = (state: RootState, ownProps) => ({
    onBevestigHandler: ownProps.onBevestigHandler,
});

const mapDispatchToProps = {
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadDagPlanningenModal);
