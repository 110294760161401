import * as React from "react";
import {MedewerkerFilters, MedewerkerModel} from "../../../redux/medewerker/types";
import {Button, Col, ListGroup, Row} from "react-bootstrap";
import MedewerkerSelector from "../MedewerkerSelector";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";
import {FieldHelperProps, FieldInputProps} from "formik";
import {MedewerkerBox} from "../medewerker/MedewerkerBox";

interface MedewerkerLijstFormFieldProps {
    filters?: MedewerkerFilters;
}

export const MedewerkerLijstFormField: React.FC<FormikFormFieldProps & MedewerkerLijstFormFieldProps> = ({
                                                                                                             disabled,
                                                                                                             isInvalid,
                                                                                                             filters = {},
                                                                                                             ...props
                                                                                                         }) => {

    const selecteerMedewerker = (field: FieldInputProps<MedewerkerModel[]>, helpers: FieldHelperProps<MedewerkerModel[]>, medewerker: MedewerkerModel) => {
        if (!medewerker) {
            return;
        }

        const nieuweMedewerkers = new Set(field.value);
        nieuweMedewerkers.add(medewerker);
        helpers.setValue(Array.from(nieuweMedewerkers));
    };

    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <div className="custom-control" style={{padding: "0", width: "100%"}}>
                    <div className="d-flex" style={{width: "100%"}}>
                        <div className="flex-grow-1">
                            <MedewerkerSelector disabled={disabled}
                                                searchFilters={filters}
                                                isInvalid={!!meta.error}
                                                isClearable={false}
                                                clearAfterSelect={true}
                                                medewerkerChanged={medewerker => selecteerMedewerker(field, helpers, medewerker)}/>
                        </div>
                    </div>
                    <div className="mt-1">
                        <ListGroup className='clearfix'>
                            {field.value?.map(medewerker => <ListGroup.Item style={{verticalAlign: 'middle'}}
                                                                            key={medewerker.id}
                                                                            className="p-1">
                                <Row className="align-items-center">
                                    <Col className="mb-n1">
                                        <MedewerkerBox medewerker={medewerker} clickable={false}/>
                                    </Col>
                                    {!disabled && (
                                        <Col xs="auto">
                                            <Button size='lg'
                                                    variant='link'
                                                    className='float-right'
                                                    onClick={() => {
                                                        const nieuweMedewerkers = new Set(field.value);
                                                        nieuweMedewerkers.delete(medewerker);
                                                        helpers.setValue(Array.from(nieuweMedewerkers));
                                                    }}>
                                                <i className={'mdi mdi-trash-can-outline'}/>
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </ListGroup.Item>)}
                        </ListGroup>
                    </div>
                </div>
            )}
        </FormikFormField>
    );
};
