import React from "react";
import {Button} from "react-bootstrap";

export interface QuickFilterProps {
    className?: string;
    active?: boolean;
    onClick?(): void;
}

export const QuickFilter: React.FC<QuickFilterProps> = (props) => {
    const {className, active, onClick} = props;

    return (
        <Button variant={active ? "primary" : "light"} className={`mr-2 btn-rounded ${className || ""}`} onClick={onClick}>
            {props.children}
        </Button>
    );
};
