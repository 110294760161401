import * as React from "react";
import {Button, Spinner} from "react-bootstrap";
import {ButtonProps} from "react-bootstrap/Button";

export interface LoadingButtonProps extends ButtonProps {
    loading?: boolean;
}

export const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
    const { loading, children, ...rest } = props;

    return (
        <Button disabled={loading} {...rest}>
            {loading && <Spinner animation="border" size="sm" className="mr-2"/>}

            {children}
        </Button>
    );
};
