import React from "react";
import * as Yup from "yup";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import {TijdstipFormField} from "../../../../components/aqualex/form/TijdstipFormField";
import {useTranslation} from "../../../../helpers/i18nUtils";

export interface DagPlanningAlignerenFormProps {
    onBevestig: (form: DagPlanningAlignerenFormValues) => void;
}

export interface DagPlanningAlignerenFormValues {
    uurEersteAankomst?: string | null;
}

export const DagPlanningAlignerenForm: React.FC<DagPlanningAlignerenFormProps> = (props) => {
    const {onBevestig} = props;

    const {t} = useTranslation("planning");

    const onSubmitHandler = (values: DagPlanningAlignerenFormValues) => {
        onBevestig({
            uurEersteAankomst: values.uurEersteAankomst,
        });
    }

    const schema = Yup.object<DagPlanningAlignerenFormValues>({
        uurEersteAankomst: Yup.string().nullable().required(t("Foutmeldingen.uur-eerste-bezoek-is-verplicht", "Uur eerste bezoek is verplicht") as string),
    });

    const initialValues: DagPlanningAlignerenFormValues = {
        uurEersteAankomst: "08:00:00",
    };

    return (
        <Formik validationSchema={schema}
                onSubmit={onSubmitHandler}
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialValues}>
            {({
                  handleSubmit,
                  isValid
              }) => (
                <Form noValidate>
                    <Row>
                        <Col>
                            <TijdstipFormField name="uurEersteAankomst"/>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Button variant="primary" disabled={!isValid} className="form-control"
                                    onClick={() => handleSubmit()}>{t("Buttons.bevestig", "Bevestig")}</Button>
                        </Col>
                    </Row>
                </Form>)}
        </Formik>
    );
};
