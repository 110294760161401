import * as React from "react";
import {OpdrachtType, OpdrachtTypeCountModel} from "../../../redux/planning/types";
import OpdrachtTypeBadge from "./OpdrachtTypeBadge";

interface OpdrachtTypesCountPillsProps {
    opdrachtTypesCount: OpdrachtTypeCountModel;
}

const OpdrachtTypesCountPills: React.FC<OpdrachtTypesCountPillsProps> = ({opdrachtTypesCount}) => {
    return <>
        {Object.keys(opdrachtTypesCount)
            .map(type => OpdrachtType[type])
            .map(type =>
                <span key={type} className="mr-3" style={{whiteSpace: "nowrap"}}>
                    <OpdrachtTypeBadge opdrachtType={type}/> × {opdrachtTypesCount[type]}
                </span>
            )}
    </>
};

export default OpdrachtTypesCountPills;
