import {RootState} from "../reducers";
import {createPaginationReducer, EntityDict, Pageable} from "../support/pagination";
import {selectPage} from "../support/selectors";
import {ServiceRapportActionTypes, ServiceRapportFilters, ServiceRapportModel, ServiceRapportState} from "./types";
import {
    SEARCH_SERVICE_RAPPORT_FAILURE,
    SEARCH_SERVICE_RAPPORT_REQUEST,
    SEARCH_SERVICE_RAPPORT_SUCCESS
} from "./constants";
import {Page} from "../support/pagination/types";

const INITIAL_STATE: ServiceRapportState = {
    entities: {
        serviceRapport: {},
    },
    serviceRapportPagination: {},
};


// indexers


const serviceRapportPaginationReducer = createPaginationReducer(
    [
        SEARCH_SERVICE_RAPPORT_REQUEST,
        SEARCH_SERVICE_RAPPORT_SUCCESS,
        SEARCH_SERVICE_RAPPORT_FAILURE
    ],
    'serviceRapportPagination',
    'entities.serviceRapport',
    (payload) => {
        return {
            entities: payload.content.reduce((acc: EntityDict<ServiceRapportModel>, serviceRapport: ServiceRapportModel) => ({
                ...acc,
                [serviceRapport.id]: serviceRapport
            }), {}),
            items: payload.content.map((serviceRapport: ServiceRapportModel) => serviceRapport.id),
            totalItemCount: payload.totalElements,
        };
    },
    (action) => {
        switch (action.type) {
            case SEARCH_SERVICE_RAPPORT_REQUEST:
                return action.payload.pageable;
            default:
                return action.meta.pageable;
        }
    },
    (action) => {
        switch (action.type) {
            case SEARCH_SERVICE_RAPPORT_REQUEST:
                return action.payload.filters;
            default:
                return action.meta.filters;
        }
    },
);

export const ServiceRapport = (state: ServiceRapportState = INITIAL_STATE, action: ServiceRapportActionTypes): ServiceRapportState => {

    switch (action.type) {

        case SEARCH_SERVICE_RAPPORT_REQUEST:
        case SEARCH_SERVICE_RAPPORT_SUCCESS:
        case SEARCH_SERVICE_RAPPORT_FAILURE:
            return serviceRapportPaginationReducer(state, action);

        default:
            return {...state};
    }
};

//Selectors

export const selectServiceRapportById = (state: RootState, serviceRapportId): ServiceRapportModel | undefined => {
    return state.ServiceRapport.entities.serviceRapport[serviceRapportId];
}

export const selectServiceRapportPage = (state: RootState, filters: ServiceRapportFilters, pageable: Pageable): Page<ServiceRapportModel> => {
    return selectPage(state.ServiceRapport.serviceRapportPagination, filters, pageable, id => state.ServiceRapport.entities.serviceRapport[id]);
};
