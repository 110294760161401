import * as React from "react";
import {Col} from "reactstrap";
import {Form} from "react-bootstrap";
import {SimpleFormField} from "../../../components/aqualex/form/SimpleFormField";
import {useTranslation} from "../../../helpers/i18nUtils";


interface ToestellenProps {
    guard: boolean;
    label?: string;
    waardePropertyName: string;
    waarde?: any;
    opmerkingPropertyName: string;
    opmerking?: string;
    waardeRenderer: (waarde: any) => any;

    waardeColSize?: number;
}

const InstallatieVereiste: React.FC<ToestellenProps> = ({
                                                            guard,
                                                            label,
                                                            waardePropertyName,
                                                            waarde,
                                                            opmerkingPropertyName,
                                                            opmerking,
                                                            waardeRenderer,
                                                            waardeColSize
                                                        }) => {


    const {t} = useTranslation("installatie");

    if (!guard) {
        return null;
    }

    const waardeColSm = waardeColSize ?? 2;

    return (
        <Form.Group as={Form.Row} className="align-items-center">
            <Form.Label column sm={12}>{label}</Form.Label>
            <Col sm={waardeColSm}>
                {waardeRenderer(waarde)}
            </Col>
            <Col sm={12 - waardeColSm}>
                <SimpleFormField name={opmerkingPropertyName} placeholder={t("Placeholders.opmerking", "Opmerking")}/>
            </Col>
        </Form.Group>
    )
};

export default InstallatieVereiste;
