import {default as React} from "react";
import DatumSelector, {DatumSelectorProps} from "../DatumSelector";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

export interface DatumformFieldProps extends FormikFormFieldProps {
    datumSelectorProps?: Omit<DatumSelectorProps, FormikFormFieldProps>;
}

export const DatumFormField: React.FC<DatumformFieldProps> = ({
                                                                   disabled,
                                                                   placeholder,
                                                                   datumSelectorProps,
                                                                   ...props
                                                               }) => {

    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => (
                <DatumSelector {...field}
                               placeholder={placeholder}
                               disabled={disabled}
                               onChange={datum => {
                                   helpers.setValue(datum);
                                   helpers.setTouched(true);
                               }}
                               isInvalid={!!meta.error}
                               {...datumSelectorProps}/>
            )}
        </FormikFormField>
    );
};
