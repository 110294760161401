import * as React from "react";
import {OpdrachtType} from "../../../redux/planning/types";
import OpdrachtTypeBadge from "../opdracht/OpdrachtTypeBadge";

export interface GeplandBezoekOpdrachtTypeBadgesProps {
    opdrachtTypes: OpdrachtType[];
}

const GeplandBezoekOpdrachtTypeBadges: React.FC<GeplandBezoekOpdrachtTypeBadgesProps> = (props) => {
    const {opdrachtTypes} = props;

    const opdrachtenPerType = opdrachtTypes
        .reduce<Partial<Record<OpdrachtType, number>>>((prev, curr) => ({
            ...prev,
            [curr]: (prev?.[curr] || 0) + 1
        }), {});

    return <React.Fragment>
        {Object.keys(opdrachtenPerType || {}).map(type => (
            <span key={type} className="mr-1">
                <OpdrachtTypeBadge opdrachtType={OpdrachtType[type]}/> x {opdrachtenPerType?.[type]}
            </span>
        ))}
    </React.Fragment>


}

export default GeplandBezoekOpdrachtTypeBadges;
