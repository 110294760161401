import React, {useMemo} from "react";
import * as Yup from "yup";
import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import {DagPlanningModel} from "../../../../../redux/planning/types";
import {RadioFormField} from "../../../../../components/aqualex/form/RadioFormField";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../../components/aqualex/Modal";
import {useTranslation} from "../../../../../helpers/i18nUtils";

export interface PlanningAutomatischAanvullenBevestigenModalProps {
    dagPlanning: DagPlanningModel;
    onBevestigHandler: (form: PlanningAutomatischAanvullenFormValues) => void;
    onAnnuleerHandler: () => void;
}

export enum PlanningAutomatischAanvullenBevestigenAction {
    BEHOUDEN = "BEHOUDEN",
    VERWIJDEREN = "VERWIJDEREN"
}

export interface PlanningAutomatischAanvullenFormValues {
    action: PlanningAutomatischAanvullenBevestigenAction;
}

export const PlanningAutomatischAanvullenBevestigenModal: React.FC<PlanningAutomatischAanvullenBevestigenModalProps> = (props) => {
    const {onBevestigHandler, onAnnuleerHandler, dagPlanning} = props;

    const {t} = useTranslation("planning");

    const onBevestig = async (values: PlanningAutomatischAanvullenFormValues) => {
        onBevestigHandler({
            action: values.action
        });
    }

    const schema = Yup.object<PlanningAutomatischAanvullenFormValues>({
        action: Yup.string().nullable().required(t("Foutmeldingen.actie-selecteren-is-verplicht", "Een actie selecteren is verplicht") as string) as any
    });

    const initialValues = useMemo(() => {
        return {
            action: PlanningAutomatischAanvullenBevestigenAction.BEHOUDEN
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dagPlanning]);

    return (
        <Modal show={true} onHide={onAnnuleerHandler} size="lg" autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Labels.automatisch-aanvullen", "Automatisch aanvullen")}</ModalTitle>
            </ModalHeader>

            <Formik validationSchema={schema}
                    onSubmit={onBevestig}
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={initialValues}>
                {({
                      handleSubmit,
                      isValid
                  }) => (
                    <Form noValidate>
                        <ModalBody>
                            <p>{t("PlanningAutomatischAanvullenBevestigenModal.er-zijn-al-bezoeken-voorgesteld", "Er zijn al bezoeken voorgesteld door de automatische planning. Wat wil je hiermee doen?")}</p>

                            <Form.Group>
                                <RadioFormField name="action"
                                                options={[
                                                    {
                                                        value: PlanningAutomatischAanvullenBevestigenAction.BEHOUDEN,
                                                        label: t("Labels.behoud-de-voorgestelde-bezoeken", "Behoud de voorgestelde bezoeken en plan ze definitief in op de dagplanning.")
                                                    },
                                                    {
                                                        value: PlanningAutomatischAanvullenBevestigenAction.VERWIJDEREN,
                                                        label: t("Labels.verwijder-de-voorgestelde-bezoeken", "Verwijder de voorgestelde bezoeken van de dagplanning.")
                                                    }
                                                ]}/>
                            </Form.Group>
                        </ModalBody>

                        <ModalFooter>
                            <Button variant="light" onClick={() => onAnnuleerHandler()}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}
                            <Button variant="primary" disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>)}
            </Formik>

        </Modal>
    );
};
