import {default as React} from "react";
import {CountryData, PhoneInput} from "./PhoneInput";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

export interface PhoneFormFieldValue {
    value?: string;
    phoneNumber?: string;
}

export interface PhoneFormFieldProps {
    placeholder?: string;
    useFormattedValue?: boolean;
    label?: string;
}

export const PhoneFormField: React.FC<FormikFormFieldProps & PhoneFormFieldProps> = ({
                                                                                         useFormattedValue,
                                                                                         placeholder,
                                                                                         ...props
                                                                                     }) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helpers) => {
                return <PhoneInput {...field}
                                   onChange={(value: string, data: CountryData, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
                                       helpers.setValue(useFormattedValue ? formattedValue : value);
                                   }}
                                   placeholder={placeholder}

                                   isValid={!meta.error}
                                   disabled={props.disabled}
                />
            }}
        </FormikFormField>
    );
};
