import {
    FETCH_ANNULATIE_REDENEN_FAILURE,
    FETCH_ANNULATIE_REDENEN_REQUEST,
    FETCH_ANNULATIE_REDENEN_SUCCESS,
} from "./constants";
import {RefDataActionTypes, RefDataState} from "./types";
import {combineHandlerBasedReducers} from "../support/reducers";
import {fetchDepotsToolkit} from "./index";

const INITIAL_STATE = {
    entities: {
        depot: {},
    },
    annulatieRedenen: {
        entries: [],
        byCodes: {},
        loading: false,
        error: undefined,
    }
} as RefDataState;

export const annulatieRedenenReducer = (state: RefDataState = INITIAL_STATE, action: RefDataActionTypes): RefDataState => {
    switch (action.type) {
        case FETCH_ANNULATIE_REDENEN_REQUEST:
            return {
                ...state,
                annulatieRedenen: {
                    ...state.annulatieRedenen,
                    loading: true
                }
            };
        case FETCH_ANNULATIE_REDENEN_SUCCESS:
            return {
                ...state,
                annulatieRedenen: {
                    entries: action.payload,
                    byCodes: action.payload.reduce((acc, reden) => ({
                        ...acc,
                        [reden.code]: reden
                    }), state.annulatieRedenen?.byCodes),
                    loading: false,
                    error: undefined
                }
            };
        case
        FETCH_ANNULATIE_REDENEN_FAILURE:
            return {
                ...state,
                annulatieRedenen: {
                    ...state.annulatieRedenen,
                    loading: false,
                    error: action.payload
                }
            };
        default:
            return {...state};
    }
};


export const RefData = combineHandlerBasedReducers(INITIAL_STATE, [
    annulatieRedenenReducer,
    fetchDepotsToolkit.reducer,
]);
