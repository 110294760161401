import "react-phone-number-input/style.css";

import React, {useMemo} from "react";
import ReactPhoneInput from "react-phone-number-input";
import {CountryCode} from "libphonenumber-js/types";

export interface CountryData {
    name: string;
    dialCode: string;
    countryCode: string;
    format: string;
}

export interface PhoneInputProps {
    value?: string;
    placeholder?: string;
    country?: CountryCode;
    disabled?: boolean;
    inputProps?: object;

    isValid?: boolean;

    onBlur?(): void | Promise<void>;

    onChange(value: string, data?: CountryData | {}, event?: React.ChangeEvent<HTMLInputElement>, formattedValue?: string): void | Promise<void>;
}

export const PhoneInput: React.FC<PhoneInputProps> = (props) => {
    const {value, onChange, onBlur, placeholder, disabled, inputProps} = props;

    const numberInputProps = useMemo(() => ({
        ...inputProps,
        className: `form-control ${inputProps?.["className"] || ""}`
    }), [inputProps]);

    return (
        <ReactPhoneInput
            defaultCountry="BE"

            placeholder={placeholder}
            disabled={disabled}
            numberInputProps={numberInputProps}

            value={value || ""}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};
