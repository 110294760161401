import * as React from "react";
import GenericBadge from "../../components/aqualex/GenericBadge";
import Config from "../../helpers/Config";
import {InterventieTaakType} from "../../redux/interventie/types";

export interface InterventieTaakTypeBadgeProps {
    type: InterventieTaakType;
}

export const InterventieTaakTypeBadge: React.FC<InterventieTaakTypeBadgeProps> = ({type}) => {
    const config = {
        [InterventieTaakType.HERSTELLING]: {
            label: Config.labels[InterventieTaakType.HERSTELLING],
            color: "danger-lighten"
        },
        [InterventieTaakType.UPGRADE]: {
            label: Config.labels[InterventieTaakType.UPGRADE],
            color: "primary-lighten"
        },
        [InterventieTaakType.VERPLAATSING]: {
            label: Config.labels[InterventieTaakType.VERPLAATSING],
            color: "secondary-lighten"
        },
        [InterventieTaakType.VERHUIS]: {
            label: Config.labels[InterventieTaakType.VERHUIS],
            color: "warning-lighten"
        },
        [InterventieTaakType.OPHALING]: {
            label: Config.labels[InterventieTaakType.OPHALING],
            color: "success-lighten"
        },
        [InterventieTaakType.STAALNAME_LABO_ONDERZOEK]: {
            label: Config.labels[InterventieTaakType.STAALNAME_LABO_ONDERZOEK],
            color: "info-lighten"
        },
        [InterventieTaakType.ADVIES]: {
            label: Config.labels[InterventieTaakType.ADVIES],
            color: "info-lighten"
        },
        [InterventieTaakType.OPSTART]: {
            label: Config.labels[InterventieTaakType.OPSTART],
            color: "info-lighten"
        }
    };

    return <GenericBadge value={type} config={config}/>;
};
