import {default as React} from "react";
import BezoekAanvraagOverzichtTable, {BezoekAanvraagOverzichtTableProps} from "./BezoekAanvraagOverzichtTable";
import {BezoekAanvraagOverzichtModel} from "../../../redux/bezoekaanvraag/types";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface MultiSelectBezoekAanvraagTableProps extends BezoekAanvraagOverzichtTableProps {
    onBezoekIdsSelectedUpdate: (bezoekIds: string[]) => void;
    geselecteerdeBezoeken: Array<string>;
}

export const MultiSelectBezoekAanvraagTable: React.FC<MultiSelectBezoekAanvraagTableProps> = ({
                                                                                                  page,
                                                                                                  loadData,
                                                                                                  filters,
                                                                                                  pageable,
                                                                                                  onRowClicked,

                                                                                                  primaryFilterFields,
                                                                                                  columns,

                                                                                                  onBezoekIdsSelectedUpdate,
                                                                                                  geselecteerdeBezoeken
                                                                                              }) => {
    const {t} = useTranslation("planning");

    const onSelectBezoek = (bezoekAanvraag: BezoekAanvraagOverzichtModel, selected: boolean) => {
        const nieuweSelectie = new Set(geselecteerdeBezoeken);
        if (selected) {
            nieuweSelectie.add(bezoekAanvraag.id);
        } else {
            nieuweSelectie.delete(bezoekAanvraag.id);
        }

        onBezoekIdsSelectedUpdate(Array.from(nieuweSelectie.values()));
    };

    return (
        <React.Fragment>
            <BezoekAanvraagOverzichtTable page={page}
                                          filters={filters}
                                          pageable={pageable}
                                          loadData={loadData}

                                          onRowClicked={onRowClicked}

                                          selectionMode="multiple"
                                          onRowSelected={onSelectBezoek}

                                          quickFilters={[]}
                                          primaryFilterFields={primaryFilterFields}
                                          columns={columns}
            />

            <p>{t("MultiSelectBezoekAanvraagTable.bezoeken-geselecteerd", {defaultValue_one: "{{count}} bezoek geselecteerd", defaultValue_other: "{{count}} bezoeken geselecteerd", count: geselecteerdeBezoeken?.length})}</p>
        </React.Fragment>
    );
};
