import {
    fetchGoogleMapsDirectionsFailure,
    fetchGoogleMapsDirectionsRequest,
    fetchGoogleMapsDirectionsSuccess,
} from './actions';
import {FETCH_GOOGLE_MAPS_ROUTE_REQUEST,} from "./constants";
import {all, call, put, takeLatest} from 'redux-saga/effects';
import GoogleMapsApi from "../../helpers/GoogleMapsApi";

function* fetchGoogleMapsDirectionsSaga({payload: {id, places}}: ReturnType<typeof fetchGoogleMapsDirectionsRequest>) {
    try {
        if (places.length) {
            const result = yield call(GoogleMapsApi.fetchDirections, places);
            yield put(fetchGoogleMapsDirectionsSuccess(result, {id, places}));
        } else {
            yield put(fetchGoogleMapsDirectionsSuccess(undefined, {id, places}));
        }
    } catch (err: any) {
        yield put(fetchGoogleMapsDirectionsFailure(err, {id, places}));
    }
}

export default function* googleMapsSaga() {
    yield all([
        takeLatest(FETCH_GOOGLE_MAPS_ROUTE_REQUEST, fetchGoogleMapsDirectionsSaga),
    ]);
}
