import * as React from "react";
import Tijdstip from "./Tijdstip";
import {useTranslation} from "../../helpers/i18nUtils";

interface VanTotTijdstipProps {
    van?: string;
    tot?: string;
}

const VanTotTijdstip: React.FC<VanTotTijdstipProps> = ({van, tot}) => {
    const {t} = useTranslation("algemeen");

    if (!van && !tot) {
        return <span className="text-muted">{t("Labels.volledige-dag", "Volledige dag")}</span>;
    }

    if (van === tot) {
        return (
            <span><Tijdstip tijd={van}/></span>
        );
    }

    return (
        <span><Tijdstip tijd={van}/> - <Tijdstip tijd={tot}/></span>
    );
};

export default VanTotTijdstip;
