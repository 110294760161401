import {Button, FormControl, InputGroup} from "react-bootstrap";
import React, {forwardRef} from "react";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {useTranslation} from "../../helpers/i18nUtils";

const InputWithButton = (props, _ref) => {
    const control = <FormControl className='form-control'
                                 onClick={props.onClick}
                                 value={props.value}
                                 onChange={props.onChange}
                                 disabled={props.disabled}
                                 isValid={props.isValid}
                                 isInvalid={props.isInvalid}
                                 placeholder={props.placeholder}
    />

    return props.showButton
        ? <InputGroup>
            {control}
            <InputGroup.Append>
                <Button onClick={props.onClick} disabled={props.disabled}>
                    <i className="mdi mdi-clock-outline font-13"/>
                </Button>
            </InputGroup.Append>
        </InputGroup>
        : control;
}

const InputWithButtonRefForward = forwardRef(InputWithButton);

interface TijdSelectorProps {
    value?: string;
    onChange: (value?: string) => void;
    placeholder?: string;
    isInvalid?: boolean;
    disabled?: boolean;
    showButton?: boolean;
    timeIntervals?: number;
}

const TijdSelector: React.FC<TijdSelectorProps> = ({
                                                       value,
                                                       onChange,
                                                       showButton = true,
                                                       disabled,
                                                       placeholder,
                                                       isInvalid,
                                                       timeIntervals = 15
                                                   }) => {
    const {t, language} = useTranslation("algemeen");

    let valueAsDate = value ? moment(value, moment.HTML5_FMT.TIME_SECONDS).toDate() : undefined;

    return (
        <DatePicker customInput={<InputWithButtonRefForward isInvalid={isInvalid} showButton={showButton}/>}
                    selected={valueAsDate}
                    onChange={(valueAsDate, event) => {
                        if (valueAsDate) {
                            onChange(moment(valueAsDate).format(moment.HTML5_FMT.TIME_SECONDS));
                        } else {
                            onChange(undefined);
                        }
                    }}
                    onChangeRaw={(event) => {
                        const value = event.target.value;

                        if (value !== null && value !== undefined && value.trim() === "") {
                            onChange(undefined);
                        }
                    }}
                    locale={language}
                    dateFormat='HH:mm'
                    timeFormat='HH:mm'
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={timeIntervals}
                    timeCaption={t("Labels.tijd", "Tijd") as string}
                    placeholderText={placeholder}
                    disabled={disabled}
        />
    );
};

export default TijdSelector;

