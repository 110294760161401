import {default as React} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {CreateVerzendAdresForm} from "../../../redux/verzendadres/types";
import {CreateVerzendAdresFormComponent} from "./CreateVerzendAdresForm";
import {KlantModel} from "../../../redux/klant/types";
import {TransportKostConfiguratieType} from "../../../redux/types";
import {transportKostValidatie} from "../../../components/aqualex/form/TransportKostConfiguratieForm";
import {useGetKlantById} from "../../../redux/klant/api";
import {skipToken} from "@reduxjs/toolkit/query";
import {useTranslation} from "../../../helpers/i18nUtils";
import Config from "../../../helpers/Config";

export interface CreateVerzendAdresFormContainerProps {
    initialValues?: CreateVerzendAdresFormValues;
    klantId?: string;
    loading?: boolean;

    onSubmit(form: CreateVerzendAdresForm): void | Promise<void>;

    onCancel(): void;
}

export interface CreateVerzendAdresFormValues {
    klant?: KlantModel;
    code: string;

    naam: string;
    adres: string;
    postcode: string;
    plaats: string;
    landCode?: string;

    taalCode: string;
    latitude?: string;
    longitude?: string;

    email: string;
    telefoon: string;
    fax?: string;
    contact: string;
    opmerking?: string;
    transportKostType?: string;
    transportKostValue?: number;
    transportKostInherit?: boolean;

    prive?: "true" | "false";
}

const CreateVerzendAdresFormContainer: React.FC<CreateVerzendAdresFormContainerProps> = (props) => {
    const {onSubmit, klantId} = props;

    const {t} = useTranslation("klant");

    const {data: klant} = useGetKlantById(klantId || skipToken);

    const [loadingState, setLoading] = React.useState(false);
    const loading = props.loading || loadingState;

    const onFormSubmit = async (values: CreateVerzendAdresFormValues) => {
        const result = onSubmit({
            klantId: values.klant?.id || props.klantId!,
            code: values.code,

            naam: values.naam,
            adres: values.adres,
            postcode: values.postcode,
            plaats: values.plaats,
            landCode: values.landCode!,

            taalCode: values.taalCode,
            geolocation: values.latitude && values.longitude ? {
                latitude: Number.parseFloat(values.latitude),
                longitude: Number.parseFloat(values.longitude),
            } : undefined,

            email: values.email,
            telefoon: values.telefoon,
            fax: values.fax!,
            contact: values.contact,
            opmerking: values.opmerking,
            prive: values.prive === "true",

            transportKostType: values.transportKostInherit ? TransportKostConfiguratieType.INHERIT : values.transportKostType as TransportKostConfiguratieType,
            transportKostValue: values.transportKostValue || undefined
        });

        if (result && typeof result.then === "function") {
            setLoading(true);

            try {
                await result;
            } finally {
                setLoading(false);
            }
        }
    };

    const defaultCreateVerzendAdresFormValues: CreateVerzendAdresFormValues = {
        code: "",

        naam: klant?.naam || "",
        adres: "",
        postcode: "",
        plaats: "",
        landCode: Config.DEFAULT_COUNTRY_CODE,

        taalCode: Config.DEFAULT_LANGUAGE_CODE,
        latitude: undefined,
        longitude: undefined,

        email: "",
        telefoon: "",
        fax: undefined,
        contact: "",
        opmerking: "",
        prive: klant?.sector === "PARTICULIER" ? "true" : undefined,

        transportKostType: TransportKostConfiguratieType.INHERIT,
        transportKostInherit: true
    };

    const verzendcodeMaximaleLengte = 10;
    const naamMaximaleLengte = 100;
    const adresMaximaleLengte = 100;
    const postcodeMaximaleLengte = 20;
    const plaatsnaamMaximaleLengte = 30;
    const landcodeMaximaleLengte = 10;
    const naamContactpersoonMaximaleLengte = 100;
    const emailAdresMaximaleLengte = 80;
    const telefoonNummerMinimaleLengte = 5;
    const telefoonNummerMaximaleLengte = 30;
    const faxnummerMaximaleLengte = 30;
    const opmerkingMaximaleLengte = 100;

    const formSchema = Yup.object<CreateVerzendAdresFormValues>({
        "klant": Yup.object(),
        "code": Yup.string()
            .required(t("Foutmeldingen.verzendcode-is-verplicht", "Code is verplicht") as string)
            .matches(/^[A-Z0-9]+$/,
                t("Foutmeldingen.verzendcode-kan-geen-speciale-tekens-bevatten", "Code kan geen speciale tekens bevatten") as string)
            .max(verzendcodeMaximaleLengte,
                t("Foutmeldingen.verzendcode-maximale-lengte",
                    "Code kan maximum {{lengte}} karakters bevatten",
                    {lengte: verzendcodeMaximaleLengte}) as string),
        "naam": Yup.string()
            .required(t("Foutmeldingen.naam-is-verplicht", "Naam is verplicht") as string)
            .max(naamMaximaleLengte,
                t("Foutmeldingen.naam-maximale-lengte",
                    "Naam kan maximum {{lengte}} karakters bevatten",
                    {lengte: naamMaximaleLengte}) as string),
        "adres": Yup.string()
            .required(t("Foutmeldingen.adres-is-verplicht", "Adres is verplicht") as string)
            .max(adresMaximaleLengte,
                t("Foutmeldingen.adres-maximale-lengte",
                    "Adres kan maximum {{lengte}} karakters bevatten",
                    {lengte: adresMaximaleLengte}) as string),
        "postcode": Yup.string()
            .required(t("Foutmeldingen.postcode-is-verplicht", "Postcode is verplicht") as string)
            .max(postcodeMaximaleLengte,
                t("Foutmeldingen.postcode-maximale-lengte",
                    "Postcode kan maximum {{lengte}} karakters bevatten",
                    {lengte: postcodeMaximaleLengte}) as string),
        "plaats": Yup.string()
            .required(t("Foutmeldingen.plaats-is-verplicht",
                "Plaats is verplicht") as string)
            .max(plaatsnaamMaximaleLengte,
                t("Foutmeldingen.plaatsnaam-maximale-lengte",
                    "Plaats kan maximum {{lengte}} karakters bevatten",
                    {lengte: plaatsnaamMaximaleLengte}) as string),
        "landCode": Yup.string()
            .required(t("Foutmeldingen.land-is-verplicht",
                "Land is verplicht") as string)
            .max(landcodeMaximaleLengte,
                t("Foutmeldingen.landcode-maximale-lengte",
                    "Landcode kan maximum {{lengte}} karakters bevatten",
                    {lengte: landcodeMaximaleLengte}) as string),
        "taalCode": Yup.string()
            .required(t("Foutmeldingen.taal-is-verplicht",
                "Taal is verplicht") as string),
        "latitude": Yup.number(),
        "longitude": Yup.number(),
        "prive": Yup.string()
            .required(t("Foutmeldingen.prive-is-verplicht",
                "Privé is verplicht") as string) as any,
        "contact": Yup.string()
            .required(t("Foutmeldingen.naam-contactpersoon-is-verplicht",
                "Naam contactpersoon is verplicht") as string)
            .max(naamContactpersoonMaximaleLengte,
                t("Foutmeldingen.naam-contactpersoon-maximale-lengte",
                    "Naam contactpersoon kan maximum {{lengte}} karakters bevatten",
                    {lengte: naamContactpersoonMaximaleLengte}) as string),
        "email": Yup.string()
            .required(t("Foutmeldingen.e-mailadres-is-verplicht", "E-mailadres is verplicht") as string)
            .email(t("Foutmeldingen.e-mailadres-is-ongeldig", "E-mailadres is geen geldig e-mailadres") as string)
            .max(emailAdresMaximaleLengte,
                t("Foutmeldingen.e-mailadres-maximale-lengte",
                    "E-mailadres kan maximum {{lengte}} karakters bevatten",
                    {lengte: emailAdresMaximaleLengte}) as string),
        "telefoon": Yup.string()
            .required(t("Foutmeldingen.telefoonnummer-is-verplicht", "Telefoonnummer is verplicht") as string)
            .min(telefoonNummerMinimaleLengte,
                t("Foutmeldingen.telefoonnummer-minimale-lengte",
                    "Telefoonnummer is verplicht") as string)
            .max(telefoonNummerMaximaleLengte,
                t("Foutmeldingen.telefoonnummer-maximale-lengte",
                    "Telefoonnummer kan maximum {{lengte}} karakters bevatten",
                    {lengte: telefoonNummerMaximaleLengte}) as string),
        "fax": Yup.string()
            .max(faxnummerMaximaleLengte,
                t("Foutmeldingen.faxnummer-maximale-lengte",
                    "Faxnummer kan maximum 30 karakters bevatten",
                    {lengte: faxnummerMaximaleLengte}) as string),
        "opmerking": Yup.string()
            .max(opmerkingMaximaleLengte, "Opmerking kan maximum 100 karakters bevatten"),
        ...transportKostValidatie(t),
    });

    return (
        <Formik<CreateVerzendAdresFormValues> validationSchema={formSchema}
                                              onSubmit={onFormSubmit}
                                              validateOnBlur={false}
                                              validateOnChange={false}
                                              initialValues={{
                                                  ...defaultCreateVerzendAdresFormValues,
                                                  ...props.initialValues
                                              }}
                                              enableReinitialize
        >
            <CreateVerzendAdresFormComponent onCancel={props.onCancel} loading={loading} editKlant={!props.klantId}
                                             klant={klant || undefined}/>
        </Formik>
    );
};

export default CreateVerzendAdresFormContainer;
