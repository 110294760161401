import React from "react";
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {Formik} from "formik";
import * as Yup from "yup";
import {FormikHelpers} from "formik/dist/types";
import {useHideModal} from "../../redux/ui/hooks";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../components/aqualex/Modal";
import {TextAreaFormField} from "../../components/aqualex/form/TextAreaFormField";
import {useTranslation} from "react-i18next";

export interface AnnuleerAccessoireBestellingFormValues {
    reden: string;
}

export interface AnnuleerAccessoireBestellingModalProps {
    onBevestigHandler: (form: AnnuleerAccessoireBestellingFormValues) => Promise<void>
}

export const AnnuleerAccessoireBestellingModal: React.FC<AnnuleerAccessoireBestellingModalProps> = (props) => {
    const {onBevestigHandler} = props;

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const {t} = useTranslation("accessoire-bestelling");

    const onBevestig = async (values: AnnuleerAccessoireBestellingFormValues, helpers: FormikHelpers<AnnuleerAccessoireBestellingFormValues>) => {
        try {
            helpers.setSubmitting(true);

            await onBevestigHandler(values);
            hideModal();
        } catch (error: any) {

        } finally {
            helpers.setSubmitting(false);
        }
    };

    const initialValues: AnnuleerAccessoireBestellingFormValues = {
        reden: ""
    };

    const redenIsVerplichtLabel = t("AccessoireBesstellingModal.reden-is-verplicht", "Reden is verplicht");

    return (
        <Modal show onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("AccessoireBestellingModal.accessoirebestelling-annuleren",
                    "Accessoirebestelling annuleren")}</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                validateOnMount // to make validateOnMount, initialValues must be created using useMemo
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    reden: Yup.string().required(redenIsVerplichtLabel)
                })}
            >
                {({
                      handleSubmit,
                      isValid,
                      values
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <h5>{t("AccessoireBestellingModal.Headers.reden", "Reden")}</h5>
                            <Form.Row>
                                <FormGroup id="reden" as={Col}>
                                    <TextAreaFormField name="reden"/>
                                </FormGroup>
                            </Form.Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="light" onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{" "}
                            <Button variant="primary" disabled={!isValid}
                                    onClick={() => handleSubmit()}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};

