import { default as React } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { KoppelPartnerAanBestelbonModalForm } from './KoppelPartnerAanBestelbonModalForm';
import { Modal, ModalHeader, ModalTitle } from '../../../components/aqualex/Modal';
import { PartnerModel, PartnerSelectie } from '../../../redux/partner/types';
import { BestelBonModel } from '../../../redux/bestelbon/types';

export interface KoppelPartnerAanBestelbonModalProps {
    bestelBon: BestelBonModel;

    onBevestigHandler(partnerSelectie: PartnerSelectie, partnerLeverancierId?: string): PromiseLike<any>;
    hideModal(): void;
}

export interface KoppelPartnerAanBestelbonFormValues {
    partnerSelectie: PartnerSelectie;
    partner?: PartnerModel;
}

export const KoppelPartnerAanBestelbonModal: React.FC<KoppelPartnerAanBestelbonModalProps> = (props) => {
    const {
        bestelBon,
        onBevestigHandler,
        hideModal
    } = props;

    const onAnnuleer = () => {
        hideModal();
    };

    const onBevestig = async (values: KoppelPartnerAanBestelbonFormValues, helpers: FormikHelpers<any>) => {
        try {
            await onBevestigHandler(values.partnerSelectie, values.partner?.id);
            hideModal();
        } finally {
            helpers.setSubmitting(false);
        }
    };

    return (
        <Modal show={true} onHide={onAnnuleer} size="lg">
            <ModalHeader closeButton={true}>
                <ModalTitle>Partner koppelen</ModalTitle>
            </ModalHeader>
            <Formik<KoppelPartnerAanBestelbonFormValues>
                initialValues={{
                    partnerSelectie: bestelBon.partnerSelectie,
                    partner: bestelBon.partnerLeverancier
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={onBevestig}
                validationSchema={Yup.object({
                    partnerSelectie: Yup.string().required("Partner is verplicht"),
                    partner: Yup.object().nullable().when("partnerSelectie", {
                        is: PartnerSelectie.VASTGELEGD,
                        then: Yup.object().required("Partner is verplicht"),
                        otherwise: Yup.object().optional()
                    })
                })}
            >
                <KoppelPartnerAanBestelbonModalForm bestelBon={bestelBon} onAnnuleer={onAnnuleer}/>
            </Formik>
        </Modal>
    );
};
