import {downloadFileByUri,} from './actions';
import {DOWNLOAD_FILE_BY_URI,} from "./constants";
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {downloadFile, FileDownload, saveFile} from "../../helpers/download";

export function* downloadFileSaga({payload: {uri, omschrijving}, meta: { onSuccessCallback }}: ReturnType<typeof downloadFileByUri.request>) {
    try {
        const result: FileDownload = yield call(downloadFile, uri, omschrijving);

        yield saveFile(result);

        yield put(downloadFileByUri.success(result, uri));

        if (onSuccessCallback) {
            onSuccessCallback();
        }
    } catch (err: any) {
        yield put(downloadFileByUri.failure(err, uri));
    }
}

export default function* downloadSaga() {
    yield all([
        takeEvery(DOWNLOAD_FILE_BY_URI.REQUEST, downloadFileSaga)
    ]);
}
