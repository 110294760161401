import 'moment/locale/nl';
import 'moment/locale/fr';
import {
    DagPlanningFase,
    DagPlanningStatus,
    OpdrachtType,
    PrestatieStatus,
    PrioriteitHint,
    Skill, ToestelOnderhoudType,
} from '../redux/planning/types';
import moment from 'moment';
import {BestelBonStatus, BestelbonVerzendDatumType, TransactieType} from '../redux/bestelbon/types';
import nlBE from 'date-fns/locale/nl-BE';
import fr from 'date-fns/locale/fr';
import {registerLocale} from 'react-datepicker';
import {Co2BestellingStatus, ExtraCo2FlesBeleid, OorsprongType} from '../redux/co2bestelling/types';
import {
    AfvoerMetSifonAanwezig,
    InstallatieDossierStatus,
    InstallatieStatusStatus,
    ProductType,
    ToestelInstallatieStatus,
    VerkoopOrderStatus,
} from '../redux/installatie/types';
import {
    KlantCommunicatieType,
    NietBezochtRedenType,
    NietUitgevoerdRedenType,
    OnvolledigeAccessoireLeveringReden,
    OpvolgingItemDefinitieType,
    OpvolgingItemStatus,
    ServiceBezoekOpvolgingNiveau,
    ServiceBezoekOpvolgingStatus,
} from '../redux/opvolging/types';
import {AccessoireBestellingStatus} from '../redux/accessoirebestelling/types';
import {
    InterventieGarantieProfiel,
    InterventiePrioriteit,
    InterventieStatus,
    InterventieTaakType,
} from '../redux/interventie/types';
import {DayOfWeek, MedewerkerRol, TechnicusProfiel} from '../redux/medewerker/types';
import {AutomatischePlanningStatus} from '../redux/autoplanning/types';
import {TransportKostConfiguratieType} from '../redux/types';
import {BezoekAanvraagStatus} from '../redux/bezoekaanvraag/types';
import {MyAqualexZichtbaarheid, ServiceArtikelStatus, TransactieType as ToestelTransactieType} from '../redux/toestel/types';
import {GeenKlantAccountMakenReden} from '../redux/klant/types';
import {Translation} from "react-i18next";
import {ReactChild} from "react";
import {ServiceStatus, ServiceType} from "../redux/service/types";
import {PartnerSelectie} from "../redux/partner/types";

registerLocale("nl", nlBE);
registerLocale("nl-BE", nlBE);
registerLocale("fr", fr);

const opvolgingItemDefinitieType = OpvolgingItemDefinitieType;

export default class Config {

    static BACKOFFICE_BASE_URL = window['_env_'].BACKOFFICE_BASE_URL;
    static BACKOFFICE_WEBSOCKET_URL = window['_env_'].BACKOFFICE_WEBSOCKET_URL;
    static ENVIRONMENT = window['_env_'].BACKOFFICE_ENVIRONMENT;

    static SENTRY_DSN = window['_env_'].SENTRY_DSN;
    static SENTRY_TRACES_SAMPLE_RATE = window['_env_'].SENTRY_TRACES_SAMPLE_RATE || 0;

    static BACKOFFICE_GOOGLE_MAPS_KEY = window['_env_'].BACKOFFICE_GOOGLE_MAPS_KEY;
    static BACKOFFICE_GOOGLE_MAPS_LIBRARIES = ["places"];

    static OAUTH_CLIENT_ID = window["_env_"].OAUTH_CLIENT_ID;
    static OAUTH_CLIENT_SCOPES = window["_env_"].OAUTH_CLIENT_SCOPES || [];
    static OAUTH_AUTHORITY = window["_env_"].OAUTH_AUTHORITY || [];

    static VIEWPORT_BORDER_COLOR = window['_env_'].VIEWPORT_BORDER_COLOR;

    static VERSION = process.env.REACT_APP_VERSION;

    static ADDRESS_AUTOCOMPLETE_COUNTRIES: string[] = ["be", "nl", "de", "fr"];

    static DEFAULT_COUNTRY_CODE = window["_env_"].DEFAULT_COUNTRY_CODE || "BE";
    static DEFAULT_LANGUAGE_CODE = window["_env_"].DEFAULT_LANGUAGE_CODE || "nl-BE";

    //Interval for components that automatically refresh
    static AUTO_COMPONENT_REFRESH_INTERVAL_MS = 10_000;

    // Maximaal aantal bestelbare flessen indien geen aantal bekend in Dynamics
    static CO2_BESTELLING_MAXIMUM_BESTELBAAR_AANTAL_FALLBACK = 100;
    // Factor waarmee ingesteld maximum bestelbaar aantal kan overschreden worden in back-office (vs. MyAQUALEX)
    static CO2_BESTELLING_MAXIMUM_BESTELBAAR_AANTAL_FACTOR = 2;

    // date and time formats
    static DATE_ISO_FORMAT = moment.HTML5_FMT.DATE;
    static DATE_HUMAN_FORMAT = "DD/MM/YYYY";
    static MONTH_FORMAT = moment.HTML5_FMT.MONTH;
    static WEEK_FORMAT = moment.HTML5_FMT.WEEK;
    static TIME_FORMAT = moment.HTML5_FMT.TIME;
    static READABLE_DATE_FORMAT = "dddd D MMM";

    static INTERVENTIE_DEADLINE_AANTAL_DAGEN_PER_PRIORITEIT: Record<InterventiePrioriteit, number> = {
        [InterventiePrioriteit.SLA]: 1,
        [InterventiePrioriteit.LEK]: 4,
        [InterventiePrioriteit.NORMAAL]: 7,
        [InterventiePrioriteit.LAAG]: 14
    };

    static labels: Record<string, ReactChild | Record<string, ReactChild>> = {
        [AccessoireBestellingStatus.PLANNING]:
            <Translation>{t =>t("algemeen:AccessoireBestellingStatus.planning", "Planning")}</Translation>,
        [AccessoireBestellingStatus.NIEUW]:
            <Translation>{t =>t("algemeen:AccessoireBestellingStatus.nieuw", "Nieuw")}</Translation>,
        [AccessoireBestellingStatus.GELEVERD]:
            <Translation>{t =>t("algemeen:AccessoireBestellingStatus.geleverd", "Geleverd")}</Translation>,
        [AccessoireBestellingStatus.GEANNULEERD]:
            <Translation>{t =>t("algemeen:AccessoireBestellingStatus.geannuleerd", "Geannuleerd")}</Translation>,
        [AccessoireBestellingStatus.WACHT_OP_UITVOERING]:
            <Translation>{t =>t("algemeen:AccessoireBestellingStatus.wacht-op-uitvoering", "Wacht op uitvoering")}</Translation>,
        [AccessoireBestellingStatus.NIET_UITGEVOERD]:
            <Translation>{t =>t("algemeen:AccessoireBestellingStatus.niet-uitgevoerd", "Niet uitgevoerd")}</Translation>,

        [BezoekAanvraagStatus.NIEUW]:
            <Translation>{t =>t("algemeen:BezoekAanvraagStatus.nieuw", 'Nieuw')}</Translation>,
        [BezoekAanvraagStatus.TE_PLANNEN]:
            <Translation>{t =>t("algemeen:BezoekAanvraagStatus.te-plannen", 'Te Plannen')}</Translation>,
        [BezoekAanvraagStatus.GEPLAND]:
            <Translation>{t =>t("algemeen:BezoekAanvraagStatus.gepland", 'Gepland')}</Translation>,
        [BezoekAanvraagStatus.GEANNULEERD]:
            <Translation>{t =>t("algemeen:BezoekAanvraagStatus.geannuleerd", 'Geannuleerd')}</Translation>,
        [BezoekAanvraagStatus.BEZOCHT]:
            <Translation>{t =>t("algemeen:BezoekAanvraagStatus.bezocht", "Bezocht")}</Translation>,

        [AutomatischePlanningStatus.AANGEMAAKT]:
            <Translation>{t =>t("algemeen:AutomatischePlanningStatus.aangemaakt", 'Aangemaakt')}</Translation>
            ,
        [AutomatischePlanningStatus.KLAAR_VOOR_VERWERKING]:
            <Translation>{t =>t("algemeen:AutomatischePlanningStatus.klaar-voor-verwerking", 'Klaar voor verwerking')}</Translation>,
        [AutomatischePlanningStatus.IN_VERWERKING]:
            <Translation>{t =>t("algemeen:AutomatischePlanningStatus.in-verwerking", 'In verwerking')}</Translation>,
        [AutomatischePlanningStatus.GEFAALD]:
            <Translation>{t =>t("algemeen:AutomatischePlanningStatus.gefaald", 'Gefaald')}</Translation>,
        [AutomatischePlanningStatus.VERWERKING_KLAAR]:
            <Translation>{t =>t("algemeen:AutomatischePlanningStatus.verwerking-klaar", 'Verwerking klaar')}</Translation>,
        [AutomatischePlanningStatus.TOEGEPAST]:
            <Translation>{t =>t("algemeen:AutomatischePlanningStatus.toegepast", 'Toegepast')}</Translation>,
        [AutomatischePlanningStatus.GEANNULEERD]:
            <Translation>{t =>t("algemeen:AutomatischePlanningStatus.geannuleerd", 'Geannuleerd')}</Translation>,

        [OpdrachtType.CO2_LEVERING]:
            <Translation>{t =>t("algemeen:OpdrachtType.co2-levering", 'CO₂ Levering')}</Translation>,
        [OpdrachtType.TOESTEL_ONDERHOUD]:
            <Translation>{t =>t("algemeen:OpdrachtType.onderhoud", 'Onderhoud')}</Translation>,
        [OpdrachtType.TOESTEL_INSTALLATIE]:
            <Translation>{t =>t("algemeen:OpdrachtType.installatie", 'Installatie')}</Translation>,
        [OpdrachtType.ONDERHOUD_SERVICE_ORDER]:
            <Translation>{t =>t("algemeen:OpdrachtType.onderhoud-serviceonderhoud", 'Onderhoud SO')}</Translation>,
        [OpdrachtType.ACCESSOIRE_LEVERING]:
            <Translation>{t =>t("algemeen:OpdrachtType.accessoire-levering", 'Accessoirelevering')}</Translation>,
        [OpdrachtType.TOESTEL_INTERVENTIE]:
            <Translation>{t =>t("algemeen:OpdrachtType.interventie", 'Interventie')}</Translation>,

        [BestelBonStatus.IN_ONTWERP]:
            <Translation>{t =>t("algemeen:BestelBonStatus.in-ontwerp", 'In ontwerp')}</Translation>,
        [BestelBonStatus.GEANNULEERD]:
            <Translation>{t =>t("algemeen:BestelBonStatus.geannuleerd", 'Geannuleerd')}</Translation>,
        [BestelBonStatus.BEVESTIGD]:
            <Translation>{t =>t("algemeen:BestelBonStatus.bevestigd", 'Bevestigd')}</Translation>,
        [BestelBonStatus.GETEKEND]:
            <Translation>{t =>t("algemeen:BestelBonStatus.getekend", 'Getekend')}</Translation>,
        [BestelBonStatus.GEVALIDEERD_GETEKEND]:
            <Translation>{t =>t("algemeen:BestelBonStatus.gevalideerd-getekend", 'Gevalideerd getekend')}</Translation>,
        [BestelBonStatus.VERWERKT]:
            <Translation>{t =>t("algemeen:BestelBonStatus.verwerkt", 'Verwerkt')}</Translation>,

        [PrioriteitHint.ZEER_DRINGEND]:
            <Translation>{t =>t("algemeen:PrioriteitHint.zeer-dringend", 'Zeer dringend')}</Translation>,
        [PrioriteitHint.INSTALLATIE]:
            <Translation>{t =>t("algemeen:PrioriteitHint.installatie", 'Installatie')}</Translation>,
        [PrioriteitHint.INTERVENTIE_MET_SLA]:
            <Translation>{t =>t("algemeen:PrioriteitHint.interventie-met-SLA", 'Interventie (met SLA)')}</Translation>,
        [PrioriteitHint.INTERVENTIE_MET_LEK]:
            <Translation>{t =>t("algemeen:PrioriteitHint.interventie-met-lek", 'Interventie (met lek)')}</Translation>,
        [PrioriteitHint.INTERVENTIE]:
            <Translation>{t =>t("algemeen:PrioriteitHint.interventie", 'Interventie')}</Translation>,
        [PrioriteitHint.LEVERING]:
            <Translation>{t =>t("algemeen:PrioriteitHint.levering", 'Levering')}</Translation>,
        [PrioriteitHint.ONDERHOUD_DRINGEND]:
            <Translation>{t =>t("algemeen:PrioriteitHint.dringend-onderhoud", 'Dringend onderhoud')}</Translation>,
        [PrioriteitHint.ONDERHOUD_MET_LEVERING]:
            <Translation>{t =>t("algemeen:PrioriteitHint.onderhoud-met-levering", 'Onderhoud met levering')}</Translation>,
        [PrioriteitHint.ONDERHOUD_ZONDER_LEVERING]:
            <Translation>{t =>t("algemeen:PrioriteitHint.onderhoud-zonder-levering", 'Onderhoud zonder levering')}</Translation>,
        [PrioriteitHint.MINST_DRINGEND]:
            <Translation>{t =>t("algemeen:PrioriteitHint.minst-dringend", 'Minst dringend')}</Translation>,
        [PrioriteitHint.AUTOMATISCH]:
            <Translation>{t =>t("algemeen:PrioriteitHint.automatisch", 'Automatisch')}</Translation>,

        [Co2BestellingStatus.NIEUW]:
            <Translation>{t =>t("algemeen:Co2BestellingStatus.nieuw", "Nieuw")}</Translation>,
        [Co2BestellingStatus.GEANNULEERD]:
            <Translation>{t =>t("algemeen:Co2BestellingStatus.geannuleerd", "Geannuleerd")}</Translation>,
        [Co2BestellingStatus.PLANNING]:
            <Translation>{t =>t("algemeen:Co2BestellingStatus.planning", "Planning")}</Translation>,
        [Co2BestellingStatus.VERZENDEN_MET_KOERIER]:
            <Translation>{t =>t("algemeen:Co2BestellingStatus.verzenden-met-koerier", "Verzenden met koerier")}</Translation>,
        [Co2BestellingStatus.VERZONDEN_MET_KOERIER]:
            <Translation>{t =>t("algemeen:Co2BestellingStatus.verzonden-met-koerier", "Verzonden met koerier")}</Translation>,
        [Co2BestellingStatus.GELEVERD]:
            <Translation>{t =>t("algemeen:Co2BestellingStatus.geleverd", "Geleverd")}</Translation>,
        [Co2BestellingStatus.WACHT_OP_UITVOERING]:
            <Translation>{t =>t("algemeen:Co2BestellingStatus.wacht-op-uitvoering", "Wacht op uitvoering")}</Translation>,
        [Co2BestellingStatus.NIET_UITGEVOERD]:
            <Translation>{t =>t("algemeen:Co2BestellingStatus.niet-uitgevoerd", "Niet uitgevoerd")}</Translation>,

        [Skill.INSTALLATIE_KRAAN]:
            <Translation>{t =>t("algemeen:Skill.installatie-kraan", "Installatie kraan")}</Translation>,
        [Skill.INSTALLATIE_KRAAN_MET_BOILER]:
            <Translation>{t =>t("algemeen:Skill.installatie-kraan-met-boiler", "Installatie kraan met boiler")}</Translation>,
        [Skill.INSTALLATIE_KRAAN_MET_KELDERUNIT]:
            <Translation>{t =>t("algemeen:Skill.installatie-kraan-met-kelderunit", "Installatie kraan met kelderunit")}</Translation>,
        [Skill.INSTALLATIE_KRAAN_MET_PYTHON]:
            <Translation>{t =>t("algemeen:Skill.installatie-kraan-met-python", "Installatie kraan met python")}</Translation>,
        [Skill.INSTALLATIE_TOESTEL]:
            <Translation>{t =>t("algemeen:Skill.installatie-toestel", "Installatie toestel")}</Translation>,
        [Skill.INSTALLATIE_CASA]:
            <Translation>{t =>t("algemeen:Skill.installatie-casa", "Installatie Casa")}</Translation>,
        [Skill.INSTALLATIE_BOREN_DOOR_STEEN]:
            <Translation>{t =>t("algemeen:Skill.boren-door-steen", "Installatie boren door steen")}</Translation>,
        [Skill.INTERVENTIE_COMPLEX]:
            <Translation>{t =>t("algemeen:Skill.interventie-complex", "Interventie complex")}</Translation>,
        [Skill.INTERVENTIE_STANDAARD]:
            <Translation>{t =>t("algemeen:Skill.interventie-standaard", "Interventie standaard")}</Translation>,
        [Skill.INTERVENTIE_EENVOUDIG]:
            <Translation>{t =>t("algemeen:Skill.interventie-eenvoudig", "Interventie eenvoudig")}</Translation>,
        [Skill.ONDERHOUD]:
            <Translation>{t =>t("algemeen:Skill.onderhoud", "Onderhoud")}</Translation>,
        [Skill.LEVERING_CO2_APART]:
            <Translation>{t =>t("algemeen:Skill.levering-co2-apart", "Levering CO₂ apart")}</Translation>,
        [Skill.LEVERING_CO2_GECOMBINEERD]:
            <Translation>{t =>t("algemeen:Skill.levering-co2-gecombineerd", "Levering CO₂ gecombineerd")}</Translation>,
        [Skill.LEVERING_ACCESSOIRES]:
            <Translation>{t =>t("algemeen:Skill.levering-accessoires", "Levering accessoires")}</Translation>,
        [Skill.LEVERING_TOESTEL]:
            <Translation>{t =>t("algemeen:Skill.levering-toestel", "Levering toestel")}</Translation>,

        [ToestelInstallatieStatus.ONVOLLEDIG]:
            <Translation>{t =>t("algemeen:ToestelInstallatieStatus.onvolledig", "Onvolledig")}</Translation>,
        [ToestelInstallatieStatus.VOLLEDIG]:
            <Translation>{t =>t("algemeen:ToestelInstallatieStatus.volledig", "Volledig")}</Translation>,

        [ServiceBezoekOpvolgingStatus.NIEUW]:
            <Translation>{t =>t("algemeen:ServiceBezoekOpvolgingStatus.nieuw", "Nieuw")}</Translation>,
        [ServiceBezoekOpvolgingStatus.AUTOMATISCH_VERWERKEN]:
            <Translation>{t =>t("algemeen:ServiceBezoekOpvolgingStatus.automatisch-verwerken", "Automatisch verwerken")}</Translation>,
        [ServiceBezoekOpvolgingStatus.OPVOLGING_NODIG]:
            <Translation>{t =>t("algemeen:ServiceBezoekOpvolgingStatus.opvolging-nodig", "Opvolging nodig",)}</Translation>,
        [ServiceBezoekOpvolgingStatus.TERUG_BIJ_TECHNICUS]:
            <Translation>{t =>t("algemeen:ServiceBezoekOpvolgingStatus.terug-bij-technicus", "Terug bij technicus")}</Translation>,
        [ServiceBezoekOpvolgingStatus.GEARCHIVEERD]:
            <Translation>{t =>t("algemeen:ServiceBezoekOpvolgingStatus.gearchiveerd", "Gearchiveerd")}</Translation>,
        [ServiceBezoekOpvolgingStatus.FOUT]:
            <Translation>{t =>t("algemeen:ServiceBezoekOpvolgingStatus.fout", "Fout")}</Translation>,

        [OpvolgingItemStatus.TE_VERWERKEN]:
            <Translation>{t =>t("algemeen:OpvolgingItemStatus.te-verwerken", "Te verwerken")}</Translation>,
        [OpvolgingItemStatus.OPVOLGING_NODIG]:
            <Translation>{t =>t("algemeen:OpvolgingItemStatus.opvolging-nodig", "Opvolging nodig")}</Translation>,
        [OpvolgingItemStatus.VERWERKT]:
            <Translation>{t =>t("algemeen:OpvolgingItemStatus.verwerkt", "Verwerkt")}</Translation>,
        [OpvolgingItemStatus.FOUT]:
            <Translation>{t =>t("algemeen:OpvolgingItemStatus.fout", "Fout")}</Translation>,
        [OpvolgingItemStatus.GEANNULEERD]:
            <Translation>{t =>t("algemeen:OpvolgingItemStatus.geannuleerd", "Geannuleerd")}</Translation>,

        [opvolgingItemDefinitieType.WATERMETER_STAND_OPNAME]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.watermeterstand", "Watermeterstand")}</Translation>,
        [opvolgingItemDefinitieType.CO2_LEVERING]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.co2-levering", "CO₂ Levering")}</Translation>,
        [opvolgingItemDefinitieType.MAAK_EN_VERSTUUR_SERVICE_RAPPORT]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.service-rapport", "Service Rapport")}</Translation>,
        [opvolgingItemDefinitieType.INTERNE_FEEDBACK]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.interne-feedback", "Interne Feedback")}</Translation>,
        [opvolgingItemDefinitieType.KLANT_EMAIL_ADRES_AANPASSING]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.klant-e-mail-adreswijziging", "Klant e-mailadreswijziging")}</Translation>,
        [opvolgingItemDefinitieType.TOESTEL_INFORMATIE_AANPASSING]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.toestelinformatie-aanpassing", "Toestelinformatie-aanpassing")}</Translation>,
        [opvolgingItemDefinitieType.TOESTEL_STUKLIJST_AANPASSING]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.toestelstuklijst-aanpassing", "Toestelstuklijst-aanpassing")}</Translation>,
        [opvolgingItemDefinitieType.KLANT_OPMERKING]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.klantopmerking", "Klantopmerking")}</Translation>,
        [opvolgingItemDefinitieType.TOESTEL_BIJLAGEN]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.toestelbijlagen", "Toestelbijlagen")}</Translation>,
        [opvolgingItemDefinitieType.TOESTEL_ONDERHOUD]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.toestelonderhoud", "Toestelonderhoud")}</Translation>,
        [opvolgingItemDefinitieType.TOESTEL_INTERVENTIE]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.toestelinterventie", "Toestelinterventie")}</Translation>,
        [opvolgingItemDefinitieType.ACCESSOIRE_LEVERING]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.accessoirelevering", "Accessoirelevering")}</Translation>,
        [opvolgingItemDefinitieType.INSTALLATIE]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.installatie", "Installatie")}</Translation>,
        [opvolgingItemDefinitieType.NIET_UITGEVOERD_WERK]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.niet-uitgevoerd-werk", "Niet uitgevoerd werk")}</Translation>,
        [opvolgingItemDefinitieType.UPDATE_PLANNING]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.update-planning", "Update planning")}</Translation>,
        [opvolgingItemDefinitieType.DYNAMICS_ORDERS_OPVOLGING]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.dynamics-orders", "Dynamics-orders")}</Translation>,
        [opvolgingItemDefinitieType.KLANT_COMMUNICATIE]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.klantcommunicatie", "Klantcommunicatie")}</Translation>,
        [opvolgingItemDefinitieType.TRANSPORT_KOST_OPVOLGING]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.transportkost", "Transportkost")}</Translation>,
        [opvolgingItemDefinitieType.TOESTEL_SERVICE_LOG_BOEK_AANVULLEN]:
            <Translation>{t =>t("algemeen:OpvolgingItemDefinitieType.toestel-servicelogboek-aanvullen", "Servicelogboek aanvullen")}</Translation>,

        [OorsprongType.MY_AQUALEX]:
            <Translation>{t =>t("algemeen:OorsprongType.my-aqualex", "MyAQUALEX")}</Translation>,
        [OorsprongType.TER_PLAATSE]:
            <Translation>{t =>t("algemeen:OorsprongType.ter-plaatse", "Ter Plaatse")}</Translation>,
        [OorsprongType.BACK_OFFICE]:
            <Translation>{t =>t("algemeen:OorsprongType.back-office", "Back-Office")}</Translation>,
        [OorsprongType.NALEVERING]:
            <Translation>{t =>t("algemeen:OorsprongType.nalevering", "Nalevering")}</Translation>,

        [ProductType.KRAAN]:
            <Translation>{t =>t("algemeen:ProductType.kraan", "Kraan")}</Translation>,
        [ProductType.CASA]:
            <Translation>{t =>t("algemeen:ProductType.casa", "Casa")}</Translation>,
        [ProductType.TOESTEL]:
            <Translation>{t =>t("algemeen:ProductType.toestel", "Toestel")}</Translation>,
        [ProductType.ANDERE]:
            <Translation>{t =>t("algemeen:ProductType.andere", "Andere")}</Translation>,

        [NietBezochtRedenType.ADRES_NIET_GEVONDEN]:
            <Translation>
                {t =>t(
                    "algemeen:NietBezochtRedenType.adres-niet-gevonden",
                    "Adres niet gevonden")}
            </Translation>,
        [NietBezochtRedenType.ADRES_ONBEREIKBAAR]:
            <Translation>{t =>t(
                "algemeen:NietBezochtRedenType.adres-onbereikbaar",
                "Adres onbereikbaar")}
            </Translation>,
        [NietBezochtRedenType.ADRES_UITZONDERLIJK_GESLOTEN]:
            <Translation>{t =>t(
                "algemeen:NietBezochtRedenType.adres-uitzonderlijk-gesloten",
                "Adres uitzonderlijk gesloten")}</Translation>,
        [NietBezochtRedenType.ADRES_GESLOTEN_OPENINGSUREN_INCORRECT]:
            <Translation>{t =>t(
                "algemeen:NietBezochtRedenType.adres-gesloten-openingsuren-incorrect",
                "Adres gesloten, openingsuren incorrect")}</Translation>,
        [NietBezochtRedenType.ADRES_GESLOTEN_OPENINGSUREN_CORRECT_MAAR_BUITEN_OPENINGSUREN_AANGEKOMEN]:
            <Translation>{t =>t(
                "algemeen:NietBezochtRedenType.adres-gesloten-openingsuren-correct-maar-buiten-openingsuren-aangekomen",
                "Adres gesloten, openingsuren correct, maar buiten openingsuren aangekomen")}
            </Translation>,
        [NietBezochtRedenType.KLANT_VERHUISD]:
            <Translation>{t =>t("algemeen:NietBezochtRedenType.klant-verhuisd",
                "Klant verhuisd")}
            </Translation>,
        [NietBezochtRedenType.NIET_VOLDOENDE_TIJD_DOOR_OPENINGSUREN]:
            <Translation>{t =>t(
                "algemeen:NietBezochtRedenType.onvoldoende-tijd-door-openingsuren",
                "Onvoldoende tijd door openingsuren")}
            </Translation>,
        [NietBezochtRedenType.NIET_VOLDOENDE_TIJD_DOOR_EINDE_SHIFT]:
            <Translation>{t =>t(
                "algemeen:NietBezochtRedenType.onvoldoende-tijd-door-einde-shift",
                "Onvoldoende tijd door einde shift")}
            </Translation>,
        [NietBezochtRedenType.PLANNINGSWIJZIGING_VANUIT_AQUALEX]:
            <Translation>{t =>t(
                "algemeen:NietBezochtRedenType.planningswijziging-vanuit-aqualex",
                "Planningswijziging vanuit Aqualex")}
            </Translation>,
        [NietBezochtRedenType.ANDERE_TECHNICUS_WERKT_VERDER]:
            <Translation>{t =>t(
                "algemeen:NietBezochtRedenType.andere-technicus-werkt-verder",
                "Andere technicus werkt verder")}
            </Translation>,
        [NietBezochtRedenType.ANDERE]:
            <Translation>
                {t =>t("algemeen:NietBezochtRedenType.andere", "Andere")}
            </Translation>,

        [NietUitgevoerdRedenType.NIET_VOLDOENDE_TIJD_DOOR_OPENINGSUREN]:
            <Translation>
                {t =>t(
                    "algemeen:NietUitgevoerdRedenType.onvoldoende-tijd-door-openingsuren",
                    "Onvoldoende tijd door openingsuren")}
            </Translation>,
        [NietUitgevoerdRedenType.NIET_VOLDOENDE_TIJD_DOOR_EINDE_SHIFT]:
            <Translation>
                {t =>t(
                    "algemeen:NietUitgevoerdRedenType.onvoldoende-tijd-door-einde-shift",
                    "Onvoldoende tijd door einde shift")}
            </Translation>,
        [NietUitgevoerdRedenType.PLANNINGSWIJZIGING_VANUIT_AQUALEX]:
            <Translation>
                {t =>t(
                    "algemeen:NietUitgevoerdRedenType.planningswijziging-vanuit-aqualex",
                    "Planningswijziging vanuit Aqualex")}
            </Translation>,
        [NietUitgevoerdRedenType.ANDERE_TECHNICUS_WERKT_VERDER]:
            <Translation>
                {t =>t(
                    "algemeen:NietUitgevoerdRedenType.andere-technicus-werkt-verder",
                    "Andere technicus werkt verder")}
            </Translation>,
        [NietUitgevoerdRedenType.ANDERE]:
            <Translation>
                {t =>t(
                    "algemeen:NietUitgevoerdRedenType.andere",
                    "Andere")}
            </Translation>,

        [InterventieTaakType.HERSTELLING]:
            <Translation>{t => t("algemeen:InterventieTaakType.herstelling", "Herstelling")}</Translation>,
        [InterventieTaakType.UPGRADE]:
            <Translation>{t => t("algemeen:InterventieTaakType.upgrade", "Upgrade")}</Translation>,
        [InterventieTaakType.VERPLAATSING]:
            <Translation>{t => t("algemeen:InterventieTaakType.verplaatsing", "Verplaatsing")}</Translation>,
        [InterventieTaakType.VERHUIS]:
            <Translation>{t => t("algemeen:InterventieTaakType.verhuis", "Verhuis")}</Translation>,
        [InterventieTaakType.OPHALING]:
            <Translation>{t => t("algemeen:InterventieTaakType.ophaling", "Ophaling")}</Translation>,
        [InterventieTaakType.STAALNAME_LABO_ONDERZOEK]:
            <Translation>{t => t("algemeen:InterventieTaakType.staalname-labo-onderzoek", "Staalname labo-onderzoek")}</Translation>,
        [InterventieTaakType.ADVIES]:
            <Translation>{t => t("algemeen:InterventieTaakType.advies", "Advies")}</Translation>,
        [InterventieTaakType.OPSTART]:
            <Translation>{t => t("algemeen:InterventieTaakType.opstart", "Opstart")}</Translation>,

        [InterventiePrioriteit.LAAG]:
            <Translation>{t => t("algemeen:InterventiePrioriteit.laag", "Laag")}</Translation>,
        [InterventiePrioriteit.SLA]:
            <Translation>{t => t("algemeen:InterventiePrioriteit.sla", "SLA")}</Translation>,
        [InterventiePrioriteit.NORMAAL]:
            <Translation>{t => t("algemeen:InterventiePrioriteit.normaal", "Normaal")}</Translation>,
        [InterventiePrioriteit.LEK]:
            <Translation>{t => t("algemeen:InterventiePrioriteit.lek", "Lek")}</Translation>,

        [InterventieStatus.IN_ONTWERP]:
            <Translation>{t => t("algemeen:InterventieStatus.in-ontwerp", "In ontwerp")}</Translation>,
        [InterventieStatus.GEANNULEERD]:
            <Translation>{t => t("algemeen:InterventieStatus.geannuleerd", "Geannuleerd")}</Translation>,
        [InterventieStatus.UITGEVOERD]:
            <Translation>{t => t("algemeen:InterventieStatus.uitgevoerd", "Uitgevoerd")}</Translation>,
        [InterventieStatus.PLANNEN]:
            <Translation>{t => t("algemeen:InterventieStatus.plannen", "Plannen")}</Translation>,
        [InterventieStatus.WACHT_OP_KLANT_AKKOORD]:
            <Translation>{t => t("algemeen:InterventieStatus.wacht-op-akkoord", "Wacht op akkoord")}</Translation>,
        [InterventieStatus.KLANT_AKKOORD]:
            <Translation>{t => t("algemeen:InterventieStatus.klant-akkoord", "Klant akkoord")}</Translation>,
        [InterventieStatus.KLANT_NIET_AKKOORD]:
            <Translation>{t => t("algemeen:InterventieStatus.klant-niet-akkoord", "Klant niet akkoord")}</Translation>,
        [InterventieStatus.WACHT_OP_UITVOERING]:
            <Translation>{t => t("algemeen:InterventieStatus.wacht-op-uitvoering", "Wacht op uitvoering")}</Translation>,
        [InterventieStatus.NIET_UITGEVOERD]:
            <Translation>{t => t("algemeen:InterventieStatus.niet-uitgevoerd", "Niet uitgevoerd")}</Translation>,

        [PrestatieStatus.UIT_TE_VOEREN]:
            <Translation>{t => t("algemeen:PrestatieStatus.uit-te-voeren", "Uit te voeren")}</Translation>,
        [PrestatieStatus.VOLTOOID]:
            <Translation>{t => t("algemeen:InterventieStatus.voltooid", "Voltooid")}</Translation>,
        [PrestatieStatus.ONVOLTOOID]:
            <Translation>{t => t("algemeen:InterventieStatus.onvoltooid", "Onvoltooid")}</Translation>,
        [PrestatieStatus.GEANNULEERD]:
            <Translation>{t => t("algemeen:InterventieStatus.geannuleerd", "Geannuleerd")}</Translation>,

        [BestelbonVerzendDatumType.OP_DATUM]:
            <Translation>{t => t("algemeen:BestelbonVerzendDatumType.op", "Op")}</Translation>,
        [BestelbonVerzendDatumType.VANAF]:
            <Translation>{t => t("algemeen:BestelbonVerzendDatumType.vanaf", "Vanaf")}</Translation>,
        [BestelbonVerzendDatumType.ZO_SNEL_MOGELIJK]:
            <Translation>{t => t("algemeen:BestelbonVerzendDatumType.zo-snel-mogelijk", "Zo snel mogelijk")}</Translation>,

        [TechnicusProfiel.ALL_ROUND]:
            <Translation>{t => t("algemeen:TechnicusProfiel.all-round", "All-round")}</Translation>,
        [TechnicusProfiel.ONDERHOUDER]:
            <Translation>{t => t("algemeen:TechnicusProfiel.onderhouder", "Onderhouder")}</Translation>,
        [TechnicusProfiel.INSTALLATEUR]:
            <Translation>{t => t("algemeen:TechnicusProfiel.installateur", "Installateur")}</Translation>,
        [TechnicusProfiel.UIT_DIENST]:
            <Translation>{t => t("algemeen:TechnicusProfiel.uit-dienst", "Uit dienst")}</Translation>,

        [DayOfWeek.MONDAY]:
            <Translation>{t => t("algemeen:DayOfWeek.maandag", "Maandag")}</Translation>,
        [DayOfWeek.TUESDAY]:
            <Translation>{t => t("algemeen:DayOfWeek.dinsdag", "Dinsdag")}</Translation>,
        [DayOfWeek.WEDNESDAY]:
            <Translation>{t => t("algemeen:DayOfWeek.woensdag", "Woensdag")}</Translation>,
        [DayOfWeek.THURSDAY]:
            <Translation>{t => t("algemeen:DayOfWeek.donderdag", "Donderdag")}</Translation>,
        [DayOfWeek.FRIDAY]:
            <Translation>{t => t("algemeen:DayOfWeek.vrijdag", "Vrijdag")}</Translation>,
        [DayOfWeek.SATURDAY]:
            <Translation>{t => t("algemeen:DayOfWeek.zaterdag", "Zaterdag")}</Translation>,
        [DayOfWeek.SUNDAY]:
            <Translation>{t => t("algemeen:DayOfWeek.zondag", "Zondag")}</Translation>,

        [KlantCommunicatieType.MAIL_BEZOEK_NIET_GELUKT]:
            <Translation>
                {t => t("algemeen:KlantCommunicatieType.mail-bezoek-niet-gelukt",
                    "Bezoek niet gelukt (e-mail)")}
            </Translation>,
        [KlantCommunicatieType.MAIL_SERVICE_RAPPORT]:
            <Translation>
                {t => t("algemeen:KlantCommunicatieType.mail-service-rapport",
                    "Servicerapport (e-mail)")}
            </Translation>,

        [TransportKostConfiguratieType.INHERIT]:
            <Translation>{t => t("algemeen:TransportKostConfiguratieType.overnemen", "Overnemen")}</Translation>,
        [TransportKostConfiguratieType.NOOIT]:
            <Translation>{t => t("algemeen:TransportKostConfiguratieType.nooit-aanrekenen", "Nooit aanrekenen")}</Translation>,
        [TransportKostConfiguratieType.STANDAARD]:
            <Translation>{t => t("algemeen:TransportKostConfiguratieType.standaardformule", "Standaardformule")}</Translation>,
        [TransportKostConfiguratieType.VAST]:
            <Translation>{t => t("algemeen:TransportKostConfiguratieType.vast", "Vast")}</Translation>,

        [OnvolledigeAccessoireLeveringReden.TE_WEINIG_ARTIKELEN_BESCHIKBAAR]:
            <Translation>{t => t("algemeen:OnvolledigeAccessoireLeveringReden.te-weinig-artikelen-beschikbaar",
                "Te weinig artikelen beschikbaar")}</Translation>,
        [OnvolledigeAccessoireLeveringReden.ANDER_SOORT_ARTIKEL_GELEVERD]:
            <Translation>{t => t("algemeen:OnvolledigeAccessoireLeveringReden.ander-soort-artikel-te-leveren",
                "Andere soort artikelen geleverd")}</Translation>,
        [OnvolledigeAccessoireLeveringReden.KLANT_AKKOORD]:
            <Translation>{t => t("algemeen:OnvolledigeAccessoireLeveringReden.klant-akkoord",
                "Klant akkoord")}</Translation>,
        [OnvolledigeAccessoireLeveringReden.VRAAG_KLANT]:
            <Translation>{t => t("algemeen:OnvolledigeAccessoireLeveringReden.vraag-klant",
                "Vraag klant")}</Translation>,

        [MyAqualexZichtbaarheid.ZICHTBAAR]:
            <Translation>{t => t("algemeen:MyAqualexZichtbaarheid.zichtbaar",
                "Zichtbaar")}</Translation>,
        [MyAqualexZichtbaarheid.VERBORGEN]:
            <Translation>{t => t("algemeen:MyAqualexZichtbaarheid.verborgen",
                "Verborgen")}</Translation>,
        [MyAqualexZichtbaarheid.NIET_BESCHIKBAAR]:
            <Translation>{t => t("algemeen:MyAqualexZichtbaarheid.niet-beschikbaar",
                "Niet beschikbaar")}</Translation>,

        [InstallatieStatusStatus.IN_VOORBEREIDING]:
            <Translation>{t => t("algemeen:InstallatieStatusStatus.in-voorbereiding", "In voorbereiding")}</Translation>,
        [InstallatieStatusStatus.UITGESTELD]:
            <Translation>{t => t("algemeen:InstallatieStatusStatus.uitgesteld", "Uitgesteld")}</Translation>,
        [InstallatieStatusStatus.GEANNULEERD]:
            <Translation>{t => t("algemeen:InstallatieStatusStatus.geannuleerd", "Geannuleerd")}</Translation>,
        [InstallatieStatusStatus.PLANNING]:
            <Translation>{t => t("algemeen:InstallatieStatusStatus.planning", "Planning")}</Translation>,
        [InstallatieStatusStatus.WACHT_OP_UITVOERING]:
            <Translation>{t => t("algemeen:InstallatieStatusStatus.wacht-op-uitvoering", "Wacht op uitvoering")}</Translation>,
        [InstallatieStatusStatus.GEINSTALLEERD]:
            <Translation>{t => t("algemeen:InstallatieStatusStatus.geïnstalleerd", "Geïnstalleerd")}</Translation>,
        [InstallatieStatusStatus.NIET_UITGEVOERD]:
            <Translation>{t => t("algemeen:InstallatieStatusStatus.niet-uitgevoerd", "Niet uitgevoerd")}</Translation>,

        dagPlanningStatus: {
            [DagPlanningStatus.ONTWERP]:
                <Translation>{t => t("algemeen:DagplanningStatus.ontwerp", "Ontwerp")}</Translation>,
            [DagPlanningStatus.GEARCHIVEERD]:
                <Translation>{t => t("algemeen:DagplanningStatus.gearchiveerd", "Gearchiveerd")}</Translation>,
            [DagPlanningStatus.VASTGELEGD]:
                <Translation>{t => t("algemeen:DagplanningStatus.vastgelegd", "Vastgelegd")}</Translation>,
            [DagPlanningStatus.NIETS_PLANNEN]:
                <Translation>{t => t("algemeen:DagplanningStatus.niets-plannen", "Niets plannen")}</Translation>
        },

        dagPlanningFase: {
            [DagPlanningFase.INSTALLATIE]:
                <Translation>{t => t("algemeen:DagplanningFase.installatie-fase", "Installatiefase")}</Translation>,
            [DagPlanningFase.KEY]:
                <Translation>{t => t("algemeen:DagplanningFase.key-account-fase", "Key account fase")}</Translation>,
            [DagPlanningFase.INTERVENTIE]:
                <Translation>{t => t("algemeen:DagplanningFase.interventie", "Interventie")}</Translation>,
            [DagPlanningFase.ONDERHOUD]:
                <Translation>{t => t("algemeen:DagplanningFase.onderhoud", "Onderhoud")}</Translation>,
            [DagPlanningFase.AUTOMATISCH_PLANNEN]:
                <Translation>{t => t("algemeen:DagplanningFase.automatisch-plannen", "Automatisch plannen")}</Translation>
        },

        extraCo2FlesBeleid: {
            [ExtraCo2FlesBeleid.INHERIT]:
                <Translation>{t => t("algemeen:ExtraCo2FlesBeleid.overnemen", "Overnemen")}</Translation>,
            [ExtraCo2FlesBeleid.GEBRUIKSRECHT]:
                <Translation>{t => t("algemeen:ExtraCo2FlesBeleid.gebruiksrecht", "Gebruiksrecht")}</Translation>,
            [ExtraCo2FlesBeleid.WAARBORG]:
                <Translation>{t => t("algemeen:ExtraCo2FlesBeleid.waarborg", "Waarborg")}</Translation>,
            [ExtraCo2FlesBeleid.GEEN]:
                <Translation>{t => t("algemeen:ExtraCo2FlesBeleid.geen", "Geen")}</Translation>
        },

        verkoopOrderStatus: {
            [VerkoopOrderStatus.OPEN]:
                <Translation>{t => t("algemeen:VerkoopOrderStatus.open", "Open")}</Translation>,
            [VerkoopOrderStatus.VRIJGEGEVEN]:
                <Translation>{t => t("algemeen:VerkoopOrderStatus.vrijgegeven", "Vrijgegeven")}</Translation>,
            [VerkoopOrderStatus.WACHT_OP_GOEDKEURING]:
                <Translation>{t => t("algemeen:VerkoopOrderStatus.wacht-op-goedkeuring", "Wacht op goedkeuring")}</Translation>,
            [VerkoopOrderStatus.WACHT_OP_VOORUITBETALING]:
                <Translation>{t => t("algemeen:VerkoopOrderStatus.wacht-op-vooruitbetaling", "Wacht op vooruitbetaling")}</Translation>
        },

        talen: {
            nl: <Translation>{t => t("algemeen:Talen.nederlands", "Nederlands")}</Translation>,
            fr_BE: <Translation>{t => t("algemeen:Talen.frans-belgie", "Frans (België)")}</Translation>,
            fr_FR: <Translation>{t => t("algemeen:Talen.frans-frankrijk", "Frans (Frankrijk)")}</Translation>,
            de: <Translation>{t => t("algemeen:Talen.duits", "Duits")}</Translation>,
            en: <Translation>{t => t("algemeen:Talen.engels", "Engels")}</Translation>
        },

        interventieGarantieProfiel: {
            [InterventieGarantieProfiel.HUUR]:
                <Translation>{t => t("algemeen:InterventieGarantieProfiel.huur-inbegrepen", "Huur; inbegrepen")}</Translation>,
            [InterventieGarantieProfiel.KOOP_GARANTIE_24]:
                <Translation>{t => t("algemeen:InterventieGarantieProfiel.wettelijke-garantie", "Wettelijke garantie")}</Translation>,
            [InterventieGarantieProfiel.KOOP_GARANTIE_EXT27]:
                <Translation>{t => t("algemeen:InterventieGarantieProfiel.uitgebreide-garantie-27-maanden", "Uitgebreide garantie (27 maanden)")}</Translation>,
            [InterventieGarantieProfiel.KOOP_GARANTIE_EXT30]:
                <Translation>{t => t("algemeen:InterventieGarantieProfiel.uitgebreide-garantie-30-maanden", "Uitgebreide garantie (30 maanden)")}</Translation>,
            [InterventieGarantieProfiel.KOOP_GARANTIE_EXT36]:
                <Translation>{t => t("algemeen:InterventieGarantieProfiel.uitgebreide-garantie-36-maanden", "Uitgebreide garantie (36 maanden)")}</Translation>,
            [InterventieGarantieProfiel.KOOP_BUITEN_GARANTIE]:
                <Translation>{t => t("algemeen:InterventieGarantieProfiel.buiten-garantie", "Buiten garantie")}</Translation>,
            [InterventieGarantieProfiel.GEEN_GARANTIEGEVAL]:
                <Translation>{t => t("algemeen:InterventieGarantieProfiel.geen-geval-van-garantie", "Geen geval van garantie")}</Translation>,
            [InterventieGarantieProfiel.SPECIALE_BEHANDELING_BETALEND]:
                <Translation>{t => t("algemeen:InterventieGarantieProfiel.speciale-behandeling-betalend", "Speciale behandeling")}</Translation>,
            [InterventieGarantieProfiel.SPECIALE_BEHANDELING_GRATIS]:
                <Translation>{t => t("algemeen:InterventieGarantieProfiel.speciale-behandeling-gratis", "Speciale behandeling (gratis)")}</Translation>
        },

        geenKlantAccountMakenReden: {
            [GeenKlantAccountMakenReden.KLANT_HEEFT_GEEN_EMAILADRES]:
                <Translation>{t => t("algemeen:GeenKlantAccountMakenReden.klant-heeft-geen-e-mail-adres", "Klant heeft geen e-mailadres")}</Translation>,
            [GeenKlantAccountMakenReden.EMAILADRES_VOOR_KLANTACCOUNT_NOG_NIET_BEKEND]:
                <Translation>{t => t("algemeen:GeenKlantAccountMakenReden.e-mail-adres-voor-klantaccount-nog-niet-bekend",
                    "E-mailadres voor klantaccount nog niet bekend")}</Translation>,
            [GeenKlantAccountMakenReden.KLANT_WIL_GEEN_MYAQUALEX_ACCOUNT]:
                <Translation>{t => t("algemeen:GeenKlantAccountMakenReden.klant-wil-geen-myaqualex-account", "Klant wil geen MyAQUALEX account")}</Translation>,
            [GeenKlantAccountMakenReden.NIET_BESPROKEN_MET_KLANT]:
                <Translation>{t => t("algemeen:GeenKlantAccountMakenReden.niet-besproken-met-klant", "Niet besproken met klant")}</Translation>,
            [GeenKlantAccountMakenReden.ANDERE]:
                <Translation>{t => t("algemeen:GeenKlantAccountMakenReden.andere", "Andere")}</Translation>
        },

        serviceBezoekOpvolgingNiveau: {
            [ServiceBezoekOpvolgingNiveau.INSTALLATIE]:
                <Translation>{t => t("algemeen:ServiceBezoekOpvolgingNiveau.installatie", "Installatie")}</Translation>,
            [ServiceBezoekOpvolgingNiveau.INTERVENTIE]:
                <Translation>{t => t("algemeen:ServiceBezoekOpvolgingNiveau.interventie", "Interventie")}</Translation>,
            [ServiceBezoekOpvolgingNiveau.ONDERHOUD]:
                <Translation>{t => t("algemeen:ServiceBezoekOpvolgingNiveau.onderhoud", "Onderhoud")}</Translation>
        },

        transactieType: {
            [TransactieType.VERKOOP]: <Translation>{t => t("algemeen:Labels.verkoop", "Verkoop")}</Translation>,
            [TransactieType.HUUR]: <Translation>{t => t("algemeen:Labels.huur", "Huur")}</Translation>
        },

        installatieDossierStatus: {
            [InstallatieDossierStatus.OPGESTART]: <Translation>{t => t("installatie:Labels.opgestart", "Opgestart")}</Translation>,
            [InstallatieDossierStatus.KLAAR]: <Translation>{t => t("installatie:Labels.klaar", "Klaar")}</Translation>,
            [InstallatieDossierStatus.BIJ_KLANT]: <Translation>{t => t("installatie:Labels.bij-klant", "Bij klant")}</Translation>,
            [InstallatieDossierStatus.EVALUEREN]: <Translation>{t => t("installatie:Labels.evalueren", "Evalueren")}</Translation>,
        },

        medewerkerRol: {
            [MedewerkerRol.PLATFORM_ADMINISTRATOR]: <Translation>{t => t("medewerker:Labels.administrator", "Administrator")}</Translation>,
            [MedewerkerRol.PLATFORM_ADMINISTRATIEF_MEDEWERKER]: <Translation>{t => t("medewerker:Labels.administratief-medewerker", "Administratief Medewerker")}</Translation>,
            [MedewerkerRol.PLATFORM_TECHNIEKER]: <Translation>{t => t("medewerker:Labels.technieker", "Technieker", "Technieker")}</Translation>,
            [MedewerkerRol.PLATFORM_PLANNER]:
                <Translation>{t => t("medewerker:Labels.planner", "Planner")}</Translation>
        },

        toestelOnderhoudType: {
            // do this for reiniger, reinigen filter vervangen, reinigen met uv, reinigen met uv filter vervangen
            [ToestelOnderhoudType.REINIGEN]: <Translation>{t => t("toestel:Labels.reinigen", "Reinigen")}</Translation>,
            [ToestelOnderhoudType.REINIGEN_FILTER_VERVANGEN]: <Translation>{t => t("toestel:Labels.reinigen-filter-vervangen", "Reinigen Filter Vervangen")}</Translation>,
            [ToestelOnderhoudType.REINIGEN_MET_UV]: <Translation>{t => t("toestel:Labels.reinigen-met-uv", "Reinigen met UV")}</Translation>,
            [ToestelOnderhoudType.REINIGEN_MET_UV_FILTER_VERVANGEN]: <Translation>{t => t("toestel:Labels.reinigen-met-uv-filter-vervangen", "Reinigen met UV-filter vervangen")}</Translation>
        },

        serviceArtikelStatus: {
            [ServiceArtikelStatus.BLANCO]: <Translation>{t => t("toestel:Labels.blanco", "Blanco")}</Translation>,
            [ServiceArtikelStatus.INSTALLED]: <Translation>{t => t("toestel:Labels.geinstalleerd", "Geïnstalleerd")}</Translation>,
            [ServiceArtikelStatus.OWN_SERVICE_ITEM]: <Translation>{t => t("toestel:Labels.eigenaar-serviceartikel", "Eigenaar serviceartikel")}</Translation>,
            [ServiceArtikelStatus.TEMPORARILY_INSTALLED]: <Translation>{t => t("toestel:Labels.tijdelijk-geinstalleerd", "Tijdelijke geïnstalleerd")}</Translation>,
            [ServiceArtikelStatus.VIA_DERDEN]: <Translation>{t => t("toestel:Labels.via-derden", "Via derden")}</Translation>,
            [ServiceArtikelStatus.DEFECT]: <Translation>{t => t("toestel:Labels.defect", "Defect")}</Translation>,
            [ServiceArtikelStatus.DUBBEL_FOUT]: <Translation>{t => t("toestel:Labels.dubbel-fout", "Dubbel fout")}</Translation>
        },

        serviceStatus: {
            [ServiceStatus.GEANNULEERD]: <Translation>{t => t("toestel:Labels.geannuleerd", "Geannuleerd")}</Translation>,
            [ServiceStatus.INPLANNEN]: <Translation>{t => t("toestel:Labels.inplannen", "Inplannen")}</Translation>,
            [ServiceStatus.OPERATIONEEL]: <Translation>{t => t("toestel:Labels.operationeel", "Operationeel")}</Translation>,
            [ServiceStatus.VERLOPEN]: <Translation>{t => t("toestel:Labels.verlopen", "Verlopen")}</Translation>
        },

        serviceType: {
            [ServiceType.ONDERHOUD]: <Translation>{t => t("toestel:Labels.onderhoud", "Onderhoud")}</Translation>,
        },

        toestelTransactieType: {
            [ToestelTransactieType.KOPEN]: <Translation>{t => t("toestel:Labels.verkoop", "Verkoop")}</Translation>,
            [ToestelTransactieType.HUREN]: <Translation>{t => t("toestel:Labels.huur", "Huur")}</Translation>,
            [ToestelTransactieType.NIET_ZELF_VERKOCHT]: <Translation>{t => t("toestel:Labels.niet-zelf-verkocht", "Niet zelf verkocht")}</Translation>
        },

        partnerSelectie: {
            [PartnerSelectie.GEEN]: <Translation>{t => t("bestelbon:Labels.geen-partner", "Geen partner")}</Translation>,
            [PartnerSelectie.NOG_NIET_BESCHIKBAAR_IN_LIJST]: <Translation>{t => t("bestelbon:Labels.nog-niet-beschikbaar-in-lijst", "Nog niet beschikbaar in lijst")}</Translation>,
            [PartnerSelectie.VASTGELEGD]: <Translation>{t => t("bestelbon:Labels.partner-uit-onderstaande-lijst", "Partner uit onderstaande lijst")}</Translation>,
        },

        afvoerMetSifonAanwezig: {
            [AfvoerMetSifonAanwezig.GEEN_AFVOER_AANWEZIG]: <Translation>{t => t("bestelbon:Labels.geen-afvoer-aanwezig", "Geen afvoer aanwezig")}</Translation>,
            [AfvoerMetSifonAanwezig.AFVOER_AANWEZIG]: <Translation>{t => t("bestelbon:Labels.afvoer-aanwezig", "Afvoer aanwezig")}</Translation>,
            [AfvoerMetSifonAanwezig.AFVOER_EN_SIFON_AANWEZIG]: <Translation>{t => t("bestelbon:Labels.afvoer-en-sifon-aanwezig", "Afvoer en sifon aanwezig")}</Translation>,
        }
    };
}

