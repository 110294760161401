import * as React from "react";
import GenericBadge from "../GenericBadge";
import {PrestatieStatus} from "../../../redux/planning/types";
import Config from "../../../helpers/Config";


interface PrestatieStatusBadgeProps {
    status: PrestatieStatus;
}

const PrestatieStatusBadge: React.FC<PrestatieStatusBadgeProps> = ({status}) => {
    const config = {
        [PrestatieStatus.UIT_TE_VOEREN]: {
            label: Config.labels[PrestatieStatus.UIT_TE_VOEREN],
            color: 'info',
        },
        [PrestatieStatus.ONVOLTOOID]: {
            label: Config.labels[PrestatieStatus.ONVOLTOOID],
            color: 'warning',
        },
        [PrestatieStatus.VOLTOOID]: {
            label: Config.labels[PrestatieStatus.VOLTOOID],
            color: 'success',
            icon: 'mdi-check-circle',
        },
        [PrestatieStatus.GEANNULEERD]: {
            label: Config.labels[PrestatieStatus.GEANNULEERD],
            color: 'light',
            icon: 'mdi-cancel',
        },
    };

    return <GenericBadge value={status}
                         config={config}/>;
};

export default PrestatieStatusBadge;
