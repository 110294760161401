import { buildApi, buildFilteredAndPagedSearchQuery, buildUpdateMutation } from '../support/ApiBuilderUtils';
import { PartnerFilters, PartnerModel, PartnerSelectie } from './types';

export const partnerApi = buildApi((build) => ({
    searchPartners: buildFilteredAndPagedSearchQuery<PartnerModel, PartnerFilters>(
        build,
        'zoeken van partners',
        'Partner',
        () => `/partner`
    ),
    koppelBestelBonPartner: buildUpdateMutation<
        void,
        { id: string; partnerSelectie: PartnerSelectie; partnerLeverancierId?: string }
    >(build, 'koppelen van bestelbon aan partner', 'BestelBon', ({ id }) => `/bestelbon/${id}/koppelPartner`),
}));

export const useSearchPartners = partnerApi.endpoints.searchPartners.useQuery;
export const useKoppelBestelBonPartner = partnerApi.endpoints.koppelBestelBonPartner.useMutation;
