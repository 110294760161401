import React from "react";
import {DagPlanningModel} from "../../../../../redux/planning/types";
import {PlanningOpmerkingCard} from "./PlanningOpmerkingCard";
import {useUpdateDagPlanning2} from "../../../../../redux/planning/api";
import {useTranslation} from "../../../../../helpers/i18nUtils";

export interface PlanningInstructieCardProps {
    dagPlanning: DagPlanningModel;
}

export const PlanningInstructieCard: React.FC<PlanningInstructieCardProps> = (props) => {
    const {dagPlanning} = props;

    const {t} = useTranslation("planning");

    const [updateDagPlanning] = useUpdateDagPlanning2();

    const onSubmit = (opmerking?: string) => {
        updateDagPlanning({id: dagPlanning.id, opmerking});
    };

    return (
        <PlanningOpmerkingCard key={dagPlanning.id} onChangeSubmit={onSubmit} opmerking={dagPlanning.opmerking} emptyLabel={t("Labels.voeg-opmerking-toe", "Voeg een opmerking toe...") as string}/>
    );
};
