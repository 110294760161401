import Config from "./Config";
import {GET, POST} from './api';
import {OpdrachtModel} from "../redux/planning/types";
import {formatDateIso} from "./DateUtils";

const BACKOFFICE_OPDRACHT_API_BASE_URL = Config.BACKOFFICE_BASE_URL + '/api/opdracht';

export default class OpdrachtApi {

    static fetchOpdrachtById(opdrachtId: string): Promise<OpdrachtModel> {

        const uri = BACKOFFICE_OPDRACHT_API_BASE_URL + `/${opdrachtId}`;

        return GET(uri, 'ophalen van opdracht');
    }


    static generateOpdrachtenForOnderhoudInitieelServiceOrdersRequest(serviceOrderDatumVan: Date, serviceOrderDatumTot: Date, postcode: string) {

        const uri = BACKOFFICE_OPDRACHT_API_BASE_URL + `/generateOpdrachtenForOnderhoudenInitieelServiceOrdersCommand`;

        return POST(uri, 'generateOpdrachtenForOnderhoudInitieelServiceOrders', {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                serviceOrderDatumVan: formatDateIso(serviceOrderDatumVan),
                serviceOrderDatumTot: formatDateIso(serviceOrderDatumTot),
                postcode: (postcode || "")
            })
        });
    }

    static generateServiceOrderOpdrachtenFromServiceOrderNrsRequest(serviceOrderNrs: String[]) {

        const uri = BACKOFFICE_OPDRACHT_API_BASE_URL + `/generateServiceOrderOpdrachtenFromServiceOrderNrs`;

        return POST(uri, 'generateServiceOrderOpdrachtenFromServiceOrderNrs', {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                serviceOrderNrs
            })
        });
    }

    static behandelServiceOrderMetDynamicsRequest(serviceOrderBedrijfId: string, serviceOrderPlatformId: string, deleteRegels: boolean) {

        const uri = BACKOFFICE_OPDRACHT_API_BASE_URL + `/behandelServiceOrderOnderhoudMetDynamicsPrestatiesCommand`;

        return POST(uri, 'behandelServiceOrderMetDynamicsRequestRequest', {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({serviceOrderBedrijfId, serviceOrderPlatformId, deleteRegels})
        });
    }

    static herbezoekOnderhoudServiceOrderRequest(serviceOrderBedrijfId: string, serviceOrderPlatformId: string, opmerking: string) {

        const uri = BACKOFFICE_OPDRACHT_API_BASE_URL + `/herbezoekOnderhoudServiceOrderCommand`;

        return POST(uri, 'herbezoekOnderhoudServiceOrderRequest', {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({serviceOrderBedrijfId, serviceOrderPlatformId, opmerking})
        });
    }

}
