import React from "react";
import Select from "react-select";

export interface SelectInputProps<T> {
    name: string;

    inputValue?: string | null;
    value?: T | null;
    defaultValue?: T | null;
    placeholder?: string;

    className?: string;

    isValid?: boolean;
    isInvalid?: boolean;

    loading?: boolean;

    options?: T[];

    noOptionsMessage?: () => string;

    onBlur?(): void;
    onInputChange?(value?: string | null, action?: string): void;
    onChange?(value?: T | null): void;

    getOptionLabel?(value: T): string;
    getOptionValue?(value: T): any;
}

export const SelectInput: React.FC<SelectInputProps<any>> = <T, >(props: SelectInputProps<T>) => {
    const {
        name, defaultValue, placeholder,
        inputValue, value, className,
        isValid, isInvalid, onBlur,
        onChange, onInputChange, loading,
        options, noOptionsMessage, getOptionLabel,
        getOptionValue
    } = props;

    return (
        <Select
            styles={{
                container: provided => ({
                    ...provided,
                    minWidth: 300
                })
            }}
            onSelectResetsInput
            isValid={isValid}
            isInvalid={isInvalid}
            inputId={name}
            name={name}
            onBlur={onBlur}
            className={"form-control-light" + className ? ` ${className}` : ""}
            classNamePrefix="react-select"
            defaultValue={defaultValue}
            value={value}
            loading={loading}
            options={options}
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={onInputChange}
            placeholder={placeholder}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}

            noOptionsMessage={noOptionsMessage}
        />
    );
};
