import * as React from "react";
import InstallatieVereiste from "./InstallatieVereiste";
import {SimpleFormField} from "../../../components/aqualex/form/SimpleFormField";


interface ToestellenProps {
    guard: boolean;
    label: string;
    waardePropertyName: string;
    waarde?: number;
    opmerkingPropertyName: string;
    opmerking?: string;
};

const IntegerInstallatieVereiste: React.FC<ToestellenProps> = ({
                                                                   guard,
                                                                   label,
                                                                   waardePropertyName,
                                                                   waarde,
                                                                   opmerkingPropertyName,
                                                                   opmerking
                                                               }) => {

    return (
        <InstallatieVereiste guard={guard}
                             label={label}
                             waardePropertyName={waardePropertyName}
                             waarde={waarde}
                             opmerkingPropertyName={opmerkingPropertyName}
                             opmerking={opmerking}
                             waardeRenderer={(waarde) => {
                                 return <SimpleFormField name={waardePropertyName}
                                                         type='number'/>
                             }}/>
    )
};

export default IntegerInstallatieVereiste;
