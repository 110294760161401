import {AnyAction, Reducer} from "redux";

export const createHandlerBasedReducer = handlers => (state, action) => {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
};

export const reducerForActionType = <S = any, A = AnyAction>(actionType: { REQUEST: string, SUCCESS: string, FAILURE: string }, onSuccess?: (state: S, action: A) => any) => {
    const handlers = {
        [actionType.REQUEST]: (state, action) => ({...state, error: undefined}),
        [actionType.SUCCESS]: (state, action) => {
            if (!onSuccess) {
                return {...state, error: undefined};
            }

            return {
                ...state,
                ...onSuccess(state, action),
                error: undefined,
            };
        },
        [actionType.FAILURE]: (state, action) => ({...state, error: action.payload}),
    };

    return createHandlerBasedReducer(handlers);
};

export const combineHandlerBasedReducers = <S>(initialState: S, reducers: Array<Reducer<S, any>>): Reducer<S, any> => {
    return (state = initialState, action) => {
        return reducers.reduce((acc, reducer) => {
            return reducer(acc, action)
        }, {...state});
    };
};
