import * as React from "react";
import {Icons} from "../../../constants/icons";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface GecommuniceerdIconProps {
    gecommuniceerdeDatums: string[];
    gecommuniceerdOpTijdstip?: string;

    geplandeDatum: string;
}

const GecommuniceerdIcon: React.FC<GecommuniceerdIconProps> = (props) => {
    const {gecommuniceerdeDatums, gecommuniceerdOpTijdstip, geplandeDatum} = props;

    const {t} = useTranslation("planning");

    const isGecommuniceerd = !!gecommuniceerdOpTijdstip;
    const isGecommuniceerdOpDatum = gecommuniceerdeDatums?.includes(geplandeDatum);
    const isGecommuniceerdOpAndereDatum = isGecommuniceerd && !gecommuniceerdeDatums?.includes(geplandeDatum);

    const gecommuniceerdeDatumsString = gecommuniceerdeDatums?.join(", ");

    if (isGecommuniceerdOpAndereDatum) {
        return <i className={Icons.GecommuniceerdOpAndereDatum}
                  title={t("GecommuniceerdIcon.gecommuniceerd-op-andere-datum", {defaultValue_one: "Gecommuniceerde datum is {{gecommuniceerdeDatumsString}}", defaultValue_other: "Gecommuniceerde datums zijn {{gecommuniceerdeDatumsString}}", count: gecommuniceerdeDatums.length, gecommuniceerdeDatumsString}) as string}/>
    } else if (isGecommuniceerdOpDatum) {
        return <i className={Icons.GecommuniceerdOpJuisteDatum}
                  title={t("GecommuniceerdIcon.gecommuniceerd-op-tijdstip", "Gecommuniceerd op {{gecommuniceerdOpTijdstip}}", {gecommuniceerdOpTijdstip}) as string}/>
    } else {
        return <i className={Icons.NietGecommuniceerd}
                  title={t("GecommuniceerdIcon.niet-gecommuniceerd", "Niet gecommuniceerd") as string}/>
    }

}

export default GecommuniceerdIcon;
