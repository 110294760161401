import {buildApi, buildGetByIdQuery, buildMutation, buildQuery, buildUpdateMutation} from "../support/ApiBuilderUtils";
import {
    BezoekenPerMedewerkerPerDagProjectionModel,
    DagPlanningEntryModel,
    DagPlanningEntryType,
    DagPlanningModel,
    DagPlanningOmwegBerekeningModel,
    DagPlanningOverzichtModel,
    DagPlanningVoegBezoekToeForm,
    GeplandBezoekModel,
    UpdateDagPlanningAutomatischAanvullenForm,
    UpdateDagPlanningForm,
    VoegDagPlanningenToeRequest,
    WijzigDuurtijdBezoekForm
} from "./types";
import {formatDateIso} from "../../helpers/DateUtils";
import {DagPlanningAlignerenFormValues} from "../../pages/planning/dagplanning/detail/DagPlanningAlignerenForm";
import * as uuid from "uuid";
import {TechnicusProfiel} from "../medewerker/types";
import {installTranslationKey} from "../../helpers/i18nUtils";

export const planningApi = buildApi(build => ({
    getDagPlanningById: buildGetByIdQuery<DagPlanningModel, string>(build, "ophalen dagplanning", "DagPlanning", id => `/dagplanning/${id}`),
    getDagPlanningByMedewerkerIdAndDatum: buildQuery<DagPlanningModel, { medewerkerId: string; datum: string | Date }>(build,
        installTranslationKey("planning:APIFoutmeldingen.ophalen-dagplanning", "ophalen dagplanning"),
        "DagPlanning",
        ({medewerkerId, datum}) => `/medewerker/${medewerkerId}/dagplanning/${formatDateIso(datum)}`,
        {
            providesTags: (result) => {
                if (!result) {
                    return [];
                }

                return [{type: "DagPlanning", id: result.id}];
            }
        },
        {
            validateStatus: (response) => [200, 404].includes(response.status),
            responseHandler: (response) => {
                if (response.status === 404) {
                    return undefined;
                }

                return response.json();
            }
        }
    ),

    getDagPlanningenByDatum: buildQuery<DagPlanningModel[], string>(build,
        installTranslationKey("planning:APIFoutmeldingen.ophalen-van-dagplanningen-op-datum", "ophalen van dagplanningen op datum"),
        "DagPlanning",
        datum => `/dagplanning?vanaf=${datum}&tot=${datum}`,
        {
            providesTags: (result, error, id) => result?.map(dagPlanning => ({
                type: "DagPlanning",
                id: dagPlanning.id
            })) || ["DagPlanning"]
        }
    ),

    getDagPlanningenByDatumEnTechnicusProfielen: buildQuery<DagPlanningModel[], { datum: string, technicusProfielen: TechnicusProfiel[] }>(build,
        installTranslationKey("planning:APIFoutmeldingen.ophalen-van-dagplanningen-op-datum", "ophalen van dagplanningen op datum"),
        "DagPlanning",
        args => "/dagplanning",
        {
            providesTags: (result, error, id) => result?.map(dagPlanning => ({
                type: "DagPlanning",
                id: dagPlanning.id
            })) || ["DagPlanning"]
        },
        {},
        ({datum, technicusProfielen}) => ({
            vanaf: datum,
            tot: datum,
            technicusProfiel: technicusProfielen,
        })
    ),

    getDagPlanningOverzichtenByDatum: buildQuery<DagPlanningOverzichtModel[], string>(build,
        installTranslationKey("planning:APIFoutmeldingen.ophalen-van-dagplanningen-op-datum", "ophalen van dagplanningen op datum"),
        "DagPlanningOverzicht",
        datum => `/dagplanning/overzicht?vanaf=${datum}&tot=${datum}`,
        {
            providesTags: (result, error, id) => ["DagPlanning", "DagPlanningOverzicht", ...(result?.map(dagPlanning => ({
                type: "DagPlanningOverzicht",
                id: dagPlanning.id
            }))|| [])]
        }
    ),

    getDagPlanningen: build.query<DagPlanningModel[], { medewerkerId?: string, vanaf?: Date, tot?: Date }>({
        query: ({medewerkerId, vanaf, tot}) => ({
            url: "/dagplanning",
            params: {
                medewerkerId,
                ...vanaf && {vanaf: formatDateIso(vanaf)},
                ...tot && {tot: formatDateIso(tot)},
            },
        }),
        extraOptions: {
            omschrijving: installTranslationKey("planning:APIFoutmeldingen.ophalen-van-dagplanningen", "ophalen van dagplanningen")
        },
        providesTags: (result, error, {medewerkerId, vanaf, tot}) =>
            result
                ? [
                    // Provides a tag for each post in the current page,
                    // as well as the 'PARTIAL-LIST' tag.
                    ...result.map(({id}) => ({type: 'DagPlanning' as const, id})),
                    // {
                    //     type: 'DagPlanning' as const,
                    //     id: `MEDEWERKER-${medewerkerId}-${formatDate(vanaf)}-${formatDate(tot)}`
                    // },
                ]
                : [
                    //     {
                    //     type: 'DagPlanning' as const,
                    //     id: `MEDEWERKER-${medewerkerId}-${formatDate(vanaf)}-${formatDate(tot)}`
                    // }
                ],
    }),

    getDagPlanningOverzichten: build.query<DagPlanningOverzichtModel[], { medewerkerId?: string, vanaf?: Date, tot?: Date }>({
        query: ({medewerkerId, vanaf, tot}) => ({
            url: "/dagplanning/overzicht",
            params: {
                medewerkerId,
                ...vanaf && {vanaf: formatDateIso(vanaf)},
                ...tot && {tot: formatDateIso(tot)},
            },
        }),
        extraOptions: {
            omschrijving: "ophalen van dagplanningen"
        },
        providesTags: (result, error, {medewerkerId, vanaf, tot}) =>
                [
                    "DagPlanning",
                    "DagPlanningOverzicht",
                    ...(result?.map(({id}) => ({type: 'DagPlanningOverzicht' as const, id})) || [])
                ]
    }),

    getDagPlanningBezoeken: buildQuery<GeplandBezoekModel[], { dagPlanningId: string }>(build,
        installTranslationKey("planning:APIFoutmeldingen.ophalen-dagplanningbezoeken", "ophalen dagplanningbezoeken"),
        "GeplandBezoek",
        ({dagPlanningId}) => `/dagplanning/${dagPlanningId}/bezoeken`,
        {
            providesTags: (result, meta, arg) => {
                if (!result) {
                    return [];
                }

                const geplandeBezoekenTags = result.map(item => ({type: "GeplandBezoek", id: item.id}));

                return [{type: "DagPlanning", id: arg.dagPlanningId}, ...geplandeBezoekenTags];
            }
        }
    ),
    getDagPlanningBezoekenPerDag: buildQuery<BezoekenPerMedewerkerPerDagProjectionModel[], { van: string; tot: string; }>(build,
        installTranslationKey("planning:APIFoutmeldingen.ophalen-dagplanningbezoeken", "ophalen dagplanningbezoeken"),
        "GeplandBezoek",
        () => `/bezoek/gepland/perMedewerkerPerDag`,
        {
            providesTags: (result) => {
                const tags = result?.map(dagPlanning => ({type: "DagPlanning", id: dagPlanning.dagPlanningId})) || [];

                return ["DagPlanning", ...tags];
            }
        }
    ),
    getDagPlanningOmwegBerekening: buildQuery<DagPlanningOmwegBerekeningModel[], {
        dagPlanningId: string,
        bezoekAanvraagId: string
    }>(build,
        installTranslationKey("planning:APIFoutmeldingen.ophalen-van-omwegberekening", "ophalen van omwegberekening"), "OmwegBerekening", ({
                                                                                                                                               dagPlanningId,
                                                                                                                                               bezoekAanvraagId
                                                                                                                                           }) => `/dagplanning/${dagPlanningId}/bezoekaanvraag/${bezoekAanvraagId}/omweg`),

    voegDagPlanningenToe: buildMutation<void, VoegDagPlanningenToeRequest>(build,
        installTranslationKey("planning:APIFoutmeldingen.dagplanning-toevoegen", "dagplanning toevoegen"), "DagPlanning", () => `/dagplanning`, {invalidatesTags: ["DagPlanningOverzicht"]}),
    updateDagPlanning: buildUpdateMutation<void, {
        id: string
    } & Partial<UpdateDagPlanningForm>>(build, "dagplanning bijwerken", "DagPlanning", ({id}) => `/dagplanning/${id}`, {
        invalidatesTags: (result, error, {id}) => [
            "DagPlanning",
            "DagPlanningOverzicht",
            {type: "DagPlanning", id},
            {type: "DagPlanningOverzicht", id}
        ]
    }, {method: "PATCH"}),
    updateDagPlanningAutomatischAanvullen: buildUpdateMutation<void, {
        id: string
    } & UpdateDagPlanningAutomatischAanvullenForm>(build,
        installTranslationKey("planning:APIFoutmeldingen.automatisch-aanvullen-wijzigen", "automatisch aanvullen wijzigen"),
        "DagPlanning",
        ({id}) => `/dagplanning/${id}/automatischAanvullen`,
        {
            async onQueryStarted({id, automatischAanvullenNaBezoekIndex}, {
                getState,
                getCacheEntry,
                dispatch,
                queryFulfilled
            }) {
                const queryName = getState().baseApi.provided.DagPlanning[id][0];
                const dagPlanningQueryResult = getState().baseApi.queries[queryName];
                const dagPlanning = dagPlanningQueryResult?.data as DagPlanningModel;

                if (!dagPlanning) {
                    return;
                }

                const medewerkerId = dagPlanning.medewerker.id;
                const datum = dagPlanning.datum;

                const key = {medewerkerId, datum};
                const patchResult = dispatch(
                    planningApi.util.updateQueryData("getDagPlanningByMedewerkerIdAndDatum", key, (draft) => {
                        Object.assign(draft, {
                            automatischAanvullen: {
                                automatischAanvullen: dagPlanning.automatischAanvullen?.automatischAanvullen || false,
                                automatischAanvullenNaBezoekIndex: automatischAanvullenNaBezoekIndex ?? dagPlanning.automatischAanvullen?.automatischAanvullenNaBezoekIndex
                            }
                        });
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            }
        }
    ),
    pasAutomatischAanvullenVoorstellenToe: buildUpdateMutation<void, { id: string }>(build,
        installTranslationKey("planning:APIFoutmeldingen.voorstellen-toepassen", "voorstellen toepassen"), "DagPlanning", ({id}) => `/dagplanning/${id}/automatischAanvullen/toepassen`, {
        invalidatesTags: ["GeplandBezoek", "DagPlanningOverzicht"]
    }),
    verwijderAutomatischAanvullenVoorstellen: buildUpdateMutation<void, { id: string }>(build,
        installTranslationKey("planning:APIFoutmeldingen.voorstellen-verwijderen", "voorstellen verwijderen"), "DagPlanning", ({id}) => `/dagplanning/${id}/automatischAanvullen/verwijderen`, {
        invalidatesTags: ["GeplandBezoek", "DagPlanningOverzicht"]
    }),
    aligneerDagPlanning: buildUpdateMutation<void, { id: string } & DagPlanningAlignerenFormValues>(build,
        installTranslationKey("planning:APIFoutmeldingen.dagplanning-aligneren", "dagplanning aligneren"), "DagPlanning", ({id}) => `/dagplanning/${id}/aligneren`, {
        invalidatesTags: ["GeplandBezoek", "DagPlanningOverzicht"]
    }),
    wijzigDuurtijdBezoek: buildUpdateMutation<void, { id: string; geplandBezoekId: string; } & WijzigDuurtijdBezoekForm>(
        build,
        installTranslationKey("planning:APIFoutmeldingen.duurtijd-van-bezoek-wijzigen", "duurtijd van bezoek wijzigen"),
        "DagPlanning",
        ({id, geplandBezoekId}) => `/dagplanning/${id}/bezoeken/${geplandBezoekId}/duurtijd`,
        {
            invalidatesTags: ["GeplandBezoek", "BezoekAanvraag", "DagPlanningOverzicht"]
        }
    ),
    breidDuurtijdBezoekUit: buildUpdateMutation<void, { id: string; geplandBezoekId: string; }>(
        build,
        installTranslationKey("planning:APIFoutmeldingen.duurtijd-van-bezoek-wijzigen", "duurtijd van bezoek wijzigen"),
        "DagPlanning",
        ({id, geplandBezoekId}) => `/dagplanning/${id}/bezoeken/${geplandBezoekId}/duurtijdUitbreiden`,
        {
            invalidatesTags: ["GeplandBezoek", "BezoekAanvraag", "DagPlanningOverzicht"]
        }
    ),

    voegBezoekToeAanDagPlanning: buildUpdateMutation<void, { id: string } & DagPlanningVoegBezoekToeForm>(build,
        installTranslationKey("planning:APIFoutmeldingen.bezoek-toevoegen-aan-dagplanning", "bezoek toevoegen aan dagplanning"),
        "DagPlanning",
        ({id}) => `/dagplanning/${id}/bezoeken`,
        {
            invalidatesTags: (result, meta, arg) => {
                return [
                    "GeplandBezoek",
                    "DagPlanningOverzicht",
                    {type: "DagPlanning", id: arg.id},
                    {type: "BezoekAanvraag", id: "PARTIAL-LIST"},
                    {type: "BezoekAanvraag", id: arg.bezoekAanvraagId},
                ];
            }
        }
    ),
    verwijderBezoekVanDagPlanning: buildUpdateMutation<void, { id: string; geplandBezoekId: string }>(build,
        installTranslationKey("planning:APIFoutmeldingen.bezoek-verwijderen-van-dagplanning", "bezoek verwijderen van dagplanning"),
        "DagPlanning",
        ({id, geplandBezoekId}) => `/dagplanning/${id}/bezoeken/${geplandBezoekId}`,
        {
            invalidatesTags: ["GeplandBezoek", "BezoekAanvraag", "DagPlanningOverzicht"],

            async onQueryStarted({id, geplandBezoekId}, {getState, dispatch, queryFulfilled}) {
                const queryName = getState().baseApi.provided.DagPlanning[id][0];
                const dagPlanningQueryResult = getState().baseApi.queries[queryName];
                const dagPlanning = dagPlanningQueryResult?.data as DagPlanningModel;

                if (!dagPlanning) {
                    return;
                }

                const medewerkerId = dagPlanning.medewerker.id;
                const datum = dagPlanning.datum;

                const nextEntries = dagPlanning.entries
                    .filter(item => item.geplandBezoekId !== geplandBezoekId)
                    .filter((item, i, arr) => !(item?.type === DagPlanningEntryType.RIT && arr[i + 1]?.type === DagPlanningEntryType.RIT));

                const patchResult = dispatch(
                    planningApi.util.updateQueryData("getDagPlanningByMedewerkerIdAndDatum", {
                        medewerkerId,
                        datum
                    }, (draft) => {
                        Object.assign(draft, {
                            entries: nextEntries
                        });
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            }
        },
        {
            method: "DELETE"
        }
    ),
    verplaatsBezoekVanDagPlanning: buildUpdateMutation<void, { id: string; geplandBezoekId: string; bezoekIndex: number; onderAutomatischAanvullenMarker: boolean; }>(build,
        installTranslationKey("planning:APIFoutmeldingen.bezoek-verplaatsen-van-dagplanning", "bezoek verplaatsen van dagplanning"),
        "DagPlanning",
        ({id, geplandBezoekId}) => `/dagplanning/${id}/bezoeken/${geplandBezoekId}/verplaats`,
        {
            invalidatesTags: ["GeplandBezoek", "DagPlanningOverzicht"],

            async onQueryStarted({id, geplandBezoekId, bezoekIndex}, {
                getState,
                dispatch,
                queryFulfilled
            }) {
                const queryName = getState().baseApi.provided.DagPlanning[id][0];
                const dagPlanningQueryResult = getState().baseApi.queries[queryName];
                const dagPlanning = dagPlanningQueryResult?.data as DagPlanningModel;

                if (!dagPlanning) {
                    return;
                }

                const medewerkerId = dagPlanning.medewerker.id;
                const datum = dagPlanning.datum;

                const prevEntries = dagPlanning.entries;
                if (!prevEntries) {
                    return;
                }

                const oudeIndex = prevEntries.findIndex(item => item.geplandBezoekId === geplandBezoekId);
                if (oudeIndex === -1) {
                    return;
                }

                const nieuwBezoek = prevEntries.filter(item => item.type === DagPlanningEntryType.BEZOEK)[bezoekIndex];
                if (!nieuwBezoek) {
                    return;
                }

                const nieuweArrayIndex = prevEntries.findIndex(item => item.id === nieuwBezoek.id);
                if (nieuweArrayIndex === -1) {
                    return;
                }

                let nextEntries = prevEntries.concat();

                // Vervang bezoek
                const item = nextEntries[oudeIndex];

                nextEntries.splice(oudeIndex, 1);

                const createPlaceholder = (): DagPlanningEntryModel => ({
                    type: DagPlanningEntryType.RIT,
                    id: uuid.v4(),
                    van: "",
                    tot: ""
                });

                // Oude item invoegen met twee ritten eromheen, dubbele worden achteraf verwijderd
                const insertItems: DagPlanningEntryModel[] = [
                    createPlaceholder(),
                    item,
                    createPlaceholder()
                ];

                nextEntries.splice(
                    nieuweArrayIndex,
                    0,
                    ...insertItems
                );

                // Dubbele ritten verwijderen
                nextEntries = nextEntries.filter((item, i, arr) => !(item?.type === DagPlanningEntryType.RIT && arr[i + 1]?.type === DagPlanningEntryType.RIT));

                const key = {
                    medewerkerId,
                    datum
                };
                const patchResult = dispatch(
                    planningApi.util.updateQueryData("getDagPlanningByMedewerkerIdAndDatum", key, (draft) => {
                        Object.assign(draft, {
                            entries: nextEntries
                        });
                    })
                );

                try {
                    const result = await queryFulfilled;

                    dispatch(
                        planningApi.util.updateQueryData("getDagPlanningByMedewerkerIdAndDatum", key, (draft) => {
                            Object.assign(draft, result.data);
                        })
                    );
                } catch {
                    patchResult.undo();
                }
            }
        }
    ),

    communiceerDagPlanning: buildUpdateMutation<void, { id: string }>(build, "dagplanning communiceren", "DagPlanning", ({id}) => `/dagplanning/${id}/communiceer`, {
        invalidatesTags: ["GeplandBezoek", "BezoekAanvraag", "DagPlanningOverzicht"]
    }),
    legDagPlanningVast: buildUpdateMutation<void, { id: string }>(build, "dagplanning vastleggen", "DagPlanning", ({id}) => `/dagplanning/${id}/vastleggen`, {invalidatesTags: ["DagPlanning", "DagPlanningOverzicht"]}),
    annuleerDagPlanning: buildUpdateMutation<void, { id: string }>(build, "dagplanning annuleren", "DagPlanning", ({id}) => `/dagplanning/${id}/annuleer`, {invalidatesTags: ["DagPlanning", "DagPlanningOverzicht"]}),
    heropenDagPlanning: buildUpdateMutation<void, { id: string }>(build, "dagplanning heropenen", "DagPlanning", ({id}) => `/dagplanning/${id}/heropen`, {invalidatesTags: ["DagPlanning", "DagPlanningOverzicht"]})
}));

export const useGetDagPlanningById = planningApi.endpoints.getDagPlanningById.useQuery;
export const useGetDagPlanningenByDatum = planningApi.endpoints.getDagPlanningenByDatum.useQuery;
export const useGetDagPlanningenByDatumEnTechnicusProfielen = planningApi.endpoints.getDagPlanningenByDatumEnTechnicusProfielen.useQuery;
export const useGetDagPlanningOverzichtenByDatum = planningApi.endpoints.getDagPlanningOverzichtenByDatum.useQuery;
export const useGetDagPlanningByMedewerkerIdAndDatum = planningApi.endpoints.getDagPlanningByMedewerkerIdAndDatum.useQuery;
export const useGetDagPlanningBezoekenPerDag = planningApi.endpoints.getDagPlanningBezoekenPerDag.useQuery;
export const useGetDagPlanningen = planningApi.endpoints.getDagPlanningen.useQuery;
export const useGetDagPlanningOverzichten = planningApi.endpoints.getDagPlanningOverzichten.useQuery;
export const useGetDagPlanningOmwegBerekening = planningApi.endpoints.getDagPlanningOmwegBerekening.useQuery;

export const useVoegDagPlanningenToe2 = planningApi.endpoints.voegDagPlanningenToe.useMutation;
export const useUpdateDagPlanning2 = planningApi.endpoints.updateDagPlanning.useMutation;
export const useUpdateDagPlanningAutomatischAanvullen2 = planningApi.endpoints.updateDagPlanningAutomatischAanvullen.useMutation;
export const usePasAutomatischAanvullenVoorstellenToe2 = planningApi.endpoints.pasAutomatischAanvullenVoorstellenToe.useMutation;
export const useVerwijderAutomatischAanvullenVoorstellen2 = planningApi.endpoints.verwijderAutomatischAanvullenVoorstellen.useMutation;
export const useAligneerDagPlanning2 = planningApi.endpoints.aligneerDagPlanning.useMutation;
export const useWijzigDuurtijdBezoek = planningApi.endpoints.wijzigDuurtijdBezoek.useMutation;
export const useBreidDuurtijdBezoekUit = planningApi.endpoints.breidDuurtijdBezoekUit.useMutation;

export const useVoegBezoekToeAanDagPlanning = planningApi.endpoints.voegBezoekToeAanDagPlanning.useMutation;
export const useVerwijderBezoekVanDagPlanning = planningApi.endpoints.verwijderBezoekVanDagPlanning.useMutation;
export const useVerplaatsBezoekVanDagPlanning = planningApi.endpoints.verplaatsBezoekVanDagPlanning.useMutation;

export const useCommuniceerDagPlanning = planningApi.endpoints.communiceerDagPlanning.useMutation;
export const useLegDagPlanningVast = planningApi.endpoints.legDagPlanningVast.useMutation;
export const useAnnuleerDagPlanning = planningApi.endpoints.annuleerDagPlanning.useMutation;
export const useHeropenDagPlanning = planningApi.endpoints.heropenDagPlanning.useMutation;
