import * as React from "react";
import {OperationeelToestelModel, OperationeelToestelStatus} from "../../../redux/toestel/types";
import {useTranslation} from "../../../helpers/i18nUtils";

interface UitDienstIndicatorProps {
    toestel: OperationeelToestelModel;
}

const UitDienstIndicator: React.FC<UitDienstIndicatorProps> = ({toestel}) => {
    const {t} = useTranslation("toestel");

    if (toestel.status !== OperationeelToestelStatus.UIT_DIENST) {
        return null;
    }

    return (
        <span className="text-danger"><i
            className="mdi mdi-cancel"/>&nbsp;{t("UitDienstIndicator.uit-dienst", "Uit dienst")}</span>
    );
};

export default UitDienstIndicator;
