import * as React from "react";
import GenericBadge from "../GenericBadge";
import {ToestelOnderhoudType} from "../../../redux/planning/types";
import Config from "../../../helpers/Config";


interface ToestelOnderhoudTypeBadgeProps {
    toestelOnderhoudType: ToestelOnderhoudType;
}

const ToestelOnderhoudTypeBadge: React.FC<ToestelOnderhoudTypeBadgeProps> = ({toestelOnderhoudType}) => {
    const config = {
        [ToestelOnderhoudType.REINIGEN]: {
            label: Config.labels.toestelOnderhoudType[ToestelOnderhoudType.REINIGEN],
            color: 'info',
        },
        [ToestelOnderhoudType.REINIGEN_FILTER_VERVANGEN]: {
            label: Config.labels.toestelOnderhoudType[ToestelOnderhoudType.REINIGEN_FILTER_VERVANGEN],
            color: 'warning',
        },
        [ToestelOnderhoudType.REINIGEN_MET_UV]: {
            label: Config.labels.toestelOnderhoudType[ToestelOnderhoudType.REINIGEN_MET_UV],
            color: 'primary',
        },
        [ToestelOnderhoudType.REINIGEN_MET_UV_FILTER_VERVANGEN]: {
            label: Config.labels.toestelOnderhoudType[ToestelOnderhoudType.REINIGEN_MET_UV_FILTER_VERVANGEN],
            color: 'primary',
        },
    };

    return <GenericBadge value={toestelOnderhoudType}
                         config={config}/>;
};

export default ToestelOnderhoudTypeBadge;
