export interface PartnerModel {
    id: string;
    bedrijfId: string;
    nr: string;
    naam: string;
    adres: string; // TODO: vervangen met adres-model
    verwijderd: boolean;
}

export interface PartnerFilters {
    naamOfNummer?: string;
}

export enum PartnerSelectie {
    GEEN = "GEEN",
    NOG_NIET_BESCHIKBAAR_IN_LIJST = "NOG_NIET_BESCHIKBAAR_IN_LIJST",
    VASTGELEGD = "VASTGELEGD"
}
