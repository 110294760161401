import Config from "./Config";
import {GET, POST} from './api';
import {PrestatieModel} from "../redux/planning/types";

const BACKOFFICE_PRESTATIE_API_BASE_URL = Config.BACKOFFICE_BASE_URL + '/api/prestatie';

export default class PrestatieApi {

    static fetchPrestatiesByOpdrachtId(opdrachtId: string): Promise<Array<PrestatieModel>> {

        const uri = Config.BACKOFFICE_BASE_URL + `/api/opdracht/${opdrachtId}/prestaties`;

        return GET(uri, 'ophalen van prestaties van opdracht');
    }

    static fetchPrestatieVerwachteDuurtijdProjection(van: string, tot: string) {
        const uri = Config.BACKOFFICE_BASE_URL + '/api/prestaties/capaciteit';

        const params = new URLSearchParams();
        params.append('van', van)
        params.append('tot', tot)

        return GET(uri + `?${params.toString()}`, 'ophalen van prestatie capaciteit');
    }

    static plan(prestatieId: string, bezoekAanvraagId?: string) {
        const uri = BACKOFFICE_PRESTATIE_API_BASE_URL + `/${prestatieId}/plan`;

        const body = {
            bezoekAanvraagId
        };

        return POST(uri, `plannen van prestatie ${prestatieId}`, {
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });
    }
}
