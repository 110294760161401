import * as React from "react";
import ConnectedComponentSpinner from "./ConnectedComponentSpinner";
import {Link} from 'react-router-dom';
import {MedewerkerModel} from "../../../redux/medewerker/types";
import {ProfielfotoAvatar} from "../ProfielfotoAvatar";
import {useGetMedewerkerById} from "../../../redux/medewerker/api";
import {skipToken} from "@reduxjs/toolkit/query";
import {useTranslation} from "../../../helpers/i18nUtils";

interface MedewerkerProps {
    medewerkerId?: string;
    medewerker?: MedewerkerModel;
    clickable: boolean;

    showProfileImage?: boolean;
}

const Medewerker: React.FC<MedewerkerProps> = (props) => {
    const {clickable, showProfileImage = true} = props;

    const {t} = useTranslation();

    const {data} = useGetMedewerkerById(props.medewerkerId || skipToken)

    const medewerker = props.medewerker || data;
    const medewerkerId = props.medewerkerId || medewerker?.id;

    if (medewerker) {
        const profielfoto = showProfileImage &&
            <ProfielfotoAvatar className="mr-1" size="xs" src={medewerker.profielfotoUrl} rounded/>;

        if (clickable) {
            return <Link to={`/medewerker/${medewerkerId}`}>{profielfoto} {medewerker.naam}</Link>;
        } else {
            return <span>{profielfoto} {medewerker.naam}</span>
        }

    } else if (medewerkerId) {
        return <ConnectedComponentSpinner entity="Medewerker" id={medewerkerId}/>
    } else {
        return <span className='text-muted'>{t("Labels.onbekend", "Onbekend")}</span>
    }

};

export default Medewerker;
