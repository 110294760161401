import React, {ReactNode} from "react";
import {Col, Row} from "react-bootstrap";
import {Co2ArtikelBox, Co2ArtikelBoxProps} from "./Co2ArtikelBox";
import {ArtikelModel} from "../../../redux/artikel/types";
import {KlantModel} from "../../../redux/klant/types";
import {VerzendAdresModel} from "../../../redux/verzendadres/types";
import {ColProps} from "react-bootstrap/Col";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface Co2ArtikelsProps<T extends ArtikelModel> {
    co2Artikelen: T[];
    clickable?: boolean;

    renderItem?: (item: ReactNode, artikel: T) => ReactNode;

    klantVoorBestelling?: KlantModel;
    verzendAdresVoorBestelling?: VerzendAdresModel;

    boxProps?: Partial<Co2ArtikelBoxProps>;
    colProps?: ColProps;
}

export const Co2Artikels = <T extends ArtikelModel>(props: Co2ArtikelsProps<T>) => {
    const {
        co2Artikelen,
        clickable = true,
        klantVoorBestelling,
        verzendAdresVoorBestelling,
        boxProps,
        colProps,

        renderItem = (item) => item
    } = props;

    const {t} = useTranslation("algemeen");

    return (
        <>
            {!co2Artikelen.length && <p className="text-muted">{t("Labels.geen", "Geen")}</p>}
            <Row>
                {co2Artikelen.map(item => (
                    <Col xs={6} md={4} {...colProps}>
                        {renderItem(<Co2ArtikelBox {...boxProps} clickable={clickable} artikel={item} klantVoorBestelling={klantVoorBestelling} verzendAdresVoorBestelling={verzendAdresVoorBestelling}/>, item)}
                    </Col>
                ))}
            </Row>
        </>
    );
};
