import React, {useState} from "react";
import {Alert, Col, Collapse, Row} from "react-bootstrap";
import {KlantModel} from "../../../../redux/klant/types";
import {Page} from "../../../../redux/support/pagination/types";
import {useTranslation} from "../../../../helpers/i18nUtils";

export interface CreateKlantModalSimilarKlantenProps {
    similarKlantNaam: Page<KlantModel>;
    similarKlantEmailadres: Page<KlantModel>;
    similarKlantBtwNummer: Page<KlantModel>;
}

export const CreateKlantModalSimilarKlanten: React.FC<CreateKlantModalSimilarKlantenProps> = (props) => {
    const {similarKlantNaam, similarKlantEmailadres, similarKlantBtwNummer} = props;

    const [isSimilarKlantenAccordionOpen, setIsSimilarKlantenAccordionOpen] = useState(false);

    const similarKlantenFound = similarKlantNaam.totalElements + similarKlantEmailadres.totalElements + similarKlantBtwNummer.totalElements > 0;

    const {t} = useTranslation("klant");

    if (!similarKlantenFound) {
        return null;
    }

    return (
        <Alert variant="warning">
            <h4>
                <i className="mdi mdi-alert mdi-24px mr-2"/>
                {t("Foutmeldingen-er-zijn-gelijkaardige-klanten-gevonden", "Er zijn gelijkaardige klanten gevonden.")}
            </h4>

            <p onClick={() => setIsSimilarKlantenAccordionOpen(!isSimilarKlantenAccordionOpen)}
               className="cursor-pointer">
                {isSimilarKlantenAccordionOpen ? <i className="mdi mdi-16px mdi-chevron-down"></i> :
                    <i className="mdi mdi-16px mdi-chevron-right"></i>}
                {t("Labels.meer-informatie", "Meer informatie")}
            </p>
            <Collapse in={isSimilarKlantenAccordionOpen}>
                <Row>
                    {similarKlantNaam.totalElements > 0 && (
                        <Col sm={4}>
                            <p>
                                {t("CreateKlantModalSimilarKlanten.gelijkaardige-klanten-op-basis-van-de-naam", "Op basis van de naam:")}
                            </p>
                            <ul>
                                {similarKlantNaam.content.map((item) => <li key={item.id}>{item.nr} - {item.naam}</li>)}
                            </ul>
                        </Col>
                    )}

                    {similarKlantEmailadres.totalElements > 0 && (
                        <Col sm={4}>
                            <p>
                                {t("CreateKlantModalSimilarKlanten.gelijkaardige-klanten-op-basis-van-het-e-mail-adres",
                                    "Op basis van het e-mailadres:")}
                            </p>
                            <ul>
                                {similarKlantEmailadres.content.map((item) =>
                                    <li key={item.id}>{item.nr} - {item.naam}</li>)}
                            </ul>
                        </Col>
                    )}

                    {similarKlantBtwNummer.totalElements > 0 && (
                        <Col sm={4}>
                            <p>
                                {t("CreateKlantModalSimilarKlanten.gelijkaardige-klanten-op-basis-van-het-btw-nummer",
                                    "Op basis van het btw-nummer:")}
                            </p>
                            <ul>
                                {similarKlantBtwNummer.content.map((item) =>
                                    <li key={item.id}>{item.nr} - {item.naam}</li>)}
                            </ul>
                        </Col>
                    )}
                </Row>
            </Collapse>
        </Alert>
    );
};
