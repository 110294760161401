import React from "react";
import Icon from "@mdi/react";
import * as MdiIcons from "@mdi/js";
import {Card, Col, Row} from "react-bootstrap";
import {Ribbon, RibbonProps} from "./Ribbon";
import {FileSize} from "./FileSize";
import {FileDownloadLink} from "./FileDownloadLink";

const MdiExtensionMap = {
    "pdf": MdiIcons.mdiFilePdfBox,
    "zip": MdiIcons.mdiFolderZip
};

export interface FileCardProps {
    filename: string;
    extraBefore?: React.ReactNode;
    extraAfter?: React.ReactNode;
    fileSize?: number;
    className?: string;
    downloadUri?: string;

    ribbon?: {
        props: RibbonProps;
        content: React.ReactNode;
    };

    onClick?(): void;
    onRemove?(): void;
}

export const FileCard: React.FC<FileCardProps> = (props) => {
    const {filename, extraBefore, extraAfter, fileSize, className, onClick, onRemove, ribbon, downloadUri} = props;

    const extension = filename.split(".")?.[1];

    const icon = MdiExtensionMap[extension] || MdiIcons.mdiFile;

    return (
        <Card className={`shadow-none border cursor-pointer ribbon-box ${className || ""}`} onClick={onClick}>
            <Card.Body className="p-2">
                { ribbon && (
                    <Ribbon {...ribbon.props}>
                        {ribbon.content}
                    </Ribbon>
                )}

                <Row className="align-items-center w-100">
                    <FileDownloadLink uri={downloadUri} as={Col}>
                        <Row>
                            <Col className={`col-auto ${ribbon ? "pl-0" : ""}`}>
                                <div className="avatar-sm">
                                    <span
                                        className="avatar-title bg-light text-secondary rounded">
                                        <Icon path={icon} size="16px"/>
                                    </span>
                                </div>
                            </Col>
                            <Col className="p-0">
                                {extraBefore}

                                <span className="text-muted font-weight-bold">{props.filename}</span>
                                {fileSize && <p className="mb-0 font-13"><FileSize fileSize={fileSize}/></p>}

                                {extraAfter}
                            </Col>
                        </Row>
                    </FileDownloadLink>
                    {onRemove && (
                        <Col className="p-0 flex-grow-0">
                            <button className="btn btn-link btn-lg text-muted" onClick={onRemove}>
                                <i className="dripicons-trash" />
                            </button>
                        </Col>
                    )}
                </Row>
            </Card.Body>
        </Card>
    )
}
