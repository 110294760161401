import * as React from "react";
import {ButtonProps} from "react-bootstrap/Button";
import {LoadingButton} from "./LoadingButton";
import {ReactNode} from "react";

type ActionBarButtonProps = {
    label: ReactNode,
    handler: any,
    loading: boolean,
    color: ButtonProps["variant"],
    icon?: any,
    visible: boolean,
    className?: string
}

const ActionBarButton = ({label, icon, handler, loading, color, visible, className}: ActionBarButtonProps) => {
    if (!visible) {
        return null;
    }

    return (
        <LoadingButton variant={color} loading={loading} disabled={loading} onClick={handler}
                       className={`ml-1 mb-1 ${className || ""}`}>
            {icon}{label}
        </LoadingButton>
    );
};

ActionBarButton.defaultProps = {
    visible: true,
};

export default ActionBarButton
