import {default as React} from "react";
import {hideModal} from "../../../../redux/ui/actions";
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {useDispatch} from 'react-redux';
import {Formik} from "formik";
import * as Yup from 'yup';
import {DatumFormField} from "../../../../components/aqualex/form/DatumFormField";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../../components/aqualex/Modal";
import {useTranslation} from "../../../../helpers/i18nUtils";
import {Trans} from "react-i18next";

type GenereerBezoekAanvragenModalProps = {
    onBevestigHandler: (van: Date, tot: Date) => void;
};
const GenereerBezoekAanvragenModal: React.FC<GenereerBezoekAanvragenModalProps> = ({onBevestigHandler}) => {

    const today = new Date();

    const {t} = useTranslation("planning");

    const schema = Yup.object({
        van: Yup.date()
            .required(t("Foutmeldingen.van-datum-is-verplicht", 'Van datum is verplicht') as string),
        tot: Yup.date()
            .required(t("Foutmeldingen.tot-datum-is-verplicht", 'Tot datum is verplicht') as string)
            .when('van', {
                is: (vanDatum) => !!vanDatum,
                then: Yup.date()
                    .required(t("Foutmeldingen.tot-datum-is-verplicht", 'Tot datum is verplicht') as string)
                    .min(Yup.ref('van'),
                        t("Foutmeldingen.tot-datum-moet-na-van-datum-liggen", 'Tot moet na van datum zijn') as string),
            })
    });

    const dispatch = useDispatch();

    const onAnnuleer = () => {
        dispatch(hideModal());
    }

    return (
        <Modal show={true} onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("GenereerBezoekaanvragenModal.bezoekaanvragen-genereren", "Bezoekaanvragen genereren")}</ModalTitle>
            </ModalHeader>

            <Formik validationSchema={schema}
                    onSubmit={(values) => {
                        dispatch(hideModal());
                        onBevestigHandler(values.van, values.tot);
                    }}
                    initialValues={{
                        van: new Date(today.getFullYear(), today.getMonth(), 1),
                        tot: new Date(today.getFullYear(), today.getMonth() + 1, 0),
                    }}>
                {({
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                      isValid,
                      values,
                      errors,
                  }) => (
                    <Form noValidate>

                        <ModalBody>

                            <Trans t={t}
                                   i18nKey="GenereerBezoekAanvragenModal.omschrijving">
                                <p>Genereer bezoekaanvragen voor prestaties die nog geen bezoekaanvraag hebben,
                                    waarbij de gewenste datum overlapt met onderstaande periode.</p>
                            </Trans>

                            <Form.Row>
                                <Col xl="auto">
                                    <FormGroup id='van' as={Col}>
                                        <DatumFormField name='van'
                                                        label={t("Labels.van", "Van")}/>
                                    </FormGroup>
                                </Col>

                                <Col xl="auto">
                                    <FormGroup id='tot' as={Col}>
                                        <DatumFormField name='tot'
                                                        label={t("Labels.tot", "Tot")}/>
                                    </FormGroup>
                                </Col>
                            </Form.Row>

                        </ModalBody>
                        <ModalFooter>
                            <Button variant='light'
                                    onClick={onAnnuleer}>{t("Buttons.annuleer", "Annuleer")}</Button>{' '}
                            <Button disabled={!isValid}
                                    variant='primary'
                                    onClick={() => handleSubmit()}>{t("Buttons.genereren", "Genereren")}</Button>
                        </ModalFooter>
                    </Form>)}
            </Formik>

        </Modal>
    );
};

export default GenereerBezoekAanvragenModal;
