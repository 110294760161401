import React from "react";

export interface RibbonProps {
    position?: "left" | "right";
    type?: "primary" | "success" | "info" | "warning" | "danger" | "dark" | "secondary";
}

export const Ribbon: React.FC<RibbonProps> = (props) => {
    const { position = "left", type = "primary" } = props;

    return (
        <div className={`ribbon ribbon-${type} float-${position}`}>
            {props.children}
        </div>
    );
};
