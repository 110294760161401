export class Icons {

    public static readonly Onderhoud = "mdi-wrench-outline";
    public static readonly Interventie = "mdi-alarm-light-outline";
    public static readonly Installatie = "mdi-water-plus-outline";
    public static readonly AccessoireLevering = "mdi-truck-delivery-outline";
    public static readonly Co2Levering = "mdi-gas-cylinder";
    public static readonly Toestel = "dripicons-flag";

    public static readonly GecommuniceerdOpAndereDatum = "mdi mdi-email-alert-outline text-danger";
    public static readonly GecommuniceerdOpJuisteDatum = "mdi mdi-email-check-outline";
    public static readonly NietGecommuniceerd = "mdi mdi-email-off-outline text-warning";

}
