import React from "react";
import {Form, useFormikContext} from "formik";
import {ModalBody, ModalFooter} from "../../../components/aqualex/Modal";
import {Button, Col, Row} from "react-bootstrap";
import {FormFileUploadField} from "../../../components/aqualex/form/FormFileUploadField";
import {SimpleCheckField} from "../../../components/aqualex/form/SimpleCheckField";
import {NieuwContractFormValues} from "./NieuwContractModal";
import {useTranslation} from "react-i18next";

export interface NieuwContractModalFormProps {
    onAnnuleerHandler: () => void;
}

export const NieuwContractModalForm: React.FC<NieuwContractModalFormProps> = (props) => {
    const {onAnnuleerHandler} = props;

    const {isValid} = useFormikContext<NieuwContractFormValues>();

    const {t} = useTranslation("bestelbon");

    return (
        <Form>
            <ModalBody>
                <Row>
                    <Col>
                        <FormFileUploadField name="contractVoorstel"
                                             label={t("Labels.contractvoorstel", "Contractvoorstel")} max={1}
                                             hideOnMax/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <SimpleCheckField name="getekend"
                                          label={t("Labels.dit-bestand-bevat-reeds-de-handtekening-van-de-klant",
                                              "Dit bestand bevat reeds de handtekening van de klant")}
                                          type="checkbox"/>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color='light'
                        onClick={onAnnuleerHandler}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                <Button color='primary' type='submit'
                        disabled={!isValid}>{t("algemeen:Buttons.bevestig", "Bevestig")}</Button>
            </ModalFooter>
        </Form>
    );
};
