import React, {ReactNode} from "react";
import {useTranslation} from "../../helpers/i18nUtils";

export interface OpsommingProps {
    items: (ReactNode | undefined | null)[];
}

export const Opsomming: React.FC<OpsommingProps> = (props) => {
    const {items} = props;

    const {t} = useTranslation("algemeen");

    const itemsToRender: ReactNode[] = [];

    const nonNullItems = items.filter(Boolean);

    for (let i = 0; i < nonNullItems.length; i++) {
        const item = nonNullItems[i];

        itemsToRender.push(item);

        const hasNext = nonNullItems[i + 1];
        const isNextLast = i + 1 === nonNullItems.length - 1;

        if (isNextLast) {
            itemsToRender.push(<span key={i}> {t("Labels.en", "en")} </span>);
        } else if (hasNext) {
            itemsToRender.push(<span key={i}>, </span>);
        }
    }

    return <>{itemsToRender}</>;
};
