import {baseApi} from "../support/api";
import {buildFilteredAndPagedSearchQuery, buildGetByIdQuery, buildQuery} from "../support/ApiBuilderUtils";
import {
    OperationeelToestelModel,
    ToestelFilters,
    ToestelKeuringAnalyseVerslagModel, UpdateLocatieForm,
    UpdateMyAqualexConfigForm
} from "./types";
import {installTranslationKey} from "../../helpers/i18nUtils";

export const toestelApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getToestelById: buildGetByIdQuery<OperationeelToestelModel, string>(build,
            installTranslationKey("toestel:APIFoutmeldingen.ophalen-van-toestel", "ophalen van toestel"), "Toestel", (id) => `toestel/${id}`),
        searchToestellen: buildFilteredAndPagedSearchQuery<OperationeelToestelModel, ToestelFilters>(build,
            installTranslationKey("toestel:APIFoutmeldingen.ophalen-van-toesetellen", "ophalen van toestellen"), "Toestel", () => `/toestel`),
        getKeuringenByToestelId: buildQuery<ToestelKeuringAnalyseVerslagModel[], string>(build,
            installTranslationKey("toestel:APIFoutmeldingen.ophalen-van-toestelkeuringsanalyseverslagen", "ophalen van toestelkeuringsanalyseverslagen"), "KeuringVerslag", (id) => `toestel/${id}/keuringen`, {
            providesTags: (result, error, toestelId) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: "KeuringVerslag" as const, id})),
                        {type: "KeuringVerslag", id: "TOESTEL-" + toestelId},
                    ]
                    : [{type: "KeuringVerslag", id: "TOESTEL-" + toestelId}],
        }),
        createToestelBijlage: build.mutation<OperationeelToestelModel, { bestand: File, omschrijving?: string } & Pick<OperationeelToestelModel, "id">>({
            query: ({id, ...form}) => {
                const formData = new FormData();

                formData.append("bestand", form.bestand, form.bestand.name);
                form.omschrijving && formData.append("omschrijving", form.omschrijving);

                return {
                    url: `/toestel/${id}/bijlage`,
                    method: 'POST',
                    body: formData,
                }
            },
            extraOptions: {
                omschrijving: "toevoegen van toestel bijlage",
            },
            invalidatesTags: (result, error, arg) => [
                {type: "Toestel", id: arg.id},
                {type: "Toestel", id: "PARTIAL-LIST"},
            ],
        }),
        deleteToestelBijlage: build.mutation<OperationeelToestelModel, { bijlageId: string } & Pick<OperationeelToestelModel, "id">>({
            query: ({id, bijlageId}) => ({
                url: `/toestel/${id}/bijlage/${bijlageId}`,
                method: 'DELETE',
            }),
            extraOptions: {
                omschrijving: installTranslationKey("toestel:APIFoutmeldingen.verwijderen-van-toestel-bijlage", "verwijderen van toestel bijlage"),
            },
            invalidatesTags: (result, error, arg) => [
                {type: "Toestel", id: arg.id},
                {type: "Toestel", id: "PARTIAL-LIST"},
            ],
        }),
        updateMyAqualexConfig: build.mutation<void, Pick<OperationeelToestelModel, "id"> & UpdateMyAqualexConfigForm>({
            query: ({id, ...form}) => ({
                url: `/toestel/${id}`,
                method: "PATCH",
                body: form,
            }),
            extraOptions: {
                omschrijving: installTranslationKey("toestel:APIFoutmeldingen.bijwerken-van-myaqualex-configuratie", "bijwerken van MyAqualex configuratie")
            },
            invalidatesTags: (result, error, {id}) => [
                {type: "Toestel", id},
                {type: "Toestel", id: "PARTIAL-LIST"}
            ],
        }),
        updateLocatie: build.mutation<void, Pick<OperationeelToestelModel, "id"> & UpdateLocatieForm>({
            query: ({id, ...form}) => ({
                url: `/toestel/${id}/locatie`,
                method: "PATCH",
                body: form,
            }),
            extraOptions: {
                omschrijving:
                    installTranslationKey("toestel:APIFoutmeldingen.bijwerken-van-locatie", "bijwerken van locatie")
            },
            invalidatesTags: (result, error, {id}) => [
                {type: "Toestel", id},
                {type: "Toestel", id: "PARTIAL-LIST"}
            ],
        }),
    })
});

export const useGetToestelById = toestelApi.endpoints.getToestelById.useQuery;
export const useSearchToestellen = toestelApi.endpoints.searchToestellen.useQuery;
export const useGetKeuringenByToestelId = toestelApi.endpoints.getKeuringenByToestelId.useQuery;
export const useCreateToestelBijlage = toestelApi.endpoints.createToestelBijlage.useMutation;
export const useDeleteToestelBijlage = toestelApi.endpoints.deleteToestelBijlage.useMutation;
export const useUpdateMyAqualexConfig = toestelApi.endpoints.updateMyAqualexConfig.useMutation;
export const useUpdateLocatie = toestelApi.endpoints.updateLocatie.useMutation;
