import {default as React} from "react";
import {Form} from "react-bootstrap";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";

export const TextAreaFormField: React.FC<FormikFormFieldProps & { rows: number, maxLength: number }> = ({
                                                                                                            rows,
                                                                                                            maxLength,
                                                                                                            placeholder,
                                                                                                            disabled,
                                                                                                            ...props
                                                                                                        }) => {
    return (
        <FormikFormField {...props}>
            {(field, meta, helper) => (
                <Form.Control {...field}
                              as="textarea"
                              rows={rows}
                              maxLength={maxLength}
                              placeholder={placeholder}
                              disabled={disabled}
                              isInvalid={!!meta.error}/>
            )}
        </FormikFormField>
    );
};
