import React, {ReactNode, useState} from "react";
import {Button, Form} from "react-bootstrap";
import {useHideModal} from "../../redux/ui/hooks";
import moment from "moment";
import BezoekAanvraagOverzichtTable, {
    BezoekAanvraagGewensteDatumColumn,
    BezoekAanvraagNrColumn,
    BezoekAanvraagPlanningColumn,
    BezoekAanvraagPrestatieSamenvattingColumn,
    BezoekAanvraagStatusColumn
} from "./bezoekaanvraag/BezoekAanvraagOverzichtTable";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "./Modal";
import {useSearchBezoekAanvragenVoorPlanning} from "../../redux/bezoekaanvraag/api";
import {useFilteredAndPagedSearch} from "../../redux/support/hooks";
import {SortOrder} from "../../redux/support/pagination";
import {BezoekAanvraagFilters, BezoekAanvraagOverzichtModel} from "../../redux/bezoekaanvraag/types";
import {useTranslation} from "../../helpers/i18nUtils";
import {FiltersAndPageable} from "../../redux/support/uiSlice";

export interface PlanOpdrachtModalProps {
    title: ReactNode;
    description: ReactNode;
    verzendAdresId: string;
    totDatum?: string;

    onBevestigHandler: (bezoekAanvraagId?: string) => void;
}

export const PlanOpdrachtModal: React.FC<PlanOpdrachtModalProps> = (props) => {
    const {title, description, verzendAdresId, totDatum, onBevestigHandler} = props;

    const {t} = useTranslation("planning");

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const handleBevestig = () => {
        hideModal();
        onBevestigHandler(bezoekAanvraagId);
    };

    const [bezoekAanvraagId, setBezoekAanvraagId] = useState<string>();
    const [nieuweBezoekAanvraag, setNieuweBezoekAanvraag] = useState(false);

    const handleSelectBezoekAanvraag = (bezoekAanvraagId?: string) => {
        setBezoekAanvraagId(bezoekAanvraagId);
        if (bezoekAanvraagId) {
            setNieuweBezoekAanvraag(false);
        }
    };

    const selectNieuweBezoekAanvraag = () => {
        setNieuweBezoekAanvraag(true);
        setBezoekAanvraagId(undefined);
    };

    const vandaag = moment().toDate();
    const binnenEenMaand = totDatum ? new Date(totDatum) : moment().add(1, "M").toDate();

    return (
        <Modal show onHide={onAnnuleer} autoFocus size="xl">
            <ModalHeader closeButton={true}>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <p>{description}</p>
                <SelecteerBezoekAanvraagTable verzendAdresId={verzendAdresId}
                                              geselecteerdeBezoekAanvraagId={bezoekAanvraagId}
                                              onSelectBezoekAanvraag={handleSelectBezoekAanvraag}/>
                <div className="mt-3">
                    <Form.Check
                        id="nieuweBezoekAanvraag"
                        type="radio"
                        checked={nieuweBezoekAanvraag}
                        label={t("PlanOpdrachtModal.een-nieuwe-bezoekaanvraag-met-gewenste-datum", "Een nieuwe bezoekaanvraag met gewenste datum {{van, datetime}} - {{tot, datetime}}", {van: vandaag, tot: binnenEenMaand})}
                        onChange={(e) => selectNieuweBezoekAanvraag()}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button variant="light" onClick={onAnnuleer}>Annuleer</Button>{" "}
                <Button variant="primary" disabled={!nieuweBezoekAanvraag && !bezoekAanvraagId}
                        onClick={() => handleBevestig()}>Bevestig</Button>
            </ModalFooter>
        </Modal>
    );
};

const initialFiltersAndPageable: FiltersAndPageable<BezoekAanvraagFilters> = {
    filters: {},
    pageable: {
        pageNumber: 1,
        pageSize: 10,
        sortField: "nummer",
        sortOrder: SortOrder.desc,
    },
    activeQuickFilters: {}
};

const SelecteerBezoekAanvraagTable: React.FC<{ verzendAdresId: string, geselecteerdeBezoekAanvraagId?: string, onSelectBezoekAanvraag: (bezoekAanvraagId?: string) => void }> = (props) => {
    const {
        verzendAdresId,
        geselecteerdeBezoekAanvraagId,
        onSelectBezoekAanvraag
    } = props;

    const {
        page,
        filtersAndPageable,
        loadData
    } = useFilteredAndPagedSearch<BezoekAanvraagOverzichtModel, BezoekAanvraagFilters>(`bezoekaanvraag.plan.${verzendAdresId}`, useSearchBezoekAanvragenVoorPlanning, {
        ...initialFiltersAndPageable,
        filters: {
            ...initialFiltersAndPageable.filters,
            verzendAdresId
        }
    });

    return (
        <BezoekAanvraagOverzichtTable
            page={page}
            pageable={filtersAndPageable.pageable}
            filters={filtersAndPageable.filters}
            loadData={loadData}

            quickFilters={[]}
            primaryFilterFields={[]}
            columns={[
                BezoekAanvraagNrColumn,
                BezoekAanvraagStatusColumn,
                BezoekAanvraagPrestatieSamenvattingColumn,
                BezoekAanvraagGewensteDatumColumn,
                BezoekAanvraagPlanningColumn
            ]}

            selectionMode="single"
            onRowSelected={bezoekAanvraag => {
                onSelectBezoekAanvraag(bezoekAanvraag?.id);
            }}
            selected={geselecteerdeBezoekAanvraagId ? [geselecteerdeBezoekAanvraagId] : []}
        />
    );
};

