import * as React from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {Button} from "react-bootstrap";
import {useTranslation} from "../../../helpers/i18nUtils";
import {Form, Formik} from "formik";
import {DatumFormField} from "../../../components/aqualex/form/DatumFormField";
import {LoadingButton} from "../../../components/aqualex/LoadingButton";
import {useHideModal} from "../../../redux/ui/hooks";

export interface StelInterventieUitFormValues {
    uitgesteldTotDatum?: Date;
}

export const InterventieStelUitModal = ({
                                     onBevestigHandler
                                 }) => {

    const hideModal = useHideModal();

    const onAnnuleer = () => {
        hideModal();
    };

    const onBevestig = (values: StelInterventieUitFormValues) => {
        onBevestigHandler(values);
        hideModal();
    };

    const {t} = useTranslation("interventie");

    return (
        <Modal show onHide={onAnnuleer} autoFocus size="lg">
            <Formik<StelInterventieUitFormValues> initialValues={{}} onSubmit={onBevestig}>
                {
                    (formikProps) => {
                        return (
                            <Form>
                                <ModalHeader closeButton={true}>
                                    <ModalTitle>{t("Titels.stel-interventie-uit", "Stel interventie uit")}</ModalTitle>
                                </ModalHeader>
                                <ModalBody>
                                    <p>{t("PlanInterventieModal.geef-eventueel-een-datum-in",
                                        "Geef eventueel een datum in tot wanneer de interventie uitgesteld moet worden. Dit is niet verplicht.")}</p>

                                    <DatumFormField name="uitgesteldTotDatum" minDate={new Date()}/>
                                </ModalBody>
                                <ModalFooter>
                                    <Button variant="light" onClick={onAnnuleer}>{
                                        t("algemeen:Buttons.annuleer", "Annuleer")}
                                    </Button>
                                    <LoadingButton variant="primary" type="submit" disabled={formikProps.isSubmitting}
                                                   loading={formikProps.isSubmitting}>
                                        {t("algemeen:Buttons.bevestig", "Bevestig")}
                                    </LoadingButton>
                                </ModalFooter>
                            </Form>
                        );
                    }
                }
            </Formik>
        </Modal>
    );
};
