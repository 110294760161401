import React from "react";
import {useFormikContext} from "formik";
import * as Yup from "yup";
import CustomerEmailPill from "../CustomerEmailPill";
import {SimpleFormField} from "./SimpleFormField";
import {MultipleFormFieldArray} from "./MultipleFormFieldArray";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface MultipleEmailAdresFormFieldArrayProps {
    name?: string;
}

export const MultipleEmailAdresFormFieldArray: React.FC<MultipleEmailAdresFormFieldArrayProps> = (props) => {
    const {name = "emailAdressen"} = props;

    const {t} = useTranslation("algemeen");

    const {values} = useFormikContext<{ [k: string]: {emailAdres: string}[] }>();

    const validationSchema = Yup.object({
        emailAdres: Yup.string().email(t("Foutmeldingen.geen-geldig-email-adres", "Gelieve een geldig e-mailadres in te vullen") as string).nullable()
            .test("no-duplicates", t("Foutmeldingen.email-adres-bestaat-al", "Dit e-mailadres is reeds toegevoegd") as string, function (value) {
                if (!value) {
                    return true;
                }

                return !values[name]?.map(item => item.emailAdres)?.includes(value);
            })
    });

    return (
        <MultipleFormFieldArray name={name}
                                validationSchema={validationSchema}
                                initialValues={{emailAdres: ""}}
                                itemRenderer={(item) => (
                                    <div>
                                        <CustomerEmailPill
                                            emailAdres={item.emailAdres}/> {item.naam}
                                    </div>
                                )}
        >
            <SimpleFormField name="emailAdres" placeholder="E-mailadres"/>
        </MultipleFormFieldArray>
    );
};
