import React, {LegacyRef} from "react";
import {useTranslation} from "../../../../../helpers/i18nUtils";

export interface PlanningPauzeProps {
    innerRef: LegacyRef<any>;
}

export const PlanningPauze: React.FC<PlanningPauzeProps> = (props) => {
    const {innerRef, ...rest} = props;

    const {t} = useTranslation("planning");

    return (
        <div className="timeline-item">
            <i className="icon mdi mdi-pause"/>

            <div className="d-flex align-items-center px-3 py-2" {...rest} ref={innerRef}>
                <i className="mdi mdi-pause font-24 mr-2"/> {t("Labels.pauze", "Pauze")}
            </div>
        </div>
    );
};
