import Config from "./Config";
import {GET} from "./api";
import {PrincipalModel} from "../redux/auth/types";

const BACKOFFICE_OAUTH_BASE_URL = Config.BACKOFFICE_BASE_URL + '/oauth/token';


export default class LoginApi {
    static login(username: string, password: string) {
        let clientId = 'sampleClientId';
        let clientSecret = 'secret';
        let authString = `${clientId}:${clientSecret}`

        if (username.toUpperCase().startsWith('AQUALEX\\')) {
            username = username.substr('AQUALEX\\'.length);
        }

        const data = `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic ' + btoa(authString),
            },
            body: data,
        };

        return fetch(BACKOFFICE_OAUTH_BASE_URL + '?grant_type=password', options)
            .then(response => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.json()
            })
            .catch(error => {
                throw error
            });
    }

    static me(): Promise<PrincipalModel> {
        const uri = Config.BACKOFFICE_BASE_URL + "/api/me2";

        return GET(uri, "ophalen van mijn accountgegevens");
    }
}
