import {default as React, useCallback} from "react";
import {FormikFormFieldProps} from "./FormikFormField";
import {KlantLanden} from "../../../redux/klant/types";
import {AdresAutocompleteInput} from "./AdresAutocompleteInput";
import {useFormikContext} from "formik";
import {Trans} from "react-i18next";
import {Col, Form, FormGroup, Row} from "react-bootstrap";
import {SimpleFormField} from "./SimpleFormField";
import {SelectFormField} from "./SelectFormField";
import {useTranslation} from "../../../helpers/i18nUtils";
import {AdresModel} from "../../../redux/verzendadres/types";

export interface AdresAutocompleteFormFieldProps {

}

export const AdresAutocompleteFormField: React.FC<FormikFormFieldProps & AdresAutocompleteFormFieldProps> = (props) => {
    const {name} = props;

    const {t} = useTranslation("algemeen");

    const {setFieldValue} = useFormikContext();

    const onAdresAutocomplete = useCallback((adres: Partial<AdresModel>) => {
        setFieldValue(`${name}.adres`, adres.adres);
        setFieldValue(`${name}.adres2`, "");
        setFieldValue(`${name}.postcode`, adres.postcode);
        setFieldValue(`${name}.plaats`, adres.plaats);
        setFieldValue(`${name}.landCode`, adres.landCode);
    }, [setFieldValue, name]);

    return (
        <Form.Row>
            <Col xs={12}>
                <div className="mb-2 d-flex align-items-center justify-content-start">
                    <i className="mdi mdi-map-marker-outline font-24 mr-2"/>
                    <div className="flex-grow-1">
                        <AdresAutocompleteInput name="adres-autocomplete"
                                                onChange={onAdresAutocomplete}/>
                        <div className="text-muted p-1">
                            <Trans t={t}
                                   i18nKey="CreateKlantModalAlgemeneInformatie.zoek-hier-een-adres-om-velden-automatisch-in-tevullen">
                                Zoek hier een adres op om de onderstaande velden automatisch in te vullen.
                            </Trans>
                        </div>
                    </div>
                </div>

                <Form.Row>
                    <Col as={Col} md={6} sm={12}>
                        <Row>
                            <FormGroup as={Col} xs={12}>
                                <SimpleFormField name={`${name}.adres`}
                                                 label={t("Labels.adresregel", "Adresregel")}
                                                 placeholder={t("Placeholders.adresregel", "Adresregel")}/>
                                <SimpleFormField name={`${name}.adres2`}
                                                 placeholder={t("Labels.tweede-adresregel", "Tweede adresregel")}/>
                            </FormGroup>
                        </Row>
                    </Col>

                    <FormGroup as={Col} md={6} sm={12}>
                        <Row>
                            <Col sm={4} className="pr-1">
                                <SimpleFormField name={`${name}.postcode`}
                                                 label={t("Labels.postcode", "Postcode")}
                                                 placeholder="1000"/>
                            </Col>
                            <Col sm={8} className="pl-0">
                                <SimpleFormField name={`${name}.plaats`}
                                                 label={t("Labels.plaats", "Plaats")}
                                                 placeholder={t("Labels.plaats", "Plaats")} uppercased
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} className="pr-1">
                                <SelectFormField name={`${name}.landCode`}>
                                    <option value="">
                                        {t("Placeholders.selecteer-een-land", "Selecteer een land...")}
                                    </option>
                                    {Object.entries(KlantLanden).map(([landCode, label]) => (
                                        <option key={landCode} value={landCode}>{label}</option>
                                    ))}
                                </SelectFormField>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form.Row>
            </Col>
        </Form.Row>
    );
};
