import {BedrijfModel} from "./types";
import {buildQuery} from "../support/ApiBuilderUtils";
import {baseApi} from "../support/api";
import {installTranslationKey} from "../../helpers/i18nUtils";

export const bedrijfApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getBedrijven: buildQuery<BedrijfModel[], any>(
            build,
            installTranslationKey("accessoire-bestelling:APIFoutmeldingen.ophalen-van-bedrijven", "ophalen van bedrijven"),
            "Bedrijf",
            () => "/bedrijf",
            {
                providesTags: result => result
                    ? [
                        ...result.map(({id}) => ({type: "Bedrijf" as const, id})),
                        {type: "Bedrijf" as const, id: 'LIST'},
                    ]
                    : [{type: "Bedrijf" as const, id: 'LIST'}],
            }
        ),
    })
});

export const useGetBedrijven = bedrijfApi.endpoints.getBedrijven.useQuery;