import React from "react";
import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import {SelectInput} from "./SelectInput";
import {AdresModel} from "../../../redux/verzendadres/types";
import Config from "../../../helpers/Config";
import {useTranslation} from "../../../helpers/i18nUtils";

export interface AdresAutocompleteInputProps {
    name: string;
    className?: string;

    onChange(adres: Partial<AdresModel>): void;
}

export const AdresAutocompleteInput: React.FC<AdresAutocompleteInputProps> = (props) => {
    const {name, className} = props;

    const {t} = useTranslation("algemeen");

    const [adres, setAdres] = React.useState<object | null>(null);

    const onChange = adres => {
        setAdres(adres);
    };

    const onSelect = async adresString => {
        const results: google.maps.GeocoderResult[] = await geocodeByAddress(adresString);
        const result = results[0];

        if (result) {
            const straat = result.address_components.find(item => item.types.includes("route"))?.long_name;
            const nummer = result.address_components.find(item => item.types.includes("street_number"))?.long_name;
            const plaats = ["sublocality", "locality", "administrative_area_level_1", "administrative_area_level_2"]
                .map((type) => result.address_components.find(item => item.types.includes(type))?.long_name)
                .find((item) => item)
                ?.toUpperCase();
            const landCode = result.address_components.find(item => item.types.includes("country"))?.short_name;
            const postcode = result.address_components.find(item => item.types.includes("postal_code"))?.long_name;

            const adres: Partial<AdresModel> = {
                adres: [straat, nummer].filter(item => item).join(" "),
                postcode,
                plaats,
                landCode
            };

            props.onChange(adres);
        }

    };

    const searchOptions: Partial<google.maps.places.AutocompletionRequest> = {
        types: ["address"],
        componentRestrictions: {
            country: Config.ADDRESS_AUTOCOMPLETE_COUNTRIES
        }
    };

    return (
        <PlacesAutocomplete
            value={adres}
            onChange={onChange}
            onSelect={onSelect}

            searchOptions={searchOptions}
        >
            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => {
                const {onChange, value, ...inputProps} = getInputProps({
                    placeholder: t("Placeholder.adres-opzoeken", "Adres opzoeken..."),
                    className: "location-search-input",
                    type: undefined,
                    role: undefined
                });

                return (
                    <SelectInput
                        {...inputProps}

                        className={className}

                        value={adres}
                        inputValue={value || ""}
                        onInputChange={(value, action) => action !== "set-value" && onChange({target: {value}})}

                        onChange={(suggestion) => {
                            const {onClick} = getSuggestionItemProps(suggestion);

                            onClick();
                        }}

                        name={name}
                        loading={loading}

                        options={suggestions || []}
                        getOptionLabel={item => item.description}
                        getOptionValue={item => item}

                        noOptionsMessage={() => t("Label.geen-resultaten", "Geen resultaten")}

                        components={{Option}}
                    />
                );
            }}
        </PlacesAutocomplete>
    );
};
