import * as React from "react";
import {Badge} from "react-bootstrap";

interface CustomerEmailPillProps {
    emailAdres: string;
}

const CustomerEmailPill: React.FC<CustomerEmailPillProps> = ({emailAdres}) => {
    return <span className="mr-1">
        <a href={'mailto:' + emailAdres}>
                <Badge className="font-14" pill variant="secondary">{emailAdres}</Badge>
        </a>
    </span>;
};

export default CustomerEmailPill;
