import {AdresForm, GeolocationModel} from "../redux/klant/types";
import {geocodeByAddress, getLatLng} from "react-places-autocomplete";

export class AdresUtils {

    public static async geocodeByAdres(adres: AdresForm): Promise<GeolocationModel | null> {
        const adresString = [adres.adres, adres.adres2, adres.postcode, adres.plaats, adres.landCode]
            .filter((item) => item)
            .join(" ");

        const results = await geocodeByAddress(adresString);
        if (!results || !results[0]) {
            return null;
        }

        const { lat, lng } = await getLatLng(results[0]);

        return {
            latitude: lat,
            longitude: lng
        };
    }

}
