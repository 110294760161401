import {default as React} from "react";
import {Formik} from "formik";
import {hideModal} from "../../../redux/ui/actions";
import {Button, Col, Form, FormGroup} from "react-bootstrap";
import {useDispatch} from 'react-redux';
import {KlantModel} from "../../../redux/klant/types";
import * as Yup from 'yup';
import {KlantFormField} from "../../../components/aqualex/form/KlantFormField";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "react-i18next";

export interface KoppelKlantModalProps {
    onBevestigHandler: (klantId: string) => void;
}

interface FormValues {
    klant?: KlantModel;
}

export const KoppelKlantModal: React.FC<KoppelKlantModalProps> = ({
                                                                      onBevestigHandler,
                                                                  }) => {
    const dispatch = useDispatch();

    const onAnnuleer = () => {
        dispatch(hideModal());
    }

    const onBevestig = (klantId: string) => {
        dispatch(hideModal());

        onBevestigHandler(klantId);
    }

    const initialValues: FormValues = {
        klant: undefined,
    };

    const {t} = useTranslation("klantaccount");

    const klantIsVerPlichtLabel = t("Foutmeldingen.klant-is-verplicht", "Klant is verplicht");

    return (
        <Modal show={true} onHide={onAnnuleer} autoFocus>
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.klant-koppelen", "Klant koppelen")}</ModalTitle>
            </ModalHeader>
            <Formik
                initialValues={initialValues}
                validateOnBlur={false}
                validateOnChange={false}
                validateOnMount // to make validateOnMount, initialValues must be created using useMemo
                onSubmit={(values: FormValues) => onBevestig(values.klant!.id)}
                validationSchema={Yup.object({
                    klant: Yup.object()
                        .nullable()
                        .required(klantIsVerPlichtLabel),
                })}
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      setFieldValue,
                  }) => {
                    return <Form noValidate>
                        <ModalBody>
                            <h5>{t("Titels.klant", "Klant")}</h5>
                            <Form.Row>
                                <FormGroup id='klant' as={Col}>
                                    <KlantFormField name='klant'/>
                                </FormGroup>
                            </Form.Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='light' onClick={onAnnuleer}>{t("algemeen:Buttons.annuleer", "Annuleer")}</Button>{' '}
                            <Button variant='primary' disabled={!isValid}
                                    onClick={() => handleSubmit()}>Bevestig</Button>
                        </ModalFooter>
                    </Form>
                }}
            </Formik>
        </Modal>
    );
};
