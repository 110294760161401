import {FetchGoogleMapsDirectionsAction, GoogleMapsDirections} from "./types";
import {
    FETCH_GOOGLE_MAPS_ROUTE_FAILURE,
    FETCH_GOOGLE_MAPS_ROUTE_REQUEST,
    FETCH_GOOGLE_MAPS_ROUTE_SUCCESS,
    INVALIDATE_GOOGLE_MAPS_DIRECTIONS
} from "./constants";
import {createActionsWithResult} from "../support/actionCreators";

export const [fetchGoogleMapsDirectionsRequest, fetchGoogleMapsDirectionsSuccess, fetchGoogleMapsDirectionsFailure] =
    createActionsWithResult<{ id: string, places: Array<{ lat: number, lng: number }> },
        GoogleMapsDirections | undefined,
        FetchGoogleMapsDirectionsAction>([FETCH_GOOGLE_MAPS_ROUTE_REQUEST, FETCH_GOOGLE_MAPS_ROUTE_SUCCESS, FETCH_GOOGLE_MAPS_ROUTE_FAILURE]);

export const invalidateGoogleMapsDirectons = (id: string) => ({
    type: INVALIDATE_GOOGLE_MAPS_DIRECTIONS,
    payload: {
        id
    }
});
