import {default as React} from "react";
import {Form} from "react-bootstrap";
import {FormikFormField, FormikFormFieldProps} from "./FormikFormField";
import {CapitalizeTextFormControl} from "./CapitalizeTextFormControl";
import {FormControlProps} from "react-bootstrap/FormControl";

export interface SimpleFormFieldProps {
    uppercased?: boolean;
    capitalize?: boolean;
    ignoreRegex?: RegExp;
    formControlProps?: FormControlProps;
}

export const SimpleFormField: React.FC<SimpleFormFieldProps & FormikFormFieldProps & { type?: string }> = ({
                                                                                        type,
                                                                                        disabled,
                                                                                        placeholder,
                                                                                        onChange,
                                                                                        uppercased,
                                                                                        capitalize,
                                                                                        ignoreRegex,
                                                                                        formControlProps,
                                                                                        ...props
                                                                                    }) => {
        return (
            <FormikFormField {...props}>
                {(field, meta) => {
                    const onFieldChange = (e) => {
                        if (e?.target?.value) {
                            if (ignoreRegex) {
                                e.target.value = e.target.value.replace(ignoreRegex, "");
                            }

                            if (uppercased) {
                                e.target.value = e.target.value.toUpperCase();
                            }
                        }

                        if (onChange) {
                            onChange(e);
                        } else {
                            field.onChange(e);
                        }
                    };

                    const FormControl = capitalize ? CapitalizeTextFormControl : Form.Control;

                    return (
                        <FormControl {...field}
                                     onChange={onFieldChange}
                                     type={type}
                                     disabled={disabled}
                                     placeholder={placeholder}
                                     isInvalid={!!meta.error}
                            {...formControlProps}/>
                    );
                }}
            </FormikFormField>
        );
    }
;
