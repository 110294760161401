import {default as React} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {
    CreateKlantForm,
    CreateKlantUitnodigingType,
    GeenKlantAccountMakenReden,
    KlantBtwBedrijfsboekingsgroepenEntries,
    KlantSectoren
} from "../../../redux/klant/types";
import {useLoggedInUser} from "../../../redux/auth/hooks";
import {CreateKlantModalForm} from "./CreateKlantModalForm";
import {TransportKostConfiguratieType} from "../../../redux/types";
import {transportKostValidatie} from "../../../components/aqualex/form/TransportKostConfiguratieForm";
import {ExtraCo2FlesBeleid} from "../../../redux/co2bestelling/types";
import {Modal, ModalHeader, ModalTitle} from "../../../components/aqualex/Modal";
import {useTranslation} from "../../../helpers/i18nUtils";
import Config from "../../../helpers/Config";

export interface NieuweKlantModalProps {
    initialValues?: NieuweKlantModalFormValues;
    loading?: boolean;

    disabledFieldNames?: string[];

    onSubmit(form: CreateKlantForm): void | Promise<void>;

    onCancel(): void;
}

export interface NieuweKlantModalFormValues {
    "algemeneInformatie:bedrijfId": string;
    "algemeneInformatie:btwNummer"?: string | null;

    "algemeneInformatie:naam": string;
    "algemeneInformatie:verkoperId"?: string;
    "klantparameters:externDocumentNrVerplicht": boolean;
    "klantparameters:btwBedrijfsboekingsgroep": string;
    "klantparameters:kredietlimiet": number;
    "klantparameters:betalingscondities"?: string;
    "klantparameters:aanmaningsconditie": string;
    "klantparameters:sector": string;
    "klantparameters:bedrijfsgrootte": string;
    "klantparameters:keyAccount": boolean;
    "klantparameters:extraCo2FlesBeleid": string;

    "algemeneInformatie:adres": string;
    "algemeneInformatie:adres2"?: string;
    "algemeneInformatie:postcode": string;
    "algemeneInformatie:plaats": string;
    "algemeneInformatie:landCode": string;
    "algemeneInformatie:taal": string;

    "contactgegevens:contactNaam": string;
    "contactgegevens:emailadres": string;
    "contactgegevens:emailadresFacturen"?: string;
    "contactgegevens:emailadresAanmaningen"?: string;
    "contactgegevens:mobielTelefoonnummer"?: string;
    "contactgegevens:telefoonnummer": string;

    "klantparameters:transportKostType"?: string;
    "klantparameters:transportKostValue"?: number;

    "myaqualex:klantAccountWelkomMailAction"?: CreateKlantUitnodigingType;
    "myaqualex:klantAccounts"?: { naam: string; emailAdres: string; }[];
    "myaqualex:geenKlantAccountMakenReden"?: string;

    "myaqualex:klantAccounts:validate"?();
}

export const defaultNieuweKlantModalFormValues: NieuweKlantModalFormValues = {
    "algemeneInformatie:bedrijfId": "BE",
    "algemeneInformatie:btwNummer": "",
    "algemeneInformatie:adres": "",
    "algemeneInformatie:adres2": "",
    "algemeneInformatie:postcode": "",
    "algemeneInformatie:plaats": "",
    "algemeneInformatie:landCode": Config.DEFAULT_COUNTRY_CODE,
    "algemeneInformatie:taal": Config.DEFAULT_LANGUAGE_CODE,
    "contactgegevens:contactNaam": "",
    "contactgegevens:emailadres": "",
    "klantparameters:externDocumentNrVerplicht": false,
    "algemeneInformatie:naam": "",
    "contactgegevens:telefoonnummer": "",
    "algemeneInformatie:verkoperId": undefined,
    "klantparameters:btwBedrijfsboekingsgroep": KlantBtwBedrijfsboekingsgroepenEntries[0][0],
    "klantparameters:kredietlimiet": 5000.00,
    "klantparameters:betalingscondities": "30D",
    "klantparameters:aanmaningsconditie": "AANMNL",
    "klantparameters:keyAccount": false,
    "klantparameters:bedrijfsgrootte": "",
    "klantparameters:sector": "",
    "klantparameters:transportKostType": TransportKostConfiguratieType.STANDAARD,
    "klantparameters:extraCo2FlesBeleid": ExtraCo2FlesBeleid.GEBRUIKSRECHT
};

const CreateKlantModal: React.FC<NieuweKlantModalProps> = (props) => {
    const {onSubmit, disabledFieldNames} = props;

    const {t} = useTranslation("klant");

    const loggedInUser = useLoggedInUser();

    const [loadingState, setLoading] = React.useState(false);
    const loading = props.loading || loadingState;

    const onValidate = (values: NieuweKlantModalFormValues) => {
        return values["myaqualex:klantAccounts:validate"]?.();
    };

    const onFormSubmit = async (values: NieuweKlantModalFormValues) => {
        const result = onSubmit({
            bedrijfId: values["algemeneInformatie:bedrijfId"],
            btwNummer: values["algemeneInformatie:btwNummer"]!,

            aanmaningsconditie: values["klantparameters:aanmaningsconditie"],
            btwBedrijfsboekingsgroep: values["klantparameters:btwBedrijfsboekingsgroep"],
            betalingscondities: values["klantparameters:betalingscondities"]!,
            klantenprijsgroep: values["klantparameters:klantenprijsgroep"]!,
            kredietlimiet: values["klantparameters:kredietlimiet"],
            sector: values["klantparameters:sector"],
            bedrijfsgrootte: values["klantparameters:bedrijfsgrootte"],
            keyAccount: values["klantparameters:keyAccount"],

            adres: {
                adres: values["algemeneInformatie:adres"],
                adres2: values["algemeneInformatie:adres2"],
                postcode: values["algemeneInformatie:postcode"],
                plaats: values["algemeneInformatie:plaats"],
                landCode: values["algemeneInformatie:landCode"]
            },
            contactNaam: values["contactgegevens:contactNaam"],
            emailadres: values["contactgegevens:emailadres"],
            emailadresFacturen: values["contactgegevens:emailadresFacturen"],
            emailadresAanmaningen: values["contactgegevens:emailadresAanmaningen"],
            externDocumentNrVerplicht: values["klantparameters:externDocumentNrVerplicht"],
            locale: values["algemeneInformatie:taal"],
            naam: values["algemeneInformatie:naam"],
            mobielTelefoonnummer: values["contactgegevens:mobielTelefoonnummer"],
            telefoonnummer: values["contactgegevens:telefoonnummer"],
            verkoperId: values["algemeneInformatie:verkoperId"],
            transportKostType: values["klantparameters:transportKostType"] as TransportKostConfiguratieType,
            transportKostValue: values["klantparameters:transportKostValue"],
            extraCo2FlesBeleid: values["klantparameters:extraCo2FlesBeleid"] as ExtraCo2FlesBeleid,

            uitnodigingType: values["myaqualex:klantAccountWelkomMailAction"],
            klantAccounts: values["myaqualex:klantAccounts"] || [],
            geenKlantAccountMakenReden: values["myaqualex:geenKlantAccountMakenReden"] as GeenKlantAccountMakenReden
        });

        if (result && typeof result.then === "function") {
            setLoading(true);

            try {
                await result;
            } finally {
                setLoading(false);
            }
        }
    };

    const naamMaximaleLengte = 100;
    const adresMaximaleLengte = 100;
    const adres2MaximaleLengte = 50;
    const postcodeMaximaleLengte = 20;
    const plaatsnaamMaximaleLengte = 30;
    const landCodeMaximaleLengte = 20;
    const contactpersoonNaamMaximaleLengte = 100;
    const eMailAdresMaximaleLengte = 80;
    const telefoonNummerMinimaleLengte = 5;
    const telefoonNummerMaximaleLengte = 30;

    const formSchema = Yup.object<NieuweKlantModalFormValues>({
        "algemeneInformatie:bedrijfId": Yup.string()
            .required(t("Foutmeldingen.bedrijf-is-verplicht", "Bedrijf is verplicht") as string),

        "algemeneInformatie:naam": Yup.string()
            .required(t("Foutmeldingen.naam-is-verplicht",
                "Naam is verplicht") as string)
            .max(naamMaximaleLengte,
                t("Foutmeldingen.maximale-lengte-naam",
                    "Naam kan maximum {{lengte}} karakters bevatten",
                    {lengte: naamMaximaleLengte}) as string),
        "algemeneInformatie:verkoperId": Yup.string()
            .nullable()
            .required(t("Foutmeldingen.verkoper-is-verplicht",
                "Verkoper is verplicht") as string),
        "klantparameters:externDocumentNrVerplicht": Yup.boolean(),
        "algemeneInformatie:btwNummer": Yup.string().nullable()
            .when("klantparameters:sector", {
                is: KlantSectoren.BEDRIJF,
                then: Yup.string().required(
                    t("Foutmeldingen.btw-nummer-is-verplicht-bij-bedrijven",
                        "Btw-nummer is verplicht bij bedrijven") as string)
            }),
        "klantparameters:btwBedrijfsboekingsgroep": Yup.string()
            .required(t("Foutmeldingen.btw-bedrijfsboekingsgroep-is-verplicht",
                "BTW Bedrijfsboekingsgroep is verplicht") as string),
        "klantparameters:kredietlimiet": Yup.number()
            .required(t("Foutmeldingen.kredietlimiet-is-verplicht", "Kredietlimiet is verplicht") as string),
        "klantparameters:betalingscondities": Yup.string()
            .required(t("Foutmeldingen.betalingscondities-is-verplicht", "Betalingscondities is verplicht") as string),
        "klantparameters:aanmaningsconditie": Yup.string()
            .required(t("Foutmeldingen.aanmaningsconditie-is-verplicht", "Aanmaningsconditie is verplicht") as string),
        "klantparameters:keyAccount": Yup.boolean()
            .required(t("Foutmeldingen.keyaccount-is-verplicht", "Keyaccount is verplicht") as string),
        "klantparameters:bedrijfsgrootte": Yup.string()
            .required(t("Foutmeldingen.bedrijfsgrootte-is-verplicht", "Bedrijfsgrootte is verplicht") as string),
        "klantparameters:sector": Yup.string()
            .required(t("Foutmeldingen.sector-is-verplicht", "Sector is verplicht") as string),
        ...transportKostValidatie(t, "klantParameters:"),
        "klantparameters:extraCo2FlesBeleid": Yup.string()
            .required(t("Foutmeldingen.extra-co2-fles-beleid-is-verplicht", "Extra CO₂-fles beleid is verplicht") as string),
        "algemeneInformatie:adres": Yup.string()
            .required(t("Foutmeldingen.adres-is-verplicht",
                "Adres is verplicht") as string)
            .max(adresMaximaleLengte,
                t("Foutmeldingen.maximale-lengte-adres",
                    "Adres kan maximum {{lengte}} karakters bevatten",
                    {lengte: adresMaximaleLengte}) as string),
        "algemeneInformatie:adres2": Yup.string()
            .max(adres2MaximaleLengte,
                t("Foutmeldingen.maximale-lengte-adresregel2",
                    "Tweede adresregel kan maximum {{lengte}} karakters bevatten",
                    {lengte: adres2MaximaleLengte}) as string),
        "algemeneInformatie:postcode": Yup.string()
            .required(t("Foutmeldingen.postcode-is-verplicht", "Postcode is verplicht") as string)
            .max(postcodeMaximaleLengte,
                t("Foutmeldingen.postcode-maximale-lengte",
                "Postcode kan maximum {{lengte}} karakters bevatten",
                    {lengte: postcodeMaximaleLengte}) as string),
        "algemeneInformatie:plaats": Yup.string()
            .required(t("Foutmeldingen.plaats-is-verplicht",
                "Plaats is verplicht") as string)
            .max(plaatsnaamMaximaleLengte,
                t("Foutmeldingen.plaatsnaam-maximale-lengte",
                    "Plaats kan maximum {{lengte}} karakters bevatten",
                    {lengte: plaatsnaamMaximaleLengte}) as string),
        "algemeneInformatie:landCode": Yup.string()
            .required(t("Foutmeldingen.land-is-verplicht",
                "Land is verplicht") as string)
            .max(landCodeMaximaleLengte,
                t("Foutmeldingen.landcode-maximale-lengte",
                    "Landcode kan maximum {{lengte}} karakters bevatten",
                    {lengte: landCodeMaximaleLengte}) as string),
        "contactgegevens:contactNaam": Yup.string()
            .required(t("Foutmeldingen.naam-contactpersson-is-verplicht", "Naam contactpersoon is verplicht") as string)
            .max(contactpersoonNaamMaximaleLengte,
                t("Foutmeldingen.naam-contactpersoon-maximale-lengte",
                    "Naam contactpersoon kan maximum {{lengte}} karakters bevatten",
                    {lengte: contactpersoonNaamMaximaleLengte}) as string),
        "contactgegevens:emailadres": Yup.string()
            .required(t("Foutmeldingen.e-mail-adres-is-verplicht", "E-mailadres is verplicht") as string)
            .email(t("Foutmeldingen.ongeldig-e-mail-adres",
                "E-mailadres is geen geldig e-mailadres") as string)
            .max(eMailAdresMaximaleLengte,
                t("Foutmeldingen.e-mail-adres-maximale-lengte",
                    "E-mailadres kan maximum {{lengte}} karakters bevatten",
                    {lengte: eMailAdresMaximaleLengte}) as string),
        "contactgegevens:emailadresFacturen": Yup.string()
            .email(t("Foutmeldingen.ongeldig-e-mail-adres-voor-facturen",
                "E-mailadres voor facturen is geen geldig e-mailadres") as string)
            .max(eMailAdresMaximaleLengte,
                t("Foutmeldingen.e-mail-adres-voor-facturen-maximale-lengte",
                    "E-mailadres voor facturen kan maximum {{lengte}} karakters bevatten",
                    {lengte: eMailAdresMaximaleLengte})  as string),
        "contactgegevens:emailadresAanmaningen": Yup.string()
            .email(t("Foutmeldingen.ongeldig-e-mail-adres-voor-aanmaningen",
                "E-mailadres voor aanmaningen is geen geldig e-mailadres") as string)
            .max(eMailAdresMaximaleLengte,
                t("Foutmeldingen.e-mail-adres-voor-aanmaningen-maximale-lengte",
                    "E-mailadres voor aanmaningen kan maximum {{lengte}} karakters bevatten",
                    {lengte: eMailAdresMaximaleLengte}) as string),
        "contactgegevens:mobielTelefoonnummer": Yup.string()
            .max(telefoonNummerMaximaleLengte,
                t("Foutmeldingen.mobiel-telefoonnummer-maximale-lengte",
                    "Mobiel telefoonnummer kan maximum {{lengte}} karakters bevatten",
                    {lengte: telefoonNummerMaximaleLengte}) as string),
        "contactgegevens:telefoonnummer": Yup.string()
            .required(t("Foutmeldingen.telefoonnummer-is-verplicht", "Telefoonnummer is verplicht") as string)
            .min(telefoonNummerMinimaleLengte,
                t("Foutmeldingen.telefoonnummer-is-verplicht",
                    "Telefoonnummer is verplicht") as string)
            .max(telefoonNummerMaximaleLengte,
                t("Foutmeldingen.telefoonnummer-maximale-lengte",
                    "Telefoonnummer kan maximum {{lengte}} karakters bevatten",
                    {lengte: telefoonNummerMaximaleLengte}) as string),
        "algemeneInformatie:taal": Yup.string()
            .required(t("Foutmeldingen.taal-is-verplicht", "Taal is verplicht") as string),

        "myaqualex:klantAccountWelkomMailAction": Yup.string()
            .required(t("Foutmeldingen.uitnodigings-e-mail-versturen-is-verplicht",
                "'Uitnodigingsmail versturen' is verplicht") as string) as any,
        "myaqualex:geenKlantAccountMakenReden": Yup.string()
            .test("reden-verplicht-indien-geen-klantaccounts",
                t("Foutmeldingen.verantwoord-niet-aanmaken-van-klantaccount",
                    "Een reden is verplicht indien er geen klantaccounts aangemaakt worden") as string,
                function (value) {
                const geenKlantAccount = !this.parent["myaqualex:klantAccounts"]?.length;

                if (geenKlantAccount && !value) {
                    return false;
                }

                return true;
            })
    });

    return (
        <Modal show={true} onHide={props.onCancel} autoFocus size="xl">
            <ModalHeader closeButton={true}>
                <ModalTitle>{t("Titels.klant-toevoegen", "Klant toevoegen")}</ModalTitle>
            </ModalHeader>

            <Formik<NieuweKlantModalFormValues> validationSchema={formSchema} validate={onValidate}
                                                onSubmit={onFormSubmit}
                                                validateOnBlur={false}
                                                validateOnChange={false}
                                                initialValues={props.initialValues || {
                                                    ...defaultNieuweKlantModalFormValues,
                                                    "algemeneInformatie:verkoperId": loggedInUser?.medewerkerId
                                                }}
            >
                <CreateKlantModalForm onCancel={props.onCancel} loading={loading}
                                      disabledFieldNames={disabledFieldNames}/>
            </Formik>
        </Modal>
    );
};

export default CreateKlantModal;
