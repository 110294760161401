import React, {useEffect, useState} from "react";
import Dropzone, {DropzoneOptions} from "react-dropzone";
import {Col, Row} from "react-bootstrap";
import {FileCard} from "./FileCard";
import * as uuid from "uuid";
import {BestandModel} from "../../redux/installatie/types";
import {blobToDataUri} from "../../helpers/download";
import {Spin} from "../Spin";
import {useTranslation} from "../../helpers/i18nUtils";

export interface InstantFileUploadProps {
    initialValues: any[];

    onUpload(file: File): Promise<void>;
    onRemove?(bestandId: string): Promise<void>;

    dropzoneOptions?: DropzoneOptions;
    renderFiles?: boolean;
    dropzoneClassName?: string;

    size?: "sm" | "lg";
}

export const InstantFileUpload: React.FC<InstantFileUploadProps> = (props) => {
    const {onUpload, onRemove, dropzoneOptions, renderFiles = true, dropzoneClassName, size = "lg"} = props;

    const {t} = useTranslation("algemeen");

    const [files, setFiles] = useState<(BestandModel & {loading?: boolean})[]>(props.initialValues || []);

    useEffect(() => {
        if (props.initialValues) {
            setFiles(props.initialValues);
        }
    }, [props.initialValues]);

    const setFileLoading = (id: string, loading: boolean) => {
        setFiles((prev) => {
            return prev.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        loading
                    };
                } else {
                    return item;
                }
            });
        });
    };

    const onDrop = async (bestanden: File[]) => {
        for (const bestand of bestanden) {
            const dataUri = await blobToDataUri(bestand);

            const id = uuid.v4();
            setFiles((prev) => {
                return [...prev, {id, naam: bestand.name, downloadUri: dataUri, previewUri: dataUri, type: "local", grootte: bestand.size, mediaType: bestand.type, loading: true} as BestandModel];
            });

            await onUpload(bestand);

            setFileLoading(id, false);
        }
    };

    const onFileRemove = async (index: number) => {
        if (files[index]) {
            const id = files[index].id;
            setFileLoading(id, true);

            await onRemove?.(files[index].id);

            setFiles((prev) => {
                return prev.filter(item => item.id !== id);
            });
        }
    };

    return (
        <div>
            {renderFiles && (
                <Row>
                    {files.map((bestand, i) => (
                        <Col xs={12} md={4}>
                            <Spin spinning={bestand.loading}>
                                <FileCard filename={bestand.naam} downloadUri={bestand.downloadUri} key={i}
                                          onRemove={() => onFileRemove(i)}/>
                            </Spin>
                        </Col>
                    ))}
                </Row>
            )}

            <Dropzone {...dropzoneOptions} onDrop={(bestanden) => onDrop(bestanden)}>
                {({getRootProps, getInputProps,}) => (
                    <div className={`dropzone ${size === "sm" ? "dropzone--small" : ""} ${dropzoneClassName || ""}`}>
                        <div
                            className="dz-message needsclick" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <i className="h1 text-muted dripicons-cloud-upload"></i>
                            <h4>{t("FileUpload.sleep-hier-het-bestand-of-klik-om-te-uploaden", "Sleep hier het bestand of klik om te uploaden")}</h4>
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    );
};
