import { Page } from '../support/pagination/types';
import { EntityDict, Pagination } from '../support/pagination';
import { ApiError } from '../ui/types';
import { KlantModel } from '../klant/types';
import { VerzendAdresModel } from '../verzendadres/types';
import { MedewerkerModel } from '../medewerker/types';
import { VerkoopOrderModel } from '../installatie/types';
import { TransportKostConfiguratieModel, TransportKostConfiguratieType } from '../types';

export interface AccessoireBestellingState {
    entities: {
        accessoireBestelling: EntityDict<AccessoireBestellingModel>;
    };
    pagination: {
        accessoireBestelling: Pagination;
    };
    error?: ApiError;
}

export enum AccessoireBestellingStatus {
    NIEUW = "NIEUW",
    PLANNING = "PLANNING",
    GELEVERD = "GELEVERD",
    GEANNULEERD = "GEANNULEERD",
    WACHT_OP_UITVOERING = "WACHT_OP_UITVOERING",
    NIET_UITGEVOERD = "NIET_UITGEVOERD"
}

export interface AccessoireBestellingFilters {
    klantNr?: string;
    klantNaam?: string;
    statusNotIn?: AccessoireBestellingStatus[];
}

export interface AccessoireBestellingModel {
    id: string;
    status: AccessoireBestellingStatus;
    nummer: string;
    klant: KlantModel;
    verzendAdres: VerzendAdresModel;
    creatieTijdstip: string;
    aangemaaktDoorMedewerker: MedewerkerModel;
    verkoopOrder: VerkoopOrderModel;
    opdrachtId?: string;
    geplandTijdstip?: string;
    geplandDoorMedewerker?: MedewerkerModel;
    transportKostConfiguratie: TransportKostConfiguratieModel;
    geleverdTijdstip?: string;
    geleverdDoorMedewerker?: MedewerkerModel;
}

export interface NieuweAccessoireBestellingForm {
    bedrijfId: string;
    verkoopOrderNr: string;

    transportKostType: TransportKostConfiguratieType;
    transportKostValue?: number;
}

export type AccessoireBestellingPage = Page<AccessoireBestellingModel>;
