import * as React from "react";
import {AdresModel} from "../../redux/verzendadres/types";
import {useTranslation} from "../../helpers/i18nUtils";

export interface AdresProps {
    adres?: AdresModel;
    toonDepotCode?: boolean;

    plaatsnaamAparteLijn?: boolean;
}

const Adres: React.FC<AdresProps> = ({adres, toonDepotCode = false, plaatsnaamAparteLijn = true}) => {
    const {t} = useTranslation("algemeen");

    if (!adres) {
        return null;
    }

    return (
        <React.Fragment>
            {toonDepotCode && adres.depotCode &&
                <React.Fragment>{t("algemeen:Labels.depot", "Depot")} <strong>{adres.depotCode}</strong><br/></React.Fragment>
            }
            {adres.adres}<br/>
            {adres.adres2 &&
            <React.Fragment>{adres.adres2}{plaatsnaamAparteLijn ? <br/> : ", "}</React.Fragment>
            }
            {adres.postcode} {adres.plaats}
        </React.Fragment>
    );
};

export default Adres;
