import * as React from "react";
import GenericBadge from "../GenericBadge";
import {TransactieType} from "../../../redux/bestelbon/types";
import Config from "../../../helpers/Config";

interface TransactieTypeProps {
    transactieType: TransactieType;
}

const TransactieTypeBadge: React.FC<TransactieTypeProps> = ({transactieType}) => {
    const config = {
        [TransactieType.VERKOOP]: {
            label: Config.labels.transactieType[TransactieType.VERKOOP],
            color: 'secondary-lighten',
        },
        [TransactieType.HUUR]: {
            label: Config.labels.transactieType[TransactieType.HUUR],
            color: 'primary-lighten',
        },
    };

    return <GenericBadge value={transactieType}
                         config={config}/>;
};


export default TransactieTypeBadge;
