import * as React from "react";
import {formatDateHuman, formatTime} from "../../helpers/DateUtils";

interface DatumProps {
    datum?: Date | string;
    showTime?: boolean;

    format?: string;
}

const Datum: React.FC<DatumProps> = ({datum, showTime = true, format}) => {
    if (!datum) {
        return null;
    }

    return (
        <span>{formatDateHuman(datum, format)}{showTime && ' ' + formatTime(datum)}</span>
    );
};

export default Datum;
