import React, {ReactNode, useState} from 'react';
import {Button, Col, Collapse, Form, FormGroup, Row} from "react-bootstrap";
import OverviewTable, {OverviewTableProps} from "./OverviewTable";

export interface Filter {
    naam: string;
    label?: ReactNode;
    component: any;
}

export interface FilteredOverviewTableProps<T> extends OverviewTableProps<T> {
    reloadClicked?: () => void;

    quickFiltersWithGroup?: { group?: string, element: ReactNode }[];
    primaryFilters?: Filter[];
    secondaryFilters?: Filter[];

    resetFilters?(): void;
}

const FilteredOverviewTable = <T, >(props: FilteredOverviewTableProps<T>) => {
    const {
        itemsLabel,
        loading,
        items,
        page,
        pageSize,
        totalSize,
        onTableChange,
        rowClicked,
        quickFiltersWithGroup,
        primaryFilters,
        secondaryFilters,
        columns,
        defaultSorted,
        selectRow,
        expandRow,
        isExpandable,
        reloadClicked,
        wrapperClasses,
        headerClasses,
        rowClasses,
        hover
    } = props;

    const [showSecondaryFilters, setShowSecondaryFilters] = useState(false);

    const quickFilterGroups: Record<string, ReactNode> | undefined = quickFiltersWithGroup?.filter((item) => item.group)
        .reduce((prev, curr) => ({
            ...prev,
            [curr.group!]: [...prev[curr.group!] || [], curr.element]
        }), {});

    const quickFiltersWithoutGroup = quickFiltersWithGroup?.filter((item) => !item.group).map((item) => item.element);

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between flex-wrap">
                <Row className="mb-1">
                    <Col>
                        {quickFilterGroups && Object.entries(quickFilterGroups).map(([group, element], idx) => (
                            <span key={idx} className="mr-3">
                                {element}
                            </span>
                        ))}

                        {quickFiltersWithoutGroup}
                    </Col>
                </Row>
                <Row className="ml-auto">
                    <Col>
                        <div className="d-flex align-items-end">
                            <Form inline className="flex-grow-1">
                                <Form.Row>
                                    {primaryFilters?.map(filter => {
                                        return (
                                            <FormGroup key={filter.naam} className="mr-1 mb-1" as={Col} xs="auto">
                                                {filter.label &&
                                                    <Form.Label htmlFor={filter.naam} className="mr-1">
                                                        {filter.label}:
                                                    </Form.Label>
                                                }
                                                {filter.component}
                                            </FormGroup>
                                        )
                                    })}
                                </Form.Row>

                                {reloadClicked && (
                                    <div className="mb-1">
                                        <Button color="light" className="btn-icon" onClick={reloadClicked}>
                                            <i className="mdi mdi-reload"/>
                                        </Button>
                                    </div>
                                )}
                            </Form>

                            {secondaryFilters && secondaryFilters.length > 0 && (
                                <div>
                                    <Button variant={showSecondaryFilters ? "primary" : "link"}
                                            className={`mb-1 btn-icon ${!showSecondaryFilters ? "text-muted" : ""}`}
                                            onClick={() => setShowSecondaryFilters(!showSecondaryFilters)}>
                                        <i className="mdi mdi-filter-menu"/>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>

            <Collapse in={showSecondaryFilters}>
                <Row>
                    <Col sm={12}>
                        <Form inline>
                            <Form.Row>
                                {secondaryFilters?.map(filter => {
                                    return (
                                        <FormGroup key={filter.naam} className="mb-1 mr-1" as={Col} xs="auto">
                                            {filter.label &&
                                                <Form.Label htmlFor={filter.naam} className="mr-1">
                                                    {filter.label}:
                                                </Form.Label>
                                            }
                                            {filter.component}
                                        </FormGroup>
                                    )
                                })}
                            </Form.Row>
                        </Form>
                    </Col>
                </Row>
            </Collapse>

            <Row>
                <Col>
                    <OverviewTable itemsLabel={itemsLabel}
                                   loading={loading}
                                   loadingOverlay
                                   items={items}
                                   page={page}
                                   pageSize={pageSize}
                                   totalSize={totalSize}
                                   onTableChange={onTableChange}
                                   rowClicked={rowClicked}
                                   columns={columns}
                                   defaultSorted={defaultSorted}
                                   selectRow={selectRow}
                                   expandRow={expandRow}
                                   isExpandable={isExpandable}
                                   wrapperClasses={wrapperClasses}
                                   headerClasses={headerClasses}
                                   rowClasses={rowClasses}
                                   hover={hover}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};


export default FilteredOverviewTable;
